import File from 'models/File'
import Letter from './Letter'
import { LetterVersion } from '../TrustDocumentVersion'

class LetterOfDistribution extends Letter {
  constructor() {
    super('lod')
  }

  static createFromJson({
    id = null,
    title = null,
    text = null,
    file = null,
    signedFile = null,
    status = null,
    updated = null,
  }) {
    const lod = new LetterOfDistribution()

    lod.changeUpdated(updated)
    lod.changeId(id)
    lod.changeStatus(status)
    lod.changeTitle(title)
    lod.changeText(text)
    lod.file = new File()
    lod.signedFile = new File()

    if (file !== null) {
      lod.file = File.fromJson(file)
    }

    if (signedFile !== null) {
      lod.signedFile = File.fromJson(signedFile)
    }

    return lod
  }

  fillFromOldVersion(letterVersion) {
    this.changeTitle(letterVersion.title)
    this.changeText(letterVersion.content)

    if (letterVersion.file !== null) {
      this.file = letterVersion.file
    }

    if (letterVersion.signedFile !== null) {
      this.signedFile = letterVersion.signedFile
    }
  }

  get letterVersion() {
    return LetterVersion.fromLetter(this)
  }
}

export default LetterOfDistribution
