import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CLIENTS_LIST } from 'routing/routes'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import clientNotExist from './clientNotExist.png'

const ClientsSectionError = () => {
  const { t } = useTranslation('clientsCreation')
  const history = useHistory()
  const handleGoBack = useCallback(() => history.push(CLIENTS_LIST))

  return (
    <GenericNotFoundError
      title={t('clientSectionErrorTitle')}
      description={t('clientSectionErrorMsg')}
      textButton={t('goToClients')}
      goBackFunction={handleGoBack}
      image={clientNotExist}
    />
  )
}

export default ClientsSectionError
