import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import NotebookOutlineIcon from 'mdi-react/NotebookOutlineIcon'
import ClipboardTextMultipleIcon from 'mdi-react/ClipboardTextMultipleIcon'
import BriefcaseAccountIcon from 'mdi-react/BriefcaseAccountIcon'
import AccountSupervisorIcon from 'mdi-react/AccountSupervisorIcon'
import MonitorIcon from 'mdi-react/MonitorIcon'
import AccountLockIconIcon from 'mdi-react/AccountLockIcon'
import KeyVariantIcon from 'mdi-react/KeyVariantIcon'
import HistoryIcon from 'mdi-react/HistoryIcon'
import StoresContext from 'providers/storeContext'
import * as Routes from 'routing/routes'
import * as Permissions from 'stores/AuthStore'
import SubMenuButton from './SubMenuButton'
import styles from './authorizedNavBarMain.module.scss'

const AuthorizedNavBarMain = ({ collapsed, toggleNavBar }) => {
  const { t } = useTranslation('navBar')
  const { authStore } = useContext(StoresContext)
  const { userName } = authStore.authUser

  return (
    <>
      <div className={styles.header}>
        {!collapsed && (
          <span title={userName || t('pageTitles:siteName')} className={styles.secondaryNavbarLogo}>
            {userName || t('pageTitles:siteName')}
          </span>
        )}
      </div>
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.DASHBOARD}
        onClick={toggleNavBar}
        Icon={MonitorIcon}
        label={t('dashboard')}
        permission={Permissions.VIEW_DASHBOARD}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.CONTACTS_LIST}
        onClick={toggleNavBar}
        Icon={NotebookOutlineIcon}
        label={t('contacts')}
        permission={Permissions.LIST_CONTACT}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.CLIENTS_LIST}
        onClick={toggleNavBar}
        Icon={AccountSupervisorIcon}
        label={t('clientsList')}
        permission={Permissions.VIEW_CLIENTS}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.TRUSTS_LIST}
        onClick={toggleNavBar}
        Icon={ClipboardTextMultipleIcon}
        label={t('trustList')}
        permission={Permissions.VIEW_TRUSTS}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.CORPORATIONS_LIST}
        onClick={toggleNavBar}
        Icon={BriefcaseAccountIcon}
        label={t('corporationList')}
        permission={Permissions.VIEW_CORPORATIONS}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        onClick={toggleNavBar}
        to={Routes.TRANSACTIONS}
        label={t('transactions')}
        Icon={BriefcaseAccountIcon}
        permission={Permissions.TRANSACTIONS_SECTION}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.ACTIVITY_LIST}
        onClick={toggleNavBar}
        Icon={HistoryIcon}
        label={t('activityList')}
        permission={Permissions.CONTENT_TRANSACTION_LIST}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.USERS_LIST}
        onClick={toggleNavBar}
        Icon={AccountLockIconIcon}
        label={t('users')}
        permission={Permissions.VIEW_USERS}
        primary
      />
      <SubMenuButton
        collapsed={collapsed}
        to={Routes.ROLES_LIST}
        onClick={toggleNavBar}
        Icon={KeyVariantIcon}
        label={t('rolesList')}
        permission={Permissions.VIEW_ROLES}
        primary
      />
    </>
  )
}

AuthorizedNavBarMain.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggleNavBar: PropTypes.func.isRequired,
}

export default observer(AuthorizedNavBarMain)
