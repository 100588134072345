import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import ClientsCreationStore from 'stores/ClientsCreationStore'
import CloudAlertIcon from 'mdi-react/CloudAlertIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Button from 'presentation/Button'
import { EDIT_CLIENT } from 'stores/AuthStore'
import styles from './clientCreationActionButtons.module.scss'

const ClientCreationActionButtons = ({
  clientsCreationStore,
  handleSave,
  handleCancel,
  handleEdit,
  handleGoBack,
}) => {
  const { t } = useTranslation('clientsCreation')

  if (!clientsCreationStore.visibleActionButtons) {
    return null
  }

  return (
    <div className={styles.buttonsContainer}>
      {clientsCreationStore.showSaveToContinue && (
        <div className={styles.showSaveToContinue}>{t('saveToContinue')}</div>
      )}
      {clientsCreationStore.isViewMode ? (
        <>
          <Button
            style={{ marginRight: '15px' }}
            label={t('common:back')}
            title={t('common:back')}
            icon={<ArrowLeftIcon />}
            onClick={handleGoBack}
            iconPositionLeft
            secondary
          />
          {clientsCreationStore.entityStatus.canBeEdited && (
            <Button
              label={t('editClient')}
              title={t('editClient')}
              permission={EDIT_CLIENT}
              onClick={handleEdit}
              primary
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.saveButtonContainer}>
            <div
              className={c(styles.unsetChanges, clientsCreationStore.isDirty ? styles.show : '')}
            >
              <CloudAlertIcon />
              <span>{t('unsavedLabel')}</span>
            </div>
            <Button
              isLoading={clientsCreationStore.loadingSave}
              title={`${t(`saveClient`)} (alt+s)`}
              disabled={!clientsCreationStore.canSave}
              style={{ marginRight: '15px' }}
              label={t('saveClient')}
              permission={EDIT_CLIENT}
              onClick={handleSave}
              primary
              big
            />
          </div>
          <Button secondary big label={t('common:cancel')} onClick={handleCancel} />
        </>
      )}
    </div>
  )
}

ClientCreationActionButtons.propTypes = {
  clientsCreationStore: PropTypes.instanceOf(ClientsCreationStore).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
}

export default observer(ClientCreationActionButtons)
