import { useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import HistoryIcon from 'mdi-react/HistoryIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import TrustDocumentArchive from 'presentation/TrustDocumentArchive'
import TrustDocumentArchiveItem from 'presentation/TrustDocumentArchiveItem'
import LoadingRing from 'presentation/LoadingRing'
import Button from 'presentation/Button'
import LettersBaseStore from 'stores/LettersBaseStore'
import styles from './secondaryLetterHistory.module.scss'

const SecondaryLetterHistory = ({ mainStore, handleViewForm, isViewMode }) => {
  const { t } = useTranslation('lettersForms')
  const { draftSecondaryLetter } = mainStore

  useEffect(() => {
    draftSecondaryLetter.loadHistory()
  }, [])

  const restoreSecondaryLetter = (oldVersion) => {
    mainStore.restoreSecondaryFromOldVersion(oldVersion)

    handleViewForm()
  }

  const handleRestoreSecondaryLetter = (oldVersion) => {
    if (oldVersion.content === null) {
      oldVersion.loadContent(() => restoreSecondaryLetter(oldVersion))
    } else {
      restoreSecondaryLetter(oldVersion)
    }
  }

  if (draftSecondaryLetter.isLoading) {
    return <LoadingRing center />
  }

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <HistoryIcon />
          {t('draftHistory')}
        </div>
        <div>
          <Button
            icon={<ArrowLeftIcon size={20} />}
            label={t('goBackToDraft')}
            onClick={handleViewForm}
            iconPositionLeft
            small
          />
        </div>
      </div>
      <TrustDocumentArchive>
        {draftSecondaryLetter.history.map((version) => (
          <TrustDocumentArchiveItem
            trustDocumentVersion={version}
            handleRestoreDocument={!isViewMode ? () => handleRestoreSecondaryLetter(version) : null}
            key={`version_${version.id}`}
          />
        ))}
      </TrustDocumentArchive>
    </>
  )
}

SecondaryLetterHistory.propTypes = {
  mainStore: PropTypes.instanceOf(LettersBaseStore).isRequired,
  handleViewForm: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool.isRequired,
}

export default observer(SecondaryLetterHistory)
