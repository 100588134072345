import React, { useContext, useCallback } from 'react'
import { warningToast } from 'helpers/Toast'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import StarIcon from 'mdi-react/StarIcon'
import CommentTextIcon from 'mdi-react/CommentTextIcon'
import PlusCircleIcon from 'mdi-react/PlusCircleIcon'
import NoResultsList from 'presentation/NoResultsList'
import CreationInput from 'presentation/CreationInput'
import TextArea from 'presentation/TextArea'
import screenSizesMatches from 'util/screen'
import Creatable from 'react-select/creatable'
import StoresContext from 'providers/storeContext'
import FormSelect from 'presentation/FormSelect'
import InputFile from 'presentation/InputFile'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import RadioButtonToggle from 'presentation/RadioButtonToggle'
import Button from 'presentation/Button'
import styles from './multipleDocumentsSelection.module.scss'

const MultipleDocumentsSelection = ({
  files,
  fileTypeOptions,
  handleCreateFileType,
  handleChangeFileType,
  handleChangeFile,
  handelRemoveFile,
  handleCertifiedFile,
  handleAddFile,
  fileTypeName,
  fileName,
  certifyLabel,
  addDocumentLabel,
  isLoadingTypes,
  literal,
  descriptions,
  handleChangeDescription,
  large,
  lastEleRef,
  handleStartUploadingFile,
  handleFinishUploadingFile,
}) => {
  const { inputFileStore } = useContext(StoresContext)
  const { t } = useTranslation('dynamicForms')

  const addFile = useCallback(() => {
    if (inputFileStore.canUpload) {
      warningToast(t('warningUpload'), null, 'warningUpload').show()
      return
    }
    handleAddFile()
  }, [])

  const removeFileHandle = useCallback((file) => {
    if (inputFileStore.eleFileInput) {
      inputFileStore.eleFileInput.value = ''
    }
    handelRemoveFile(file)
  }, [])

  return (
    <div className={styles.multipleDocumentsContainer}>
      {files?.length === 0 ? (
        <div className={styles.noDocumentsAttached}>
          <NoResultsList
            topText={t('noDocumentsAttached')}
            Icon={PlusCircleIcon}
            buttonText={addDocumentLabel}
            showIcon={!literal}
            onClick={literal ? null : handleAddFile}
          />
        </div>
      ) : (
        <>
          <TransitionGroup>
            {files?.map((file, i) => (
              <CSSTransition
                key={`file_${file.uuid}`}
                timeout={200}
                className={c(styles.fileContainer, large && styles.large, 'fileContainer')}
                classNames={{
                  enter: styles['fileContainer-enter'],
                  enterActive: styles['fileContainer-enter-active'],
                  exit: styles['fileContainer-exit'],
                  exitActive: styles['fileContainer-exit-active'],
                }}
              >
                <div>
                  {literal ? (
                    <div className={styles.literalMultipleDocument}>
                      <CreationInput as={InputFile} fileStore={file} onChange={() => {}} literal />
                      <div className={styles.literalCategory}>
                        <StarIcon size={15} />
                        {file.type.value !== '' ? file.type.value.value : '-'}
                      </div>
                      <div>
                        <RadioButtonToggle
                          onChange={() => {}}
                          label={certifyLabel}
                          checked={file.legalCertified}
                          literal
                          italic
                        />
                      </div>
                      {descriptions && (
                        <div className={styles.literalCategory}>
                          <CommentTextIcon size={15} />
                          {file.description.value !== '' ? file.description.value : '-'}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className={styles.fileDataContainer}>
                        <CreationInput
                          as={FormSelect}
                          formSelectAs={Creatable}
                          inputStore={file.type}
                          name={fileTypeName}
                          options={fileTypeOptions}
                          onCreateOption={(value) => handleCreateFileType(value, file)}
                          onChange={(e) => handleChangeFileType(e, file)}
                          isLoading={isLoadingTypes}
                          mergeStyles={
                            !screenSizesMatches('xs') && {
                              control: {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            }
                          }
                          inputRef={i === files?.length - 1 ? lastEleRef : null}
                        />
                        <CreationInput
                          as={InputFile}
                          fileStore={file}
                          name={fileName}
                          onChange={(e) => {
                            return handleChangeFile !== null ? handleChangeFile(e, file) : null
                          }}
                          handleStartUploadingFile={handleStartUploadingFile}
                          handleFinishUploadingFile={handleFinishUploadingFile}
                        />
                      </div>
                      <TrashCanOutlineIcon
                        size={20}
                        className={styles.deleteFileIcon}
                        onClick={() => removeFileHandle(file)}
                      />
                      <div>
                        <RadioButtonToggle
                          onChange={() => handleCertifiedFile(file)}
                          label={certifyLabel}
                          checked={file.legalCertified}
                          italic
                        />
                      </div>
                      {descriptions && (
                        <div className={styles.textareaContainer}>
                          <CreationInput
                            as={TextArea}
                            inputStore={file.description}
                            name="description"
                            onChange={(e) => handleChangeDescription(e.target.value, file)}
                            minRows={1}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
          {!literal && (
            <div className={c(!large && styles.addDocumentButtonTop)}>
              <Button
                small
                onClick={addFile}
                label={addDocumentLabel}
                className={styles.addDocumentButton}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

MultipleDocumentsSelection.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  files: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  fileTypeOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleCreateFileType: PropTypes.func.isRequired,
  handleChangeFileType: PropTypes.func.isRequired,
  handleChangeFile: PropTypes.func,
  handelRemoveFile: PropTypes.func.isRequired,
  handleCertifiedFile: PropTypes.func.isRequired,
  handleAddFile: PropTypes.func.isRequired,
  fileTypeName: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  certifyLabel: PropTypes.string.isRequired,
  addDocumentLabel: PropTypes.string.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  isLoadingTypes: PropTypes.bool,
  literal: PropTypes.bool,
  descriptions: PropTypes.bool,
  large: PropTypes.bool,
  lastEleRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  handleStartUploadingFile: PropTypes.func,
  handleFinishUploadingFile: PropTypes.func,
}

MultipleDocumentsSelection.defaultProps = {
  isLoadingTypes: false,
  handleChangeFile: null,
  literal: false,
  descriptions: false,
  large: false,
  lastEleRef: null,
  handleStartUploadingFile: null,
  handleFinishUploadingFile: null,
}

export default observer(MultipleDocumentsSelection)
