import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class ImageService {
  saveImage = (image) => {
    const data = new FormData()
    data.append('file', image)

    return axios.post(`${API_URL}/tenant/files/image`, data).then((response) => response.data.data)
  }
}

export default ImageService
