import React, { useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import PageTitle from 'presentation/PageTitle'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import { useParams, useHistory } from 'react-router'
import LoadingRing from 'presentation/LoadingRing'
import LinkButton from 'presentation/LinkButton'
import PermissionUserList from 'presentation/PermissionUserList'
import { useTranslation } from 'react-i18next'
import Modal from 'scenes/Modal'
import { TRUSTS_LIST, TRUSTS_CREATION } from 'routing/routes'
import ServerError from 'presentation/ServerError'
import DynamicEntitiesUserItem from 'presentation/DynamicEntitiesUserItem'
import ButtonBackListTrust from 'presentation/ButtonBackListTrust'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import { successToast } from 'helpers/Toast'
import RequestErrors from 'presentation/RequestErrors'
import NoResultsList from 'presentation/NoResultsList'
import TrustUsersStore from './TrustUsersStore'
import styles from './trustUsers.module.scss'

const TrustUsers = () => {
  const { t } = useTranslation('trustUsers')
  const history = useHistory()
  const [lastSearch, setLastSearch] = useState('')
  const [deleteUser, setDeleteUser] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [trustUsersStore] = useState(() => new TrustUsersStore())
  let searchUsersTimeout = null
  const { trustId } = useParams()

  useEffect(() => {
    trustUsersStore.init(trustId).then(() => {
      trustUsersStore.searchUsers()
    })
  }, [])

  const handleInputChange = (value) => {
    if (searchUsersTimeout) {
      clearTimeout(searchUsersTimeout)
    }

    searchUsersTimeout = setTimeout(() => {
      if (value !== lastSearch) {
        trustUsersStore.searchUsers(value)
        setLastSearch(value)
      }
    }, 300)
  }

  const handleInputFocus = useCallback(() => {
    trustUsersStore.searchUsers()
  }, [])

  const saveUsers = useCallback((toastText = t('trustUserSavedMsg')) => {
    trustUsersStore.save(() => {
      successToast(toastText, null, 'trustUserSaved').show()
      trustUsersStore.init(trustUsersStore.id)
    })
  }, [])

  const handleSetUserRole = useCallback((selectedUser, role) => {
    TrustUsersStore.setUserRole(selectedUser, role)
    saveUsers(t('changesSaved'))
  }, [])

  const handleChange = useCallback((selectedUser) => {
    trustUsersStore.addUser(selectedUser)
    saveUsers()
  }, [])

  const handleDelete = useCallback((selectedUser) => {
    setDeleteUser(selectedUser)
    setDeleteModal(true)
  }, [])

  const successDeleteModal = useCallback(() => {
    trustUsersStore.deleteUser(deleteUser)
    setDeleteModal(false)
    saveUsers(t('userRemoved'))
  }, [deleteUser])

  const cancelDeleteModal = useCallback(() => setDeleteModal(false), [])

  const handleGoBack = useCallback(() => history.push(TRUSTS_LIST))

  if (trustUsersStore.noIdError) {
    return (
      <GenericNotFoundError
        description={t('noIdErrorDesc')}
        textButton={t('noIdErrorButton')}
        goBackFunction={handleGoBack}
        title={t('noIdErrorTitle')}
      />
    )
  }

  const assignedUsersList = () => {
    if (trustUsersStore.assignedUsers.length === 0) {
      return <NoResultsList topText={t('noSelectedUsers')} showIcon={false} />
    }

    return (
      <PermissionUserList
        assignedUsers={trustUsersStore.assignedUsers}
        loadingSave={trustUsersStore.loadingSave}
        handleSetUserRole={handleSetUserRole}
        handleDelete={handleDelete}
      />
    )
  }

  const tryAgain = useCallback(() => trustUsersStore.tryAgain())

  if (trustUsersStore.serverError) {
    return <ServerError handleTryAgain={tryAgain} />
  }

  if (trustUsersStore.usersError) {
    return (
      <GenericNotFoundError
        description={t('trustUsersErrorDesc')}
        textButton={t('trustUsersErrorButton')}
        goBackFunction={handleGoBack}
        title={t('trustUsersErrorTitle')}
      />
    )
  }

  if (trustUsersStore.isLoading) {
    return <LoadingRing center />
  }

  return (
    <>
      <LinkButton
        styleButton={styles.subtitle}
        text={trustUsersStore.trustName}
        icon={<ArrowRightIcon size={15} />}
        route={`${TRUSTS_CREATION}?id=${trustId}&v=true`}
      />
      <PageTitle title={t('trustUsersTitle')} floating />
      <CreationInput
        as={FormSelect}
        inputStore={trustUsersStore.store}
        name="fullName"
        label={t('selectUserLabel')}
        placeholder={t('selectUserPlaceHolder')}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onFocus={handleInputFocus}
        filterOption={(option) => option}
        options={trustUsersStore.searchOptionUsers}
        isLoading={trustUsersStore.loadingSearch}
        value={null}
        literal={false}
        components={{ Option: DynamicEntitiesUserItem }}
      />
      <div className={styles.assignedUsersContainer}>
        {trustUsersStore.isLoadingData ? <LoadingRing small center /> : assignedUsersList()}
      </div>
      <Modal
        open={deleteModal}
        name="deleteUserModal"
        title={t('deleteModalTitle')}
        onSuccess={successDeleteModal}
        onCancel={cancelDeleteModal}
      />
      <RequestErrors errors={trustUsersStore.requestErrors} />
      <ButtonBackListTrust id={trustUsersStore.id} />
    </>
  )
}

export default observer(TrustUsers)
