import moment from 'moment'
// import ListDate from 'models/ListDate'

class ListRole {
  constructor(id, name, created, updated) {
    this.id = id
    this.name = name
    this.created = created
    this.updated = updated
  }

  static fromJson({ id, name, created, updated }) {
    // return new ListRole(id, name, ListDate.fromJson(created), ListDate.fromJson(updated))
    return new ListRole(id, name, moment(created), moment(updated))
  }
}

export default ListRole
