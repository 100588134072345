import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { DatePart } from 'models/FormMetadata'
import { useTranslation } from 'react-i18next'
import CreationInput from 'presentation/CreationInput'
import DatePicker from 'presentation/DatePicker'

const DynamicFormPartDate = ({ part, inputs, readOnly, inputRef }) => {
  const [inputStore] = useState(inputs.get(part.id))
  const { t } = useTranslation('dynamicForms')
  const handleChangeDate = useCallback((val) => {
    inputStore.store.setValue(val)
    inputStore.validate()
  }, [])
  const handleOnBlur = useCallback(() => inputStore.validate(), [])

  return (
    <CreationInput
      inputStore={inputStore.store}
      label={part.label}
      placeholder={t('placeholderDate', { name: part.label })}
      onChange={handleChangeDate}
      onBlur={handleOnBlur}
      literal={readOnly}
      as={DatePicker}
      showYearDropdown
      minDate={
        inputStore.validationRules ? inputStore.validationRules.get('min') || undefined : undefined
      }
      maxDate={
        inputStore.validationRules ? inputStore.validationRules.get('max') || undefined : undefined
      }
      inputRef={(ref) => {
        if (ref && ref.input) {
          inputRef(ref.input)
        }
      }}
    />
  )
}

DynamicFormPartDate.propTypes = {
  part: PropTypes.instanceOf(DatePart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

DynamicFormPartDate.defaultProps = {
  readOnly: true,
  inputRef: () => {},
}

export default DynamicFormPartDate
