import FileDocumentOutlineIcon from 'mdi-react/FileDocumentOutlineIcon'
import AccountIcon from 'mdi-react/AccountIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import AccountSearchIcon from 'mdi-react/AccountSearchIcon'
import ShieldAccountIcon from 'mdi-react/ShieldAccountIcon'
import ClipboardCheckOutlineIcon from 'mdi-react/ClipboardCheckOutlineIcon'
import FolderOpenIcon from 'mdi-react/FolderOpenIcon'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import BriefcaseCheckIcon from 'mdi-react/BriefcaseCheckIcon'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import CashMultipleIcon from 'mdi-react/CashMultipleIcon'
import CardTextOutlineIcon from 'mdi-react/CardTextOutlineIcon'
import PenIcon from 'mdi-react/PenIcon'
import CityVariantOutlineIcon from 'mdi-react/CityVariantOutlineIcon'
import BullhornIcon from 'mdi-react/BullhornIcon'
import ChartLineIcon from 'mdi-react/ChartLineIcon'
import StarIcon from 'mdi-react/StarIcon'
import HomeHeartIcon from 'mdi-react/HomeHeartIcon'
import DotsHorizontalCircleOutlineIcon from 'mdi-react/DotsHorizontalCircleOutlineIcon'
import ImageFrameIcon from 'mdi-react/ImageFrameIcon'
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon'
import BitcoinIcon from 'mdi-react/BitcoinIcon'
import DiamondStoneIcon from 'mdi-react/DiamondStoneIcon'
import SitemapIcon from 'mdi-react/SitemapIcon'
import BookOutlineIcon from 'mdi-react/BookOutlineIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import ChartGanttIcon from 'mdi-react/ChartGanttIcon'
import HistoryIcon from 'mdi-react/HistoryIcon'
// eslint-disable-next-line import/no-duplicates
import LibraryBooksIcon from 'mdi-react/ClipboardTextMultipleIcon'
// eslint-disable-next-line import/no-duplicates
import ClipboardTextMultipleIcon from 'mdi-react/ClipboardTextMultipleIcon'
import PhoneIcon from 'mdi-react/PhoneIcon'
import VectorTriangleIcon from 'mdi-react/VectorTriangleIcon'
import AccountKeyOutlineIcon from 'mdi-react/AccountKeyOutlineIcon'
import FileHiddenIcon from 'mdi-react/FileHiddenIcon'
import CogClockwiseIcon from 'mdi-react/CogClockwiseIcon'
import ClockCheckOutlineIcon from 'mdi-react/ClockCheckOutlineIcon'
import BriefcaseVariantOutlineIcon from 'mdi-react/BriefcaseVariantOutlineIcon'

export default {
  BookOutlineIcon,
  FileHiddenIcon,
  FileDocumentOutlineIcon,
  AccountIcon,
  EyeOutlineIcon,
  AccountMultipleIcon,
  AccountSearchIcon,
  ShieldAccountIcon,
  CheckCircleIcon,
  ClipboardCheckOutlineIcon,
  FolderOpenIcon,
  BriefcaseCheckIcon,
  BriefcaseIcon,
  CashMultipleIcon,
  CardTextOutlineIcon,
  PenIcon,
  CityVariantOutlineIcon,
  BullhornIcon,
  ChartLineIcon,
  StarIcon,
  HomeHeartIcon,
  DotsHorizontalCircleOutlineIcon,
  ImageFrameIcon,
  BitcoinIcon,
  DiamondStoneIcon,
  PlusIcon,
  SitemapIcon,
  LibraryBooksIcon,
  ClipboardTextMultipleIcon,
  PhoneIcon,
  VectorTriangleIcon,
  AccountKeyOutlineIcon,
  ChartGanttIcon,
  HistoryIcon,
  CogClockwiseIcon,
  ClockCheckOutlineIcon,
  BriefcaseVariantOutlineIcon,
}
