import Part, { TYPE_SNAPSHOT_LOW } from './Part'

class LowSnapshotPart extends Part {
  constructor(id, label) {
    super(id, TYPE_SNAPSHOT_LOW)

    this.label = label
  }

  static fromJson({ id, display }) {
    const { label = '' } = display

    return new LowSnapshotPart(id, label)
  }
}

export default LowSnapshotPart
