import Change from './Change'

class ContactActivity {
  constructor(
    activityId,
    id,
    changes,
    contact,
    entity,
    description,
    created,
    updated,
    status,
    type,
    isConfirmed
  ) {
    this.id = id
    this.changes = changes.map((change) => Change.fromJson(change))
    this.contact = contact
    this.entity = entity
    this.description = description
    this.created = created
    this.updated = updated
    this.status = status
    this.type = type
    this.activityId = activityId
    this.isConfirmed = isConfirmed
  }

  static fromJson({
    subjectAt: { id, changes, contact, entity },
    description,
    created,
    updated,
    status,
    type,
    id: activityId,
    isConfirmed,
  }) {
    const changesMap = changes.map((change) => Change.fromJson(change))

    return new ContactActivity(
      activityId,
      id,
      changesMap,
      contact,
      entity,
      description,
      created,
      updated,
      status,
      type,
      isConfirmed
    )
  }

  get isApproved() {
    return this.status === 'approved'
  }

  get showingName() {
    return this.contact.name
  }

  get showingEntity() {
    return this.entity.name
  }
}

export default ContactActivity
