import { makeObservable, runInAction, observable, action, computed } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import TrustsService from 'services/TrustService'
import CorporationService from 'services/CorporationService'
import { CORPORATIONS_CREATION, TRUSTS_CREATION } from 'routing/routes'

export const TRUST_TYPE = 'trust'
export const CORP_TYPE = 'corp'

class FiduciaryStructurePreloadDataStore extends AsyncStore {
  constructor(fiduciaryStructureId, structure) {
    super()
    this.fiduciaryStructureId = fiduciaryStructureId
    this.structure = structure
    this.fiduciaryStructureName = ''
    this.getFiduciaryStructureService =
      this.structure === TRUST_TYPE
        ? new TrustsService().getTrust
        : new CorporationService().getCorporation

    makeObservable(this, {
      // observables
      fiduciaryStructureName: observable,

      // actions
      preloadData: action,
      getTrustData: action,
      getCorpData: action,

      // computed
      isTrust: computed,
      isCorp: computed,
      trustId: computed,
      corporationId: computed,
    })

    this.getCorpData = this.getCorpData.bind(this)
    this.getTrustData = this.getTrustData.bind(this)

    this.preloadData()
  }

  preloadData() {
    this.preRequest(() => this.preloadData(this.fiduciaryStructureId))

    const getFiduciaryStructureData = this.isTrust ? this.getTrustData : this.getCorpData

    this.getFiduciaryStructureService(this.fiduciaryStructureId)
      .then(getFiduciaryStructureData)
      .catch((e) => {
        runInAction(() => {
          this.onErrorRequest(e)
        })
      })
  }

  getTrustData(fiduciaryStructure) {
    runInAction(() => {
      // We can take more data if would be necessary
      this.fiduciaryStructureName = fiduciaryStructure.data.trustName
    })
  }

  getCorpData(fiduciaryStructure) {
    runInAction(() => {
      // We can take more data if would be necessary
      this.fiduciaryStructureName = fiduciaryStructure.data.name
    })
  }

  get isTrust() {
    return this.structure === TRUST_TYPE
  }

  get isCorp() {
    return this.structure === CORP_TYPE
  }

  get trustId() {
    return this.isTrust ? this.fiduciaryStructureId : null
  }

  get corporationId() {
    return this.isCorp ? this.fiduciaryStructureId : null
  }

  get fiduciaryStructureCreationRoute() {
    return this.isTrust ? TRUSTS_CREATION : CORPORATIONS_CREATION
  }

  get fiduciaryStructureRoute() {
    return `${this.fiduciaryStructureCreationRoute}?id=${this.fiduciaryStructureId}&v=true`
  }
}

export default FiduciaryStructurePreloadDataStore
