import React from 'react'
import PropTypes from 'prop-types'
import Letter from 'models/Letters'
import { DraftEditionButton } from 'presentation/LettersSection'
import DocumentDate from 'presentation/DocumentsSection'
import styles from './letterFormDraftCard.module.scss'

const LetterFormDraftCard = ({ letter, onClick }) => {
  return (
    <div className={styles.cardContainer} onClick={onClick} role="button" tabIndex="0">
      <div className={styles.cardLeft}>
        <DocumentDate
          lastUpdate={letter.lastUpdate}
          firsName={letter.updatedByFirstName}
          lastName={letter.updatedByLastName}
        />
        {letter.title.value !== null && letter.title.value !== '' && (
          <div className={styles.title}>{letter.title.value}</div>
        )}
      </div>
      <div>
        <DraftEditionButton />
      </div>
    </div>
  )
}

LetterFormDraftCard.propTypes = {
  letter: PropTypes.instanceOf(Letter).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default LetterFormDraftCard
