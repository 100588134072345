import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import * as Routes from 'routing/routes'
import { useTranslation } from 'react-i18next'
import DynamicFormSubMenu from 'presentation/DynamicFormSubMenu'
import StoresContext from 'providers/storeContext'
import { SubMenu, subMenuUrl, toggleNavBar } from './SubMenuUtils'

const SubMenuCompanyContacts = observer(({ id: idContact, view, collapsed }) => {
  const { contactsCreationStore, navBarStore } = useContext(StoresContext)
  const { t } = useTranslation('navBar')

  useEffect(() => {
    if (contactsCreationStore.errorLoadEdit) {
      navBarStore.setForcedOnlyMainNavBar()
    }
  }, [contactsCreationStore.errorLoadEdit])

  const getSubMenuUrl = (url, smIdView, smView, disabled) =>
    subMenuUrl(url, smIdView, smView, disabled, Routes.COMPANY_CONTACT_CREATION)

  return (
    <SubMenu collapsed={collapsed}>
      <DynamicFormSubMenu
        dynamicFormsStore={contactsCreationStore}
        sectionUrlFunction={getSubMenuUrl}
        toggleNavBar={toggleNavBar}
        entityTypeName={t('contact')}
        entityId={idContact}
        collapsed={collapsed}
        view={view}
        noStatus
      />
    </SubMenu>
  )
})

export default SubMenuCompanyContacts
