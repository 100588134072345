export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const SETTINGS = '/settings'
export const FORGOT_PASSWORD = '/forgotPassword'
export const ACTIVATE_USER = '/activate'
export const RESET_PASSWORD_USER = '/resetPassword'
export const MAINTENANCE_PAGE = '/maintenancePage'
export const COOKIE_POLICY_PAGE = '/cookies'

export const USER_PROFILE = '/profile'
export const CHANGE_PASSWORD = '/changePassword'

export const DASHBOARD = '/dashboard'
export const TRUSTS_LIST = '/trusts'
export const CORPORATIONS_LIST = '/corporations'
export const TRANSACTIONS = '/transactions'
export const CLIENTS_LIST = '/clients'
export const CONTACTS_LIST = '/contacts'
export const USERS_LIST = '/users'
export const ROLES_LIST = '/roles'
export const ACTIVITY_LIST = '/activity'
export const TRUST_ACTIVITY = '/activity/details/:trustId'

// users creation
export const USERS_CREATION = '/users/user/'

// trust creation
export const TRUSTS_CREATION = '/trusts/trust/'
export const TRUSTS_SNAPSHOT = '/trusts/trust/snapshot'
export const TRUSTS_CREATION_RULES_SHORT = 'rules'
export const TRUSTS_CREATION_RULES = `/trusts/trust/${TRUSTS_CREATION_RULES_SHORT}`
export const TRUSTS_CREATION_SIGNED_DOCUMENTS_SHORT = `signedDocs`
export const TRUSTS_CREATION_SIGNED_DOCUMENTS = `/trusts/trust/${TRUSTS_CREATION_SIGNED_DOCUMENTS_SHORT}`
export const TRUSTS_USERS = '/trusts/permissions/:trustId'
export const TRUSTS_ASSETS = '/trusts/trust/assets'
export const DOWNLOAD_ZIP = `/trusts/downloadZip`
export const TRUSTS_VALUATIONS = `/trusts/valuations`
export const TRUSTS_HISTORY = `/trusts/history`
export const TRUSTS_HISTORY_VERSIONS = `/trusts/versions-history`
export const TRUSTS_HISTORY_VERSION_DETAILS = `/trusts/versions-history/version`

// corps & trusts transaction
export const FIDUCIARY_STRUCTURE_TRANSACTIONS = `/transactions-assets/changes`
export const FIDUCIARY_STRUCTURE_TRANSACTIONS_ASSETS = '/transactions-assets/:fiduciaryStructureId'
export const FIDUCIARY_STRUCTURE_TRANSACTIONS_VALUATION =
  '/transactions-assets/valuation/:fiduciaryStructureId'
export const FIDUCIARY_STRUCTURE_TRANSACTIONS_EXPENSE =
  '/transactions-assets/expense/:fiduciaryStructureId'

// corporation creation
export const CORPORATIONS_CREATION = '/corporations/corporation/'
export const CORPORATIONS_CREATION_SIGNED_DOCUMENTS_SHORT = `signedDocs`
export const CORPORATIONS_CREATION_SIGNED_DOCUMENTS = `/corporations/corporation/${TRUSTS_CREATION_SIGNED_DOCUMENTS_SHORT}`
export const CORPORATIONS_USERS = '/corporations/permissions/:corporationId'

// contacts creation
export const INDIVIDUAL_CONTACT_CREATION = '/contacts/individual/'
export const INDIVIDUAL_CONTACT_FINANCIAL_STRUCTURE = 'financial-structure'
export const COMPANY_CONTACT_CREATION = '/contacts/company/'

// clients creation
export const INDIVIDUAL_CLIENT_CREATION = '/clients/individual/'
export const INDIVIDUAL_CLIENT_FINANCIAL_STRUCTURE = 'financial-structure'
export const COMPANY_CLIENT_CREATION = '/clients/company/'
export const COMPANY_CLIENT_FINANCIAL_STRUCTURE = 'financial-structure'

// role creation
export const ROLES_CREATION = '/roles/role/'

const trustsStatusCreationRoute = (status) => `${TRUSTS_LIST}?status=${status}`
const trustsCreationRoute = (trustId) => TRUSTS_USERS.replace(':trustId', trustId)
const corporationsCreationRoute = (corporationId) =>
  CORPORATIONS_USERS.replace(':corporationId', corporationId)

export { trustsCreationRoute, corporationsCreationRoute, trustsStatusCreationRoute }
