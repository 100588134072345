import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { observer } from 'mobx-react'
import defaultProfile from 'presentation/NavBarComponents/images/defaultProfile.png'
import styles from 'scenes/TrustUsers/trustUsers.module.scss'
import RoleSelectList from 'presentation/RoleSelectList'

const DynamicEntitiesUserItem = ({
  innerProps,
  innerRef,
  data,
  handleSetUserRole,
  isFocused,
  isLoading,
  hasCheckbox,
}) => {
  const selectedUser = data
  return (
    <div
      ref={innerRef}
      className={c(styles.option, isFocused && styles.focus, innerProps.className)}
      {...innerProps}
    >
      <div className={styles.imageContainer}>
        {isLoading && (
          <div className={styles.loadingSave}>
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
        <div className={c(styles.optionImage, isLoading && styles.loadingImage)}>
          <img src={selectedUser.profileImage || defaultProfile} alt={selectedUser.name} />
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.title} />
        <div className={styles.name}>{selectedUser.name}</div>
        <div className={styles.rolesList}>
          <RoleSelectList
            hasCheckbox={hasCheckbox}
            handleSetUserRole={handleSetUserRole}
            selectedUser={selectedUser}
          />
        </div>
      </div>
      <div className={styles.email}>{selectedUser.email}</div>
    </div>
  )
}

DynamicEntitiesUserItem.propTypes = {
  innerProps: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.oneOfType([PropTypes.any]).isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  handleSetUserRole: PropTypes.func,
  isLoading: PropTypes.bool,
  isFocused: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
}
DynamicEntitiesUserItem.defaultProps = {
  innerRef: null,
  isLoading: false,
  isFocused: false,
  handleSetUserRole: null,
  hasCheckbox: false,
}

export default observer(DynamicEntitiesUserItem)
