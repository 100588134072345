import { action, makeObservable } from 'mobx'
import PermissionUserStore from 'stores/PermissionUserStore'
import CorporationUsersService from 'services/CorporationUsersService'
import CorporationService from 'services/CorporationService'

class CorporationUsersStore extends PermissionUserStore {
  constructor() {
    super()
    this.corporationService = new CorporationService()
    this.permissionService = new CorporationUsersService()

    makeObservable(this, {
      init: action,
    })
  }

  async init(id) {
    if (!id) {
      this.noIdError = true
      return
    }

    this.setId(id)
    this.preRequest(() => this.init(id))

    this.corporationService
      .getCorporation(id)
      .then((corp) => {
        this.corporationName = corp.data.name
        this.getAssignedUsers()
        this.searchUsers()
        this.onSuccessRequest()
      })
      .catch(() => {
        this.noIdError = true
      })
  }

  listAssignedUsers(id) {
    return this.permissionService.listAssignedUsers(id)
  }

  searchPermissionUsers(id, term) {
    return this.permissionService.searchPermissionUsers(id, term)
  }

  savePermissionUsers(id, getJson) {
    this.permissionService.savePermissionUsers(id, getJson)
  }
}

export default CorporationUsersStore
