import Dinero from 'dinero.js'

const MONEY_UNITS = {
  billions: {
    base: 1_000_000_000,
    label: 'B',
  },
  millions: {
    base: 1_000_000,
    label: 'M',
  },
  thousands: {
    base: 1_000,
    label: 'K',
  },
  default: {
    base: 1,
  },
}

const moneyValueFormat = (value = '', currency = '', showAs = 'default') => {
  const { base, label } = MONEY_UNITS[showAs]

  if (value !== '' && currency !== '') {
    const shouldBeFixed = value > base
    const fixedValue = shouldBeFixed ? value / base : value
    const amount = Math.round(Number(fixedValue) * 100)
    const displayValue = Dinero({ amount, currency }).toFormat()

    return shouldBeFixed && label ? `${displayValue} ${label}` : displayValue
  }
  if (value !== '') {
    return Number(value)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return null
}

export default moneyValueFormat
