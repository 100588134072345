import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import EntityStatus from 'models/TransactionStatus'
import styles from './voteStatus.module.scss'

const VoteStatus = observer(({ status, onlyBullet, t }) => {
  return (
    <div className={c(styles.statusContainer, onlyBullet && styles.onlyBullet)}>
      <span
        className={c(
          styles.bullet,
          styles.statusVoting,
          status === 'approved' && styles.statusApproved,
          status === 'voting' && styles.statusVoting,
          status === 'rejected' && styles.statusRejected,
          status === 'complexResolution' && styles.statusCancel,
          status === 'resolved' && styles.statusResolved
        )}
        title={t(`${status}`)}
      />
      <div className={c(styles.status)}>{t(`${status}`)}</div>
    </div>
  )
})

VoteStatus.propTypes = {
  status: PropTypes.string.isRequired,
  onlyBullet: PropTypes.bool,
}

VoteStatus.defaultProps = {
  status: new EntityStatus(),
}

export default withTranslation('transactionsCreation')(VoteStatus)
