import AsyncStore from 'stores/AsyncStore'
import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import TrustTransactionsAssetsService from 'services/TrustTransactionsAssetsService'
import InputStore from 'stores/InputStore'
import DestinationAsset from 'models/DestinationAsset'
import File from 'models/File'

class FundingTransactionStore extends AsyncStore {
  constructor(id, type) {
    super()
    this.id = id
    this.type = type
    this.assets = []
    this.destinationAssets = [new DestinationAsset()]
    this.destinationAssetsOptions = []
    this.transactionDate = new InputStore()
    this.title = new InputStore()
    this.description = new InputStore()
    this.hasVoting = new InputStore()
    this.votersOptions = []
    this.voters = []

    this.file = new File()

    this.trustTransactionsAssetsService = new TrustTransactionsAssetsService()

    this.init()

    makeObservable(this, {
      // observables
      id: observable,
      assets: observable,
      destinationAssets: observable,
      destinationAssetsOptions: observable,
      hasVoting: observable,
      votersOptions: observable,
      voters: observable,
      file: observable,
      // actions
      loadAssets: action,
      addDestinationAsset: action,
      deleteDestinationAsset: action,
      setHasVoting: action,
      setVoters: action,
      emptyInputs: action,
      resetErrors: action,
      hasWithdrawAmount: computed,
    })
  }

  init() {
    this.loadVotersOptions()
    this.loadDestinationAssetsOptions()
  }

  async loadVotersOptions() {
    try {
      this.preRequest(this.loadVotersOptions)
      const voters = await this.trustTransactionsAssetsService.loadVoters(this.id)

      runInAction(() => {
        this.votersOptions = voters
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async loadAssets(type) {
    try {
      this.preRequest(this.loadAssets)
      this.trustTransactionsAssetsService.loadAssets(this.id, type).then(({ assets }) => {
        runInAction(() => {
          this.assets = assets
        })
      })
      this.onSuccessRequest()
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  async loadDestinationAssetsOptions() {
    try {
      this.preRequest()
      const { assets } = await this.trustTransactionsAssetsService.loadAssets(
        this.id,
        null,
        'inflow'
      )

      runInAction(() => {
        this.destinationAssetsOptions = assets.map((asset) => ({
          label: asset.id,
          value: asset.name,
          currency: asset.valuation[0].currency,
          canTransaction: asset.canTransaction,
        }))
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  destinationAssetsHasErrors() {
    let result = false
    this.destinationAssets.forEach((destination) => {
      if (destination.asset.error) {
        result = true
      }
    })

    return result
  }

  async save(successCallback) {
    this.preRequest(this.save)
    const data = this.getDataJson()

    try {
      await this.trustTransactionsAssetsService.saveTransaction(this.type, data)
      runInAction(() => {
        this.emptyInputs()
        this.onSuccessRequest()
        successCallback(this.type)
      })
    } catch (e) {
      e.response?.data?.error?.errors.map((error) =>
        this.onErrorRequest(error || 'Something went wrong')
      )
    }
  }

  emptyInputs() {
    this.destinationAssets = [new DestinationAsset()]
    this.transactionDate.setValue('')
    this.title.setValue('')
    this.description.setValue('')
    this.hasVoting.setValue(false)
    this.voters = []
    this.file = new File()
    this.assets.forEach((asset) => {
      asset.unselect()
      asset.value.setValue('')
    })
  }

  getDataJson() {
    const sourceAssets = this.assets
      .filter((asset) => asset.isSelected)
      .map((asset) => asset.getJson())
    const destinationAssets = this.destinationAssets.map((asset) => asset.getJson())

    const updatedAditionalData = {
      description: this.description.value,
      document: this.file.getJson(),
      sourceAccounts: sourceAssets,
      destination: destinationAssets,
    }
    if (this.hasVoting.value) {
      updatedAditionalData.voters = this.voters
    }
    const json = {}
    json.fiduciaryStructureId = this.id
    json.name = this.title.value
    json.date = this.transactionDate.value.toString()
    json.withVoting = !!this.hasVoting.value
    json.additionalData = updatedAditionalData
    return json
  }

  addDestinationAsset() {
    this.destinationAssets.push(new DestinationAsset())
  }

  deleteDestinationAsset(index) {
    this.destinationAssets.splice(index, 1)
  }

  setHasVoting() {
    this.hasVoting.setValue(!this.hasVoting.value)
  }

  setVoters(voters) {
    this.voters = [...voters]
  }

  resetErrors() {
    this.errors = []
  }

  get hasWithdrawAmount() {
    const assetHasValue = (asset) => asset.value.value

    return this.assets.some(assetHasValue)
  }
}

export default FundingTransactionStore
