import { makeAutoObservable } from 'mobx'
import { InstanceEntity } from 'models/FormMetadata'

class DynamicFormPartEntityListStore {
  showCreation = false
  editEntity = null
  editEntityOriginal = null
  deleteModal = false
  deleteInstanceEntity = null

  constructor() {
    makeAutoObservable(this)
  }

  setShowCreation(value) {
    this.showCreation = value
  }

  setEditEntity(value) {
    this.editEntity = value
  }

  setEditEntityOriginal(value) {
    this.editEntityOriginal = value
  }

  setDeleteModal(value) {
    this.deleteModal = value
  }

  setDeleteInstanceEntity(value) {
    this.deleteInstanceEntity = value
  }

  closeModalCreation() {
    this.setShowCreation(false)
    this.setEditEntity(null)
    this.setEditEntityOriginal(null)
    this.setDeleteModal(null)
    this.setDeleteInstanceEntity(null)
  }

  handleDeleteRow(instanceEntity) {
    this.setDeleteModal(true)
    this.setDeleteInstanceEntity(instanceEntity)
  }

  handleClickRow(instanceEntity) {
    this.setShowCreation(true)
    this.setEditEntity(InstanceEntity.createFromInstanceEntity(instanceEntity))
    this.setEditEntityOriginal(instanceEntity)
  }

  closeDeleteModal() {
    this.setDeleteModal(false)
    this.setDeleteInstanceEntity(null)
  }
}

export default DynamicFormPartEntityListStore
