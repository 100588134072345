import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import Icon from 'presentation/Icon'
import styles from './rulesTextHighlighter.module.scss'
import buttonBackground from './images/button.svg'

const ADD_BUTTON_HEIGHT = 41
const ADD_BUTTON_WIDTH = 51

const OverlayText = observer(({ parsedRule, handleSelectRule }) => {
  if (parsedRule.rules.length > 0) {
    const usableColors = parsedRule.rules.map((rule) => rule.color) // array with the colors for each rule in this chunk
    const highlightedColors = parsedRule.rules
      .filter((rule) => rule.highlighted || rule.selected)
      .map((rule) => rule.color) // array with all the colors for HIGHLIGHTED rules in this chunk

    // mix colors for background mesh when more than 1 rule is represented in chunk
    let bgColor = usableColors[0]

    for (let i = 1; i < usableColors.length; i += 1) {
      bgColor = bgColor.mix(usableColors[i])
    }

    // same as before, but for highlight colors
    let highlightColor = highlightedColors[0]

    for (let i = 1; i < highlightedColors.length; i += 1) {
      highlightColor = highlightColor.mix(highlightedColors[i])
    }

    // detects if any rule in this chunk is highlighted
    const highlighted =
      parsedRule.rules.filter((rule) => rule.highlighted || rule.selected).length > 0

    return (
      <span
        className={c(
          styles.highlightRule,
          parsedRule.rules.map((rule) => rule.highlightElementId)
        )}
        onFocus={() => {
          parsedRule.rules.forEach((rule) => rule.highlight())
        }}
        onBlur={() => {
          parsedRule.rules.forEach((rule) => rule.highlight())
        }}
        style={{ background: highlighted ? highlightColor.string() : bgColor.fade(0.8).string() }}
        onMouseOver={() => {
          parsedRule.rules.forEach((rule) => rule.highlight())
        }}
        onMouseOut={() => parsedRule.rules.forEach((rule) => rule.dim())}
        onClick={() => {
          handleSelectRule(parsedRule.rules)
        }}
        role="button"
        tabIndex="0"
      >
        {parsedRule.text}
      </span>
    )
  }

  return <>{parsedRule.text}</>
})

const getSelectedText = (containerRef) => {
  let start = 0
  let end = 0
  let text = ''
  let selection = null
  let position = null

  if (typeof window.getSelection !== 'undefined') {
    selection = window.getSelection()
    if (selection.anchorNode) {
      const range = window.getSelection().getRangeAt(0)

      // if startContainer and commonAncestorContainer are different, then user has selected multiple dom elements
      if (range.startContainer === range.commonAncestorContainer) {
        const rects = range.getClientRects()
        start = range.startOffset
        end = range.endOffset
        text = window.getSelection().toString()
        const containerRect = containerRef.getBoundingClientRect()
        position = {
          sx: rects[0].x - containerRect.left,
          sy: rects[0].y - containerRect.top + rects[0].height / 2,
          ex: rects[rects.length - 1].x - containerRect.left + rects[rects.length - 1].width,
          ey: rects[rects.length - 1].y - containerRect.top + rects[rects.length - 1].height / 2,
        }
      }
    }
  }

  return { start, end, text, position }
}

const RulesTextHighlighter = ({
  text,
  selectionMode,
  parsedRules,
  unSelectAllRules,
  handleSelectRule,
  id,
  onAdd,
}) => {
  const { t } = useTranslation('rulesCreation')

  let overlaysText = text
  const [floatingButtonStyle, setFloatingButtonStyle] = useState({})
  const [visibleButton, setVisibleButton] = useState(false)

  const [selectedText, setSelectedText] = useState({})

  const [selecting, setSelecting] = useState(false)
  const [event, setEvent] = useState(false)
  const selectionText = useRef(null)

  if (parsedRules.length > 0) {
    overlaysText = parsedRules.map((parsedRule, i) => {
      const key = `chunk_${i}`

      return (
        <OverlayText
          key={key}
          parsedRule={parsedRule}
          unSelectAllRules={unSelectAllRules}
          handleSelectRule={handleSelectRule}
        />
      )
    })
  }

  const globalMouseUp = (e) => {
    setVisibleButton(false)
    setEvent(e.offsetY)
  }

  useEffect(() => {
    document.addEventListener('mouseup', globalMouseUp)
    return () => {
      document.removeEventListener('mouseup', globalMouseUp)
    }
  }, [])

  const handleMouseDown = () => {
    setEvent(null)
    setSelecting(true)
  }

  const selectText = (offsetY) => {
    setSelecting(false)
    setEvent(false)
    setTimeout(() => {
      const { position, text: sText, start, end } = getSelectedText(selectionText.current)

      if (sText && text.includes(sText)) {
        setSelectedText({ text: sText, start, end })

        const isStart = Math.abs(offsetY - position.sy) < Math.abs(offsetY - position.ey)

        const X = isStart ? position.sx - ADD_BUTTON_WIDTH - 5 : position.ex + 5
        const Y = isStart
          ? position.sy - ADD_BUTTON_HEIGHT / 2
          : position.ey - ADD_BUTTON_HEIGHT / 2

        setVisibleButton(false)
        setTimeout(() => {
          setFloatingButtonStyle({
            top: Y,
            left: X,
            transform: isStart && 'rotate(180deg)',
          })

          setVisibleButton(true)
        }, 200)
      } else {
        setVisibleButton(false)
      }
    })
  }

  useEffect(() => {
    if (selecting && event) {
      selectText(event)
    }
  }, [selecting, event])

  const onAddRule = () => {
    if (onAdd) onAdd(selectedText)
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div tabIndex="0" className={styles.highlightContainer}>
      <div className={c(styles.floatingText, selectionMode && styles.floatingTextShow)}>
        {t('rulesCreation:floatingMessage')}
      </div>
      <div
        className={c(styles.floatingButton, visibleButton && styles.visible)}
        style={{
          height: ADD_BUTTON_HEIGHT,
          width: ADD_BUTTON_WIDTH,
          ...floatingButtonStyle,
        }}
        onClick={onAddRule}
        role="button"
        tabIndex="0"
      >
        <img src={buttonBackground} alt="Add" className={styles.floatingButtonBackground} />
        <Icon name="PlusIcon" size={24} className={styles.floatingButtonIcon} />
      </div>
      <div
        ref={selectionText}
        className={c(
          styles.highlighter,
          styles.selectionText,
          selectionMode && styles.selectionMode
        )}
        id={id}
        onMouseDown={handleMouseDown}
        role="button"
        tabIndex="0"
      >
        {text}
      </div>
      <div
        className={c(
          styles.highlighter,
          styles.rangeSelectionHighlight,
          selectionMode && styles.rangerSelectionSelectionMode
        )}
      >
        {overlaysText}
      </div>
    </div>
  )
}

RulesTextHighlighter.propTypes = {
  text: PropTypes.string.isRequired,
  unSelectAllRules: PropTypes.func.isRequired,
  handleSelectRule: PropTypes.func.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  parsedRules: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string,
  selectionMode: PropTypes.bool,
  onAdd: PropTypes.func,
}

RulesTextHighlighter.defaultProps = {
  selectionMode: false,
  parsedRules: [],
  id: '',
  onAdd: null,
}

export default RulesTextHighlighter
