import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class UsersService {
  validateToken = (id, token) =>
    axios
      .get(`${API_URL}/tenant/auth/activate/validate?token=${token}&id=${id}`)
      .then((response) => response.data.data)

  activate = (id, token, password, passwordConfirmation) =>
    axios
      .post(`${API_URL}/tenant/auth/activate/password?token=${token}&id=${id}`, {
        password,
        password_confirmation: passwordConfirmation,
      })
      .then((response) => response.data.data)
}

export default UsersService
