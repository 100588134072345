import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import TransactionItem from 'presentation/TransactionItem'

const TransactionsList = ({
  transactionsList,
  handleRejectTransaction,
  handleApproveTransaction,
  handleOpenViewTransaction,
  handleModalDeleteTransaction,
}) => {
  return transactionsList.map((transaction) => (
    <TransactionItem
      transaction={transaction}
      key={`transaction__${transaction.id}`}
      handleRejectTransaction={handleRejectTransaction}
      handleApproveTransaction={handleApproveTransaction}
      handleOpenViewTransaction={handleOpenViewTransaction}
      handleModalDeleteTransaction={handleModalDeleteTransaction}
    />
  ))
}

TransactionsList.propTypes = {
  handleRejectTransaction: PropTypes.func.isRequired,
  handleApproveTransaction: PropTypes.func.isRequired,
  handleOpenViewTransaction: PropTypes.func.isRequired,
  handleModalDeleteTransaction: PropTypes.func.isRequired,
  transactionsList: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default observer(TransactionsList)
