import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from 'presentation/Button'
import RuleGlobalView from '../RuleGlobalView'
import NoResultsList from '../NoResultsList'
import styles from './globalView.module.scss'

const GlobalView = ({ rules, assets, beneficiaries, handleRollBack }) => {
  const { t } = useTranslation('rulesCreation')

  return (
    <div className={styles.globalViewContainer}>
      {rules.length ? (
        <>
          <>
            {rules.map((rule, i) => (
              <RuleGlobalView
                // eslint-disable-next-line react/no-array-index-key
                key={`globalView_${i}`}
                trustAssets={assets}
                trustBeneficiaries={beneficiaries}
                rule={rule}
                globalViewMode
              />
            ))}
          </>
          <div className={styles.rollBackButton}>
            <Button label={t('ok')} onClick={handleRollBack} secondary small />
          </div>
        </>
      ) : (
        <NoResultsList
          showIcon={false}
          topText={rules ? t('noFilteredRules') : t('noRulesCreated')}
        />
      )}
    </div>
  )
}

GlobalView.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  rules: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  assets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  beneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleRollBack: PropTypes.func.isRequired,
}

export default GlobalView
