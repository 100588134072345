import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import c from 'classnames'
import PropTypes from 'prop-types'
import defaultProfile from 'presentation/NavBarComponents/images/defaultProfile.png'
import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon'
import { VIEW_SETTINGS } from 'stores/AuthStore'
import CogOutlineIcon from 'mdi-react/CogOutlineIcon'
import AccountIcon from 'mdi-react/AccountIcon'
import ToolTip from 'presentation/ToolTip'
import Gated from 'util/Gated'
import StoresContext from 'providers/storeContext'
import styles from './navBarProfileMenu.module.scss'

const NavBarProfileMenu = ({ handleProfile, handleLogout, handleSettings }) => {
  const { t } = useTranslation('navBar')
  const { authStore } = useContext(StoresContext)

  const { profileImage, userName } = authStore.authUser
  const [visibleProfileMenu, setVisibleProfileMenu] = useState(false)

  const handleClickProfileMenu = useCallback(() => {
    setVisibleProfileMenu(!visibleProfileMenu)
  }, [visibleProfileMenu])

  const handleHideProfileMenu = useCallback(() => {
    setTimeout(() => {
      setVisibleProfileMenu(false)
    }, 500)
  }, [visibleProfileMenu])

  const ButtonsMenu = () => {
    return (
      <div onMouseLeave={handleHideProfileMenu} className={styles.buttonsMenuContainer}>
        <div onClick={handleProfile} className={styles.menuOption} role="button" tabIndex="0">
          <span className={styles.menuIcon}>
            <AccountIcon size={18} />
          </span>
          {t('profile')}
        </div>
        <Gated permission={VIEW_SETTINGS}>
          <div onClick={handleSettings} className={styles.menuOption} role="button" tabIndex="0">
            <span className={styles.menuIcon}>
              <CogOutlineIcon size={18} />
            </span>
            {t('settings')}
          </div>
        </Gated>
        <div
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleLogout()
          }}
          className={styles.menuOption}
          role="button"
          tabIndex="0"
        >
          <span className={styles.menuIcon}>
            <LogoutVariantIcon size={18} />
          </span>
          {t('logout')}
        </div>
      </div>
    )
  }

  return (
    <div>
      <ToolTip
        interactive
        className={styles.buttonsMenu}
        arrow
        tooltipContent={<ButtonsMenu />}
        visible={visibleProfileMenu}
        hideOnClick={false}
      >
        <div
          onClick={handleClickProfileMenu}
          className={c(styles.avatar, visibleProfileMenu && styles.avatarMenu)}
          title={userName || t('pageTitles:siteName')}
          style={{ backgroundImage: `url(${profileImage || defaultProfile})` }}
          role="button"
          tabIndex="0"
          aria-label="tooltip"
        />
      </ToolTip>
    </div>
  )
}

NavBarProfileMenu.propTypes = {
  handleProfile: PropTypes.func.isRequired,
  handleSettings: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
}

export default observer(NavBarProfileMenu)
