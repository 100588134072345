import React from 'react'
import PropTypes from 'prop-types'
import AlertIcon from 'mdi-react/AlertIcon'
import styles from './firstLevelError.module.scss'

const parseDescription = (description) => {
  const parsed = description.replace('field', 'section').replace('1 items', '1 item')

  return parsed.charAt(0).toUpperCase() + parsed.slice(1)
}

const FirstLevelError = ({ description }) => (
  <div className={styles.firstLevelError}>
    <AlertIcon size={20} className={styles.alertIcon} />
    <span className={styles.text}>{parseDescription(description)}</span>
  </div>
)

FirstLevelError.propTypes = {
  description: PropTypes.string.isRequired,
}

export default FirstLevelError
