import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import LetterBaseStore from 'stores/LettersBaseStore'
import LettersSectionBaseStore from 'stores/LettersSectionBaseStore'
import LetterArchiveStore from 'stores/LetterArchiveStore'
import EntityStatus from 'models/EntityStatus'
import LetterFormSection from '../LetterFormSection'
import LetterVersions from '../LetterVersions'
import LetterHistory from '../LetterHistory'

/* eslint-disable class-methods-use-this */
class LetterMainSection extends React.Component {
  handleViewHistory = () => {
    const { mainSectionStore } = this.props

    mainSectionStore.viewHistory()
  }

  handleViewVersions = () => {
    const { mainSectionStore } = this.props

    mainSectionStore.viewVersions()
  }

  handleViewForm = () => {
    const { mainSectionStore } = this.props

    mainSectionStore.viewForm()
  }

  render() {
    const {
      mainStore,
      mainSectionStore,
      handleSaveEntity,
      isViewMode,
      mainEntityStatus,
      mainEntityHasAlternativeStatus,
      letterTypeName,
      letterArchiveStore,
      mainSectionCanBeRevoked,
      showRulesAlert,
      mainFirstLevelError,
      validationHandler,
    } = this.props

    if (mainSectionStore.formActive) {
      return (
        <LetterFormSection
          mainStore={mainStore}
          handleViewHistory={this.handleViewHistory}
          handleViewVersions={this.handleViewVersions}
          mainSectionStore={mainSectionStore}
          handleSaveEntity={handleSaveEntity}
          isViewMode={isViewMode}
          mainFirstLevelError={mainFirstLevelError}
          mainEntityStatus={mainEntityStatus}
          mainEntityHasAlternativeStatus={mainEntityHasAlternativeStatus}
          letterTypeName={letterTypeName}
          mainSectionCanBeRevoked={mainSectionCanBeRevoked}
          showRulesAlert={showRulesAlert}
          validationHandler={validationHandler}
        />
      )
    }

    if (mainSectionStore.historyActive) {
      return (
        <LetterHistory
          mainStore={mainStore}
          handleViewForm={this.handleViewForm}
          isViewMode={isViewMode}
        />
      )
    }

    if (mainSectionStore.versionsActive) {
      return (
        <LetterVersions
          letterArchiveStore={letterArchiveStore}
          handleViewForm={this.handleViewForm}
          letterTypeName={letterTypeName}
        />
      )
    }

    return null
  }
}

LetterMainSection.propTypes = {
  mainStore: PropTypes.instanceOf(LetterBaseStore).isRequired,
  mainSectionStore: PropTypes.instanceOf(LettersSectionBaseStore).isRequired,
  handleSaveEntity: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool.isRequired,
  mainEntityStatus: PropTypes.instanceOf(EntityStatus).isRequired,
  letterArchiveStore: PropTypes.instanceOf(LetterArchiveStore).isRequired,
  mainEntityHasAlternativeStatus: PropTypes.bool,
  letterTypeName: PropTypes.string.isRequired,
  mainSectionCanBeRevoked: PropTypes.bool,
  showRulesAlert: PropTypes.bool,
  mainFirstLevelError: PropTypes.bool,
  validationHandler: PropTypes.func.isRequired,
}

LetterMainSection.defaultProps = {
  mainEntityHasAlternativeStatus: false,
  mainSectionCanBeRevoked: true,
  mainFirstLevelError: false,
  showRulesAlert: true,
}

export default observer(LetterMainSection)
