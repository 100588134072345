import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { successToast } from 'helpers/Toast'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'
import CreationInput from 'presentation/CreationInput'
import PermissionsSelector from 'presentation/PermissionsSelector'
import RolesCreationStore from 'stores/RolesCreationStore'
import ServerError from 'presentation/ServerError'
import LoadingRing from 'presentation/LoadingRing'
import AlertIcon from 'mdi-react/AlertIcon'
import { useHistory, useLocation } from 'react-router'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import DeleteRoleErrors from 'presentation/DeleteRoleErrors'
import Modal from 'scenes/Modal'
import RequestErrors from 'presentation/RequestErrors'
import RoleSectionError from 'presentation/RoleSectionError'
import RoleCreationActionButtons from 'presentation/RoleCreationActionButtons'
import ExpandableButton from 'presentation/ExpandableButton'
import { ROLES_LIST, ROLES_CREATION } from 'routing/routes'
import RoleCreationTitle from './RoleCreationTitle'
import styles from './rolesCreation.module.scss'

const RolesCreation = () => {
  const [rolesCreationStore] = useState(() => new RolesCreationStore())
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleErrorsModal, setVisibleErrorsModal] = useState(false)
  const { t } = useTranslation('rolesCreation')
  const location = useLocation()
  const history = useHistory()

  const handleChangeName = (e) => {
    rolesCreationStore.changeName(e.target.value)
  }

  const handleAddPermissions = (permissions) => {
    rolesCreationStore.addSelectedPermissions(permissions)
  }

  const handleRemovePermissions = (permissions) => {
    rolesCreationStore.removeSelectedPermissions(permissions)
  }

  const goToList = () => {
    history.push(ROLES_LIST)
  }

  const handleSave = () => {
    rolesCreationStore.save(() => {
      successToast(t('rolesSave'), null, 'savedTrust').show()
      goToList()
    })
  }

  const handleEdit = () => {
    rolesCreationStore.removeViewMode()
    rolesCreationStore.removeIsDirty()
  }

  const loadEditData = () => {
    const params = new URLSearchParams(location.search)

    if (params.get('id')) {
      rolesCreationStore.loadRole(params.get('id'))
      rolesCreationStore.setViewMode()
    }
  }

  const handleCancel = () => {
    if (rolesCreationStore.isEdition) {
      loadEditData()
      rolesCreationStore.clearRequestErrors()
      return
    }

    goToList()
  }

  const onSuccessErrorModal = () => {
    setVisibleDeleteModal(false)
  }

  const handleGoBack = () => {
    goToList()
  }

  const handleTryAgain = () => {
    rolesCreationStore.tryAgain()
  }

  const openErrorsModal = () => {
    setVisibleErrorsModal(true)
  }

  const handleDelete = () => {
    setVisibleDeleteModal(true)
  }

  const onSuccessDelete = () => {
    setVisibleDeleteModal(false)

    rolesCreationStore.delete(
      () => {
        history.push(ROLES_LIST)
        successToast(t('roleDeleted'), null, 'roleDeleted').show()
      },
      () => openErrorsModal()
    )
  }

  const onCancelDelete = () => {
    setVisibleDeleteModal(false)
  }

  useEffect(() => {
    rolesCreationStore.fillPermissions()
    loadEditData()
  }, [])

  const { isLoading, isViewMode, errorLoadEdit, serverError } = rolesCreationStore

  if (serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  if (errorLoadEdit) {
    return <RoleSectionError />
  }

  return (
    <>
      <RoleCreationTitle
        isViewMode={rolesCreationStore.isViewMode}
        isEdition={rolesCreationStore.isEdition}
        name={rolesCreationStore.roleName.value}
      />
      <Prompt
        when={rolesCreationStore.isDirty}
        message={(loc) => {
          if (loc.pathname.search(ROLES_CREATION) < 0 || loc.pathname === ROLES_CREATION) {
            return t('leavePagePrompt')
          }

          return true
        }}
      />
      {isLoading ? (
        <LoadingRing center />
      ) : (
        <>
          <div className={styles.container}>
            <CreationInput
              inputStore={rolesCreationStore.roleName}
              placeholder={t('roleNamePlaceholder')}
              onChange={handleChangeName}
              label={t('roleNameLabel')}
              literal={isViewMode}
            />
            <PermissionsSelector
              selectedPermissions={rolesCreationStore.selectedPermissions}
              allPermissions={rolesCreationStore.visibleAllPermissions}
              handleRemovePermissions={handleRemovePermissions}
              errorMsg={rolesCreationStore.permissionsErrorMsg}
              handleAddPermissions={handleAddPermissions}
              isLoading={rolesCreationStore.isLoading}
              literal={isViewMode}
            />
          </div>
          <RequestErrors errors={rolesCreationStore.requestErrors} />
          {rolesCreationStore.editable && !rolesCreationStore.viewMode && (
            <div className={styles.deleteContainer}>
              <ExpandableButton
                className={styles.deleteButton}
                onClick={handleDelete}
                label={t('common:delete')}
                Icon={<TrashCanOutlineIcon size={20} />}
                big
              />
              {rolesCreationStore.deleteErrors.length > 0 && (
                <AlertIcon className={styles.alertIcon} onClick={openErrorsModal} size={20} />
              )}
            </div>
          )}
          <RoleCreationActionButtons
            editable={rolesCreationStore.editable}
            rolesCreationStore={rolesCreationStore}
            handleGoBack={handleGoBack}
            handleCancel={handleCancel}
            handleSave={handleSave}
            handleEdit={handleEdit}
          />
        </>
      )}
      <Modal
        name="deleteRoleModal"
        open={visibleDeleteModal}
        title={t('deleteModalTitle')}
        onSuccess={onSuccessDelete}
        onCancel={onCancelDelete}
      />
      <Modal
        name="errorsModal"
        open={visibleErrorsModal}
        title={t('errorModalTitle')}
        okOnly
        onSuccess={onSuccessErrorModal}
      >
        <DeleteRoleErrors errors={rolesCreationStore.deleteErrors} />
      </Modal>
    </>
  )
}

export default observer(RolesCreation)
