import { successToast } from 'helpers/Toast'

// displays the success toast
const showToast = (msg) => successToast(msg, null, 'changedTrustStatus').show()

// redirects to main section
const redirectToMainSection = (baseRedirectRoute, sections, id, history) => {
  // gets initial route for the client's form
  const initialRedirectRoute = `${baseRedirectRoute}${sections[0].route}`

  history.push(`${initialRedirectRoute}?id=${id}`)
}

// coordinates toast, redirection and reload data
const successStatusChange = (clientsCreationStore, toastText, history, redirectRoute) => {
  showToast(toastText)
  redirectToMainSection(
    redirectRoute,
    clientsCreationStore.entitySections,
    clientsCreationStore.idEdition,
    history
  )
  clientsCreationStore.reloadData()
}

// changes client status to active
const changeClientStatusActive = (clientsCreationStore, toastText, history, redirectRoute) => {
  clientsCreationStore.activate(() =>
    successStatusChange(clientsCreationStore, toastText, history, redirectRoute)
  )
}

// changes client status to inactive
const changeClientStatusInactive = (clientsCreationStore, toastText, history, redirectRoute) => {
  clientsCreationStore.inactivate(() =>
    successStatusChange(clientsCreationStore, toastText, history, redirectRoute)
  )
}

export { changeClientStatusActive, changeClientStatusInactive }
