import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { RuleAsset } from 'models/Rule'
import BeneficiarySelection from './BeneficiarySelection'

const BeneficiariesSelection = observer(({ beneficiaries, ruleAsset }) => {
  const value = () => {
    return ruleAsset.parsedPercentage
  }

  if (ruleAsset.assetId) {
    return ruleAsset.beneficiaries.map((beneficiary, i) => (
      <BeneficiarySelection
        options={beneficiary.getOptions(ruleAsset.selectedBeneficiaries, beneficiaries)}
        beneficiary={beneficiary.beneficiaryId !== null ? beneficiary : null}
        key={`benefselection_${beneficiary.id}`}
        handleChangeBeneficiary={(option) => {
          // eslint-disable-next-line
          beneficiary.setBeneficiaryId(option.id, value, option.contactId)
          ruleAsset.validate()
        }}
        showAddButton={i === ruleAsset.beneficiaries.length - 1}
        handleAddBeneficiary={() => ruleAsset.addEmptyBeneficiary()}
        handleDeleteBeneficiary={() => ruleAsset.removeBeneficiary(beneficiary)}
        error={!!beneficiary.error}
        handleChangeValue={() => ruleAsset.validate()}
      />
    ))
  }

  return null
})

BeneficiariesSelection.propTypes = {
  ruleAsset: PropTypes.instanceOf(RuleAsset).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  beneficiaryOptions: PropTypes.oneOfType([PropTypes.array]),
}

BeneficiariesSelection.defaultProps = {
  beneficiaryOptions: [],
}

export default BeneficiariesSelection
