import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class TermsAndConditionsService {
  getTermsAndConditions = (termsId) => {
    return axios.get(`${API_URL}/legals/${termsId}`).then((response) => {
      return response.data.data
    })
  }

  acceptTermsAndConditions = (termsId) => {
    return axios.post(`${API_URL}/legals`, { version: termsId }).then((response) => {
      return response.data.data
    })
  }
}

export default TermsAndConditionsService
