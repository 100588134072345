import { action, observable, computed, runInAction, makeObservable } from 'mobx'
import moment from 'moment'
import i18next from 'i18next'
import File from 'models/File'
import { LetterVersion } from 'models/TrustDocumentVersion'
import InputStore from 'stores/InputStore'
import AsyncStore from 'stores/AsyncStore'
import LettersArchiveService from 'services/LettersArchiveService'

const STATUS_DRAFT = 'draft'
const STATUS_ACTIVE = 'stored'
const STATUS_PENDING_TO_APPROVAL = 'pending'
const STATUS_EXECUTION = 'execution'
const STATUS_EXECUTED = 'executed'
const STATUS_DELETED = 'deleted'
const STATUS_REVOKED = 'revoked'

class Letter extends AsyncStore {
  type = null
  historyList = []
  id = null
  status = null
  title = new InputStore()
  text = new InputStore()
  file = new File()
  lastUpdate = null
  updatedByFirstName = null
  updatedByLastName = null
  errorsCount = 0

  constructor(type) {
    super()

    this.type = type
    this.lettersArchiveService = new LettersArchiveService()

    makeObservable(this, {
      // observables
      type: observable,
      historyList: observable,
      id: observable,
      status: observable,
      title: observable,
      text: observable,
      file: observable,
      lastUpdate: observable,
      updatedByFirstName: observable,
      updatedByLastName: observable,
      errorsCount: observable,
      // actions
      reset: action,
      changeTitle: action,
      changeText: action,
      changeId: action,
      changeStatus: action,
      setDraft: action,
      delete: action,
      revoke: action,
      activate: action,
      newVersion: action,
      clearFile: action,
      changeUpdated: action,
      loadHistory: action,
      validateEmptyTitle: action,
      validateEmptyText: action,
      validateEmptyFields: action,
      // computeds
      isDraft: computed,
      isExecution: computed,
      isExecuted: computed,
      isActive: computed,
      history: computed,
    })

    this.reset()
  }

  reset() {
    this.id = null
    this.title = new InputStore()
    this.text = new InputStore()
    this.file = new File()
    this.status = STATUS_DRAFT
    this.errorsCount = 0
  }

  changeTitle(title) {
    this.title.setValue(title)
  }

  changeText(text) {
    this.text.setValue(text)
  }

  changeId(id) {
    this.id = id
  }

  changeStatus(status) {
    this.status = status
  }

  setDraft() {
    this.status = STATUS_DRAFT
  }

  delete() {
    this.status = STATUS_DELETED
  }

  revoke() {
    this.status = STATUS_REVOKED
  }

  activate() {
    this.status = STATUS_ACTIVE
  }

  newVersion() {
    this.reset()
  }

  clearFile() {
    this.file = new File()
    this.signedFile = new File()
  }

  changeUpdated({ at = null, firstName = null, lastName = null }) {
    this.lastUpdate = at !== null ? moment(at) : null
    this.updatedByFirstName = firstName
    this.updatedByLastName = lastName
  }

  get isDraft() {
    return this.status === STATUS_DRAFT || this.isExecution || this.isExecuted
  }

  get isExecution() {
    return this.status === STATUS_EXECUTION
  }

  get isExecuted() {
    return this.status === STATUS_EXECUTED
  }

  get isActive() {
    return this.status === STATUS_PENDING_TO_APPROVAL || this.status === STATUS_ACTIVE
  }

  getJson() {
    return {
      id: this.id,
      title: this.title.value,
      text: this.text.value,
      file: this.file.getJson(),
      status: this.status,
    }
  }

  async loadHistory() {
    if (this.historyList.length === 0) {
      this.preRequest()

      try {
        const response = await this.lettersArchiveService.loadHistory(this.id, this.type)

        runInAction(() => {
          response.map((letterVersion) => {
            const historyVersion = LetterVersion.fromJson(letterVersion)

            historyVersion.setType(this.type)
            historyVersion.setParentId(this.id)

            this.historyList.push(historyVersion)
            return null
          })

          this.onSuccessRequest()
        })
      } catch (e) {
        runInAction(() => {
          this.onErrorRequest(e)
        })
      }
    }
  }

  get history() {
    return this.historyList
  }

  validateEmptyTitle() {
    this.errorsCount = 0

    if (this.title.value === null || this.title.value.trim() === '') {
      this.title.setError(true, i18next.t('validation:requiredField'))

      this.errorsCount += 1
    }

    return this.errorsCount
  }

  validateEmptyText() {
    this.errorsCount = 0

    if (this.text.value === null || this.text.value.trim() === '') {
      this.text.setError(true, i18next.t('validation:requiredField'))
      this.errorsCount += 1
    }

    return this.errorsCount
  }

  validateEmptyFields() {
    this.errorsCount = this.validateEmptyTitle() + this.validateEmptyText()

    return this.errorsCount
  }
}

export default Letter
