import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import ListTrust from 'models/ListTrust'
import UpdateIcon from 'mdi-react/UpdateIcon'
import StatusName from 'presentation/StatusName'
import TableItemDate from 'presentation/TableItemDate'
import FiduciaryStructureActionsButtonList from 'presentation/FiduciaryStructureActionsButtonList'
import TrustTableItemSettlorName from './TrustTableItemSettlorName'
import styles from './trustsTableItem.module.scss'

const FiduciaryStructuresTableItem = ({
  listItem,
  link,
  view,
  isGrantor,
  handleExpenseLink,
  handleTransactionsLink,
  handleValuationChange,
  handlePermissionLink,
  handleValuationsLink,
  handleHistoryLink,
  handleContentChangeLink,
  hasActions,
  handleRequestZip,
  requestedZip,
  isTransaction,
}) => {
  return (
    <NavLink
      className={[
        !hasActions && styles.noActions,
        styles.trustTableItem,
        styles[`trustTableItem--${view}`],
        isTransaction ? styles.isNotClickable : styles.isClickable,
      ].join(' ')}
      to={link}
    >
      <div className={styles.trustTableItemName} title={listItem.name}>
        {listItem.name !== '' && `${listItem.name}`}
      </div>
      <div className={styles.trustTableItemSettlorName}>
        <TrustTableItemSettlorName
          listItem={listItem}
          settlors={listItem.settlors}
          view={view}
          isGrantor={isGrantor}
        />
      </div>
      <div className={styles.trustTableItemCreatedAt}>
        <TableItemDate listDate={listItem.created} view={view} icon={<UpdateIcon size={12} />} />
      </div>
      <div className={styles.trustTableItemUpdatedAt}>
        <TableItemDate listDate={listItem.updated} view={view} icon={<UpdateIcon size={12} />} />
      </div>
      <div className={styles.trustTableItemStatus}>
        <StatusName status={listItem.status} alternativeStatus={listItem.alternativeStatus} />
      </div>
      {hasActions && (
        <FiduciaryStructureActionsButtonList
          listItem={listItem}
          view={view}
          handleExpenseLink={handleExpenseLink}
          handleTransactionsLink={handleTransactionsLink}
          handleValuationChange={handleValuationChange}
          handlePermissionLink={handlePermissionLink}
          handleValuationsLink={handleValuationsLink}
          handleHistoryLink={handleHistoryLink}
          handleContentChangeLink={handleContentChangeLink}
          handleRequestZip={handleRequestZip}
          requestedZip={requestedZip}
          isTransaction={isTransaction}
        />
      )}
    </NavLink>
  )
}

FiduciaryStructuresTableItem.propTypes = {
  listItem: PropTypes.instanceOf(ListTrust).isRequired,
  handleExpenseLink: PropTypes.func,
  handleTransactionsLink: PropTypes.func,
  handlePermissionLink: PropTypes.func,
  handleValuationsLink: PropTypes.func,
  handleRequestZip: PropTypes.func,
  handleHistoryLink: PropTypes.func,
  handleContentChangeLink: PropTypes.func,
  handleValuationChange: PropTypes.func,
  hasActions: PropTypes.bool.isRequired,
  link: PropTypes.string,
  view: PropTypes.string.isRequired,
  requestedZip: PropTypes.bool,
  isGrantor: PropTypes.bool,
  isTransaction: PropTypes.bool,
}

FiduciaryStructuresTableItem.defaultProps = {
  handleExpenseLink: () => {},
  handleTransactionsLink: () => {},
  handlePermissionLink: () => {},
  handleValuationsLink: () => {},
  handleValuationChange: () => {},
  handleRequestZip: () => {},
  handleHistoryLink: () => {},
  handleContentChangeLink: () => {},
  requestedZip: false,
  isGrantor: false,
  isTransaction: false,
  link: null,
}

export default FiduciaryStructuresTableItem
