import { useState, useContext } from 'react'
import { warningToast } from 'helpers/Toast'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import c from 'classnames'
import PlusIcon from 'mdi-react/PlusIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import File from 'models/File'
import FormLabel from 'presentation/FormLabel'
import Modal from 'scenes/Modal'
import StoresContext from 'providers/storeContext'
import DownloadFile from './DownloadFile'
import DownloadFileLink from './DownloadFileLink'
import styles from './inputFile.module.scss'

const getFile = (e) => {
  e.stopPropagation()
  e.preventDefault()

  return e.target.files[0]
}

const InputFile = ({
  label,
  placeholder,
  onChange,
  fileStore,
  literal,
  handleStartUploadingFile,
  handleFinishUploadingFile,
  onBeforeDeletion,
}) => {
  const { t } = useTranslation('dynamicForms')
  const { settingsStore, inputFileStore } = useContext(StoresContext)
  const [errorModal, setErrorModal] = useState(false)
  const [errorData, setErrorData] = useState({ title: '', content: '' })

  const handleSetErrorModal = (v) => setErrorModal(v)
  const handleSetErrorData = (v) => setErrorData(v)

  if (literal) {
    return <DownloadFile fileStore={fileStore} label={label} />
  }

  return (
    <label>
      {label && <FormLabel label={label} />}
      <div className={styles.inputContainer}>
        <input
          type="file"
          className={styles.inputFileHidden}
          ref={(ele) => {
            inputFileStore.eleFileInput = ele
          }}
          onChange={(e) => {
            if (!inputFileStore.canUpload) {
              inputFileStore.setCanUpload()

              const file = getFile(e)

              if (!file) {
                inputFileStore.setCanNotUpload()

                return
              }

              const fileSizeMB = file.size / 1024 / 1024

              if (fileSizeMB > settingsStore.maxUploadSize) {
                fileStore.changeName(null)

                handleSetErrorData({
                  title: t('attention'),
                  content: t('fileSizeError', { maxMb: settingsStore.maxUploadSize }),
                })

                handleSetErrorModal(true)
                if (inputFileStore.eleFileInput) {
                  inputFileStore.eleFileInput.value = ''
                }

                inputFileStore.setCanNotUpload()
                return
              }

              // Setea el nombre del archivo
              fileStore.changeName(file.name)

              // Progress Bar value
              fileStore.setLoaderValue(0)

              const uploadProgress = (progressEvent) => {
                fileStore.setLoaderValue((progressEvent.loaded * 100) / progressEvent.total)
              }

              if (handleStartUploadingFile !== null) {
                handleStartUploadingFile()

                inputFileStore.setCanUpload()
              }

              // Envia el archivo al backend
              fileStore
                .upload(file, uploadProgress)
                .then(() => {
                  fileStore.setLoaderValue(null)

                  if (handleFinishUploadingFile !== null) {
                    handleFinishUploadingFile()
                    inputFileStore.setCanNotUpload()
                  }

                  if (onChange !== null) {
                    onChange(fileStore)
                    inputFileStore.setCanUpload()
                  }
                  inputFileStore.setCanNotUpload()
                })
                .catch((error) => {
                  inputFileStore.eleFileInput.value = ''

                  if (!error.response) {
                    handleSetErrorData({
                      title: t('common:attention'),
                      content: t('common:genericError'),
                    })
                  } else {
                    switch (error.response.status) {
                      case 400:
                        handleSetErrorData({
                          title: t('common:attention'),
                          content: error.response.data.error.errors[0],
                        })
                        break
                      case 413:
                        handleSetErrorData({
                          title: t('common:attention'),
                          content: t('common:fileSizeError', {
                            maxMb: settingsStore.maxUploadSize,
                          }),
                        })
                        break
                      case 422:
                        handleSetErrorData({
                          title: 'Error',
                          content: (
                            <div>
                              {Object.values(error.response.data.error.fields).map((el) => (
                                <div>{el}</div>
                              ))}
                            </div>
                          ),
                        })
                        break
                      default:
                        handleSetErrorData({
                          title: t('common:attention'),
                          content: t('common:genericError'),
                        })
                    }
                  }
                  fileStore.changeName(null)
                  handleSetErrorModal(true)
                })
            } else {
              warningToast(t('warningUpload'), null, 'warningUpload').show()
            }
          }}
        />
        <input
          style={(() => {
            if (fileStore.isUploading) {
              const progressStyle = {
                backgroundImage:
                  'linear-gradient(to right, var(--primary-btn-main-color-1), var(--primary-btn-main-color-2))',
                backgroundRepeat: 'no-repeat',
              }

              if (fileStore.loaderValue) {
                return Object.assign(progressStyle, {
                  backgroundSize: `${fileStore.loaderValue}% 100%`,
                })
              }

              return Object.assign(progressStyle, {
                backgroundSize: `0% 100%`,
              })
            }

            return null
          })()}
          className={c(styles.input, fileStore.error && styles.error)}
          placeholder={placeholder}
          readOnly
          onClick={() => {
            if (!inputFileStore.canUpload) {
              inputFileStore.setCanNotUpload()
            }
          }}
          value={fileStore.name !== null ? fileStore.name : ''}
        />
        {fileStore.name === null || fileStore.name === '' ? (
          <PlusIcon className={styles.fileIcon} />
        ) : (
          <>
            {fileStore.isUploading === false ? (
              <div className={styles.fileIcons}>
                <CloseIcon
                  size={20}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    if (onBeforeDeletion !== null) {
                      onBeforeDeletion()
                    }

                    fileStore.removeFile()

                    inputFileStore.eleFileInput.value = '' // Vacie el input file

                    if (onChange !== null) {
                      onChange(null)
                    }

                    inputFileStore.setCanNotUpload()
                  }}
                />
                <DownloadFileLink fileStore={fileStore} />
              </div>
            ) : (
              <div className={styles.fileIcons}>
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    fileStore.cancelUpload()

                    if (onChange !== null) {
                      onChange(null)
                    }

                    inputFileStore.setCanNotUpload()
                  }}
                />
                <div className={styles.loadingRing}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {fileStore && fileStore.error && (
        <div className={c(styles.errorInput, literal && styles.errorLiteralInput)}>
          {t(fileStore.errorMessage)}
        </div>
      )}
      <Modal
        name="inputErrorModal"
        title={errorData.title}
        open={errorModal}
        onSuccess={() => {
          handleSetErrorModal(false)
        }}
        closeOutside
        okOnly
        okMessage={t('common:ok')}
      >
        <div>{errorData.content}</div>
      </Modal>
    </label>
  )
}

InputFile.propTypes = {
  literal: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onBeforeDeletion: PropTypes.func,
  handleStartUploadingFile: PropTypes.func,
  handleFinishUploadingFile: PropTypes.func,
  fileStore: PropTypes.instanceOf(File).isRequired,
}

InputFile.defaultProps = {
  label: '',
  onChange: null,
  literal: false,
  placeholder: '',
  onBeforeDeletion: null,
  handleStartUploadingFile: null,
  handleFinishUploadingFile: null,
}

export default observer(InputFile)
