import PropTypes from 'prop-types'
import CashMultipleIcon from 'mdi-react/CashMultipleIcon'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import ClipboardTextMultipleIcon from 'mdi-react/ClipboardTextMultipleIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import CityVariantOutlineIcon from 'mdi-react/CityVariantOutlineIcon'
import ChartLineIcon from 'mdi-react/ChartLineIcon'
import DiamondStoneIcon from 'mdi-react/DiamondStoneIcon'
import BitcoinIcon from 'mdi-react/BitcoinIcon'
import ImageFrameIcon from 'mdi-react/ImageFrameIcon'
import BullhornIcon from 'mdi-react/BullhornIcon'
import MoneyIcon from 'mdi-react/MoneyIcon'

const IconSelector = ({ type }) => {
  let typeIcon = null
  switch (type) {
    case 'money':
      typeIcon = <CashMultipleIcon size={16} />
      break
    case 'assetCorporation':
      typeIcon = <BriefcaseIcon size={16} />
      break
    case 'assetTrust':
      typeIcon = <ClipboardTextMultipleIcon size={16} />
      break
    case 'partnership':
      typeIcon = <AccountMultipleIcon size={16} />
      break
    case 'property':
      typeIcon = <CityVariantOutlineIcon size={16} />
      break
    case 'equity':
      typeIcon = <ChartLineIcon size={16} />
      break
    case 'preciousMetals':
      typeIcon = <DiamondStoneIcon size={16} />
      break
    case 'cryptoCurrencies':
      typeIcon = <BitcoinIcon size={16} />
      break
    case 'artworks':
      typeIcon = <ImageFrameIcon size={16} />
      break
    case 'other':
      typeIcon = <BullhornIcon size={16} />
      break
    default:
      typeIcon = <MoneyIcon size={16} />
  }
  return <>{typeIcon}</>
}

IconSelector.propTypes = {
  type: PropTypes.string.isRequired,
}

export default IconSelector
