import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Rule from 'models/Rule'
import CreationInput from 'presentation/CreationInput'
import TextArea from 'presentation/TextArea'
import Button from 'presentation/Button'
import RuleViewItemBeneficiaries from 'presentation/RuleViewItemBeneficiaries'
import RuleViewItemScheduling from 'presentation/RuleViewItemScheduling'
import styles from './ruleCreationForm.module.scss'

const RuleCreationForm = ({
  rule,
  handleChangeTitle,
  handleChangeDescription,
  handleSave,
  handleCancel,
  titleRef,
  trustAssets,
  trustBeneficiaries,
  showAssetsModal,
  showConfigModal,
}) => {
  const { t } = useTranslation('common')

  return (
    <>
      <div className={styles.inputsContainer}>
        <CreationInput
          inputStore={rule.title}
          name="ruleTitle"
          onChange={handleChangeTitle}
          inputRef={titleRef}
        />
        <CreationInput
          inputStore={rule.description}
          name="ruleComments"
          as={TextArea}
          onChange={handleChangeDescription}
          autosize={false}
        />
      </div>
      <RuleViewItemBeneficiaries
        rule={rule}
        trustAssets={trustAssets}
        trustBeneficiaries={trustBeneficiaries}
        handleViewMore={showAssetsModal}
      />
      <RuleViewItemScheduling rule={rule} />
      <div className={styles.manageAssetsButton}>
        <Button
          label={t('trustsCreation:manageAssets')}
          fullWidth
          small
          onClick={showAssetsModal}
        />
        <Button
          label={t('trustsCreation:advancedConfig')}
          fullWidth
          smallest
          className={styles.linkButton}
          onClick={showConfigModal}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button primary label={t('save')} small fullWidth onClick={handleSave} />
        <Button secondary label={t('cancel')} small fullWidth onClick={handleCancel} />
      </div>
    </>
  )
}

RuleCreationForm.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
  handleChangeTitle: PropTypes.func.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  showAssetsModal: PropTypes.func.isRequired,
  titleRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  showConfigModal: PropTypes.func,
}

RuleCreationForm.defaultProps = {
  titleRef: null,
  showConfigModal: () => {},
}

export default RuleCreationForm
