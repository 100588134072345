import LettersSectionBaseStore from './LettersSectionBaseStore'

class DeedsSectionStore extends LettersSectionBaseStore {
  constructor(deedsStore) {
    super()

    this.deedsStore = deedsStore
    this.letterStore = deedsStore
  }
}

export default DeedsSectionStore
