import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import InputStore from 'stores/InputStore'
import AsyncStore from 'stores/AsyncStore'
import TrustService from 'services/TrustService'
import moment from 'moment'
import Amount from 'models/Asset/Amount'
import Document from 'models/Document'

class AssetTrustPartStore extends AsyncStore {
  validationRules

  valueStore = new Amount()

  stores = {
    assetName: new InputStore(),
    trustId: new InputStore(),
    trustName: new InputStore(),
    fullName: new InputStore(),
    governingLaw: new InputStore(),
    trustType: new InputStore(),
    isDiscretional: new InputStore(),
    isRevocable: new InputStore(),
    isEstablished: new InputStore(),
    contactName: new InputStore(),
    documents: new Document(),
    email: new InputStore(),
    phone: new InputStore(),
    value: this.valueStore,
    moneyCurrency: this.valueStore.currency,
    moneyValue: this.valueStore.value,
    valuationDate: new InputStore(),
    selected: new InputStore(),
    role: new InputStore(),
    dataStatus: new InputStore(),
  }

  list = []
  selected = null

  constructor() {
    super()

    makeObservable(this, {
      // observables
      list: observable,
      selected: observable,
      // actions
      loadData: action,
      setFullName: action,
      select: action,
      clearInputs: action,
      fill: action,
      // computeds
      elementOptions: computed,
      selectedOption: computed,
      json: computed,
    })

    this.isLoading = true
    this.trustService = new TrustService()
  }

  // Full Name Select
  async loadData(searchKey = '') {
    this.preRequest()

    try {
      const response = await this.trustService.listAssetTrusts(searchKey)

      runInAction(() => {
        this.list = response.map(
          ({
            id,
            name,
            discretional,
            establishedByWill,
            revocable,
            governingLaw,
            trustType,
            ...rest
          }) => ({
            id,
            name,
            isNew: false,
            isDiscretional: !(!discretional || discretional === ''),
            isEstablished: !(!establishedByWill || establishedByWill === ''),
            isRevocable: !(!revocable || revocable === ''),
            governingLaw,
            trustType,
            ...rest,
          })
        )

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  get elementOptions() {
    if (this.isLoading) return []

    let list = this.list.map((e) => ({ id: e.id, value: e.name, label: e.name }))

    if (this.stores.fullName && this.stores.fullName.value) {
      const element = this.list.filter((e) => e.id === this.stores.fullName.value.id)
      if (element.length === 0) list = list.concat([this.stores.fullName.value])
    }

    if (this.selected && !list.map((e) => e.id).includes(this.selected.id)) {
      list.push({
        id: this.selected.id,
        label: this.selected.name,
        value: this.selected.name,
      })
    }

    return list
  }

  get selectedOption() {
    if (!this.selected) return null
    return this.elementOptions.reduce((c, e) => (e.id === this.selected.id ? e : c), null)
  }

  setFullName(value) {
    this.stores.fullName.setValue(value)
    this.stores.assetName.setValue(value.label)
  }

  select(element) {
    this.setFullName({
      id: element.id,
      label: element.name,
      value: element.name,
    })

    const data = this.list.filter((e) => e.id === element.id)[0]
    if (data || element.isNew) this.clearInputs()

    if (!data || element.isNew) {
      this.selected = { ...element, isNew: true }
      return
    }
    this.setInputs(data)
    this.selected = data
    // This passes the "selected" to backend and then it can be fetched by the json() method
    this.stores.selected.setValue(data)
  }

  setInputs(data) {
    const keys = Object.keys(data)
    for (let i = 0; i < keys.length; i += 1) {
      if (this.stores[keys[i]] && data[keys[i]]) {
        switch (keys[i]) {
          case 'valuationDate':
            this.stores[keys[i]].setValue(moment(data[keys[i]]))
            break
          case 'documents':
            this.stores[keys[i]].fillFromJson(data[keys[i]])
            break
          case 'value':
            break
          default:
            this.stores[keys[i]].setValue(data[keys[i]])
        }
      }
    }
  }

  clearInputs() {
    const keys = Object.keys(this.stores)
    for (let i = 0; i < keys.length; i += 1) {
      if (this.stores[keys[i]]) {
        if (!['assetName', 'fullName'].includes(keys[i]) && this.stores[keys[i]].setValue)
          this.stores[keys[i]].setValue('')
      }
    }
  }

  fill(data) {
    this.setInputs(data)
    if (data.selected) this.selected = { ...data.selected }
  }

  createFromCurrent() {
    const newAssetTrustPartStore = new AssetTrustPartStore()
    const data = this.json
    newAssetTrustPartStore.fill(data)
    return newAssetTrustPartStore
  }

  get json() {
    const current = {
      id: this.selected.id,
      name: this.selected.name,
      selected: this.selected,
    }

    const keys = Object.keys(this.stores)
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === 'documents') {
        current[keys[i]] = this.stores[keys[i]].getJson()
      } else if (keys[i] === 'value') {
        current[keys[i]] = this.stores[keys[i]].json
      } else {
        const { value } = this.stores[keys[i]]
        current[keys[i]] = value instanceof Object ? JSON.parse(JSON.stringify(value)) : value
      }
    }

    return current
  }

  setValidationRules(validationRules) {
    this.validationRules = validationRules
  }

  // eslint-disable-next-line class-methods-use-this
  get validationSchema() {
    return true
  }

  // eslint-disable-next-line class-methods-use-this
  async validate() {
    return Promise.resolve(true)
  }

  // eslint-disable-next-line class-methods-use-this
  get isValid() {
    return true
  }
}

export default AssetTrustPartStore
