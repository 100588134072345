import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import AlertIcon from 'mdi-react/AlertIcon'
import styles from './requestErrors.module.scss'

const RequestErrors = observer(({ errors }) => {
  if (errors && errors.length > 0) {
    return (
      <div className={styles.errorBox}>
        {errors.map((error) => (
          <div className={styles.errorItem} key={`error_${error}`}>
            <AlertIcon size={20} />
            {error}
          </div>
        ))}
      </div>
    )
  }

  return null
})

RequestErrors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default RequestErrors
