/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'presentation/Button'
import Tabs from './Tabs'
import styles from './tabsWizard.module.scss'

const TabsWizard = (
  { children, defaultActiveTab = 0, onFinish, hideButtons, onChangeTab },
  ref
) => {
  const { t } = useTranslation('common')
  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const tabCount = children.length - 1

  useEffect(() => {
    const activatedTab = children.reduce((prev, current, i) => {
      if (current.props.active) {
        return i
      }

      return prev
    }, defaultActiveTab)

    if (activatedTab !== 0 && activatedTab !== undefined) {
      setActiveTab(activatedTab) // Asigna el tab activo por cambio de propiedades
    }
  }, [children])

  // Mueve al siguietne tab
  const handleClick = useCallback(() => {
    if (onChangeTab) {
      onChangeTab(activeTab, activeTab === tabCount)
    }

    if (activeTab < tabCount) {
      return setActiveTab(activeTab + 1)
    }

    if (onFinish) {
      onFinish()
    }

    return Promise.resolve()
  }, [activeTab, tabCount])

  const handleChangetab = useCallback(
    (tabIndex) => {
      if (onChangeTab) {
        onChangeTab(tabIndex, tabIndex === tabCount)
      }
      setActiveTab(tabIndex) // detecta el cambio del tab
    },
    [tabCount]
  )

  useImperativeHandle(ref, () => ({
    // Funcion referenciada para mover el tab
    onSuccessTab() {
      handleClick()
    },
  }))

  return (
    <div className={styles.tabsWizardContainer}>
      <Tabs onChangeTab={handleChangetab}>
        {children.map((child, i) => {
          return React.cloneElement(child, {
            key: `tabWizard_${child.props.name}`,
            active: activeTab === i,
          })
        })}
      </Tabs>
      {!hideButtons && (
        <Button
          primary
          label={activeTab < tabCount ? t('common:next') : t('common:finish')}
          style={{ marginRight: '15px' }}
          onClick={handleClick}
        />
      )}
    </div>
  )
}

/* TabsWizard.propTypes = {
  children: PropTypes.node.isRequired,
  defaultActiveTab: PropTypes.number,
  onFinish: PropTypes.func,
  hideButtons: PropTypes.bool,
  onChangeTab: PropTypes.func,
}

TabsWizard.defaultProps = {
  defaultActiveTab: 0,
  onFinish: null,
  hideButtons: false,
  onChangeTab: null,
} */

export default forwardRef(TabsWizard)
