import AsyncStore from 'stores/AsyncStore'
import { action, makeObservable, observable } from 'mobx'
import TrustHistoryService from 'services/TrustHistoryService'

class TrustHistoryVersionsStore extends AsyncStore {
  constructor(trustId) {
    super()
    this.trustId = trustId
    this.visibleVersions = null
    this.trustHistoryService = new TrustHistoryService()

    makeObservable(this, {
      // observables
      trustId: observable,
      visibleVersions: observable,
      // actions
      loadVersions: action,
    })
  }

  async loadVersions() {
    this.visibleVersions = await this.trustHistoryService.loadVersions(this.trustId)
  }
}

export default TrustHistoryVersionsStore
