import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import MetaTitle from 'util/MetaTitle'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'

const ContactCreationTitle = () => {
  const { t } = useTranslation('contactsCreation')
  const { contactsCreationStore } = useContext(StoresContext)
  let pageTitle = t('titleCreation')

  if (contactsCreationStore.isViewMode) {
    pageTitle = t('titleViewName', { name: contactsCreationStore.entityName })
  } else if (contactsCreationStore.isEdition) {
    pageTitle = t('titleEditionName', { name: contactsCreationStore.entityName })
  }

  return <MetaTitle pageTitle={pageTitle} />
}

export default observer(ContactCreationTitle)
