import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import StoresContext from 'providers/storeContext'
import FormSection from 'models/FormMetadata'
import PageTitle from 'presentation/PageTitle'
import DynamicFormPart from '../DynamicFormPart'

const DynamicFormSection = ({
  formSection,
  inputs,
  readOnly,
  dynamicEntityName,
  defaultRedirectionUrl,
}) => {
  const { currentDynamicStore } = useContext(StoresContext)
  // const [firstInputRef, setFirstInputRef] = useState(null)
  const firstInputRef = useRef(null)
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus()
      }
    }, 20)
    currentDynamicStore.showActionButtons()
  }, [])

  useEffect(() => {
    if (!currentDynamicStore.canViewSection(formSection)) {
      let redirectUrl = defaultRedirectionUrl

      if (!currentDynamicStore.isViewMode) {
        redirectUrl = redirectUrl.replace('v=true', 'v=false')
      }

      history.push(redirectUrl)
    }
  }, [currentDynamicStore.isViewMode])

  // const handleFirstInputRef = (ele) => setFirstInputRef(ele)
  const { sectionTitle } = formSection

  return (
    <>
      {!!sectionTitle && <PageTitle title={sectionTitle} />}
      {formSection.parts.map((part, index) => {
        return (
          <DynamicFormPart
            part={part}
            inputs={inputs}
            readOnly={readOnly}
            key={`dfp_${part.id}`}
            dynamicEntityName={dynamicEntityName}
            inputRef={index === 0 ? firstInputRef : undefined}
          />
        )
      })}
    </>
  )
}

DynamicFormSection.propTypes = {
  formSection: PropTypes.instanceOf(FormSection).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  inputs: PropTypes.shape({}).isRequired,
  dynamicEntityName: PropTypes.string.isRequired,
  defaultRedirectionUrl: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
}

DynamicFormSection.defaultProps = {
  readOnly: true,
}

export default DynamicFormSection
