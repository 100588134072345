import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import StoresContext from '../../providers/storeContext'
import styles from './switchStatusMessage.module.scss'

const SwitchStatusMessage = ({ handleClick, message }) => {
  const { navBarStore } = useContext(StoresContext)

  useEffect(() => {
    navBarStore.toggleTopMsg()
    return () => {
      navBarStore.toggleTopMsg()
    }
  }, [])

  return (
    <div onClick={handleClick} className={styles.messageContainer} role="button" tabIndex="0">
      {message}
      <span className={styles.arrowIcon}>
        <ArrowRightIcon size={17} />
      </span>
    </div>
  )
}

SwitchStatusMessage.propTypes = {
  handleClick: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
}

SwitchStatusMessage.defaultProps = {}

export default SwitchStatusMessage
