import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styles from './assetValuationInformationItem.module.scss'

const AssetValuationInformationItem = ({ asset }) => {
  const { t } = useTranslation('corporationsCreation')

  return (
    <div className={styles.container}>
      <div className={styles.assetNameContainer}>
        <div className={styles.columnDescription}>{t('assetName')}</div>
        <div>{asset.name}</div>
      </div>
      <div className={styles.assetValuationContainer}>
        <div className={styles.gridCell}>
          <div className={styles.columnDescription}>{t('value')}</div>
          <div>
            {asset.valuation.length !== 0 ? (
              asset.valuation.map((valuation) => (
                <div key={`amount-${valuation.amount}`} className={styles.cellValue}>
                  {valuation.amount ? valuation.amount : '-'}
                </div>
              ))
            ) : (
              <div className={styles.cellValue}>-</div>
            )}
          </div>
        </div>
        <div className={styles.gridCell}>
          <div className={styles.columnDescription}>{t('currency')}</div>
          <div>
            {asset.valuation.length !== 0 ? (
              asset.valuation.map((valuation) => (
                // eslint-disable-next-line
                <div key={`currency-${valuation.currency}`} className={styles.cellValue}>
                  {valuation.currency !== '' ? valuation.currency : '-'}
                </div>
              ))
            ) : (
              <div className={styles.cellValue}>-</div>
            )}
          </div>
        </div>

        <div className={styles.gridCell}>
          <div className={styles.columnDescription}>{t('valuationDate')}</div>
          <div className={styles.cellValue}>
            {asset?.valuation?.map((value) =>
              value.date ? moment(value.date).format(t('common:humanDateYearFormat')) : '-'
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

AssetValuationInformationItem.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default AssetValuationInformationItem
