import { action, computed, observable, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import { cloneDeep } from 'lodash'

class PermissionUserStore extends AsyncStore {
  noIdError = false
  usersError = false
  name = ''
  searchOptionUsers = []
  assignedUsers = []
  defaultAssignedUsers = []
  loadingSave = false
  loadingAssignedUsers = false
  loadingSearch = false
  requestErrors = []

  constructor() {
    super()

    makeObservable(this, {
      // observables
      noIdError: observable,
      usersError: observable,
      name: observable,
      searchOptionUsers: observable,
      assignedUsers: observable,
      defaultAssignedUsers: observable,
      loadingSave: observable,
      loadingAssignedUsers: observable,
      loadingSearch: observable,
      requestErrors: observable,
      // actions
      getAssignedUsers: action,
      searchUsers: action,
      addUser: action,
      save: action,
      deleteUser: action,
      // computeds
      getJson: computed,
      isDirty: computed,
      isLoadingData: computed,
    })
  }

  setId(id) {
    this.id = id
  }

  async getAssignedUsers() {
    this.loadingAssignedUsers = true

    this.requestProcess(() => this.getAssignedUsers())

    try {
      const response = await this.listAssignedUsers(this.id)

      runInAction(() => {
        this.assignedUsers = response
        this.defaultAssignedUsers = cloneDeep(this.assignedUsers)
        this.loadingAssignedUsers = false
      })
    } catch (e) {
      runInAction(() => {
        this.loadingAssignedUsers = false
        this.usersError = false

        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        }
      })
    }
  }

  async searchUsers(term = '') {
    this.loadingSearch = true
    this.searchOptionUsers = []

    this.requestProcess(() => this.searchUsers(term))

    try {
      const response = await this.searchPermissionUsers(this.id, term)

      runInAction(() => {
        this.searchOptionUsers = response.users
        this.loadingSearch = false
      })
    } catch (e) {
      runInAction(() => {
        this.loadingSearch = false
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        }
      })
    }
  }

  addUser(selectedUser) {
    if (!this.assignedUsers.some((assignedUser) => assignedUser.id === selectedUser.id)) {
      this.assignedUsers.push(selectedUser)
      if (selectedUser.roles.length > 0) {
        selectedUser.setRole(selectedUser.roles[0])
      }
    }
  }

  static setUserRole(selectedUser, role) {
    selectedUser.setRole(role)
  }

  get getJson() {
    return this.assignedUsers.map((user) => ({ userId: user.id, roles: user.selectedRoles }))
  }

  async save(successCb) {
    if (this.isDirty) {
      this.loadingSave = true

      this.preRequest(() => this.save(successCb))
      try {
        await this.savePermissionUsers(this.id, this.getJson)
        runInAction(() => {
          this.defaultAssignedUsers = this.assignedUsers
          this.loadingSave = false
          successCb()
        })
      } catch (e) {
        runInAction(() => {
          this.requestErrors = e.response.data.error.errors
          this.loadingSave = false
          this.onErrorRequest(e)
        })
      }
    }
  }

  deleteUser(selectedUser) {
    this.assignedUsers.remove(selectedUser)
  }

  get isDirty() {
    return JSON.stringify(this.assignedUsers) !== JSON.stringify(this.defaultAssignedUsers)
  }

  get isLoadingData() {
    return this.isLoading || this.loadingAssignedUsers
  }
}

export default PermissionUserStore
