import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ROLES_LIST } from 'routing/routes'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import imageError from './imageError.png'

const RoleSectionError = () => {
  const { t } = useTranslation('rolesCreation')
  const history = useHistory()
  const handleGoBack = useCallback(() => history.push(ROLES_LIST))

  return (
    <GenericNotFoundError
      description={t('roleSectionErrorDesc')}
      textButton={t('roleSectionErrorButton')}
      goBackFunction={handleGoBack}
      title={t('roleSectionErrorTitle')}
      image={imageError}
    />
  )
}

export default RoleSectionError
