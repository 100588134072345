class SnapshotEntity {
  constructor(type, icon, name) {
    this.type = type
    this.icon = icon
    this.name = name
  }

  static fromJson({ category, type, name, icon }) {
    return new SnapshotEntity(category || type, icon, name)
  }
}

export default SnapshotEntity
