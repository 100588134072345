import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import Modal from 'scenes/Modal'
import NavBarProfileMenu from 'presentation/NavBarProfileMenu'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import * as Routes from 'routing/routes'
import logo from './images/logo.svg'
import styles from './navLeftBar.module.scss'

const NavLeftBar = ({ toggleNavBar }) => {
  const { t } = useTranslation('navBar')
  const history = useHistory()

  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false)

  const handleLogout = useCallback(() => {
    toggleNavBar()
    setVisibleLogoutModal(true)
  }, [])

  const successLogout = useCallback(() => history.push(Routes.LOGOUT), [])

  const cancelLogout = useCallback(() => setVisibleLogoutModal(false), [])

  const handleProfile = useCallback(() => {
    toggleNavBar()
    history.push(Routes.USER_PROFILE)
  }, [])

  const handleSettings = useCallback(() => {
    toggleNavBar()
    history.push(Routes.SETTINGS)
  }, [])

  return (
    <>
      <div className={styles.navBarContent}>
        <div onClick={toggleNavBar} className={styles.arrowLeftIcon} role="button" tabIndex="0">
          <ArrowLeftIcon />
        </div>
        <NavLink to={Routes.DASHBOARD} onClick={toggleNavBar}>
          <img src={logo} alt="Smart Trust" title="Smart Trust" className={styles.mainNavbarLogo} />
        </NavLink>
        <NavLink
          className={styles.mainNavbarCreateTrust}
          to={Routes.TRUSTS_CREATION}
          onClick={toggleNavBar}
        >
          <PlusIcon />
        </NavLink>
      </div>
      <NavBarProfileMenu
        handleProfile={handleProfile}
        handleLogout={handleLogout}
        handleSettings={handleSettings}
      />
      <Modal
        name="logoutModal"
        open={visibleLogoutModal}
        title={t('logoutModalTitle')}
        onClick={handleLogout}
        onSuccess={successLogout}
        okMessage={t('logout')}
        onCancel={cancelLogout}
      />
    </>
  )
}

NavLeftBar.propTypes = {
  toggleNavBar: PropTypes.func.isRequired,
}

export default observer(NavLeftBar)
