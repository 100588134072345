import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Prompt, Route, useLocation, useHistory } from 'react-router-dom'
import { rootStore } from 'App'
import {
  TRUSTS_CREATION_RULES,
  TRUSTS_CREATION,
  TRUSTS_LIST,
  TRUSTS_CREATION_SIGNED_DOCUMENTS,
} from 'routing/routes'
import { EDIT_TRUST, CREATE_TRUST } from 'stores/AuthStore'
import MainCreationContainer from 'presentation/MainCreationContainer'
import MainCreationLoading from 'presentation/MainCreationLoading'
import ServerError from 'presentation/ServerError'
import TrustCreationActionButtons from 'presentation/TrustCreationActionButtons'
import TrustSectionError from 'presentation/TrustSectionError'
import Modal from 'scenes/Modal'
import SignedDocumentsSection from 'scenes/TrustsCreation/SignedDocumentsSection'
import saveTrust from 'util/saveTrust'
import StoresContext from 'providers/storeContext'
import { changeNextTrustStatus, changeBackTrustStatus } from 'util/changeTrustStatus'
import { trustViewAlternativeStatus } from 'util/trustViewStatus'
import PrintPdf from 'presentation/PrintPdf'
import LoadingRing from 'presentation/LoadingRing'
import TrustCreationTitle from './TrustCreationTitle'
import DynamicFormRoutes from '../DynamicForm/DynamicFormRoutes'
import RulesSection from './RulesSection'
import FreezeModalContent from './FreezeModalContent'
import ExecutionErrorsModalContent from './ExecutionErrorsModalContent'
import TrustAlternativeStatusMessage from './TrustAlternativeStatusMessage'
import TransactionCollisionsModalContent from './TransactionCollisionsModalContent'

const TrustsCreation = () => {
  const { t } = useTranslation('trustsCreation')
  const history = useHistory()
  const location = useLocation()
  const { trustsCreationStore, creationParametersStore, hotkeyStore, authStore } =
    useContext(StoresContext)

  const handleSave = () => {
    if (trustsCreationStore.isViewMode) {
      return null
    }

    /* saco la ruta de la segunda sección de una forma cuestionable */
    const redirectRoute = `${TRUSTS_CREATION}${trustsCreationStore.entitySections[1].route}`

    saveTrust(trustsCreationStore, t('trustCreatedMsg'), history, redirectRoute)

    return null
  }

  const configureKeyStore = () => {
    hotkeyStore.setConfig('trustCreation', [
      {
        shortcut: 'alt+s',
        handler: () => {
          handleSave()
        },
      },
    ])
  }

  const startComponent = () => {
    const params = new URLSearchParams(location.search)
    let isViewMode = params.get('v') === 'true'
    let id = params.get('id')

    if (!id && !authStore.can(CREATE_TRUST)) {
      id = 'forced_error'
    }

    if (!isViewMode && !authStore.can(EDIT_TRUST)) {
      isViewMode = true
    }

    creationParametersStore.init()
    trustsCreationStore.init(id, isViewMode, params.get('a') === 'true')
  }

  const getToastSuccessText = () => {
    if (trustsCreationStore.entityStatus.isExecution) {
      return t('executedToast')
    }

    if (trustsCreationStore.entityStatus.isExecuted) {
      return t('pendingToast')
    }

    return ''
  }

  const handleCancel = () => {
    if (trustsCreationStore.isEdition) {
      trustsCreationStore.reloadData()
      trustsCreationStore.setViewMode(true)
    } else {
      history.push(TRUSTS_LIST)
    }
  }

  const handleEdit = () => {
    trustsCreationStore.removeViewMode()
  }

  const handleGoBack = () => {
    history.push(TRUSTS_LIST)
  }

  const successRollBackModal = () => {
    changeBackTrustStatus(trustsCreationStore, t('trustCreatedMsg'), true, history)
  }

  const cancelRollBackModal = () => {
    trustsCreationStore.cancelRollBackModal()
  }

  const handleCloseFreeze = () => {
    trustsCreationStore.hideFreezeModal()
  }

  const successFreezeTrust = () => {
    changeNextTrustStatus(trustsCreationStore, t('trustCreatedMsg'), true, history)

    handleCloseFreeze()
  }

  const closeErrorsModal = () => {
    trustsCreationStore.hideValidationModal()
  }

  const handleCloseChangeStatus = () => {
    trustsCreationStore.hideChangeStatusModal()
  }

  const successChangeStatusForward = () => {
    changeNextTrustStatus(trustsCreationStore, getToastSuccessText(), true, history)
    handleCloseChangeStatus()
  }

  const successBackToDraft = () => {
    trustsCreationStore.goBackToDraft(trustViewAlternativeStatus)
  }

  const handleCloseBackToDraft = () => {
    trustsCreationStore.hideModalBackToDraft()
  }

  const handleTryAgain = () => {
    trustsCreationStore.tryAgain()
  }

  const canPrint = () => {
    return location.pathname.includes('snapshot')
  }

  const handleSaveAndDiscardCollisions = async () => {
    await trustsCreationStore.deleteCollisions()
    changeNextTrustStatus(trustsCreationStore, null, false, history)
    trustsCreationStore.hideTransactionCollisionsModal()
  }

  const handleCancelTransactionCollisionsModal = () => {
    trustsCreationStore.hideTransactionCollisionsModal()
  }

  useEffect(() => {
    rootStore.setCurrentDynamicStore(trustsCreationStore)
    trustsCreationStore.prepare()
    configureKeyStore()
    startComponent()

    return () => {
      hotkeyStore.removeConfig('trustCreation')

      rootStore.resetTrustsCreation()
    }
  }, [])

  if (trustsCreationStore.isLoading) {
    return <LoadingRing center />
  }

  if (trustsCreationStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  if (trustsCreationStore.errorLoadEdit) {
    return <TrustSectionError />
  }

  return (
    <>
      <Prompt
        when={trustsCreationStore.isDirty}
        message={() => {
          if (
            location.pathname.search(TRUSTS_CREATION) < 0 ||
            location.pathname === TRUSTS_CREATION
          ) {
            return t('leavePagePrompt')
          }

          return true
        }}
      />
      <MainCreationLoading store={trustsCreationStore} />
      <MainCreationContainer isLoading={trustsCreationStore.isLoading}>
        <TrustCreationTitle />
        <TrustAlternativeStatusMessage />
        <DynamicFormRoutes
          dynamicFormsStore={trustsCreationStore}
          onResetComponent={startComponent}
          dynamicEntityName={t('trust')}
          baseRoute={TRUSTS_CREATION}
        />
        {trustsCreationStore.idEdition && (
          <Route
            render={() => <RulesSection rulesStore={trustsCreationStore.rulesStore} />}
            exact
            path={TRUSTS_CREATION_RULES}
          />
        )}
        {trustsCreationStore.idEdition && (
          <Route
            render={() => <SignedDocumentsSection />}
            exact
            path={TRUSTS_CREATION_SIGNED_DOCUMENTS}
          />
        )}
        <TrustCreationActionButtons
          trustsCreationStore={trustsCreationStore}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleEdit={handleEdit}
          handleGoBack={handleGoBack}
        />
        <Modal
          name="rollBackModal"
          title={t('attention')}
          open={trustsCreationStore.visibleRollBackModal}
          onSuccess={successRollBackModal}
          onCancel={cancelRollBackModal}
        >
          {trustsCreationStore.entityStatus.isExecution && t('cancelExecution')}
          {trustsCreationStore.entityStatus.isExecuted &&
            !trustsCreationStore.entityStatus.isExecution &&
            t('cancelExecuted')}
        </Modal>
        <Modal
          name="freezeTrustModal"
          title={t('executionModalTitle')}
          open={trustsCreationStore.visibleFreezeModal}
          onSuccess={successFreezeTrust}
          onCancel={handleCloseFreeze}
        >
          <FreezeModalContent />
        </Modal>
        <Modal
          name="changeStatusModal"
          title={t('changeStatusModalTitle')}
          open={trustsCreationStore.visibleChangeStatusModal}
          onSuccess={successChangeStatusForward}
          onCancel={handleCloseChangeStatus}
        />
        <Modal
          name="backToDraftModal"
          title={t('backToDraftModalTitle')}
          open={trustsCreationStore.visibleBackToDraftModal}
          onSuccess={successBackToDraft}
          onCancel={handleCloseBackToDraft}
        >
          <div>
            <p>{t('backToDraftDescription')}</p>
            <p>{t('backToDraftDescription2')}</p>
            <p>{t('backToDraftDescription3')}</p>
          </div>
        </Modal>
        <Modal
          name="errorsTrustModal"
          title={t('attention')}
          open={trustsCreationStore.visibleValidationModal}
          onSuccess={closeErrorsModal}
          okOnly
        >
          <ExecutionErrorsModalContent />
        </Modal>
        <Modal
          name="transactionCollisionModal"
          title={t('collision')}
          open={trustsCreationStore.visibleTransactionCollisionsModal}
          okMessage={t('saveTrust')}
          onSuccess={handleSaveAndDiscardCollisions}
          onCancel={handleCancelTransactionCollisionsModal}
        >
          <TransactionCollisionsModalContent />
        </Modal>
        {canPrint() && (
          <PrintPdf>
            <DynamicFormRoutes
              dynamicFormsStore={trustsCreationStore}
              onResetComponent={startComponent}
              dynamicEntityName={t('trust')}
              baseRoute={TRUSTS_CREATION}
            />
          </PrintPdf>
        )}
      </MainCreationContainer>
    </>
  )
}

export default observer(TrustsCreation)
