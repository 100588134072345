import LettersSectionBaseStore from './LettersSectionBaseStore'

class OtherDocumentsSectionStore extends LettersSectionBaseStore {
  constructor(otherDocsStore) {
    super()

    this.otherDocsStore = otherDocsStore
    this.deedsStore = otherDocsStore
    this.letterStore = otherDocsStore
  }
}

export default OtherDocumentsSectionStore
