import { useCallback, useRef } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import c from 'classnames'
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import TransactionsAsset from 'models/TransactionsAsset'
import ValuationChangeAsset from 'models/ValuationChangeAsset'
import OwnershipTransactionAsset from 'models/OwnershipTransactionAsset'
import CreationInput from 'presentation/CreationInput'
import IconSelector from 'presentation/IconSelector'
import Button from 'presentation/Button'
import ToolTip from 'presentation/ToolTip'
import numberHelper from 'util/numberHelper'
import styles from './withdrawRow.module.scss'

const WithdrawRow = ({ asset, errors, handleClickNext, noAmount, type, noNextButton }) => {
  const { t } = useTranslation('transactions')
  const inputRef = useRef(null)
  const editButtonRef = useRef(null)
  const nextButtonRef = useRef(null)

  const handleClickEdit = useCallback(() => {
    asset.setSelected()
    if (asset.isSelected === false) {
      asset.setValue('')
    }
  }, [inputRef])

  const handleAmountChange = useCallback((e) => {
    asset.setValue(e.target.value)
  }, [])

  return (
    <article className={c(styles.row, asset.name === '' && styles.noName)}>
      {errors ? <div className={styles.error}>{errors}</div> : null}
      {asset.name !== '' && <div className={styles.name}>{asset.name}</div>}
      <div className={styles.type}>
        {asset.type && (
          <div className={styles.typeIcon}>
            <IconSelector type={asset.type} />
          </div>
        )}
        {type}
      </div>
      <div className={styles.valuation}>
        {asset.valuation.map((value) => (
          <div key={value.amount + value.currency}>
            <div className={styles.amount}>{numberHelper(value.amount)}</div>
            <div className={styles.currency}>{value.currency}</div>
          </div>
        ))}
      </div>
      {!noAmount && asset.isSelected && (
        <CreationInput
          placeholder={t('amountToWithdraw')}
          inputStore={asset.value}
          onChange={handleAmountChange}
          wrapperClassName={styles.amountInput}
          inputRef={inputRef}
          autoFocus
        />
      )}
      <div className={styles.actions}>
        {!noAmount && (
          <ToolTip tooltipContent={t('common:edit')}>
            <Button
              className={c(styles.editButton, asset.isSelected && styles.editButtonSelected)}
              icon={<PencilOutlineIcon size={18} />}
              ref={editButtonRef}
              onClick={handleClickEdit}
              circle
              small
            />
          </ToolTip>
        )}
        {!noNextButton && (
          <Button
            className={styles.nextButton}
            icon={<ArrowRightIcon size={18} />}
            onClick={handleClickNext}
            ref={nextButtonRef}
            primary
            circle
            small
          />
        )}
      </div>
    </article>
  )
}

WithdrawRow.propTypes = {
  errors: PropTypes.number,
  asset: PropTypes.oneOfType([
    PropTypes.instanceOf(TransactionsAsset),
    PropTypes.instanceOf(ValuationChangeAsset),
    PropTypes.instanceOf(OwnershipTransactionAsset),
  ]).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  TypeIcon: PropTypes.shape({}),
  handleClickNext: PropTypes.func.isRequired,
  noAmount: PropTypes.bool,
  noNextButton: PropTypes.bool,
}

WithdrawRow.defaultProps = {
  name: null,
  type: null,
  errors: 0,
  TypeIcon: null,
  noAmount: false,
  noNextButton: false,
}

export default observer(WithdrawRow)
