import { parseValidationRules } from 'util/validation'
import Part, { TYPE_ENTITY_LIST } from './Part'
import InstanceEntitiesList from '../InstanceEntitiesList'
import Entity from '../Entity'

class EntityListPart extends Part {
  entities = []

  instanceEntitiesList

  constructor(
    id,
    entityName,
    entityNamePlural,
    contactsSearch,
    onlyClientSearch,
    parsedValidationRules,
    readOnly,
    canAddEntities = true
  ) {
    super(id, TYPE_ENTITY_LIST)

    this.entityName = entityName
    this.entityNamePlural = entityNamePlural
    this.contactsSearch = contactsSearch
    this.onlyClientSearch = onlyClientSearch
    this.instanceEntitiesList = new InstanceEntitiesList(this, parsedValidationRules)
    this.readOnly = readOnly
    this.canAddEntities = canAddEntities
  }

  addEntity(entity) {
    this.entities.push(entity)
  }

  addInstanceEntity(instanceEntity) {
    this.instanceEntitiesList.addInstanceEntity(instanceEntity)
  }

  removeInstanceEntity(instanceEntity) {
    this.instanceEntitiesList.removeInstanceEntity(instanceEntity)
  }

  hasContactSearch() {
    return this.contactsSearch
  }

  get isOnlyClientSearch() {
    return this.onlyClientSearch
  }

  getEntityByType(type) {
    for (let i = 0; i < this.entities.length; i += 1) {
      if (this.entities[i].type === type) {
        return this.entities[i]
      }
    }

    return null
  }

  static fromJson(
    { id, display, entities, validationRules = null, readOnly = false },
    onCreateInput
  ) {
    const {
      entityName = '',
      entityNamePlural = '',
      contactsSearch = false,
      onlyClientSearch,
      canAddEntities,
    } = display

    let parsedValidationRules = null

    if (validationRules) {
      parsedValidationRules = parseValidationRules(validationRules)
    }

    const entityList = new EntityListPart(
      id,
      entityName,
      entityNamePlural,
      contactsSearch,
      onlyClientSearch,
      parsedValidationRules,
      readOnly,
      canAddEntities
    )

    // creates list of entity on store
    onCreateInput(id, entityList.instanceEntitiesList)

    entities.forEach((entity) => {
      const instanceEntity = Entity.fromJson(entity)

      if (instanceEntity) {
        entityList.addEntity(instanceEntity)
      }
    })

    return entityList
  }
}

export default EntityListPart
