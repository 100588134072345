import { parseValidationRules } from 'util/validation'
import Part, { TYPE_INPUT_DATA_LIST } from './Part'
import FormPartStore from './FormPartStore'

class DataListPart extends Part {
  constructor(id, label, dataKey, readOnlyInEdition, readOnly) {
    super(id, TYPE_INPUT_DATA_LIST)

    this.label = label
    this.dataKey = dataKey
    this.readOnly = readOnly

    this.setReadOnlyInEdition(readOnlyInEdition)
  }

  static fromJson({ id, display, dataKey, validationRules = null }, onCreateInput) {
    const { label = '', readOnlyInEdition = true, readOnly = false } = display
    const input = new FormPartStore()

    if (validationRules) {
      input.setValidationRules(parseValidationRules(validationRules))
    }

    onCreateInput(id, input)

    return new DataListPart(id, label, dataKey, readOnlyInEdition, readOnly)
  }
}

export default DataListPart
