import moment from 'moment'
import CausedBy from 'models/CausedBy'

class HistoryElement {
  constructor(description, date, causedBy) {
    this.description = description
    this.date = moment(date)
    this.causedBy = causedBy
  }

  static fromJson({ description, date, causedBy }) {
    return new HistoryElement(description, date, CausedBy.fromJson(causedBy))
  }
}

export default HistoryElement
