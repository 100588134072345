import { observable, makeObservable } from 'mobx'
import moment from 'moment'
import UserStatus from 'models/UserStatus'
import Role from 'models/Role'

class PermissionUser {
  selectedRoles = []
  isDirty = false

  setRole(role) {
    if (!this.selectedRoles.includes(role.id)) {
      this.selectedRoles.push(role.id)
    } else if (this.selectedRoles.length > 1) {
      this.unsetRole(role)
    }
  }

  unsetRole(rol) {
    if (this.selectedRoles.includes(rol.id)) {
      this.selectedRoles = this.selectedRoles.filter((selectedRoleId) => selectedRoleId !== rol.id)
    }
  }

  configRoles(roles) {
    this.roles = roles.map((json) => {
      return Role.fromJson(json)
    })
  }

  constructor(
    id,
    name,
    firstName,
    lastName,
    email,
    created,
    updated,
    status,
    roles,
    profileImage,
    selectedRoles = []
  ) {
    this.id = id
    this.name = name
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.created = created
    this.updated = updated
    this.profileImage = profileImage
    this.status = new UserStatus(status)
    this.roles = roles
    this.selectedRoles = selectedRoles.map((role) => role.id)
    this.configRoles(roles)
    this.isDirty = false

    makeObservable(this, {
      // observables
      selectedRoles: observable,
      isDirty: observable,
    })
  }

  setIsDirty() {
    this.isDirty = true
  }

  clearIsDirty() {
    this.isDirty = false
  }

  static fromJson({
    id,
    firstName,
    lastName,
    email,
    created,
    updated,
    active,
    roles,
    profileImage,
    selectedRoles = [],
  }) {
    return new PermissionUser(
      id,
      `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      moment(created),
      moment(updated),
      active ? 'active' : 'inactive',
      roles,
      profileImage,
      selectedRoles
    )
  }
}

export default PermissionUser
