class Change {
  constructor(field, currentValue, newValue) {
    this.field = field
    this.currentValue = currentValue
    this.newValue = newValue
  }

  static fromJson({ field, currentValue, newValue }) {
    return new Change(field, currentValue, newValue)
  }
}

export default Change
