import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MenuDownIcon from 'mdi-react/MenuDownIcon'
import c from 'classnames'
import {
  STATUS_DRAFT,
  STATUS_EXECUTION,
  STATUS_EXECUTED,
  STATUS_PENDING,
  STATUS_STORED,
  STATUS_STORED_ALTERNATIVE,
} from './filterStatus'
import styles from './statusFilter.module.scss'

function StatusFilter({
  handleChangeFilter,
  showDropdownFilter,
  setShowDropdownFilter,
  selectedFromDashboard,
}) {
  const { t } = useTranslation()
  const [selectedFilter, setSelectedFilter] = useState('')

  const columns = [
    { name: '' },
    { name: STATUS_DRAFT },
    { name: STATUS_EXECUTION },
    { name: STATUS_EXECUTED },
    { name: STATUS_PENDING },
    { name: STATUS_STORED },
    { name: STATUS_STORED_ALTERNATIVE },
  ]

  const filters = []

  columns.forEach((col) => {
    filters.push(
      <div
        className={c(selectedFilter === col.name && styles.selectedFilter)}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()

          handleChangeFilter(col.name)
          setShowDropdownFilter(false)
          setSelectedFilter(col.name)

          return null
        }}
        key={col.name}
        role="button"
        tabIndex="0"
      >
        {col.name === '' ? t(`common:viewAll`) : t(`common:${col.name}`)}
      </div>
    )

    return null
  })

  useEffect(() => {
    if (selectedFromDashboard) {
      setSelectedFilter(selectedFromDashboard)
    }
  }, [selectedFromDashboard])

  return (
    <div className={styles.containerFilterStatus}>
      <div
        className={styles.filter}
        onBlur={() => {
          setShowDropdownFilter(false)
        }}
      >
        <label>{t('common:filterBy')}</label>
        <div
          className={styles.filterBox}
          onClick={() => {
            setShowDropdownFilter(!showDropdownFilter)
          }}
          role="button"
          tabIndex="0"
        >
          <div>{selectedFilter === '' ? t('common:viewAll') : t(`common:${selectedFilter}`)}</div>
          <MenuDownIcon />
        </div>
        <div className={c(styles.dropdownFilter, showDropdownFilter && styles.showDropdown)}>
          {filters.map((col) => col)}
        </div>
      </div>
    </div>
  )
}

StatusFilter.propTypes = {
  handleChangeFilter: PropTypes.func.isRequired,
  setShowDropdownFilter: PropTypes.func.isRequired,
  showDropdownFilter: PropTypes.bool.isRequired,
  selectedFromDashboard: PropTypes.string,
}

StatusFilter.defaultProps = {
  selectedFromDashboard: '',
}

export default observer(StatusFilter)
