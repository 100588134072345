import React, { useContext, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StoresContext from 'providers/storeContext'
import LettersSection from 'presentation/LettersSection'
import DeedsSectionStore from 'stores/DeedsSectionStore'
import AmendmentSectionStore from 'stores/AmendmentSectionStore'
import DeedsArchiveStore from 'stores/DeedsArchiveStore'
import saveTrust from 'util/saveTrust'

const DynamicFormPartDeeds = () => {
  const { t } = useTranslation('trustsCreation')
  const { trustsCreationStore } = useContext(StoresContext)
  const { deedsStore } = trustsCreationStore

  const [deedsSectionStore] = useState(() => new DeedsSectionStore(deedsStore))
  const [amendmentSectionStore] = useState(() => new AmendmentSectionStore(deedsStore))
  const [deedsArchiveStore] = useState(() => new DeedsArchiveStore(trustsCreationStore.idEdition))

  const handleResetMainSection = useCallback(() => deedsSectionStore.reset(), [])
  const handleResetSecondarySection = useCallback(() => amendmentSectionStore.reset(), [])
  const validationHandler = () => trustsCreationStore.validateDeed()
  const handleSaveEntity = useCallback(
    () => saveTrust(trustsCreationStore, t('trustCreatedMsg')),
    []
  )

  if (trustsCreationStore.idEdition === undefined && trustsCreationStore.isLoading) {
    return null
  }

  return (
    <LettersSection
      title={t('deedOfTrust')}
      mainTabName={t('deedOfTrust')}
      secondaryTabName={t('amendments')}
      secondaryTabNameSingular={t('amendment')}
      showExistsDraft={
        !deedsSectionStore.draftEditionMode &&
        deedsStore.draftMainLetter !== null &&
        trustsCreationStore.hasAlternativeStatus
      }
      existsDraftText={t('draftInProgress')}
      resetMainSection={handleResetMainSection}
      resetSecondarySection={handleResetSecondarySection}
      isLoading={deedsStore.isLoading || trustsCreationStore.isLoading}
      mainStore={deedsStore}
      mainSectionStore={deedsSectionStore}
      mainFirstLevelError={deedsStore.mainFirstLevelError}
      secondaryFirstLevelError={deedsStore.secondaryFirstLevelError}
      secondarySectionStore={amendmentSectionStore}
      letterArchiveStore={deedsArchiveStore}
      handleSaveEntity={handleSaveEntity}
      isViewMode={trustsCreationStore.isViewMode}
      mainEntityStatus={trustsCreationStore.entityStatus}
      mainEntityHasAlternativeStatus={trustsCreationStore.hasAlternativeStatus}
      secondLetterShowRulesAlert={false}
      mainSectionCanBeRevoked={false}
      validationHandler={validationHandler}
    />
  )
}

export default observer(DynamicFormPartDeeds)
