import { action, makeObservable, observable } from 'mobx'

class OwnershipTransactionAsset {
  constructor(assetId, name, type, icon, valuation) {
    this.id = assetId
    this.name = name
    this.type = type
    this.icon = icon
    this.valuation = valuation
    this.isSelected = false

    makeObservable(this, {
      // observables
      isSelected: observable,
      // actions
      setSelected: action,
    })
  }

  static fromJson({ assetId, assetName, assetType, icon, value: { value, currency } }) {
    const valuation = [
      {
        amount: value,
        currency: currency?.value,
      },
    ]
    return new OwnershipTransactionAsset(assetId, assetName, assetType, icon, valuation)
  }

  setSelected() {
    this.isSelected = !this.isSelected
  }

  unselect() {
    this.isSelected = false
  }

  getJson() {
    return { assetId: this.id, amount: this.value.value }
  }
}

export default OwnershipTransactionAsset
