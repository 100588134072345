import { action, observable, runInAction, makeObservable } from 'mobx'
import i18next from 'i18next'
import AsyncStore from 'stores/AsyncStore'
import GoverningLaw from 'models/GoverningLaw'
import GoverningLawService from 'services/GoverningLawService'
import InputStore from 'stores/InputStore'

const REQUIRED = i18next.t('validation:requiredField')

class GoverningLawStore extends AsyncStore {
  canSave = false
  isSaving = false
  governingLaws = []
  governingLaw = null
  messageErrorServer = ''
  name = new InputStore()
  visibleErrorModal = false
  isNotSelectedOptions = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      canSave: observable,
      isSaving: observable,
      governingLaws: observable,
      governingLaw: observable,
      messageErrorServer: observable,
      name: observable,
      visibleErrorModal: observable,
      isNotSelectedOptions: observable,
      // actions
      save: action,
      createGoverningLaw: action,
      cleanCreation: action,
      hideVisibleErrorModal: action,
      showVisibleErrorModal: action,
      setTrustEnabled: action,
      setCorporationEnabled: action,
      validateSelected: action,
    })

    this.governingLawService = new GoverningLawService()
  }

  async save() {
    this.isSaving = false
    if (this.validateCreate()) {
      this.preRequest()

      this.requestProcess(() => this.save(this.governingLaw.getJson()))

      try {
        await this.governingLawService.saveGoverningLaw(this.governingLaw.getJson())

        runInAction(() => {
          this.isSaving = true
          this.governingLaw = null
          this.name.setValue('')
          this.onSuccessRequest()
        })
      } catch (e) {
        this.isSaving = false
        const [errorMessage] = e.response.data.error.errors
        this.messageErrorServer = errorMessage
        this.showVisibleErrorModal()
        this.onSuccessRequest()
        this.onErrorRequest(e)
      }
    }
  }

  createGoverningLaw() {
    if (this.validateName()) {
      this.governingLaw = new GoverningLaw()
      this.governingLaw.changeName(this.name.value)
    }
  }

  cleanCreation() {
    this.name.setValue('')
    this.governingLaw = null
  }

  hideVisibleErrorModal() {
    this.visibleErrorModal = false
  }

  showVisibleErrorModal() {
    this.visibleErrorModal = true
  }

  setTrustEnabled() {
    this.governingLaw.setTrustEnabled(!this.governingLaw.trustEnabled)
  }

  setCorporationEnabled() {
    this.governingLaw.setCorporationEnabled(!this.governingLaw.corporationEnabled)
  }

  validateName() {
    this.cleanError()
    let isValid = true

    if (!this.name.value) {
      this.name.setError(true, REQUIRED)

      isValid = false
    }

    return isValid
  }

  validateCreate() {
    this.cleanError()
    let isValid = true

    if (!this.name.value) {
      this.name.setError(true, REQUIRED)

      isValid = false
    }
    if (!this.governingLaw) {
      isValid = false
    }

    if (this.governingLaw) {
      if (!this.governingLaw.trustEnabled && !this.governingLaw.corporationEnabled) {
        this.isNotSelectedOptions = true
        isValid = false
      }
    }

    return isValid
  }

  validateSelected() {
    this.canSave = false
  }

  cleanError() {
    this.isNotSelectedOptions = false
    this.name.setError(false, '')
  }
}

export default GoverningLawStore
