import { parseValidationRules } from 'util/validation'
import Part, { TYPE_INPUT_FILE_LIST } from './Part'
import FileListFormPartStore from './FileListFormPartStore'

class FileListPart extends Part {
  constructor(id, title) {
    super(id, TYPE_INPUT_FILE_LIST)

    this.title = title
  }

  static fromJson({ id, display, validationRules }, onCreateInput) {
    const { title } = display

    const input = new FileListFormPartStore()

    input.setValidationRules(parseValidationRules(validationRules))

    onCreateInput(id, input)

    return new FileListPart(id, title)
  }
}

export default FileListPart
