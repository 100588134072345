import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon'
import CheckboxMarkedCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import styles from './freezeModalItem.module.scss'

const FreezeModalItem = ({ label, selected, disabled, handleClick }) => {
  let icon = <CircleOutlineIcon />

  if (selected) {
    icon = <CheckboxMarkedCircleIcon />
  }

  if (disabled) {
    icon = <CheckIcon />
  }

  return (
    <li
      className={c(styles.item, selected && styles.selected, disabled && styles.disabled)}
      onClick={handleClick}
    >
      <span className={styles.icon}>{icon}</span>
      <div className={styles.label}>{label}</div>
    </li>
  )
}

FreezeModalItem.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
}

FreezeModalItem.defaultProps = {
  selected: false,
  disabled: false,
  handleClick: null,
}

export default FreezeModalItem
