export const sanitizeForSearch = (value) => {
  const sanitize = value.toLowerCase()

  return sanitize
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/Á/gi, 'a')
    .replace(/É/gi, 'e')
    .replace(/Í/gi, 'i')
    .replace(/Ó/gi, 'o')
    .replace(/Ú/gi, 'u')
}

export const toUpperCase = (value) => {
  const uppered = value.toUpperCase()

  return uppered
    .replace(/á/gi, 'Á')
    .replace(/é/gi, 'É')
    .replace(/í/gi, 'Í')
    .replace(/ó/gi, 'Ó')
    .replace(/ú/gi, 'Ú')
    .replace(/ñ/gi, 'Ñ')
}

export const capitalize = (value) => {
  return value
    .toLowerCase()
    .split(' ')
    .map((chunk) => {
      return chunk.charAt(0).toUpperCase() + chunk.substring(1)
    })
    .join(' ')
}
