import React from 'react'
import { useTranslation } from 'react-i18next'
import StarIcon from 'mdi-react/StarIcon'
import isMobile from 'util/browser'
import ToolTip from 'presentation/ToolTip'
import styles from './tenantUserWarning.module.scss'

const TenantUserWarning = () => {
  const { t } = useTranslation('users')

  if (isMobile()) {
    return (
      <>
        <span className={styles.tenantUserIcon}>
          <StarIcon size={18} />
        </span>
        <div className={styles.mobileWarning}>
          <span>
            <StarIcon size={18} />
          </span>
          <span>{t('tenantUserText')}</span>
        </div>
      </>
    )
  }

  return (
    <span className={styles.tenantUserIcon}>
      <ToolTip tooltipContent={t('tenantUserText')} placement="right">
        <StarIcon size={18} />
      </ToolTip>
    </span>
  )
}

export default TenantUserWarning
