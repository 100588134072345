import { useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import PencilIcon from 'mdi-react/PencilIcon'
import { useTranslation } from 'react-i18next'
import { LetterForm, ActionButtons } from 'presentation/LettersSection'
import { DocumentSubtitle, DeleteDraftModal } from 'presentation/DocumentsSection'
import LettersBaseStore from 'stores/LettersBaseStore'
import LettersSectionSecondaryBaseStore from 'stores/LettersSectionSecondaryBaseStore'

const SecondaryLetterFormSection = ({
  mainStore,
  secondarySectionStore,
  handleViewHistory,
  handleViewVersions,
  handleSaveEntity,
  isViewMode,
  letterTypeName,
  letterTypeNameSingular,
  secondLetterShowRulesAlert,
}) => {
  const { t } = useTranslation('lettersForms')
  useEffect(() => {
    if (isViewMode) {
      secondarySectionStore.viewHistory()
    }
  }, [])

  const handleDeleteDraft = () => {
    secondarySectionStore.showDeleteDraftModal()
  }

  const closeDeleteDraftModal = () => {
    secondarySectionStore.hideDeleteDraftModal()
  }

  const unsetDraftEditionMode = () => {
    secondarySectionStore.hideDraftEditionMode()
  }

  const successDeleteDraftModal = () => {
    const secondaryLetter = mainStore.draftSecondaryLetter

    secondaryLetter.delete()

    closeDeleteDraftModal()
    unsetDraftEditionMode()
    handleSaveEntity()
  }

  const { draftSecondaryLetter } = mainStore

  return (
    <>
      <DocumentSubtitle
        handleGoBackClick={unsetDraftEditionMode}
        goBackLabel={t('common:goBack')}
        title={t('editLetterDraft', { letterTypeName: letterTypeNameSingular })}
        icon={<PencilIcon size={15} />}
      />
      <LetterForm letter={draftSecondaryLetter} viewMode={isViewMode} />
      <ActionButtons
        letter={draftSecondaryLetter}
        handleDeleteDraft={handleDeleteDraft}
        handleViewHistory={handleViewHistory}
        handleViewVersions={handleViewVersions}
        viewMode={isViewMode}
        showCreateLetter={draftSecondaryLetter === null}
        letterTypeName={letterTypeName}
      />
      <DeleteDraftModal
        title={t('confirmDeleteDraftDocument')}
        open={secondarySectionStore.deleteDraftModal}
        onSuccess={successDeleteDraftModal}
        onCancel={closeDeleteDraftModal}
        showRulesAlert={secondLetterShowRulesAlert}
      />
    </>
  )
}

SecondaryLetterFormSection.propTypes = {
  mainStore: PropTypes.instanceOf(LettersBaseStore).isRequired,
  secondarySectionStore: PropTypes.instanceOf(LettersSectionSecondaryBaseStore).isRequired,
  handleViewHistory: PropTypes.func.isRequired,
  handleViewVersions: PropTypes.func.isRequired,
  handleSaveEntity: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool.isRequired,
  letterTypeName: PropTypes.string.isRequired,
  letterTypeNameSingular: PropTypes.string.isRequired,
  secondLetterShowRulesAlert: PropTypes.bool.isRequired,
}

export default observer(SecondaryLetterFormSection)
