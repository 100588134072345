import LettersSectionBaseStore from './LettersSectionBaseStore'

class StatutesSectionStore extends LettersSectionBaseStore {
  constructor(statutesStore) {
    super()

    this.statutessStore = statutesStore
  }
}

export default StatutesSectionStore
