import { useContext } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import AlertIcon from 'mdi-react/AlertIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import CheckAllIcon from 'mdi-react/CheckAllIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import FileUndoIcon from 'mdi-react/FileUndoIcon'
import LockIcon from 'mdi-react/LockIcon'
import Button from 'presentation/Button'
import ToolTip from 'presentation/ToolTip'
import StoresContext from 'providers/storeContext'
import { CHANGE_CORPORATION_STATUS, EDIT_CORPORATION } from 'stores/AuthStore'
import styles from './corporationChangeStatusButton.module.scss'

const CorporationChangeStatusButton = ({ toggleNavBar, collapsed }) => {
  const { t } = useTranslation('corporationsCreation')
  const { corporationsCreationStore, authStore } = useContext(StoresContext)

  const buttonLabel = () => {
    const { entityStatus } = corporationsCreationStore

    if (entityStatus.isDraft) {
      return t('execution')
    }

    if (entityStatus.isExecution) {
      return t('executed')
    }

    if (entityStatus.isExecuted) {
      return t('store')
    }

    if (entityStatus.isStored) {
      return t('editCorporation')
    }

    return ''
  }

  const iconNext = () => {
    const { entityStatus } = corporationsCreationStore

    if (entityStatus.isDraft) {
      return <CheckIcon size={18} />
    }

    if (entityStatus.isExecution) {
      return <CheckAllIcon size={18} />
    }

    if (entityStatus.isExecuted) {
      return <LockIcon size={18} />
    }

    return <PencilIcon size={18} />
  }

  if (corporationsCreationStore.isEdition) {
    return (
      <>
        <div className={c(styles.changeStatusButton, collapsed && styles.collapsed)}>
          {authStore.can(CHANGE_CORPORATION_STATUS) &&
            corporationsCreationStore.showChangeStatusError &&
            !collapsed && (
              <ToolTip
                placement="right"
                tooltipContent={<div>{t('viewCorporationChangeErrors')}</div>}
              >
                <AlertIcon
                  size={20}
                  className={styles.alertExecution}
                  onClick={() => {
                    toggleNavBar()
                    corporationsCreationStore.tryToChangeStatus()
                  }}
                />
              </ToolTip>
            )}
          {!corporationsCreationStore.entityStatus.isStored && (
            <Button
              error={collapsed && corporationsCreationStore.validationErrors.length}
              fullWidth={!corporationsCreationStore.showChangeStatusError}
              primary={corporationsCreationStore.entityStatus.isExecuted}
              isLoading={corporationsCreationStore.loadingValidation}
              icon={collapsed && iconNext()}
              label={buttonLabel()}
              iconPositionLeft
              permission={CHANGE_CORPORATION_STATUS}
              onClick={() => {
                toggleNavBar()
                corporationsCreationStore.tryToChangeStatus()
              }}
              className={c(
                collapsed ? styles.buttonCollapsable : null,
                corporationsCreationStore.entityStatus.isExecuted && styles.storeButton
              )}
              small
            />
          )}
          {corporationsCreationStore.entityStatus.isStored &&
            !corporationsCreationStore.corporationAlternativeStatus && (
              <Button
                fullWidth={!corporationsCreationStore.showChangeStatusError}
                isLoading={corporationsCreationStore.loadingValidation}
                className={collapsed ? styles.buttonCollapsable : null}
                icon={collapsed && iconNext()}
                permission={EDIT_CORPORATION}
                label={buttonLabel()}
                iconPositionLeft
                onClick={() => {
                  toggleNavBar()
                  corporationsCreationStore.tryToGoBackToDraft()
                }}
                small
              />
            )}
        </div>
        {corporationsCreationStore.entityStatus.canRollback && (
          <div
            className={c(
              styles.changeStatusButton,
              collapsed && styles.collapsed,
              styles.secondary
            )}
          >
            <Button
              label={`${t('rollBack')} ${t(corporationsCreationStore.entityStatus.status)}`}
              className={collapsed ? styles.buttonCollapsable : null}
              isLoading={corporationsCreationStore.loadingRollBack}
              icon={collapsed && <FileUndoIcon size={18} />}
              permission={CHANGE_CORPORATION_STATUS}
              iconPositionLeft
              secondary
              fullWidth
              small
              onClick={() => {
                toggleNavBar()
                corporationsCreationStore.tryToRollBack()
              }}
            />
          </div>
        )}
      </>
    )
  }

  return null
}

CorporationChangeStatusButton.contextType = StoresContext

CorporationChangeStatusButton.propTypes = {
  toggleNavBar: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
}

CorporationChangeStatusButton.defaultProps = {
  collapsed: false,
}

export default observer(CorporationChangeStatusButton)
