import { action, runInAction, makeObservable } from 'mobx'
import LettersArchiveService from 'services/LettersArchiveService'
import { LetterVersion } from 'models/TrustDocumentVersion'
import LetterArchiveStore from 'stores/LetterArchiveStore'

class OtherDocumentsArchiveStore extends LetterArchiveStore {
  constructor(idEdition) {
    super()

    makeObservable(this, {
      listVersions: action,
    })

    this.idEdition = idEdition
    this.lettersArchiveService = new LettersArchiveService()
  }

  async listVersions() {
    this.preRequest()

    try {
      const response = await this.lettersArchiveService.listVersions(
        this.idEdition,
        'other-documents'
      )

      runInAction(() => {
        this.versionsList = []
        response.map((otherDocsVersion) =>
          this.versionsList.push(LetterVersion.fromJson(otherDocsVersion))
        )

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }
}

export default OtherDocumentsArchiveStore
