import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LiteralValue from 'presentation/LiteralValue'
import styles from './ownershipActivityModalForm.module.scss'

const getOwnershipValue = (value) => (value ? `${value}%` : '-')

const OwnershipActivityModalForm = ({ ownership, corpName }) => {
  const { t } = useTranslation('activity')

  return (
    <div className={styles.valuationChangeContainer}>
      <div className={styles.field}>{t('ownership')}</div>
      <div className={styles.corpName}>
        <LiteralValue label={t('corpName')} value={corpName} borderBottom />
      </div>
      <div className={styles.currentValue}>
        <LiteralValue
          label={t('current')}
          value={getOwnershipValue(ownership.currentValue)}
          borderBottom
        />
      </div>
      <div className={styles.newValue}>
        <LiteralValue label={t('new')} value={getOwnershipValue(ownership.newValue)} borderBottom />
      </div>
    </div>
  )
}

OwnershipActivityModalForm.propTypes = {
  ownership: PropTypes.shape({
    currentValue: PropTypes.string,
    newValue: PropTypes.string,
  }).isRequired,
  corpName: PropTypes.string.isRequired,
}

export default OwnershipActivityModalForm
