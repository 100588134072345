import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from 'presentation/Button'
import styles from './transactionCreateActionsButtons.module.scss'

const TransactionCreateActionsButtons = ({
  isLoading,
  isEdition,
  handleCancel,
  handleSaveTransaction,
}) => {
  const { t } = useTranslation('transactionsCreation')
  return (
    <div className={styles.buttonsContainer}>
      {!isEdition && (
        <div className={styles.backButtonContainer}>
          <Button
            big
            primary
            iconPositionLeft
            label={t('save')}
            onClick={handleSaveTransaction}
            style={{ marginRight: '15px' }}
          />
        </div>
      )}
      <Button
        big
        secondary
        label={t('cancel')}
        isLoading={isLoading}
        onClick={handleCancel}
        title={`${t('cancel')} (alt+s)`}
      />
    </div>
  )
}

TransactionCreateActionsButtons.propTypes = {
  isEdition: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleSaveTransaction: PropTypes.func.isRequired,
}

TransactionCreateActionsButtons.defaultProps = {
  isLoading: false,
  isEdition: false,
}

export default TransactionCreateActionsButtons
