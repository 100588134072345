import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LockResetIcon from 'mdi-react/LockResetIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import TrustDocumentArchive from 'presentation/TrustDocumentArchive'
import TrustDocumentArchiveItem from 'presentation/TrustDocumentArchiveItem'
import LoadingRing from 'presentation/LoadingRing'
import Button from 'presentation/Button'
import LetterArchiveStore from 'stores/LetterArchiveStore'
import styles from './letterVersions.module.scss'

const LetterVersions = ({ handleViewForm, letterArchiveStore, letterTypeName }) => {
  const { t } = useTranslation('lettersForms')

  useEffect(() => {
    letterArchiveStore.listVersions()
  }, [])

  if (letterArchiveStore.isLoading) {
    return <LoadingRing center />
  }

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <LockResetIcon />
          {t('storedHistory')}
        </div>
        <div>
          <Button
            icon={<ArrowLeftIcon size={20} />}
            label={t('backToStored')}
            onClick={handleViewForm}
            iconPositionLeft
            small
          />
        </div>
      </div>
      {letterArchiveStore.versionsList.length > 0 ? (
        <TrustDocumentArchive>
          {letterArchiveStore.versionsList.map((version) => (
            <TrustDocumentArchiveItem
              trustDocumentVersion={version}
              key={`version_${version.id}`}
              showStatus
            />
          ))}
        </TrustDocumentArchive>
      ) : (
        <div className={styles.noHistory}>{t('noLetterHistory', { letterTypeName })}</div>
      )}
    </>
  )
}

LetterVersions.propTypes = {
  letterArchiveStore: PropTypes.instanceOf(LetterArchiveStore).isRequired,
  letterTypeName: PropTypes.string.isRequired,
  handleViewForm: PropTypes.func.isRequired,
}

export default observer(LetterVersions)
