import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class BlockchainVotingService {
  getBlockchainTransaction = (voteId) =>
    axios.get(`${API_URL}/votes/${voteId}/blockchain`).then((response) => response.data.data)
}

export default BlockchainVotingService
