import axios from 'axios'
import { API_URL } from 'services/config'
import moment from 'moment'
import HistoryElement from 'models/HistoryElement'
import HistoryVersion from 'models/HistoryVersion'

/* eslint-disable class-methods-use-this */
class TrustHistoryService {
  loadHistory = (
    trustId,
    page = 1,
    sortField = 'date',
    sortDirection = 'desc',
    from,
    to,
    version
  ) => {
    const params = {}

    if (page) {
      params.page = page
    }
    if (sortField && sortDirection) {
      params[`sort[${sortField}]`] = sortDirection
    }

    if (from) {
      params.from = moment(from).format('YYYY-MM-DD')
    }

    if (to) {
      params.to = moment(to).format('YYYY-MM-DD')
    }

    if (version) {
      params.versionNumber = version
    }
    return axios
      .get(`${API_URL}/tenant/trusts/${trustId}/activity`, { params })
      .then((response) => ({
        history: response.data.data.map((historyItem) => HistoryElement.fromJson(historyItem)),
        pagination: response.data.pagination,
      }))
  }

  loadVersions = (trustId) =>
    axios
      .get(`${API_URL}/tenant/trusts/${trustId}/stored-versions`)
      .then((results) => results.data.data.map((result) => HistoryVersion.fromJson(result)))
}

export default TrustHistoryService
