import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import DatePicker from 'presentation/DatePicker'
import Vote from 'models/Vote'
import LiteralValue from 'presentation/LiteralValue'
import CreationInput from 'presentation/CreationInput'
import styles from './generalInformationForm.module.scss'

const GeneralInformationForm = ({ vote, isEdition, checksum, blockChainAddress }) => {
  const { t } = useTranslation('transactionsCreation')

  const handleChangeDescription = useCallback((e) => {
    vote.description.setValue(e.target.value)
  })

  const handleChangeTitle = useCallback((e) => {
    vote.title.setValue(e.target.value)
  })

  const handleChangeDate = useCallback((e) => {
    vote.created.setValue(e)
  })

  return (
    <>
      <div className={styles.formContainerTwoColumns}>
        <CreationInput
          literal={isEdition}
          inputStore={vote.title}
          label={t('titleLabel')}
          onChange={handleChangeTitle}
          placeholder={t('titlePlaceholder')}
        />
        <CreationInput
          as={DatePicker}
          literal={isEdition}
          label={t('dateLabel')}
          inputStore={vote.created}
          onChange={handleChangeDate}
          placeholder={t('datePlaceholder')}
        />
      </div>
      <div className={styles.formContainer}>
        <CreationInput
          literal={isEdition}
          inputStore={vote.description}
          label={t('descriptionLabel')}
          onChange={handleChangeDescription}
          placeholder={t('descriptionPlaceholder')}
        />
      </div>
      {isEdition && (
        <div className={styles.formContainer}>
          <LiteralValue label={t('checksum')} value={checksum} />
          <LiteralValue label={t('blockChainAddress')} value={blockChainAddress} />
        </div>
      )}
    </>
  )
}

GeneralInformationForm.propTypes = {
  vote: PropTypes.instanceOf(Vote).isRequired,
  isEdition: PropTypes.bool.isRequired,
  checksum: PropTypes.string,
  blockChainAddress: PropTypes.string,
}

GeneralInformationForm.defaultProps = {
  checksum: '',
  blockChainAddress: '',
}

export default observer(GeneralInformationForm)
