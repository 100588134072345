import { makeAutoObservable } from 'mobx'

const STATUS_ACTIVE = 'active'
const STATUS_INACTIVE = 'inactive'

class ClientStatus {
  constructor(status = STATUS_INACTIVE) {
    this.status = status

    makeAutoObservable(this)
  }

  setStatus(status) {
    this.status = status
  }

  setActive() {
    this.setStatus(STATUS_ACTIVE)
  }

  get isActive() {
    return this.status === STATUS_ACTIVE
  }

  get isInactive() {
    return this.status === STATUS_INACTIVE
  }

  // eslint-disable-next-line class-methods-use-this
  get canBeEdited() {
    return true
  }
}

export default ClientStatus
