import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StoresContext from 'providers/storeContext'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'
import DownloadDocument from 'presentation/DownloadDocument'

const SignedDocumentsView = () => {
  const { t } = useTranslation('signedDocuments')
  const { trustsCreationStore } = useContext(StoresContext)

  const deed = trustsCreationStore.deedsStore.activeMainLetter
  const amendments = trustsCreationStore.deedsStore.activeSecondaryLetter
  const low = trustsCreationStore.lettersStore.activeMainLetter
  const lods = trustsCreationStore.lettersStore.activeSecondaryLetter
  const otherDocs = trustsCreationStore.otherDocsStore.activeMainLetter

  return (
    <>
      <DynamicFormSubtitle label={t('subtitleView')} />
      {deed && (
        <DownloadDocument
          name={t('deedOfTrust')}
          file={deed.signedFile}
          documentTitle={deed.title.value}
        />
      )}
      {low && (
        <DownloadDocument
          name={t('letterOfWishes')}
          file={low.signedFile}
          documentTitle={low.title.value}
        />
      )}
      {otherDocs && (
        <DownloadDocument
          name={t('otherDocuments')}
          file={otherDocs.signedFile}
          documentTitle={otherDocs.title.value}
        />
      )}
      {amendments &&
        amendments.map((amendment, i) => (
          <DownloadDocument
            // eslint-disable-next-line react/no-array-index-key
            key={`signedDoc_am_${i}`}
            name={t('amendment')}
            file={amendment.signedFile}
            documentTitle={amendment.title.value}
          />
        ))}
      {lods &&
        lods.map((lod, i) => (
          <DownloadDocument
            // eslint-disable-next-line react/no-array-index-key
            key={`signedDoc_lod_${i}`}
            name={t('letterOfDistribution')}
            file={lod.signedFile}
            documentTitle={lod.title.value}
          />
        ))}
    </>
  )
}

export default observer(SignedDocumentsView)
