import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Prompt, Route } from 'react-router-dom'
import { rootStore } from 'App'
import {
  CORPORATIONS_CREATION,
  CORPORATIONS_LIST,
  CORPORATIONS_CREATION_SIGNED_DOCUMENTS,
} from 'routing/routes'
import MainCreationContainer from 'presentation/MainCreationContainer'
import MainCreationLoading from 'presentation/MainCreationLoading'
import PublishLoader from 'presentation/PublishLoader'
import CorporationSectionError from 'presentation/CorporationSectionError'
import CorporationCreationActionButtons from 'presentation/CorporationCreationActionButtons'
import Modal from 'scenes/Modal'
import saveCorporation from 'util/saveCorporation'
import {
  changeNextCorporationStatus,
  changeBackCorporationStatus,
} from 'util/changeCorporationStatus'
import ServerError from 'presentation/ServerError'
import {
  corporationViewAlternativeStatus,
  corporationViewCurrentStatus,
} from 'util/corporationViewStatus'
import StoresContext from 'providers/storeContext'
import CorporationCreationTitle from './CorporationCreationTitle'
import DynamicFormRoutes from '../DynamicForm/DynamicFormRoutes'
import ExecutionErrorsModalContent from './ExecutionErrorsModalContent'
import CorporationAlternativeStatusMessage from './CorporationAlternativeStatusMessage'
import SignedDocumentsSection from './SignedDocumentsSection'
import FreezeModalContent from './FreezeModalContent'

/* eslint-disable class-methods-use-this */
class CorporationsCreation extends React.Component {
  constructor(props, context) {
    super(props, context)

    const { corporationsCreationStore } = this.context

    rootStore.setCurrentDynamicStore(corporationsCreationStore)
    corporationsCreationStore.prepare()
    this.startComponent()
  }

  componentDidMount() {
    this.configureKeyStore()
  }

  componentWillUnmount() {
    const { hotkeyStore } = this.context

    hotkeyStore.removeConfig('corporationCreation')

    rootStore.resetCorporationsCreation()
  }

  getToastSuccessText() {
    const { t } = this.props
    const { corporationsCreationStore } = this.context

    if (corporationsCreationStore.entityStatus.isExecution) {
      return t('executedToast')
    }

    if (corporationsCreationStore.entityStatus.isExecuted) {
      return t('storedToast')
    }

    return ''
  }

  handleSave = () => {
    const { history, t } = this.props
    const { corporationsCreationStore } = this.context

    if (corporationsCreationStore.isViewMode) {
      return null
    }

    /* saco la ruta de la segunda sección de una forma cuestionable */
    const redirectRoute = `${CORPORATIONS_CREATION}${corporationsCreationStore.entitySections[1].route}`

    saveCorporation(corporationsCreationStore, t('corporationCreatedMsg'), history, redirectRoute)
    return null
  }

  handleCancel = () => {
    const { history } = this.props
    const { corporationsCreationStore } = this.context

    if (corporationsCreationStore.isEdition) {
      corporationsCreationStore.reloadData()
      corporationsCreationStore.setViewMode(true)
    } else {
      history.push(CORPORATIONS_LIST)
    }
  }

  handleEdit = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.removeViewMode()
  }

  handleGoBack = () => {
    const { history } = this.props

    history.push(CORPORATIONS_LIST)
  }

  startComponent = () => {
    const { location } = this.props
    const { corporationsCreationStore, creationParametersStore } = this.context

    const params = new URLSearchParams(location.search)

    creationParametersStore.init()
    corporationsCreationStore.init(
      params.get('id'),
      params.get('v') === 'true',
      params.get('a') === 'true'
    )
  }

  successRollBackModal = () => {
    const { t, history } = this.props
    const { corporationsCreationStore } = this.context

    changeBackCorporationStatus(
      corporationsCreationStore,
      t('corporationCreatedMsg'),
      true,
      history
    )
  }

  cancelRollBackModal = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.cancelRollBackModal()
  }

  closeErrorsModal = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.hideValidationModal()
  }

  successChangeStatusForward = () => {
    const { history } = this.props
    const { corporationsCreationStore } = this.context

    if (corporationsCreationStore.entityStatus.isExecuted) {
      corporationsCreationStore.showPublishingModal()
      changeNextCorporationStatus(corporationsCreationStore, null, false, history)
    } else {
      changeNextCorporationStatus(
        corporationsCreationStore,
        this.getToastSuccessText(),
        true,
        history
      )
    }

    this.handleCloseChangeStatus()
  }

  handleCloseChangeStatus = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.hideChangeStatusModal()
  }

  successBackToDraft = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.goBackToDraft(corporationViewAlternativeStatus)
  }

  handleCloseBackToDraft = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.hideModalBackToDraft()
  }

  handleFinishStore = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.hidePublishingModal()
    this.redirectHomeCorporation()
  }

  redirectHomeCorporation = () => {
    const { history } = this.props
    const { corporationsCreationStore } = this.context

    corporationViewCurrentStatus(corporationsCreationStore, history)
  }

  successFreezeTrust = () => {
    const { t, history } = this.props
    const { corporationsCreationStore } = this.context

    changeNextCorporationStatus(
      corporationsCreationStore,
      t('corporationCreatedMsg'),
      true,
      history
    )

    this.handleCloseFreeze()
  }

  handleCloseFreeze = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.hideFreezeModal()
  }

  handleTryAgain = () => {
    const { corporationsCreationStore } = this.context

    corporationsCreationStore.tryAgain()
  }

  configureKeyStore() {
    const { hotkeyStore } = this.context

    hotkeyStore.setConfig('corporationCreation', [
      {
        shortcut: 'alt+s',
        handler: () => {
          this.handleSave()
        },
      },
    ])
  }

  render() {
    const { t, history } = this.props
    const { corporationsCreationStore } = this.context

    if (corporationsCreationStore.errorLoadEdit) {
      return <CorporationSectionError />
    }

    if (corporationsCreationStore.serverError) {
      return <ServerError handleTryAgain={this.handleTryAgain} />
    }

    return (
      <>
        <Prompt
          when={corporationsCreationStore.isDirty}
          message={(location) => {
            if (
              location.pathname.search(CORPORATIONS_CREATION) < 0 ||
              location.pathname === CORPORATIONS_CREATION
            ) {
              return t('leavePagePrompt')
            }

            return true
          }}
        />
        <MainCreationLoading store={corporationsCreationStore} />
        <MainCreationContainer isLoading={corporationsCreationStore.isLoading}>
          <CorporationCreationTitle />
          <CorporationAlternativeStatusMessage history={history} />
          <DynamicFormRoutes
            dynamicFormsStore={corporationsCreationStore}
            onResetComponent={this.startComponent}
            dynamicEntityName={t('corporation')}
            baseRoute={CORPORATIONS_CREATION}
          />
          {corporationsCreationStore.idEdition && (
            <Route
              render={() => <SignedDocumentsSection />}
              exact
              path={CORPORATIONS_CREATION_SIGNED_DOCUMENTS}
            />
          )}
          <CorporationCreationActionButtons
            corporationsCreationStore={corporationsCreationStore}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            handleEdit={this.handleEdit}
            handleGoBack={this.handleGoBack}
          />
          <Modal
            name="rollBackModal"
            title={t('attention')}
            open={corporationsCreationStore.visibleRollBackModal}
            onSuccess={this.successRollBackModal}
            onCancel={this.cancelRollBackModal}
          >
            {corporationsCreationStore.entityStatus.isExecution && t('cancelExecution')}
            {corporationsCreationStore.entityStatus.isExecuted &&
              !corporationsCreationStore.entityStatus.isExecution &&
              t('cancelExecuted')}
          </Modal>
          <Modal
            name="freezeCorporationModal"
            title={t('executionModalTitle')}
            open={corporationsCreationStore.visibleFreezeModal}
            onSuccess={this.successFreezeTrust}
            onCancel={this.handleCloseFreeze}
          >
            <FreezeModalContent />
          </Modal>
          <Modal
            name="changeStatusModal"
            title={t('changeStatusModalTitle')}
            open={corporationsCreationStore.visibleChangeStatusModal}
            onSuccess={this.successChangeStatusForward}
            onCancel={this.handleCloseChangeStatus}
          />
          <Modal
            name="backToDraftModal"
            title={t('backToDraftModalTitle')}
            open={corporationsCreationStore.visibleBackToDraftModal}
            onSuccess={this.successBackToDraft}
            onCancel={this.handleCloseBackToDraft}
          >
            <div>
              <p>{t('backToDraftDescription')}</p>
              <p>{t('backToDraftDescription2')}</p>
              <p>{t('backToDraftDescription3')}</p>
            </div>
          </Modal>
          <Modal
            name="errorsCorporationModal"
            title={t('attention')}
            open={corporationsCreationStore.visibleValidationModal}
            onSuccess={this.closeErrorsModal}
            okOnly
          >
            <ExecutionErrorsModalContent type="corporations" />
          </Modal>
          {corporationsCreationStore.visiblePublishingModal && (
            <PublishLoader
              handleFinish={this.handleFinishStore}
              storedText={t('corporationStored')}
              loadingStore={corporationsCreationStore.loadingSave}
            />
          )}
        </MainCreationContainer>
      </>
    )
  }
}

CorporationsCreation.contextType = StoresContext

CorporationsCreation.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default withTranslation('corporationsCreation')(observer(CorporationsCreation))
