import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import MenuDownIcon from 'mdi-react/MenuDownIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import MetaTitle from 'util/MetaTitle'
import CustomActivitySearch from 'scenes/ActivityList/CustomActivitySearch'
import PageTitle from 'presentation/PageTitle'
import NoResultsList from 'presentation/NoResultsList'
import HistoryList from 'presentation/HistoryList'
import TablePager from 'presentation/TablePager'
import LoadingRing from 'presentation/LoadingRing'
import TrustHistoryVersionDetailsStore from './TrustHistoryVersionDetailsStore'
import styles from './trustHistoryVersionDetails.module.scss'

const TrustHistoryVersionDetails = () => {
  const { t } = useTranslation('history')
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const trustId = params.get('id')
  const versionNumber = params.get('version')
  const [showDropdownOrder, setShowDropdownOrder] = useState(false)
  const [trustHistoryVersionDetailsStore] = useState(
    () => new TrustHistoryVersionDetailsStore(trustId, versionNumber)
  )

  useEffect(() => {
    trustHistoryVersionDetailsStore.loadHistory()
  }, [])

  const handlePageChange = useCallback((value) => {
    trustHistoryVersionDetailsStore.changePage(value)
    trustHistoryVersionDetailsStore.loadHistory()
  }, [])

  const handleSetToDate = useCallback((value) => {
    trustHistoryVersionDetailsStore.setToDate(value)
    trustHistoryVersionDetailsStore.loadHistory()
  }, [])

  const handleSetFromDate = useCallback((value) => {
    trustHistoryVersionDetailsStore.setFromDate(value)
    trustHistoryVersionDetailsStore.loadHistory()
  }, [])

  const onOrder = useCallback(() => {
    trustHistoryVersionDetailsStore.orderList()
    trustHistoryVersionDetailsStore.loadHistory()
  }, [])

  const handleClickOrderBox = useCallback(() => {
    setShowDropdownOrder(!showDropdownOrder)
  }, [showDropdownOrder])

  const handleBlurOrder = useCallback(() => {
    setShowDropdownOrder(false)
  }, [])

  const { isLoading } = trustHistoryVersionDetailsStore

  const handleClickOrderButton = useCallback(() => {
    if (!isLoading) {
      onOrder()
    }
  }, [isLoading])

  const { visibleHistory, version, to, from, sortDirection, activePage, pages } =
    trustHistoryVersionDetailsStore

  if (isLoading || visibleHistory === null) {
    return (
      <div className={styles.loading}>
        <LoadingRing />
      </div>
    )
  }

  return (
    <>
      <MetaTitle pageTitle={`Version ${version}`} />
      <PageTitle title={t('versionNumber', { number: version })} />
      <div className={styles.orderContainer}>
        <div className={styles.dateFilter}>
          <CustomActivitySearch
            toDateInputStore={to}
            setFromDate={handleSetFromDate}
            setToDate={handleSetToDate}
            fromDateInputStore={from}
          />
        </div>
        <div className={styles.sorter}>
          <div className={styles.order} onBlur={handleBlurOrder}>
            <label className={styles.labelSortBy}>{t('common:sortBy')}</label>
            <div
              className={styles.orderBox}
              onClick={handleClickOrderBox}
              role="button"
              tabIndex="0"
            >
              <div>{t('createdAt')}</div>
              <MenuDownIcon />
            </div>
          </div>
          <div
            className={styles.orderButton}
            onClick={handleClickOrderButton}
            role="button"
            tabIndex="0"
          >
            {sortDirection === 'asc' && <SortAscendingIcon size={20} />}
            {(!sortDirection || sortDirection === 'desc') && <SortDescendingIcon size={20} />}
          </div>
        </div>
      </div>
      <div className={styles.historyContainer}>
        {visibleHistory?.length === 0 ? (
          <NoResultsList topText={t('common:noResultsFound')} />
        ) : (
          <HistoryList historyElements={visibleHistory} />
        )}
      </div>
      {visibleHistory?.length === 0 ? null : (
        <TablePager handlePageChange={handlePageChange} currentPage={activePage} pages={pages} />
      )}
    </>
  )
}

export default observer(TrustHistoryVersionDetails)
