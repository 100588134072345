import axios from 'axios'
import { API_URL } from 'services/config'
import ListTrust from 'models/ListTrust'
import ListCorporation from 'models/ListCorporation'
import ValuationChangeAsset from 'models/ValuationChangeAsset'

/* eslint-disable class-methods-use-this */
class TransactionsAssetsService {
  loadTransactionStructureList = (structure, page, sortField, sortDirection) => {
    const params = { status: 'stored', page }

    if (sortField && sortDirection) {
      params[`sort[${sortField}]`] = sortDirection
    }
    if (structure === 'trusts') {
      return axios.get(`${API_URL}/tenant/${structure}`, { params }).then((response) => ({
        storedStructures: response.data.data.map((trust) => ListTrust.fromJson(trust)),
        pagination: response.data.pagination,
      }))
    }
    return axios.get(`${API_URL}/tenant/${structure}`, { params }).then((response) => ({
      storedStructures: response.data.data.map((corp) => ListCorporation.fromJson(corp)),
      pagination: response.data.pagination,
    }))
  }

  loadAssetList = (id) =>
    axios.get(`${API_URL}/tenant/client/fiduciary-structure/${id}/assets`).then((result) => ({
      assets: result.data.data.map((asset) => ValuationChangeAsset.fromJson(asset)),
    }))

  loadMetadata = (id, type) =>
    axios
      .get(`${API_URL}/metadata/asset/fiduciaryStructure/${id}/${type}`)
      .then((response) => response.data.data)

  loadAssetDetails = (assetId) =>
    axios
      .get(`${API_URL}/tenant/asset/${assetId}`)
      .then((response) => ({ assetDetails: response.data.data }))

  updateAssetValuation = (assetId, data) =>
    axios
      .post(`${API_URL}/tenant/asset/${assetId}/valuation`, data)
      .then((response) => response.data.data)

  sendToBlockchainAll = () => {
    return axios.post(`${API_URL}/tenant/content-transactions/run-trigger`)
  }
}

export default TransactionsAssetsService
