import React from 'react'
import PropTypes from 'prop-types'
import PageTitle from 'presentation/PageTitle'
import Tabs, { Tab } from 'presentation/Tabs'
import LoadingRing from 'presentation/LoadingRing'
import PageTitleButton from 'presentation/PageTitleButton/PageTitleButton'
import { scrollToCoord } from 'util/scroll'
import LettersBaseStore from 'stores/LettersBaseStore'
import LettersSectionBaseStore from 'stores/LettersSectionBaseStore'
import LettersSectionSecondaryBaseStore from 'stores/LettersSectionSecondaryBaseStore'
import LetterArchiveStore from 'stores/LetterArchiveStore'
import EntityStatus from 'models/EntityStatus'
import LetterMainSection from './LetterMainSection'
import LetterSecondarySection from './LetterSecondarySection'
import styles from './lettersSection.module.scss'

/* eslint-disable class-methods-use-this */
class LettersSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = { defaultActiveTab: 0 }
    this.tabRef = null
  }

  handleChangeTab = (activeTab) => {
    const { resetMainSection, resetSecondarySection } = this.props

    this.setState({ defaultActiveTab: activeTab })

    if (activeTab === 0) {
      resetMainSection()
    }

    if (activeTab === 1) {
      resetSecondarySection()
    }
  }

  handleOnClickScrollToDraft = () => {
    scrollToCoord(document.body.clientHeight, 0)
  }

  render() {
    const {
      title,
      showExistsDraft,
      isLoading,
      existsDraftText,
      mainTabName,
      secondaryTabName,
      secondaryTabNameSingular,
      mainStore,
      mainSectionStore,
      secondarySectionStore,
      handleSaveEntity,
      isViewMode,
      mainEntityStatus,
      mainFirstLevelError,
      secondaryFirstLevelError,
      mainEntityHasAlternativeStatus,
      letterArchiveStore,
      secondLetterShowRulesAlert,
      mainSectionCanBeRevoked,
      showRulesAlert,
      validationHandler,
    } = this.props
    const { defaultActiveTab } = this.state

    if (isLoading) {
      return <LoadingRing center />
    }

    const mainLetterSection = (
      <LetterMainSection
        mainStore={mainStore}
        mainSectionStore={mainSectionStore}
        handleSaveEntity={handleSaveEntity}
        isViewMode={isViewMode}
        mainEntityStatus={mainEntityStatus}
        mainEntityHasAlternativeStatus={mainEntityHasAlternativeStatus}
        mainFirstLevelError={mainFirstLevelError}
        letterTypeName={mainTabName}
        letterArchiveStore={letterArchiveStore}
        mainSectionCanBeRevoked={mainSectionCanBeRevoked}
        showRulesAlert={showRulesAlert}
        validationHandler={validationHandler}
      />
    )

    const secondaryLetterSection = secondaryTabName ? (
      <LetterSecondarySection
        mainStore={mainStore}
        secondarySectionStore={secondarySectionStore}
        isViewMode={isViewMode}
        secondaryFirstLevelError={secondaryFirstLevelError}
        letterTypeName={secondaryTabName}
        letterTypeNameSingular={secondaryTabNameSingular}
        handleSaveEntity={handleSaveEntity}
        secondLetterShowRulesAlert={secondLetterShowRulesAlert}
        validationHandler={validationHandler}
      />
    ) : null

    let pageSection = mainLetterSection

    if (secondaryLetterSection) {
      pageSection = (
        <Tabs
          ref={(ref) => {
            this.tabRef = ref
          }}
          removeOutPageScrollMobile
          onChangeTab={this.handleChangeTab}
          defaultActiveTab={defaultActiveTab}
        >
          <Tab name={mainTabName} errors={mainSectionStore.letterStore.mainLetterErrors}>
            {mainLetterSection}
          </Tab>
          {secondaryTabName !== null && (
            <Tab
              name={secondaryTabName}
              errors={mainSectionStore.letterStore.secondaryLetterErrors}
            >
              {secondaryLetterSection}
            </Tab>
          )}
        </Tabs>
      )
    }

    return (
      <>
        <PageTitle
          title={title}
          floating
          tabRef={this.tabRef}
          containerClass={styles.pageTitleContainer}
          rightSection={
            <PageTitleButton
              onClick={this.handleOnClickScrollToDraft}
              showButton={defaultActiveTab === 0 && showExistsDraft}
              text={existsDraftText}
            />
          }
        />
        <div className={styles.tabsContainer}>{pageSection}</div>
      </>
    )
  }
}

LettersSection.propTypes = {
  secondarySectionStore: PropTypes.instanceOf(LettersSectionSecondaryBaseStore),
  mainSectionStore: PropTypes.instanceOf(LettersSectionBaseStore).isRequired,
  letterArchiveStore: PropTypes.instanceOf(LetterArchiveStore).isRequired,
  mainEntityStatus: PropTypes.instanceOf(EntityStatus).isRequired,
  validationHandler: PropTypes.func.isRequired,
  mainEntityHasAlternativeStatus: PropTypes.bool,
  mainStore: PropTypes.instanceOf(LettersBaseStore).isRequired,
  title: PropTypes.string.isRequired,
  showExistsDraft: PropTypes.bool.isRequired,
  resetMainSection: PropTypes.func.isRequired,
  mainTabName: PropTypes.string.isRequired,
  existsDraftText: PropTypes.string,
  isLoading: PropTypes.bool,
  resetSecondarySection: PropTypes.func,
  secondaryTabName: PropTypes.string,
  mainFirstLevelError: PropTypes.bool,
  secondaryTabNameSingular: PropTypes.string,
  secondaryFirstLevelError: PropTypes.bool,
  handleSaveEntity: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool,
  secondLetterShowRulesAlert: PropTypes.bool,
  mainSectionCanBeRevoked: PropTypes.bool,
  showRulesAlert: PropTypes.bool,
}

LettersSection.defaultProps = {
  resetSecondarySection: null,
  isLoading: true,
  existsDraftText: true,
  secondaryTabName: null,
  secondaryTabNameSingular: null,
  secondarySectionStore: null,
  isViewMode: true,
  mainFirstLevelError: false,
  secondaryFirstLevelError: false,
  mainEntityHasAlternativeStatus: false,
  secondLetterShowRulesAlert: true,
  mainSectionCanBeRevoked: true,
  showRulesAlert: true,
}

export default LettersSection
