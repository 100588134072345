import { runInAction, makeAutoObservable } from 'mobx'
import TermsAndConditionsService from 'services/TermsAndConditionsService'

class TermsAndConditionsStore {
  constructor() {
    this.isLoading = false // request is being sent
    this.termsAndConditions = '' // Terms and conditions text
    this.checkedConditions = false // checkbox value
    this.termsIsNotSelected = false // error if terms are not accepted
    this.currentVersion = null // holds the number of the current version of the terms and conditions
    this.errorLoadEdit = false // Indicates if there is an error on fetching terms and conditions or accepting them
    this.errorMessage = '' // Holds the error message in case errorLoadEdit is true

    this.termsAndConditionsService = new TermsAndConditionsService()

    makeAutoObservable(this)
  }

  closeTermsAndConditions() {
    this.setCheckTermsAndConditions(false)
    this.setTermsIsNotSelected(false)
  }

  checkTermsAndConditions() {
    this.checkedConditions = !this.checkedConditions
  }

  setCheckTermsAndConditions(value) {
    this.checkedConditions = value
  }

  setMustAccept(mustAccept) {
    this.mustAccept = mustAccept
  }

  setCurrentVersion(currentVersion) {
    this.currentVersion = currentVersion
  }

  setTermsIsNotSelected(value) {
    this.termsIsNotSelected = value
  }

  async loadTermsAndConditions() {
    try {
      const response = await this.termsAndConditionsService.getTermsAndConditions(
        this.currentVersion
      )

      runInAction(() => {
        this.termsAndConditions = response
      })
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.errorMessage = e.response.data.error.errors
      })
    }
    return null
  }

  async acceptTerms() {
    this.setTermsIsNotSelected(false)
    if (this.checkedConditions) {
      try {
        await this.termsAndConditionsService.acceptTermsAndConditions(this.currentVersion)

        runInAction(() => {
          this.setCheckTermsAndConditions(false)
        })
      } catch (e) {
        runInAction(() => {
          this.errorLoadEdit = true
          this.errorMessage = e.response.data.error.errors
        })
      }
    } else {
      this.setTermsIsNotSelected(true)
    }
  }
}

export default TermsAndConditionsStore
