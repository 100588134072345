import { SnapshotEntity } from 'models/FormMetadata'
import Part, { TYPE_ENTITY_SNAPSHOT_LIST } from './Part'

class EntitySnapshotListPart extends Part {
  entities = []

  constructor(id, label, readOnly) {
    super(id, TYPE_ENTITY_SNAPSHOT_LIST)

    this.label = label
    this.readOnly = readOnly
  }

  addEntity(entity) {
    this.entities.push(entity)
  }

  getEntityByType(type) {
    for (let i = 0; i < this.entities.length; i += 1) {
      if (this.entities[i].type === type) {
        return this.entities[i]
      }
    }

    return null
  }

  static fromJson({ id, display, entities }) {
    const { label = '', readOnly = true } = display

    const entitySnapshotListPart = new EntitySnapshotListPart(id, label, readOnly)

    entities.forEach((entity) => entitySnapshotListPart.addEntity(SnapshotEntity.fromJson(entity)))

    return entitySnapshotListPart
  }
}

export default EntitySnapshotListPart
