import { makeAutoObservable } from 'mobx'

class Dashboard {
  financialStructures = []
  transactions = []
  trusts = []
  stats = {}

  constructor() {
    makeAutoObservable(this)
  }

  fillJson({ financialStructures, transactions, trusts, stats }) {
    this.stats = stats
    this.trusts = trusts
    this.transactions = transactions
    this.financialStructures = financialStructures
  }

  getJson() {
    return {
      stats: this.stats,
      trusts: this.trusts,
      transactions: this.transactions,
      financialStructures: this.financialStructures,
    }
  }

  formatData(data, elementCount) {
    this.dataFormated = []

    data.forEach((element, index) => {
      if (index < elementCount) {
        this.dataFormated.push(element)
      }
    })

    return this.dataFormated
  }

  // ============================================
  // Getters
  // ============================================

  get getFinancialStructures() {
    return this.formatData(this.financialStructures, 1)
  }

  get getTransactions() {
    return this.formatData(this.transactions, 5)
  }

  get getTrusts() {
    return this.formatData(this.trusts, 2)
  }

  get getStats() {
    return [
      { status: 'draft', count: this.stats.draft },
      { status: 'execution', count: this.stats.execution },
      { status: 'executed', count: this.stats.executed },
      { status: 'pending', count: this.stats.pending },
      { status: 'stored', count: this.stats.stored },
      { status: 'storedAlternative', count: this.stats.storedAlternative },
    ]
  }
}

export default Dashboard
