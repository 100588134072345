import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import StoresContext from 'providers/storeContext'
import LiteralValue from 'presentation/LiteralValue'
import CompanyAssetsStore from 'models/FormMetadata/Parts/AssetCompanyPartStore'
import styles from './styles.module.scss'

const CompanyInformation = ({ store, readOnly }) => {
  const { creationParametersStore } = useContext(StoresContext)

  const { t } = useTranslation('corporationsCreation')

  const handleChange = (name, value, inputStore) => {
    inputStore.setValue(value)
  }

  const { isNew } = store.selected

  return (
    <div className={styles.companyInformation}>
      {!isNew ? (
        // company existente
        <div className={styles.companyInformation__content}>
          <div className={styles.companyInformation__literalValue}>
            <LiteralValue
              label={t('assetCompany.companyInformation.governingLaw')}
              value={store.stores.governingLaw.value.value || ''}
            />
          </div>
          <div className={styles.companyInformation__literalValue}>
            <LiteralValue
              label={t('assetCompany.companyInformation.companyType')}
              value={store.stores.companyType.value.value || ''}
            />
          </div>
        </div>
      ) : (
        // company nuevo
        <div className={styles.companyInformation__content}>
          <CreationInput
            as={FormSelect}
            placeholder={t('assetCompany.companyInformation.selectGoverningLaw')}
            label={t('assetCompany.companyInformation.governingLaw')}
            inputStore={store.stores.governingLaw}
            onChange={(option) => {
              return handleChange('governingLawCorporation', option, store.stores.governingLaw)
            }}
            options={creationParametersStore.getOptions('governingLawCorporation')}
            literal={readOnly}
          />
          <CreationInput
            as={FormSelect}
            placeholder={t('assetCompany.companyInformation.selectCompanyType')}
            label={t('assetCompany.companyInformation.companyType')}
            inputStore={store.stores.companyType}
            onChange={(option) => handleChange('companyType', option, store.stores.companyType)}
            options={creationParametersStore.getOptions('corporationType')}
            literal={readOnly}
          />
        </div>
      )}
    </div>
  )
}

CompanyInformation.propTypes = {
  store: PropTypes.instanceOf(CompanyAssetsStore).isRequired,
  readOnly: PropTypes.bool,
}

CompanyInformation.defaultProps = {
  readOnly: true,
}

export default observer(CompanyInformation)
