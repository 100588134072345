import { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import { successToast } from 'helpers/Toast'
import DocumentForSignRow from 'presentation/DocumentForSignRow'
import NoResultsList from 'presentation/NoResultsList'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'
import SignedDocumentsStore from '../SignedDocumentsStore'

const SignedDocumentsEdit = () => {
  const { t } = useTranslation('signedDocuments')
  const { trustsCreationStore } = useContext(StoresContext)
  const [signedDocumentsStore] = useState(() => new SignedDocumentsStore())

  const getSignedErrors = (section) => {
    if (section.signedFile.hasErrors) {
      return 1
    }

    return 0
  }

  const finishUpload = () => {
    trustsCreationStore.validateExecuted()

    successToast(t('successUpload'), null, 'successUpload').show()
  }

  const handleFinishUploadingDot = () => {
    const { deedsStore } = trustsCreationStore
    const deedOfTrust = deedsStore.draftMainLetter

    signedDocumentsStore
      .uploadDot(deedOfTrust.signedFile.id, trustsCreationStore.idEdition, deedOfTrust.id)
      .then(finishUpload)
  }

  const handleFinishUploadingLow = () => {
    const { lettersStore } = trustsCreationStore
    const letterOfWishes = lettersStore.draftMainLetter

    signedDocumentsStore
      .uploadLow(letterOfWishes.signedFile.id, trustsCreationStore.idEdition, letterOfWishes.id)
      .then(finishUpload)
  }

  const handleFinishUploadingOther = () => {
    const { otherDocsStore } = trustsCreationStore
    const otherDocs = otherDocsStore.draftMainLetter

    signedDocumentsStore
      .uploadOther(otherDocs.signedFile.id, trustsCreationStore.idEdition, otherDocs.id)
      .then(finishUpload)
  }

  const handleFinishUploadingAmendment = () => {
    const { deedsStore } = trustsCreationStore
    const amendment = deedsStore.draftSecondaryLetter

    signedDocumentsStore
      .uploadAmendment(amendment.signedFile.id, trustsCreationStore.idEdition, amendment.id)
      .then(finishUpload)
  }

  const handleFinishUploadingLod = () => {
    const { lettersStore } = trustsCreationStore
    const letterOfDistribution = lettersStore.draftSecondaryLetter

    signedDocumentsStore
      .uploadLod(
        letterOfDistribution.signedFile.id,
        trustsCreationStore.idEdition,
        letterOfDistribution.id
      )
      .then(finishUpload)
  }

  const handleDeleteDot = () => {
    const { deedsStore } = trustsCreationStore
    const deedOfTrust = deedsStore.draftMainLetter

    signedDocumentsStore
      .deleteDot(deedOfTrust.signedFile.id, trustsCreationStore.idEdition, deedOfTrust.id)
      .then(() => trustsCreationStore.validateExecuted())
  }

  const handleDeleteLow = () => {
    const { lettersStore } = trustsCreationStore
    const letterOfWishes = lettersStore.draftMainLetter

    signedDocumentsStore
      .deleteLow(letterOfWishes.signedFile.id, trustsCreationStore.idEdition, letterOfWishes.id)
      .then(() => trustsCreationStore.validateExecuted())
  }

  const handleDeleteOther = () => {
    const { otherDocsStore } = trustsCreationStore
    const otherDocs = otherDocsStore.draftMainLetter

    signedDocumentsStore
      .deleteOther(otherDocs.signedFile.id, trustsCreationStore.idEdition, otherDocs.id)
      .then(() => trustsCreationStore.validateExecuted())
  }

  const handleDeleteAmendment = () => {
    const { deedsStore } = trustsCreationStore
    const amendment = deedsStore.draftSecondaryLetter

    signedDocumentsStore
      .deleteAmendment(amendment.signedFile.id, trustsCreationStore.idEdition, amendment.id)
      .then(() => trustsCreationStore.validateExecuted())
  }

  const handleDeleteLod = () => {
    const { lettersStore } = trustsCreationStore
    const letterOfDistribution = lettersStore.draftSecondaryLetter

    signedDocumentsStore
      .deleteLod(
        letterOfDistribution.signedFile.id,
        trustsCreationStore.idEdition,
        letterOfDistribution.id
      )
      .then(() => trustsCreationStore.validateExecuted())
  }

  const { deedsStore, lettersStore, otherDocsStore } = trustsCreationStore
  const deed = deedsStore.draftMainLetter
  const amendment = deedsStore.draftSecondaryLetter
  const letterOfWishes = lettersStore.draftMainLetter
  const letterOfDistribution = lettersStore.draftSecondaryLetter
  const otherDocs = otherDocsStore.draftMainLetter

  return (
    <>
      {deed || letterOfWishes || amendment || letterOfDistribution || otherDocs ? (
        <>
          <DynamicFormSubtitle label={t('subtitleEdit')} />
          {deed && (
            <DocumentForSignRow
              file={deed.file}
              signedFile={deed.signedFile}
              errors={getSignedErrors(deed)}
              section={t('deedOfTrust')}
              documentName={deed.file.name}
              sectionTitle={deed.title.value}
              handleFinishUploadingFile={handleFinishUploadingDot}
              handleBeforeDeletion={handleDeleteDot}
            />
          )}
          {letterOfWishes && (
            <DocumentForSignRow
              file={letterOfWishes.file}
              signedFile={letterOfWishes.signedFile}
              errors={getSignedErrors(letterOfWishes)}
              section={t('letterOfWishes')}
              documentName={letterOfWishes.file.name}
              sectionTitle={letterOfWishes.title.value}
              handleFinishUploadingFile={handleFinishUploadingLow}
              handleBeforeDeletion={handleDeleteLow}
            />
          )}
          {amendment && (
            <DocumentForSignRow
              file={amendment.file}
              signedFile={amendment.signedFile}
              errors={getSignedErrors(amendment)}
              section={t('amendment')}
              documentName={amendment.file.name}
              sectionTitle={amendment.title.value}
              handleFinishUploadingFile={handleFinishUploadingAmendment}
              handleBeforeDeletion={handleDeleteAmendment}
            />
          )}
          {letterOfDistribution && (
            <DocumentForSignRow
              file={letterOfDistribution.file}
              signedFile={letterOfDistribution.signedFile}
              errors={getSignedErrors(letterOfDistribution)}
              section={t('letterOfDistribution')}
              documentName={letterOfDistribution.file.name}
              sectionTitle={letterOfDistribution.title.value}
              handleFinishUploadingFile={handleFinishUploadingLod}
              handleBeforeDeletion={handleDeleteLod}
            />
          )}
          {otherDocs && (
            <DocumentForSignRow
              file={otherDocs.file}
              signedFile={otherDocs.signedFile}
              errors={getSignedErrors(otherDocs)}
              section={t('otherDocuments')}
              documentName={otherDocs.file.name}
              sectionTitle={otherDocs.title.value}
              handleFinishUploadingFile={handleFinishUploadingOther}
              handleBeforeDeletion={handleDeleteOther}
            />
          )}
        </>
      ) : (
        <NoResultsList showIcon={false} topText={t('noNewDocumentsToSign')} />
      )}
    </>
  )
}

export default observer(SignedDocumentsEdit)
