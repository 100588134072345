import NavBarStore from 'stores/NavBarStore'
import SettingsStore from 'stores/SettingsStore'
import AuthStore from 'stores/AuthStore'
import HotkeyStore from 'stores/HotkeyStore'
import TrustsCreationStore from 'stores/TrustsCreationStore'
import UsersCreationStore from 'stores/UsersCreationStore'
import CorporationsCreationStore from 'stores/CorporationsCreationStore'
import ClientsCreationStore from 'stores/ClientsCreationStore'
import CreationParametersStore from 'stores/CreationParametersStore'
import InputFileStore from 'stores/InputFileStore'
import InputContactsFileStore from 'stores/InputContactsFileStore'
import TrustProtectorCategoriesStore from 'stores/TrustProtectorCategoriesStore'
import ContactsCreationStore from 'stores/ContactsCreationStore'

class RootStore {
  constructor() {
    this.settingsStore = new SettingsStore()
    this.authStore = new AuthStore(this)
    this.hotkeyStore = new HotkeyStore()
    this.inputFileStore = new InputFileStore()
    this.inputContactsFileStore = new InputContactsFileStore()
    this.trustsCreationStore = new TrustsCreationStore(this.authStore)
    this.usersCreationStore = new UsersCreationStore()
    this.contactsCreationStore = new ContactsCreationStore(this.authStore)
    this.corporationsCreationStore = new CorporationsCreationStore(this.authStore)
    this.clientsCreationStore = new ClientsCreationStore(this.authStore)
    this.creationParametersStore = new CreationParametersStore()
    this.trustProtectorCategoriesStore = new TrustProtectorCategoriesStore()
    this.currentDynamicStore = null
    this.navBarStore = new NavBarStore()
  }

  resetInputFileStore() {
    this.inputFileStore = new InputFileStore()
  }

  resetInputContactsFileStore() {
    this.inputContactsFileStore = new InputContactsFileStore()
  }

  resetUsersCreation() {
    this.usersCreationStore = new UsersCreationStore()
  }

  resetTrustsCreation() {
    this.trustsCreationStore = new TrustsCreationStore(this.authStore)
  }

  resetCorporationsCreation() {
    this.corporationsCreationStore = new CorporationsCreationStore(this.authStore)
  }

  resetClientsCreation() {
    this.clientsCreationStore = new ClientsCreationStore(this.authStore)
  }

  resetContactsCreation() {
    this.contactsCreationStore = new ContactsCreationStore(this.authStore)
  }

  setCurrentDynamicStore(store) {
    this.currentDynamicStore = store
  }
}

export default RootStore
