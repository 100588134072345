import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StoresContext from 'providers/storeContext'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'
import DownloadDocument from 'presentation/DownloadDocument'

const SignedDocumentsView = () => {
  const { t } = useTranslation('signedDocuments')
  const { corporationsCreationStore } = useContext(StoresContext)

  const statute = corporationsCreationStore.statutesStore.activeMainLetter

  return (
    <>
      <DynamicFormSubtitle label={t('subtitleView')} />
      {statute && (
        <DownloadDocument
          name={t('statute')}
          file={statute.signedFile}
          documentTitle={statute.title.value}
        />
      )}
    </>
  )
}

export default observer(SignedDocumentsView)
