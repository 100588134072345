import { makeAutoObservable } from 'mobx'

class AuthUser {
  constructor(id, token, firstName, lastName, keys, email, roles, legals) {
    this.id = id
    this.token = token
    this.firstName = firstName
    this.lastName = lastName
    this.keys = keys
    this.email = email
    this.roles = roles
    this.legals = legals
    this.profileImage = null
    this.permissions = []

    makeAutoObservable(this)
  }

  static fromJson({
    id,
    firstName,
    lastName,
    permissions = [],
    keys,
    profileImage,
    jwt,
    email,
    roles,
    legals,
  }) {
    const authUser = new AuthUser(id, jwt, firstName, lastName, keys, email, roles, legals)

    if (permissions.length > 0) {
      permissions.forEach((permission) => authUser.addPermission(permission))
    }

    if (profileImage) {
      authUser.setProfileImage(profileImage[0])
    }

    return authUser
  }

  updateFromJson({ profileImage, firstName, lastName }) {
    this.profileImage = profileImage ? profileImage[0] : null
    this.firstName = firstName
    this.lastName = lastName
  }

  addPermission(permission) {
    this.permissions.push(permission)
  }

  setProfileImage(profileImage) {
    this.profileImage = profileImage
  }

  setToken(token) {
    this.token = token
  }

  get userName() {
    if (this.firstName && this.lastName) {
      return `${this.firstName.charAt(0)}. ${this.lastName}`
    }

    return null
  }
}

export default AuthUser
