import axios from 'axios'
import { API_URL } from 'services/config'

const { CancelToken } = axios
let cancel

/* eslint-disable class-methods-use-this */
class FileService {
  cancelUpload = () => {
    if (cancel !== undefined) {
      cancel()
    }
  }

  uploadFile = (file, progress) => {
    if (cancel) {
      cancel()
    }

    // Crea el objeto de guardado
    const data = new FormData()

    data.append('file', file)

    // return axios.post(`${API_URL}/file/upload`, data, {}).then(response => response.data.data)
    return axios
      .post(`${API_URL}/tenant/files`, data, {
        onUploadProgress: progress,
        cancelToken: new CancelToken((c) => {
          cancel = c
        }),
      })
      .then((response) => {
        cancel = null
        return response.data.data
      })
  }

  uploadContactsFile = (file, progress) => {
    if (cancel) {
      cancel()
    }

    // Crea el objeto de guardado
    const data = new FormData()

    data.append('file', file)

    // return axios.post(`${API_URL}/file/upload`, data, {}).then(response => response.data.data)
    return axios
      .post(`${API_URL}/tenant/contacts/import`, data, {
        onUploadProgress: progress,
        cancelToken: new CancelToken((c) => {
          cancel = c
        }),
      })
      .then((response) => {
        cancel = null
        return response.data.data
      })
  }

  getSecureUrl = (id) => {
    return axios.get(`${API_URL}/tenant/files/grant/${id}`).then((response) => response.data.data)
  }
}

export default FileService
