import React from 'react'
import { useTranslation } from 'react-i18next'
import PencilIcon from 'mdi-react/PencilIcon'
import styles from './draftEditionButton.module.scss'

const DraftEditionButton = () => {
  const { t } = useTranslation('common')
  return (
    <div className={styles.button}>
      <PencilIcon size={15} />
      {t('draft')}
    </div>
  )
}

export default DraftEditionButton
