import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './mainCreationContainer.module.scss'

const MainCreationContainer = ({ isLoading, children }) => (
  <div className={c(styles.wrapper, isLoading && styles.hidden)}>{children}</div>
)

MainCreationContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
}

MainCreationContainer.defaultProps = {
  isLoading: false,
}

export default MainCreationContainer
