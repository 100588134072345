const screenSizesMatches = (size) => {
  const screen = window.innerWidth

  if (size === 'lg' && screen >= 1240) {
    return false
  }

  if (size === 'md' && screen >= 992) {
    return false
  }

  if (size === 'sm' && screen >= 768) {
    return false
  }

  return true
}

export default screenSizesMatches
