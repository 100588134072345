import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TrustAssetsStore from 'models/FormMetadata/Parts/AssetTrustPartStore'
import AssetCompanyPartStore from 'models/FormMetadata/Parts/AssetCompanyPartStore'
import AssetValuationInformationItem from 'presentation/AssetValuationInformationItem'
import NoResultsList from 'presentation/NoResultsList'

const AssetInformation = ({ store, readOnly }) => {
  const { t } = useTranslation('corporationsCreation')

  if (store.stores?.selected?.value?.assets) {
    return store.stores?.selected?.value?.assets?.map((asset) => (
      <AssetValuationInformationItem key={asset.id} asset={asset} literal={readOnly} />
    ))
  }

  if (store.selected?.assets) {
    return store.selected?.assets?.map((asset) => (
      <AssetValuationInformationItem key={asset.id} asset={asset} literal={readOnly} />
    ))
  }
  return <NoResultsList topText={t('noResult')} />
}

AssetInformation.propTypes = {
  store: PropTypes.oneOfType([
    PropTypes.instanceOf(TrustAssetsStore),
    PropTypes.instanceOf(AssetCompanyPartStore),
  ]).isRequired,
  readOnly: PropTypes.bool,
}

AssetInformation.defaultProps = {
  readOnly: false,
}
export default AssetInformation
