import { observable, action, computed, override, runInAction, reaction, makeObservable } from 'mobx'
import i18next from 'i18next'
import moment from 'moment'
import TrustService from 'services/TrustService'
import TrustStatus from 'models/TrustStatus'
import TransactionCollisionError from 'models/Error/TransactionCollisionError'
import { countPartErrors } from 'util/validation'
import AsyncStore from 'stores/AsyncStore'
import LettersStore from 'stores/LettersStore'
import DeedsStore from 'stores/DeedsStore'
import OtherDocumentsStore from 'stores/OtherDocumentsStore'
import RulesStore from 'scenes/TrustsCreation/RulesSection/RulesStore'
import DynamicFormsStore from './DynamicFormsStore'

const SIGNED_DOCUMENTS_ERROR = `One or more Signed Documents are missing, you must upload them to store the trust.`

class TrustsCreationStore extends DynamicFormsStore {
  trustJson = null
  firstLoad = true
  isDirtyGeneral = false
  isDirtyLows = false
  isDirtyDots = false
  isDirtyOthers = false
  showSaveToContinue = false
  visiblePublishingModal = false
  loadingStoreTrust = false
  visibleFreezeModal = false
  visibleBackToDraftModal = false
  visibleChangeStatusModal = false
  visibleRollBackModal = false
  freezeDeed = true
  freezeLow = true
  freezeLod = true
  freezeAmendment = true
  freezeOtherDocuments = true
  validationErrors = []
  visibleValidationModal = false
  loadingValidation = false
  loadingRollBack = false
  alternativeStatusLoad = false
  forceGeneralDataSend = false
  visibleErrors = true
  deedError = 0
  lowError = 0
  otherDocumentsError = 0
  signedDocsErrors = 0
  transactionData = {}
  transactionCollisions = []
  visibleTransactionCollisionsModal = false

  // deeds
  deedsStore

  // letter of wishes
  lettersStore

  // other documents
  otherDocsStore

  trustAlternativeStatus = null

  constructor(authStore) {
    super(authStore)

    makeObservable(this, {
      // observables
      firstLoad: observable,
      isDirtyGeneral: observable,
      isDirtyLows: observable,
      isDirtyDots: observable,
      isDirtyOthers: observable,
      showSaveToContinue: observable,
      visiblePublishingModal: observable,
      loadingStoreTrust: observable,
      visibleFreezeModal: observable,
      visibleBackToDraftModal: observable,
      visibleChangeStatusModal: observable,
      visibleRollBackModal: observable,
      freezeDeed: observable,
      freezeLow: observable,
      freezeLod: observable,
      freezeAmendment: observable,
      freezeOtherDocuments: observable,
      validationErrors: observable,
      visibleValidationModal: observable,
      loadingValidation: observable,
      loadingRollBack: observable,
      alternativeStatusLoad: observable,
      forceGeneralDataSend: observable,
      visibleErrors: observable,
      deedError: observable,
      lowError: observable,
      otherDocumentsError: observable,
      signedDocsErrors: observable,
      transactionData: observable,
      trustAlternativeStatus: observable,
      transactionCollisions: observable,
      visibleTransactionCollisionsModal: observable,
      // actions
      init: action,
      setAlternativeStatusLoad: action,
      resetValidationErrors: action,
      reloadData: action,
      setFirstLoad: action,
      resetAttributes: override,
      fillEditData: action,
      save: action,
      validateTrust: action,
      tryToGoBackToDraft: action,
      goBackToDraft: action,
      tryToChangeStatus: action,
      tryToRollBack: action,
      successRollBackModal: action,
      cancelRollBackModal: action,
      changeStatusFromDraft: action,
      changeStatusOther: action,
      changeStatusToStored: action,
      changeNextStatus: action,
      changeStatusSharedFunctionality: action,
      resetDirty: action,
      setDeedError: action,
      clearDeedError: action,
      setLowError: action,
      clearLowError: action,
      setOtherDocumentsError: action,
      clearOtherDocumentsError: action,
      validateDeed: action,
      validateOtherDocuments: action,
      validateLows: action,
      validateSignedFile: action,
      validateExecuted: action,
      validateLetterStore: action,
      validate: action,
      showValidationModal: action,
      hideValidationModal: action,
      validateFirstSection: action,
      showFreezeModal: action,
      hideFreezeModal: action,
      showModalBackToDraft: action,
      hideModalBackToDraft: action,
      showChangeStatusModal: action,
      hideChangeStatusModal: action,
      showPublishingModal: action,
      hidePublishingModal: action,
      toggleFreezeLow: action,
      toggleFreezeDeed: action,
      toggleFreezeOtherDocuments: action,
      toggleFreezeLod: action,
      toggleFreezeAmendment: action,
      checkFirstStepComplete: action,
      verifyCollisions: action,
      // computeds
      showChangeStatusError: computed,
      isDirty: computed,
      hasAlternativeStatus: computed,
      goBackShouldUpdateMetadata: computed,
    })
  }

  prepare() {
    super.setEntityNameKey('trustName')
    super.setEntityStatus(new TrustStatus())

    this.trustService = new TrustService()
    this.deedsStore = new DeedsStore(this)
    this.lettersStore = new LettersStore(this)
    this.rulesStore = new RulesStore(this)
    this.otherDocsStore = new OtherDocumentsStore(this)
    this.trustAlternativeStatus = null
    this.forceGeneralDataSend = false
  }

  async init(id, viewMode, alternativeStatusLoad) {
    this.preRequest(() => this.init(id, viewMode, alternativeStatusLoad))
    // resets atributos
    this.resetAttributes()
    this.setAlternativeStatusLoad(alternativeStatusLoad)

    try {
      if (id) {
        this.trustJson = await this.trustService.getTrust(id, alternativeStatusLoad)
      }
      // loads trusts metadata
      const metadata = await this.trustService.loadMetadata(
        this.trustJson?.id,
        this.trustJson?.dataId
      )

      runInAction(() => {
        // processes the trust metadata into classes
        super.processMetadata(metadata)

        // if is edition
        if (id !== null) {
          // sets edition
          this.setIsEdition(id)
          this.reloadData()
          this.toggleViewMode(viewMode)
        } else {
          this.onSuccessRequest()
          this.detectTrustChanges()
        }

        this.firstLoad = false
      })
    } catch (e) {
      this.onErrorRequest(e)
      this.firstLoad = false
    }
  }

  setAlternativeStatusLoad(val) {
    this.alternativeStatusLoad = val
  }

  resetValidationErrors() {
    this.validationErrors = []
  }

  reloadData(validate = true, successCallback = null) {
    this.preRequest(() => this.reloadData(validate))
    this.firstLoad = false
    this.resetValidationErrors()
    this.deedError = 0
    this.lowError = 0
    this.signedDocsErrors = 0

    // inits deeds store and starts detecting letter changes
    this.fillEditData().then(() => {
      // starts detecting trust changes
      this.detectTrustChanges()

      // inits letters store and starts detecting letter changes
      this.lettersStore.initialize(this.alternativeStatusLoad).then(() => {
        this.detectTrustChangesLetters()

        this.deedsStore.initialize(this.alternativeStatusLoad).then(() => {
          this.detectTrustChangesDots()

          this.otherDocsStore.initialize(this.alternativeStatusLoad).then(() => {
            this.detectTrustChangesOthers()

            if (validate) {
              this.validate()
            }
            this.resetDirty()
          })
        })
      })

      if (successCallback) {
        successCallback()
      }

      this.onSuccessRequest()
    })
  }

  setFirstLoad() {
    this.firstLoad = true
  }

  resetAttributes() {
    super.resetAttributes()
    super.setEntityStatus(new TrustStatus())

    this.isDirtyGeneral = false
    this.isDirtyLows = false
    this.isDirtyDots = false
    this.isDirtyOthers = false
    this.showSaveToContinue = false
    this.loadingValidation = false
    this.freezeDeed = true
    this.freezeOtherDocuments = true
    this.freezeLow = true
    this.visibleErrors = true
    this.freezeLod = true
    this.visibleRollBackModal = false
    this.validationErrors = []
    this.loadingRollBack = false
    this.freezeAmendment = true
    this.trustAlternativeStatus = null
    this.alternativeStatusLoad = false
    this.forceGeneralDataSend = false
  }

  detectTrustChanges() {
    /**
     * sets reaction to detect changes on any part of the trust except for lows and dots
     */
    reaction(
      () => JSON.stringify(this.getDataJson()),
      (data) => {
        /* al guardar, el datajson devuelve un objeto vacio, hago una validacion para ignorar ese caso */
        if (Object.keys(data).length > 2 && !this.isDirtyGeneral) {
          this.isDirtyGeneral = true
        }
      }
    )
  }

  detectTrustChangesLetters() {
    /**
     * sets reaction to detect changes on lows
     */
    reaction(
      () => JSON.stringify(this.getLowsJson()),
      (data) => {
        if (Object.keys(data).length > 2 && !this.isDirtyLows) {
          this.isDirtyLows = true
        }
      }
    )
  }

  detectTrustChangesDots() {
    /**
     * sets reaction to detect changes on dots
     */
    reaction(
      () => JSON.stringify(this.getDotsJson()),
      (data) => {
        if (Object.keys(data).length > 2 && !this.isDirtyDots) {
          this.isDirtyDots = true
        }
      }
    )
  }

  detectTrustChangesOthers() {
    reaction(
      () => JSON.stringify(this.getOthersJson()),
      (data) => {
        if (Object.keys(data).length > 2 && !this.isDirtyOthers) {
          this.isDirtyOthers = true
        }
      }
    )
  }

  async fillEditData() {
    this.preRequest(this.fillEditData)

    try {
      if (this.idEdition) {
        this.trustJson = await this.trustService.getTrust(
          this.idEdition,
          this.alternativeStatusLoad
        )
      }

      const additionalData = await this.trustService.getAdditionalTrustData(this.idEdition)

      runInAction(() => {
        super.setLastSaveDate(moment(this.trustJson.updated.at))
        this.entityStatus.setStatus(this.trustJson.status.currentData.status)

        this.trustAlternativeStatus = null

        if (this.trustJson.status.alternativeData !== null) {
          this.trustAlternativeStatus = new TrustStatus(
            this.trustJson.status.alternativeData.status
          )
        }

        // seteo checksum y blockchainAddress obtenidas del pedido de additionalData
        this.trustJson.data.checksum = additionalData.checksum
        this.trustJson.data.blockchainAddress = additionalData.address

        this.fillDataInputs(this.trustJson.data)
        super.setStep1Finished()
      })

      return this.trustJson
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }

    return null
  }

  getJson() {
    return {
      id: this.idEdition,
      data: this.isDirtyGeneral || this.forceGeneralDataSend ? this.getDataJson() : null,
      lowSection: this.isDirtyLows ? this.lettersStore.getJson() : null,
      deedSection: this.isDirtyDots ? this.deedsStore.getJson() : null,
      otherDocumentSection: this.isDirtyOthers ? this.otherDocsStore.getJson() : null,
    }
  }

  getDataJson() {
    const json = {}

    this.inputs.forEach((input, key) => {
      json[key] = input.json
    })

    return json
  }

  getLowsJson() {
    return this.lettersStore.getJson()
  }

  getDotsJson() {
    return this.deedsStore.getJson()
  }

  getOthersJson() {
    return this.otherDocsStore.getJson()
  }

  async save(successCallback) {
    if (this.isEdition) {
      await this.validate()
    }

    const validFirstSection = await this.validateFirstSection()

    runInAction(() => {
      this.showSaveToContinue = false
    })

    if (!validFirstSection) {
      return
    }

    runInAction(() => {
      this.loadingSave = true
    })

    this.requestProcess(() => this.save(successCallback))

    try {
      let id = this.idEdition
      if (this.isDirty || (this.entityStatus.isStored && !this.trustAlternativeStatus)) {
        const json = this.getJson()
        const response = await this.trustService.saveTrust(json, id)
        id = response.id
      }

      runInAction(() => {
        super.setStep1Finished()
        this.loadingSave = false

        super.setLastSaveDate(moment())

        this.resetDirty()

        successCallback(id)
      })
    } catch (e) {
      runInAction(() => {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        } else {
          this.validationErrors = e.response.data.error.errors
          this.showValidationModal()
        }
        this.loadingSave = false
      })
    }
  }

  async validateTrust(successCallback, errorCallback) {
    this.requestProcess(() => this.validateTrust(successCallback, errorCallback))
    this.loadingSave = true
    this.loadingValidation = true
    this.resetValidationErrors()

    try {
      await this.trustService.validateTrust(this.idEdition)

      runInAction(() => {
        successCallback()
        this.loadingSave = false
        this.loadingValidation = false
      })
    } catch (e) {
      runInAction(() => {
        this.loadingSave = false
        this.loadingValidation = false

        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        } else {
          this.validationErrors = e.response.data.error.errors
          errorCallback()
        }
      })
    }
  }

  tryToGoBackToDraft() {
    this.showModalBackToDraft()
  }

  async updateMetadata(id, successCallback = () => {}) {
    await this.trustService.updateMetadata(id)
    successCallback()
  }

  goBackToDraft(successCallback) {
    this.forceGeneralDataSend = true
    this.save((id) => {
      this.updateMetadata(id, successCallback)
    })
    this.hideModalBackToDraft()
  }

  async tryToChangeStatus() {
    await this.validate()

    if (this.validationErrors.length) {
      this.showValidationModal()
    } else if (this.entityStatus.isDraft) {
      runInAction(() => {
        this.loadingValidation = true
      })

      this.save(() => {
        this.reloadData(true, () => {
          this.validateTrust(
            () => {
              this.loadingValidation = false
              this.showFreezeModal()
            },
            () => {
              this.loadingValidation = false
              this.showValidationModal()
            }
          )
        })
      })
    } else if (this.entityStatus.isExecuted || this.entityStatus.isExecution) {
      this.showChangeStatusModal()
    }
  }

  tryToRollBack() {
    this.visibleRollBackModal = true
  }

  async successRollBackModal(successCallback) {
    this.visibleRollBackModal = false
    this.loadingRollBack = true

    this.requestProcess(() => this.successRollBackModal(successCallback))
    try {
      await this.trustService.changeBackStatus(this.idEdition)
      let updatedMetadata

      if (this.goBackShouldUpdateMetadata)
        updatedMetadata = await this.trustService.updateMetadata(this.idEdition)

      runInAction(async () => {
        if (updatedMetadata) super.processMetadata(updatedMetadata)
        this.loadingRollBack = false

        successCallback()
      })
    } catch (e) {
      runInAction(() => {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        }

        this.loadingRollBack = false
      })
    }
  }

  cancelRollBackModal() {
    this.visibleRollBackModal = false
  }

  countPartErrors(part) {
    if (part.id === 'deed' && this.deedError > 0) {
      return this.deedError
    }

    if (
      part.id === 'trustDocuments' &&
      (this.otherDocumentsError > 0 || this.deedError > 0 || this.lowError > 0)
    ) {
      return this.otherDocumentsError + this.deedError + this.lowError
    }

    if (part.id === 'low' && this.lowError > 0) {
      return this.lowError
    }

    if (part.id === 'snapshot') {
      return 0
    }

    return countPartErrors(part, this.inputs)
  }

  changeStatusFromDraft(successCallback) {
    const jsonParams = {}
    let deedId = null
    let lowId = null
    const lods = []
    const amendments = []
    let otherDocumentId = null

    if (
      (this.freezeDeed && this.deedsStore.draftMainLetter !== null) ||
      !this.hasAlternativeStatus
    ) {
      deedId = this.deedsStore.draftMainLetter.id
    }

    if (this.freezeLow && this.lettersStore.draftMainLetter !== null) {
      lowId = this.lettersStore.draftMainLetter.id
    }

    if (this.freezeLod && this.lettersStore.draftSecondaryLetter !== null) {
      lods.push(this.lettersStore.draftSecondaryLetter.id)
    }

    if (this.freezeAmendment && this.deedsStore.draftSecondaryLetter !== null) {
      amendments.push(this.deedsStore.draftSecondaryLetter.id)
    }

    if (this.freezeOtherDocuments && this.otherDocsStore.draftMainLetter !== null) {
      otherDocumentId = this.otherDocsStore.draftMainLetter.id
    }

    jsonParams.deedId = deedId
    jsonParams.lowId = lowId
    jsonParams.otherDocumentId = otherDocumentId
    jsonParams.lods = lods
    jsonParams.amendments = amendments

    this.changeStatusSharedFunctionality(jsonParams, successCallback)
  }

  changeStatusOther(successCallback) {
    // move other statuses
    const jsonParams = {}

    this.changeStatusSharedFunctionality(jsonParams, successCallback)
  }

  showTransactionCollisionsModal() {
    this.visibleTransactionCollisionsModal = true
  }

  hideTransactionCollisionsModal() {
    this.visibleTransactionCollisionsModal = false
  }

  async verifyCollisions() {
    this.transactionCollisions = await this.trustService.verifyTransactionCollisions(this.idEdition)

    if (this.transactionCollisions.length) throw new TransactionCollisionError('Has Collisions')
  }

  async deleteCollisions() {
    try {
      const ids = this.transactionCollisions.map(({ activity }) => ({ id: activity.id }))

      await this.trustService.deleteTransactionCollisions(ids)
      this.transactionCollisions = []
    } catch (e) {
      if (AsyncStore.isServerError(e)) this.setServerError()
    }
  }

  async changeStatusToStored() {
    this.loadingStoreTrust = true

    try {
      await this.verifyCollisions()

      this.changeStatusOther(() => {
        this.showPublishingModal()
        this.loadingStoreTrust = false
      })
    } catch (e) {
      if (AsyncStore.isServerError(e)) {
        this.setServerError()
      } else if (e instanceof TransactionCollisionError) this.showTransactionCollisionsModal()
    }
  }

  async changeStatusToPendingToApproval(successCallback) {
    this.loadingStoreTrust = true

    this.changeStatusOther(() => {
      this.loadingStoreTrust = false
      successCallback()
    })
  }

  async changeNextStatus(successCallback) {
    if (this.entityStatus.isDraft) {
      this.changeStatusFromDraft(successCallback)
    } else if (this.entityStatus.isExecuted) {
      this.changeStatusToPendingToApproval(successCallback)
    } else {
      this.changeStatusOther(successCallback)
    }
  }

  async changeStatusSharedFunctionality(jsonParams, successCallback) {
    this.loadingSave = true
    this.loadingValidation = true

    this.requestProcess(() => this.changeStatusSharedFunctionality(jsonParams, successCallback))

    try {
      await this.trustService.changeNextStatus(this.idEdition, jsonParams)

      runInAction(() => {
        this.loadingSave = false
        this.loadingValidation = false

        successCallback()
      })
    } catch (e) {
      runInAction(() => {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        } else {
          this.validationErrors = e.response.data.error.errors
          this.showValidationModal()
        }
        this.loadingSave = false
        this.loadingValidation = false
      })
    }
  }

  resetDirty() {
    this.isDirtyGeneral = false
    this.isDirtyLows = false
    this.isDirtyDots = false
  }

  setDeedError(errorCount) {
    this.deedError = errorCount
  }

  setOtherDocumentsError(errorCount) {
    this.otherDocumentsError = errorCount
  }

  clearDeedError() {
    this.deedError = 0
  }

  clearOtherDocumentsError() {
    this.otherDocumentsError = 0
  }

  setLowError(errorCount) {
    this.lowError = errorCount
  }

  clearLowError() {
    this.lowError = 0
  }

  validateDeed() {
    if (this.deedsStore) {
      this.deedsStore.validate()

      if (this.deedsStore.isValid) {
        this.clearDeedError()
      } else {
        this.setDeedError(this.deedsStore.totalErrors)
      }
    }
  }

  validateOtherDocuments() {
    if (this.otherDocsStore) {
      this.otherDocsStore.validate()
    }

    if (this.otherDocsStore.isValid) {
      this.clearOtherDocumentsError()
    } else {
      this.setOtherDocumentsError(this.otherDocsStore.totalErrors)
    }
  }

  validateLows() {
    if (this.lettersStore) {
      this.lettersStore.validate()

      if (this.lettersStore.isValid) {
        this.clearLowError()
      } else {
        this.setLowError(this.lettersStore.totalErrors)
      }
    }
  }

  get showChangeStatusError() {
    return this.validationErrors.length > 0
  }

  validateSignedFile(signedFile) {
    if (!signedFile.id) {
      signedFile.setError(true, 'requiredFile')
      this.signedDocsErrors += 1
    }
  }

  validateExecuted() {
    if (this.entityStatus.isExecuted) {
      this.signedDocsErrors = 0

      this.validateLetterStore(this.deedsStore)
      this.validateLetterStore(this.lettersStore)
      this.validateLetterStore(this.otherDocsStore)

      if (this.signedDocsErrors === 0 && this.validationErrors.includes(SIGNED_DOCUMENTS_ERROR)) {
        this.validationErrors = this.validationErrors.filter(
          (error) => error !== SIGNED_DOCUMENTS_ERROR
        )
      } else if (
        this.signedDocsErrors > 0 &&
        !this.validationErrors.includes(SIGNED_DOCUMENTS_ERROR)
      ) {
        this.validationErrors.push(SIGNED_DOCUMENTS_ERROR)
      }
    }
  }

  validateLetterStore(letterStore) {
    if (letterStore) {
      const { draftMainLetter } = letterStore
      const { draftSecondaryLetter } = letterStore

      if (draftMainLetter) {
        draftMainLetter.signedFile.clearError()
        this.validateSignedFile(draftMainLetter.signedFile)
      }

      if (draftSecondaryLetter) {
        draftSecondaryLetter.signedFile.clearError()
        this.validateSignedFile(draftSecondaryLetter.signedFile)
      }
    }
  }

  async validate() {
    this.visibleErrors = true
    this.validateDeed()
    this.validateOtherDocuments()
    this.validateLows()
    this.validateExecuted()

    const errors = await [...this.inputs.values()].reduce(async (validationErrors, input) => {
      const previous = await validationErrors
      if (input.validate) {
        try {
          await input.validate()
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      return previous
    }, [])

    this.resetValidationErrors()

    this.entitySections.forEach((trustSection) => {
      const error = this.countPartErrors(trustSection, this.inputs)

      if (error > 0) {
        const section = trustSection.menuLabel
        runInAction(() => {
          this.validationErrors.push(i18next.t('validation:sectionError', { section }))
        })
      }
    })

    if (this.signedDocsErrors) {
      this.validationErrors.push(SIGNED_DOCUMENTS_ERROR)
    }

    return !errors.length
  }

  showValidationModal() {
    this.visibleValidationModal = true
  }

  hideValidationModal() {
    this.visibleValidationModal = false
  }

  async validateFirstSection() {
    /*
    valida especificamente la primer seccion, pero de forma no dinamica, tener en cuenta
    */

    if (this.entitySections.length === 0) {
      return false
    }

    let isValid = true
    if (this.entitySections.length > 0) {
      if (!this.inputs.get('trustName').store.value) {
        this.inputs.get('trustName').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }

      if (!this.inputs.get('governingLawTrust').store.value) {
        this.inputs
          .get('governingLawTrust')
          .store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }

      if (!this.inputs.get('trustType').store.value) {
        this.inputs.get('trustType').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
    }

    return isValid
  }

  get isDirty() {
    if (!this.entityStatus.isDraft) {
      return false
    }

    return (
      this.isDirtyGeneral ||
      this.isDirtyLows ||
      this.isDirtyDots ||
      this.isDirtyOthers ||
      (!this.trustAlternativeStatus && this.forceGeneralDataSend)
    )
  }

  showFreezeModal() {
    this.visibleFreezeModal = true
  }

  hideFreezeModal() {
    this.visibleFreezeModal = false
  }

  showModalBackToDraft() {
    this.visibleBackToDraftModal = true
  }

  hideModalBackToDraft() {
    this.visibleBackToDraftModal = false
  }

  showChangeStatusModal() {
    this.visibleChangeStatusModal = true
  }

  hideChangeStatusModal() {
    this.visibleChangeStatusModal = false
  }

  showPublishingModal() {
    this.visiblePublishingModal = true
  }

  hidePublishingModal() {
    this.visiblePublishingModal = false
  }

  toggleFreezeLow() {
    this.freezeLow = !this.freezeLow
  }

  toggleFreezeDeed() {
    this.freezeDeed = !this.freezeDeed
  }

  toggleFreezeOtherDocuments() {
    this.freezeOtherDocuments = !this.freezeOtherDocuments
  }

  toggleFreezeLod() {
    this.freezeLod = !this.freezeLod
  }

  toggleFreezeAmendment() {
    this.freezeAmendment = !this.freezeAmendment
  }

  checkFirstStepComplete() {
    if (this.subMenuDisabled && this.validateFirstSection()) {
      this.showSaveToContinue = true
    }
  }

  get isViewMode() {
    if (super.isEdition) {
      return false
    }

    if (this.entityStatus?.isDraft) {
      return this.viewMode
    }

    return true
  }

  get hasAlternativeStatus() {
    return this.trustAlternativeStatus !== null || this.alternativeStatusLoad
  }

  get goBackShouldUpdateMetadata() {
    return this.entityStatus.isExecution
  }
}

export default TrustsCreationStore
