import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import ContactsService from 'services/ContactsService'
import File from 'models/File'

class ContactsListStore extends AsyncStore {
  contacts = []
  activePage = 1
  pages = 5
  activeSearch = false
  showDropdownOrder = false
  showDropdownFilter = false
  visibleCreateContactModal = false
  visibleConfirmDelete = false
  idContactDelete = ''
  lastSearch = ''
  lastOrderParam = ''
  lastOrderDir = ''
  templateFile = new File()
  templateUrl = ''

  constructor() {
    super()

    this.isLoading = true
    this.contactsService = new ContactsService()

    makeObservable(this, {
      // observables
      contacts: observable,
      activePage: observable,
      pages: observable,
      activeSearch: observable,
      showDropdownOrder: observable,
      visibleCreateContactModal: observable,
      visibleConfirmDelete: observable,
      idContactDelete: observable,
      templateUrl: observable,
      // actions
      init: action,
      loadContacts: action,
      delecteContact: action,
      toggleCreateContactModal: action,
      changeActivePage: action,
      hideConfirmDelete: action,
      showConfirmDelete: action,
      setContactId: action,
      setShowDropdownOrder: action,
      revokeTemplateUrl: action,
      // computeds
      visibleContacts: computed,
    })

    this.init()
  }

  init() {
    this.loadContacts()
  }

  async loadContacts(searchKey = null, orderParam = null, orderDir = null, resetpage = false) {
    this.activeSearch = searchKey !== '' && searchKey !== null

    this.preRequest(this.loadContacts)

    try {
      if (searchKey || searchKey === '') {
        this.lastSearch = searchKey
      }

      if (orderParam || orderParam === '') {
        this.lastOrderParam = orderParam
      }

      if (orderDir || orderDir === '') {
        this.lastOrderDir = orderDir
      }

      if (resetpage) {
        this.activePage = 1
      }

      const response = await this.contactsService.listContact(
        this.activePage,
        this.lastSearch,
        this.lastOrderParam,
        this.lastOrderDir
      )

      runInAction(() => {
        this.contacts = response.contacts
        this.pages = response.pagination.totalPages

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  get visibleContacts() {
    return this.contacts
  }

  async delecteContact() {
    this.isLoading = true

    await this.contactsService.deleteContact(this.idContactDelete)

    try {
      runInAction(() => {
        this.init()
        this.isLoading = false
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.isLoading = false
        this.onErrorRequest(e)
      })
    }
  }

  async downloadTemplate() {
    try {
      this.preRequest()
      const fileUrl = await this.contactsService.downloadTemplate()

      this.onSuccessRequest()
      runInAction(() => {
        this.templateUrl = fileUrl
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  revokeTemplateUrl() {
    if (this.templateUrl) {
      URL.revokeObjectURL(this.templateUrl)
      this.templateUrl = ''
    }
  }

  toggleCreateContactModal() {
    this.visibleCreateContactModal = !this.visibleCreateContactModal
  }

  changeActivePage(pageNumber, orderParam, orderDir) {
    this.activePage = pageNumber

    this.loadContacts(null, orderParam, orderDir)
  }

  hideConfirmDelete() {
    this.visibleConfirmDelete = false
  }

  showConfirmDelete() {
    this.visibleConfirmDelete = true
  }

  setContactId(value) {
    this.idContactDelete = value
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }

  setShowDropdownFilter(value) {
    this.showDropdownFilter = value
  }
}

export default ContactsListStore
