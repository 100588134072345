import { makeAutoObservable, observable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import instanceEntityTextReplacer from 'util/instanceEntityTextReplacer'
import FileFormPartStore from 'models/FormMetadata/Parts/FileFormPartStore'
import FileListFormPartStore from 'models/FormMetadata/Parts/FileListFormPartStore'
import PartStrategy from './PartStrategy'

class InstanceEntity {
  parts = []
  inputs = observable.map()

  constructor(type, parts, entity, isStored = false) {
    this.id = uuidv4()
    this.type = type
    this.entity = entity
    this.inputs = observable.map()
    this.isStored = isStored

    parts.forEach((part) => this.addPart(PartStrategy.getPart(part, this.onCreateInput)))

    makeAutoObservable(this)
  }

  setId(id) {
    this.id = id
  }

  addPart(part) {
    this.parts.push(part)
  }

  onCreateInput = (id, input) => {
    this.inputs.set(id, input)
  }

  setIsStored(value) {
    this.isStored = value
  }

  static createFromInstanceEntity(instanceEntity) {
    const newInstance = new InstanceEntity(
      instanceEntity.type,
      [],
      instanceEntity.entity,
      instanceEntity.isStored
    )

    newInstance.fillFromInstanceEntity(instanceEntity)

    return newInstance
  }

  fillFromInstanceEntity(instanceEntity) {
    instanceEntity.parts.forEach((part) => this.addPart(part))

    instanceEntity.inputs.forEach((input, key) => this.inputs.set(key, input.createFromCurrent()))

    this.setId(instanceEntity.id)
  }

  fillFromContact(contact) {
    Object.entries(contact.rawData).forEach(([key, value]) => {
      if (this.inputs.has(key)) {
        if (
          this.inputs.get(key) instanceof FileFormPartStore ||
          this.inputs.get(key) instanceof FileListFormPartStore
        ) {
          this.inputs.get(key).fill(value)
        } else {
          this.inputs.get(key).store.setValue(value)
        }
      }
    })

    if (this.entity.contactMapExceptions) {
      this.entity.contactMapExceptions.forEach((contactMapException) => {
        if (this.inputs.has(contactMapException.to)) {
          let value = null

          Object.entries(contact.rawData).forEach(([key, loopValue]) => {
            if (key === contactMapException.from) {
              value = loopValue
            }
          })

          if (value === null) {
            Object.entries(contact).forEach(([key, loopValue]) => {
              if (key === contactMapException.from) {
                value = loopValue
              }
            })
          }
          this.inputs.get(contactMapException.to).store.setValue(value)
        }
      })
    }
  }

  updateFromEntity(instanceEntity) {
    this.fillFromInstanceEntity(instanceEntity)
  }

  fillFromJson(data) {
    this.inputs.forEach((input, key) => input.fill(data[key]))
  }

  get json() {
    const json = {
      id: this.id,
      type: this.type,
    }

    this.inputs.forEach((value, key) => {
      json[key] = value.json
    })

    return json
  }

  get fullName() {
    const { mainText } = this.entity.listView

    return instanceEntityTextReplacer(this, mainText)
  }

  get isValid() {
    const invalidInputs = [...this.inputs.values()].filter((input) => input.isValid === false)

    return !invalidInputs.length
  }

  get validationCountErrors() {
    const validationCountErrors = [...this.inputs.values()].filter(
      (input) => input.isValid === false
    )

    return validationCountErrors.length
  }

  validate() {
    this.inputs.forEach((input) => input.validate())
  }
}

export default InstanceEntity
