import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import { corporationsCreationRoute } from 'routing/routes'
import ListCorporation from 'models/ListCorporation'
import StatusName from 'presentation/StatusName'
import TableItemDate from 'presentation/TableItemDate'
import FiduciaryStructureActionsButtonList from 'presentation/FiduciaryStructureActionsButtonList'
import UpdateIcon from 'mdi-react/UpdateIcon'
import CorporationTableItemPartyName from './CorporationTableItemPartyName'
// import styles from './corporationsTableItem.module.scss'
import styles from '../FiduciaryStructuresTableItem/trustsTableItem.module.scss'

const CorporationsTableItem = ({
  listItem,
  link,
  view,
  isTransaction,
  isCorp,
  handleTransactionsLink,
  handleContentChangeLink,
  handleExpenseLink,
  handleValuationChange,
}) => {
  const history = useHistory()

  const handlePermissionLink = useCallback((corpId) => {
    history.push(corporationsCreationRoute(corpId))
  }, [])

  return (
    <NavLink
      className={[styles.trustTableItem, styles[`trustTableItem--${view}`]].join(' ')}
      to={link}
    >
      <div className={styles.trustTableItemName}>{listItem.name !== '' && `${listItem.name}`}</div>
      <div className={styles.trustTableItemSettlorName}>
        <CorporationTableItemPartyName
          listItem={listItem}
          controllingParties={listItem.controllingParties}
          view={view}
        />
      </div>
      <div className={styles.trustTableItemCreatedAt}>
        <TableItemDate listDate={listItem.created} view={view} icon={<UpdateIcon size={12} />} />
      </div>
      <div className={styles.trustTableItemUpdatedAt}>
        <TableItemDate listDate={listItem.updated} view={view} icon={<UpdateIcon size={12} />} />
      </div>
      <div className={styles.trustTableItemStatus}>
        <StatusName status={listItem.status} alternativeStatus={listItem.alternativeStatus} />
      </div>
      <FiduciaryStructureActionsButtonList
        listItem={listItem}
        view={view}
        handleTransactionsLink={handleTransactionsLink}
        handleExpenseLink={handleExpenseLink}
        handleValuationChange={handleValuationChange}
        handlePermissionLink={handlePermissionLink}
        handleContentChangeLink={handleContentChangeLink}
        isTransaction={isTransaction}
        isCorp={isCorp}
      />
    </NavLink>
  )
}

CorporationsTableItem.propTypes = {
  listItem: PropTypes.instanceOf(ListCorporation).isRequired,
  link: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  isTransaction: PropTypes.bool.isRequired,
  isCorp: PropTypes.bool,
  handleExpenseLink: PropTypes.func,
  handleContentChangeLink: PropTypes.func,
  handleValuationChange: PropTypes.func,
  handleTransactionsLink: PropTypes.func,
}

CorporationsTableItem.defaultProps = {
  handleExpenseLink: () => {},
  handleContentChangeLink: () => {},
  handleValuationChange: () => {},
  handleTransactionsLink: () => {},
  isCorp: false,
}

export default CorporationsTableItem
