import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Button from 'presentation/Button'
import CreateEntityModal from 'scenes/DynamicForm/CreateEntityModal'
import styles from './transactionsValuationForm.module.scss'

const TransactionsValuationForm = ({ instanceEntity, onCancelForm, onSaveForm, setButtons }) => {
  const { t } = useTranslation('transactions')
  const handleModalCancel = useCallback(() => {
    onCancelForm()
  }, [])

  const handleSave = useCallback(() => {
    onSaveForm()
  }, [])

  useEffect(() => {
    setButtons([
      <Button label={t('save')} primary className={styles.primaryButton} onClick={handleSave} />,
      <Button label={t('cancel')} secondary onClick={handleModalCancel} />,
    ])
  }, [])

  return (
    <div>
      {instanceEntity && (
        <CreateEntityModal
          editionInstanceEntity={instanceEntity}
          readOnly={false}
          handleCancel={handleModalCancel}
          handleAddEntity={handleSave}
        />
      )}
    </div>
  )
}

TransactionsValuationForm.propTypes = {
  instanceEntity: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  onCancelForm: PropTypes.func,
  onSaveForm: PropTypes.func,
  setButtons: PropTypes.func,
}

TransactionsValuationForm.defaultProps = {
  instanceEntity: null,
  onCancelForm: () => {},
  onSaveForm: () => {},
  setButtons: () => {},
}

export default observer(TransactionsValuationForm)
