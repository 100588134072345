import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { USERS_CREATION } from 'routing/routes'
import { observer } from 'mobx-react'
import styles from './deleteRoleErrors.module.scss'

const DeleteRoleErrors = observer(({ errors }) => {
  const { t } = useTranslation('rolesCreation')
  const history = useHistory()

  const handleGoToUser = useCallback((id) => history.push(`${USERS_CREATION}?id=${id}&v=true`), [])

  return (
    <div className={styles.deleteRoles}>
      {errors.map((error) => {
        if (error.id && error.name) {
          return (
            <div>
              {'- '}
              <span
                className={styles.name}
                onClick={(e) => {
                  e.preventDefault()
                  handleGoToUser(error.id)
                }}
                role="button"
                tabIndex="0"
              >
                {error.name}
              </span>
              {t('usingRole')}
            </div>
          )
        }
        return null
      })}
    </div>
  )
})

DeleteRoleErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

DeleteRoleErrors.defaultProps = {
  errors: [],
}

export default DeleteRoleErrors
