import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import TransactionsStore from 'stores/TransactionsStore'
import { Tab, TabsWizard } from 'presentation/Tabs'
import { MainSectionTable } from 'presentation/MainSectionList'
import FiduciaryStructuresTableItem from 'presentation/FiduciaryStructuresTableItem'
import CorporationsTableItem from 'presentation/CorporationsTableItem'
import { useTranslation } from 'react-i18next'
import { TRUST_TYPE, CORP_TYPE } from 'stores/FiduciaryStructurePreloadDataStore'

const TransactionsTabs = ({
  handleTabChange,
  trustColumns,
  corpsColumns,
  transactionsStore,
  view,
  handleTrustOrderChange,
  handleCorpOrderChange,
  handleTransactionsLink,
  handleValuationChange,
  handleContentChange,
  handleVotingLink,
  handleExpenseLink,
  defaultActiveTab,
}) => {
  const { t } = useTranslation('transactions')
  const [showTrustDropdownOrder, setShowTrustDropdownOrder] = useState(false)
  const [showCorpDropdownOrder, setShowCorpDropdownOrder] = useState(false)

  const handleTrustShowDropdownOrder = useCallback(() => {
    setShowTrustDropdownOrder(true)
  }, [])

  const handleCorpShowDropdownOrder = useCallback(() => {
    setShowCorpDropdownOrder(true)
  }, [])

  const handleOrderChangeTrusts = useCallback((sortField, sortDirection) => {
    setShowTrustDropdownOrder(false)
    handleTrustOrderChange(sortField, sortDirection)
  }, [])

  const handleOrderChangeCorps = useCallback((sortField, sortDirection) => {
    setShowCorpDropdownOrder(false)
    handleCorpOrderChange(sortField, sortDirection)
  }, [])

  return (
    <>
      <TabsWizard hideButtons onChangeTab={handleTabChange} defaultActiveTab={defaultActiveTab}>
        <Tab name={t('trusts')}>
          <MainSectionTable
            t={t}
            orderInputStore={transactionsStore.order}
            listItems={transactionsStore.storedTrusts}
            columns={trustColumns}
            isLoading={transactionsStore.isLoading}
            view={view}
            showDropdownOrder={showTrustDropdownOrder}
            setShowDropdownOrder={handleTrustShowDropdownOrder}
            onOrdered={handleOrderChangeTrusts}
            component={({ listItem, view: cView }) => (
              <FiduciaryStructuresTableItem
                link="#"
                handleTransactionsLink={() => handleTransactionsLink(listItem.id, TRUST_TYPE)}
                handleContentChangeLink={() => handleContentChange(listItem.id, TRUST_TYPE)}
                handleValuationChange={() => handleValuationChange(listItem.id, TRUST_TYPE)}
                handleVotingLink={() => handleVotingLink(listItem.id, TRUST_TYPE)}
                handleExpenseLink={() => handleExpenseLink(listItem.id, TRUST_TYPE)}
                hasActions
                isTransaction
                listItem={listItem}
                view={cView}
              />
            )}
          />
        </Tab>

        <Tab name={t('corporations')}>
          <MainSectionTable
            t={t}
            orderInputStore={transactionsStore.order}
            listItems={transactionsStore.storedCorps}
            columns={corpsColumns}
            isLoading={transactionsStore.isLoading}
            view={view}
            showDropdownOrder={showCorpDropdownOrder}
            setShowDropdownOrder={handleCorpShowDropdownOrder}
            onOrdered={handleOrderChangeCorps}
            component={({ listItem, view: cView }) => (
              <CorporationsTableItem
                link="#"
                listItem={listItem}
                view={cView}
                isTransaction
                handleTransactionsLink={() => handleTransactionsLink(listItem.id, CORP_TYPE)}
                handleContentChangeLink={() => handleContentChange(listItem.id, CORP_TYPE)}
                handleValuationChange={() => handleValuationChange(listItem.id, CORP_TYPE)}
                handleVotingLink={() => handleVotingLink(listItem.id, CORP_TYPE)}
                handleExpenseLink={() => handleExpenseLink(listItem.id, CORP_TYPE)}
              />
            )}
          />
        </Tab>
      </TabsWizard>
    </>
  )
}

TransactionsTabs.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  trustColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      order: PropTypes.bool,
      inverted: PropTypes.bool,
      transName: PropTypes.string,
      cssGridDefinition: PropTypes.string,
    })
  ).isRequired,
  corpsColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      order: PropTypes.bool,
      inverted: PropTypes.bool,
      transName: PropTypes.string,
      cssGridDefinition: PropTypes.string,
    })
  ).isRequired,
  view: PropTypes.string.isRequired,
  transactionsStore: PropTypes.instanceOf(TransactionsStore),
  handleTransactionsLink: PropTypes.func,
  handleContentChange: PropTypes.func,
  handleValuationChange: PropTypes.func,
  handleVotingLink: PropTypes.func,
  handleExpenseLink: PropTypes.func,
  handleTrustOrderChange: PropTypes.func,
  handleCorpOrderChange: PropTypes.func,
  defaultActiveTab: PropTypes.number.isRequired,
}

TransactionsTabs.defaultProps = {
  transactionsStore: () => {},
  handleTransactionsLink: () => {},
  handleContentChange: () => {},
  handleVotingLink: () => {},
  handleExpenseLink: () => {},
  handleTrustOrderChange: () => {},
  handleCorpOrderChange: () => {},
  handleValuationChange: () => {},
}

export default TransactionsTabs
