import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CHANGE_PASSWORD } from 'routing/routes'
import isMobile from 'util/browser'
import KeyVariantIcon from 'mdi-react/KeyVariantIcon'
import ExpandableButton from 'presentation/ExpandableButton'
import Button from 'presentation/Button'
import styles from './profilePassword.module.scss'

const ProfilePassword = () => {
  const history = useHistory()
  const { t } = useTranslation('userProfile')
  const handleChangePassword = useCallback(() => history.push(CHANGE_PASSWORD), [])

  if (isMobile()) {
    return (
      <div className={styles.changePassword}>
        <Button
          onClick={handleChangePassword}
          className={styles.changePasswordButton}
          label={t('changePassword')}
          iconPositionLeft
          icon={<KeyVariantIcon size={20} />}
        />
      </div>
    )
  }

  return (
    <div className={styles.changePassword}>
      <ExpandableButton
        onClick={handleChangePassword}
        className={styles.changePasswordButton}
        label={t('changePassword')}
        Icon={<KeyVariantIcon size={20} />}
        big
      />
    </div>
  )
}

export default ProfilePassword
