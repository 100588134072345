import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import StoresContext from 'providers/storeContext'
import HistorySubMenu from 'presentation/HistorySubMenu/HistorySubMenu'
import { SubMenu } from './SubMenuUtils'

const SubMenuHistory = ({ id: idTrust, collapsed }) => {
  const { trustsCreationStore, navBarStore } = useContext(StoresContext)

  useEffect(() => {
    if (trustsCreationStore.errorLoadEdit) {
      navBarStore.setForcedOnlyMainNavBar()
    }
  }, [trustsCreationStore.errorLoadEdit])

  useEffect(() => {
    trustsCreationStore.prepare()
    trustsCreationStore.init(idTrust, true, false)
  }, [])

  if (trustsCreationStore.isLoading || trustsCreationStore.errorLoadEdit) {
    return null
  }

  return (
    <SubMenu collapsed={collapsed}>
      <HistorySubMenu store={trustsCreationStore} collapsed={collapsed} idTrust={idTrust} />
    </SubMenu>
  )
}

SubMenuHistory.propTypes = {
  id: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
}

export default observer(SubMenuHistory)
