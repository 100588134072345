import React from 'react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './transactionDashboadCard.module.scss'

const TransactionDashboadCard = ({ name, updated, status }) => {
  const { t } = useTranslation('dashboard')

  return (
    <div className={styles.card}>
      <div className={styles.containerInformation}>
        <div className={styles.name}>{name}</div>
        <div className={styles.containerUpdated}>
          <div className={styles.updated}>{updated}</div>
        </div>
      </div>
      <div className={styles.statusContainer}>
        <span
          className={c(
            styles.bullet,
            status === 'voting' && styles.statusVoting,
            status === 'rejected' && styles.statusRegected,
            status === 'approved' && styles.statusApproved,
            status === 'finished' && styles.statusFinished,
            status === 'stored' && styles.statusStored,
            status === 'pending' && styles.statusPending,
            status === 'complex resolution' && styles.complexResolution
          )}
        />
        <div className={styles.status}>{t(status === 'pending' ? 'common:pending' : status)}</div>
      </div>
    </div>
  )
}

TransactionDashboadCard.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
}

export default TransactionDashboadCard
