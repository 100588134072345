import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import StoresContext from 'providers/storeContext'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer/YesNoToggleContainer'
import LiteralValue from 'presentation/LiteralValue'
import TrustAssetsStore from 'models/FormMetadata/Parts/AssetTrustPartStore'
import styles from './styles.module.scss'

const CreationYesNoToggleContainer = observer(
  ({ className, inputStore, initialValue, onChange, viewMode, label }) => {
    if (initialValue && inputStore.value === null) {
      inputStore.setValue(initialValue)
    }

    return (
      <div className={className}>
        <YesNoToggleContainer
          desc={label}
          value={!!inputStore.value}
          handleChange={() => {
            if (onChange) onChange(!inputStore.value)
          }}
          viewMode={viewMode}
        />
      </div>
    )
  }
)

const TrustInformation = ({ store, readOnly }) => {
  const { creationParametersStore } = useContext(StoresContext)
  const { t } = useTranslation('corporationsCreation')

  const handleChange = (name, value, inputStore) => {
    inputStore.setValue(value)
  }

  const { isNew } = store.selected

  return (
    <div className={styles.trustInformation}>
      {!isNew ? (
        // trust existente
        <div className={styles.trustInformation__content}>
          <div>
            <LiteralValue
              label={t('assetTrust.trustInformation.governingLaw')}
              value={store.stores.governingLaw.value.value || ''}
            />
          </div>
          <div>
            <LiteralValue
              label={t('assetTrust.trustInformation.trustType')}
              value={store.stores.trustType.value.value || ''}
            />
          </div>
        </div>
      ) : (
        // trust nuevo
        <div className={styles.trustInformation__content}>
          <CreationInput
            as={FormSelect}
            placeholder={t('assetTrust.trustInformation.selectGoverningLaw')}
            label={t('assetTrust.trustInformation.governingLaw')}
            inputStore={store.stores.governingLaw}
            onChange={(option) => {
              return handleChange('governingLawTrust', option, store.stores.governingLaw)
            }}
            options={creationParametersStore.getOptions('governingLawTrust')}
            literal={readOnly}
          />
          <CreationInput
            as={FormSelect}
            placeholder={t('assetTrust.trustInformation.selectTrustType')}
            label={t('assetTrust.trustInformation.trustType')}
            inputStore={store.stores.trustType}
            onChange={(option) => handleChange('trustType', option, store.stores.trustType)}
            options={creationParametersStore.getOptions('trustType')}
            literal={readOnly}
          />
        </div>
      )}
      <div>
        <CreationYesNoToggleContainer
          className={styles.trustInformation__YesNoToggle}
          label={t('assetTrust.trustInformation.isDiscretional')}
          inputStore={store.stores.isDiscretional}
          onChange={(value) => handleChange('isDiscretional', value, store.stores.isDiscretional)}
          initialValue={isNew ? store.selected.isDiscretional : store.selected.discretional}
          viewMode={!isNew || readOnly}
        />
        <CreationYesNoToggleContainer
          className={styles.trustInformation__YesNoToggle}
          label={t('assetTrust.trustInformation.isRevocable')}
          inputStore={store.stores.isRevocable}
          onChange={(value) => handleChange('isRevocable', value, store.stores.isRevocable)}
          initialValue={!isNew && store.selected.isRevocable}
          viewMode={!isNew || readOnly}
        />
        <CreationYesNoToggleContainer
          className={styles.trustInformation__YesNoToggle}
          label={t('assetTrust.trustInformation.isEstablished')}
          inputStore={store.stores.isEstablished}
          onChange={(value) => handleChange('isEstablished', value, store.stores.isEstablished)}
          initialValue={!isNew && store.selected.isEstablished}
          viewMode={!isNew || readOnly}
        />
      </div>
    </div>
  )
}

TrustInformation.propTypes = {
  store: PropTypes.instanceOf(TrustAssetsStore).isRequired,
  readOnly: PropTypes.bool,
}

TrustInformation.defaultProps = {
  readOnly: true,
}

export default observer(TrustInformation)
