import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormSelect from 'presentation/FormSelect'
import { observer } from 'mobx-react'
import CreationInput from 'presentation/CreationInput'
import Vote from 'models/Vote'
import InputStore from 'stores/InputStore'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'
import styles from './votersInformationForm.module.scss'

const VotersInformationForm = ({
  vote,
  isEdition,
  votersSelected,
  possibleVoters,
  handleChangeVoter,
  handleChangeApprovalRule,
  handleChangeVotersCanAbstain,
}) => {
  const { t } = useTranslation('transactionsCreation')

  return (
    <div>
      <CreationInput
        as={FormSelect}
        label={t('voters')}
        inputStore={votersSelected}
        placeholder={t('selectVoter')}
        onChange={(options) => handleChangeVoter(options)}
        options={possibleVoters.map((e) => ({
          id: e.id,
          value: `${e.firstName} ${e.lastName}`,
        }))}
        isMulti
        literal={isEdition}
      />
      <div className={styles.formContainer}>
        <YesNoToggleContainer
          viewMode={isEdition}
          desc={t('votersCanAbstain')}
          handleChange={handleChangeVotersCanAbstain}
          value={vote.votersCanAbstain.value || false}
        />
      </div>
      <div className={styles.formContainer}>
        <CreationInput
          literal={isEdition}
          inputStore={vote.approvalRule}
          label={t('approvalRuleLabel')}
          onChange={handleChangeApprovalRule}
          placeholder={t('approvalRulePlaceholder')}
        />
      </div>
    </div>
  )
}

VotersInformationForm.propTypes = {
  isEdition: PropTypes.bool,
  vote: PropTypes.instanceOf(Vote).isRequired,
  handleChangeVoter: PropTypes.func.isRequired,
  handleChangeApprovalRule: PropTypes.func.isRequired,
  possibleVoters: PropTypes.oneOfType([PropTypes.array]),
  handleChangeVotersCanAbstain: PropTypes.func.isRequired,
  votersSelected: PropTypes.instanceOf(InputStore).isRequired,
}

VotersInformationForm.defaultProps = {
  isEdition: false,
  possibleVoters: null,
}

export default observer(VotersInformationForm)
