import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PencilIcon from 'mdi-react/PencilIcon'
import NoResultsList from 'presentation/NoResultsList'
import LettersBaseStore from 'stores/LettersBaseStore'
import LettersSectionSecondaryBaseStore from 'stores/LettersSectionSecondaryBaseStore'
import SecondaryLetterVersions from '../SecondaryLetterVersions'
import SecondaryLetterHistory from '../SecondaryLetterHistory'
import SecondaryLetterFormSection from '../SecondaryLetterFormSection'

const LetterSecondarySection = ({
  mainStore,
  secondarySectionStore,
  isViewMode,
  handleSaveEntity,
  letterTypeName,
  letterTypeNameSingular,
  secondLetterShowRulesAlert,
}) => {
  const { t } = useTranslation('lettersForms')

  const handleViewForm = () => {
    secondarySectionStore.viewForm()
  }

  const setDraftEditionMode = () => {
    secondarySectionStore.showDraftEditionMode()

    handleViewForm()
  }

  const handleCreate = () => {
    mainStore.createSecondaryLetter()

    setDraftEditionMode()
  }

  const handleViewVersions = () => {
    secondarySectionStore.viewVersions()
  }

  const handleViewHistory = () => {
    secondarySectionStore.viewHistory()
  }

  const handleEditDraft = () => {
    handleViewForm()
  }

  const showNoResultsMessage = () => {
    return mainStore.usableSecondaryLetters.length === 0 && !secondarySectionStore.draftEditionMode
  }

  if (showNoResultsMessage()) {
    return (
      <NoResultsList
        onClick={!isViewMode ? handleCreate : null}
        topText={t('noLetterInEffect', { letterTypeName })}
        Icon={PencilIcon}
        buttonText={t('newLetter', { letterTypeName: letterTypeNameSingular })}
        showIcon={!isViewMode}
      />
    )
  }

  if (secondarySectionStore.formActive) {
    return (
      <SecondaryLetterFormSection
        mainStore={mainStore}
        handleViewHistory={handleViewHistory}
        handleViewVersions={handleViewVersions}
        secondarySectionStore={secondarySectionStore}
        isViewMode={isViewMode}
        handleSaveEntity={handleSaveEntity}
        letterTypeName={letterTypeName}
        letterTypeNameSingular={letterTypeNameSingular}
        secondLetterShowRulesAlert={secondLetterShowRulesAlert}
      />
    )
  }

  if (secondarySectionStore.historyActive) {
    return (
      <SecondaryLetterHistory
        mainStore={mainStore}
        handleViewForm={handleViewForm}
        isViewMode={isViewMode}
      />
    )
  }

  if (secondarySectionStore.versionsActive) {
    return (
      <SecondaryLetterVersions
        mainStore={mainStore}
        handleNewLetter={handleCreate}
        handleEditDraft={isViewMode ? null : handleEditDraft}
        isViewMode={isViewMode}
        letterTypeNameSingular={letterTypeNameSingular}
      />
    )
  }

  return null
}

LetterSecondarySection.propTypes = {
  mainStore: PropTypes.instanceOf(LettersBaseStore).isRequired,
  secondarySectionStore: PropTypes.instanceOf(LettersSectionSecondaryBaseStore).isRequired,
  isViewMode: PropTypes.bool.isRequired,
  letterTypeName: PropTypes.string.isRequired,
  letterTypeNameSingular: PropTypes.string.isRequired,
  handleSaveEntity: PropTypes.func.isRequired,
  secondLetterShowRulesAlert: PropTypes.bool.isRequired,
}

export default observer(LetterSecondarySection)
