import { makeObservable, observable, action, runInAction } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import InputStore from 'stores/InputStore'
import TransactionsAssetsService from 'services/TransactionsAssetsService'

class TransactionsStore extends AsyncStore {
  constructor() {
    super()
    this.storedTrusts = []
    this.storedCorps = []
    this.isLoading = false

    // Paginator
    this.activePage = 1
    this.totalTrustPages = 1
    this.totalCorpPages = 1

    // View
    this.viewMode = 'list'

    // Sorter & Filter
    this.showDropdownOrder = false
    this.showDropdownFilter = false
    this.order = new InputStore()
    this.order.setValue({ column: '', orderParam: '' })

    this.transactionsAssetsService = new TransactionsAssetsService()

    makeObservable(this, {
      // observables
      storedTrusts: observable,
      storedCorps: observable,
      activePage: observable,
      totalTrustPages: observable,
      totalCorpPages: observable,
      showDropdownOrder: observable,
      showDropdownFilter: observable,
      viewMode: observable,
      order: observable,
      // actions
      loadStoredTrustList: action,
      setActivePage: action,
      setShowDropdownOrder: action,
      setShowDropdownFilter: action,
      setViewMode: action,
    })
  }

  async loadStoredTrustList(sortField, sortDirection) {
    try {
      this.preRequest(this.loadStoredTrustList)
      this.transactionsAssetsService
        .loadTransactionStructureList('trusts', this.activePage, sortField, sortDirection)
        .then((response) => {
          runInAction(() => {
            this.storedTrusts = response.storedStructures
            this.totalTrustPages = response.pagination.totalPages
            this.onSuccessRequest()
          })
        })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async loadStoredCorpsList(sortField, sortDirection) {
    try {
      this.preRequest(this.loadStoredCorpsList)
      this.transactionsAssetsService
        .loadTransactionStructureList(
          'financialStructures',
          this.activePage,
          sortField,
          sortDirection
        )
        .then((response) => {
          runInAction(() => {
            this.storedCorps = response.storedStructures
            this.totalCorpPages = response.pagination.totalPages
            this.onSuccessRequest()
          })
        })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async sendToBlockchainAll() {
    try {
      this.preRequest()
      return this.transactionsAssetsService.sendToBlockchainAll().then(() => {
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
    throw new Error()
  }

  setActivePage(value) {
    this.activePage = value
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }

  setShowDropdownFilter(value) {
    this.showDropdownFilter = value
  }

  setViewMode(value) {
    this.viewMode = value
  }
}

export default TransactionsStore
