import React, { useCallback, useContext, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { observer } from 'mobx-react'
import { USER_PROFILE } from 'routing/routes'
import StoresContext from 'providers/storeContext'
import MetaTitle from 'util/MetaTitle'
import ImageInput from 'presentation/ImageInput'
import Button from 'presentation/Button'
import ServerError from 'presentation/ServerError'
import PageTitle from 'presentation/PageTitle'
import { useTranslation } from 'react-i18next'
import ProfilePassword from 'presentation/ProfilePassword'
import RequestErrors from 'presentation/RequestErrors'
import LiteralValue from 'presentation/LiteralValue'
import c from 'classnames'
import CloudAlertIcon from 'mdi-react/CloudAlertIcon'
import UserProfileStore from './UserProfileStore'
import styles from './userProfile.module.scss'

const UserProfile = () => {
  const { t } = useTranslation('userProfile')
  const { authStore } = useContext(StoresContext)
  const [userProfileStore] = useState(() => new UserProfileStore(authStore.authUser))
  const { firstName, lastName, email, userName } = authStore.authUser

  const handleEdit = useCallback(() => {
    userProfileStore.toggleViewMode()
  }, [])

  const handleSave = useCallback(() => {
    userProfileStore.save(() => {
      authStore.keepAlive().then(() => {
        userProfileStore.updateAuthUser(authStore.authUser)
        userProfileStore.toggleViewMode()
      })
    })
  }, [])

  const handleCancelEdit = useCallback(() => {
    userProfileStore.revertChanges()
  }, [])

  const handleTryAgain = useCallback(() => userProfileStore.tryAgain(), [])

  if (userProfileStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  return (
    <div className={styles.userProfile}>
      <Prompt
        when={userProfileStore.isDirty}
        message={(location) => {
          if (location.pathname.search(USER_PROFILE) < 0 || location.pathname === USER_PROFILE) {
            return t('leavePagePrompt')
          }

          return true
        }}
      />
      <PageTitle title={t('profile')} />
      <MetaTitle pageTitle={userName} />
      <div className={styles.imageContainer}>
        <ImageInput
          literal={userProfileStore.viewMode}
          inputStore={userProfileStore.profileImage}
        />
      </div>
      <div className={styles.dataContainer}>
        <LiteralValue label={t('firstNameLabel')} value={firstName} />
        <LiteralValue label={t('lastNameLabel')} value={lastName} />
        <LiteralValue label={t('emailLabel')} value={email} />
        {!userProfileStore.viewMode && <ProfilePassword />}
      </div>
      {!userProfileStore.viewMode && (
        <div className={styles.errors}>
          <RequestErrors errors={userProfileStore.requestErrors} />
        </div>
      )}
      <div className={styles.actionsContainer}>
        <div className={styles.saveButtonContainer}>
          <div className={c(styles.unsetChanges, userProfileStore.isDirty ? styles.show : '')}>
            <CloudAlertIcon className={styles.cloudIcon} />
            <span className={styles.unsaved}>{t('unsavedLabel')}</span>
          </div>
          <Button
            isLoading={userProfileStore.isLoadingSave}
            big
            onClick={() => {
              if (userProfileStore.viewMode) {
                handleEdit()
              } else {
                handleSave()
              }
            }}
            primary
            style={{ marginRight: '10px' }}
            label={userProfileStore.viewMode ? t('edit') : t('save')}
          />
        </div>
        {!userProfileStore.viewMode && (
          <Button big onClick={handleCancelEdit} secondary label={t('cancel')} />
        )}
      </div>
    </div>
  )
}

export default observer(UserProfile)
