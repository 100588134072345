import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'scenes/Modal'

const DeleteDraftModal = ({ title, open, onSuccess, onCancel, letterTypeName, showRulesAlert }) => {
  const { t } = useTranslation('lettersForms')

  return (
    <Modal
      name="deleteDraftModal"
      title={title}
      open={open}
      onSuccess={onSuccess}
      onCancel={onCancel}
      closeOutside
    >
      {showRulesAlert && t('revokeDeleteRules', { letterTypeName })}
    </Modal>
  )
}

DeleteDraftModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  letterTypeName: PropTypes.string,
  showRulesAlert: PropTypes.bool,
}

DeleteDraftModal.defaultProps = {
  letterTypeName: null,
  showRulesAlert: true,
}

export default DeleteDraftModal
