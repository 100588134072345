import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import File from 'models/File'
import DownloadFileLink from 'presentation/InputFile/DownloadFileLink'
import styles from './downloadDocument.module.scss'

const DownloadDocument = observer(({ name, file, documentTitle }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.documentData}>
        <div className={styles.name}>{name}</div>
        <div className={styles.documentTitle}>{documentTitle}</div>
      </div>
      <div className={styles.downloadContainer}>
        <div className={styles.fileName}>{file.name}</div>
        <DownloadFileLink fileStore={file} circle />
      </div>
    </div>
  )
})

DownloadDocument.propTypes = {
  name: PropTypes.string.isRequired,
  documentTitle: PropTypes.string.isRequired,
  file: PropTypes.instanceOf(File).isRequired,
}

export default DownloadDocument
