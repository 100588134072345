import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LiteralValue from 'presentation/LiteralValue'
import styles from './documentActivityModalForm.module.scss'

const DocumentActivityModalForm = ({ item }) => {
  const { t } = useTranslation('activity')

  return (
    <div>
      <div className={styles.sectionContainer}>
        <div className={styles.title}>{t('docDetails')}</div>
        <div className={styles.docsContainer}>
          <LiteralValue label={t('docsCreated')} value={item.documents.created.length} />
          <LiteralValue label={t('docsEliminated')} value={item.documents.eliminated.length} />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.title}>{t('entityDetails')}</div>
        <div className={styles.docsContainer}>
          <LiteralValue label={t('entityName')} value={item.entity.name} />
          <LiteralValue label={t('entityType')} value={item.entity.type} />
        </div>
      </div>
    </div>
  )
}

DocumentActivityModalForm.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default DocumentActivityModalForm
