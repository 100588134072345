import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import InputStore from 'stores/InputStore'
import AsyncStore from 'stores/AsyncStore'
import CorporationService from 'services/CorporationService'
import moment from 'moment'
import Amount from 'models/Asset/Amount'
import Document from 'models/Document'
import PercentageFormPartStore from './PercentageFormPartStore'

class AssetCompanyPartStore extends AsyncStore {
  validationRules
  valueStore = new Amount()
  stores = {
    assetName: new InputStore(),
    assetType: new InputStore(),
    companyId: new InputStore(),
    companyName: new InputStore(),
    fullName: new InputStore(),
    governingLaw: new InputStore(),
    companyType: new InputStore(),
    contactName: new InputStore(),
    documents: new Document(),
    email: new InputStore(),
    phone: new InputStore(),
    value: this.valueStore,
    moneyCurrency: this.valueStore.currency,
    moneyValue: this.valueStore.value,
    valuationDate: new InputStore(),
    role: new InputStore(),
    dataStatus: new InputStore(),
    ownershipPercentage: new PercentageFormPartStore(),
  }

  list = []
  selected = null

  constructor() {
    super()

    makeObservable(this, {
      // observables
      list: observable,
      selected: observable,
      // actions
      loadData: action,
      setFullName: action,
      select: action,
      // computeds
      elementOptions: computed,
      selectedOption: computed,
    })

    this.isLoading = true
    this.corporationService = new CorporationService()
  }

  // Full Name Select
  async loadData(searchKey = '') {
    this.preRequest()
    // @TODO request correcto, con todos los datos
    try {
      const response = await this.corporationService.listAssetCorporation(searchKey)

      runInAction(() => {
        this.list = response.map(
          ({ id, name, governingLaw, financialStructureType, ownershipPercentage, ...rest }) => ({
            id,
            name,
            isNew: false,
            governingLaw,
            companyType: financialStructureType,
            ownershipPercentage,
            ...rest,
          })
        )

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  get elementOptions() {
    if (this.isLoading) return []

    let list = this.list.map((e) => ({ id: e.id, value: e.name, label: e.name }))

    if (this.stores.fullName && this.stores.fullName.value) {
      const element = this.list.filter((e) => e.id === this.stores.fullName.value.id)
      if (element.length === 0) list = list.concat([this.stores.fullName.value])
    }

    if (this.selected && !list.map((e) => e.id).includes(this.selected.id)) {
      list.push({
        id: this.selected.id,
        label: this.selected.name,
        value: this.selected.name,
      })
    }

    return list
  }

  get selectedOption() {
    if (!this.selected) return null
    return this.elementOptions.reduce((c, e) => (e.id === this.selected.id ? e : c), null)
  }

  setFullName(value) {
    this.stores.fullName.setValue(value)
    this.stores.assetName.setValue(value.label)
  }

  select(element) {
    this.setFullName({
      id: element.id,
      label: element.name,
      value: element.name,
    })

    const data = this.list.filter((e) => e.id === element.id)[0]

    if (data || element.isNew) this.clearInputs()

    if (!data || element.isNew) {
      this.selected = { ...element, isNew: true }
      return
    }
    this.setInputs(data)
    this.selected = data
  }

  setInputs(data) {
    const keys = Object.keys(data)
    for (let i = 0; i < keys.length; i += 1) {
      const currentKey = keys[i]
      const currentData = data[currentKey]
      const currentStore = this.stores[currentKey]

      if (currentStore && currentData) {
        switch (currentKey) {
          case 'ownershipPercentage':
            currentStore.store.setValue(currentData)
            break
          case 'documents':
            currentStore.fillFromJson(currentData)
            break
          case 'valuationDate':
            currentStore.setValue(moment(currentData))
            break
          case 'value':
            break
          default:
            currentStore.setValue(currentData)
        }
      }
    }
  }

  clearInputs() {
    const keys = Object.keys(this.stores)
    const dontClear = ['assetName', 'fullName', 'assetType', 'ownershipPercentage']
    for (let i = 0; i < keys.length; i += 1) {
      if (this.stores[keys[i]]) {
        if (!dontClear.includes(keys[i]) && this.stores[keys[i]].setValue) {
          this.stores[keys[i]].setValue('')
        }
      }
    }
  }

  fill(data) {
    this.setInputs(data)
    if (data.selected) this.selected = { ...data.selected }
  }

  createFromCurrent() {
    const newAssetCompanyPartStore = new AssetCompanyPartStore()
    const data = this.json
    newAssetCompanyPartStore.fill(data)
    return newAssetCompanyPartStore
  }

  get json() {
    const current = {
      id: this.selected.id,
      name: this.selected.name,
      selected: this.selected,
    }
    const keys = Object.keys(this.stores)
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === 'ownershipPercentage') {
        current[keys[i]] = this.stores[keys[i]].store.json
      } else if (keys[i] === 'documents') {
        current[keys[i]] = this.stores[keys[i]].getJson()
      } else if (keys[i] === 'value') {
        current[keys[i]] = this.stores[keys[i]].json
      } else {
        const { value } = this.stores[keys[i]]
        current[keys[i]] = value instanceof Object ? JSON.parse(JSON.stringify(value)) : value
      }
    }

    return current
  }

  setValidationRules(validationRules) {
    this.validationRules = validationRules
  }

  // eslint-disable-next-line class-methods-use-this
  get validationSchema() {
    return true
  }

  // eslint-disable-next-line class-methods-use-this
  async validate() {
    return Promise.resolve(true)
  }

  // eslint-disable-next-line class-methods-use-this
  get isValid() {
    return true
  }
}

export default AssetCompanyPartStore
