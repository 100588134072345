import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import SearchIcon from 'mdi-react/SearchIcon'
import Button from 'presentation/Button'
import styles from './noResultsList.module.scss'

const NoResultsList = ({
  topText,
  bottomText,
  showIcon,
  onClick,
  Icon,
  buttonText,
  secondButtonOnClick,
  secondButtonText,
}) => (
  <div className={c(styles.notFoundList)}>
    {showIcon && Icon && (
      <Icon onClick={onClick} size={40} className={c(onClick && styles.onClick)} />
    )}
    {showIcon && !Icon && (
      <SearchIcon onClick={onClick} size={45} className={c(onClick && styles.onClick)} />
    )}
    <div
      onClick={onClick}
      className={c(styles.topText, onClick && styles.onClick)}
      role="button"
      tabIndex="0"
    >
      {topText}
    </div>
    {bottomText && (
      <div onClick={onClick} className={c(onClick && styles.onClick)} role="button" tabIndex="0">
        {bottomText}
      </div>
    )}
    {buttonText && onClick && (
      <Button label={buttonText} onClick={onClick} small className={styles.button} />
    )}
    {secondButtonText && secondButtonOnClick && (
      <Button
        label={secondButtonText}
        onClick={secondButtonOnClick}
        className={styles.button}
        secondary
        small
      />
    )}
  </div>
)

NoResultsList.propTypes = {
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string,
  showIcon: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  secondButtonOnClick: PropTypes.func,
  secondButtonText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PropTypes.any,
}

NoResultsList.defaultProps = {
  bottomText: null,
  showIcon: true,
  onClick: null,
  Icon: null,
  buttonText: null,
  secondButtonOnClick: null,
  secondButtonText: null,
}

export default NoResultsList
