import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import CreationInput from 'presentation/CreationInput'
import TrustAssetsStore from 'models/FormMetadata/Parts/AssetTrustPartStore'
import AssetCompanyPartStore from 'models/FormMetadata/Parts/AssetCompanyPartStore'
import styles from './styles.module.scss'

const ContactInformation = ({ store, readOnly }) => {
  const { t } = useTranslation('corporationsCreation')

  const handleChange = (name, value, inputStore) => {
    inputStore.setValue(value)
  }

  return (
    <div className={styles.contactInformationTab}>
      <CreationInput
        inputStore={store.stores.contactName}
        label={t('asset.contactInformation.contactName')}
        placeholder={t('asset.contactInformation.typeInContactName')}
        onChange={(e) => handleChange('contactName', e.target.value, store.stores.contactName)}
        literal={readOnly}
      />
      <CreationInput
        inputStore={store.stores.email}
        label={t('asset.contactInformation.email')}
        placeholder={t('asset.contactInformation.typeInEmail')}
        onChange={(e) => handleChange('email', e.target.value, store.stores.email)}
        literal={readOnly}
      />
      <CreationInput
        inputStore={store.stores.phone}
        label={t('asset.contactInformation.phone')}
        placeholder={t('asset.contactInformation.typeInPhone')}
        onChange={(e) => handleChange('phone', e.target.value, store.stores.phone)}
        literal={readOnly}
      />
    </div>
  )
}

ContactInformation.propTypes = {
  store: PropTypes.oneOfType([
    PropTypes.instanceOf(TrustAssetsStore),
    PropTypes.instanceOf(AssetCompanyPartStore),
  ]).isRequired,
  readOnly: PropTypes.bool,
}

ContactInformation.defaultProps = {
  readOnly: true,
}

export default observer(ContactInformation)
