import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { scrollToCoord } from 'util/scroll'
import styles from './pageTitle.module.scss'

const FloatingBar = ({ enabled, title, rightSection, contentRef }) => {
  const [scrolling, setScrolling] = useState(0)

  if (!enabled) return null

  const handleScroll = () => {
    setScrolling(contentRef.current.getBoundingClientRect().top < 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleClickFloatingBar = () => {
    scrollToCoord(0)
  }

  return (
    <div
      onClick={handleClickFloatingBar}
      className={c(styles.floatBar, scrolling && styles.show)}
      role="button"
      tabIndex="0"
    >
      <h2 className={styles.subtitle}>{title}</h2>
      {rightSection && <div className={styles.rightSection}>{rightSection}</div>}
    </div>
  )
}

FloatingBar.propTypes = {
  enabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  contentRef: PropTypes.oneOfType([PropTypes.object]),
  rightSection: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
}

FloatingBar.defaultProps = {
  enabled: false,
  contentRef: null,
  title: null,
  rightSection: '',
}

export default FloatingBar
