import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { TRUSTS_LIST } from 'routing/routes'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import imageError from './imageError.png'

const TrustSectionError = () => {
  const { t } = useTranslation('trustsCreation')
  const history = useHistory()
  const handleGoBack = useCallback(() => history.push(TRUSTS_LIST), [])

  return (
    <GenericNotFoundError
      description={t('trustSectionErrorDesc')}
      textButton={t('trustSectionErrorButton')}
      goBackFunction={handleGoBack}
      title={t('trustSectionErrorTitle')}
      image={imageError}
    />
  )
}

export default TrustSectionError
