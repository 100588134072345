import { observable, action, makeObservable } from 'mobx'
import i18next from 'i18next'
import AsyncStore from 'stores/AsyncStore'

class SignInStore extends AsyncStore {
  username = ''
  password = ''
  rememberMe = false

  constructor(authStore) {
    super()

    makeObservable(this, {
      // observables
      username: observable,
      password: observable,
      rememberMe: observable,
      // actions
      updateUsername: action,
      updatePassword: action,
      signIn: action,
    })

    this.authStore = authStore
  }

  updateUsername(value) {
    this.username = value
  }

  updatePassword(value) {
    this.password = value
  }

  signIn() {
    this.clearErrors()

    if (this.validate()) {
      this.preRequest()

      this.authStore
        .basicLogin(this.username, this.password, this.rememberMe)
        .then(() => {
          this.onSuccessRequest()
        })
        .catch((e) => {
          this.onSuccessRequest()

          if (
            !e.response ||
            (e.response && (e.response.data.status === 422 || e.response.data.status === 401))
          ) {
            this.onErrorRequest(i18next.t('signIn:invalidUsernameOrPassword'))
          } else {
            /* error generico */
            this.onErrorRequest(i18next.t('common:genericError'))
          }
        })
    }
  }

  clearErrors() {
    this.errors = []
  }

  validate() {
    let isValid = true

    if (!this.validateUsername()) {
      isValid = false
    }

    if (!this.validatePassword()) {
      isValid = false
    }

    if (!isValid) {
      this.onErrorRequest(i18next.t('login:completeRequiredFields'))
    }

    return isValid
  }

  validateUsername() {
    return this.username.trim() !== ''
  }

  validatePassword() {
    return this.password.trim() !== ''
  }
}

export default SignInStore
