import { useCallback, useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { TRANSACTIONS } from 'routing/routes'
import MetaTitle from 'util/MetaTitle'
import { CONTENT_TRANSACTION_LIST } from 'stores/AuthStore'
import StoresContext from 'providers/storeContext'
import ServerError from 'presentation/ServerError'
import ActivityTableItem from 'presentation/ActivityTableItem'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import ActivityListStore from './ActivityListStore'
import CustomActivitySearch from './CustomActivitySearch'

const ActivityList = () => {
  const [activityListStore] = useState(() => new ActivityListStore())
  const [columns, setColumns] = useState([])
  const { hotkeyStore } = useContext(StoresContext)
  const { t } = useTranslation('activity')
  const history = useHistory()

  const handleCreateActivity = useCallback(() => history.push(TRANSACTIONS), [])

  const configureHotKeys = useCallback(() => {
    hotkeyStore.setConfig('activitiesList', [
      {
        shortcut: 'alt+c',
        handler: () => {
          handleCreateActivity()
        },
      },
    ])
  }, [])

  useEffect(() => {
    configureHotKeys()

    setColumns([
      {
        name: 'description',
        order: false,
        inverted: true,
        transName: t('activityDescription'),
        cssGridDefinition: '1.5fr',
      },
      {
        name: 'entity',
        order: false,
        inverted: true,
        transName: t('entity'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
    ])

    activityListStore.loadActiveEntities()
    return () => hotkeyStore.removeConfig('activitiesList')
  }, [])

  const handleTryAgain = useCallback(() => activityListStore.tryAgain(), [])
  const handlePageChange = useCallback((pageTo) => activityListStore.changeActivePage(pageTo), [])
  const handleChangeOrder = useCallback((orderParameter, orderDirection) => {
    activityListStore.loadActiveEntities(orderParameter, orderDirection)
  }, [])

  const handleSetShowDropdownOrder = useCallback((value) => {
    activityListStore.setShowDropdownOrder(value)
  }, [])

  const handleChangeToDate = useCallback((value) => {
    activityListStore.setToDate(value)
    activityListStore.loadActiveEntities()
  }, [])

  const handleChangeFromDate = useCallback((value) => {
    activityListStore.setFromDate(value)
    activityListStore.loadActiveEntities()
  }, [])

  const { visibleActivities } = activityListStore

  if (activityListStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  return (
    <>
      <MetaTitle pageTitle={t('pageTitles:activity')} />
      <MainSectionList
        sectionItems={visibleActivities}
        currentPage={activityListStore.activePage}
        isLoading={activityListStore.isLoading}
        handleChangeOrder={handleChangeOrder}
        handlePageChange={handlePageChange}
        creationPermission={CONTENT_TRANSACTION_LIST}
        totalPages={activityListStore.pages}
        handleCreation={handleCreateActivity}
        sectionNameSingular={t('activity')}
        sectionNamePlural={t('activities')}
        setShowDropdownOrder={handleSetShowDropdownOrder}
        customSearchComponent={
          <CustomActivitySearch
            setToDate={handleChangeToDate}
            setFromDate={handleChangeFromDate}
            toDateInputStore={activityListStore.toDate}
            fromDateInputStore={activityListStore.fromDate}
          />
        }
        tableComponent={
          <MainSectionTable
            t={t}
            listItems={visibleActivities}
            isLoading={activityListStore.isLoading}
            onOrdered={handleChangeOrder}
            view="list"
            columns={columns}
            setShowDropdownFilter={() => {}}
            showDropdownOrder={activityListStore.showDropdownOrder}
            setShowDropdownOrder={handleSetShowDropdownOrder}
            component={({ listItem, view: cView }) => (
              <ActivityTableItem view={cView} listItem={listItem} />
            )}
          />
        }
      />
    </>
  )
}

export default observer(ActivityList)
