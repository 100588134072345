import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import PencilIcon from 'mdi-react/PencilIcon'
import HistoryIcon from 'mdi-react/HistoryIcon'
import NoResultsList from 'presentation/NoResultsList'
import { LetterForm, ActionButtons, NewDraftModal } from 'presentation/LettersSection'
import FirstLevelError from 'presentation/FirstLevelError'
import { DocumentSubtitle, DeleteDraftModal } from 'presentation/DocumentsSection'
import LetterFormDraftCard from 'presentation/LetterFormDraftCard'
import { scrollToCoord } from 'util/scroll'
import EntityStatus from 'models/EntityStatus'
import LetterBaseStore from 'stores/LettersBaseStore'
import LettersSectionBaseStore from 'stores/LettersSectionBaseStore'
import styles from './letterFormSection.module.scss'

const LetterFormSection = ({
  handleViewVersions,
  mainStore,
  mainEntityStatus,
  isViewMode,
  letterTypeName,
  mainSectionStore,
  handleSaveEntity,
  mainEntityHasAlternativeStatus,
  handleViewHistory,
  mainFirstLevelError,
  mainSectionCanBeRevoked,
  showRulesAlert,
  validationHandler,
}) => {
  const { t } = useTranslation('lettersForms')

  const setDraftEditionMode = () => {
    scrollToCoord(0)
    mainSectionStore.showDraftEditionMode()
  }

  const handleCreateLetter = (letter = null) => {
    mainStore.createLetter(letter)

    setDraftEditionMode()
  }

  const handleClickNoResults = () => {
    if (mainEntityStatus.isStored) {
      handleViewVersions()
    } else if (!isViewMode) {
      handleCreateLetter()
    }
  }

  const closeNewDraftModal = () => {
    mainSectionStore.hideNewDraftFromModal()
  }

  const createNewDraftFromScratch = () => {
    handleCreateLetter()
    closeNewDraftModal()
    setDraftEditionMode()
  }

  const getNoResultsList = () => {
    const { draftMainLetter } = mainStore

    if (mainEntityStatus.isStored) {
      return (
        <NoResultsList
          onClick={handleClickNoResults}
          topText={t('noLetterInEffect', { letterTypeName })}
          secondButtonText={t('newLetter', { letterTypeName })}
          buttonText={t('viewHistory')}
          Icon={HistoryIcon}
          secondaryButton
          secondButtonOnClick={!isViewMode && !draftMainLetter ? createNewDraftFromScratch : null}
        />
      )
    }

    /* si el trust no esta publicado muestro no results con opcion de crear draft */
    const isOtherType = letterTypeName === 'Other'

    return (
      <NoResultsList
        topText={t(isOtherType ? 'noDocumentCreated' : 'noLetterCreated', { letterTypeName })}
        buttonText={t(isOtherType ? 'createDocument' : 'createLetter', { letterTypeName })}
        onClick={!isViewMode && !draftMainLetter ? handleClickNoResults : null}
        bottomText={!isViewMode && !draftMainLetter ? t('startBy') : null}
        Icon={PencilIcon}
        showIcon={!isViewMode}
      />
    )
  }

  const closeDeleteDraftModal = () => {
    mainSectionStore.hideDeleteDraftModal()
  }

  const unsetDraftEditionMode = () => {
    mainSectionStore.hideDraftEditionMode()
  }

  const successDeleteDraftModal = () => {
    const mainLetter = mainStore.draftMainLetter

    mainLetter.delete()

    closeDeleteDraftModal()
    unsetDraftEditionMode()

    handleSaveEntity()
  }

  const successRevokeModal = () => {
    const mainLetter = mainStore.activeMainLetter

    mainLetter.revoke()

    closeDeleteDraftModal()
    unsetDraftEditionMode()

    handleSaveEntity()
  }

  const handleNewDraft = () => {
    mainSectionStore.showNewDraftFromModal()
  }

  const createNewDraftFromCurrent = () => {
    handleCreateLetter(mainStore.activeMainLetter)
    closeNewDraftModal()
    setDraftEditionMode()
  }

  const handleDeleteDraft = () => {
    mainSectionStore.showDeleteDraftModal()
  }

  const handleRevokeLetter = () => {
    mainSectionStore.showDeleteDraftModal(true)
  }

  const showDraftCard = () => {
    const { draftMainLetter } = mainStore

    // si estoy editando un draft o si el trust que estoy viendo esta stored
    if (mainSectionStore.draftEditionMode || mainEntityStatus.isStored) {
      return false
    }

    // en otros casos devuelvo true si hay letter of wishes
    return draftMainLetter !== null && mainEntityHasAlternativeStatus
  }

  const showNoResultsMessage = () => {
    const { activeMainLetter, draftMainLetter } = mainStore

    if (mainEntityStatus.isWorkingProgress && !mainEntityHasAlternativeStatus) {
      // si el trust esta en draft y no existe un estado alternativo
      return draftMainLetter === null
    }

    if (mainSectionStore.draftEditionMode) {
      return false
    }

    return activeMainLetter === null
  }

  const showExitDraftEditionButton = () => {
    return mainSectionStore.draftEditionMode && mainEntityStatus.isStored
  }

  const getShowLetter = () => {
    const { draftMainLetter, activeMainLetter } = mainStore

    if (mainEntityStatus.isWorkingProgress && !mainEntityHasAlternativeStatus) {
      // si el trust esta en draft y no existe un estado alternativo
      return draftMainLetter
    }

    if (mainEntityStatus.isWorkingProgress && mainEntityHasAlternativeStatus) {
      // si el trust esta en draft y existe un estado alternativo
      if (mainSectionStore.draftEditionMode) {
        return draftMainLetter
      }
    }

    return activeMainLetter
  }

  const { draftMainLetter } = mainStore
  const showLetter = getShowLetter()

  return (
    <>
      {showNoResultsMessage() ? (
        <>
          {mainFirstLevelError && <FirstLevelError description={t('deedFirstLevelError')} />}
          {getNoResultsList()}
        </>
      ) : (
        <>
          {showExitDraftEditionButton() && (
            <DocumentSubtitle
              handleGoBackClick={unsetDraftEditionMode}
              goBackLabel={t('goBackToCurrentLetter', { letterTypeName })}
              title={t('editLetterDraft', { letterTypeName })}
              icon={<PencilIcon size={15} />}
            />
          )}
          <LetterForm
            letter={showLetter}
            viewMode={showLetter.isActive ? true : isViewMode}
            validationHandler={validationHandler}
          />
          <ActionButtons
            letter={showLetter}
            handleDeleteDraft={handleDeleteDraft}
            handleRevokeLetter={mainSectionCanBeRevoked ? handleRevokeLetter : null}
            handleNewDraft={handleNewDraft}
            handleViewHistory={handleViewHistory}
            handleViewVersions={handleViewVersions}
            viewMode={isViewMode}
            showCreateLetter={draftMainLetter === null}
            letterTypeName={letterTypeName}
          />
        </>
      )}
      {showDraftCard() && (
        <div className={styles.draftCardContainer}>
          <LetterFormDraftCard letter={draftMainLetter} onClick={setDraftEditionMode} />
        </div>
      )}
      <DeleteDraftModal
        title={
          mainSectionStore.deleteDraftModalRevoke
            ? t('confirmRevokeLetter', { letterTypeName })
            : t('confirmDeleteDraftDocument')
        }
        open={mainSectionStore.deleteDraftModal}
        onSuccess={
          mainSectionStore.deleteDraftModalRevoke ? successRevokeModal : successDeleteDraftModal
        }
        onCancel={closeDeleteDraftModal}
        letterTypeName={letterTypeName}
        showRulesAlert={showRulesAlert}
      />
      <NewDraftModal
        open={mainSectionStore.newDraftFromModal}
        onCancel={closeNewDraftModal}
        createNewDraftFromCurrent={createNewDraftFromCurrent}
        createNewDraftFromScratch={createNewDraftFromScratch}
        letterTypeName={letterTypeName}
        showRulesAlert={showRulesAlert}
      />
    </>
  )
}

LetterFormSection.propTypes = {
  mainStore: PropTypes.instanceOf(LetterBaseStore).isRequired,
  mainSectionStore: PropTypes.instanceOf(LettersSectionBaseStore).isRequired,
  handleViewHistory: PropTypes.func.isRequired,
  handleViewVersions: PropTypes.func.isRequired,
  handleSaveEntity: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool.isRequired,
  mainEntityStatus: PropTypes.instanceOf(EntityStatus).isRequired,
  mainEntityHasAlternativeStatus: PropTypes.bool,
  letterTypeName: PropTypes.string.isRequired,
  mainSectionCanBeRevoked: PropTypes.bool,
  mainFirstLevelError: PropTypes.bool,
  showRulesAlert: PropTypes.bool,
  validationHandler: PropTypes.func.isRequired,
}

LetterFormSection.defaultProps = {
  mainEntityHasAlternativeStatus: false,
  mainSectionCanBeRevoked: true,
  mainFirstLevelError: false,
  showRulesAlert: true,
}

export default observer(LetterFormSection)
