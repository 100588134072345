import React from 'react'
import PropTypes from 'prop-types'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Button from 'presentation/Button'
import screenSizesMatches from 'util/screen'
import styles from './documentSubtitle.module.scss'

const DocumentSubtitle = ({ title, goBackLabel, handleGoBackClick, icon }) => {
  const isMobile = screenSizesMatches('xs')

  return (
    <div className={styles.subtitleContainer}>
      <div className={styles.title}>
        {icon && icon}
        {title}
      </div>
      <div className={styles.goBackButton}>
        <Button
          label={isMobile ? null : goBackLabel}
          onClick={handleGoBackClick}
          icon={<ArrowLeftIcon size={20} />}
          small
          iconPositionLeft={!isMobile}
          circle={isMobile}
        />
      </div>
    </div>
  )
}

DocumentSubtitle.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  goBackLabel: PropTypes.string.isRequired,
  handleGoBackClick: PropTypes.func.isRequired,
}

DocumentSubtitle.defaultProps = {
  icon: null,
}

export default DocumentSubtitle
