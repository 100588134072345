import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import styles from './TransactionCollisionsModalContent.module.scss'

const TransactionCollisionsModalContent = () => {
  const { t } = useTranslation('trustsCreation')
  const { trustsCreationStore } = useContext(StoresContext)

  return (
    <div className={styles.modalContainer}>
      <div className={styles.message}>{t('collisionSubtitle')}</div>
      {trustsCreationStore &&
        trustsCreationStore.transactionCollisions.map(({ activity }, i) => (
          <div className={styles.errorItem} key={activity.id}>
            {`${i + 1} - ${activity.description}`}
          </div>
        ))}
    </div>
  )
}

export default observer(TransactionCollisionsModalContent)
