class ListView {
  constructor(mainText, extraTypeText, secondaryTextHierarchy) {
    this.mainText = mainText
    this.extraTypeText = extraTypeText
    this.secondaryTextHierarchy = secondaryTextHierarchy
  }

  static fromJson(json) {
    const { mainText = '', extraTypeText = '', secondaryTextHierarchy = [] } = json

    return new ListView(mainText, extraTypeText, secondaryTextHierarchy)
  }
}

export default ListView
