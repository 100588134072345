import React from 'react'
import PropTypes from 'prop-types'
import LinkButton from 'presentation/LinkButton'
import styles from './successLoginMessage.module.scss'

const SuccessLoginMessage = ({ route, textButton, messageFirstLine, messageSecondLine, icon }) => {
  return (
    <div className={styles.success}>
      {icon && <div className={styles.containerIcon}>{icon}</div>}
      <div className={styles.successBox}>{messageFirstLine}</div>
      <div className={styles.successBoxSecondLine}>{messageSecondLine}</div>
      <LinkButton route={route} text={textButton} />
    </div>
  )
}

SuccessLoginMessage.propTypes = {
  icon: PropTypes.node.isRequired,
  messageFirstLine: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  textButton: PropTypes.string.isRequired,
  messageSecondLine: PropTypes.string,
}

SuccessLoginMessage.defaultProps = {
  messageSecondLine: '',
}

export default SuccessLoginMessage
