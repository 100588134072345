import React from 'react'
import PropTypes from 'prop-types'
import styles from './storeTitleName.module.scss'

const StoreTitleName = ({ name }) => {
  return <div className={styles.subtitle}>{name}</div>
}

StoreTitleName.propTypes = {
  name: PropTypes.string,
}

StoreTitleName.defaultProps = {
  name: '',
}

export default StoreTitleName
