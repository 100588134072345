import PropTypes from 'prop-types'
import c from 'classnames'
import styles from '../genericRow.module.scss'

const GenericRowDefaultExtraData = ({ extraData }) => {
  return (
    <>
      {extraData.slice(0, 2).map((data, i) => (
        <div
          className={c(styles.extraData, i > 0 && styles.extraDataSecondary)}
          // eslint-disable-next-line react/no-array-index-key
          key={`extradata_${i}`}
        >
          {data}
        </div>
      ))}
    </>
  )
}

GenericRowDefaultExtraData.propTypes = {
  extraData: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

GenericRowDefaultExtraData.defaultProps = {
  extraData: '',
}
export default GenericRowDefaultExtraData
