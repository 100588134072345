import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import CreationInput from 'presentation/CreationInput'
import DatePicker from 'presentation/DatePicker'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'
import RuleRecurrenceForm from 'presentation/RuleRecurrenceForm'
import Button from 'presentation/Button'
import Rule from 'models/Rule'
import styles from './ruleAdvancedConfigModalForm.module.scss'

const RuleAdvancedConfigModalForm = ({ rule, handleCancel, handleOk, setButtons }) => {
  const { t } = useTranslation('trustsCreation')

  const handleChangeVestingDate = useCallback((date) => {
    rule.setVestingDate(date)
  }, [])

  const handleIsRecurrentChange = useCallback(() => {
    rule.toggleIsRecurrent()
    if (rule.isRecurrent) {
      rule.resetDueDate()
    } else {
      rule.resetRecurrence()
    }
  }, [])

  useEffect(() => {
    const buttons = []

    buttons.push(<Button label={t('ok')} primary onClick={handleOk} className={styles.okButton} />)
    buttons.push(<Button label={t('cancel')} onClick={handleCancel} />)

    setButtons(buttons)
  }, [])

  return (
    <div>
      <h4 className={styles.modalTitle}>{t('schedulingConfig')}</h4>
      {!rule.hasRecurrence ? (
        <CreationInput
          as={DatePicker}
          label={t('vestingDate')}
          placeholder={t('selectVestingDate')}
          inputStore={rule.vestingDate}
          onChange={handleChangeVestingDate}
          minDate={new Date()}
          showYearDropdown
          dropdownMode="select"
        />
      ) : (
        <RuleRecurrenceForm rule={rule} />
      )}
      <div className={styles.yesNoContainer}>
        <YesNoToggleContainer
          label={t('isRecurrent')}
          value={!!rule.isRecurrent.value}
          handleChange={handleIsRecurrentChange}
          desc={t('isRecurrent')}
        />
      </div>
    </div>
  )
}

RuleAdvancedConfigModalForm.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  setButtons: PropTypes.func,
}

RuleAdvancedConfigModalForm.defaultProps = {
  handleCancel: () => {},
  handleOk: () => {},
  setButtons: () => {},
}

export default observer(RuleAdvancedConfigModalForm)
