import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import HotKeys from 'presentation/HotKeys'
import StoresContext from 'providers/storeContext'
import styles from './mainLayout.module.scss'

const MainLayout = ({ sidebar: Sidebar, children }) => {
  const { hotkeyStore } = useContext(StoresContext)

  return (
    <div className={styles.wrapper}>
      <HotKeys config={hotkeyStore.currentConfig}>
        <>
          <Sidebar />
          <main className={styles.main}>{children}</main>
        </>
      </HotKeys>
    </div>
  )
}

MainLayout.propTypes = {
  sidebar: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
}

export default observer(MainLayout)
