import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import c from 'classnames'
import CheckIcon from 'mdi-react/CheckIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import ComplexResolutionTransaction from 'models/ComplexResolutionTransaction'
import styles from './complexResolutionList.module.scss'

const ListTransactionsComplex = ({ transaction }) => {
  const handleApprove = useCallback((selectedTransaction) => selectedTransaction.setApproved(), [])
  const handleReject = useCallback((selectedTransaction) => selectedTransaction.setRejected(), [])
  const handleNewVote = useCallback((selectedTransaction) => selectedTransaction.setNewVote(), [])

  return (
    <>
      <div className={c(styles.literalValue)}>{transaction.transaction.name}</div>
      <div className={styles.statusContain}>
        <div
          className={c(
            styles.icon,
            transaction.isApproved ? styles.approve : styles.emptySelection
          )}
          onClick={() => handleApprove(transaction)}
          role="button"
          tabIndex="0"
        >
          {transaction.isApproved && <CheckIcon size={20} />}
        </div>
      </div>
      <div className={styles.statusContain}>
        <div
          className={c(styles.icon, transaction.isRejected ? styles.reject : styles.emptySelection)}
          onClick={() => handleReject(transaction)}
          role="button"
          tabIndex="0"
        >
          {transaction.isRejected && <CloseIcon size={20} />}
        </div>
      </div>
      <div className={styles.statusContain}>
        <div
          className={c(styles.icon, transaction.isNewVote ? styles.newVote : styles.emptySelection)}
          onClick={() => handleNewVote(transaction)}
          role="button"
          tabIndex="0"
        >
          {transaction.isNewVote && <PlusIcon size={20} />}
        </div>
      </div>
    </>
  )
}

ListTransactionsComplex.propTypes = {
  transaction: PropTypes.instanceOf(ComplexResolutionTransaction).isRequired,
}

export default observer(ListTransactionsComplex)
