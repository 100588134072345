import { observable, makeObservable } from 'mobx'
import LettersSectionSecondaryBaseStore from 'stores/LettersSectionSecondaryBaseStore'

class AmendmentSectionStore extends LettersSectionSecondaryBaseStore {
  deedsStore

  constructor(deedsStore) {
    super()

    this.deedsStore = deedsStore

    makeObservable(this, {
      deedsStore: observable,
    })
  }
}

export default AmendmentSectionStore
