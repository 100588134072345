import axios from 'axios'
import Cookies from 'js-cookie'
import { API_URL } from 'services/config'
import AuthUser from 'models/AuthUser'

const { CancelToken } = axios

/* eslint-disable class-methods-use-this */
class AuthService {
  authenticate = (email, password, rememberMe) =>
    axios
      .post(`${API_URL}/tenant/auth/login`, {
        email,
        password,
        rememberMe,
      })
      .then((response) => {
        // setear en cookie lo necesario para poder levantar al user despues en loadAuthUserFromBrowser
        const authUser = AuthUser.fromJson(response.data.data)

        Cookies.set('token', authUser.token)

        return authUser
      })

  updateToken = (token) => {
    Cookies.set('token', token)
  }

  getToken = () => {
    return Cookies.get('token') || null
  }

  loadAuthUserFromBrowser = () => {
    const token = Cookies.get('token')

    if (token) {
      return this.getAuthUserByToken(token)
    }

    return null
  }

  keepAlive = () => {
    if (this.call) {
      this.call.cancel('Only one request allowed at a time.')
    }

    this.call = CancelToken.source()

    return axios
      .post(`${API_URL}/tenant/auth/keep-alive`, {}, { cancelToken: this.call.token })
      .then((response) => response.data.data)
  }

  getAuthUserByToken = (token) => {
    return axios
      .get(`${API_URL}/tenant/auth/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const authUser = AuthUser.fromJson(response.data.data)
        authUser.setToken(response.data.metadata.token.jwt)

        return authUser
      })
  }

  logout = () => {
    const token = Cookies.get('token')

    if (token) {
      axios.get(`${API_URL}/tenant/auth/logout`).then((response) => response.data.data)
    }

    Cookies.remove('token')

    return true
  }

  forgotPassword = (email) =>
    axios.post(`${API_URL}/tenant/auth/sendResetPassword`, { email }).then((response) => response)

  changePassword = (token, password, repeatPassword) =>
    axios
      .post(`${API_URL}/tenant/auth/resetPassword?token=${token}`, {
        password,
        password_confirmation: repeatPassword,
      })
      .then((response) => response)

  validateToken = (token) =>
    axios
      .get(`${API_URL}/tenant/auth/reset/validate?token=${token}`)
      .then((response) => response.data.data)
}

export default AuthService
