import moment from 'moment'

class ListDate {
  constructor(at, firstName, lastName) {
    this.at = moment(at)
    this.firstName = firstName
    this.lastName = lastName
  }

  get name() {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    if (!this.firstName && this.lastName) {
      return this.lastName
    }
    if (this.firstName && !this.lastName) {
      return this.firstName
    }

    return null
  }

  static fromJson({ at, firstName = null, lastName = null }) {
    return new ListDate(at, firstName, lastName)
  }
}

export default ListDate
