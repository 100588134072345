import { action, observable, runInAction, computed, makeObservable } from 'mobx'
import moment from 'moment'
import AsyncStore from 'stores/AsyncStore'
import TrustsService from 'services/TrustService'
import InputStore from 'stores/InputStore'

class TrustsValuationsStore extends AsyncStore {
  id = null
  valuables = []
  invaluables = []
  misvalued = []
  noIdError = false
  trustName = null
  dateInput = new InputStore()

  constructor() {
    super()
    this.isLoading = true
    this.trustService = new TrustsService()

    makeObservable(this, {
      // observables
      id: observable,
      valuables: observable,
      invaluables: observable,
      misvalued: observable,
      noIdError: observable,
      trustName: observable,
      // actions
      init: action,
      getTrustValuations: action,
      setDateInput: action,
      // computeds
      valuablesCount: computed,
      hasValidValuations: computed,
      invaluablesCount: computed,
      misvaluedCount: computed,
    })
  }

  async init(id) {
    if (!id) {
      this.noIdError = true
      return
    }

    this.id = id

    this.preRequest(() => this.init(id))

    this.trustService
      .getTrust(id)
      .then((trust) => {
        runInAction(() => {
          this.trustName = trust.data.trustName
        })
      })
      .catch((e) => {
        runInAction(() => {
          this.noIdError = true
          this.onErrorRequest(e)
        })
      })
  }

  get valuablesCount() {
    let count = 0
    this.valuables.forEach((valuable) => {
      count += valuable.assets.length
    })

    return count
  }

  get hasValidValuations() {
    return !(this.valuablesCount === 0 && this.invaluablesCount === 0 && this.misvaluedCount === 0)
  }

  get invaluablesCount() {
    let count = 0
    this.invaluables.forEach((invaluable) => {
      count += invaluable.assets.length
    })

    return count
  }

  get misvaluedCount() {
    let count = 0
    this.misvalued.forEach((asset) => {
      count += asset.assets.length
    })

    return count
  }

  async getTrustValuations() {
    this.preRequest(() => this.getTrustValuations())

    try {
      const response = await this.trustService.getTrustValuations(
        this.id,
        this.dateInput.value && moment(this.dateInput.value).format('YYYY-MM-DD')
      )

      runInAction(() => {
        this.valuables = response.valuables
        this.invaluables = response.invaluables
        this.misvalued = response.misvalued

        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
      if (e?.response?.data?.error?.message) {
        this.dateInput.setError(e.response.data.error.message)
      }
    }
  }

  setDateInput(date) {
    this.dateInput.setValue(date)
  }
}

export default TrustsValuationsStore
