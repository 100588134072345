import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Rule from 'models/Rule'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import Button from 'presentation/Button'
import RuleNameExpansor from 'presentation/RuleNameExpansor'
import RuleGraphicalView from 'presentation/RuleGraphicalView'
import styles from './ruleGlobalView.module.scss'

const RuleGlobalView = ({
  rule,
  setButtons,
  handleCancel,
  trustAssets,
  trustBeneficiaries,
  trustGovernors,
  globalViewMode,
  changeToEdit,
  canEdit,
}) => {
  const { t } = useTranslation('common')

  if (!globalViewMode) {
    useEffect(() => {
      const buttons = []

      if (canEdit) {
        buttons.push(
          <Button
            style={{ marginRight: '15px' }}
            title={`${t(`common:save`)}`}
            primary
            label={t('edit')}
            onClick={changeToEdit}
            small
          />
        )
      }
      buttons.push(<Button label={t('close')} onClick={handleCancel} secondary small />)

      setButtons(buttons)
    }, [])
  }

  return (
    <div className={styles.container}>
      <RuleNameExpansor rule={rule} />
      <RuleGraphicalView
        trustAssets={trustAssets}
        trustBeneficiaries={trustBeneficiaries}
        trustGovernors={trustGovernors}
        rule={rule}
      />
    </div>
  )
}

RuleGlobalView.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  trustGovernors: PropTypes.arrayOf(PropTypes.instanceOf(RuleAssetGovernor)),
  handleCancel: PropTypes.func,
  setButtons: PropTypes.func,
  changeToEdit: PropTypes.func,
  globalViewMode: PropTypes.bool,
  canEdit: PropTypes.bool,
}

RuleGlobalView.defaultProps = {
  globalViewMode: false,
  handleCancel: null,
  changeToEdit: null,
  canEdit: false,
  setButtons: () => {},
  trustGovernors: null,
}

export default RuleGlobalView
