import FiduciaryStructurePreloadDataStore, {
  CORP_TYPE,
} from 'stores/FiduciaryStructurePreloadDataStore'
import { action, makeObservable, observable, runInAction } from 'mobx'
import TrustTransactionsAssetsService from 'services/TrustTransactionsAssetsService'
import InputStore from 'stores/InputStore'
import AccountFundingTransactionStore from './AccountFundingTransactionStore'
import ExpenseTransactionStore from './ExpenseTransactionStore'
import OwnershipTransactionStore from './OwnershipTransactionStore'

const ALLOWED_CORP_TRANSACTIONS = ['accountFunding']

class TransactionsAssetsStore extends FiduciaryStructurePreloadDataStore {
  constructor(id, structure) {
    super(id, structure)
    this.currentTransactionStore = null
    this.accountFundingTransactionStore = null
    this.expenseTransactionStore = null
    this.ownershipTransactionStore = null

    this.transactionTypesOptions = []
    this.typeSelected = new InputStore()

    this.trustTransactionsAssetsService = new TrustTransactionsAssetsService()

    makeObservable(this, {
      // observables
      transactionTypesOptions: observable,
      // actions
      setTypeSelected: action,
    })
  }

  async loadAssets(type) {
    this.currentTransactionStore.loadAssets(type)
  }

  async loadTransactionTypes() {
    try {
      this.preRequest()
      const staticTypeOptions = []

      await this.trustTransactionsAssetsService.loadTransactionTypes().then(({ typeOptions }) => {
        const options =
          this.structure === CORP_TYPE
            ? typeOptions.filter(({ id }) => ALLOWED_CORP_TRANSACTIONS.includes(id))
            : typeOptions
        options.forEach((option) => staticTypeOptions.push(option))
      })

      runInAction(() => {
        this.transactionTypesOptions = staticTypeOptions
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  setTypeSelected(value) {
    this.typeSelected.setValue(value)

    switch (value.id) {
      case 'accountFunding':
        if (!this.accountFundingTransactionStore)
          this.accountFundingTransactionStore = new AccountFundingTransactionStore(
            this.fiduciaryStructureId
          )
        this.currentTransactionStore = this.accountFundingTransactionStore
        break
      case 'expense':
        if (!this.expenseTransactionStore)
          this.expenseTransactionStore = new ExpenseTransactionStore(this.fiduciaryStructureId)
        this.currentTransactionStore = this.expenseTransactionStore
        break
      case 'ownership':
        if (!this.ownershipTransactionStore)
          this.ownershipTransactionStore = new OwnershipTransactionStore(this.fiduciaryStructureId)
        this.currentTransactionStore = this.ownershipTransactionStore
        break
      default:
        this.currentTransactionStore = null
    }
  }

  async save(successCallback) {
    try {
      this.preRequest(this.save)
      await this.currentTransactionStore.save(successCallback)

      runInAction(() => {
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  get destinationAssetsHasErrors() {
    return (
      this.currentTransactionStore?.destinationAssetsHasErrors &&
      this.currentTransactionStore.destinationAssetsHasErrors()
    )
  }

  get assets() {
    return this.currentTransactionStore ? this.currentTransactionStore.assets : []
  }

  getDataJson() {
    const sourceAssets = this.assets
      .filter((asset) => asset.isSelected)
      .map((asset) => asset.getJson())
    const destinationAssets = this.destinationAssets.map((asset) => asset.getJson())

    const updatedAditionalData = {
      description: this.description.value,
      document: this.file.getJson(),
      sourceAccounts: sourceAssets,
      destination: destinationAssets,
    }
    if (this.hasVoting.value) {
      updatedAditionalData.voters = this.voters
    }
    const json = {}
    json.fiduciaryStructureId = this.id
    json.name = this.title.value
    json.date = this.transactionDate.value.toString()
    json.withVoting = !!this.hasVoting.value
    json.additionalData = updatedAditionalData
    return json
  }

  resetErrors() {
    this.currentTransactionStore.resetErrors()
  }

  get isOwnershipTransaction() {
    return this.currentTransactionStore instanceof OwnershipTransactionStore
  }

  shouldHandleMultipleAssets() {
    return !!this.currentTransactionStore.setCurrentAsset
  }

  changeCurrentAsset(index) {
    this.currentTransactionStore.setCurrentAsset(index)
  }
}

export default TransactionsAssetsStore
