import File from 'models/File'
import Letter from './Letter'

class LetterOfWishes extends Letter {
  constructor() {
    super('low')
  }

  static createFromJson({
    id = null,
    title = null,
    text = null,
    file = null,
    signedFile = null,
    status = null,
    updated = null,
  }) {
    const low = new LetterOfWishes()

    low.changeUpdated(updated)
    low.changeId(id)
    low.changeStatus(status)
    low.changeTitle(title)
    low.changeText(text)
    low.file = new File()
    low.signedFile = new File()

    if (file !== null) {
      low.file = File.fromJson(file)
    }

    if (signedFile !== null) {
      low.signedFile = File.fromJson(signedFile)
    }

    return low
  }

  fillFromOldVersion(letterVersion) {
    this.changeTitle(letterVersion.title)
    this.changeText(letterVersion.content)

    if (letterVersion.file !== null) {
      this.file = letterVersion.file
    }

    if (letterVersion.signedFile !== null) {
      this.signedFile = letterVersion.signedFile
    }
  }

  static createFromLetterOfWishes(letterOfWishes) {
    const low = new LetterOfWishes()

    low.changeTitle(letterOfWishes.title.value)
    low.changeText(letterOfWishes.text.value)

    if (letterOfWishes.file !== null) {
      low.file = letterOfWishes.file
    }

    if (letterOfWishes.signedFile !== null) {
      low.signedFile = letterOfWishes.signedFile
    }

    return low
  }
}

export default LetterOfWishes
