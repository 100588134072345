import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ToolTip from 'presentation/ToolTip'
import styles from './multipleTooltipsBeneficiaries.module.scss'

const TOOLTIP_MAX_BENEFICIARIES_SHOWN = 3
const MAX_BENEFICIARIES_SHOWN = 2

const MultipleBeneficiariesTooltip = ({ beneficiaries }) => {
  const { t } = useTranslation('trusts')
  const maxBeneficiariesDisplay = TOOLTIP_MAX_BENEFICIARIES_SHOWN
  const chosenBeneficiaries = beneficiaries
    .slice(0, maxBeneficiariesDisplay)
    .map((beneficiary) => beneficiary.beneficiary.name.value)

  return (
    <>
      {chosenBeneficiaries.map((beneficiarie, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${i}_beneficiaries`}>{beneficiarie}</div>
      ))}
      {beneficiaries.length > maxBeneficiariesDisplay && (
        <div>
          {t('andXMore', {
            amount: beneficiaries.length - maxBeneficiariesDisplay,
          })}
        </div>
      )}
    </>
  )
}

MultipleBeneficiariesTooltip.propTypes = {
  beneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

const MultipleTooltipsBeneficiaries = ({ beneficiaries }) => {
  const { t } = useTranslation('trusts')
  const maxBeneficiaries = MAX_BENEFICIARIES_SHOWN
  const chosenBeneficiaries = beneficiaries
    .slice(0, maxBeneficiaries)
    .map((beneficiary) => beneficiary.beneficiary.name.value)
  const remainingSettlors = beneficiaries.slice(maxBeneficiaries)
  const chosenNames = chosenBeneficiaries.join(', ')

  if (beneficiaries.length > maxBeneficiaries) {
    return (
      <>
        <span title={chosenNames}>{chosenNames}</span>
        <ToolTip
          tooltipContent={
            <span className={styles.multipleBeneficiaries}>
              <MultipleBeneficiariesTooltip beneficiaries={remainingSettlors} />
            </span>
          }
        >
          <span className={styles.andXMoreFixer}>
            <span className={styles.andXMore}>
              {t('andXMore', {
                amount: beneficiaries.length - maxBeneficiaries,
              })}
            </span>
          </span>
        </ToolTip>
      </>
    )
  }

  return <span title={chosenNames}>{chosenNames}</span>
}

MultipleTooltipsBeneficiaries.propTypes = {
  beneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default MultipleTooltipsBeneficiaries
