import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import MetaTitle from 'util/MetaTitle'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'

const CorporationCreationTitle = () => {
  const { t } = useTranslation('corporationsCreation')
  const { corporationsCreationStore } = useContext(StoresContext)
  let pageTitle = t('titleCreation')

  if (corporationsCreationStore.isViewMode) {
    pageTitle = t('titleViewName', { name: corporationsCreationStore.entityName })
  } else if (corporationsCreationStore.isEdition) {
    pageTitle = t('titleEditionName', { name: corporationsCreationStore.entityName })
  }

  return <MetaTitle pageTitle={pageTitle} />
}

export default observer(CorporationCreationTitle)
