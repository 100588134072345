import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  FIDUCIARY_STRUCTURE_TRANSACTIONS,
  FIDUCIARY_STRUCTURE_TRANSACTIONS_ASSETS,
  FIDUCIARY_STRUCTURE_TRANSACTIONS_EXPENSE,
  FIDUCIARY_STRUCTURE_TRANSACTIONS_VALUATION,
} from 'routing/routes'
import { CORP_TYPE } from 'stores/FiduciaryStructurePreloadDataStore'
import MetaTitle from 'util/MetaTitle'
import MainSectionList from 'presentation/MainSectionList'
import TransactionsStore from 'stores/TransactionsStore'
import TransactionsTabs from './TransactionsTabs'

const Transactions = () => {
  const { t } = useTranslation('transactions')
  const [trustColumns, setTrustColumns] = useState([])
  const [corpsColumns, setCorpsColumns] = useState([])
  const history = useHistory()
  const structureParam = new URLSearchParams(history.location.search).get('structure')
  const [activeTab, setActiveTab] = useState(structureParam === CORP_TYPE ? 1 : 0)
  const [transactionsStore] = useState(() => new TransactionsStore())
  const { isLoading } = transactionsStore

  useEffect(() => {
    const visibleTrustColumns = [
      {
        name: 'trustName',
        order: false,
        inverted: true,
        transName: t('name'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'settlorName',
        order: false,
        inverted: true,
        transName: t('settlorName', {
          what: t('settlor'),
        }),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'status',
        order: false,
        inverted: false,
        transName: t('common:status'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'actions',
        order: false,
        inverted: false,
        transName: t('common:actions'),
        cssGridDefinition: '1fr',
      },
    ]

    const visibleCorpColumns = [
      {
        name: 'corporationName',
        order: false,
        inverted: true,
        transName: t('name'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'controllingPartyName',
        order: false,
        inverted: true,
        transName: t('controllingPartyName'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: true,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: true,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'status',
        order: false,
        inverted: false,
        transName: t('common:status'),
        cssGridDefinition: '130px',
      },
    ]

    setTrustColumns(visibleTrustColumns)
    setCorpsColumns(visibleCorpColumns)
    transactionsStore.loadStoredTrustList()
    transactionsStore.loadStoredCorpsList()
  }, [])

  const handlePageChange = useCallback((value) => {
    transactionsStore.setActivePage(value)
    transactionsStore.loadStoredTrustList()
    transactionsStore.loadStoredCorpsList()
  }, [])

  const handleTransactionsLink = useCallback((id, structure) => {
    history.push(`${FIDUCIARY_STRUCTURE_TRANSACTIONS}?id=${id}&structure=${structure}`)
  }, [])

  const handleContentChange = useCallback((id, structure) => {
    const URL = FIDUCIARY_STRUCTURE_TRANSACTIONS_ASSETS.replace(':fiduciaryStructureId', id)
    history.push(`${URL}?structure=${structure}`)
  }, [])

  const handleExpenseLink = useCallback((id, structure) => {
    const URL = FIDUCIARY_STRUCTURE_TRANSACTIONS_EXPENSE.replace(':fiduciaryStructureId', id)
    history.push(`${URL}?structure=${structure}`)
  }, [])

  const handleValuationChange = useCallback((id, structure) => {
    const URL = FIDUCIARY_STRUCTURE_TRANSACTIONS_VALUATION.replace(':fiduciaryStructureId', id)
    history.push(`${URL}?structure=${structure}`)
  }, [])

  const handleVotingLink = useCallback((id, structure) => {
    history.push(`${FIDUCIARY_STRUCTURE_TRANSACTIONS}?id=${id}&structure=${structure}&tab=voting`)
  }, [])

  const handleTabChange = useCallback((tab) => {
    transactionsStore.setActivePage(1)
    setActiveTab(tab)
  }, [])

  const handleOrderChange = useCallback((sortField, sortDirection) => {
    transactionsStore.loadStoredTrustList(sortField, sortDirection)
    transactionsStore.loadStoredCorpsList(sortField, sortDirection)
  }, [])

  const handleTrustOrderChange = useCallback((sortField, sortDirection) => {
    transactionsStore.loadStoredTrustList(sortField, sortDirection)
  }, [])

  const handleCorpOrderChange = useCallback((sortField, sortDirection) => {
    transactionsStore.loadStoredCorpsList(sortField, sortDirection)
  }, [])

  const handleShowDropdownOrder = useCallback((value) => {
    transactionsStore.setShowDropdownOrder(value)
  }, [])

  const handleShowDropdownFilter = useCallback((value) => {
    transactionsStore.setShowDropdownFilter(value)
  }, [])

  return (
    <div>
      <MetaTitle pageTitle={t('pageTitles:transactions')} />
      <MainSectionList
        creationPermission="no"
        isLoading={isLoading}
        sectionNameSingular={t('transaction')}
        sectionNamePlural={t('transactions')}
        handlePageChange={handlePageChange}
        currentPage={transactionsStore.activePage}
        totalPages={
          activeTab === 0 ? transactionsStore.totalTrustPages : transactionsStore.totalCorpPages
        }
        sectionItems={transactionsStore.storedTrusts}
        setShowDropdownOrder={handleShowDropdownOrder}
        setShowDropdownFilter={handleShowDropdownFilter}
        tableComponent={
          <TransactionsTabs
            handleTabChange={handleTabChange}
            trustColumns={trustColumns}
            corpsColumns={corpsColumns}
            transactionsStore={transactionsStore}
            view="list"
            handleShowDropdownOrder={handleShowDropdownOrder}
            handleOrderChange={handleOrderChange}
            handleTrustOrderChange={handleTrustOrderChange}
            handleCorpOrderChange={handleCorpOrderChange}
            handleExpenseLink={handleExpenseLink}
            handleTransactionsLink={handleTransactionsLink}
            handleContentChange={handleContentChange}
            handleValuationChange={handleValuationChange}
            handleVotingLink={handleVotingLink}
            isLoading={isLoading}
            defaultActiveTab={activeTab}
          />
        }
      />
    </div>
  )
}

export default observer(Transactions)
