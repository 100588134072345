import React from 'react'
import PropTypes from 'prop-types'
import TrustDocumentVersion from 'models/TrustDocumentVersion'
import ItemCard from './ItemCard'

const TrustDocumentArchiveItem = ({
  trustDocumentVersion,
  handleRestoreDocument,
  showStatus,
  handleDraftEdit,
  errors,
}) => (
  <ItemCard
    trustDocumentVersion={trustDocumentVersion}
    showStatus={showStatus}
    handleRestoreDocument={handleRestoreDocument}
    handleDraftEdit={handleDraftEdit}
    errors={errors}
  />
)

TrustDocumentArchiveItem.propTypes = {
  trustDocumentVersion: PropTypes.instanceOf(TrustDocumentVersion).isRequired,
  handleRestoreDocument: PropTypes.func,
  showStatus: PropTypes.bool,
  handleDraftEdit: PropTypes.func,
  errors: PropTypes.number,
}

TrustDocumentArchiveItem.defaultProps = {
  handleRestoreDocument: null,
  showStatus: false,
  handleDraftEdit: null,
  errors: 0,
}

export default TrustDocumentArchiveItem
