import { useTranslation } from 'react-i18next'
import PageTitle from 'presentation/PageTitle'
import MetaTitle from 'util/MetaTitle'
import logo from 'presentation/UnauthorizedContainer/images/logo.svg'
import styles from './cookiePolicy.module.scss'

const CookiePolicy = () => {
  const { t } = useTranslation('signIn')
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <img src={logo} title="Smart Trust" alt="Smart Trust" />
          <div className={styles.logoBrand}>Smart Trust</div>
        </div>
      </div>
      <MetaTitle pageTitle={t('cookiePolicy')} />
      <PageTitle title={t('cookiePolicy')} containerClass={styles.titleContainer} />
      <div className={styles.text}>{t('cookiePolicy:cookiePolicy')}</div>
    </div>
  )
}

export default CookiePolicy
