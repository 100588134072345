import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'
import StoresContext from 'providers/storeContext'
import isMobile from 'util/browser'
import { scrollBodyToElement } from 'util/scroll'
import PageTitle from 'presentation/PageTitle'
import Tabs, { Tab } from 'presentation/Tabs'
import Button from 'presentation/Button'
import Modal from 'scenes/Modal'
import LoadingRing from 'presentation/LoadingRing'
import PlusIcon from 'mdi-react/PlusIcon'
import SitemapIcon from 'mdi-react/SitemapIcon'
import FilterOutlineIcon from 'mdi-react/FilterOutlineIcon'
import FilterRemoveIcon from 'mdi-react/FilterRemoveIcon'
import RulesSearch from 'presentation/RulesSearch'
import TrustAssetsStore from 'stores/TrustAssetsStore'
import GlobalView from 'presentation/GlobalView'
import TrustBeneficiariesStore from 'stores/TrustBeneficiariesStore'
import TrustGovernorsStore from 'stores/TrustGovernorsStore'
import CloseIcon from 'mdi-react/CloseIcon'
import RulesStore from './RulesStore'
import DeedOfTrustRulesSection from './DeedOfTrustRulesSection'
import LetterOfWishesRulesSection from './LetterOfWishesRulesSection'
import OtherDocumentsRulesSection from './OtherDocumentsRulesSection'
import RestrictionsRulesSection from './RestrictionsRulesSection'
import styles from './rulesSection.module.scss'

const getTargetSelectionText = () => {
  if (typeof window.getSelection !== 'undefined') {
    if (typeof window.getSelection().baseNode !== 'undefined') {
      return window.getSelection().baseNode.parentElement.id
    }

    return window.getSelection().focusNode.parentElement.id
  }

  return null
}

/* eslint-disable class-methods-use-this */
class RulesSection extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      defaultActiveTab: 0,
      selectionModeAddToRule: null,
      showDeleteModal: false,
      ruleToDelete: null,
    }

    const { trustsCreationStore } = this.context

    trustsCreationStore.hideActionButtons()

    this.trustAssetsStore = new TrustAssetsStore(trustsCreationStore.idEdition)
    this.trustBeneficiariesStore = new TrustBeneficiariesStore(trustsCreationStore.idEdition)
    this.trustGovernorsStore = new TrustGovernorsStore(trustsCreationStore.idEdition)

    this.tabRef = null
  }

  componentDidMount() {
    const { rulesStore } = this.props
    rulesStore.reset()

    rulesStore.disableGlobalViewMode()
    rulesStore.loadRules()
    this.trustAssetsStore.loadAssets(rulesStore.trustCreationStore.alternativeStatusLoad)
    this.trustBeneficiariesStore.loadBeneficiaries(
      rulesStore.trustCreationStore.alternativeStatusLoad
    )
    this.trustGovernorsStore.loadGovernors()
  }

  handleChangeTab = (activeTab) => {
    this.setState({ defaultActiveTab: activeTab })
  }

  successDeleteModal = () => {
    const { rulesStore } = this.props
    const { ruleToDelete } = this.state
    rulesStore.removeRule(ruleToDelete)

    this.handleCloseDeleteModal()
  }

  handleCloseDeleteModal = () => {
    this.setState({ ruleToDelete: null, showDeleteModal: false })
  }

  handleClickToggleGlobalView = () => {
    const { rulesStore } = this.props

    rulesStore.toggleGlobalViewMode()
    rulesStore.setToggleViewFilters(false)
    window.scrollTo(0, 0)
    document.activeElement.blur()
    rulesStore.clearFilters()
  }

  handleClickToggleViewFilters = () => {
    const { rulesStore } = this.props

    rulesStore.toggleViewFilters()
    document.activeElement.blur()
    rulesStore.clearFilters()
  }

  handleAddRule = (selection) => {
    const { rulesStore } = this.props
    const { defaultActiveTab } = this.state

    this.ruleCreation(selection)

    setTimeout(() => {
      const rule = rulesStore.rules[rulesStore.rules.length - 1]
      const lastElement = document.querySelector(`.${rule.listElementId}`)

      let scrollEle = this.dotListRef

      if (isMobile()) {
        scrollEle = window
      } else if (defaultActiveTab === 1) {
        scrollEle = this.lowsListRef
      }

      setTimeout(() => {
        scrollEle.scroll({ top: lastElement.offsetTop, behavior: 'smooth' })

        this.handleScrollToTextFromList(rule)
      }, 300)
    }, 1)
  }

  handleStartSelectionMode = () => {
    const { rulesStore } = this.props
    rulesStore.startSelectionMode()
    rulesStore.setToggleViewFilters(false)
  }

  handleFinishSelectionMode = () => {
    const { rulesStore } = this.props
    rulesStore.finishSelectionMode()
  }

  handleSaveRule = (rule) => {
    const { rulesStore } = this.props

    rulesStore.saveRule(rule)
  }

  unSelectAllRules = () => {
    const { rulesStore } = this.props

    rulesStore.rules.forEach((rule) => rule.unselect())
  }

  handleDeleteRule = (rule) => {
    this.setState({ ruleToDelete: rule, showDeleteModal: true })
  }

  /* handleAddChunk = rule => {
    this.setState({ selectionMode: true, selectionModeAddToRule: rule })
  } */

  handleSelectRuleFromList = (rule) => {
    const { selected, edition } = rule

    if (!edition) {
      this.unSelectAllRules()

      if (!selected) {
        rule.select()
      }
    }
  }

  handleOpenCardToTextFromList = (rule) => {
    if (!rule.graphicalView) {
      this.handleSelectRuleFromList(rule)
    }
  }

  handleScrollToTextFromList = (rule) => {
    if (!rule.graphicalView) {
      this.handleSelectRuleFromList(rule)
      const highlightElement = document.querySelector(`.${rule.highlightElementId}`)

      if (!isMobile()) {
        scrollBodyToElement(highlightElement, 80)
      } else {
        scrollBodyToElement(highlightElement)
      }
    }
  }

  handleSelectRuleFromText = (rules) => {
    const { defaultActiveTab } = this.state

    const firstRuleEle = document.querySelector(`.${rules[0].listElementId}`)
    let scrollEle = this.dotListRef

    if (isMobile()) {
      scrollEle = window
    } else if (defaultActiveTab === 1) {
      scrollEle = this.lowsListRef
    }

    setTimeout(() => {
      setTimeout(() => scrollEle.scroll({ top: firstRuleEle.offsetTop, behavior: 'smooth' }), 300)

      this.handleScrollToTextFromList(rules[0])
    }, 1)
  }

  ruleCreation(position) {
    const { rulesStore } = this.props
    const { selectionModeAddToRule } = this.state
    const targetRuleCreation = getTargetSelectionText()

    if (position.start !== position.end) {
      if (selectionModeAddToRule === null) {
        rulesStore.createRule(position.start, position.end, targetRuleCreation)
      } else {
        rulesStore.addToRule(selectionModeAddToRule, position.start, position.end)
      }
    }
  }

  showAddRule() {
    const { rulesStore } = this.props
    const { trustsCreationStore } = this.context
    const { canEditRules } = trustsCreationStore.entityStatus
    const { defaultActiveTab } = this.state

    if (rulesStore.globalViewMode) {
      return false
    }

    if (!canEditRules || isMobile()) {
      return false
    }

    if (defaultActiveTab === 0) {
      const { deedsStore } = trustsCreationStore

      if (!deedsStore.deedOfTrustForRules) {
        return false
      }
    } else if (defaultActiveTab === 3) {
      return false
    } else {
      const { lettersStore } = trustsCreationStore

      if (!lettersStore.letterOfWishesForRules) {
        return false
      }
    }

    return true
  }

  render() {
    const { t, rulesStore } = this.props
    const { trustsCreationStore } = this.context

    const { selectionMode } = rulesStore
    const { defaultActiveTab, showDeleteModal } = this.state
    const viewMode = !trustsCreationStore.entityStatus.canEditRules

    return (
      <>
        <Prompt
          when={rulesStore.isEditingRules}
          message={() => {
            return t('leavePagePrompt')
          }}
        />
        <PageTitle
          title={!rulesStore.globalViewMode ? t('rules') : t('globalView')}
          tabRef={!rulesStore.globalViewMode && this.tabRef}
          floating
          rightSection={
            <>
              {!isMobile() && (
                <>
                  {!rulesStore.globalViewMode && (
                    <span title={rulesStore.viewFilters ? t('hideFilters') : t('viewFilters')}>
                      <Button
                        circle
                        style={{ marginRight: '10px' }}
                        onClick={this.handleClickToggleViewFilters}
                        icon={rulesStore.viewFilters ? <FilterRemoveIcon /> : <FilterOutlineIcon />}
                      />
                    </span>
                  )}
                </>
              )}
              <span title={!rulesStore.globalViewMode ? t('globalView') : t('rules')}>
                <Button
                  circle
                  onClick={this.handleClickToggleGlobalView}
                  icon={rulesStore.globalViewMode ? <CloseIcon /> : <SitemapIcon />}
                />
              </span>
            </>
          }
        />
        {!this.trustAssetsStore.isLoading && !this.trustBeneficiariesStore.isLoading && (
          <RulesSearch
            rulesStore={rulesStore}
            view={rulesStore.viewFilters}
            selectionMode={selectionMode}
            assets={this.trustAssetsStore.assets}
            beneficiaries={this.trustBeneficiariesStore.beneficiaries}
          />
        )}

        {rulesStore.isLoading && <LoadingRing center />}

        {!rulesStore.isLoading && !rulesStore.globalViewMode && (
          <Tabs
            ref={(ref) => {
              this.tabRef = ref
            }}
            removeOutPageScrollMobile
            onChangeTab={this.handleChangeTab}
            defaultActiveTab={defaultActiveTab}
          >
            <Tab name={t('deedOfTrust')}>
              <DeedOfTrustRulesSection
                rulesStore={rulesStore}
                selectionMode={selectionMode}
                unSelectAllRules={this.unSelectAllRules}
                handleDeleteRule={this.handleDeleteRule}
                handleSaveRule={this.handleSaveRule}
                isLoading={
                  this.trustAssetsStore.isLoading || this.trustBeneficiariesStore.isLoading
                }
                trustAssets={this.trustAssetsStore.assets}
                trustBeneficiaries={this.trustBeneficiariesStore.beneficiaries}
                trustGovernors={this.trustGovernorsStore.governors}
                handleSelectRuleFromList={this.handleSelectRuleFromList}
                handleScrollToTextFromList={this.handleScrollToTextFromList}
                handleOpenCardToTextFromList={this.handleOpenCardToTextFromList}
                handleSelectRuleFromText={this.handleSelectRuleFromText}
                listRef={(ele) => {
                  this.dotListRef = ele
                }}
                viewMode={viewMode}
                handleAdd={this.handleAddRule}
              />
            </Tab>
            <Tab name={t('letterOfWishes')}>
              <LetterOfWishesRulesSection
                rulesStore={rulesStore}
                selectionMode={selectionMode}
                trustAssets={this.trustAssetsStore.assets}
                trustBeneficiaries={this.trustBeneficiariesStore.beneficiaries}
                isLoading={
                  this.trustAssetsStore.isLoading || this.trustBeneficiariesStore.isLoading
                }
                unSelectAllRules={this.unSelectAllRules}
                handleDeleteRule={this.handleDeleteRule}
                handleSaveRule={this.handleSaveRule}
                handleOpenCardToTextFromList={this.handleOpenCardToTextFromList}
                handleSelectRuleFromList={this.handleSelectRuleFromList}
                handleScrollToTextFromList={this.handleScrollToTextFromList}
                handleSelectRuleFromText={this.handleSelectRuleFromText}
                listRef={(ele) => {
                  this.lowsListRef = ele
                }}
                viewMode={viewMode}
                handleAdd={this.handleAddRule}
              />
            </Tab>
            <Tab name={t('otherDocuments')}>
              <OtherDocumentsRulesSection
                rulesStore={rulesStore}
                selectionMode={selectionMode}
                trustAssets={this.trustAssetsStore.assets}
                trustBeneficiaries={this.trustBeneficiariesStore.beneficiaries}
                isLoading={
                  this.trustAssetsStore.isLoading || this.trustBeneficiariesStore.isLoading
                }
                unSelectAllRules={this.unSelectAllRules}
                handleDeleteRule={this.handleDeleteRule}
                handleSaveRule={this.handleSaveRule}
                handleOpenCardToTextFromList={this.handleOpenCardToTextFromList}
                handleSelectRuleFromList={this.handleSelectRuleFromList}
                handleScrollToTextFromList={this.handleScrollToTextFromList}
                handleSelectRuleFromText={this.handleSelectRuleFromText}
                listRef={(ele) => {
                  this.lowsListRef = ele
                }}
                viewMode={viewMode}
                handleAdd={this.handleAddRule}
              />
            </Tab>
            <Tab name={t('restrictions')}>
              <RestrictionsRulesSection rulesStore={rulesStore} />
            </Tab>
          </Tabs>
        )}
        {!rulesStore.isLoading && rulesStore.globalViewMode && (
          <GlobalView
            rules={rulesStore.visibleRules}
            assets={this.trustAssetsStore.assets}
            beneficiaries={this.trustBeneficiariesStore.beneficiaries}
            handleRollBack={this.handleClickToggleGlobalView}
          />
        )}
        <Modal
          name="editRuleModal"
          title={t('confirmDeleteRule')}
          open={showDeleteModal}
          onSuccess={this.successDeleteModal}
          onCancel={this.handleCloseDeleteModal}
          closeOutside
        />
        {this.showAddRule() && (
          <div className={styles.actionButtons}>
            {selectionMode ? (
              <Button
                onClick={this.handleFinishSelectionMode}
                label={t('endSelection')}
                as="button"
                secondary
              />
            ) : (
              <Button
                label={t('addRule')}
                icon={<PlusIcon />}
                title={`${t(`addRule`)} (alt+c)`}
                onClick={this.handleStartSelectionMode}
                primary
              />
            )}
          </div>
        )}
      </>
    )
  }
}

RulesSection.contextType = StoresContext

RulesSection.propTypes = {
  rulesStore: PropTypes.instanceOf(RulesStore).isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation('rulesCreation')(observer(RulesSection))
