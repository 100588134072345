import { action, observable, computed, runInAction, makeObservable } from 'mobx'
import i18next from 'i18next'
import InputStore from 'stores/InputStore'
import AsyncStore from 'stores/AsyncStore'
import ChangePasswordService from 'services/ChangePasswordService'
import { TENANT_IMPORT_PUBLIC_KEY, TENANT_SHARE_PRIVATE_KEY } from 'stores/AuthStore'

const PASSWORD_DIGITS = 8
const SHORT_PASSWORD = i18next.t('validation:shortPassword', { count: PASSWORD_DIGITS })
const REQUIRED = i18next.t('validation:requiredField')
const EQUAL_PASSWORD = i18next.t('validation:equalPassword')

class ChangePasswordStore extends AsyncStore {
  authUser = null
  currentPassword = new InputStore()
  newPassword = new InputStore()
  confirmPassword = new InputStore()
  certifyKeys = new InputStore()
  requestErrors = []
  isLoadingSave = false

  constructor(authUser) {
    super()
    this.authUser = authUser

    makeObservable(this, {
      // observables
      authUser: observable,
      currentPassword: observable,
      newPassword: observable,
      confirmPassword: observable,
      certifyKeys: observable,
      requestErrors: observable,
      isLoadingSave: observable,
      // actions
      resetChangePassword: action,
      toggleCertifyKeys: action,
      validate: action,
      save: action,
      // computeds
      isValid: computed,
      showAlertKeys: computed,
    })

    this.changePasswordService = new ChangePasswordService()
  }

  resetChangePassword() {
    this.currentPassword = new InputStore()
    this.newPassword = new InputStore()
    this.confirmPassword = new InputStore()
    this.certifyKeys = new InputStore()
    this.requestErrors = []
    this.isLoadingSave = false
  }

  static validatePassword(passwordStore, lenghtValidation = true) {
    if (passwordStore.value) {
      if (lenghtValidation && passwordStore.value.length < PASSWORD_DIGITS) {
        passwordStore.setError(true, SHORT_PASSWORD)
      }
    } else {
      passwordStore.setError(true, REQUIRED)
    }
  }

  toggleCertifyKeys() {
    this.certifyKeys.setValue(!this.certifyKeys.value)
  }

  validateConfirmPassword() {
    if (!this.confirmPassword.value) {
      this.confirmPassword.setError(true, REQUIRED)
    } else if (this.confirmPassword.value !== this.newPassword.value) {
      this.confirmPassword.setError(true, EQUAL_PASSWORD)
    }
  }

  validate() {
    ChangePasswordStore.validatePassword(this.currentPassword, false)
    ChangePasswordStore.validatePassword(this.newPassword)
    this.validateConfirmPassword()

    if (!this.certifyKeys.value) {
      this.certifyKeys.setError(true)
    }
  }

  get isValid() {
    return !(
      this.currentPassword.error ||
      this.newPassword.error ||
      this.confirmPassword.error ||
      (this.showAlertKeys && this.certifyKeys.error)
    )
  }

  async save(successCallback) {
    this.requestProcess(() => this.save(successCallback))
    this.validate()

    if (this.isValid) {
      this.isLoadingSave = true
      try {
        await this.changePasswordService.changePassword(
          this.currentPassword.value,
          this.newPassword.value,
          this.confirmPassword.value
        )

        runInAction(() => {
          this.resetChangePassword()
          successCallback()
        })
      } catch (e) {
        runInAction(() => {
          if (AsyncStore.isServerError(e)) {
            this.setServerError()
          } else {
            this.requestErrors = e.response.data.error.errors
              ? e.response.data.error.errors
              : [i18next.t('common:genericError')]
          }
          this.isLoadingSave = false
        })
      }
    }
  }

  get showAlertKeys() {
    return (
      this.authUser.keys.personal ||
      (this.authUser.keys.tenant &&
        (this.authUser.permissions.includes(TENANT_SHARE_PRIVATE_KEY) ||
          this.authUser.permissions.includes(TENANT_IMPORT_PUBLIC_KEY)))
    )
  }
}

export default ChangePasswordStore
