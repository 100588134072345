import { useState, useContext } from 'react'
import { observer } from 'mobx-react'
import { Route } from 'react-router'
import { useLocation } from 'react-router-dom'
import c from 'classnames'
import * as Routes from 'routing/routes'
import ChevronLeftCircleIcon from 'mdi-react/ChevronLeftCircleIcon'
import MenuIcon from 'mdi-react/MenuIcon'
import AuthorizedNavBarMain, { NavLeftBar } from 'presentation/NavBarComponents'
import isMobileChecker from 'util/browser'
import StoresContext from 'providers/storeContext'
import styles from './authorizedNavBar.module.scss'
import {
  SubMenuCompanyClient,
  SubMenuCompanyContact,
  SubMenuCorporation,
  SubMenuIndividualClient,
  SubMenuIndividualContact,
  SubMenuTrust,
  SubMenuRoute,
  SubMenuHistory,
} from './SubMenu'
import { toggleNavBar } from './SubMenu/SubMenuUtils'

const isMobile = isMobileChecker()

const defaultCollapsed = () => {
  try {
    return JSON.parse(window.localStorage.getItem('subMenuCollapsed'))
  } catch (e) {
    return false
  }
}

const AuthorizedNavBar = () => {
  const { navBarStore } = useContext(StoresContext)
  const [over, setOver] = useState(false)
  const [collapsed, setCollapsed] = useState(!!defaultCollapsed())
  const location = useLocation()

  const handleOver = () => {
    setOver(true)
  }

  const handleOut = () => {
    setOver(false)
  }

  const toggleCollapse = () => {
    const newCollapsed = !collapsed

    setCollapsed(newCollapsed)

    window.localStorage.setItem('subMenuCollapsed', JSON.stringify(newCollapsed))
  }

  return (
    <div id="nav-bar">
      <div
        onClick={() => toggleNavBar()}
        className={c(styles.menuIconMobile, navBarStore.topMsg && styles.menuIconMobileShowTopMsg)}
        role="button"
        tabIndex="0"
      >
        <MenuIcon size={35} />
      </div>
      <div className={styles.navBar}>
        <div className={styles.mainNavbar} onMouseEnter={handleOver} onMouseLeave={handleOut}>
          <NavLeftBar toggleNavBar={toggleNavBar} />
        </div>
        <div className={c(styles.secondaryNavbar, collapsed && styles.collapsed)}>
          {!isMobile && (
            <div
              className={c(styles.collapseIconContainer, collapsed && styles.collapseIconCollapsed)}
            >
              <ChevronLeftCircleIcon className={styles.collapseIcon} onClick={toggleCollapse} />
            </div>
          )}
          <SubMenuRoute
            path={Routes.TRUSTS_CREATION}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuTrust}
          />
          <SubMenuRoute
            path={Routes.CORPORATIONS_CREATION}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuCorporation}
          />
          <SubMenuRoute
            path={Routes.INDIVIDUAL_CLIENT_CREATION}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuIndividualClient}
          />
          <SubMenuRoute
            path={Routes.COMPANY_CLIENT_CREATION}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuCompanyClient}
          />
          <SubMenuRoute
            path={Routes.INDIVIDUAL_CONTACT_CREATION}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuIndividualContact}
          />
          <SubMenuRoute
            path={Routes.COMPANY_CONTACT_CREATION}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuCompanyContact}
          />
          <SubMenuRoute
            path={Routes.TRUSTS_HISTORY}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuHistory}
          />
          <SubMenuRoute
            path={Routes.TRUSTS_HISTORY_VERSIONS}
            collapsed={collapsed}
            query={location.search}
            component={SubMenuHistory}
          />
          <div
            onMouseEnter={handleOver}
            onMouseLeave={handleOut}
            className={c(
              styles.mainMenu,
              (over || navBarStore.forcedOnlyMainNavBar) && styles.mainMenuOver,
              collapsed && styles.mainMenuCollapsed
            )}
          >
            <Route
              path="/"
              render={() => (
                <AuthorizedNavBarMain toggleNavBar={toggleNavBar} collapsed={collapsed} />
              )}
            />
          </div>
        </div>
      </div>
      <div
        className={styles.outspreadBackground}
        onClick={toggleNavBar}
        aria-label="toggle"
        role="button"
        tabIndex="0"
      />
    </div>
  )
}

export default observer(AuthorizedNavBar)
