import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Vote from 'models/Vote'
import ViewVoteContainer from './ViewVoteContainer'
import FormLabel from '../FormLabel'
import styles from './viewVotingFormVoters.module.scss'

const ViewVotingFormVoters = ({ vote }) => {
  const { t } = useTranslation('transactionsCreation')

  return (
    <div className={styles.containerVoters}>
      <FormLabel label={t('voters')} viewMode />
      <div className={styles.viewVoters}>
        {vote.votings.value.map((votes) => {
          return (
            <ViewVoteContainer
              key={`viewVoteContainer_${votes.id}`}
              name={votes.voter.name}
              statusVoter={votes.response ? votes.response : 'pending'}
            />
          )
        })}
      </div>
    </div>
  )
}

ViewVotingFormVoters.propTypes = {
  vote: PropTypes.instanceOf(Vote).isRequired,
}

export default ViewVotingFormVoters
