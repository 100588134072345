import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import HistoryElement from 'models/HistoryElement'
import styles from './historyList.module.scss'

const HistoryList = ({ historyElements }) => {
  const { t } = useTranslation('history')
  return (
    <div className={styles.listContainer}>
      {historyElements.map((element) => (
        <div
          className={styles.itemContainer}
          key={element.description + element.date.format('YYYY-MM-DD hh:mm')}
        >
          <div className={styles.decoration}>
            <div className={styles.primaryDot} />
            <div className={styles.secondaryDot} />
            <div className={styles.line} />
            <div className={styles.triangle} />
          </div>
          <div className={styles.card}>
            <div className={styles.authorStamp}>
              {t('authorStamp', {
                date: element.date.format(t('common:humanDateTimeYearFormat')),
                author: element.causedBy.name,
              })}
            </div>
            <div>{element.description}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

HistoryList.propTypes = {
  historyElements: PropTypes.arrayOf(PropTypes.instanceOf(HistoryElement)).isRequired,
}

export default HistoryList
