import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TRANSACTIONS_STORE } from 'stores/AuthStore'
import Button from 'presentation/Button'
import ButtonBack, { useBackButtonURL } from 'presentation/ButtonBack'
import styles from './buttonBackListTrust.module.scss'

const ButtonBackListTrust = ({ handleTrustButtonClick }) => {
  const { t } = useTranslation()
  const backButtonURL = useBackButtonURL()

  const buttons = []

  buttons.push(<ButtonBack key="backListTrust_1" to={backButtonURL} />)

  if (handleTrustButtonClick) {
    buttons.push(
      <Button
        key="backListTrust_2"
        primary
        label={t('common:store')}
        permission={TRANSACTIONS_STORE}
        onClick={handleTrustButtonClick}
      />
    )
  }

  return <div className={styles.actionsContainer}>{buttons}</div>
}

ButtonBackListTrust.propTypes = {
  handleTrustButtonClick: PropTypes.func,
}

ButtonBackListTrust.defaultProps = {
  handleTrustButtonClick: null,
}

export default ButtonBackListTrust
