import SignedDocumentsService from 'services/SignedDocumentsService'

class SignedDocumentsStore {
  constructor() {
    this.signedDocumentsService = new SignedDocumentsService()
  }

  uploadDot(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedUploadTrust(fileId, 'deed', trustId, letterId)
  }

  uploadLow(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedUploadTrust(fileId, 'low', trustId, letterId)
  }

  uploadOther(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedUploadTrust(fileId, 'other', trustId, letterId)
  }

  uploadLod(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedUploadTrust(fileId, 'lod', trustId, letterId)
  }

  uploadAmendment(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedUploadTrust(
      fileId,
      'amendment',
      trustId,
      letterId
    )
  }

  deleteDot(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedDeletionTrust(fileId, 'deed', trustId, letterId)
  }

  deleteLow(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedDeletionTrust(fileId, 'low', trustId, letterId)
  }

  deleteOther(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedDeletionTrust(fileId, 'other', trustId, letterId)
  }

  deleteLod(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedDeletionTrust(fileId, 'lod', trustId, letterId)
  }

  deleteAmendment(fileId, trustId, letterId) {
    return this.signedDocumentsService.notifySignedDeletionTrust(
      fileId,
      'amendment',
      trustId,
      letterId
    )
  }
}

export default SignedDocumentsStore
