import { useState, useEffect, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import LinkLockIcon from 'mdi-react/LinkLockIcon'
import MetaTitle from 'util/MetaTitle'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import ActivityDetailsTableItem from 'presentation/ActivityDetailsTableItem'
import CreationModal from 'presentation/CreationModal'
import ActivityModalForm from 'presentation/ActivityModalForm'
import ActivityDetailsActionButtons from 'presentation/ActivityDetailsTableItem/ActivityDetailsActionButtons'
import FiduaciaryStructureHeaderLink from 'presentation/FiduaciaryStructureHeaderLink'
import Button from 'presentation/Button'
import Modal from 'scenes/Modal'
import CustomActivitySearch from 'scenes/ActivityList/CustomActivitySearch'
import { TRANSACTIONS_FORCE_BLOCKCHAIN } from 'stores/AuthStore'
import { ACTIVITY_LIST } from 'routing/routes'
import ActivityDetailsListStore from './ActivityDetailsListStore'

const ActivityDetailsList = () => {
  const { t } = useTranslation('activity')
  const { search } = useLocation()
  const history = useHistory()
  const { trustId } = useParams()
  const structureType = new URLSearchParams(search).get('structure')
  const [activityDetailsListStore] = useState(
    () => new ActivityDetailsListStore(trustId, structureType)
  )
  const [showModal, setShowModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleSelectItem = useCallback((item) => {
    activityDetailsListStore.selectItem(item)
    setShowModal(true)
  }, [])

  const handleApproveChange = useCallback((id) => {
    activityDetailsListStore.approveChange(id)
    activityDetailsListStore.loadDetails()
  }, [])

  const handleRejectChange = useCallback((id) => {
    activityDetailsListStore.rejectChange(id)
    activityDetailsListStore.loadDetails()
  }, [])

  const handleAbstainChange = useCallback((id) => {
    activityDetailsListStore.abstainChange(id)
    activityDetailsListStore.loadDetails()
  }, [])

  const handleConfirmChange = useCallback((id) => {
    activityDetailsListStore.confirmChange(id)
    activityDetailsListStore.loadDetails()
    setShowModal(false)
  }, [])

  const handlePageChange = useCallback((page) => activityDetailsListStore.setActivePage(page), [])

  const handleChangeOrder = useCallback((orderParameter, orderDirection) => {
    activityDetailsListStore.loadDetails(orderParameter, orderDirection)
  }, [])

  const handleSetShowDropdownOrder = useCallback((value) => {
    activityDetailsListStore.setShowDropdownOrder(value)
  }, [])

  const handleChangeToDate = useCallback((value) => {
    activityDetailsListStore.setToDate(value)
    activityDetailsListStore.loadDetails()
  }, [])

  const handleChangeFromDate = useCallback((value) => {
    activityDetailsListStore.setFromDate(value)
    activityDetailsListStore.loadDetails()
  }, [])

  const handleGoBack = useCallback(() => {
    history.push(ACTIVITY_LIST)
  }, [])

  const handleSendToBlockchain = useCallback(() => {
    setShowConfirmModal(true)
  }, [])

  const handleConfirmSendToBlockchain = useCallback(() => {
    activityDetailsListStore.sendActivityToBlockchain()
  }, [])

  const handleCloseSendToBlockchain = useCallback(() => {
    setShowConfirmModal(false)
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'description',
        order: false,
        inverted: true,
        transName: t('activityDescription'),
        cssGridDefinition: '1.5fr',
      },
      {
        name: 'entity',
        order: false,
        inverted: true,
        transName: t('entity'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'status',
        order: true,
        inverted: false,
        transName: t('status'),
        cssGridDefinition: '1fr',
      },
    ],
    []
  )

  useEffect(() => {
    activityDetailsListStore.loadDetails()
  }, [showModal])

  const { activityDetails, selectedItem } = activityDetailsListStore

  return (
    <>
      <FiduaciaryStructureHeaderLink
        text={activityDetailsListStore.fiduciaryStructureName}
        route={activityDetailsListStore.fiduciaryStructureRoute}
      />
      <MetaTitle pageTitle={t('pageTitles:activityDetails')} />
      <MainSectionList
        sectionItems={activityDetails}
        currentPage={activityDetailsListStore.activePage}
        isLoading={activityDetailsListStore.isLoading}
        handleChangeOrder={handleChangeOrder}
        handlePageChange={handlePageChange}
        creationPermission="no"
        totalPages={activityDetailsListStore.pages}
        sectionNameSingular={t('activityDetail')}
        sectionNamePlural={t('activityDetails')}
        setShowDropdownOrder={handleSetShowDropdownOrder}
        customHeaderRightSection={
          activityDetailsListStore.structure === 'trust' ? (
            <>
              <Button
                onClick={handleSendToBlockchain}
                label={t('sendToBlockchain')}
                className="visibleDesktop"
                permission={TRANSACTIONS_FORCE_BLOCKCHAIN}
                icon={<LinkLockIcon />}
                iconPositionLeft
                primary
                big
              />
              <Button
                onClick={handleSendToBlockchain}
                className="visibleMobile"
                permission={TRANSACTIONS_FORCE_BLOCKCHAIN}
                icon={<LinkLockIcon />}
                primary
                circle
              />
            </>
          ) : null
        }
        customSearchComponent={
          <CustomActivitySearch
            setToDate={handleChangeToDate}
            setFromDate={handleChangeFromDate}
            toDateInputStore={activityDetailsListStore.toDate}
            fromDateInputStore={activityDetailsListStore.fromDate}
          />
        }
        tableComponent={
          <MainSectionTable
            t={t}
            listItems={activityDetails}
            isLoading={activityDetailsListStore.isLoading}
            onOrdered={handleChangeOrder}
            view="list"
            columns={columns}
            component={({ listItem, view: cView }) => (
              <ActivityDetailsTableItem
                listItem={listItem}
                view={cView}
                handleSelectItem={handleSelectItem}
              />
            )}
          />
        }
      />
      <ActivityDetailsActionButtons handleGoBack={handleGoBack} />
      <CreationModal
        handleClose={handleCloseModal}
        visible={showModal}
        title={t('contentChangeDetails')}
      >
        <ActivityModalForm
          item={selectedItem}
          handleRejectChange={handleRejectChange}
          handleApproveChange={handleApproveChange}
          handleAbstainChange={handleAbstainChange}
          handleConfirmChange={handleConfirmChange}
        />
      </CreationModal>
      <Modal
        name={t('confirmBlockchainTransaction')}
        open={showConfirmModal}
        onSuccess={handleConfirmSendToBlockchain}
        onCancel={handleCloseSendToBlockchain}
      >
        <div>{t('confirmBlockchainTransaction')}</div>
      </Modal>
    </>
  )
}

export default observer(ActivityDetailsList)
