import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import CreationInput from 'presentation/CreationInput'
import PercentageFormPartStore from 'models/FormMetadata/Parts/PercentageFormPartStore'
import styles from './DynamicFormPartPercentage.module.scss'

const FormPercentage = ({ inputStore, label, placeholder, literal, inputRef }) => {
  const handleChangeText = useCallback((e) => {
    inputStore.store.value.setValue(e.target.value)
    inputStore.validate()
  }, [])

  const handleOnBlur = useCallback(() => inputStore.validate(), [])

  return (
    <CreationInput
      inputStore={inputStore.store.value}
      type="number"
      min="0"
      max="100"
      label={label}
      placeholder={placeholder}
      onChange={handleChangeText}
      onBlur={handleOnBlur}
      literal={literal}
      inputRef={inputRef}
      wrapperClassName={styles.percentageInput}
    />
  )
}

FormPercentage.propTypes = {
  inputStore: PropTypes.instanceOf(PercentageFormPartStore).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  literal: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

FormPercentage.defaultProps = {
  label: '',
  placeholder: '',
  literal: true,
  inputRef: () => {},
}

export default FormPercentage
