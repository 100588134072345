import React from 'react'
import { observer } from 'mobx-react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import Dinero from 'dinero.js'
import ScrollMemory from 'react-router-scroll-memory'
import RootStore from 'stores/RootStore'
import {
  FORGOT_PASSWORD,
  LOGIN,
  ACTIVATE_USER,
  RESET_PASSWORD_USER,
  MAINTENANCE_PAGE,
  COOKIE_POLICY_PAGE,
} from 'routing/routes'
import PrivateRoute from 'routing/PrivateRoute'
import { GA_TRACKING_ID } from 'services/config'
import SignIn from 'scenes/SignIn'
import ActivateUser from 'scenes/ActivateUser'
import AuthorizedRouter from 'scenes/AuthorizedRouter'
import SendForgotPassword from 'scenes/SendForgotPassword'
import ResetPasswordUser from 'scenes/ResetPasswordUser'
import { ToastContainer } from 'react-toastify'
import axiosInterceptors from 'util/axiosInterceptors'
import LoadingRing from 'presentation/LoadingRing'
import GoogleAnalytics from 'util/GoogleAnalytics'
import StoreContext from 'providers/storeContext'
import MaintenancePage from 'scenes/MaintenancePage/MaintenancePage'
import CookiePolicy from 'scenes/CookiePolicy'
import 'react-toastify/dist/ReactToastify.css'
import 'util/i18n'
import 'styles/base.module.scss'

/* theme de colores */
const variablesName = 'variables'

/* eslint-disable no-unused-expressions */
import(`styles/${variablesName}.scss`)
/* theme de colores */

const rootStore = new RootStore()

Dinero.globalFormat = 'USD0,0.00'

moment.locale('en')

// adds handlers for all API error requests
axiosInterceptors(rootStore)
rootStore.authStore.keepAlive()

const App = () => {
  const loadingStyles = {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  }

  if (rootStore.authStore.isMaintenancePage) {
    return <MaintenancePage />
  }

  if (rootStore.authStore.isLoadingAuthFromBrowser) {
    return (
      <div style={loadingStyles}>
        <LoadingRing center />
      </div>
    )
  }

  return (
    <StoreContext.Provider value={rootStore}>
      <BrowserRouter>
        <ScrollMemory />
        <Switch>
          <Route exact path={LOGIN} component={SignIn} />
          <Route exact path={COOKIE_POLICY_PAGE} component={CookiePolicy} />
          <Route exact path={FORGOT_PASSWORD} component={SendForgotPassword} />
          <Route exact path={RESET_PASSWORD_USER} render={() => <ResetPasswordUser />} />
          <Route exact path={ACTIVATE_USER} render={() => <ActivateUser />} />
          <Route exact path={MAINTENANCE_PAGE} render={() => <MaintenancePage />} />
          <PrivateRoute redirectPath={LOGIN} path="/" component={AuthorizedRouter} />
        </Switch>
        {GA_TRACKING_ID && <GoogleAnalytics />}
        <ToastContainer />
      </BrowserRouter>
    </StoreContext.Provider>
  )
}

export default observer(App)
export { rootStore }
