import { useContext, useCallback, useState, useEffect } from 'react'
import { useCookieConsent } from '@use-cookie-consent/core'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { FORGOT_PASSWORD, COOKIE_POLICY_PAGE } from 'routing/routes'
import Modal from 'scenes/Modal'
import UnauthorizedContainer from 'presentation/UnauthorizedContainer'
import LinkButton from 'presentation/LinkButton'
import StoresContext from 'providers/storeContext'
import SignInInput from 'presentation/SignInInput'
import Button from 'presentation/Button'
import AuthFormLayout from 'presentation/AuthFormLayout'
import SignInStore from './SignInStore'
import styles from './signIn.module.scss'

const SignIn = () => {
  const { authStore } = useContext(StoresContext)
  const [signInStore] = useState(() => new SignInStore(authStore))
  const history = useHistory()
  const { consent, acceptAllCookies, declineAllCookies } = useCookieConsent()
  const [showCookieModal, setShowCookieModal] = useState(false)

  const { t } = useTranslation('signIn')
  const updateUsername = useCallback((e) => signInStore.updateUsername(e.target.value), [])

  const updatePassword = useCallback((e) => signInStore.updatePassword(e.target.value), [])

  useEffect(() => {
    if (authStore.isAuthenticated) {
      history.push(authStore.dashboardRoute)
    }
  }, [authStore.isAuthenticated, authStore.dashboardRoute])

  const handleOpenCookieModal = useCallback(() => {
    setShowCookieModal(true)
  }, [])

  const handleCancelCookieModal = useCallback(() => {
    declineAllCookies()
    setShowCookieModal(false)
  }, [])

  const handleSignIn = useCallback((e) => {
    e?.preventDefault()
    e?.stopPropagation()

    acceptAllCookies()
    setShowCookieModal(false)

    if (!consent.firstParty || !consent.thirdParty) {
      handleOpenCookieModal(true)
    } else if (consent.firstParty || consent.thirdParty) {
      signInStore.signIn()
    }

    return false
  }, [])

  const handleAcceptCookies = useCallback(() => {
    setShowCookieModal(false)
    signInStore.signIn()
  }, [])

  return (
    <UnauthorizedContainer title={t('pageTitles:signIn')}>
      <AuthFormLayout title={t('titleSignIn')}>
        {signInStore.hasErrors && (
          <div className={styles.errorBox}>
            {signInStore.errors.map((error, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>{error}</div>
            ))}
          </div>
        )}
        <form action="" onSubmit={handleSignIn} className={styles.signInForm}>
          <SignInInput
            autoFocus
            type="email"
            placeholder={t('username')}
            value={signInStore.username}
            handleChange={updateUsername}
            hasError={signInStore.hasErrors}
          />
          <SignInInput
            type="password"
            placeholder={t('password')}
            value={signInStore.password}
            handleChange={updatePassword}
            hasError={signInStore.hasErrors}
          />
          <Button
            isLoading={signInStore.isLoading}
            label={t('btn_signIn')}
            primary
            big
            type="submit"
          />
          <LinkButton route={FORGOT_PASSWORD} text={t('forgotPassword')} />
        </form>
      </AuthFormLayout>
      <Modal
        name={t('cookieModal')}
        title={t('cookieModal')}
        open={showCookieModal}
        onSuccess={handleAcceptCookies}
        onCancel={handleCancelCookieModal}
        closeOutside={false}
      >
        <div>
          {t('cookieModalText')}
          <br />
          {t('cookieModalPleaseConfirm')}
          <span>
            <a href={COOKIE_POLICY_PAGE} target="_blank" rel="noreferrer">
              {t('cookiePolicy')}
            </a>
          </span>
        </div>
      </Modal>
    </UnauthorizedContainer>
  )
}

export default observer(SignIn)
