import PartStrategy from './PartStrategy'

const VISIBLE_STATE_CREATION = 'create'
const VISIBLE_STATE_EDITION = 'edit'
const VISIBLE_STATE_VIEW = 'view'

class FormSection {
  parts = []

  constructor(id, menuLabel, menuIcon, sectionTitle, permission, visibleStates) {
    this.id = id
    this.menuLabel = menuLabel
    this.menuIcon = menuIcon
    this.sectionTitle = sectionTitle
    this.permission = permission
    this.visibleStates = visibleStates
  }

  get route() {
    return this.id
  }

  isVisibleCreation() {
    return this.visibleStates.length === 0 || this.visibleStates.includes(VISIBLE_STATE_CREATION)
  }

  isVisibleEdition() {
    return this.visibleStates.length === 0 || this.visibleStates.includes(VISIBLE_STATE_EDITION)
  }

  isVisibleView() {
    return this.visibleStates.length === 0 || this.visibleStates.includes(VISIBLE_STATE_VIEW)
  }

  addPart(part) {
    this.parts.push(part)
  }

  static fromJson({ id, display, parts }, onCreateInput) {
    const {
      menuLabel = '',
      menuIcon = '',
      sectionTitle = '',
      permission = 'yes',
      visibleStates = [],
    } = display

    const formSection = new FormSection(
      id,
      menuLabel,
      menuIcon,
      sectionTitle,
      permission,
      visibleStates
    )

    try {
      parts.forEach((partJson) => {
        const part = PartStrategy.getPart(partJson, onCreateInput)

        if (part) {
          formSection.addPart(part)
        }
      })
    } catch (e) {
      // do nothing
    }

    return formSection
  }
}

export default FormSection
