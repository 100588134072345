import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash'
import Select from 'react-select'
import LiteralValue from 'presentation/LiteralValue'
import FormLabel from 'presentation/FormLabel'
import InputStore from 'stores/InputStore'

const customStyles = (mergeStyles, error) => ({
  control: (provided, state) => {
    let borderColor = 'var(--inputs-border-color)'

    if (state.menuIsOpen) {
      borderColor = 'var(--main-color)'
    }

    if (error) {
      borderColor = 'var(--signin-error-color)'
    }

    return {
      ...provided,
      opacity: state.isDisabled ? '0.3' : '1',
      borderColor,
      borderRadius: '25px',
      background: 'transparent',
      boxShadow: 'none',
      fontFamily: 'verdana',
      minHeight: '40px',
      paddingBottom: '0',
      paddingTop: '0',
      paddingLeft: '25px',
      paddingRight: '10px',
      width: '100%',
      ':hover': {
        borderColor,
      },
      ...mergeStyles.control,
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '38px',
    fontWeight: 'normal',
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--inputs-border-color)',
    fontWeight: 'normal',
    fontSize: '14px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
    width: '100%',
    zIndex: 13,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ':active': {
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: state.isSelected
          ? 'var(--main-color)'
          : state.isFocused
          ? '#ededed'
          : 'white',
      },
      // eslint-disable-next-line no-nested-ternary
      color: state.isSelected
        ? 'white'
        : state.isFocused
        ? 'var(--form-select-option-focused-color)'
        : 'var(--form-select-option-color)',
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: state.isSelected
        ? 'var(--main-color)'
        : state.isFocused
        ? '#ededed'
        : 'white',
      fontSize: '14px',
      fontWeight: 'normal',
    }
  },
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--main-text-color)',
    fontWeight: 'normal',
    fontSize: '14px',
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'var(--inputs-multivalue-bg)',
    borderRadius: '100px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--main-text-color)',
    fontSize: '14px',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--main-color)',
    fontSize: '14px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    span: {
      backgroundColor: 'white',
    },
  }),
})

const FormSelect = ({
  formSelectAs: Component,
  inputRef,
  inputStore,
  label,
  mergeStyles,
  disabled,
  error,
  literal,
  value,
  isMulti,
  options,
  autoSelect,
  ...props
}) => {
  const [onlyValue, setOnlyValue] = useState({})

  useEffect(() => {
    if (options?.length === 1 && autoSelect) {
      const [selectOption] = options
      setOnlyValue(selectOption)
      if (inputStore) {
        inputStore.setValue(selectOption)
      }
    }
  }, [options, autoSelect, inputStore])

  return literal ? (
    <LiteralValue
      label={label}
      value={isMulti ? value.map((e) => e.value).join(', ') : value.value}
    />
  ) : (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      {label && <FormLabel label={label} disabled={disabled} />}
      <Component
        styles={customStyles(mergeStyles, error)}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            e.target.blur()
          }
        }}
        isDisabled={disabled}
        getOptionLabel={(option) => {
          // eslint-disable-next-line no-underscore-dangle
          if (option.__isNew__) {
            return option.label
          }

          return option.value
        }}
        getOptionValue={(option) => {
          return option.id || option.value
        }}
        value={value || onlyValue}
        isMulti={isMulti}
        options={orderBy(options, 'value', 'asc')}
        {...props}
      />
    </label>
  )
}
FormSelect.propTypes = {
  formSelectAs: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  label: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  mergeStyles: PropTypes.shape({}),
  error: PropTypes.bool,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  options: PropTypes.oneOfType([PropTypes.array]),
  disabled: PropTypes.bool,
  literal: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
    PropTypes.shape({}),
    PropTypes.oneOfType([PropTypes.array]),
    PropTypes.string,
  ]),
  autoSelect: PropTypes.bool,
  inputStore: PropTypes.instanceOf(InputStore),
}

FormSelect.defaultProps = {
  label: '',
  formSelectAs: Select,
  inputRef: null,
  options: [],
  mergeStyles: { control: {} },
  disabled: false,
  error: false,
  literal: false,
  isMulti: false,
  value: null,
  autoSelect: false,
  inputStore: null,
}

export default FormSelect
