import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import UsersService from 'services/UsersService'

class ClientsListStore extends AsyncStore {
  itemPerPage = 10
  users = []
  order = ''
  activePage = 1
  pages = 5
  activeSearch = false
  showDropdownOrder = false
  lastSearch = ''
  lastOrderParam = ''
  lastOrderDir = ''

  constructor() {
    super()

    makeObservable(this, {
      // observables
      users: observable,
      order: observable,
      activePage: observable,
      pages: observable,
      activeSearch: observable,
      showDropdownOrder: observable,
      // actions
      changeActivePage: action,
      loadUsers: action,
      setShowDropdownOrder: action,
      // computeds
      visibleUsers: computed,
    })

    this.isLoading = true
    this.usersService = new UsersService()
  }

  get visibleUsers() {
    return this.users
  }

  changeActivePage(pageNumber, orderParam, orderDir) {
    this.activePage = pageNumber
    this.loadUsers(null, orderParam, orderDir)
  }

  async loadUsers(searchKey = null, orderParam = null, orderDir = null, resetpage = false) {
    this.activeSearch = searchKey !== '' && searchKey !== null

    this.preRequest(this.loadUsers)

    try {
      if (searchKey || searchKey === '') {
        this.lastSearch = searchKey
      }

      if (orderParam || orderParam === '') {
        this.lastOrderParam = orderParam
      }

      if (orderDir || orderDir === '') {
        this.lastOrderDir = orderDir
      }

      if (resetpage) {
        this.activePage = 1
      }

      const response = await this.usersService.listUsers(
        this.activePage,
        this.lastSearch,
        this.lastOrderParam,
        this.lastOrderDir
      )
      runInAction(() => {
        this.users = response.users
        this.pages = response.pagination.totalPages

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }
}

export default ClientsListStore
