import React, { useContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import StoresContext from 'providers/storeContext'
import { FileListPart } from 'models/FormMetadata/Parts'
import DocumentForm from 'presentation/DocumentForm/DocumentForm'
import { observer } from 'mobx-react'

const DynamicFormPartFileList = ({ part, inputs, readOnly, modal }) => {
  const { trustsCreationStore } = useContext(StoresContext)
  const [inputStore] = useState(inputs.get(part.id).store)
  const handleStartUploadingFile = useCallback(() => trustsCreationStore.startUploadingFile(), [])
  const handleFinishUploadingFile = useCallback(() => trustsCreationStore.finishUploadingFile(), [])

  return (
    <DocumentForm
      store={inputStore}
      literal={readOnly}
      large={!modal}
      descriptions
      handleStartUploadingFile={handleStartUploadingFile}
      handleFinishUploadingFile={handleFinishUploadingFile}
    />
  )
}

DynamicFormPartFileList.propTypes = {
  part: PropTypes.instanceOf(FileListPart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  modal: PropTypes.bool,
}

DynamicFormPartFileList.defaultProps = {
  readOnly: true,
  modal: false,
}

export default observer(DynamicFormPartFileList)
