import React, { useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import * as Routes from 'routing/routes'
import MetaTitle from 'util/MetaTitle'
import { useHistory } from 'react-router'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import { CREATE_USER } from 'stores/AuthStore'
import UsersTableItem from 'presentation/UsersTableItem'
import ServerError from 'presentation/ServerError'
import UsersListStore from './UsersListStore'

const UsersList = () => {
  const [usersListStore] = useState(() => new UsersListStore())
  const [columns, setColumns] = useState([])
  const { t } = useTranslation('users')
  const history = useHistory()

  const handleChangeOrder = useCallback(
    (orderParamter, orderDirection) =>
      usersListStore.loadUsers(
        null,
        orderParamter.includes('user')
          ? orderParamter.replace('user', '').toLowerCase()
          : orderParamter,
        orderDirection,
        true
      ),
    []
  )
  const handleSearch = useCallback((value) => usersListStore.loadUsers(value, null, null, true), [])
  const handlePageChange = useCallback((page) => usersListStore.changeActivePage(page), [])
  const handleCreate = useCallback(() => history.push(Routes.USERS_CREATION), [])

  useEffect(() => {
    setColumns([
      {
        name: 'userName',
        order: true,
        inverted: true,
        transName: t('common:userName'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'email',
        order: true,
        inverted: false,
        transName: t('common:email'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'status',
        order: true,
        inverted: false,
        transName: t('common:status'),
        cssGridDefinition: '130px',
      },
    ])

    usersListStore.loadUsers()
  }, [])

  const handleSetShowDropdownOrder = useCallback((value) => {
    usersListStore.setShowDropdownOrder(value)
  }, [])

  const { visibleUsers, activePage, isLoading, pages } = usersListStore

  const handleTryAgain = useCallback(() => usersListStore.tryAgain(), [])

  if (usersListStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  return (
    <>
      <MetaTitle pageTitle={t('pageTitles:users')} />
      <MainSectionList
        setShowDropdownOrder={handleSetShowDropdownOrder}
        sectionItems={visibleUsers}
        currentPage={activePage}
        isLoading={isLoading}
        handleChangeOrder={handleChangeOrder}
        handlePageChange={handlePageChange}
        creationPermission={CREATE_USER}
        totalPages={pages}
        handleCreation={handleCreate}
        handleSearch={handleSearch}
        sectionNameSingular={t('user')}
        sectionNamePlural={t('users')}
        tableComponent={
          <MainSectionTable
            t={t}
            setShowDropdownOrder={handleSetShowDropdownOrder}
            showDropdownOrder={usersListStore.showDropdownOrder}
            listItems={visibleUsers}
            isLoading={isLoading}
            onOrdered={handleChangeOrder}
            view="list"
            columns={columns}
            component={({ listItem, view: cView }) => (
              <UsersTableItem
                view={cView}
                listItem={listItem}
                link={`${Routes.USERS_CREATION}?id=${listItem.id}&v=true`}
              />
            )}
          />
        }
      />
    </>
  )
}

export default observer(UsersList)
