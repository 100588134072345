import ListDate from 'models/ListDate'
import CorporationStatus from 'models/CorporationStatus'
import ListCorporationParty from 'models/ListCorporationParty'

class ListCorporation {
  constructor(
    id,
    name,
    controllingParties,
    updated,
    created,
    dataUpdated,
    status,
    alternativeStatus
  ) {
    this.id = id
    this.name = name
    this.controllingParties = controllingParties
    this.updated = updated
    this.created = created
    this.dataUpdated = dataUpdated
    this.status = new CorporationStatus(status)
    this.alternativeStatus = new CorporationStatus(alternativeStatus)
  }

  get isMultipleControllingParties() {
    return this.controllingParties.length > 1
  }

  get isCompanyControllingParty() {
    return this.controllingParties.length === 1 && this.controllingParties[0].isCompany
  }

  get isIndividualControllingParty() {
    return this.controllingParties.length === 1 && this.controllingParties[0].isIndividual
  }

  get hasControllingParty() {
    return this.controllingParties.length > 0
  }

  get isDraft() {
    return this.status.isDraft
  }

  get isExecuted() {
    return this.status.isExecuted
  }

  get isExecution() {
    return this.status.isExecution
  }

  get isStored() {
    return this.status.isStored
  }

  get isTerminated() {
    return this.status.isTerminated
  }

  static fromJson({ id, name, controllingParties, updated, created, dataUpdated, status = {} }) {
    return new ListCorporation(
      id,
      name,
      controllingParties.map((controllingParty) => ListCorporationParty.fromJson(controllingParty)),
      ListDate.fromJson(updated),
      ListDate.fromJson(created),
      ListDate.fromJson(dataUpdated),
      status.currentData.status,
      status.alternativeData ? status.alternativeData.status : null
    )
  }
}

export default ListCorporation
