import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Rule from 'models/Rule'
import TrustAssetsStore from 'stores/TrustAssetsStore'
import TrustBeneficiariesStore from 'stores/TrustBeneficiariesStore'
import TrustGovernorsStore from 'stores/TrustGovernorsStore'
import RuleAssetFormItem from 'presentation/RuleAssetFormItem'
import Button from 'presentation/Button'
import StoresContext from 'providers/storeContext'
import LoadingRing from 'presentation/LoadingRing'

/* eslint-disable class-methods-use-this */
class RuleAssetsForm extends React.Component {
  constructor(props, context) {
    super(props)

    const { trustsCreationStore } = context

    this.trustAssetsStore = new TrustAssetsStore(trustsCreationStore.idEdition)
    this.trustBeneficiariesStore = new TrustBeneficiariesStore(trustsCreationStore.idEdition)
    this.trustGovernorsStore = new TrustGovernorsStore(trustsCreationStore.idEdition)
  }

  componentDidMount() {
    const { hotkeyStore, trustsCreationStore } = this.context

    this.trustAssetsStore.loadAssets(trustsCreationStore.alternativeStatusLoad)
    this.trustBeneficiariesStore.loadBeneficiaries(trustsCreationStore.alternativeStatusLoad)
    this.trustGovernorsStore.loadGovernors()

    const { rule } = this.props

    hotkeyStore.setConfig('RuleAssetForm', [
      {
        shortcut: 'alt+c',
        handler: () => {
          rule.addEmptyAsset()
        },
      },
    ])
  }

  componentWillUnmount() {
    const { hotkeyStore } = this.context
    hotkeyStore.removeConfig('RuleAssetForm')
  }

  deleteAsset = (asset) => {
    const { rule } = this.props
    rule.removeAsset(asset)
  }

  render() {
    const { rule } = this.props

    if (this.trustAssetsStore.isLoading || this.trustBeneficiariesStore.isLoading) {
      return <LoadingRing center small />
    }

    return (
      <div>
        {rule.assets.map((ruleAsset, i) => (
          <RuleAssetFormItem
            ruleAsset={ruleAsset}
            options={ruleAsset.getOptions(rule.selectedAssets, this.trustAssetsStore.assets)}
            assets={this.trustAssetsStore.assets}
            beneficiaries={this.trustBeneficiariesStore.beneficiaries}
            governors={this.trustGovernorsStore.governors}
            // eslint-disable-next-line react/no-array-index-key
            key={`ruleAssetFormItem_${i}`}
            showDeleteButton={rule.assets.length > 1}
            handleDeleteAsset={this.deleteAsset}
            isSaved={rule.saved}
          />
        ))}
        <div>
          <Button label="Add Asset" onClick={() => rule.addEmptyAsset()} />
        </div>
      </div>
    )
  }
}

RuleAssetsForm.contextType = StoresContext

RuleAssetsForm.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
}

export default observer(RuleAssetsForm)
