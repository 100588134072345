import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Input from 'presentation/Input'
import EyeIcon from 'mdi-react/EyeIcon'
import EyeOffIcon from 'mdi-react/EyeOffIcon'
import styles from './passwordInput.module.scss'

const PasswordInput = ({ type, ...props }) => {
  const [inputType, setInputType] = useState(type)

  const handleToggleType = useCallback(
    () => (inputType === 'password' ? setInputType('text') : setInputType('password')),
    [inputType]
  )

  return (
    <div className={styles.inputContainer}>
      <Input type={inputType} {...props} />
      {inputType === 'password' ? (
        <EyeIcon className={styles.passwordIcon} size={20} onClick={handleToggleType} />
      ) : (
        <EyeOffIcon className={styles.passwordIcon} size={20} onClick={handleToggleType} />
      )}
    </div>
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  onlyNumeric: PropTypes.bool,
  literal: PropTypes.bool,
  value: PropTypes.string,
}

PasswordInput.defaultProps = {
  label: '',
  type: '',
  inputRef: null,
  disabled: false,
  onChange: null,
  onlyNumeric: false,
  error: false,
  literal: false,
  value: '',
}

export default PasswordInput
