import React, { useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import PageTitle from 'presentation/PageTitle'
import { useHistory, useParams } from 'react-router'
import LoadingRing from 'presentation/LoadingRing'
import StoreTitleName from 'presentation/StoreTitleName'
import PermissionUserList from 'presentation/PermissionUserList'
import { useTranslation } from 'react-i18next'
import Modal from 'scenes/Modal'
import DynamicEntitiesUserItem from 'presentation/DynamicEntitiesUserItem'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import { successToast } from 'helpers/Toast'
import RequestErrors from 'presentation/RequestErrors'
import NoResultsList from 'presentation/NoResultsList'
import { CORPORATIONS_LIST } from 'routing/routes'
import CorporationUsersActionButtons from './CorporationUsersActionButtons'
import styles from './corporationUsers.module.scss'
import CorporationUsersStore from './CorporationUsersStore'

const CorporationUsers = () => {
  const { t } = useTranslation('corporationUsers')
  const history = useHistory()
  const [lastSearch, setLastSearch] = useState('')
  const [deleteUser, setDeleteUser] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [corporationUsersStore] = useState(() => new CorporationUsersStore())
  let searchUsersTimeout = null
  const { corporationId } = useParams()

  useEffect(() => {
    corporationUsersStore.init(corporationId).then(() => {
      corporationUsersStore.searchUsers()
    })
  }, [])

  const handleInputChange = (value) => {
    if (searchUsersTimeout) {
      clearTimeout(searchUsersTimeout)
    }

    searchUsersTimeout = setTimeout(() => {
      if (value !== lastSearch) {
        corporationUsersStore.searchUsers(value)
        setLastSearch(value)
      }
    }, 300)
  }

  const handleInputFocus = useCallback(() => {
    corporationUsersStore.searchUsers()
  }, [])

  const saveUsers = useCallback((toastText = t('corporationUserSavedMsg')) => {
    corporationUsersStore.save(() => {
      corporationUsersStore.searchUsers()
      successToast(toastText, null, 'corporationUserSaved').show()
      corporationUsersStore.init(corporationUsersStore.id)
    })
  }, [])

  const handleSetUserRole = useCallback((selectedUser, role) => {
    CorporationUsersStore.setUserRole(selectedUser, role)
    saveUsers()
  }, [])

  const handleChange = useCallback((selectedUser) => {
    corporationUsersStore.addUser(selectedUser)
    saveUsers()
  }, [])

  const handleDelete = useCallback((selectedUser) => {
    setDeleteUser(selectedUser)
    setDeleteModal(true)
  }, [])

  const successDeleteModal = useCallback(() => {
    corporationUsersStore.deleteUser(deleteUser)
    setDeleteModal(false)
    saveUsers(t('userRemoved'))
  }, [deleteUser])

  const cancelDeleteModal = useCallback(() => setDeleteModal(false), [])

  const handleGoBack = useCallback(() => history.push(CORPORATIONS_LIST))

  if (corporationUsersStore.noIdError) {
    return (
      <GenericNotFoundError
        description={t('noIdErrorDesc')}
        textButton={t('noIdErrorButton')}
        goBackFunction={handleGoBack}
        title={t('noIdErrorTitle')}
      />
    )
  }

  if (corporationUsersStore.usersError) {
    return (
      <GenericNotFoundError
        description={t('corporationUsersErrorDesc')}
        textButton={t('corporationUsersErrorButton')}
        goBackFunction={handleGoBack}
        title={t('corporationUsersErrorTitle')}
      />
    )
  }

  return (
    <>
      <StoreTitleName className={styles.subtitle} name={corporationUsersStore.corporationName} />
      <PageTitle title={t('corporationUsersTitle')} floating />
      <CreationInput
        as={FormSelect}
        inputStore={corporationUsersStore.store}
        name="fullName"
        label={t('selectUserLabel')}
        placeholder={t('selectUserPlaceholder')}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onFocus={handleInputFocus}
        filterOption={(option) => option}
        options={corporationUsersStore.searchOptionUsers}
        isLoading={corporationUsersStore.loadingSearch}
        value={null}
        literal={false}
        components={{ Option: DynamicEntitiesUserItem }}
      />
      <div className={styles.assignedUsersContainer}>
        {corporationUsersStore.isLoadingData &&
          corporationUsersStore.assignedUsers.length === 0 && <LoadingRing small center />}
        {!corporationUsersStore.isLoadingData &&
        corporationUsersStore.assignedUsers.length === 0 ? (
          <NoResultsList topText={t('noSelectedUsers')} showIcon={false} />
        ) : (
          <PermissionUserList
            assignedUsers={corporationUsersStore.assignedUsers}
            loadingSave={corporationUsersStore.loadingSave}
            handleSetUserRole={handleSetUserRole}
            handleDelete={handleDelete}
          />
        )}
      </div>
      <Modal
        open={deleteModal}
        name="deleteUserModal"
        title={t('deleteModalTitle')}
        onSuccess={successDeleteModal}
        onCancel={cancelDeleteModal}
      />
      <RequestErrors errors={corporationUsersStore.requestErrors} />
      <CorporationUsersActionButtons handleCancel={handleGoBack} />
    </>
  )
}

export default observer(CorporationUsers)
