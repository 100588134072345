import axios from 'axios'
import { API_URL } from 'services/config'
import GoverningLaw from 'models/GoverningLaw'

/* eslint-disable class-methods-use-this */
class GoverningLawService {
  listGoverningLaw = () =>
    axios
      .get(`${API_URL}/governing-laws`)
      .then((response) =>
        response.data.data.map((governingLawList) => GoverningLaw.fromJson(governingLawList))
      )

  saveGoverningLaw = (data) =>
    axios.post(`${API_URL}/governing-laws`, data).then((response) => response.data.data)
}

export default GoverningLawService
