import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Tippy from '@tippy.js/react'
import moneyValueFormat from 'util/numberHelper'
import styles from './genericRowValuationExtraData.module.scss'

const GenericRowValuationExtraData = ({ extraData }) => {
  const { t } = useTranslation('dynamicForms')

  const tooltip = (
    <>
      {extraData.slice(1, extraData.length).map((data) => (
        <div key={`data-${data.amount}${data.currency}`}>
          {moneyValueFormat(data.amount, data.currency)}
        </div>
      ))}
    </>
  )

  return (
    <div className={styles.container}>
      <div className={styles.firstValuation}>
        {extraData.length
          ? moneyValueFormat(extraData[0].amount, extraData[0].currency)
          : moneyValueFormat(0, 'USD')}
      </div>

      {extraData.length > 1 && (
        <Tippy placement="bottom" content={tooltip}>
          <div className={styles.tooltipLink}>{t('andMore', { amount: extraData.length - 1 })}</div>
        </Tippy>
      )}
    </div>
  )
}

GenericRowValuationExtraData.propTypes = {
  extraData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
}

GenericRowValuationExtraData.defaultProps = {
  extraData: [],
}

export default GenericRowValuationExtraData
