import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import MetaTitle from 'util/MetaTitle'
import c from 'classnames'
import { successToast, warningToast } from 'helpers/Toast'
import PlusIcon from 'mdi-react/PlusIcon'
import { DASHBOARD } from 'routing/routes'
import PageTitle from 'presentation/PageTitle'
import CreationInput from 'presentation/CreationInput'
import Button from 'presentation/Button/Button'
import Modal from 'scenes/Modal'
import { capitalize } from 'util/string'
import GoverningLawStore from './GoverningLawStore'
import GoverningLawCreationActionButtons from './GoverningLawCreationActionButtons'
import GoverningLawItem from './GoverningLawItem'
import styles from './settings.module.scss'

const Settings = () => {
  const { t } = useTranslation('governingLaw')
  const [governingLawStore] = useState(() => new GoverningLawStore())
  const history = useHistory()

  const handleChangeGoverningLawName = useCallback((e) => {
    const textFormating = capitalize(e.target.value)
    governingLawStore.name.setValue(textFormating)
    governingLawStore.governingLaw?.name?.setValue(textFormating)
  }, [])

  const handleChangeTrustEnabled = useCallback(() => {
    governingLawStore.setTrustEnabled()
  }, [])

  const handleChangeCorporationEnabled = useCallback(() => {
    governingLawStore.setCorporationEnabled()
  }, [])

  const handleCreateGoverningLaw = useCallback(() => {
    governingLawStore.createGoverningLaw()
  }, [])

  const handleCloseModal = useCallback(() => {
    governingLawStore.hideVisibleErrorModal()
  }, [])

  const handleCancel = useCallback(() => {
    governingLawStore.cleanCreation()
    history.push(DASHBOARD)
  }, [])

  const handleSave = useCallback(() => {
    governingLawStore.save()

    if (governingLawStore.governingLaw === null) {
      warningToast(t('warningGoverningLaw'), null, 'warningGoverningLaw').show()
    }
    if (governingLawStore.governingLaw !== null && governingLawStore.isNotSelectedOptions) {
      warningToast(t('warningGoverningLawOptions'), null, 'warningGoverningLawOptions').show()
    }
  }, [])

  useEffect(() => {
    if (governingLawStore.isSaving) {
      successToast(t('successUpload'), null, 'successUpload').show()
    }
  }, [governingLawStore.isSaving])

  return (
    <>
      <div className={styles.userProfile}>
        <PageTitle title={t('settings')} />
        <MetaTitle pageTitle={t('settings')} />
        <div className={styles.subtitle}>{t('governingLaw')}</div>
        <div className={styles.containerGoverningLaw}>
          <CreationInput
            label={t('addGoverningLaw')}
            inputStore={governingLawStore.name}
            onChange={handleChangeGoverningLawName}
            placeholder={t('addGoverningLawPlaceholder')}
          />
          <div className={c(styles.buttonAdd, governingLawStore.name.error && styles.error)}>
            <Button onClick={handleCreateGoverningLaw} circle icon={<PlusIcon />} />
          </div>
        </div>
      </div>
      <div className={styles.governingLawList}>
        {governingLawStore.governingLaw && (
          <GoverningLawItem
            governingLawStore={governingLawStore}
            governingLaw={governingLawStore.governingLaw}
            handleChangeTrustEnabled={handleChangeTrustEnabled}
            handleChangeCorporationEnabled={handleChangeCorporationEnabled}
          />
        )}
      </div>
      <GoverningLawCreationActionButtons
        handleSave={handleSave}
        store={governingLawStore}
        handleCancel={handleCancel}
      />
      <Modal
        okOnly
        name="errorServer"
        onSuccess={handleCloseModal}
        title={t('titleErrorMessage')}
        open={governingLawStore.visibleErrorModal}
      >
        <div className={styles.errorMessageModal}>{governingLawStore.messageErrorServer}</div>
      </Modal>
    </>
  )
}

export default observer(Settings)
