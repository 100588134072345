import React from 'react'
import { observer } from 'mobx-react'
import c from 'classnames'
import PropTypes from 'prop-types'
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon'
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import SitemapIcon from 'mdi-react/SitemapIcon'
import EyeIcon from 'mdi-react/EyeIcon'
import Rule from 'models/Rule'
import RuleViewItemBeneficiaries from 'presentation/RuleViewItemBeneficiaries'
import RuleViewItemScheduling from 'presentation/RuleViewItemScheduling'
import styles from './ruleViewItem.module.scss'

const RuleViewItem = ({
  rule,
  selected,
  viewMode,
  deleteRule,
  handleEditRule,
  handleShowScrollTextClick,
  trustBeneficiaries,
  trustAssets,
}) => {
  const viewRuleGraphical = () => rule.showGraphicalView()

  return (
    <>
      <div className={styles.ruleTitle}>{rule.title.value}</div>
      {selected && (
        <>
          {rule.description.value !== '' && rule.description.value !== null && (
            <div className={styles.ruleDescription}>
              <div>
                <MessageTextOutlineIcon size={17} />
              </div>
              <div className={styles.descriptionDisplay}>{rule.description.value}</div>
            </div>
          )}
          <RuleViewItemBeneficiaries
            trustBeneficiaries={trustBeneficiaries}
            trustAssets={trustAssets}
            rule={rule}
            handleViewMore={viewRuleGraphical}
          />
          <RuleViewItemScheduling rule={rule} />
          <div className={styles.actionsContainer}>
            {!viewMode && (
              <>
                <div className={styles.actionIcon} onClick={deleteRule} role="button" tabIndex="0">
                  <DeleteOutlineIcon size={19} />
                </div>
                <div
                  className={styles.actionIcon}
                  onClick={handleEditRule}
                  role="button"
                  tabIndex="0"
                >
                  <PencilIcon size={19} />
                </div>
              </>
            )}
            {rule.hasValidAssets && (
              <div
                className={styles.actionIcon}
                onClick={viewRuleGraphical}
                role="button"
                tabIndex="0"
              >
                <SitemapIcon size={19} />
              </div>
            )}
            <div
              className={c(styles.actionIcon)}
              onClick={handleShowScrollTextClick}
              role="button"
              tabIndex="0"
            >
              <EyeIcon size={19} />
            </div>
            {/* <div><PlaylistPlusIcon onClick={() => handleAddChunk(rule)} /></div> */}
          </div>
        </>
      )}
    </>
  )
}

RuleViewItem.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
  deleteRule: PropTypes.func.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleEditRule: PropTypes.func.isRequired,
  handleShowScrollTextClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  viewMode: PropTypes.bool,
}

RuleViewItem.defaultProps = {
  selected: false,
  viewMode: true,
}

export default observer(RuleViewItem)
