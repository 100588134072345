import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PlusIcon from 'mdi-react/PlusIcon'
import Gated from 'util/Gated'
import PageTitle from 'presentation/PageTitle'
import FiduaciaryStructureHeaderLink from 'presentation/FiduaciaryStructureHeaderLink'
import { TRANSACTIONS_CREATE, VOTE_CREATE, VOTE_VIEW } from 'stores/AuthStore'
import Button from 'presentation/Button'
import { Tab, TabsWizard } from 'presentation/Tabs'
import TrustSectionError from 'presentation/TrustSectionError'
import FiduciaryStructurePollsStore from './FiduciaryStructurePollsStore'
import ComplexResolutionModalStore from './ComplexResolutionModalStore'
import PollsListTab from './PollsListTab'
import VotesListTab from './VotesListTab'
import FiduciaryStructureVotesStore from './FiduciaryStructureVotesStore'

function FiduciaryStructuresPolls({ location }) {
  const { search } = location
  const params = new URLSearchParams(search)
  const tab = params.get('tab')
  const [hashTab] = useState(tab)
  const [viewListVotes, setViewListVotes] = useState(false)
  const fiduciaryStructureId = params.get('id')
  const structure = params.get('structure')

  const { t } = useTranslation('transactionsCreation')
  const [fiduciaryStructurePollsStore] = useState(
    () => new FiduciaryStructurePollsStore(fiduciaryStructureId, structure)
  )
  const [fiduciaryStructureVotesStore] = useState(
    () => new FiduciaryStructureVotesStore(fiduciaryStructureId, structure)
  )
  const [complexResolutionListStore] = useState(() => new ComplexResolutionModalStore())

  const handleOnChangeTab = (active) => {
    if (active === 0) {
      fiduciaryStructureVotesStore.resetVotesList()
      fiduciaryStructurePollsStore.reloadList()
      setViewListVotes(false)
    }
    if (active === 1) {
      fiduciaryStructurePollsStore.resetTransationslist()
      fiduciaryStructureVotesStore.loadListVotes()
      fiduciaryStructureVotesStore.loadListTransactionsForSelect()
      setViewListVotes(true)
    }
  }

  // =================================
  // Transactions
  // =================================

  const handleCloseModal = () => {
    fiduciaryStructurePollsStore.hideErrorModal()
    fiduciaryStructurePollsStore.closeRejectModal()
    fiduciaryStructurePollsStore.hideCreationModal()
    fiduciaryStructurePollsStore.closeApproveModal()
    fiduciaryStructurePollsStore.resetShowTransaction()
    fiduciaryStructurePollsStore.transactionFromDashboard = []
    fiduciaryStructurePollsStore.setCanOpenTransactionFromDashboard(false)
  }

  const handleSaveTransaction = () => {
    fiduciaryStructurePollsStore.createTransaction()

    if (fiduciaryStructurePollsStore.checkedSaveAndCreate) {
      setTimeout(() => {
        fiduciaryStructurePollsStore.resetShowTransaction()
        fiduciaryStructurePollsStore.toggleCheckedSaveAndCreate()
        fiduciaryStructurePollsStore.showCreationModal()
      }, 500)
    }
  }

  const handleOpenViewTransaction = (transaction) => {
    fiduciaryStructurePollsStore.setShowTransaction(transaction)
    fiduciaryStructurePollsStore.showCreationModal()
    fiduciaryStructurePollsStore.setViewDashboardTransaction(false)
  }

  const handleAddTransaction = useCallback(() => {
    fiduciaryStructurePollsStore.showCreationModal()
  }, [])

  const handleApproveTransaction = useCallback((transaction) => {
    fiduciaryStructurePollsStore.setShowTransaction(transaction)
    fiduciaryStructurePollsStore.showApproveModal()
  }, [])

  const handleSuccessApprove = useCallback(() => {
    fiduciaryStructurePollsStore.approveTransaction(fiduciaryStructurePollsStore.showTransaction.id)
    handleCloseModal()
  }, [])

  const handleRejectTransaction = useCallback((transaction) => {
    fiduciaryStructurePollsStore.setShowTransaction(transaction)
    fiduciaryStructurePollsStore.showRejectModal()
  }, [])

  const handleSuccessReject = useCallback(() => {
    fiduciaryStructurePollsStore.rejectTransaction(fiduciaryStructurePollsStore.showTransaction.id)
    handleCloseModal()
  }, [])

  const handleModalDeleteTransaction = useCallback((transaction) => {
    fiduciaryStructurePollsStore.setDeleteTransaction(transaction)
    fiduciaryStructurePollsStore.showDeleteModal()
  }, [])

  const handleDeleteTransaction = useCallback(() => {
    fiduciaryStructurePollsStore.deleteTransactionSelected()
    fiduciaryStructurePollsStore.hideDeleteModal()
  }, [])

  const handleStoreTransactions = useCallback(() => {
    fiduciaryStructurePollsStore.showStoreModal()
  }, [])

  const handleSuccessStore = useCallback(() => {
    fiduciaryStructurePollsStore.closeStoreModal()
    fiduciaryStructurePollsStore.storeTransactions()
  }, [])

  const handleCancelStore = useCallback(() => {
    fiduciaryStructurePollsStore.closeStoreModal()
  }, [])

  // Method for creation transaction in TransactionsForm

  const handleChangeName = useCallback((e) => {
    fiduciaryStructurePollsStore.showTransaction.setName(e.target.value)
  }, [])

  const handleChangeDate = useCallback((e) => {
    fiduciaryStructurePollsStore.showTransaction.setDate(e)
  }, [])

  const handleChangeDescriptionTransaction = useCallback((e) => {
    fiduciaryStructurePollsStore.showTransaction.setDescription(e.target.value)
  }, [])

  const handleChangeApproveAutomatically = useCallback(() => {
    fiduciaryStructurePollsStore.showTransaction.setApproveAutomatically()
  }, [])

  const handleToggleCreateAnother = useCallback(() => {
    fiduciaryStructurePollsStore.toggleCheckedSaveAndCreate()
  }, [])

  const handleResetTransationslist = useCallback(() => {
    fiduciaryStructurePollsStore.resetTransationslist()
  }, [])

  // =================================
  // END Transactions
  // =================================

  // =================================
  // Vote
  // =================================

  const handleSaveVote = useCallback(() => {
    fiduciaryStructureVotesStore.saveVote()
  }, [])

  const handleSetActiveTab = useCallback(() => {
    fiduciaryStructureVotesStore.setActiveTab(fiduciaryStructureVotesStore.activeTab)
  }, [])

  const handleResolveVoting = useCallback((vote) => {
    fiduciaryStructureVotesStore.resetMessageErrorServer()
    fiduciaryStructureVotesStore.showResolveVotingModal()
    fiduciaryStructureVotesStore.setShowVote(vote)
  }, [])

  const handleViewVote = useCallback((vote) => {
    fiduciaryStructureVotesStore.setShowVote(vote)
    fiduciaryStructureVotesStore.getBlockChainInformation()
    fiduciaryStructureVotesStore.showCreationVoteModal()
  }, [])

  const handleVoteCloseModal = useCallback(() => {
    fiduciaryStructureVotesStore.setTab(0)
    fiduciaryStructureVotesStore.resetVote()
    fiduciaryStructureVotesStore.hideErrorModal()
    fiduciaryStructureVotesStore.resetInputSelect()
    fiduciaryStructureVotesStore.clearErrorsVotes()
    fiduciaryStructureVotesStore.resetResultSelected()
    fiduciaryStructureVotesStore.hideCreationVoteModal()
    fiduciaryStructureVotesStore.hideResolveVotingModal()
    fiduciaryStructureVotesStore.resetMessageErrorServer()
    fiduciaryStructureVotesStore.setComplexResolution(false)
    fiduciaryStructureVotesStore.setComplexResolution(false)
    fiduciaryStructureVotesStore.closeComplexResolutionModal()
  }, [])

  const handleShowCreationVoteModal = useCallback(() => {
    fiduciaryStructureVotesStore.resetVote()
    fiduciaryStructureVotesStore.votersSelected.setValue([])
    fiduciaryStructureVotesStore.showCreationVoteModal()
  }, [])

  // Method for complex resolution

  const handleToggleLoadingDocument = useCallback(() => {
    fiduciaryStructureVotesStore.toggleLoadingDocument()
  }, [])

  const handleChangeVotingResult = useCallback((option) => {
    fiduciaryStructureVotesStore.setVotingResult(option)
  }, [])

  const handleCloseVoting = useCallback(() => {
    fiduciaryStructureVotesStore.validateVotersInProgress()

    if (fiduciaryStructureVotesStore.votersInProgress) {
      fiduciaryStructureVotesStore.closeVotingResolution()
      handleVoteCloseModal()
    }
  }, [])

  const handleSetComplexResolution = useCallback(() => {
    if (fiduciaryStructureVotesStore.isComplexResolution)
      fiduciaryStructureVotesStore.setComplexResolution(false)
    else fiduciaryStructureVotesStore.setComplexResolution(true)
  }, [])

  const handleSuccessComplexResolution = useCallback(() => {
    if (!complexResolutionListStore.hasEmptyTransactions()) {
      fiduciaryStructurePollsStore.setFinishedCloseVote(false)
      complexResolutionListStore.setMissingSelection(false)

      if (complexResolutionListStore.hasEmptyTransactions()) {
        complexResolutionListStore.setMissingSelection(true)
      }

      fiduciaryStructurePollsStore.resolutionTransaction(
        fiduciaryStructureVotesStore.vote,
        complexResolutionListStore.transactions
      )

      handleVoteCloseModal()
      fiduciaryStructureVotesStore.resetVote()

      return null
    }

    return fiduciaryStructureVotesStore.setMessageErrorServer(
      t('missingComplexResolutionSelection')
    )
  }, [])

  useEffect(() => {
    if (fiduciaryStructurePollsStore.finishedCloseVote) {
      fiduciaryStructureVotesStore.init()
      fiduciaryStructurePollsStore.setFinishedCloseVote(false)
    }
  }, [fiduciaryStructurePollsStore.finishedCloseVote])

  const handleSuccessWarningVotingInProsess = useCallback(() => {
    fiduciaryStructureVotesStore.closeVotingResolution()
    fiduciaryStructureVotesStore.hideWarningVotingInProcess()
    fiduciaryStructureVotesStore.hideResolveVotingModal()
    handleVoteCloseModal()
  }, [])

  const handleVoteCloseModalWarningVotingModalInProsess = useCallback(() => {
    fiduciaryStructureVotesStore.hideWarningVotingInProcess()
  }, [])

  const handleShowComplexResolution = useCallback((vote) => {
    fiduciaryStructureVotesStore.setShowVote(vote)
    fiduciaryStructureVotesStore.showComplexResolutionModal()
  }, [])

  const handleActionComplexResolution = useCallback(() => {
    if (fiduciaryStructureVotesStore.isComplexResolution) {
      complexResolutionListStore.processVote(fiduciaryStructureVotesStore.vote)
      fiduciaryStructureVotesStore.showComplexResolutionModal()
    } else if (fiduciaryStructureVotesStore.validateClose()) handleCloseVoting()
  }, [])

  const handleResetVotesList = useCallback(() => {
    fiduciaryStructureVotesStore.resetVotesList()
  }, [])

  // =================================
  // END Vote
  // =================================

  useEffect(() => {
    if (fiduciaryStructurePollsStore.transactionsNewVote.length > 0) {
      fiduciaryStructureVotesStore.setDefaultSelectedTransactions(
        fiduciaryStructurePollsStore.transactionsNewVote
      )
      setTimeout(() => {
        fiduciaryStructureVotesStore.showCreationVoteModal()
      }, 1000)
    }
  }, [fiduciaryStructurePollsStore.transactionsNewVote])

  // this use effect is for open view transactions selected from dashboard
  useEffect(() => {
    fiduciaryStructurePollsStore.setTransactionIdFromDashboard(params.get('transactionId'))

    if (
      fiduciaryStructurePollsStore.transactionIdFromDashboard &&
      fiduciaryStructurePollsStore.canOpenTransactionFromDashboard &&
      fiduciaryStructurePollsStore.transactionFromDashboard.length > 0
    ) {
      fiduciaryStructurePollsStore.findTransaction(
        fiduciaryStructurePollsStore.transactionIdFromDashboard
      )
    }

    if (fiduciaryStructurePollsStore.viewDashboardTransaction) {
      setTimeout(() => {
        handleOpenViewTransaction(fiduciaryStructurePollsStore.transactionFromDashboard[0])
      }, 500)
    }

    fiduciaryStructurePollsStore.setViewDashboardTransaction(false)
  }, [
    fiduciaryStructurePollsStore.viewDashboardTransaction,
    fiduciaryStructurePollsStore.transactionIdFromDashboard,
  ])

  useEffect(() => {
    if (hashTab === 'voting') {
      handleSetActiveTab(0)
    }
  }, [hashTab])

  const {
    fiduciaryStructureName,
    fiduciaryStructureRoute,
    fiduciaryStructureError,
    transactionsList,
  } = fiduciaryStructurePollsStore
  const { warningVoteInProgress } = fiduciaryStructureVotesStore

  return (
    <>
      {fiduciaryStructureError ? (
        <TrustSectionError />
      ) : (
        <>
          <FiduaciaryStructureHeaderLink
            text={fiduciaryStructureName}
            route={fiduciaryStructureRoute}
          />
          {!viewListVotes ? (
            <PageTitle
              title={t('polls')}
              rightSection={
                <>
                  <Button
                    big
                    icon={<PlusIcon />}
                    className="visibleDesktop"
                    label={t('addPoll')}
                    onClick={handleAddTransaction}
                    permission={TRANSACTIONS_CREATE}
                  />
                  <Button
                    big
                    circle
                    icon={<PlusIcon />}
                    className="visibleMobile"
                    onClick={handleAddTransaction}
                    permission={TRANSACTIONS_CREATE}
                  />
                </>
              }
            />
          ) : (
            <PageTitle
              title={t('polls')}
              rightSection={
                <>
                  <Button
                    big
                    icon={<PlusIcon />}
                    label={t('addVoting')}
                    permission={VOTE_CREATE}
                    className="visibleDesktop"
                    onClick={handleShowCreationVoteModal}
                  />
                  <Button
                    big
                    circle
                    icon={<PlusIcon />}
                    permission={VOTE_CREATE}
                    className="visibleMobile"
                    onClick={handleShowCreationVoteModal}
                  />
                </>
              }
            />
          )}
          <TabsWizard
            hideButtons
            onChangeTab={handleOnChangeTab}
            defaultActiveTab={hashTab === 'voting' ? 1 : 0}
          >
            <Tab name={t('polls')}>
              <PollsListTab
                handleChangeDate={handleChangeDate}
                handleChangeName={handleChangeName}
                transactionsList={transactionsList}
                handleCloseModal={handleCloseModal}
                handleSuccessStore={handleSuccessStore}
                handleCancelStore={handleCancelStore}
                handleSuccessReject={handleSuccessReject}
                handleSuccessApprove={handleSuccessApprove}
                handleResetVotesList={handleResetVotesList}
                handleSaveTransaction={handleSaveTransaction}
                fiduciaryStructurePollsStore={fiduciaryStructurePollsStore}
                handleDeleteTransaction={handleDeleteTransaction}
                handleRejectTransaction={handleRejectTransaction}
                handleStoreTransactions={handleStoreTransactions}
                handleApproveTransaction={handleApproveTransaction}
                handleToggleCreateAnother={handleToggleCreateAnother}
                handleOpenViewTransaction={handleOpenViewTransaction}
                handleModalDeleteTransaction={handleModalDeleteTransaction}
                handleChangeDescription={handleChangeDescriptionTransaction}
                handleChangeApproveAutomatically={handleChangeApproveAutomatically}
              />
            </Tab>
            <Tab name={t('voting')}>
              <Gated permission={VOTE_VIEW}>
                <VotesListTab
                  handleSaveVote={handleSaveVote}
                  handleViewVote={handleViewVote}
                  fiduciaryStructureVotesStore={fiduciaryStructureVotesStore}
                  handleCloseVoting={handleCloseVoting}
                  handleSetActiveTab={handleSetActiveTab}
                  handleResolveVoting={handleResolveVoting}
                  handleVoteCloseModal={handleVoteCloseModal}
                  warningVoteInProgress={warningVoteInProgress}
                  fiduciaryStructurePollsStore={fiduciaryStructurePollsStore}
                  handleChangeVotingResult={handleChangeVotingResult}
                  handleResetTransationslist={handleResetTransationslist}
                  handleSetComplexResolution={handleSetComplexResolution}
                  handleToggleLoadingDocument={handleToggleLoadingDocument}
                  handleShowComplexResolution={handleShowComplexResolution}
                  handleActionComplexResolution={handleActionComplexResolution}
                  handleSuccessComplexResolution={handleSuccessComplexResolution}
                  transactionsComplexResolution={complexResolutionListStore.transactions}
                  handleSuccessWarningVotingInProsess={handleSuccessWarningVotingInProsess}
                  handleVoteCloseModalWarningVotingModalInProsess={
                    handleVoteCloseModalWarningVotingModalInProsess
                  }
                />
              </Gated>
            </Tab>
          </TabsWizard>
        </>
      )}
    </>
  )
}

FiduciaryStructuresPolls.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default observer(FiduciaryStructuresPolls)
