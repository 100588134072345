import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import File from 'models/File'
import LiteralValue from 'presentation/LiteralValue'
import DownloadFileLink from './DownloadFileLink'

const DownloadFile = observer(({ label, fileStore }) => (
  <LiteralValue
    label={label}
    value={
      <>
        <span>{fileStore.name !== '' && fileStore.name !== null ? fileStore.name : '-'}</span>
        {fileStore.id !== undefined && fileStore.id !== null && fileStore.id !== '' && (
          <DownloadFileLink fileStore={fileStore} />
        )}
      </>
    }
  />
))

DownloadFile.propTypes = {
  label: PropTypes.string,
  fileStore: PropTypes.instanceOf(File).isRequired,
}

DownloadFile.defaultProps = {
  label: '',
}

export default DownloadFile
