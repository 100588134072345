import { action, observable, makeObservable } from 'mobx'
import moment from 'moment'
import File from 'models/File'
import AsyncStore from 'stores/AsyncStore'

const STATUS_ACTIVE = 'stored'
const STATUS_DRAFT = 'draft'
const STATUS_DEPRECATED = 'deprecated'
const STATUS_DELETED = 'deleted'
const STATUS_REVOKED = 'revoked'

class TrustDocumentVersion extends AsyncStore {
  content = null
  isLoadingContent = false
  parentId = null
  file = null

  constructor(id, status, title, updated, type, text) {
    super()

    this.id = id
    this.status = status
    this.title = title
    this.lastUpdate = updated.at !== null ? moment(updated.at) : null
    this.updatedByFirstName = updated.firstName
    this.updatedByLastName = updated.lastName
    this.type = type
    this.content = text
    this.file = new File()
    this.signedFile = new File()

    makeObservable(this, {
      // observables
      content: observable,
      isLoadingContent: observable,
      parentId: observable,
      file: observable,
      // actions
      setType: action,
      setParentId: action,
    })
  }

  setType(type) {
    this.type = type
  }

  setParentId(value) {
    this.parentId = value
  }

  get isActive() {
    return this.status === STATUS_ACTIVE
  }

  get isDraft() {
    return this.status === STATUS_DRAFT
  }

  get isDeprecated() {
    return this.status === STATUS_DEPRECATED
  }

  get isDeleted() {
    return this.status === STATUS_DELETED
  }

  get isRevoked() {
    return this.status === STATUS_REVOKED
  }
}

export default TrustDocumentVersion
