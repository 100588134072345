import PropTypes from 'prop-types'
import { useCallback } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Change from 'models/ContactActivity/Change'
import LiteralValue from 'presentation/LiteralValue'
import styles from './contactActivityModalForm.module.scss'

const ContactActivityModalForm = ({ changes }) => {
  const { t } = useTranslation('activity')

  const showValue = useCallback((value, field) => {
    let string = ''
    switch (field) {
      case 'birthDate':
      case 'deceasedDate':
      case 'passportValidUntil':
        string = value ? moment(value).format(t('common:humanDateDayFormat')) : value
        break
      case 'isClient':
        string = value ? 'Yes' : 'No'
        break
      case 'pepAccreditationDocument':
        string = value?.description || value?.name || ''
        break
      case 'sourceOfFunds':
        string = value?.description || value?.name || ''
        break
      case 'country':
        string = value?.value || ''
        break
      case 'documents':
        if (value.length === 0) {
          string = ''
        }
        value.forEach((document) => {
          string = string.concat(', ', document.description || document.name)
        })
        break
      default:
        string = value
    }
    return string
  }, [])
  return (
    <div>
      <div className={styles.fieldList}>
        <div>
          {changes.map((change) => (
            <div className={styles.changeContainer} key={`${change.field}`}>
              <div className={styles.field}>{t(change.field)}</div>

              <div className={styles.currentValue}>
                <LiteralValue
                  label={t('current')}
                  value={showValue(change.currentValue, change.field)}
                  borderBottom
                />
              </div>
              <div className={styles.newValue}>
                <LiteralValue
                  label={t('new')}
                  value={showValue(change.newValue, change.field)}
                  borderBottom
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ContactActivityModalForm.propTypes = {
  changes: PropTypes.arrayOf(PropTypes.instanceOf(Change)),
}

ContactActivityModalForm.defaultProps = {
  changes: [],
}

export default ContactActivityModalForm
