import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import CropRotateIcon from 'mdi-react/CropRotateIcon'
import Cropper from 'react-cropper'
/* eslint-disable import/no-extraneous-dependencies */
import 'cropperjs/dist/cropper.css'
import Button from 'presentation/Button'
import styles from './imageCropper.module.scss'

const CROP_WIDTH = 250
const CROP_HEIGHT = 250

const ImageCropper = observer(
  ({ imageBase64, handleCancelCrop, handleCropImage, handleSaveCrop }) => {
    const { t } = useTranslation('users')
    const cropperRef = useRef(null)

    const cropImage = useCallback(() => {
      handleCropImage(cropperRef.current.cropper.getCroppedCanvas().toDataURL())
    })

    const handleRotate = useCallback(() => {
      cropperRef.current.cropper.rotate(90)
    })

    return (
      <div>
        <div className={styles.cropperContainerInner}>
          <Cropper
            ref={cropperRef}
            src={imageBase64}
            style={{ height: CROP_HEIGHT, maxWidth: '100%', width: CROP_WIDTH }}
            aspectRatio={1}
            viewMode={1}
            preview="#preview"
            background
            crop={cropImage}
          />
          <Button
            onClick={handleRotate}
            icon={<CropRotateIcon />}
            circle
            className={styles.rotateIcon}
          />
          <div className={styles.resultContainer}>
            <div className={styles.sectionTitle}>{t('preview')}</div>
            <div id="preview" className={styles.preview} />
          </div>
        </div>

        <div className={styles.actionsButtonContainer}>
          <Button onClick={handleSaveCrop} primary label={t('common:ok')} />
          <Button
            style={{ marginLeft: 10 }}
            onClick={handleCancelCrop}
            secondary
            label={t('common:cancel')}
          />
        </div>
      </div>
    )
  }
)

ImageCropper.propTypes = {
  imageBase64: PropTypes.string,
  handleCancelCrop: PropTypes.func.isRequired,
  handleCropImage: PropTypes.func.isRequired,
  handleSaveCrop: PropTypes.func.isRequired,
}

ImageCropper.defaultProps = {
  imageBase64: null,
}

export default ImageCropper
