import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import c from 'classnames'
import ViewGridIcon from 'mdi-react/ViewGridIcon'
import screenSizesMatches from 'util/screen'
import ViewSequentialIcon from 'mdi-react/ViewSequentialIcon'
import ReorderHorizontalIcon from 'mdi-react/ReorderHorizontalIcon'
import styles from './sectionListViewSelection.module.scss'

const SectionListViewSelection = ({
  viewTable,
  changeView,
  setShowDropdownOrder,
  setShowDropdownFilter,
}) => {
  const { t } = useTranslation('mainSectionList')

  useEffect(() => {
    if (screenSizesMatches('lg')) {
      changeView('list')
    }
  }, [])

  return (
    <div className={styles.viewModeButtons}>
      <label>{t('displayOptions')}</label>
      <div
        className={c(styles.icon, viewTable === 'grid' && styles.selected)}
        onClick={() => {
          changeView('grid')
          setShowDropdownOrder(false)

          if (setShowDropdownFilter) {
            setShowDropdownFilter(false)
          }
        }}
        role="button"
        tabIndex="0"
      >
        <ViewGridIcon />
      </div>
      <div
        className={c(styles.icon, viewTable === 'list' && styles.selected)}
        onClick={() => {
          changeView('list')
          setShowDropdownOrder(false)

          if (setShowDropdownFilter) {
            setShowDropdownFilter(false)
          }
        }}
        role="button"
        tabIndex="0"
      >
        <ViewSequentialIcon />
      </div>
      <div
        className={c(styles.icon, styles.hideButton, viewTable === 'detail' && styles.selected)}
        onClick={() => {
          changeView('detail')
          setShowDropdownOrder(false)

          if (setShowDropdownFilter) {
            setShowDropdownFilter(false)
          }
        }}
        role="button"
        tabIndex="0"
      >
        <ReorderHorizontalIcon />
      </div>
    </div>
  )
}

SectionListViewSelection.propTypes = {
  viewTable: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  setShowDropdownOrder: PropTypes.func,
  setShowDropdownFilter: PropTypes.func,
}

SectionListViewSelection.defaultProps = {
  setShowDropdownFilter: () => {},
  setShowDropdownOrder: () => {},
}

export default SectionListViewSelection
