import React, { useContext, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import FormSelect from 'presentation/FormSelect'
import CreationInput from 'presentation/CreationInput'
import ContactSelectItem from 'presentation/ContactSearch/ContactSelectItem'
import StoresContext from 'providers/storeContext'
import ContactSearchStore from 'stores/ContactSearchStore'
import styles from './dynamicFormPartContactSearch.module.scss'

const DynamicFormPartContactSearch = ({ part }) => {
  const [contactSelected, setContactSelected] = useState(null)
  const { clientsCreationStore } = useContext(StoresContext)
  const { clientType, viewMode, isEdition } = clientsCreationStore
  const [contactSearchStore] = useState(() => new ContactSearchStore())

  const { t } = useTranslation('contacts')

  const handleChangeContactSelected = useCallback((selectedOption) => {
    clientsCreationStore.contactfillData(selectedOption.id, part)
    setContactSelected(selectedOption)
  }, [])
  const handleInputChange = (value) => {
    contactSearchStore.setSearchTerm(value)
  }

  useEffect(() => {
    contactSearchStore.loadContacts()
  }, [])

  return (
    <>
      {!viewMode && !isEdition && (
        <div className={styles.containerContactSelect}>
          <CreationInput
            as={FormSelect}
            value={contactSelected}
            label={t('labelSearchContact', { clientType })}
            placeholder={t('placeholderSearch')}
            onChange={(option) => handleChangeContactSelected(option)}
            onInputChange={handleInputChange}
            components={{ Option: ContactSelectItem }}
            options={contactSearchStore.selectOptions}
            isLoading={contactSearchStore.isLoading}
          />
        </div>
      )}
    </>
  )
}

DynamicFormPartContactSearch.propTypes = {
  part: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default observer(DynamicFormPartContactSearch)
