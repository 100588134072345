import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import UserStatus from 'models/UserStatus'
import styles from './userStatusName.module.scss'

const UserStatusName = ({ status, onlyBullet }) => {
  const { t } = useTranslation('clientsCreation')
  return (
    <div className={c(styles.statusContainer, onlyBullet && styles.onlyBullet)}>
      <span
        className={c(
          styles.bullet,
          status.isInactive && styles.statusInactive,
          status.isActive && styles.statusActive,
          status.isPendingActivation && styles.pendingActivation
        )}
        title={t(`status_${status.status}`)}
      />
      <div className={c(styles.status)}>{t(`status_${status.status}`)}</div>
    </div>
  )
}

UserStatusName.propTypes = {
  status: PropTypes.instanceOf(UserStatus),
  onlyBullet: PropTypes.bool,
}

UserStatusName.defaultProps = {
  status: new UserStatus(),
  onlyBullet: false,
}

export default observer(UserStatusName)
