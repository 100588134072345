import File from 'models/File'
import Letter from './Letter'
import { LetterVersion } from '../TrustDocumentVersion'

class Amendment extends Letter {
  constructor() {
    super('amendment')
  }

  static createFromJson({
    id = null,
    title = null,
    text = null,
    file = null,
    signedFile = null,
    status = null,
    updated = null,
  }) {
    const amendment = new Amendment()

    amendment.changeUpdated(updated)
    amendment.changeId(id)
    amendment.changeStatus(status)
    amendment.changeTitle(title)
    amendment.changeText(text)
    amendment.file = new File()
    amendment.signedFile = new File()

    if (file !== null) {
      amendment.file = File.fromJson(file)
    }

    if (signedFile !== null) {
      amendment.signedFile = File.fromJson(signedFile)
    }

    return amendment
  }

  fillFromOldVersion(deedVersion) {
    this.changeTitle(deedVersion.title)
    this.changeText(deedVersion.content)

    if (deedVersion.file !== null) {
      this.file = deedVersion.file
    }

    if (deedVersion.signedFile !== null) {
      this.signedFile = deedVersion.signedFile
    }
  }

  get letterVersion() {
    return LetterVersion.fromLetter(this)
  }
}

export default Amendment
