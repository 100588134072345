import { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CreationInput from 'presentation/CreationInput'
import DatePicker from 'presentation/DatePicker'
import FormSelect from 'presentation/FormSelect'
import Rule from 'models/Rule'
import styles from './ruleRecurrenceForm.module.scss'

const RuleRecurrenceForm = ({ rule }) => {
  const { t } = useTranslation('trustsCreation')

  const handleChangeFromDate = useCallback((value) => {
    rule.setFromDate(value)
  }, [])

  const handleChangeToDate = useCallback((value) => {
    rule.setToDate(value)
  }, [])

  const handleChangeRecurrenceUnits = useCallback((option) => {
    rule.setRecurrenceUnits(option)
  }, [])

  const handleChangeRecurrenceAmount = useCallback((e) => {
    rule.setRecurrenceAmount(e.target.value)
  }, [])

  const recurrenceUnits = useMemo(
    () => [
      { label: 'year', value: 'Years' },
      { label: 'month', value: 'Months' },
      { label: 'day', value: 'Days' },
    ],
    []
  )

  return (
    <div>
      <div className={styles.dateContainer}>
        <CreationInput
          as={DatePicker}
          inputStore={rule.from}
          label={t('fromDate')}
          placeholder={t('fromDate')}
          onChange={handleChangeFromDate}
          minDate={new Date()}
          showYearDropdown
          dropdownMode="select"
        />
        <CreationInput
          as={DatePicker}
          inputStore={rule.to}
          label={t('toDate')}
          placeholder={t('toDate')}
          onChange={handleChangeToDate}
          minDate={new Date()}
          showYearDropdown
          dropdownMode="select"
        />
      </div>
      <div className={styles.recurrenceContainer}>
        <CreationInput
          label={t('amount')}
          placeholder={t('typeRecurrenceAmount')}
          inputStore={rule.recurrencePeriodAmount}
          onChange={handleChangeRecurrenceAmount}
        />
        <CreationInput
          as={FormSelect}
          inputStore={rule.recurrencePeriodUnits}
          options={recurrenceUnits}
          label={t('units')}
          placeholder={t('units')}
          onChange={handleChangeRecurrenceUnits}
        />
      </div>
    </div>
  )
}

RuleRecurrenceForm.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
}

export default observer(RuleRecurrenceForm)
