import ListTrustSettlor from 'models/ListTrustSettlor'
import ListDate from 'models/ListDate'
import TrustStatus from 'models/TrustStatus'

class ListTrust {
  constructor(id, name, settlors, updated, created, dataUpdated, status, alternativeStatus) {
    this.id = id
    this.name = name
    this.settlors = settlors
    this.updated = updated
    this.created = created
    this.dataUpdated = dataUpdated
    this.status = new TrustStatus(status)
    this.alternativeStatus = new TrustStatus(alternativeStatus)
  }

  get isMultipleSettlors() {
    return this.settlors.length > 1
  }

  get isCompanySettlor() {
    return this.settlors.length === 1 && this.settlors[0].isCompany
  }

  get isIndividualSettlor() {
    return this.settlors.length === 1 && this.settlors[0].isIndividual
  }

  get hasSettlor() {
    return this.settlors.length > 0
  }

  get isDraft() {
    return this.status.isDraft
  }

  get isExecuted() {
    return this.status.isExecuted
  }

  get isExecution() {
    return this.status.isExecution
  }

  get isStored() {
    return this.status.isStored
  }

  get isTerminated() {
    return this.status.isTerminated
  }

  static fromJson({ id, name, settlors, updated, created, dataUpdated, status }) {
    return new ListTrust(
      id,
      name,
      settlors.map((settlor) => ListTrustSettlor.fromJson(settlor)),
      ListDate.fromJson(updated),
      ListDate.fromJson(created),
      ListDate.fromJson(dataUpdated),
      status.currentData.status,
      status.alternativeData ? status.alternativeData.status : null
    )
  }
}

export default ListTrust
