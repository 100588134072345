import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Asset from 'models/Asset'

const AssetValue = ({ asset }) => {
  const { t } = useTranslation('trustsCreation')

  if (asset !== null) {
    return (
      <span>
        {asset.moneyValue}
        {asset.invaluable && ` (${t('invaluable')})`}
      </span>
    )
  }
  return '-'
}

AssetValue.propTypes = {
  asset: PropTypes.instanceOf(Asset),
}

AssetValue.defaultProps = {
  asset: null,
}

export default AssetValue
