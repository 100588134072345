import axios from 'axios'
import { API_URL } from 'services/config'
import { TrustUser } from 'models/PermissionUser'

/* eslint-disable class-methods-use-this */
class TrustUsersService {
  searchPermissionUsers = (idTrust, term) =>
    axios.get(`${API_URL}/tenant/trust/users/${idTrust}?search=${term}`).then((response) => {
      return {
        users: response.data.data.map((listUser) => TrustUser.fromJson(listUser)),
      }
    })

  listAssignedUsers = (idTrust) =>
    axios.get(`${API_URL}/tenant/trust/${idTrust}/users`).then((response) =>
      response.data.data.map((listUser) => {
        return TrustUser.fromJson(listUser)
      })
    )

  savePermissionUsers = (idTrust, json) =>
    axios
      .post(`${API_URL}/tenant/trust/${idTrust}/users`, { data: json })
      .then((response) => response.data.data)
}

export default TrustUsersService
