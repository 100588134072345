import { makeObservable, runInAction, observable, action } from 'mobx'
import FiduciaryStructurePreloadDataStore from 'stores/FiduciaryStructurePreloadDataStore'
import TransactionsAssetsService from 'services/TransactionsAssetsService'
import { Entity } from 'models/FormMetadata'

const MULTIPLE_VALUATION_CHANGE_ERROR_MESSAGE = 'There is already a valuation change for this asset'

class FiduciaryStructureTransactionsValuationStore extends FiduciaryStructurePreloadDataStore {
  constructor(fiduciaryStructureId, structure, creationParametersStore) {
    super(fiduciaryStructureId, structure)
    this.assets = []
    this.selectedAsset = null
    this.instanceEntity = null
    this.part = null
    this.assetDetails = null
    this.multipleValuationChangeErrorModal = false

    this.transactionAssetsService = new TransactionsAssetsService()
    this.creationParametersStore = creationParametersStore

    makeObservable(this, {
      // observables
      assets: observable,
      selectedAsset: observable,
      instanceEntity: observable,
      part: observable,
      assetDetails: observable,
      multipleValuationChangeErrorModal: observable,

      // actions
      selectAsset: action,
      getAssetMetadata: action,
      showMultipleValuationChangeErrorModal: action,
      closeMultipleValuationChangeErrorModal: action,
    })
  }

  async loadAssets() {
    try {
      this.preRequest()
      const { assets } = await this.transactionAssetsService.loadAssetList(
        this.fiduciaryStructureId
      )

      runInAction(() => {
        this.assets = assets
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  selectAsset(id) {
    this.selectedAsset = id
  }

  async getAssetMetadata() {
    this.instanceEntity = null
    try {
      this.preRequest(this.getAssetMetadata)
      const [metadata] = await this.transactionAssetsService.loadMetadata(
        this.fiduciaryStructureId,
        this.assetDetails.assetType
      )
      runInAction(() => {
        this.processMetadata(metadata, this.assetDetails)
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async loadAssetDetails() {
    try {
      this.preRequest(this.loadAssetDetails)
      const { assetDetails } = await this.transactionAssetsService.loadAssetDetails(
        this.selectedAsset
      )
      runInAction(() => {
        this.assetDetails = assetDetails
        this.getAssetMetadata()
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  processMetadata(metadata, assetData) {
    const entity = Entity.fromJson(metadata)
    this.instanceEntity = entity.createInputInstance()
    this.instanceEntity.fillFromJson(assetData)

    // Backend sends only the id of the asset so look in the options list for the options and change it
    const assetTypeInputStore = this.instanceEntity.inputs.get('assetType').store
    const assetCategory = `${this.instanceEntity.type}Type`

    const [assetTypeOption] = this.creationParametersStore.parameters
      .get(assetCategory)
      .filter((option) => option.id === assetTypeInputStore.value)

    assetTypeInputStore.setValue(assetTypeOption)
  }

  async save(successCallback) {
    try {
      this.preRequest(this.save)
      const asset = this.getDataJson()
      const data = {
        amount: asset.value.value,
        currency: asset.value.currency.value,
        lastValuationDate: asset.lastValuationDate,
      }

      await this.transactionAssetsService.updateAssetValuation(this.selectedAsset, data)
      successCallback()
      this.onSuccessRequest()
    } catch (e) {
      this.onErrorRequest(e)

      if (this.isMultipleValuationChangeError()) this.showMultipleValuationChangeErrorModal()
    }
  }

  isMultipleValuationChangeError() {
    return this.errors.find((error) => error === MULTIPLE_VALUATION_CHANGE_ERROR_MESSAGE)
  }

  showMultipleValuationChangeErrorModal() {
    this.multipleValuationChangeErrorModal = true
  }

  closeMultipleValuationChangeErrorModal() {
    this.multipleValuationChangeErrorModal = false
  }

  getDataJson() {
    const json = {}

    this.instanceEntity.inputs.forEach((input, key) => {
      json[key] = input.json
    })

    return json
  }
}

export default FiduciaryStructureTransactionsValuationStore
