import React, { useCallback, useContext, useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import LetterOfWishesSectionStore from 'stores/LetterOfWishesSectionStore'
import DeedsSectionStore from 'stores/DeedsSectionStore'
import AmendmentSectionStore from 'stores/AmendmentSectionStore'
import LetterOfDistributionSectionStore from 'stores/LetterOfDistributionSectionStore'
import DeedsArchiveStore from 'stores/DeedsArchiveStore'
import LetterOfWishesArchiveStore from 'stores/LetterOfWishesArchiveStore'
import OtherDocumentsArchiveStore from 'stores/OtherDocumentsArchiveStore'
import OtherDocumentsSectionStore from 'stores/OtherDocumentsSectionStore'
import saveTrust from 'util/saveTrust'
import PageTitle from 'presentation/PageTitle'
import Tabs, { Tab } from 'presentation/Tabs'
import LetterMainSection from 'presentation/LettersSection/LetterMainSection/LetterMainSection'
import LetterSecondarySection from 'presentation/LettersSection/LetterSecondarySection/LetterSecondarySection'

const TrustDocumentsSection = ({ title }) => {
  const { t } = useTranslation('trustsCreation')
  const { trustsCreationStore } = useContext(StoresContext)
  const { deedsStore, lettersStore, otherDocsStore } = trustsCreationStore
  const [activeTab, setActiveTab] = useState(0)

  // Main documents stores
  const [letterOfWishesSectionStore] = useState(() => new LetterOfWishesSectionStore(lettersStore))
  const [deedsSectionStore] = useState(() => new DeedsSectionStore(deedsStore))
  const [otherDocumentsSectionStore] = useState(
    () => new OtherDocumentsSectionStore(otherDocsStore)
  )

  // Secondary documents Stores
  const [amendmentSectionStore] = useState(() => new AmendmentSectionStore(deedsStore))
  const [letterOfDistributionSectionStore] = useState(
    () => new LetterOfDistributionSectionStore(lettersStore)
  )

  // Archive Stores
  const [deedsArchiveStore] = useState(() => new DeedsArchiveStore(trustsCreationStore.idEdition))
  const [letterOfWishesArchiveStore] = useState(
    () => new LetterOfWishesArchiveStore(trustsCreationStore.idEdition)
  )
  const [otherDocumentsArchiveStore] = useState(
    () => new OtherDocumentsArchiveStore(trustsCreationStore.idEdition)
  )

  const resetDeedOfTrust = useCallback(() => {
    deedsSectionStore.reset()
  }, [])

  const resetAmendments = useCallback(() => {
    amendmentSectionStore.reset()
  }, [])

  const resetLetterOfWishes = useCallback(() => {
    letterOfWishesSectionStore.reset()
  }, [])

  const resetLetterOfDistribution = useCallback(() => {
    letterOfDistributionSectionStore.reset()
  }, [])

  const resetOthers = useCallback(() => {
    otherDocumentsSectionStore.reset()
  }, [])

  const handleChangeTab = useCallback(
    (tab) => {
      setActiveTab(tab)

      if (activeTab === 0) {
        resetDeedOfTrust()
      }

      if (activeTab === 1) {
        resetAmendments()
      }

      if (activeTab === 2) {
        resetLetterOfWishes()
      }

      if (activeTab === 3) {
        resetLetterOfDistribution()
      }

      if (activeTab === 4) {
        resetOthers()
      }
    },
    [activeTab]
  )

  const handleSaveEntity = useCallback((store) => saveTrust(store, t('trustCreatedMsg')), [])

  const handleDeedValidation = useCallback(() => trustsCreationStore.validateDeed(), [])

  const handleLetterOfWishesValidation = useCallback(() => trustsCreationStore.validateLows(), [])

  const handleOtherDocumentsValidation = useCallback(
    () => trustsCreationStore.validateOtherDocuments(),
    []
  )

  if (trustsCreationStore.idEdition === undefined && trustsCreationStore.isLoading) {
    return null
  }

  return (
    <>
      <PageTitle title={title} />
      <Tabs defaultActiveTab={activeTab} onChangeTab={handleChangeTab}>
        <Tab name={t('deedOfTrust')}>
          <LetterMainSection
            mainStore={deedsStore}
            mainSectionStore={deedsSectionStore}
            letterArchiveStore={deedsArchiveStore}
            mainEntityStatus={trustsCreationStore.entityStatus}
            mainEntityHasAlternativeStatus={trustsCreationStore.hasAlternativeStatus}
            mainFirstLevelError={deedsStore.mainFirstLevelError}
            mainSectionCanBeRevoked={false}
            isViewMode={trustsCreationStore.isViewMode}
            letterTypeName={t('deedOfTrust')}
            handleSaveEntity={() => handleSaveEntity(trustsCreationStore)}
            validationHandler={handleDeedValidation}
          />
        </Tab>
        <Tab name={t('amendments')}>
          <LetterSecondarySection
            secondarySectionStore={amendmentSectionStore}
            mainStore={deedsStore}
            isViewMode={trustsCreationStore.isViewMode}
            secondaryFirstLevelError={deedsStore.secondaryFirstLevelError}
            secondLetterShowRulesAlert={false}
            letterTypeName={t('amendments')}
            letterTypeNameSingular={t('amendment')}
            handleSaveEntity={() => handleSaveEntity(trustsCreationStore)}
            validationHandler={handleDeedValidation}
          />
        </Tab>
        <Tab name={t('letterOfWishes')}>
          <LetterMainSection
            mainStore={lettersStore}
            mainSectionStore={letterOfWishesSectionStore}
            letterArchiveStore={letterOfWishesArchiveStore}
            mainEntityStatus={trustsCreationStore.entityStatus}
            mainEntityHasAlternativeStatus={trustsCreationStore.hasAlternativeStatus}
            mainFirstLevelError={lettersStore.mainFirstLevelError}
            isViewMode={trustsCreationStore.isViewMode}
            letterTypeName={t('letterOfWishes')}
            handleSaveEntity={() => handleSaveEntity(lettersStore.trustCreationStore)}
            validationHandler={handleLetterOfWishesValidation}
          />
        </Tab>
        <Tab name={t('lettersOfDistribution')}>
          <LetterSecondarySection
            mainStore={lettersStore}
            secondarySectionStore={letterOfDistributionSectionStore}
            isViewMode={trustsCreationStore.isViewMode}
            secondaryFirstLevelError={lettersStore.secondaryFirstLevelError}
            secondLetterShowRulesAlert={false}
            letterTypeName={t('lettersOfDistribution')}
            letterTypeNameSingular={t('letterOfDistribution')}
            handleSaveEntity={() => handleSaveEntity(lettersStore.trustCreationStore)}
            validationHandler={handleLetterOfWishesValidation}
          />
        </Tab>
        <Tab name={t('otherDocuments')}>
          <LetterMainSection
            mainStore={otherDocsStore}
            mainSectionStore={otherDocumentsSectionStore}
            letterArchiveStore={otherDocumentsArchiveStore}
            mainEntityStatus={trustsCreationStore.entityStatus}
            mainEntityHasAlternativeStatus={trustsCreationStore.hasAlternativeStatus}
            mainFirstLevelError={otherDocsStore.mainFirstLevelError}
            mainSectionCanBeRevoked={false}
            isViewMode={trustsCreationStore.isViewMode}
            letterTypeName={t('otherDocuments')}
            handleSaveEntity={() => handleSaveEntity(trustsCreationStore)}
            validationHandler={handleOtherDocumentsValidation}
          />
        </Tab>
      </Tabs>
    </>
  )
}

TrustDocumentsSection.propTypes = {
  title: PropTypes.string.isRequired,
}

export default observer(TrustDocumentsSection)
