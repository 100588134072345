import React from 'react'
import PropTypes from 'prop-types'
import ValuationAssetItem from 'presentation/ValuationAssetItem'
import styles from './valuationAssets.module.scss'

const ValuationAssets = ({ title, valuations }) => {
  return (
    <div className={styles.valuationAssets}>
      {title && <div className={styles.valuablesTitle}>{title}</div>}
      <div className={styles.assetsContainer}>
        {valuations.map((valuation, i) => (
          <ValuationAssetItem
            // eslint-disable-next-line react/no-array-index-key
            key={`valuations_${i}`}
            title={valuation.currencyValue}
            expandable={valuation.assets.length > 0}
            assets={valuation.assets}
          />
        ))}
      </div>
    </div>
  )
}

ValuationAssets.propTypes = {
  title: PropTypes.string,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  valuations: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

ValuationAssets.defaultProps = {
  title: null,
}

export default ValuationAssets
