import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import CloudDownloadOutlineIcon from 'mdi-react/CloudDownloadOutlineIcon'
import download from 'util/download'
// import { API_URL } from 'services/config'
import File from 'models/File'
import styles from './inputContactsFile.module.scss'

const DownloadFileLink = ({
  fileStore,
  circle,
  className,
  noUploadFile,
  localFileUrl,
  localFileName,
  handleRevokeLocalFileUrl,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => handleRevokeLocalFileUrl()
  }, [])

  return (
    <div
      role="link"
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault()

        if (!noUploadFile) {
          setLoading(true)

          if (!localFileUrl) {
            fileStore.getSecureUrl().then((secureUrl) => {
              // busco una url segura y luego creo un iframe durante una ventana de tiempo para que el browser promptee la descarga
              const tempIframe = document.createElement('iframe')

              tempIframe.style.display = 'none'
              tempIframe.src = [secureUrl]

              document.body.appendChild(tempIframe)
              setLoading(false)

              setTimeout(() => document.body.removeChild(tempIframe), 10000)
            })
          } else {
            download(localFileUrl, localFileName)
            setLoading(false)
          }
        }
      }}
      target="_blank"
      className={c(
        styles.downloadFileBtn,
        circle && styles.circle,
        className,
        noUploadFile && styles.noUploadFile
      )}
    >
      {loading ? (
        <div className={styles.loadingRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        <CloudDownloadOutlineIcon />
      )}
    </div>
  )
}

DownloadFileLink.propTypes = {
  fileStore: PropTypes.instanceOf(File).isRequired,
  circle: PropTypes.bool,
  className: PropTypes.string,
  noUploadFile: PropTypes.bool,
  localFileUrl: PropTypes.string,
  localFileName: PropTypes.string,
  handleRevokeLocalFileUrl: PropTypes.func,
}

DownloadFileLink.defaultProps = {
  circle: false,
  className: undefined,
  noUploadFile: false,
  localFileUrl: null,
  localFileName: null,
  handleRevokeLocalFileUrl: () => {},
}

export default DownloadFileLink
