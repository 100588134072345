import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import c from 'classnames'
import anime from 'animejs/lib/anime.es'
import { useTranslation } from 'react-i18next'
import Button from 'presentation/Button'
import LockSVG from './lock.svg'
import Security from './security.svg'
import Server from './server.svg'
import styles from './publishLoader.module.scss'

const BAR_DURATION_STEP_1 = 3000

const PublishLoader = ({ loadingStore, storedText, handleFinish }) => {
  const { t } = useTranslation()
  const [finishedLoading, setFinishedLoading] = useState(false)
  const [finishedCheckmark, setFinishedCheckmark] = useState(false)
  const [showingLockAnimation, setShowingLockAnimation] = useState(false)

  const showLoadingAnimation = () => {
    anime({
      // shows connecting to server text
      targets: '.connectingToServerText',
      duration: 1,
      opacity: '0',
    }).finished.then(() =>
      // changes connecting to server button to progress bar
      anime({
        targets: `.${styles.connectingToServer}`,
        height: 10,
        width: '100%',
        marginTop: '20px',
        duration: 500,
        easing: 'easeOutBounce',
      }).finished.then(() =>
        // shows progress bar
        anime({
          targets: `.${styles.loadingBar}`,
          opacity: 1,
          duration: 1,
        }).finished.then(() => {
          // shows Loading... text
          anime({
            targets: `.textLoading`,
            opacity: 1,
            top: 0,
            easing: 'linear',
            duration: 200,
          })
          anime({
            // animates loading bar to fill 100%
            targets: `.${styles.loadingBar}`,
            width: '100%',
            easing: 'easeOutSine',
            duration: BAR_DURATION_STEP_1,
          }).finished.then(() => {
            // once the bar reaches 50% a state it's set to show the sign modal
            setFinishedLoading(true)
          })
        })
      )
    )
  }

  const showLockFinishAnimation = () => {
    setShowingLockAnimation(true)

    anime({
      targets: '.textLoading',
      opacity: 0,
      easing: 'linear',
      duration: 1,
    })
    anime({
      targets: `.${styles.connectingToServer}`,
      opacity: 0,
      duration: 100,
      easing: 'linear',
    })
    // Start animation image lock
    anime({
      targets: `.lock`,
      opacity: 1,
      translateY: {
        value: 150,
        duration: 500,
      },
      easing: 'easeOutExpo',
      // change state finishedCheckmark to start animation checkmark
    }).finished.then(() => setFinishedCheckmark(true))

    // Start animation image security
    anime({
      targets: `.security`,
      opacity: 1,
      translateX: {
        value: 50,
        duration: 500,
      },
      delay: 500,
      easing: 'easeOutExpo',
    })

    // Start animation image server
    anime({
      targets: `.server`,
      opacity: 1,
      translateX: {
        value: -50,
        duration: 500,
      },
      delay: 400,
      easing: 'easeOutExpo',
    })

    // Start animation text an button ok
    anime({
      targets: `.succesfullyText`,
      opacity: 1,
      duration: 600,
      translateY: {
        value: -40,
        duration: 400,
      },
      delay: 1000,
      easing: 'linear',
    })
  }

  useEffect(() => {
    setTimeout(() => {
      showLoadingAnimation()
    }, 1000)
  }, [])

  useEffect(() => {
    if (finishedLoading && !loadingStore && !showingLockAnimation) {
      showLockFinishAnimation()
    }
  }, [finishedLoading, loadingStore, showingLockAnimation])

  return ReactDOM.createPortal(
    <div className={styles.publishingModal}>
      <div className={styles.publishTrustLoaderContainer}>
        <div className={styles.statusTexts}>
          <div className={c(styles.statusText, 'textLoading')}>{t('loadingStore')}</div>
          <div className={c(styles.statusText, 'successText')}>{storedText}</div>
        </div>
        <div className={styles.connectingToServer}>
          <div className={styles.loadingBar} />
          <span className="connectingToServerText">{t('connectingToServer')}</span>
        </div>
      </div>
      <div className={styles.lockContainer}>
        <img className={c(styles.lock, 'lock')} src={LockSVG} alt="lock" />
        <img className={c(styles.security, 'security')} src={Security} alt="security" />
        <img className={c(styles.server, 'server')} src={Server} alt="server" />
        <div className={c(styles.succesfully, 'succesfullyText')}>{storedText}</div>
        <div className={c(styles.succesfully, styles.buttonSuccesfully, 'succesfullyText')}>
          <Button label={t('ok')} primary onClick={handleFinish} />
        </div>
        <svg
          className={c(finishedCheckmark ? styles.checkmark : styles.displayNone)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle className={styles.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
          <path className={styles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
    </div>,
    document.body
  )
}

PublishLoader.propTypes = {
  handleFinish: PropTypes.func.isRequired,
  storedText: PropTypes.string.isRequired,
  loadingStore: PropTypes.bool,
}

PublishLoader.defaultProps = {
  loadingStore: false,
}

export default observer(PublishLoader)
