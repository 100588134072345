/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { observer } from 'mobx-react'
import creationModalSubTitle from 'util/trustCreation'
import EntityModalTypeTitle from 'presentation/EntityModalTypeTitle'
import Icon from 'presentation/Icon'
import { TabsWizard, Tab } from 'presentation/Tabs'
import { countPartErrors } from 'util/validation'
import DynamicFormPart from '../DynamicFormPart'

const CreateEntityModalForm = (
  { onGoBack, readOnly, onTabFinish, instanceEntity, onTabChangeTab },
  ref
) => {
  const [firstInputRefs] = useState([])
  const tabWizardRef = useRef()

  useImperativeHandle(ref, () => ({
    onSuccessTab: () => {
      tabWizardRef.current.onSuccessTab()
    },
  }))

  useEffect(() => {
    if (firstInputRefs[0]) {
      firstInputRefs[0].focus()
    }
  }, [])

  const handleOnChangeTab = (active, isLast) => {
    onTabChangeTab(active, isLast)

    if (firstInputRefs[active]) {
      firstInputRefs[active].focus()
    }
  }

  const { entity } = instanceEntity

  return (
    <>
      <EntityModalTypeTitle
        icon={<Icon name={entity.icon} size={16} />}
        label={creationModalSubTitle(entity.name, instanceEntity.fullName)}
        onGoBack={onGoBack}
      />
      <TabsWizard
        ref={tabWizardRef}
        onChangeTab={handleOnChangeTab}
        onFinish={onTabFinish}
        hideButtons
      >
        {entity.parts.map((entityPart, index) => (
          <Tab
            name={entityPart.title}
            errors={countPartErrors(entityPart, instanceEntity.inputs)}
            key={`entityTab_${entityPart.title}`}
          >
            <DynamicFormPart
              readOnly={readOnly}
              part={entityPart}
              inputs={instanceEntity.inputs}
              inputRef={(ele) => {
                firstInputRefs[index] = ele
              }}
              isEntityStored={instanceEntity.isStored}
              modal
              tab
            />
          </Tab>
        ))}
      </TabsWizard>
    </>
  )
}

/* CreateEntityModalForm.propTypes = {
  instanceEntity: PropTypes.instanceOf(InstanceEntity).isRequired,
  onTabChangeTab: PropTypes.func.isRequired,
  onTabFinish: PropTypes.func.isRequired,
  passedInRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onGoBack: PropTypes.func,
  readOnly: PropTypes.bool,
}

CreateEntityModalForm.defaultProps = {
  onGoBack: null,
  readOnly: true,
} */

export default observer(forwardRef(CreateEntityModalForm))
