import React from 'react'
import PropTypes from 'prop-types'
import styles from './fullWidthInputContainer.module.scss'

const FullWidthInputContainer = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>
}

FullWidthInputContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullWidthInputContainer
