import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import SearchIcon from 'mdi-react/SearchIcon'
import { useTranslation } from 'react-i18next'
import { sanitizeForSearch } from 'util/string'
import Input from 'presentation/Input'
import styles from './permissionsList.module.scss'

const searchOption = (option, term) => {
  return sanitizeForSearch(option).indexOf(sanitizeForSearch(term)) > -1
}

const PermissionsList = ({ refEle, options, placeholder, label, errorMsg }) => {
  const { t } = useTranslation('permission')
  const [searchTerm, changeSearchTerm] = useState('')
  const handleChangeTerm = useCallback((e) => changeSearchTerm(e.target.value))

  return (
    <div>
      <Input
        label={label}
        value={searchTerm}
        placeholder={placeholder}
        onChange={handleChangeTerm}
        icon={<SearchIcon size={20} />}
      />
      <select
        multiple
        ref={refEle}
        className={c(styles.selectPermissions, errorMsg !== '' && styles.error)}
      >
        {options
          .filter((option) => searchOption(t(option.replace(/\./g, '_')), searchTerm))
          .map((option) => (
            <option value={option} key={`option_${option}`}>
              {t(option.replace(/\./g, '_'))}
            </option>
          ))}
      </select>
      {errorMsg !== '' && <div className={styles.errorMsg}>{errorMsg}</div>}
    </div>
  )
}

PermissionsList.propTypes = {
  refEle: PropTypes.oneOfType([PropTypes.object]).isRequired,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
}

PermissionsList.defaultProps = {
  errorMsg: '',
}

export default PermissionsList
