import React, { useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import FilePdfIcon from 'mdi-react/FilePdfIcon'
import { observer } from 'mobx-react'
import Button from 'presentation/Button'
import styles from './printPdf.module.scss'

const ComponentToPrint = forwardRef(({ children }, ref) => {
  return (
    <div ref={ref} className={styles.containerPdf}>
      {children}
    </div>
  )
})

const PrintPdf = ({ children }) => {
  const { t } = useTranslation('trusts')
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <div>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef}>{children}</ComponentToPrint>
      </div>
      <Button
        permission="yes"
        onClick={handlePrint}
        label={t('download')}
        title={t('download')}
        icon={<FilePdfIcon size={18} />}
        style={{ marginRight: '15px' }}
      />
    </div>
  )
}

ComponentToPrint.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

observer(ComponentToPrint)

PrintPdf.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default observer(PrintPdf)
