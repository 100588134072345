import { action, makeObservable, runInAction } from 'mobx'
import File from 'models/File'
import LettersArchiveService from 'services/LettersArchiveService'
import TrustDocumentVersion from './TrustDocumentVersion'

class LetterVersion extends TrustDocumentVersion {
  constructor(id, status, title, updated, type, text) {
    super(id, status, title, updated, type, text)

    this.lettersArchiveService = new LettersArchiveService()

    makeObservable(this, {
      loadContent: action,
    })
  }

  async loadContent(callback = null) {
    if (this.id === null || this.parentId === null) {
      return false
    }

    this.isLoadingContent = true

    try {
      const response = await this.lettersArchiveService.loadContent(
        this.id,
        this.parentId,
        this.type
      )

      runInAction(() => {
        this.content = response.text
        this.file = File.fromJson(response.file)

        if (response.signedFile) {
          this.signedFile = File.fromJson(response.signedFile)
        }

        if (callback) {
          callback()
        }

        this.isLoadingContent = false
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
        this.isLoadingContent = false
      })
    }

    return null
  }

  static fromJson({
    id,
    status,
    title,
    updated,
    type = null,
    text = null,
    file = null,
    signedFile = null,
  }) {
    const letterVersion = new LetterVersion(id, status, title, updated, type, text)

    if (file) {
      letterVersion.file = File.fromJson(file)
    }

    if (signedFile) {
      letterVersion.signedFile = File.fromJson(signedFile)
    }

    return letterVersion
  }

  static fromLetter(letter) {
    const letterVersion = new LetterVersion(
      letter.id,
      letter.status,
      letter.title.value,
      {
        at: letter.lastUpdate,
        firstName: letter.updatedByFirstName,
        lastName: letter.updatedByLastName,
      },
      letter.type,
      letter.text.value
    )

    if (letter.file) {
      letterVersion.file = letter.file
    }

    if (letter.signedFile) {
      letterVersion.signedFile = letter.signedFile
    }

    return letterVersion
  }
}

export default LetterVersion
