import InputStore from 'stores/InputStore'

class RulesRestriction {
  constructor(value, label) {
    this.value = value
    this.label = label
    this.isRestricted = new InputStore()
  }

  static fromJson({ value, label, isAllowed = true }) {
    const restriction = new RulesRestriction(value, label)
    restriction.setIsRestricted(!isAllowed)

    return restriction
  }

  setIsRestricted(value) {
    this.isRestricted.setValue(value)
  }

  toggleIsRestricted() {
    this.isRestricted.setValue(!this.isRestricted.value)
  }

  getJson() {
    return this.value
  }
}

export default RulesRestriction
