import AmountStore from 'models/Asset/Amount'
import PercentageStore from 'models/Asset/Percentage'

const instanceEntityTextReplacer = (instanceEntity, originalExpression, options = {}) => {
  try {
    // why is this outside the if statement? Why is this in a try/catch pair if there is no async calls?
    const expressionParts = originalExpression.split(':')
    const expression = expressionParts[0]
    const boolText = expressionParts[1] !== undefined ? expressionParts[1] : null
    let selected

    if (originalExpression !== '') {
      let results = expression.match(/\$\{[a-zA-Z0-9_]*\}/g) // Returns all alphanumeric characters or _ in the string
      let returnText = expression

      if (results === null) {
        // if is not alpahnumeric (match returns null) then keep the original expresion as a result
        results = [expression]
      }

      results.forEach((result) => {
        const key = result.replace('${', '').replace('}', '')
        let input = instanceEntity.inputs.get(key)

        // Si no existe el input en el primer nivel Obtine como valor de store desde un inputStore hijo
        if (!input) {
          const keys = [...instanceEntity.inputs.keys()]
          for (let i = 0; i < instanceEntity.inputs.size; i += 1) {
            const element = instanceEntity.inputs.get(keys[i])
            if (element && element.stores) {
              const subInput = element.stores[key]

              if (subInput) {
                input = { store: subInput }
                break
              }
              if (!subInput && key === 'valuation') {
                if (instanceEntity.inputs.has('assetTrustInformation')) {
                  selected = instanceEntity.inputs.get('assetTrustInformation').selected
                }

                if (instanceEntity.inputs.has('assetCorporationInformation')) {
                  selected = instanceEntity.inputs.get('assetCorporationInformation').selected
                }
              }
            }
          }
        }

        let value = ''
        if (selected) {
          value = selected.valuation
        } else if (input) {
          value = input.store.value
        }

        if (Array.isArray(value)) {
          if (selected) {
            returnText = value.map((valuationItem) => {
              return {
                amount: valuationItem.amount,
                currency: valuationItem.currency,
              }
            })
          } else {
            // Para los EntityDataList, el value es un array de objetos {id, value}
            returnText = returnText
              .replace(result, value.map((item) => item.value).join(', '))
              .trim()
          }
        } else if (typeof input.store.value === 'boolean') {
          returnText = ''

          if (input.store.value) {
            returnText = boolText.trim()
          }
        } else if (input.store instanceof PercentageStore) {
          returnText = input.store.percentageValue
        } else if (input.store instanceof AmountStore) {
          // Formatear moneda
          returnText = returnText
            .replace(result, input.store.getMoneyValue(options.moneyFormat))
            .trim()
        } else if (typeof value === 'object') {
          // para los datalist, tienen gaurdado un objeto con value
          returnText = returnText.replace(result, value.value).trim()
        } else {
          returnText = returnText.replace(result, value ?? '').trim()
        }
      })
      if (returnText === 'null') {
        returnText = ''
      }

      return returnText
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return ''
}

export default instanceEntityTextReplacer
