import AsyncStore from 'stores/AsyncStore'
import { action, makeObservable, observable, runInAction } from 'mobx'
import TrustHistoryService from 'services/TrustHistoryService/TrustHistoryService'
import InputStore from 'stores/InputStore'

class TrustHistoryStore extends AsyncStore {
  constructor(trustId) {
    super()
    this.trustId = trustId
    this.visibleHistory = []

    this.activePage = 1
    this.pages = 1
    this.orderParameter = 'date'
    this.orderDirection = 'desc'

    this.fromDate = new InputStore()
    this.toDate = new InputStore()

    this.trustHistoryService = new TrustHistoryService()

    this.isLoading = false
    makeObservable(this, {
      // observables
      orderParameter: observable,
      orderDirection: observable,
      visibleHistory: observable,
      // actions
      orderList: action,
      loadHistory: action,
      setFromDate: action,
      setToDate: action,
    })
  }

  async loadHistory() {
    try {
      this.preRequest(this.loadHistory)
      const { history, pagination } = await this.trustHistoryService.loadHistory(
        this.trustId,
        this.activePage,
        this.orderParameter,
        this.orderDirection,
        this.fromDate.value,
        this.toDate.value
      )
      runInAction(() => {
        this.pages = pagination.totalPages
        this.visibleHistory = history
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  orderList() {
    this.orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc'
  }

  changePage(value) {
    this.activePage = value
  }

  setFromDate(value) {
    this.fromDate.setValue(value)
  }

  setToDate(value) {
    this.toDate.setValue(value)
  }
}

export default TrustHistoryStore
