import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './expandableButton.module.scss'

const ANIMATION_DURATION = 150

const ExpandableButton = ({ label, onClick, Icon, className, big, ...props }) => (
  <div
    onClick={onClick}
    className={c(styles.expandableButton, big && styles.big, className && className)}
    role="button"
    tabIndex="0"
    {...props}
  >
    {Icon}
    <span className={styles.text}>{label}</span>
  </div>
)

ExpandableButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.node.isRequired,
  big: PropTypes.bool,
  className: PropTypes.string,
}

ExpandableButton.defaultProps = {
  className: null,
  big: false,
}

export default ExpandableButton
export { ANIMATION_DURATION }
