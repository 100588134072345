import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import InputStore from 'stores/InputStore'
import ActivitiesService from 'services/ActivitiesService'

class ActivityListStore extends AsyncStore {
  activeEntities = []
  activities = []
  activePage = 1
  pages = 1
  order = ''
  showDropdownOrder = false

  selectedItem = null

  statusFilter = new InputStore()
  fromDate = new InputStore()
  toDate = new InputStore()
  isConfirmed = new InputStore()

  constructor() {
    super()

    makeObservable(this, {
      // observables
      activeEntities: observable,
      activities: observable,
      activePage: observable,
      pages: observable,
      order: observable,
      selectedItem: observable,
      statusFilter: observable,
      showDropdownOrder: observable,
      // actions
      loadActiveEntities: action,
      changeActivePage: action,
      setShowDropdownOrder: action,
      setStatusFilter: action,
      setFromDate: action,
      setToDate: action,
      // computeds
      visibleActivities: computed,
    })

    this.isLoading = true
    this.activitiesService = new ActivitiesService()
  }

  async loadActiveEntities(sortField, sortDirection) {
    this.preRequest()
    try {
      this.activitiesService
        .listActiveEntities(
          sortField,
          sortDirection,
          this.activePage,
          this.statusFilter.value,
          this.fromDate.value,
          this.toDate.value
        )
        .then((response) => {
          runInAction(() => {
            this.activeEntities = response.activeEntities
            this.pages = response.pagination.totalPages
            this.onSuccessRequest()
          })
        })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  get visibleActivities() {
    return this.activeEntities
  }

  changeActivePage(pageNumber, orderParam, orderDir) {
    this.activePage = pageNumber
    this.loadActiveEntities(orderParam, orderDir)
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }

  setStatusFilter(value) {
    this.statusFilter.setValue(value)
  }

  setFromDate(value) {
    this.fromDate.setValue(value)
  }

  setToDate(value) {
    this.toDate.setValue(value)
  }
}

export default ActivityListStore
