import { makeAutoObservable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import moneyValueFormat from 'util/numberHelper'
import moment from 'moment'
import InputStore from 'stores/InputStore'

class Asset {
  constructor(type, icon) {
    this.id = uuidv4()
    this.type = type
    this.name = new InputStore()
    this.currency = new InputStore()
    this.description = new InputStore()
    this.value = new InputStore()
    this.lastValuationDate = new InputStore()
    this.icon = icon
    this.invaluable = false

    makeAutoObservable(this)
  }

  static createEntityFromAsset(asset) {
    const assetInstance = new Asset()

    assetInstance.fillSharedFromAsset(asset)

    return assetInstance
  }

  fillSharedFromAsset(asset) {
    this.id = asset.id
    this.type = asset.type

    this.changeIcon(asset.icon)
    this.changeInvaluable(asset.invaluable)
    this.changeName(asset.name.value)
    this.changeCurrency(asset.currency.value)
    this.changeDescription(asset.description.value)
    this.changeValue(asset.value.value)
    this.changeLastValuationDate(asset.lastValuationDate.value)
  }

  fillSharedFromJson(json) {
    this.id = json.id
    this.type = json.type

    this.changeIcon(json.icon)
    this.changeInvaluable(json.invaluable)
    this.changeName(json.assetName)
    this.changeCurrency(json.value.currency)
    this.changeDescription(json.assetDescription)
    this.changeValue(json.value.value)
    this.changeLastValuationDate(
      json.lastValuationDate !== '' ? moment(json.lastValuationDate) : ''
    )
  }

  changeName(value) {
    this.name.setValue(value)
  }

  changeDescription(value) {
    this.description.setValue(value)
  }

  changeValue(value) {
    this.value.setValue(value)
  }

  changeCurrency(value) {
    this.currency.setValue(value)
  }

  changeLastValuationDate(value) {
    this.lastValuationDate.setValue(value)
  }

  changeInvaluable(value) {
    this.invaluable = value
  }

  changeIcon(value) {
    this.icon = value
  }

  get moneyValue() {
    if (this.value.value && this.currency.value && this.currency.value.value) {
      return moneyValueFormat(this.value.value, this.currency.value.value)
    }

    if (this.value.value) {
      return moneyValueFormat(this.value.value, '')
    }

    return ''
  }

  getSharedJson() {
    return {
      id: this.id,
      type: this.type,
      name: this.name.value,
      invaluable: this.invaluable,
      currency: this.currency.value,
      description: this.description.value,
      value: this.value.value,
      lastValuationDate: this.lastValuationDate.value,
      icon: this.icon,
    }
  }

  static createFromJson(json) {
    const asset = new Asset()

    asset.fillSharedFromJson(json)

    return asset
  }
}

export default Asset
