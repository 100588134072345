import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import c from 'classnames'
import Icon from 'presentation/Icon'
import isMobile from 'util/browser'
import styles from './menuButton.module.scss'

const MenuButton = ({
  url,
  onClick,
  disabled,
  iconProps,
  menuIconName,
  menuLabel,
  collapsed,
  errors,
}) => (
  <NavLink
    isActive={() => url.indexOf(window.location.pathname) > -1}
    activeClassName={styles.subMenuButtonActive}
    to={disabled ? '' : url}
    onClick={onClick}
    title={menuLabel}
    className={c(
      styles.subMenuButton,
      collapsed && styles.collapsed,
      isMobile() && styles.isMobile
    )}
  >
    {errors ? <div className={styles.error}>{errors}</div> : ''}
    <span className={styles.icon}>
      <Icon name={menuIconName} {...iconProps} />
    </span>
    <span className={styles.text}>{` ${menuLabel}`}</span>
  </NavLink>
)

MenuButton.propTypes = {
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  menuIconName: PropTypes.string.isRequired,
  errors: PropTypes.number,
  menuLabel: PropTypes.string.isRequired,
  iconProps: PropTypes.shape({
    size: PropTypes.number,
  }),
  collapsed: PropTypes.bool,
  permission: PropTypes.string,
}

MenuButton.defaultProps = {
  iconProps: {},
  collapsed: false,
  errors: 0,
  permission: 'yes',
}

export default MenuButton
