import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { rootStore } from 'App'
import saveContact from 'util/saveContact'
import {
  INDIVIDUAL_CONTACT_CREATION,
  COMPANY_CONTACT_CREATION,
  CONTACTS_LIST,
} from 'routing/routes'
import { SAVE } from 'stores/AuthStore'
import { INDIVIDUAL_CONTACT_TYPE } from 'stores/ContactsCreationStore'
import MainCreationContainer from 'presentation/MainCreationContainer'
import ContactCreationActionButtons from 'presentation/ContactCreationActionButtons'
import ServerError from 'presentation/ServerError'
import MainCreationLoading from 'presentation/MainCreationLoading'
import ContactCreationTitle from 'presentation/ContactCreationTitle'
import StoresContext from 'providers/storeContext'
import Modal from 'scenes/Modal'
import DynamicFormRoutes from 'scenes/DynamicForm'
import ContactsSectionError from './ContactsSectionError'
import ContactErrorsModalContent from './ContactErrorsModalContent'
import styles from './contactsCreation.module.scss'

/* eslint-disable class-methods-use-this */
class ContactsCreation extends React.Component {
  constructor(props, context) {
    super(props, context)

    const { contactsCreationStore } = this.context

    rootStore.setCurrentDynamicStore(contactsCreationStore)

    contactsCreationStore.prepare()
    this.startComponent()
  }

  componentDidMount() {
    this.configureKeyStore()
  }

  componentWillUnmount() {
    const { hotkeyStore } = this.context

    hotkeyStore.removeConfig('contactCreation')

    rootStore.resetContactsCreation()
  }

  get creationUrl() {
    const { type } = this.props

    if (type === INDIVIDUAL_CONTACT_TYPE) {
      return INDIVIDUAL_CONTACT_CREATION
    }

    return COMPANY_CONTACT_CREATION
  }

  closeErrorsModal = () => {
    const { contactsCreationStore } = this.context

    contactsCreationStore.hideValidationModal()
  }

  handleSave = () => {
    const { history, t } = this.props
    const { contactsCreationStore } = this.context

    if (contactsCreationStore.isViewMode) {
      return null
    }

    /* saco la ruta de la segunda sección de una forma cuestionable */
    const redirectRoute = `${this.creationUrl}${contactsCreationStore.entitySections[1].route}`

    saveContact(contactsCreationStore, t('contactCreatedMsg'), history, redirectRoute)

    return null
  }

  handleCancel = () => {
    const { history } = this.props
    const { contactsCreationStore } = this.context

    if (contactsCreationStore.isEdition) {
      contactsCreationStore.reloadData()
      contactsCreationStore.setViewMode(true)
    } else {
      history.push(CONTACTS_LIST)
    }
  }

  handleEdit = () => {
    const { contactsCreationStore } = this.context

    contactsCreationStore.removeViewMode()
  }

  handleGoBack = () => {
    const { history } = this.props

    history.push(CONTACTS_LIST)
  }

  startComponent = () => {
    const { location, type } = this.props
    const { contactsCreationStore, creationParametersStore, authStore } = this.context
    const params = new URLSearchParams(location.search)
    let isViewMode = params.get('v') === 'true'
    let id = params.get('id')

    if (!id && !authStore.can(SAVE)) {
      id = 'forced_error'
    }

    if (!isViewMode && !authStore.can(SAVE)) {
      isViewMode = true
    }

    creationParametersStore.init()
    contactsCreationStore.init(id, isViewMode, type)
  }

  handleTryAgain = () => {
    const { contactsCreationStore } = this.context

    contactsCreationStore.tryAgain()
  }

  handleCloseModal = () => {
    const { contactsCreationStore } = this.context

    contactsCreationStore.hideErrorModal()
  }

  configureKeyStore() {
    const { hotkeyStore } = this.context

    hotkeyStore.setConfig('contactCreation', [
      {
        shortcut: 'alt+s',
        handler: () => {
          this.handleSave()
        },
      },
    ])
  }

  render() {
    const { t } = this.props
    const { contactsCreationStore } = this.context

    if (contactsCreationStore.serverError) {
      return <ServerError handleTryAgain={this.handleTryAgain} />
    }

    if (contactsCreationStore.errorLoadEdit) {
      return <ContactsSectionError />
    }

    return (
      <>
        <MainCreationLoading store={contactsCreationStore} />
        <MainCreationContainer isLoading={contactsCreationStore.isLoading}>
          <ContactCreationTitle />
          <DynamicFormRoutes
            dynamicFormsStore={contactsCreationStore}
            onResetComponent={this.startComponent}
            dynamicEntityName={t('contact')}
            baseRoute={this.creationUrl}
          />
          {contactsCreationStore.isEdition && (
            <>
              <Modal
                open={contactsCreationStore.visibleValidationModal}
                onSuccess={this.closeErrorsModal}
                name="errorsContactModal"
                title={t('attention')}
                okOnly
              >
                <ContactErrorsModalContent />
              </Modal>
            </>
          )}
          <ContactCreationActionButtons
            contactsCreationStore={contactsCreationStore}
            handleCancel={this.handleCancel}
            handleGoBack={this.handleGoBack}
            handleSave={this.handleSave}
            handleEdit={this.handleEdit}
            contactCreated={this.id}
          />
        </MainCreationContainer>
        <Modal
          okOnly
          name="errorServer"
          title={t('titleErrorMessage')}
          onSuccess={this.handleCloseModal}
          open={contactsCreationStore.visibleErrorModal}
        >
          <div className={styles.errorMessageModal}>{contactsCreationStore.messageErrorServer}</div>
        </Modal>
      </>
    )
  }
}

ContactsCreation.contextType = StoresContext

ContactsCreation.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default withTranslation('contactsCreation')(observer(ContactsCreation))
