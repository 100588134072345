import AsyncStore from 'stores/AsyncStore'
import { makeObservable, observable, runInAction } from 'mobx'
import TrustHistoryService from 'services/TrustHistoryService'
import InputStore from 'stores/InputStore'

class TrustHistoryVersionDetailsStore extends AsyncStore {
  constructor(trustId, version) {
    super()
    this.trustId = trustId
    this.version = version
    this.visibleHistory = null
    this.activePage = 1
    this.pages = 1
    this.from = new InputStore()
    this.to = new InputStore()
    this.sortField = 'date'
    this.sortDirection = 'desc'
    this.isLoading = false

    this.trustHistoryService = new TrustHistoryService()

    makeObservable(this, {
      // observables
      trustId: observable,
      version: observable,
      visibleHistory: observable,
      activePage: observable,
      pages: observable,
      from: observable,
      to: observable,
      sortField: observable,
    })
  }

  async loadHistory() {
    try {
      this.preRequest(this.loadHistory)
      const { history, pagination } = await this.trustHistoryService.loadHistory(
        this.trustId,
        this.activePage,
        this.sortField,
        this.sortDirection,
        this.from.value,
        this.to.value,
        this.version
      )

      runInAction(() => {
        this.visibleHistory = history
        this.pages = pagination.totalPages
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  orderList() {
    this.orderDirection = this.orderDirection === 'asc' ? 'desc' : 'asc'
  }

  setToDate(value) {
    this.to.setValue(value)
  }

  setFromDate(value) {
    this.from.setValue(value)
  }

  changePage(value) {
    this.activePage = value
  }
}

export default TrustHistoryVersionDetailsStore
