import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import ClientsService from 'services/ClientsService'

class ClientsListStore extends AsyncStore {
  clients = []
  activePage = 1
  pages = 5
  activeSearch = false
  showDropdownOrder = false
  dataUpdated = []
  visibleCreateClientModal = false
  lastSearch = ''
  lastOrderParam = ''
  lastOrderDir = ''

  constructor() {
    super()

    makeObservable(this, {
      // observables
      clients: observable,
      activePage: observable,
      pages: observable,
      activeSearch: observable,
      showDropdownOrder: observable,
      dataUpdated: observable,
      visibleCreateClientModal: observable,
      // actions
      loadClients: action,
      toggleCreateClientModal: action,
      changeActivePage: action,
      setShowDropdownOrder: action,
      // computeds
      visibleClients: computed,
    })

    this.isLoading = true
    this.clientsService = new ClientsService()
  }

  async loadClients(searchKey = null, orderParam = null, orderDir = null, resetpage = false) {
    this.activeSearch = searchKey !== '' && searchKey !== null

    this.preRequest(this.loadClients)

    try {
      if (searchKey || searchKey === '') {
        this.lastSearch = searchKey
      }

      if (orderParam || orderParam === '') {
        this.lastOrderParam = orderParam
      }

      if (orderDir || orderDir === '') {
        this.lastOrderDir = orderDir
      }

      if (resetpage) {
        this.activePage = 1
      }

      const response = await this.clientsService.listClients(
        this.activePage,
        this.lastSearch,
        this.lastOrderParam,
        this.lastOrderDir
      )

      runInAction(() => {
        this.clients = response.clients
        this.dataUpdated = response.clients
        this.pages = response.pagination.totalPages

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  get visibleClients() {
    return this.clients
  }

  toggleCreateClientModal() {
    this.visibleCreateClientModal = !this.visibleCreateClientModal
  }

  changeActivePage(pageNumber, orderParam, orderDir) {
    this.activePage = pageNumber

    this.loadClients(null, orderParam, orderDir)
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }
}

export default ClientsListStore
