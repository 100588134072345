import { observable, action, computed, runInAction, reaction, makeObservable, override } from 'mobx'
import moment from 'moment'
import AsyncStore from 'stores/AsyncStore'
import i18next from 'i18next'
import CorporationService from 'services/CorporationService'
import CorporationStatus from 'models/CorporationStatus'
import { countPartErrors } from 'util/validation'
import StatutesStore from 'stores/StatutesStore'
import DynamicFormsStore from './DynamicFormsStore'

const SIGNED_DOCUMENTS_ERROR = `One or more Signed Documents are missing, you must upload them to store the corporation.`

class CorporationsCreationStore extends DynamicFormsStore {
  coporationJson = null
  firstLoad = true
  isDirtyGeneral = false
  isDirtyStatute = false
  showSaveToContinue = false
  visiblePublishingModal = false
  visibleFreezeModal = false
  visibleBackToDraftModal = false
  visibleChangeStatusModal = false
  visibleRollBackModal = false
  freezeStatute = true
  validationErrors = []
  visibleValidationModal = false
  loadingValidation = false
  loadingRollBack = false
  alternativeStatusLoad = false
  forceGeneralDataSend = false
  visibleErrors = true
  statuteError = 0
  signedDocsErrors = 0
  // statutes
  statutesStore
  corporationAlternativeStatus = null

  constructor(authStore) {
    super(authStore)

    makeObservable(this, {
      // observables
      firstLoad: observable,
      isDirtyGeneral: observable,
      isDirtyStatute: observable,
      showSaveToContinue: observable,
      visiblePublishingModal: observable,
      visibleFreezeModal: observable,
      visibleBackToDraftModal: observable,
      visibleChangeStatusModal: observable,
      visibleRollBackModal: observable,
      freezeStatute: observable,
      validationErrors: observable,
      visibleValidationModal: observable,
      loadingValidation: observable,
      loadingRollBack: observable,
      alternativeStatusLoad: observable,
      forceGeneralDataSend: observable,
      visibleErrors: observable,
      statuteError: observable,
      signedDocsErrors: observable,
      corporationAlternativeStatus: observable,
      // actions
      prepare: action,
      init: action,
      setAlternativeStatusLoad: action,
      resetValidationErrors: action,
      reloadData: action,
      setFirstLoad: action,
      resetAttributes: override,
      fillEditData: action,
      save: action,
      validateCorporation: action,
      tryToGoBackToDraft: action,
      goBackToDraft: action,
      tryToChangeStatus: action,
      tryToRollBack: action,
      successRollBackModal: action,
      cancelRollBackModal: action,
      changeNextStatus: action,
      resetDirty: action,
      setStatuteError: action,
      clearStatuteError: action,
      validateStatute: action,
      validateExecuted: action,
      validate: action,
      showValidationModal: action,
      hideValidationModal: action,
      validateFirstSection: action,
      showFreezeModal: action,
      hideFreezeModal: action,
      showModalBackToDraft: action,
      hideModalBackToDraft: action,
      showChangeStatusModal: action,
      hideChangeStatusModal: action,
      showPublishingModal: action,
      hidePublishingModal: action,
      toggleFreezeStatute: action,
      checkFirstStepComplete: action,
      // computed
      showChangeStatusError: computed,
      isDirty: computed,
      corporationName: computed,
      hasAlternativeStatus: computed,
      goBackShouldUpdateMetadata: computed,
    })
  }

  prepare() {
    super.setEntityNameKey('name')
    super.setEntityStatus(new CorporationStatus())

    this.corporationService = new CorporationService()
    this.statutesStore = new StatutesStore(this)
    this.corporationAlternativeStatus = null
    this.forceGeneralDataSend = false
  }

  async init(id, viewMode, alternativeStatusLoad) {
    this.preRequest(() => this.init(id, viewMode, alternativeStatusLoad))
    // resets atributos
    this.resetAttributes()
    this.setAlternativeStatusLoad(alternativeStatusLoad)

    try {
      if (id !== null) {
        this.coporationJson = await this.corporationService.getCorporation(
          id,
          alternativeStatusLoad
        )
      }

      // loads corporation metadata
      const metadata = await this.corporationService.loadMetadata(
        this.coporationJson?.id,
        this.coporationJson?.dataId
      )

      runInAction(() => {
        // processes the corporation metadata into classes
        this.processMetadata(metadata)

        // if is edition
        if (id !== null) {
          // sets edition
          this.setIsEdition(id)
          this.reloadData()
          this.toggleViewMode(viewMode)
        } else {
          this.onSuccessRequest()
          this.detectCorporationChanges()
        }

        this.firstLoad = false
      })
    } catch (e) {
      this.onErrorRequest(e)
      this.firstLoad = false
    }
  }

  setAlternativeStatusLoad(val) {
    this.alternativeStatusLoad = val
  }

  resetValidationErrors = () => {
    this.validationErrors = []
  }

  reloadData(validate = true, successCallback = null) {
    this.preRequest(() => this.reloadData(validate))
    this.firstLoad = false
    this.resetValidationErrors()
    this.statuteError = 0
    this.signedDocsErrors = 0

    this.fillEditData().then(() => {
      // starts detecting corporation changes
      this.detectCorporationChanges()

      // inits statutes store and starts detecting statute changes
      this.statutesStore.initialize(this.alternativeStatusLoad).then(() => {
        this.detectCorporationChangesStatutes()

        if (validate) {
          this.resetDirty()
          this.validate()
        }
      })

      if (successCallback) {
        successCallback()
      }

      this.onSuccessRequest()
    })
  }

  setFirstLoad() {
    this.firstLoad = true
  }

  resetAttributes() {
    super.resetAttributes()
    super.setEntityStatus(new CorporationStatus())

    this.isDirtyGeneral = false
    this.showSaveToContinue = false
    this.loadingValidation = false
    this.visibleErrors = true
    this.visibleRollBackModal = false
    this.validationErrors = []
    this.loadingRollBack = false
    this.corporationAlternativeStatus = null
    this.alternativeStatusLoad = false
    this.forceGeneralDataSend = false
  }

  detectCorporationChanges() {
    /**
     * sets reaction to detect changes on any part of the corporation except for lows and dots
     */
    reaction(
      () => JSON.stringify(this.getDataJson()),
      (data) => {
        /* al guardar, el datajson devuelve un objeto vacio, hago una validacion para ignorar ese caso */
        if (Object.keys(data).length > 2 && !this.isDirtyGeneral) {
          this.isDirtyGeneral = true
        }
      }
    )
  }

  detectCorporationChangesStatutes() {
    /**
     * sets reaction to detect changes on statutes
     */
    reaction(
      () => JSON.stringify(this.getStatutesJson()),
      (data) => {
        if (Object.keys(data).length > 2 && !this.isDirtyLows) {
          this.isDirtyStatute = true
        }
      }
    )
  }

  async fillEditData() {
    this.preRequest(this.fillEditData)

    try {
      if (this.idEdition) {
        this.coporationJson = await this.corporationService.getCorporation(
          this.idEdition,
          this.alternativeStatusLoad
        )
      }

      runInAction(() => {
        super.setLastSaveDate(moment(this.coporationJson.updated.at))
        this.entityStatus.setStatus(this.coporationJson.status.currentData.status)

        this.corporationAlternativeStatus = null

        if (this.coporationJson.status.alternativeData !== null) {
          this.corporationAlternativeStatus = new CorporationStatus(
            this.coporationJson.status.alternativeData.status
          )
        }

        this.fillDataInputs(this.coporationJson.data)
        super.setStep1Finished()
      })

      return this.coporationJson
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }

    return null
  }

  getJson() {
    return {
      id: this.idEdition,
      data: this.isDirtyGeneral || this.forceGeneralDataSend ? this.getDataJson() : null,
      statuteSection: this.isDirtyStatute ? this.statutesStore.getJson() : [],
    }
  }

  getDataJson() {
    const json = {}

    this.inputs.forEach((input, key) => {
      json[key] = input.json
    })

    return json
  }

  getStatutesJson() {
    return this.statutesStore.getJson()
  }

  async save(successCallback) {
    if (this.isEdition) {
      await this.validate()
    }

    const validFirstSection = await this.validateFirstSection()

    runInAction(() => {
      this.showSaveToContinue = false
    })

    if (!validFirstSection) {
      return
    }

    runInAction(() => {
      this.loadingSave = true
    })

    try {
      let id = this.idEdition

      this.requestProcess(() => this.save(successCallback))

      if (this.isDirty || (this.entityStatus.isStored && !this.corporationAlternativeStatus)) {
        const json = this.getJson()
        const response = await this.corporationService.saveCorporation(json, id)
        id = response.id
      }

      runInAction(() => {
        this.loadingSave = false

        super.setStep1Finished()
        super.setLastSaveDate(moment())
        this.resetDirty()

        successCallback(id)
      })
    } catch (e) {
      runInAction(() => {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        } else {
          this.validationErrors = e.response.data.error.errors
          this.showValidationModal()
        }

        this.loadingSave = false
      })
    }
  }

  async validateCorporation(successCallback, errorCallback) {
    runInAction(() => {
      this.loadingSave = true
      this.loadingValidation = true
    })
    this.resetValidationErrors()

    this.requestProcess(() => this.validateCorporation(successCallback, errorCallback))
    try {
      await this.corporationService.validateCorporation(this.idEdition)

      runInAction(() => {
        successCallback()

        this.loadingSave = false
        this.loadingValidation = false
      })
    } catch (e) {
      runInAction(() => {
        this.loadingSave = false
        this.loadingValidation = false

        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        } else {
          this.validationErrors = e.response.data.error.errors
          errorCallback()
        }
      })
    }
  }

  tryToGoBackToDraft() {
    this.showModalBackToDraft()
  }

  async updateMetadata(id, successCallback = () => {}) {
    await this.corporationService.updateMetadata(id)
    successCallback()
  }

  goBackToDraft(successCallback) {
    this.forceGeneralDataSend = true
    this.save((id) => {
      this.updateMetadata(id, successCallback)
    })
    this.hideModalBackToDraft()
  }

  async tryToChangeStatus() {
    await this.validate()

    if (this.validationErrors.length) {
      this.showValidationModal()
    } else if (this.entityStatus.isDraft) {
      runInAction(() => {
        this.loadingValidation = true

        this.save(() => {
          this.reloadData(true, () => {
            this.validateCorporation(
              () => {
                this.loadingValidation = false
                this.showFreezeModal()
              },
              () => {
                this.loadingValidation = false
                this.showValidationModal()
              }
            )
          })
        })
      })
    } else if (this.entityStatus.isExecuted || this.entityStatus.isExecution) {
      this.showChangeStatusModal()
    }
  }

  tryToRollBack() {
    this.visibleRollBackModal = true
  }

  async successRollBackModal(successCallback) {
    this.visibleRollBackModal = false
    this.loadingRollBack = true

    this.requestProcess(() => this.successRollBackModal(successCallback))
    try {
      await this.corporationService.changeBackStatus(this.idEdition)
      let updatedMetadata

      if (this.goBackShouldUpdateMetadata)
        updatedMetadata = await this.corporationService.updateMetadata(this.idEdition)

      runInAction(() => {
        if (updatedMetadata) super.processMetadata(updatedMetadata)
        this.loadingRollBack = false
        successCallback()
      })
    } catch (e) {
      runInAction(() => {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        }
        this.loadingRollBack = false
      })
    }
  }

  cancelRollBackModal() {
    this.visibleRollBackModal = false
  }

  countPartErrors(part) {
    if (part.id === 'statuteSection' && this.statuteError) {
      return this.statuteError
    }

    return countPartErrors(part, this.inputs)
  }

  async changeNextStatus(successCallback) {
    const jsonParams = {}
    if (this.entityStatus.isDraft) {
      let statuteId = null
      if (this.freezeStatute && this.statutesStore.draftMainLetter !== null) {
        statuteId = this.statutesStore.draftMainLetter.id
      }

      jsonParams.statuteId = statuteId
    }
    runInAction(() => {
      this.loadingSave = true
      this.loadingValidation = true
    })

    this.requestProcess(() => this.changeNextStatus(successCallback))
    try {
      await this.corporationService.changeNextStatus(this.idEdition, jsonParams)

      runInAction(() => {
        this.loadingSave = false
        this.loadingValidation = false

        successCallback()
      })
    } catch (e) {
      runInAction(() => {
        this.loadingSave = false
        this.loadingValidation = false
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        } else {
          this.validationErrors = e.response.data.error.errors
          this.showValidationModal()
        }
      })
    }
  }

  resetDirty() {
    this.isDirtyGeneral = false
    this.isDirtyStatute = false
  }

  setStatuteError(errorCount) {
    this.statuteError = errorCount
  }

  clearStatuteError() {
    this.statuteError = 0
  }

  validateStatute() {
    if (this.statutesStore) {
      this.statutesStore.validate()

      if (this.statutesStore.isValid) {
        this.clearStatuteError()
      } else {
        this.setStatuteError(this.statutesStore.totalErrors)
      }
    }
  }

  get showChangeStatusError() {
    return this.validationErrors.length > 0
  }

  validateSignedFile(signedFile) {
    if (!signedFile.id) {
      signedFile.setError(true, 'requiredFile')

      this.signedDocsErrors += 1
    }
  }

  validateExecuted() {
    if (this.entityStatus.isExecuted) {
      this.signedDocsErrors = 0

      if (this.statutesStore) {
        const draftStatute = this.statutesStore.draftMainLetter

        if (draftStatute) {
          draftStatute.signedFile.clearError()
          this.validateSignedFile(draftStatute.signedFile)
        }
      }

      if (this.signedDocsErrors === 0 && this.validationErrors.includes(SIGNED_DOCUMENTS_ERROR)) {
        this.validationErrors = this.validationErrors.filter(
          (error) => error !== SIGNED_DOCUMENTS_ERROR
        )
      } else if (
        this.signedDocsErrors > 0 &&
        !this.validationErrors.includes(SIGNED_DOCUMENTS_ERROR)
      ) {
        this.validationErrors.push(SIGNED_DOCUMENTS_ERROR)
      }
    }
  }

  async validate() {
    this.visibleErrors = true
    this.validateStatute()
    this.validateExecuted()

    const errors = await [...this.inputs.values()].reduce(async (validationErrors, input) => {
      const previous = await validationErrors

      if (input.validate) {
        try {
          await input.validate()
        } catch (error) {
          // do nothing
        }
      }

      return previous
    }, [])

    this.resetValidationErrors()

    this.entitySections.forEach((corporationSection) => {
      this.countPartErrors(corporationSection, this.inputs)

      if (errors > 0 || (corporationSection.id === 'statuteSection' && this.statuteError)) {
        const section = corporationSection.menuLabel

        runInAction(() => {
          this.validationErrors.push(i18next.t('validation:sectionError', { section }))
        })
      }
    })

    if (this.signedDocsErrors) {
      runInAction(() => {
        this.validationErrors.push(SIGNED_DOCUMENTS_ERROR)
      })
    }

    return !errors.length
  }

  showValidationModal() {
    this.visibleValidationModal = true
  }

  hideValidationModal() {
    this.visibleValidationModal = false
  }

  async validateFirstSection() {
    /*
    valida especificamente la primer seccion, pero de forma no dinamica, tener en cuenta
    */
    if (this.entitySections.length === 0) {
      return false
    }

    let isValid = true
    if (!this.inputs.get('name').store.value) {
      this.inputs.get('name').store.setError(true, i18next.t('validation:requiredField'))
      isValid = false
    }

    if (!this.inputs.get('governingLawCorporation').store.value) {
      this.inputs
        .get('governingLawCorporation')
        .store.setError(true, i18next.t('validation:requiredField'))
      isValid = false
    }

    if (!this.inputs.get('corporationType').store.value) {
      this.inputs.get('corporationType').store.setError(true, i18next.t('validation:requiredField'))
      isValid = false
    }

    return isValid
  }

  get isDirty() {
    if (!this.entityStatus.isDraft) {
      return false
    }

    return (
      this.isDirtyGeneral ||
      this.isDirtyStatute ||
      (!this.corporationAlternativeStatus && this.forceGeneralDataSend)
    )
  }

  get corporationName() {
    if (this.inputs.get('corporationName')) {
      return this.inputs.get('corporationName').store.value
    }

    return null
  }

  showFreezeModal() {
    this.visibleFreezeModal = true
  }

  hideFreezeModal() {
    this.visibleFreezeModal = false
  }

  showModalBackToDraft() {
    this.visibleBackToDraftModal = true
  }

  hideModalBackToDraft() {
    this.visibleBackToDraftModal = false
  }

  showChangeStatusModal() {
    this.visibleChangeStatusModal = true
  }

  hideChangeStatusModal() {
    this.visibleChangeStatusModal = false
  }

  showPublishingModal() {
    this.visiblePublishingModal = true
  }

  hidePublishingModal() {
    this.visiblePublishingModal = false
  }

  toggleFreezeStatute() {
    this.freezeStatute = !this.freezeStatute
  }

  checkFirstStepComplete() {
    if (this.subMenuDisabled && this.validateFirstSection()) {
      runInAction(() => {
        this.showSaveToContinue = true
      })
    }
  }

  get isViewMode() {
    if (super.isEdition) {
      return false
    }

    if (this.entityStatus.isDraft) {
      return this.viewMode
    }

    return true
  }

  get hasAlternativeStatus() {
    return this.corporationAlternativeStatus !== null || this.alternativeStatusLoad
  }

  get goBackShouldUpdateMetadata() {
    return this.entityStatus.isExecution
  }
}

export default CorporationsCreationStore
