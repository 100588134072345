import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Modal from 'scenes/Modal'
import ComplexResolutionList from '../../ComplexResolutionList'
import styles from './complexResolutionModal.module.scss'

const ComplexResolutionModal = ({
  open,
  onClose,
  transactions,
  messageError,
  missingSelection,
  handleSuccessComplexResolution,
}) => {
  const { t } = useTranslation('transactionsCreation')

  return (
    <Modal
      open={open}
      onCancel={onClose}
      name="complexResolution"
      title={t('complexResolutionModal')}
      onSuccess={handleSuccessComplexResolution}
    >
      <div>{t('complexResolutionSubtitle')}</div>
      {messageError && (
        <div className={styles.containerMessageError}>
          <div className={styles.messageError}>{messageError}</div>
        </div>
      )}
      {missingSelection && (
        <div className={styles.missingSelection}>{t('missingComplexResolutionSelection')}</div>
      )}
      <ComplexResolutionList transactions={transactions} label={t('transactions')} />
    </Modal>
  )
}

ComplexResolutionModal.propTypes = {
  transactions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  open: PropTypes.bool,
  messageError: PropTypes.string,
  missingSelection: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  handleSuccessComplexResolution: PropTypes.func.isRequired,
}

ComplexResolutionModal.defaultProps = {
  open: false,
  missingSelection: '',
  messageError: 'false',
}

export default observer(ComplexResolutionModal)
