import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TransactionsList from 'presentation/TransactionsList'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import LoadingRing from 'presentation/LoadingRing'
import Modal from 'scenes/Modal'
import ButtonBackListTrust from 'presentation/ButtonBackListTrust'
import CreationModal from 'presentation/CreationModal'
import NoResultsList from 'presentation/NoResultsList'
import TransactionForm from '../PollsForm'
import FiduciaryStructurePollsStore from '../FiduciaryStructurePollsStore'
import styles from './pollsListTab.scss'

const PollsListTab = ({
  handleCloseModal,
  handleChangeName,
  handleChangeDate,
  handleSuccessStore,
  handleCancelStore,
  handleSuccessReject,
  handleSuccessApprove,
  handleResetVotesList,
  handleSaveTransaction,
  handleStoreTransactions,
  fiduciaryStructurePollsStore,
  handleRejectTransaction,
  handleDeleteTransaction,
  handleChangeDescription,
  handleApproveTransaction,
  handleOpenViewTransaction,
  handleToggleCreateAnother,
  handleModalDeleteTransaction,
  handleChangeApproveAutomatically,
}) => {
  const [isEdition, setIsEdition] = useState(false)
  const { t } = useTranslation('transactionsCreation')

  const {
    trustId,
    isLoading,
    showTransaction,
    transactionsList,
    visibleStoreModal,
    visibleErrorModal,
    visibleDeleteModal,
    visibleRejectModal,
    messageErrorServer,
    visibleApproveModal,
    checkedSaveAndCreate,
    visibleCreationModal,
  } = fiduciaryStructurePollsStore
  useEffect(() => {
    if (showTransaction?.id) {
      setIsEdition(true)
    } else {
      setIsEdition(false)
    }

    handleResetVotesList()
  }, [showTransaction])

  useEffect(() => {
    handleResetVotesList()
  }, [])

  if (isLoading) {
    return <LoadingRing center />
  }

  return (
    <>
      {transactionsList.length > 0 ? (
        <TransactionsList
          transactionsList={transactionsList}
          handleRejectTransaction={handleRejectTransaction}
          handleApproveTransaction={handleApproveTransaction}
          handleOpenViewTransaction={handleOpenViewTransaction}
          handleModalDeleteTransaction={handleModalDeleteTransaction}
        />
      ) : (
        <NoResultsList showIcon={false} topText={t('noPollsCreated')} />
      )}
      <CreationModal
        handleClose={handleCloseModal}
        visible={visibleCreationModal}
        title={showTransaction ? t('viewPoll') : t('addPoll')}
      >
        <TransactionForm
          viewMode={isEdition}
          handleCancel={handleCloseModal}
          showTransaction={showTransaction}
          handleChangeName={handleChangeName}
          handleChangeDate={handleChangeDate}
          checkedSaveAndCreate={checkedSaveAndCreate}
          handleSaveTransaction={handleSaveTransaction}
          handleChangeDescription={handleChangeDescription}
          handleToggleCreateAnother={handleToggleCreateAnother}
          handleChangeApproveAutomatically={handleChangeApproveAutomatically}
        />
      </CreationModal>
      <Modal
        name="deleteTransaction"
        title={t('deleteModal')}
        open={visibleDeleteModal}
        onCancel={handleCloseModal}
        onSuccess={handleDeleteTransaction}
      />
      <Modal
        name="approveTransaction"
        title={t('approveModal')}
        open={visibleApproveModal}
        onCancel={handleCloseModal}
        onSuccess={handleSuccessApprove}
      >
        {t('wantToApprove')}
      </Modal>
      <Modal
        name="rejectTransaction"
        title={t('rejectModal')}
        open={visibleRejectModal}
        onCancel={handleCloseModal}
        onSuccess={handleSuccessReject}
      >
        {t('wantToReject')}
      </Modal>
      <Modal
        title={t('storeModal')}
        name="storeTransactions"
        open={visibleStoreModal}
        onSuccess={handleSuccessStore}
        onCancel={handleCancelStore}
      />
      <Modal
        okOnly
        name="errorServer"
        open={visibleErrorModal}
        onSuccess={handleCloseModal}
        title={t('titleErrorMessage')}
      >
        <div className={styles.errorMessageModal}>{messageErrorServer}</div>
      </Modal>
      <ButtonBackListTrust id={trustId} handleTrustButtonClick={handleStoreTransactions} />
    </>
  )
}

PollsListTab.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  handleSuccessStore: PropTypes.func.isRequired,
  handleCancelStore: PropTypes.func.isRequired,
  handleSuccessReject: PropTypes.func.isRequired,
  handleResetVotesList: PropTypes.func.isRequired,
  handleSuccessApprove: PropTypes.func.isRequired,
  handleSaveTransaction: PropTypes.func.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  handleDeleteTransaction: PropTypes.func.isRequired,
  handleRejectTransaction: PropTypes.func.isRequired,
  handleStoreTransactions: PropTypes.func.isRequired,
  handleApproveTransaction: PropTypes.func.isRequired,
  handleToggleCreateAnother: PropTypes.func.isRequired,
  handleOpenViewTransaction: PropTypes.func.isRequired,
  handleModalDeleteTransaction: PropTypes.func.isRequired,
  handleChangeApproveAutomatically: PropTypes.func.isRequired,
  fiduciaryStructurePollsStore: PropTypes.instanceOf(FiduciaryStructurePollsStore).isRequired,
  transactionsList: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default observer(PollsListTab)
