import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import VotesList from 'presentation/VotesList'
import CreationModal from 'presentation/CreationModal'
import LoadingRing from 'presentation/LoadingRing'
import NoResultsList from 'presentation/NoResultsList'
import ButtonBack, { useBackButtonURL } from 'presentation/ButtonBack'
import Modal from 'scenes/Modal'
import ViewVoteForm from '../ViewVoteForm'
import FiduciaryStructureVotesStore from '../FiduciaryStructureVotesStore'
import ResolveVotingForm from '../ResolveVotingForm'
import ComplexResolutionModal from './ComplexResolutionModal'
import styles from './votesListTab.module.scss'

const VotesListTab = ({
  handleSaveVote,
  handleViewVote,
  fiduciaryStructureVotesStore,
  handleCloseVoting,
  handleSetActiveTab,
  handleResolveVoting,
  handleVoteCloseModal,
  warningVoteInProgress,
  toggleLoadingDocument,
  handleChangeVotingResult,
  handleSetComplexResolution,
  handleToggleLoadingDocument,
  handleShowComplexResolution,
  handleSuccessComplexResolut,
  transactionsComplexResolution,
  handleActionComplexResolution,
  handleSuccessComplexResolution,
  handleSuccessWarningVotingInProsess,
  handleVoteCloseModalWarningVotingModalInProsess,
}) => {
  const { t } = useTranslation('transactionsCreation')
  const backButtonURL = useBackButtonURL()

  const {
    vote,
    votes,
    trustId,
    isLoading,
    isEdition,
    visibleErrorModal,
    messageErrorServer,
    isComplexResolution,
    votingResultSelected,
    visibleModalCreationVote,
    visibleResolveVotingModal,
    visibleComplexResolutionModal,
    showVotingTransactionEditable,
    visibleWarningVotingModalInProcess,
  } = fiduciaryStructureVotesStore

  useEffect(() => {
    if (isEdition) {
      fiduciaryStructureVotesStore.setVotersSelected()
    }
  }, [isEdition])

  if (isLoading) {
    return <LoadingRing center />
  }

  return (
    <>
      {votes.length > 0 ? (
        <VotesList
          votes={votes}
          handleViewVote={handleViewVote}
          handleResolveVoting={handleResolveVoting}
        />
      ) : (
        <NoResultsList showIcon={false} topText={t('noVotesCreated')} />
      )}
      <div className={styles.actionButtons}>
        <ButtonBack to={backButtonURL} />
      </div>
      <CreationModal
        handleClose={handleVoteCloseModal}
        visible={visibleModalCreationVote}
        title={vote.id ? t('viewVoting') : t('addVoting')}
      >
        <ViewVoteForm
          vote={vote}
          handleSaveVote={handleSaveVote}
          fiduciaryStructureVotesStore={fiduciaryStructureVotesStore}
          handleCancel={handleVoteCloseModal}
          handleSetActiveTab={handleSetActiveTab}
          isViewMode={!showVotingTransactionEditable}
          handleToggleLoadingDocument={!handleToggleLoadingDocument}
          defaultSelectedTransactions={fiduciaryStructureVotesStore.transactionsNewVote}
        />
      </CreationModal>
      <CreationModal
        title={t('resolveVoting')}
        handleClose={handleVoteCloseModal}
        visible={visibleResolveVotingModal}
      >
        <ResolveVotingForm
          vote={vote}
          trustId={trustId}
          handleCloseVoting={handleCloseVoting}
          isComplexResolution={isComplexResolution}
          handleVoteCloseModal={handleVoteCloseModal}
          votingResultSelected={votingResultSelected}
          warningVoteInProgress={warningVoteInProgress}
          toggleLoadingDocument={toggleLoadingDocument}
          handleChangeVotingResult={handleChangeVotingResult}
          handleSetComplexResolution={handleSetComplexResolution}
          handleShowComplexResolution={handleShowComplexResolution}
          handleActionComplexResolution={handleActionComplexResolution}
          visibleWarningVotingModalInProcess={visibleWarningVotingModalInProcess}
          handleSuccessWarningVotingInProsess={handleSuccessWarningVotingInProsess}
          handleVoteCloseModalWarningVotingModalInProsess={
            handleVoteCloseModalWarningVotingModalInProsess
          }
        />
      </CreationModal>
      <ComplexResolutionModal
        onClose={handleVoteCloseModal}
        messageError={messageErrorServer}
        open={visibleComplexResolutionModal}
        transactions={transactionsComplexResolution}
        handleSuccessComplexResolut={handleSuccessComplexResolut}
        handleSuccessComplexResolution={handleSuccessComplexResolution}
      />
      <Modal
        okOnly
        name="errorServer"
        open={visibleErrorModal}
        title={t('titleErrorMessage')}
        onSuccess={handleVoteCloseModal}
      >
        <div className={styles.errorMessageModal}>{messageErrorServer}</div>
      </Modal>
    </>
  )
}

VotesListTab.propTypes = {
  warningVoteInProgress: PropTypes.bool,
  toggleLoadingDocument: PropTypes.func,
  handleSaveVote: PropTypes.func.isRequired,
  handleViewVote: PropTypes.func.isRequired,
  handleSuccessComplexResolut: PropTypes.func,
  handleCloseVoting: PropTypes.func.isRequired,
  handleSetActiveTab: PropTypes.func.isRequired,
  handleResolveVoting: PropTypes.func.isRequired,
  handleVoteCloseModal: PropTypes.func.isRequired,
  handleChangeVotingResult: PropTypes.func.isRequired,
  handleSetComplexResolution: PropTypes.func.isRequired,
  handleShowComplexResolution: PropTypes.func.isRequired,
  handleToggleLoadingDocument: PropTypes.func.isRequired,
  handleActionComplexResolution: PropTypes.func.isRequired,
  handleSuccessComplexResolution: PropTypes.func.isRequired,
  handleSuccessWarningVotingInProsess: PropTypes.func.isRequired,
  fiduciaryStructureVotesStore: PropTypes.instanceOf(FiduciaryStructureVotesStore).isRequired,
  handleVoteCloseModalWarningVotingModalInProsess: PropTypes.func.isRequired,
  transactionsComplexResolution: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

VotesListTab.defaultProps = {
  toggleLoadingDocument: null,
  warningVoteInProgress: false,
  handleSuccessComplexResolut: null,
}

export default observer(VotesListTab)
