import { action, observable, runInAction, computed, makeObservable } from 'mobx'
import TrustProtectorCategoriesService from 'services/TrustProtectorCategoriesService'
import { sortBy as _sortBy } from 'lodash'
import AsyncStore from './AsyncStore'

const transformToOption = (category) => ({ id: category.id, value: category.name })

class TrustProtectorCategoriesStore extends AsyncStore {
  categories = []

  constructor() {
    super()

    makeObservable(this, {
      // observables
      categories: observable,
      // actions
      resetCategories: action,
      loadCategories: action,
      addCategory: action,
      createCategory: action,
      create: action,
      // computeds
      categoriesOptions: computed,
    })

    this.trustProtectorCategoriesService = new TrustProtectorCategoriesService()
  }

  resetCategories() {
    this.categories = []
  }

  async loadCategories(trustId) {
    this.preRequest()

    this.resetCategories()

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.trustProtectorCategoriesService.listCategories(trustId)

      runInAction(() => {
        _sortBy(response, (cat) => cat.name).forEach((category) => {
          this.addCategory(category)
        })

        this.onSuccessRequest()
      })
    } catch (e) {
      throw e
    }
  }

  addCategory = (category) => {
    this.categories.push(category)
  }

  createCategory(value, trustId) {
    return this.create(value, trustId, this.addCategory)
  }

  async create(value, trustId, addFunction) {
    this.preRequest()

    const response = await this.trustProtectorCategoriesService.createCategory(trustId, value)

    try {
      const category = response

      this.onSuccessRequest()

      addFunction(category)

      return transformToOption(category)
    } catch (e) {
      this.onErrorRequest()
      throw e
    }
  }

  get categoriesOptions() {
    return this.categories.map((category) => transformToOption(category))
  }
}

export default TrustProtectorCategoriesStore
