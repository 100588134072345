import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ListDate from 'models/ListDate'
import ToolTip from 'presentation/ToolTip'
import styles from './tableItemDate.module.scss'

const TableItemDate = ({ listDate, view, preText, icon }) => {
  const { t } = useTranslation('common')
  if (view === 'detail') {
    return (
      <div>
        <ToolTip
          tooltipContent={
            <>
              {listDate.at.format(t('humanDateTimeYearFormat'))}
              <br />
              {t('updatedBy', {
                name: listDate.name,
              })}
            </>
          }
        >
          <span className={styles.dottedBorder}>{listDate.at.format(t('dateFormat'))}</span>
        </ToolTip>
      </div>
    )
  }

  const timeFormat = t('humanDateYearFormat')

  return (
    <div className={styles.wrapper}>
      {icon && <span>{icon}</span>}
      {preText && <span>{preText}</span>}
      &nbsp;
      <ToolTip tooltipContent={listDate.at.format(t('humanDateTimeYearFormat'))}>
        <span className={styles.dottedBorder}>{listDate.at.format(timeFormat)}</span>
      </ToolTip>
      &nbsp;
      {listDate.name && t('updatedBy', { name: listDate.name })}
    </div>
  )
}

TableItemDate.propTypes = {
  listDate: PropTypes.instanceOf(ListDate).isRequired,
  view: PropTypes.string.isRequired,
  preText: PropTypes.string,
  icon: PropTypes.node,
}

TableItemDate.defaultProps = {
  icon: null,
  preText: null,
}

export default TableItemDate
