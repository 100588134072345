import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import User from 'models/User'
import UserStatusName from 'presentation/UserStatusName'
import TableItemDate from 'presentation/TableItemDate'
import TableItemName from 'presentation/TableItemName'
import UpdateIcon from 'mdi-react/UpdateIcon'
import styles from './usersTableItem.module.scss'

const UsersTableItem = ({ listItem, link, view }) => {
  const { t } = useTranslation('common')

  return (
    <NavLink
      className={[styles.userTableItem, styles[`userTableItem--${view}`]].join(' ')}
      to={link}
    >
      <TableItemName
        name={listItem.name}
        type={t('users:user')}
        classNames={styles.userTableItemName}
        iconClassNames={styles.typeIcon}
      />
      <div className={styles.userTableItemStructureName}>{listItem.email}</div>
      <div className={styles.userTableItemCreatedAt}>
        <TableItemDate
          listDate={{ at: listItem.created }}
          view={view}
          icon={<UpdateIcon size={12} />}
        />
      </div>
      <div className={styles.userTableItemUpdatedAt}>
        <TableItemDate
          listDate={{ at: listItem.updated }}
          view={view}
          icon={<UpdateIcon size={12} />}
        />
      </div>
      <div className={styles.userTableItemStatus}>
        <UserStatusName status={listItem.status} />
      </div>
    </NavLink>
  )
}

UsersTableItem.propTypes = {
  listItem: PropTypes.instanceOf(User).isRequired,
  link: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
}

export default UsersTableItem
