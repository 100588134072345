import Part, { TYPE_GROUP_LIST } from './Part'
import PartStrategy from '../PartStrategy'

class GroupListPart extends Part {
  parts = []

  constructor(id, title, readOnly) {
    super(id, TYPE_GROUP_LIST)

    this.title = title
    this.readOnly = readOnly
  }

  addPart(part) {
    this.parts.push(part)
  }

  static fromJson({ id, display = {}, parts, title: fullTitle }, onCreateInput) {
    const { title, readOnly = false } = display
    const groupList = new GroupListPart(id, title || fullTitle, readOnly)

    parts.forEach((partJson) => {
      const part = PartStrategy.getPart(partJson, onCreateInput)

      if (part) {
        groupList.addPart(part)
      }
    })

    return groupList
  }
}

export default GroupListPart
