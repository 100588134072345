import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import Input from 'presentation/Input'
import PropTypes from 'prop-types'
import styles from './creationInput.module.scss'

const CreationInput = ({
  as: Component,
  inputStore,
  name,
  label,
  placeholder,
  inputRef,
  required,
  literal,
  wrapperClassName,
  ...props
}) => {
  const { t } = useTranslation('dynamicForms')
  let useLabel = label
  let usePlaceholder = placeholder

  if (name !== null) {
    if (label === null) {
      useLabel = t(`${name}Label`)
    }

    if (placeholder === null) {
      usePlaceholder = t(`${name}Placeholder`)
    }
  }

  if (required && !literal) {
    useLabel = `${useLabel} *`
  }

  return (
    <div className={wrapperClassName}>
      <Component
        value={inputStore !== null && inputStore.value}
        error={inputStore !== null && inputStore.error}
        label={useLabel}
        placeholder={usePlaceholder}
        inputRef={inputRef}
        literal={literal}
        inputStore={inputStore}
        {...props}
      />
      {inputStore && inputStore.error && inputStore.errorMessage && (
        <div className={c(styles.error, literal && styles.errorLiteral)}>
          {inputStore.errorMessage}
        </div>
      )}
    </div>
  )
}

CreationInput.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  inputStore: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  required: PropTypes.bool,
  literal: PropTypes.bool,
  wrapperClassName: PropTypes.string,
}

CreationInput.defaultProps = {
  as: Input,
  inputStore: null,
  name: null,
  label: null,
  placeholder: null,
  inputRef: null,
  required: false,
  literal: false,
  wrapperClassName: null,
}

export default observer(CreationInput)
