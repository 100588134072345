import { action, makeAutoObservable, observable, runInAction } from 'mobx'
import * as yup from 'yup'
import RuleAssetBeneficiary from './RuleAssetBeneficiary'
import RuleAssetGovernor from './RuleAssetGovernor'

class RuleAsset {
  beneficiaries = []
  governors = []
  error = null
  assetValue = null
  hasDistributionError = false

  constructor(assetId = null) {
    this.assetId = assetId

    makeAutoObservable(this, { governors: observable, addGovernor: action })
  }

  validateDistributions() {
    let count = 0

    this.beneficiaries.forEach((beneficiary) => {
      if (beneficiary.beneficiaryId) {
        if (beneficiary.distribution.isPercentage) {
          count += parseFloat(beneficiary.distribution.value.value)
        } else {
          count += (parseFloat(beneficiary.distribution.value.value) * 100) / this.assetValue
        }
      }
    })

    this.hasDistributionError = count > 100
  }

  setValue(value) {
    this.assetValue = value
  }

  setDistributionErrors() {
    this.beneficiaries.forEach((beneficiary) => {
      beneficiary.distribution.value.setError(true)
      beneficiary.distribution.type.setError(true)
    })
  }

  get hasError() {
    if (this.error) return true
    return (
      this.beneficiaries.reduce((prev, e) => {
        if (e.hasError) return true
        return prev
      }, null) || this.hasDistributionError
    )
  }

  clearErrors() {
    this.beneficiaries.forEach((beneficiary) => {
      beneficiary.distribution.value.clearError()
      beneficiary.distribution.type.clearError()
    })
  }

  async validate() {
    this.clearErrors()
    const validation = yup.string().required()
    this.validateDistributions()

    for (let i = 0; i < this.beneficiaries.length; i += 1) {
      this.beneficiaries[i].validate()
    }

    if (this.hasDistributionError) {
      this.setDistributionErrors()
    }

    try {
      await validation.validate(this.assetId)
      runInAction(() => {
        this.error = null
      })
    } catch (err) {
      runInAction(() => {
        this.error = err
      })
    }
  }

  getOptions(selectedAssets, assets) {
    const options = []

    assets.forEach((asset) => {
      const option = { id: asset.id, value: asset.name.value }

      if (!(this.assetId !== asset.id && selectedAssets && selectedAssets.includes(asset.id))) {
        options.push(option)
      }
    })

    return options
  }

  get selectedBeneficiaries() {
    return this.beneficiaries.map((e) => {
      return e.beneficiaryId
    })
  }

  get valuation() {
    let count = 0

    this.beneficiaries.forEach((beneficiary) => {
      if (beneficiary.beneficiaryId) {
        if (beneficiary.distribution.isPercentage) {
          count += (parseFloat(beneficiary.distribution.value.value) * this.assetValue) / 100
        } else {
          count += parseFloat(beneficiary.distribution.value.value)
        }
      }
    })

    return count
  }

  setAssetId(assetId) {
    this.assetId = assetId
  }

  selectAsset(assetId) {
    this.beneficiaries = []

    this.setAssetId(assetId)
    this.validate()
    this.addEmptyBeneficiary()
  }

  get parsedPercentage() {
    if (this.assetValue) {
      if ((this.assetValue - this.valuation).toFixed(2) >= 0) {
        return (this.assetValue - this.valuation).toFixed(2)
      }
    }

    return 0
  }

  addEmptyBeneficiary() {
    this.addBeneficiary(new RuleAssetBeneficiary())
  }

  addEmptyGovernor() {
    this.addGovernor(new RuleAssetGovernor())
  }

  addBeneficiary(beneficiary) {
    this.beneficiaries.push(beneficiary)
  }

  addGovernor(governor) {
    this.governors.push(governor)
  }

  removeBeneficiary(beneficiary) {
    this.beneficiaries.remove(beneficiary)
  }

  static fromJson({
    assetId = null,
    beneficiaries = [],
    governors = [],
    distributionId = null,
  } = {}) {
    const ruleAsset = new RuleAsset(assetId)

    beneficiaries.forEach((beneficiary) =>
      ruleAsset.addBeneficiary(RuleAssetBeneficiary.fromJson(beneficiary))
    )
    if (distributionId) {
      ruleAsset.distributionId = distributionId
    }

    governors.forEach((governor) => ruleAsset.addGovernor(RuleAssetGovernor.fromJson(governor)))
    return ruleAsset
  }

  getJson() {
    if (this.assetId) {
      return {
        assetId: this.assetId,
        beneficiaries: this.beneficiaries.map((beneficiary) => beneficiary.getJson()),
        governors: this.governors.map((governor) => {
          return governor.getJson()
        }),
        distributionId: this.distributionId,
      }
    }
    return {}
  }
}

export default RuleAsset
