import { makeAutoObservable } from 'mobx'
import { uniqueId } from 'lodash'
import moment from 'moment'
import InputStore from 'stores/InputStore'
import Position from './Position'
import RuleAsset from './RuleAsset'

class Rule {
  positions = []
  highlighted = false
  selected = true
  edition = true
  saved = false
  graphicalView = false
  assets = []
  status = 'draft'
  backupTitle
  backupDescription
  backupAssets

  constructor(start, end, fullText, color, target, id = null) {
    this.id = id
    this.color = color
    this.title = new InputStore()
    this.description = new InputStore()
    this.target = target
    this.isLoading = false
    this.internalId = uniqueId()
    this.vestingDate = new InputStore()
    this.isRecurrent = new InputStore()
    this.from = new InputStore()
    this.to = new InputStore()
    this.recurrencePeriodAmount = new InputStore()
    this.recurrencePeriodUnits = new InputStore()

    makeAutoObservable(this, {
      color: false,
    })
    this.addPosition(start, end, fullText)
    this.addEmptyAsset()
  }

  fillFromRule(rule) {
    this.id = rule.id
    this.color = rule.color
    this.target = rule.target
    this.title.setValue(rule.title.value)
    this.description.setValue(rule.description.value)
    this.internalId = rule.internalId

    this.vestingDate = rule.vestingDate
    this.isRecurrent = rule.isRecurrent
    this.recurrencyPeriodAmount = rule.recurrencyPeriodAmount
    this.recurrencyPeriodUnits = rule.recurrencyPeriodUnits

    this.assets = []
    this.positions = []

    rule.assets.forEach((asset) => this.addAsset(asset))

    rule.positions.forEach((position) => {
      const pos = new Position(position.start, position.end, position.text)

      this.positions.push(pos)
    })
  }

  static createFromRule(rule) {
    // creo una rule con position de mentira, luego la lleno con datos de verdad
    const newRule = new Rule(0, 1, 'a', rule.color, rule.target, rule.id)

    newRule.title.setValue(rule.title.value)
    newRule.description.setValue(rule.description.value)
    newRule.internalId = rule.internalId

    newRule.assets = []
    newRule.positions = []

    rule.assets.forEach((asset) => {
      if (asset.assetId) {
        newRule.addAsset(asset)
      }
    })

    rule.positions.forEach((position) => {
      const pos = new Position(position.start, position.end, position.text)

      newRule.positions.push(pos)
    })

    return newRule
  }

  static fromJson({
    id,
    name,
    description,
    letterType,
    paragraphs,
    color,
    letterId,
    distributions = [],
    isRecurring,
    ...rest
  }) {
    const firstPos = paragraphs[0]
    let target = `deedOfTrust_${letterId}`

    if (letterType === 'amendment') {
      target = `amendment_${letterId}`
    }

    if (letterType === 'low') {
      target = `letterOfWishes_${letterId}`
    }

    if (letterType === 'other') {
      target = `otherDocuments_${letterId}`
    }

    const rule = new Rule(
      firstPos.startPosition,
      firstPos.endPosition,
      firstPos.text,
      color,
      target,
      id
    )
    rule.title.setValue(name)
    rule.description.setValue(description)
    if (!isRecurring) {
      const { vestingDate } = rest
      if (vestingDate) {
        rule.vestingDate.setValue(moment(vestingDate))
        rule.isRecurrent.setValue(false)
      }
    } else {
      const {
        recurrenceData: { startDate, endDate, frequencyTime },
      } = rest

      const options = [
        { label: 'day', value: 'Days' },
        { label: 'month', value: 'Months' },
        { label: 'year', value: 'Years' },
      ]

      if (startDate && endDate && frequencyTime) {
        const [selectedOption] = options.filter(
          (option) => option.label === frequencyTime.timeReference
        )
        rule.from.setValue(moment(startDate))
        rule.to.setValue(moment(endDate))
        rule.recurrencePeriodAmount.setValue(frequencyTime.number)
        rule.recurrencePeriodUnits.setValue(selectedOption)
      }
    }
    rule.save()
    rule.unselect()

    rule.positions[0].text = firstPos.text

    /* @todo if multi-paragraphs are someday enable, we need to process them here and add new positions to the rule */

    rule.assets = []

    distributions.forEach((asset) => rule.addAsset(RuleAsset.fromJson(asset)))

    return rule
  }

  includesChunk(chunk) {
    return this.positions.filter((position) => position.includesChunk(chunk)).length > 0
  }

  setId(id) {
    this.id = id
  }

  highlight() {
    this.highlighted = true
  }

  setTarget(target) {
    this.target = target
  }

  dim() {
    this.highlighted = false
  }

  select() {
    this.selected = true
  }

  showGraphicalView() {
    this.graphicalView = true
  }

  hideGraphicalView() {
    this.graphicalView = false
  }

  unselect() {
    this.selected = false
    this.dim()
  }

  removePosition(position) {
    this.positions.remove(position)
  }

  save() {
    this.edition = !this.title.value
    this.saved = true
    /* this.unselect() */
  }

  setEdition() {
    this.backupTitle = this.title.value
    this.backupDescription = this.description.value
    this.backupAssets = this.assets.map((asset) => asset.getJson())
    this.edition = true
  }

  removeEdition() {
    this.edition = false
  }

  revertChangesEdition() {
    this.title.setValue(this.backupTitle)
    this.description.setValue(this.backupDescription)
    this.assets = []

    this.backupAssets.forEach((backupAsset) => {
      this.addAsset(RuleAsset.fromJson(backupAsset))
    })
  }

  addPosition(start, end, fullText) {
    // checks if new selecion overlaps with old selections and merges them up
    const overlappedPositions = this.positions.filter((position) =>
      position.includesChunk([start, end])
    )

    let positionStart = start
    let positionEnd = end

    if (overlappedPositions.length > 0) {
      overlappedPositions.forEach((position) => {
        if (position.start < positionStart) {
          positionStart = position.start
        }

        if (position.end > positionEnd) {
          positionEnd = position.end
        }

        this.removePosition(position)
      })
    }

    const text = fullText.substring(positionStart, positionEnd)
    const pos = new Position(positionStart, positionEnd, text)

    this.positions.push(pos)
  }

  setDeleted() {
    this.status = 'deleted'
  }

  setVestingDate(value) {
    this.vestingDate.setValue(value)
  }

  setFromDate(value) {
    this.from.setValue(value)
  }

  setToDate(value) {
    this.to.setValue(value)
  }

  setRecurrenceUnits(option) {
    this.recurrencePeriodUnits.setValue(option)
  }

  setRecurrenceAmount(value) {
    this.recurrencePeriodAmount.setValue(value)
  }

  toggleIsRecurrent() {
    this.isRecurrent.setValue(!this.isRecurrent.value)
  }

  resetAdvancedConfig() {
    this.vestingDate.setValue()
    this.isRecurrent.setValue()
    this.from.setValue()
    this.to.setValue()
    this.recurrencePeriodAmount.setValue()
    this.recurrencePeriodUnits.setValue()
  }

  resetRecurrence() {
    this.from.setValue()
    this.to.setValue()
    this.recurrencePeriodAmount.setValue()
    this.recurrencePeriodUnits.setValue()
  }

  resetDueDate() {
    this.vestingDate.setValue()
  }

  get isDeleted() {
    return this.status === 'deleted'
  }

  loading() {
    this.isLoading = true
  }

  notLoading() {
    this.isLoading = false
  }

  addAsset(asset) {
    this.assets.push(asset)
  }

  removeAsset(asset) {
    this.assets.remove(asset)
  }

  addEmptyAsset() {
    this.addAsset(new RuleAsset(null, this.selectedAssets))
  }

  getOptions(ruleAsset, assets) {
    const options = []
    for (let i = 0; i < assets.length; i += 1) {
      const asset = assets[i]
      const option = { id: asset.id, value: asset.name.value }

      const selectedIds = this.assets.map((e) => {
        return e.assetId
      })

      if (!(ruleAsset.assetId !== asset.id && selectedIds.includes(asset.id))) {
        options.push(option)
      }
    }
    return options
  }

  get selectedAssets() {
    return this.assets.map((e) => {
      return e.assetId
    })
  }

  get hasError() {
    return this.assets.reduce((prev, e) => {
      if (e.hasError) return true
      return prev
    }, null)
  }

  get selectedBeneficiaries() {
    const beneficiaries = []
    this.assets.forEach((asset) => {
      asset.beneficiaries.forEach((beneficiary) => {
        if (!beneficiaries.includes(beneficiary.beneficiaryId)) {
          beneficiaries.push(beneficiary.beneficiaryId)
        }
      })
    })

    return beneficiaries
  }

  async validate() {
    const result = []
    for (let i = 0; i < this.assets.length; i += 1) {
      result.push(this.assets[i].validate())
    }
    await Promise.all(result)
  }

  get isDeedOfTrust() {
    return this.target.indexOf('deedOfTrust') > -1
  }

  get isLetterOfWishes() {
    return this.target.indexOf('letterOfWishes') > -1
  }

  get isOtherDocuments() {
    return this.target.indexOf('otherDocuments') > -1
  }

  get isAmendment() {
    return this.target.indexOf('amendment') > -1
  }

  get amendmentId() {
    if (this.isAmendment) {
      return this.target.replace('amendment_', '')
    }

    return null
  }

  get letterId() {
    if (this.isAmendment) {
      return this.target.replace('amendment_', '')
    }

    if (this.isLetterOfWishes) {
      return this.target.replace('letterOfWishes_', '')
    }

    if (this.isDeedOfTrust) {
      return this.target.replace('deedOfTrust_', '')
    }

    if (this.isOtherDocuments) {
      return this.target.replace('otherDocuments_', '')
    }

    return null
  }

  get highlightElementId() {
    return `highlightRule_${this.internalId}`
  }

  get listElementId() {
    return `listRule_${this.internalId}`
  }

  get fullText() {
    return this.positions.map((position) => position.text).join('')
  }

  get type() {
    if (this.isAmendment) {
      return 'amendment'
    }

    if (this.isLetterOfWishes) {
      return 'low'
    }

    if (this.isDeedOfTrust) {
      return 'deed'
    }

    if (this.isOtherDocuments) {
      return 'other'
    }

    return null
  }

  get hasValidAssets() {
    let validAssets = false

    this.assets.forEach((asset) => {
      if (asset.assetId) {
        validAssets = true
      }
    })

    return validAssets
  }

  get hasRecurrence() {
    return !!this.isRecurrent.value
  }

  cleanUpAssets() {
    this.assets.map((asset) => {
      if (!asset.assetId) {
        this.assets.remove(asset)
      }
      return null
    })

    if (this.assets.length === 0) {
      this.addEmptyAsset()
    }
  }

  getJson() {
    this.cleanUpAssets()

    const json = {
      id: this.id,
      name: this.title.value,
      description: this.description.value,
      status: this.status,
      paragraphs: this.positions.map((position) => position.getJson()),
      distributions: this.assets.map((asset) => asset.getJson()),
      type: this.type,
      amendmentId: this.amendmentId,
    }

    if (this.isRecurrent.value && this.from.value) {
      json.isRecurring = !!this.isRecurrent.value

      const startDate = this.from.value.format('YYYY-MM-DD')
      const endDate = this.to.value.format('YYYY-MM-DD')

      json.recurrenceData = {
        startDate,
        endDate,
        frequencyTime: {
          number: this.recurrencePeriodAmount.value,
          timeReference: this.recurrencePeriodUnits.value.label,
        },
      }
    }

    if (!this.isRecurrent.value && this.vestingDate.value) {
      json.isRecurring = !!this.isRecurrent.value

      json.vestingDate = this.vestingDate.value.format()
    }

    return json
  }
}

export default Rule
