import { action, observable, reaction, computed, runInAction, makeObservable } from 'mobx'
import { DeedOfTrust, Amendment } from 'models/Letters'
import LettersBaseStore from './LettersBaseStore'

class DeedsStore extends LettersBaseStore {
  constructor(trustCreationStore) {
    super()

    this.isLoading = false
    this.initialized = false
    this.deeds = []
    this.amendments = []
    this.isDirty = false

    makeObservable(this, {
      // observables
      initialized: observable,
      deeds: observable,
      amendments: observable,
      isDirty: observable,
      // actions
      createLetter: action,
      createSecondaryLetter: action,
      initialize: action,
      initList: action,
      resetDirty: action,
      fillFromJson: action,
      restoreMainFromOldVersion: action,
      restoreSecondaryFromOldVersion: action,
      setMainFirstLevelError: action,
      clearMainFirstLevelError: action,
      validate: action,
      // computeds
      draftMainLetter: computed,
      activeMainLetter: computed,
      activeSecondaryLetter: computed,
      deedOfTrustForRules: computed,
      draftSecondaryLetter: computed,
      usableSecondaryLetters: computed,
      amendmentsForRules: computed,
    })

    this.trustCreationStore = trustCreationStore
  }

  getJson() {
    const deeds = []
    const amendments = []

    this.deeds.forEach((deed) => {
      if (!deed.isActive) {
        deeds.push(deed.getJson())
      }
    })

    this.amendments.forEach((lod) => {
      if (!lod.isActive) {
        amendments.push(lod.getJson())
      }
    })

    return {
      deeds,
      amendments,
    }
  }

  createLetter(from = null) {
    let newDeed = new DeedOfTrust()

    if (from !== null) {
      newDeed = DeedOfTrust.createFromDeedOfTrust(from)
      newDeed.clearFile()
    }

    this.deeds.push(newDeed)
  }

  createSecondaryLetter() {
    this.amendments.unshift(new Amendment())
  }

  async initialize(alternativeStatusLoad) {
    this.preRequest()

    try {
      const response = await this.trustCreationStore.trustService.getDeedsSection(
        alternativeStatusLoad,
        this.trustCreationStore.idEdition
      )

      runInAction(() => {
        this.initList(response)

        this.initialized = true

        this.onSuccessRequest()
      })

      return response
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }

    return null
  }

  initList(json) {
    if (json) {
      this.fillFromJson(json)
    }

    setTimeout(() => {
      reaction(
        () => {
          return JSON.stringify(this.getJson())
        },
        () => {
          if (!this.isDirty) {
            this.isDirty = true
          }
        },
        {
          equals: (prev, next) => prev === next && this.isDirty,
        }
      )
    })
  }

  resetDirty() {
    this.isDirty = false
  }

  fillFromJson({ deeds = [], amendments = [] }) {
    this.deeds = deeds.map((deed) => DeedOfTrust.createFromJson(deed))
    this.amendments = amendments.map((amendment) => Amendment.createFromJson(amendment))
  }

  restoreMainFromOldVersion(oldVersion) {
    this.draftMainLetter.fillFromOldVersion(oldVersion)
  }

  restoreSecondaryFromOldVersion(oldVersion) {
    this.draftSecondaryLetter.fillFromOldVersion(oldVersion)
  }

  get draftMainLetter() {
    const drafts = this.deeds.filter((deed) => deed.isDraft)

    if (drafts.length > 0) {
      return drafts[0]
    }

    return null
  }

  get activeMainLetter() {
    const actives = this.deeds.filter((deed) => deed.isActive)

    if (actives.length > 0) {
      return actives[0]
    }

    return null
  }

  get activeSecondaryLetter() {
    return this.amendments.filter((amendment) => amendment.isActive)
  }

  get deedOfTrustForRules() {
    const actives = this.deeds.filter(
      (deed) => deed.isExecution || deed.isActive || deed.isExecuted
    )

    if (actives.length === 1) {
      return actives[0]
    }
    if (actives.length > 1) {
      return actives.filter((deed) => deed.isExecution || deed.isExecuted)[0]
    }

    return null
  }

  get draftSecondaryLetter() {
    const drafts = this.amendments.filter((amendment) => amendment.isDraft)

    if (drafts.length > 0) {
      return drafts[0]
    }

    return null
  }

  get usableSecondaryLetters() {
    return this.amendments.filter((amendment) => amendment.isDraft || amendment.isActive)
  }

  get amendmentsForRules() {
    if (
      this.deeds.filter((deed) => deed.isExecution || deed.isActive || deed.isExecuted).length === 1
    ) {
      return this.amendments.filter(
        (amendment) => amendment.isExecution || amendment.isExecuted || amendment.isActive
      )
    }

    return this.amendments.filter(
      (amendment) => amendment.isExecution || amendment.isExecuted || amendment.isDraft
    )
  }

  setMainFirstLevelError() {
    this.mainFirstLevelError = true
  }

  clearMainFirstLevelError() {
    this.mainFirstLevelError = false
  }

  validate() {
    this.clearMainFirstLevelError()
    if (
      this.trustCreationStore.entityStatus.isDraft &&
      !this.draftMainLetter &&
      !this.activeMainLetter
    ) {
      this.setErrorCountMainLetter()
      this.setMainFirstLevelError()
    } else {
      this.setErrorCountMainLetter(this.validateMainLetterDraft())
    }

    this.setErrorCountSecondaryLetter(this.validateSecondaryLetterDraft())
  }
}

export default DeedsStore
