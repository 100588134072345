import React, { useContext, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import AccountIcon from 'mdi-react/AccountIcon'
import PlusCircleIcon from 'mdi-react/PlusCircleIcon'
import { Link, useHistory, Redirect } from 'react-router-dom'
import MetaTitle from 'util/MetaTitle'
import TotalDashboadCard from 'presentation/TotalDashboadCard'
import NoResultsList from 'presentation/NoResultsList'
import {
  TRUSTS_CREATION,
  CORPORATIONS_CREATION,
  FIDUCIARY_STRUCTURE_TRANSACTIONS,
  CLIENTS_LIST,
  TRUSTS_LIST,
  MAINTENANCE_PAGE,
  CORPORATIONS_LIST,
} from 'routing/routes'
import FormLabel from 'presentation/FormLabel'
import LoadingRing from 'presentation/LoadingRing'
import TransactionDashboadCard from 'presentation/TransactionDashboadCard'
import PageTitle from 'presentation/PageTitle'
import StoresContext from 'providers/storeContext'
import DashboadCard from 'presentation/DashboadCard'
import DashboardStore from 'stores/DashboardStore'
import {
  VIEW_CLIENTS,
  VIEW_TRUSTS,
  VIEW_DASHBOARD,
  VIEW_CORPORATIONS,
  CREATE_TRUST,
} from 'stores/AuthStore'
import styles from './dashboard.module.scss'

const Dashboard = () => {
  const { t } = useTranslation('dashboard')
  const [dashboardStore] = useState(() => new DashboardStore())
  const history = useHistory()
  const { authStore } = useContext(StoresContext)

  const handleTrustCreation = useCallback(() => {
    history.push(TRUSTS_CREATION)
  }, [])

  if (authStore.isMaintenancePage) {
    return (
      <Redirect
        to={{
          pathname: MAINTENANCE_PAGE,
        }}
      />
    )
  }

  if (!authStore.can(VIEW_DASHBOARD)) {
    if (authStore.can(VIEW_TRUSTS)) {
      history.push(TRUSTS_LIST)
      return <div />
    }

    if (authStore.can(VIEW_CLIENTS)) {
      history.push(CLIENTS_LIST)

      return <div />
    }

    if (authStore.can(VIEW_CORPORATIONS)) {
      history.push(CORPORATIONS_LIST)

      return <div />
    }
  }

  const { getTrusts, getTransactions, getFinancialStructures, getStats } = dashboardStore.dashboard

  if (dashboardStore.isLoading) {
    return <LoadingRing center />
  }

  return (
    <div>
      <MetaTitle pageTitle={t('pageTitles:dashboard')} />
      <PageTitle title={t('pageTitles:dashboard')} />
      <FormLabel viewMode label={t('trustRecentActivity')} />

      <div className={styles.containerTotalCards}>
        {getStats.map((stats) => {
          return <TotalDashboadCard key={stats.status} status={stats.status} count={stats.count} />
        })}
      </div>
      <div className={styles.containerLists}>
        <div className={styles.lastTrust}>
          <FormLabel viewMode label={t('lastTrusts')} />
          {getTrusts.length > 0 && authStore.can(VIEW_TRUSTS) ? (
            getTrusts.map((trust) => {
              return (
                <Link
                  key={trust.id}
                  className={styles.link}
                  to={`${TRUSTS_CREATION}?id=${trust.id}`}
                >
                  <DashboadCard
                    name={trust.name}
                    icon={<AccountIcon size={16} />}
                    status={trust.status.currentData.status}
                    updated={`${trust.updated.firstName} ${trust.updated.lastName}`}
                  />
                </Link>
              )
            })
          ) : (
            <div className={c(styles.notResult, styles.trustNotResult)}>
              {authStore.can(CREATE_TRUST) ? (
                <NoResultsList
                  topText={t('noTrustWereFound')}
                  bottomText={t('startByCreatingOne')}
                  onClick={handleTrustCreation}
                  Icon={PlusCircleIcon}
                />
              ) : (
                <NoResultsList
                  Icon="false"
                  showIcon="false"
                  topText={
                    authStore.can(VIEW_TRUSTS)
                      ? t('hasNoTrustAssigned')
                      : t('hasNoPermissions', { typeCard: 'Trusts' })
                  }
                />
              )}
            </div>
          )}

          <div className={styles.lastCorporation}>
            <FormLabel viewMode label={t('lastCorporations')} />
            {getFinancialStructures.length > 0 && authStore.can(VIEW_CORPORATIONS) ? (
              getFinancialStructures.map((corporation) => {
                return (
                  <Link
                    key={corporation.id}
                    className={styles.link}
                    to={`${CORPORATIONS_CREATION}?id=${corporation.id}`}
                  >
                    <DashboadCard
                      name={corporation.name}
                      icon={<AccountIcon size={16} />}
                      status={corporation.status.currentData.status}
                      updated={`${corporation.updated.firstName} ${corporation.updated.lastName}`}
                    />
                  </Link>
                )
              })
            ) : (
              <div className={c(styles.notResult, styles.financialStructuresNotResult)}>
                <NoResultsList
                  Icon="false"
                  showIcon="false"
                  topText={
                    authStore.can(VIEW_CORPORATIONS)
                      ? t('noCorporationWereFound')
                      : t('hasNoPermissions', { typeCard: 'Corporation' })
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.lastTransactions}>
          <FormLabel viewMode label={t('lastTransactions')} />
          {getTransactions.length > 0 ? (
            getTransactions.map((transaction) => {
              return (
                <Link
                  key={transaction.id}
                  className={styles.link}
                  to={`${FIDUCIARY_STRUCTURE_TRANSACTIONS}?id=${transaction.fiduciaryStructureId}&transactionId=${transaction.id}`}
                >
                  <TransactionDashboadCard
                    name={transaction.name}
                    status={transaction.status}
                    updated={`${transaction.updated.firstName || ''} ${
                      transaction.updated.lastName || ''
                    }`}
                  />
                </Link>
              )
            })
          ) : (
            <div className={c(styles.notResult, styles.transactionsNotResult)}>
              <NoResultsList Icon="false" showIcon="false" topText={t('noTransactionInEffect')} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(Dashboard)
