import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import * as Routes from 'routing/routes'
import { useTranslation } from 'react-i18next'
import MenuButton from 'presentation/MenuButton'
import DynamicFormSubMenu from 'presentation/DynamicFormSubMenu'
import StoresContext from 'providers/storeContext'
import CorporationChangeStatusButton from 'presentation/CorporationChangeStatusButton'
import { SubMenu, subMenuUrl, toggleNavBar } from './SubMenuUtils'

const SubMenuCorporation = observer(({ id: idCorporation, view, collapsed }) => {
  const { corporationsCreationStore, navBarStore } = useContext(StoresContext)
  const { t } = useTranslation('navBar')

  useEffect(() => {
    if (corporationsCreationStore.errorLoadEdit) {
      navBarStore.setForcedOnlyMainNavBar()
    }
  }, [corporationsCreationStore.errorLoadEdit])

  const getSubMenuUrl = (url, smIdView, smView, disabled) =>
    subMenuUrl(url, smIdView, smView, disabled, Routes.CORPORATIONS_CREATION)

  return (
    <SubMenu collapsed={collapsed}>
      <DynamicFormSubMenu
        dynamicFormsStore={corporationsCreationStore}
        sectionUrlFunction={getSubMenuUrl}
        toggleNavBar={toggleNavBar}
        entityTypeName={t('corporation')}
        entityId={idCorporation}
        collapsed={collapsed}
        view={view}
        changeStatusButton={
          <CorporationChangeStatusButton toggleNavBar={toggleNavBar} collapsed={collapsed} />
        }
        extraMenuButtons={
          corporationsCreationStore.entityStatus &&
          corporationsCreationStore.entityStatus.canViewSignedDocuments && (
            <MenuButton
              url={getSubMenuUrl(
                Routes.CORPORATIONS_CREATION_SIGNED_DOCUMENTS_SHORT,
                idCorporation,
                view
              )}
              onClick={toggleNavBar}
              disabled={corporationsCreationStore.subMenuDisabled}
              iconProps={{ size: 17 }}
              menuIconName="PenIcon"
              menuLabel={t('signedDocs')}
              errors={corporationsCreationStore.signedDocsErrors}
              collapsed={collapsed}
            />
          )
        }
      />
    </SubMenu>
  )
})

export default SubMenuCorporation
