const TYPE_CONTACT_SEARCH = 'searchContacts'
const TYPE_ENTITY_SNAPSHOT_LIST = 'entitySnapshotList'
const TYPE_GROUP_LIST = 'groupList'
const TYPE_HIDDEN = 'hidden'
const TYPE_INPUT_LIST = 'inputList'
const TYPE_ENTITY_LIST = 'entityList'
const TYPE_INPUT_TEXT = 'text'
const TYPE_INPUT_BOOL = 'bool'
const TYPE_INPUT_DATA_LIST = 'dataList'
const TYPE_INPUT_CREATABLE_DATA_LIST = 'creatableDataList'
const TYPE_INPUT_READ_ONLY = 'readOnly'
const TYPE_INPUT_DATE = 'date'
const TYPE_INPUT_TEXTAREA = 'textarea'
const TYPE_INPUT_FILE = 'file'
const TYPE_INPUT_CERTIFIED_FILE = 'certifiedFile'
const TYPE_INPUT_FILE_LIST = 'fileList'
const TYPE_INPUT_MONEY = 'money'
const TYPE_INPUT_PERCENTAGE = 'percentage'
const TYPE_INPUT_ASSET_TRUST = 'assetTrust'
const TYPE_INPUT_ASSET_COMPANY = 'assetCorporation'
const TYPE_INPUT_ENTITIES_DATA_LIST = 'entitiesDataList'
const TYPE_INPUT_PROTECTOR_CATEGORIES_DATA_LIST = 'protectorCategoryDataList'
const TYPE_LOWS = 'lowSection'
const TYPE_LODS = 'deedSection'
const TYPE_STATUTES = 'statuteSection'
const TYPE_SNAPSHOT_LOW = 'lowSnapshotBool'
const TYPE_TRUST_DOCUMENTS = 'trustDocumentsSection'

class Part {
  validationRules = []

  constructor(id, type, validationRules = '') {
    this.id = id
    this.type = type
    this.validationRules = validationRules.split('|').filter((rule) => rule !== '')
    this.readOnlyInEdition = false
  }

  setReadOnlyInEdition(value) {
    this.readOnlyInEdition = value
  }

  get isInputList() {
    return this.type === TYPE_INPUT_LIST
  }

  get isInputText() {
    return this.type === TYPE_INPUT_TEXT
  }

  get isGroupList() {
    return this.type === TYPE_GROUP_LIST
  }

  get isEntityList() {
    return this.type === TYPE_ENTITY_LIST
  }

  get isBool() {
    return this.type === TYPE_INPUT_BOOL
  }

  get isLowsSnapShot() {
    return this.type === TYPE_SNAPSHOT_LOW
  }

  get isDataList() {
    return this.type === TYPE_INPUT_DATA_LIST
  }

  get isCreatableDataList() {
    return this.type === TYPE_INPUT_CREATABLE_DATA_LIST
  }

  get isReadOnly() {
    return this.type === TYPE_INPUT_READ_ONLY
  }

  get isDate() {
    return this.type === TYPE_INPUT_DATE
  }

  get isTextarea() {
    return this.type === TYPE_INPUT_TEXTAREA
  }

  get isLows() {
    return this.type === TYPE_LOWS
  }

  get isDeeds() {
    return this.type === TYPE_LODS
  }

  get isTrustDocuments() {
    return this.type === TYPE_TRUST_DOCUMENTS
  }

  get isStatutes() {
    return this.type === TYPE_STATUTES
  }

  get isFile() {
    return this.type === TYPE_INPUT_FILE
  }

  get isCertifiedFile() {
    return this.type === TYPE_INPUT_CERTIFIED_FILE
  }

  get isFileList() {
    return this.type === TYPE_INPUT_FILE_LIST
  }

  get isMoney() {
    return this.type === TYPE_INPUT_MONEY
  }

  get isPercentage() {
    return this.type === TYPE_INPUT_PERCENTAGE
  }

  get isAssetTrust() {
    return this.type === TYPE_INPUT_ASSET_TRUST
  }

  get isAssetCompany() {
    return this.type === TYPE_INPUT_ASSET_COMPANY
  }

  get isEntitiesDataList() {
    return this.type === TYPE_INPUT_ENTITIES_DATA_LIST
  }

  get isProtectorCategoryDataList() {
    return this.type === TYPE_INPUT_PROTECTOR_CATEGORIES_DATA_LIST
  }

  get isHidden() {
    return this.type === TYPE_HIDDEN
  }

  get isEntitySnapshotList() {
    return this.type === TYPE_ENTITY_SNAPSHOT_LIST
  }

  get isContactSearch() {
    return this.type === TYPE_CONTACT_SEARCH
  }
}

export default Part
export {
  TYPE_LODS,
  TYPE_LOWS,
  TYPE_TRUST_DOCUMENTS,
  TYPE_STATUTES,
  TYPE_INPUT_LIST,
  TYPE_GROUP_LIST,
  TYPE_INPUT_TEXT,
  TYPE_ENTITY_LIST,
  TYPE_INPUT_BOOL,
  TYPE_INPUT_DATA_LIST,
  TYPE_INPUT_CREATABLE_DATA_LIST,
  TYPE_INPUT_READ_ONLY,
  TYPE_INPUT_DATE,
  TYPE_INPUT_TEXTAREA,
  TYPE_INPUT_FILE,
  TYPE_INPUT_CERTIFIED_FILE,
  TYPE_INPUT_FILE_LIST,
  TYPE_INPUT_MONEY,
  TYPE_INPUT_PERCENTAGE,
  TYPE_INPUT_ASSET_TRUST,
  TYPE_INPUT_ASSET_COMPANY,
  TYPE_INPUT_ENTITIES_DATA_LIST,
  TYPE_INPUT_PROTECTOR_CATEGORIES_DATA_LIST,
  TYPE_HIDDEN,
  TYPE_ENTITY_SNAPSHOT_LIST,
  TYPE_SNAPSHOT_LOW,
  TYPE_CONTACT_SEARCH,
}
