import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import FormLabel from 'presentation/FormLabel'
import LiteralValue from 'presentation/LiteralValue'
import styles from './input.module.scss'

const handleInputChange = (e, onChange, onlyNumeric) => {
  if (onlyNumeric && !/^-?\d*[.]?\d{0,2}$/.test(e.target.value)) {
    return false
  }

  if (e.target.value * 1 > Number.MAX_SAFE_INTEGER - 1) {
    return false
  }

  onChange(e)

  return null
}

const Input = ({
  label,
  inputRef,
  disabled,
  onChange,
  onlyNumeric,
  error,
  literal,
  value,
  icon,
  ...props
}) =>
  literal ? (
    <LiteralValue label={label} value={value} />
  ) : (
    <label className={styles.containerInput}>
      {label && <FormLabel label={label} disabled={disabled} />}
      <input
        className={c(styles.input, error && styles.error, icon && styles.hasIcon)}
        ref={inputRef}
        value={value}
        {...props}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            e.target.blur()
          }
        }}
        disabled={disabled}
        onChange={(e) => handleInputChange(e, onChange, onlyNumeric)}
      />
      {icon && <div className={styles.iconInput}>{icon}</div>}
    </label>
  )

Input.propTypes = {
  label: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  icon: PropTypes.node,
  onlyNumeric: PropTypes.bool,
  literal: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Input.defaultProps = {
  label: '',
  inputRef: null,
  icon: null,
  disabled: false,
  onChange: null,
  onlyNumeric: false,
  error: false,
  literal: false,
  value: '',
}

export default Input
