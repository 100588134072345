class DocumentActivity {
  constructor(
    activityId,
    id,
    asset,
    documents,
    entity,
    description,
    created,
    updated,
    status,
    type,
    isConfirmed
  ) {
    this.id = id
    this.asset = asset
    this.documents = documents
    this.entity = entity
    this.description = description
    this.created = created
    this.updated = updated
    this.status = status
    this.type = type
    this.activityId = activityId
    this.isConfirmed = isConfirmed
  }

  static fromJson({
    subjectAt: { id, asset, documents, entity },
    description,
    created,
    updated,
    status,
    type,
    id: activityId,
    isConfirmed,
  }) {
    return new DocumentActivity(
      activityId,
      id,
      asset,
      documents,
      entity,
      description,
      created,
      updated,
      status,
      type,
      isConfirmed
    )
  }

  get isApproved() {
    return this.status === 'approved'
  }

  get showingName() {
    return this.asset.name
  }

  get showingEntity() {
    return this.entity.name
  }
}

export default DocumentActivity
