import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class TrustParametersService {
  listParameters = () => axios.get(`${API_URL}/tenant/types`).then((response) => response.data.data)

  createParameter = (name, value) =>
    axios.post(`${API_URL}/tenant/types`, { name, value }).then((response) => response.data.data)
}

export default TrustParametersService
