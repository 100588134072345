import React, { useContext } from 'react'
import { string } from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import styles from './executionErrorsModalContent.module.scss'

const ExecutionErrorsModalContent = ({ type }) => {
  const { t } = useTranslation(`${type}Creation`)
  const { trustsCreationStore, corporationsCreationStore } = useContext(StoresContext)
  const currentStore = type === 'trusts' ? trustsCreationStore : corporationsCreationStore

  return (
    <div className={styles.modalContainer}>
      <div className={styles.message}>
        {currentStore.entityStatus.isExecuted
          ? t('executedErrorsDescription')
          : t('executionErrorsDescription')}
      </div>
      {currentStore &&
        currentStore.validationErrors.map((error, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.errorItem} key={`validation_error_${i}`}>
            {error}
          </div>
        ))}
    </div>
  )
}

ExecutionErrorsModalContent.defaultProps = {
  type: 'trusts',
}

ExecutionErrorsModalContent.propTypes = {
  type: string,
}

export default observer(ExecutionErrorsModalContent)
