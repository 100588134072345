import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InputStore from 'stores/InputStore'
import CreationInput from 'presentation/CreationInput/CreationInput'
import DatePicker from 'presentation/DatePicker'
import styles from './customActivitySearch.module.scss'

const CustomActivitySearch = ({ fromDateInputStore, setFromDate, toDateInputStore, setToDate }) => {
  const { t } = useTranslation('activity')

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.statusContainer}>
          <div className={styles.dateContainer}>
            <label>{t('ShowFrom')}</label>
            <CreationInput
              as={DatePicker}
              placeholder={t('fromDate')}
              inputStore={fromDateInputStore}
              onChange={setFromDate}
              wrapperClassName={styles.dateInputWrapper}
              maxDate={new Date()}
            />
            <label>{t('to')}</label>
            <CreationInput
              as={DatePicker}
              placeholder={t('toDate')}
              inputStore={toDateInputStore}
              onChange={setToDate}
              wrapperClassName={styles.dateInputWrapper}
              maxDate={new Date()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

CustomActivitySearch.propTypes = {
  fromDateInputStore: PropTypes.instanceOf(InputStore).isRequired,
  setFromDate: PropTypes.func.isRequired,
  toDateInputStore: PropTypes.instanceOf(InputStore).isRequired,
  setToDate: PropTypes.func.isRequired,
}

export default CustomActivitySearch
