import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import StoresContext from 'providers/storeContext'
import CloseIcon from 'mdi-react/CloseIcon'
import Button from 'presentation/Button'
import styles from './modal.module.scss'

ReactModal.setAppElement('#root')

const Modal = ({
  name,
  title,
  open,
  children,
  onSuccess,
  onCancel,
  okOnly,
  okIsLoading,
  customButtons,
  closeOutside,
  okMessage,
  marginTop,
  close,
  zIndex,
  buttonsAlignRight,
  large,
  noButtons,
  ...props
}) => {
  const { hotkeyStore } = useContext(StoresContext)
  const { t } = useTranslation('common')

  // Click en ok
  const okClick = () => {
    if (onSuccess) {
      onSuccess()
    }
  }

  // Click en cancel
  const cancelClick = () => {
    if (onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    if (open) {
      hotkeyStore.setConfig(name, [
        {
          shortcut: 'alt+s',
          handler: () => {
            okClick()
          },
        },
        {
          shortcut: 'alt+c',
          handler: () => {
            cancelClick()
          },
        },
      ])
    } else {
      hotkeyStore.removeConfig(name)
    }
  }, [open])

  const modalStyles = {
    content: { zIndex },
    overlay: { zIndex: zIndex - 1, top: marginTop },
  }

  return (
    <ReactModal
      style={modalStyles}
      isOpen={open || false}
      contentLabel={title}
      shouldCloseOnOverlayClick={closeOutside}
      onRequestClose={cancelClick}
      className={{
        base: styles.modal,
        afterOpen: styles.modalAfterOpen,
        beforeClose: styles.modalBeforeClose,
      }}
      overlayClassName={styles.overlay}
      closeTimeoutMS={300}
      {...props}
    >
      <div className={c(styles.modalContainer, large && styles.large)}>
        {close && (
          <span onClick={cancelClick} className={styles.closeButton} role="button" tabIndex="0">
            <CloseIcon />
          </span>
        )}
        {title !== null && (
          <div className={styles.modalTopBar}>
            <h2 className={styles.modalTitle}>{title}</h2>
          </div>
        )}
        {children && <div className={styles.modalContent}>{children}</div>}
        {!noButtons && (
          <div
            className={c(
              styles.modalButtons,
              customButtons.length > 2 && styles.modalButtonsCustom,
              buttonsAlignRight && styles.buttonsAlignRight
            )}
          >
            {customButtons.length > 0 ? (
              <>{customButtons.map((e) => e)}</>
            ) : (
              <>
                <Button
                  primary
                  label={okMessage}
                  onClick={okClick}
                  isLoading={okIsLoading}
                  title={`${okMessage} (alt+s)`}
                />
                {!okOnly && (
                  <Button
                    secondary
                    onClick={cancelClick}
                    label={t('common:cancel')}
                    style={{ marginLeft: 10 }}
                    title={`${t('common:cancel')} (alt+c)`}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  okOnly: PropTypes.bool,
  okMessage: PropTypes.string,
  marginTop: PropTypes.string,
  okIsLoading: PropTypes.bool,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  customButtons: PropTypes.oneOfType([PropTypes.array]),
  closeOutside: PropTypes.bool,
  buttonsAlignRight: PropTypes.bool,
  large: PropTypes.bool,
  zIndex: PropTypes.number,
  close: PropTypes.bool,
  noButtons: PropTypes.bool,
}

Modal.defaultProps = {
  title: null,
  onSuccess: null,
  onCancel: null,
  okOnly: null,
  customButtons: [],
  closeOutside: true,
  close: false,
  okMessage: 'OK',
  marginTop: '0%',
  okIsLoading: false,
  buttonsAlignRight: false,
  large: false,
  zIndex: 100,
  noButtons: false,
  open: false,
  children: null,
}

export default Modal
