import axios from 'axios'
import TransactionsAsset from 'models/TransactionsAsset'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class TrustTransactionsAssetsService {
  loadAssets = (id, type, flow = 'outflow') => {
    const structureType =
      type === 'financialStructure' ? 'corporation-structure' : 'fiduciaryStructure'

    return axios
      .get(`${API_URL}/tenant/${structureType}/${id}/financial-accounts?${flow}`)
      .then((response) => {
        return {
          assets: response.data.data.map((asset) => TransactionsAsset.fromJson(asset)),
        }
      })
  }

  loadAssetsByType = (id, type) => {
    return axios
      .get(`${API_URL}/tenant/trusts/${id}/assets?status=current&type=${type}`)
      .then((response) => response.data.data)
  }

  loadTransactionTypes = () =>
    axios
      .get(`${API_URL}/transactions/types`)
      .then((response) => ({ typeOptions: response.data.data }))

  loadAssetDetails = (assetId) =>
    axios
      .get(`${API_URL}/tenant/asset/${assetId}`)
      .then((response) => ({ assetDetails: response.data.data }))

  getMetadata = (structureType, id, assetType) =>
    axios
      .get(`${API_URL}/metadata/asset/${structureType}/${id}/${assetType}`)
      .then((response) => ({ metadata: response.data.data }))

  updateAssetValuation = (assetId, data) =>
    axios
      .post(`${API_URL}/tenant/asset/${assetId}/valuation`, data)
      .then((response) => response.data.data)

  loadVoters = (id) =>
    axios
      .get(`${API_URL}/tenant/fiduciary-structures/${id}/users`)
      .then((response) => response.data.data)
      .then((voters) =>
        voters.map((voter) => ({
          label: voter.id,
          value: `${voter.name} (${voter.selectedRoles[0].name})`,
        }))
      )

  saveTransaction = (type, data) => {
    let formatedType = type
    if (type === 'accountFunding') {
      formatedType = 'account-funding'
    }
    return axios.post(`${API_URL}/transactions/${formatedType}`, data)
  }
}

export default TrustTransactionsAssetsService
