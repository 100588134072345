import { parseValidationRules } from 'util/validation'
import Part, { TYPE_INPUT_PERCENTAGE } from './Part'
import PercentageFormPartStore from './PercentageFormPartStore'

class PercentagePart extends Part {
  constructor(id, label, validationRules, readOnlyInEdition) {
    super(id, TYPE_INPUT_PERCENTAGE)

    this.setReadOnlyInEdition(readOnlyInEdition)
    this.label = label
    this.validationRules = validationRules
  }

  static fromJson({ id, display, validationRules = null }, onCreateInput) {
    const { label = '', readOnlyInEdition = true } = display
    const input = new PercentageFormPartStore()

    if (validationRules) {
      input.setValidationRules(parseValidationRules(validationRules))
    }

    onCreateInput(id, input)

    return new PercentagePart(id, label, validationRules, readOnlyInEdition)
  }
}

export default PercentagePart
