import React, { useState, useEffect, useCallback } from 'react'
import UnauthorizedContainer from 'presentation/UnauthorizedContainer'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { LOGIN } from 'routing/routes'
import RadioButtonToggle from 'presentation/RadioButtonToggle'
import SuccessLoginMessage from 'presentation/SuccessLoginMessage'
import LoginError from 'presentation/LoginError'
import FormChangePassword from 'presentation/FormChangePassword'
import LoadingRing from 'presentation/LoadingRing'
import AuthFormLayout from 'presentation/AuthFormLayout'
import ResetPasswordUserStore from './ResetPasswordUserStore'
import styles from './resetPasswordUser.module.scss'

const ResetPasswordUser = () => {
  const { t } = useTranslation('signIn')
  const [loaded, setLoaded] = useState(false)
  const [changePasswordUserStore] = useState(() => new ResetPasswordUserStore())
  const { error, success, errorMessage, isLoading } = changePasswordUserStore

  const sendFormSubmit = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    changePasswordUserStore.send()
  }, [])

  const handleAcceptCertifyKeys = useCallback(() => {
    changePasswordUserStore.toggleCertifyKeys(true)
  }, [])

  // onMount
  useEffect(() => {
    // eslint-disable-next-line
    const params = new URLSearchParams(location.search)

    changePasswordUserStore.validateToken(params.get('token')).then(() => {
      setLoaded(true)
    })
  }, [])

  return (
    <UnauthorizedContainer title={t('pageTitles:resetPassword')}>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingRing small />
        </div>
      )}
      {error && <LoginError textBack={t('backToLogin')} route={LOGIN} message={errorMessage} />}
      {success && (
        <SuccessLoginMessage
          messageFirstLine={t('successMsgChangePassword')}
          route={LOGIN}
          textButton={t('backToLogin')}
        />
      )}
      {(!error || errorMessage) && loaded && !success && (
        <AuthFormLayout title={t('titleResetPassword')}>
          <div className={styles.containerResetPassword}>
            <FormChangePassword
              isLoading={changePasswordUserStore.isLoading}
              setPasswordStore={changePasswordUserStore.setPasswordStore}
              additionalContent={
                <RadioButtonToggle
                  italic
                  onChange={handleAcceptCertifyKeys}
                  label={t('userProfile:certifyKeysLabel')}
                  checked={changePasswordUserStore.certifyKeys.value}
                  error={changePasswordUserStore.certifyKeys.error}
                />
              }
              submit={sendFormSubmit}
            />
          </div>
        </AuthFormLayout>
      )}
    </UnauthorizedContainer>
  )
}

export default observer(ResetPasswordUser)
