import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import EntityStatus from 'models/EntityStatus'
import ToolTip from 'presentation/ToolTip'
import styles from './statusName.module.scss'

const StatusName = ({ status, alternativeStatus, onlyBullet }) => {
  const { t } = useTranslation('trustsCreation')
  return (
    <div className={c(styles.statusContainer, onlyBullet && styles.onlyBullet)}>
      <span
        className={c(
          styles.bullet,
          status.isDraft && styles.statusDraft,
          status.isTerminated && styles.statusTerminated,
          status.isExecution && styles.statusExecution,
          status.isExecuted && styles.statusExecuted,
          status.isPendingToApproval && styles.statusPending,
          status.isStored && styles.statusStored
        )}
        title={t(status.status)}
      />
      {alternativeStatus && alternativeStatus.status ? (
        <>
          <ToolTip tooltipContent={t(`${alternativeStatus.status}VersionInProgress`)}>
            <span className={styles.dottedUnderline}>{t(status.status)}</span>
          </ToolTip>
          <span
            className={c(
              styles.alternativeBullet,
              alternativeStatus.isDraft && styles.statusDraft,
              alternativeStatus.isTerminated && styles.statusTerminated,
              alternativeStatus.isExecution && styles.statusExecution,
              alternativeStatus.isExecuted && styles.statusExecuted,
              alternativeStatus.isStored && styles.statusStored
            )}
            title={t(`${alternativeStatus.status}VersionInProgress`)}
          />
        </>
      ) : (
        <div className={c(styles.status)}>{t(status.status)}</div>
      )}
    </div>
  )
}

StatusName.propTypes = {
  status: PropTypes.instanceOf(EntityStatus),
  onlyBullet: PropTypes.bool,
  alternativeStatus: PropTypes.instanceOf(EntityStatus),
}

StatusName.defaultProps = {
  status: new EntityStatus(),
  alternativeStatus: null,
  onlyBullet: false,
}

export default observer(StatusName)
