import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Button from 'presentation/Button'
import styles from './activityDetailsActionButtons.module.scss'

const ActivityDetailsActionButtons = ({ handleGoBack }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.buttonsContainer}>
      <Button
        label={t('back')}
        secondary
        icon={<ArrowLeftIcon />}
        iconPositionLeft
        style={{ marginRight: '15px' }}
        onClick={handleGoBack}
      />
    </div>
  )
}

ActivityDetailsActionButtons.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
}

export default ActivityDetailsActionButtons
