import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import c from 'classnames'
import { VOTE_CLOSE } from 'stores/AuthStore'
import Button from 'presentation/Button'
import Vote from 'models/Vote'
import VoteStatus from 'presentation/VoteStatus'
import HandLeftIcon from 'mdi-react/HandLeftIcon'
import styles from './voteItem.module.scss'

const VoteItem = ({ vote, handleResolveVoting, handleViewVote }) => {
  const { t } = useTranslation('transactionsCreation')
  const resolveVotingHandler = useCallback(() => {
    handleResolveVoting(vote)
  }, [])

  const ViewVoteHandle = useCallback(() => {
    handleViewVote(vote)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} onClick={ViewVoteHandle} role="button" tabIndex="0">
        <div>
          {vote.lastUpdate && (
            <div className={c(styles.date, vote.status.status === 'stored' && styles.dateStored)}>
              <span className={c(styles.status, styles.spanInformation)}>{vote.status.status}</span>
              <span className={styles.spanInformation}>{t('on')}</span>
              <span className={styles.spanInformation}>
                {vote.lastUpdate.format(t('common:humanDateDayFormat'))}
              </span>
              <span className={styles.spanInformation}>{t('by')}</span>
              <span className={styles.spanInformation}>{vote.updatedByFirstName}</span>
              <span className={styles.spanInformation}>{vote.updatedByLastName}</span>
            </div>
          )}
          <div className={styles.itemName}>{vote.title.value}</div>
          {vote.status.status === 'pending' && (
            <Button
              small
              smallest
              transparent
              iconPositionLeft
              permission={VOTE_CLOSE}
              label={t('resolveVoting')}
              className={styles.votingIcon}
              onClick={resolveVotingHandler}
              icon={<HandLeftIcon size={15} />}
            />
          )}
        </div>
        <div>
          <VoteStatus status={vote.status.status} />
        </div>
      </div>
    </div>
  )
}

VoteItem.propTypes = {
  vote: PropTypes.instanceOf(Vote).isRequired,
  handleResolveVoting: PropTypes.func.isRequired,
  handleViewVote: PropTypes.func.isRequired,
}

export default VoteItem
