import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './noRules.module.scss'

const NoRules = ({ isFiltered }) => {
  const { t } = useTranslation('rulesCreation')
  return (
    <div className={styles.container}>
      <div className={styles.text}>{isFiltered ? t('noFilteredRules') : t('noRulesFound')}</div>
    </div>
  )
}

NoRules.propTypes = {
  isFiltered: PropTypes.number,
}

NoRules.defaultProps = {
  isFiltered: 0,
}

export default NoRules
