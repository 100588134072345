import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import StoresContext from 'providers/storeContext'
import { Part } from 'models/FormMetadata'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'

const DynamicFormPartBoolLowsSnapshot = ({ part }) => {
  const { trustsCreationStore } = useContext(StoresContext)
  const { lettersStore } = trustsCreationStore

  const hasLows = lettersStore.lows.length > 0

  return <YesNoToggleContainer desc={part.label} value={hasLows} viewMode />
}

DynamicFormPartBoolLowsSnapshot.propTypes = {
  part: PropTypes.instanceOf(Part).isRequired,
}

export default observer(DynamicFormPartBoolLowsSnapshot)
