import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import RuleAssetBeneficiaryDistribution from './RuleAssetBeneficiaryDistribution'

class RuleAssetBeneficiary {
  constructor(id = null, initialValue, contactId) {
    this.beneficiaryId = id
    this.contactId = contactId
    this.distribution = new RuleAssetBeneficiaryDistribution('$', initialValue)
    this.error = null

    makeAutoObservable(this)
  }

  get hasError() {
    return !!this.error
  }

  async validate() {
    const validation = yup.string().required()

    try {
      await validation.validate(this.beneficiaryId)
      runInAction(() => {
        this.error = null
      })
    } catch (err) {
      runInAction(() => {
        this.error = err
      })
    }
  }

  getOptions(selectedBeneficiaries, beneficiaries) {
    const options = []

    beneficiaries.forEach((beneficiary) => {
      const option = {
        id: beneficiary.id,
        value: beneficiary.name.value,
        contactId: beneficiary.contactId,
      }

      if (
        !(
          this.beneficiaryId !== beneficiary.id &&
          selectedBeneficiaries &&
          selectedBeneficiaries.includes(beneficiary.id)
        )
      ) {
        options.push(option)
      }
    })

    return options
  }

  setBeneficiaryId(beneficiaryId, value, contactId) {
    if (beneficiaryId !== this.beneficiaryId) {
      this.distribution.setValue(0)
      this.beneficiaryId = beneficiaryId
      this.contactId = contactId
      this.distribution.setType('$')
      this.distribution.setValue(value())
      this.validate()
    }
  }

  static fromJson({ beneficiaryId = null, distribution = null, contactId = null }) {
    const ruleAssetBeneficiary = new RuleAssetBeneficiary(beneficiaryId, null, contactId)

    if (distribution !== null) {
      ruleAssetBeneficiary.distribution = RuleAssetBeneficiaryDistribution.fromJson(distribution)
    }

    return ruleAssetBeneficiary
  }

  getJson() {
    return {
      beneficiaryId: this.beneficiaryId,
      contactId: this.contactId,
      distribution: this.distribution.getJson(),
    }
  }
}

export default RuleAssetBeneficiary
