import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Rule from 'models/Rule'
import RuleAssetGraphicalView from 'presentation/RuleAssetGraphicalView'

const RuleGraphicalView = observer(({ rule, trustBeneficiaries, trustAssets }) => {
  return (
    <div>
      {rule.assets.map((ruleAsset, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`ruleAssetGraphicalView_${i}`}>
          {ruleAsset.assetId ? (
            <RuleAssetGraphicalView
              ruleAsset={ruleAsset}
              assets={trustAssets}
              beneficiaries={trustBeneficiaries}
              governors={ruleAsset.governors}
            />
          ) : null}
        </div>
      ))}
    </div>
  )
})

RuleGraphicalView.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default RuleGraphicalView
