import ListDate from 'models/ListDate'
import ClientStatus from 'models/ClientStatus'
import ListClientStructure from '../ListClientStructure'

class ListClient {
  constructor(id, name, created, updated, dataUpdated, type, status, structures) {
    this.id = id
    this.name = name
    this.created = created
    this.updated = updated
    this.dataUpdated = dataUpdated
    this.type = type
    this.status = new ClientStatus(status)
    this.structures = structures
  }

  get isMultipleStructures() {
    return this.structures.length > 1
  }

  get isAssetCorporation() {
    return this.structures.length === 1 && this.structures[0].type === 'assetCorporation'
  }

  get isAssetTrust() {
    return this.structures.length === 1 && this.structures[0].type === 'assetTrust'
  }

  get hasStructures() {
    return this.structures.length > 0
  }

  get isIndividual() {
    return this.type === 'individual'
  }

  get isCompany() {
    return this.type === 'company'
  }

  static fromJson({ id, name, created, updated, dataUpdated, type, status, structures }) {
    return new ListClient(
      id,
      name,
      ListDate.fromJson(created),
      ListDate.fromJson(updated),
      ListDate.fromJson(dataUpdated),
      type,
      status.currentData.status,
      structures.map((structure) => ListClientStructure.fromJson(structure))
    )
  }
}

export default ListClient
