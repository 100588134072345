import { observable, action, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import AuthService from 'services/AuthService'
import SetPasswordStore from 'stores/SetPasswordStore'
import InputStore from 'stores/InputStore'

class ResetPasswordUserStore extends AsyncStore {
  token = ''
  error = false
  success = false
  certifyKeys = new InputStore()
  errorMessage = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      token: observable,
      error: observable,
      success: observable,
      certifyKeys: observable,
      errorMessage: observable,
      // actions
      validateToken: action,
      setToken: action,
      toggleCertifyKeys: action,
      validate: action,
      send: action,
    })

    this.authService = new AuthService()
    this.isLoading = false
    this.setPasswordStore = new SetPasswordStore()
  }

  validateToken(token) {
    if (!token) {
      this.error = true
      return Promise.reject()
    }

    this.preRequest()

    // Validar token con endpoint
    return this.authService
      .validateToken(token)
      .then(() => {
        this.setToken(token)
        runInAction(() => {
          this.onSuccessRequest()
        })
      })
      .catch((e) => {
        this.error = true
        this.onErrorRequest(e)
      })
  }

  setToken(token) {
    this.token = token
  }

  toggleCertifyKeys() {
    this.certifyKeys.setValue(!this.certifyKeys.value)
  }

  validate() {
    this.setPasswordStore.validatePassword()

    if (!this.certifyKeys.value) {
      this.certifyKeys.setError(true)
    }
  }

  async send() {
    this.validate()
    if (!this.setPasswordStore.hasError && !this.certifyKeys.error) {
      this.preRequest()
      this.error = false
      this.errorMessage = false

      try {
        await this.authService.changePassword(
          this.token,
          this.setPasswordStore.password.value,
          this.setPasswordStore.repeatPassword.value
        )

        runInAction(() => {
          this.success = true

          this.onSuccessRequest()
        })
      } catch (e) {
        runInAction(() => {
          this.success = false
          this.error = true
          this.errorMessage = e.response.data.error.message
          this.onErrorRequest(e)
        })
      }
    }
  }
}

export default ResetPasswordUserStore
