const DEFAULT_OPTIONS = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h24',
  timeZoneName: 'longOffset',
}

const dateFormat = (value, options = DEFAULT_OPTIONS) => {
  return value.toLocaleDateString('en-EN', options).replace(/,/g, '')
}

export default dateFormat
