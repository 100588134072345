import { makeAutoObservable } from 'mobx'
import InputStore from 'stores/InputStore'
import { v4 as uuidv4 } from 'uuid'

const TYPE_PERCENTAGE = '%'
const TYPE_VALUE = '$'

class RuleAssetBeneficiaryDistribution {
  constructor(type = TYPE_VALUE, value = '0', id = null) {
    this.id = id || uuidv4()
    this.type = new InputStore()
    this.value = new InputStore()

    makeAutoObservable(this)

    this.type.setValue(type)
    this.value.setValue(value)
  }

  setId(id) {
    this.id = id
  }

  setType(type) {
    this.type.setValue(type)
  }

  setValue(value) {
    this.value.setValue(value)
  }

  get isPercentage() {
    return this.type.value === TYPE_PERCENTAGE
  }

  get isValue() {
    return this.type.value === TYPE_VALUE
  }

  static fromJson({ id = null, type = null, value = null }) {
    return new RuleAssetBeneficiaryDistribution(type, value, id)
  }

  getJson() {
    return {
      id: this.id,
      type: this.type.value,
      value: this.value.value,
    }
  }
}

export default RuleAssetBeneficiaryDistribution
export { TYPE_PERCENTAGE, TYPE_VALUE }
