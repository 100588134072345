const isIOS = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
}

const isAndroid = () => {
  const ua = navigator.userAgent.toLowerCase()

  return ua.indexOf('android') > -1
}

const isMobile = () => {
  return isIOS() || isAndroid()
}

export default isMobile
export { isAndroid, isIOS }
