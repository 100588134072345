import React, { useContext, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import FreezeModalList, { FreezeModalItem } from 'presentation/FreezeModal'
import StoresContext from 'providers/storeContext'
import styles from './freezeModalContent.module.scss'

const FreezeModalContent = () => {
  const { t } = useTranslation('corporationsCreation')
  const { corporationsCreationStore } = useContext(StoresContext)
  const toggleFreezeStatute = useCallback(() => corporationsCreationStore.toggleFreezeStatute(), [])
  const draftStatute = corporationsCreationStore.statutesStore.draftMainLetter
  const { hasAlternativeStatus } = corporationsCreationStore
  let statuteFreezeRow = null

  if (draftStatute) {
    statuteFreezeRow = (
      <FreezeModalItem label={`${t('statute')} - ${draftStatute.title.value}`} disabled />
    )

    if (hasAlternativeStatus) {
      statuteFreezeRow = (
        <FreezeModalItem
          label={`${t('statute')} - ${draftStatute.title.value}`}
          selected={corporationsCreationStore.freezeStatute}
          handleClick={toggleFreezeStatute}
        />
      )
    }
  }

  return (
    <div className={styles.freezeModalContainer}>
      <div className={styles.message}>{t('executionModalDescription')}</div>
      <FreezeModalList>
        <FreezeModalItem label={t('trustInformation')} disabled />
        {statuteFreezeRow}
      </FreezeModalList>
    </div>
  )
}

export default observer(FreezeModalContent)
