import FundingTransactionStore from './FundingTransactionStore'

class AccountFundingTransactionStore extends FundingTransactionStore {
  constructor(id) {
    super(id, 'accountFunding')

    this.id = id
  }
}

export default AccountFundingTransactionStore
