import Part, { TYPE_INPUT_CERTIFIED_FILE } from './Part'
import FileFormPartStore from './FileFormPartStore'

class CertifiedFilePart extends Part {
  constructor(id, label, alwaysVisible, validationRules) {
    super(id, TYPE_INPUT_CERTIFIED_FILE)

    this.label = label
    this.validationRules = validationRules
    this.alwaysVisible = alwaysVisible
  }

  static fromJson({ id, display, alwaysVisible = false, validationRules = null }, onCreateInput) {
    const { label = '' } = display

    onCreateInput(id, new FileFormPartStore())

    return new CertifiedFilePart(id, label, alwaysVisible, validationRules)
  }
}

export default CertifiedFilePart
