import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import c from 'classnames'
import styles from './voterStatus.module.scss'

const VoterStatus = observer(({ status, t }) => {
  return (
    <>
      {status === 'pending' ? (
        <div className={styles.inProcess}>{t('inProcess')}</div>
      ) : (
        <div className={c(styles.statusContainer)}>
          <span
            className={c(
              styles.bullet,
              status === 'rejected' && styles.statusRejected,
              status === 'abstain' && styles.statusAbstain,
              status === 'approved' && styles.statusPositive
            )}
            title={t(status.votingStatus)}
          />
          <div className={c(styles.status)}>{t(status)}</div>
        </div>
      )}
    </>
  )
})

VoterStatus.propTypes = {
  status: PropTypes.string.isRequired,
  onlyBullet: PropTypes.bool,
}

export default withTranslation('transactionsCreation')(VoterStatus)
