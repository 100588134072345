import { makeAutoObservable } from 'mobx'
import InputStore from 'stores/InputStore'

class Percentage {
  constructor() {
    this.value = new InputStore()
    this.error = false
    this.errorMessage = ''

    makeAutoObservable(this)
  }

  changeValue(value) {
    // eslint-disable-next-line no-restricted-globals
    if (value === '' || (!isNaN(value) && value >= 0)) {
      this.value.setValue(value)
      this.clearError()
    }
  }

  fillFromJson({ value }) {
    this.changeValue(value)
  }

  get percentageValue() {
    return `${this.value.value} %`
  }

  getPercentageValue() {
    return `${this.value.value} %`
  }

  get json() {
    return {
      value: this.value.value,
    }
  }

  setValue(value) {
    this.value.setValue(value.value)
  }

  setError(error, errorMessage = '') {
    this.error = error
    this.errorMessage = errorMessage
  }

  clearError() {
    this.error = false
    this.errorMessage = ''
  }
}

export default Percentage
