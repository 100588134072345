import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Part } from 'models/FormMetadata'
import AssetTrustForm from 'presentation/AssetTrustForm'

const DynamicFormPartAssetTrust = ({ readOnly, part, inputs }) => {
  const [store] = useState(inputs.get(part.id))

  return <AssetTrustForm store={store} part={part} inputs={inputs} readOnly={readOnly} />
}

DynamicFormPartAssetTrust.propTypes = {
  readOnly: PropTypes.bool,
  part: PropTypes.instanceOf(Part).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

DynamicFormPartAssetTrust.defaultProps = {
  readOnly: true,
}

export default observer(DynamicFormPartAssetTrust)
