import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import MetaTitle from 'util/MetaTitle'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'

const ClientCreationTitle = () => {
  const { t } = useTranslation('clientsCreation')
  const { clientsCreationStore } = useContext(StoresContext)
  let pageTitle = t('titleCreation')

  if (clientsCreationStore.isViewMode) {
    pageTitle = t('titleViewName', { name: clientsCreationStore.entityName })
  } else if (clientsCreationStore.isEdition) {
    pageTitle = t('titleEditionName', { name: clientsCreationStore.entityName })
  }

  return <MetaTitle pageTitle={pageTitle} />
}

export default observer(ClientCreationTitle)
