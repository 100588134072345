import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import PlusIcon from 'mdi-react/PlusIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import moment from 'moment'
import FileSearchOutlineIcon from 'mdi-react/FileSearchOutlineIcon'
import { observer } from 'mobx-react'
import FormSelect from 'presentation/FormSelect'
import CreationInput from 'presentation/CreationInput'
import LoadingRing from 'presentation/LoadingRing'
import { useTranslation } from 'react-i18next'
import LiteralValue from 'presentation/LiteralValue'
import Transaction from 'models/Transaction'
import Vote from 'models/Vote'
import Modal from 'scenes/Modal'
import Button from 'presentation/Button/Button'
import styles from './multipleSelectTransactionsForm.module.scss'

const MultipleSelectPollsForm = ({
  vote,
  isLoading,
  isEdition,
  inputsSelect,
  viewTransaction,
  handleCreateSelect,
  handleRemoveSelect,
  transactionsOptions,
  handleChangeTransaction,
  showTransactionViewModal,
  hideTransactionViewModal,
  visibleViewTransactionModal,
}) => {
  const { t } = useTranslation('transactionsCreation')

  if (isLoading) {
    return <LoadingRing center />
  }

  useEffect(() => {
    if (inputsSelect.length === 0) {
      handleCreateSelect()
    }
  }, [])

  const options = transactionsOptions.filter((option) => {
    let found = false
    inputsSelect.forEach((inputSelect) => {
      if (inputSelect.value && inputSelect.value !== '' && inputSelect.value.id === option.id) {
        found = true
      }
    })

    return !found
  })

  return (
    <>
      {isEdition ? (
        <div className={styles.container}>
          {inputsSelect.map((input, i) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`key_${i}`}
                className={c(styles.containerSelect, input.error && styles.centerIcon)}
              >
                <CreationInput
                  as={FormSelect}
                  inputStore={input}
                  label={t('poll')}
                  placeholder={t('selectTransaction')}
                  onChange={(option) => handleChangeTransaction(option, input)}
                  options={options.map((e) => ({
                    id: e.id,
                    value: `${e.name.value}`,
                  }))}
                  isClearable
                />
                {i === inputsSelect.length - 1 ? (
                  <>
                    {isEdition && (
                      <div className={styles.buttonAdd}>
                        <Button onClick={() => handleCreateSelect()} circle icon={<PlusIcon />} />
                      </div>
                    )}
                  </>
                ) : (
                  <div className={styles.buttonAdd}>
                    <Button onClick={() => handleRemoveSelect(input)} circle icon={<CloseIcon />} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <>
          {vote.transactions.value.map((transaction) => {
            let dateTransaction = ''
            dateTransaction = moment(transaction.date).add(1, 'day').format('DD-MM-YYYY')

            return (
              <div
                key={`transaction_${transaction.id}`}
                className={styles.containerTransactionsLiteral}
              >
                <LiteralValue
                  borderBottom
                  value={transaction.name}
                  label={t('poll')}
                  icon={<FileSearchOutlineIcon size={20} />}
                  handleClick={() => showTransactionViewModal(transaction)}
                />
                <Modal
                  okOnly
                  marginTop="-22%"
                  name="viewTransaction"
                  title={t('viewTransactionModal')}
                  open={visibleViewTransactionModal}
                  onSuccess={hideTransactionViewModal}
                >
                  <div className={styles.containerLiteralModal}>
                    <div className={styles.containerLiteral}>
                      <LiteralValue
                        borderBottom
                        value={viewTransaction.name}
                        label={t('transaction')}
                      />
                    </div>
                    <div className={styles.containerLiteral}>
                      <LiteralValue borderBottom value={dateTransaction} label={t('dateLabel')} />
                    </div>
                    <div className={styles.containerLiteral}>
                      <LiteralValue
                        borderBottom
                        value={
                          viewTransaction.additionalData !== undefined
                            ? viewTransaction.additionalData.description.value
                            : ''
                        }
                        label={t('descriptionLabel')}
                      />
                    </div>
                    <div className={styles.containerStatus}>
                      <div className={styles.containerStatustitleLiteralStatus}>{t('status')}</div>
                      <div className={c(styles.statusContainer)}>
                        <span
                          className={c(
                            styles.bullet,
                            viewTransaction.status === 'voting' && styles.statusAbstain,
                            viewTransaction.status === 'pending' && styles.statusAbstain,
                            viewTransaction.status === 'rejected' && styles.statusRejected,
                            viewTransaction.status === 'approved' && styles.statusPositive
                          )}
                        />
                        <div className={c(styles.status)}>{t(viewTransaction.status)}</div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

MultipleSelectPollsForm.propTypes = {
  isEdition: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  vote: PropTypes.instanceOf(Vote).isRequired,
  handleCreateSelect: PropTypes.func.isRequired,
  handleRemoveSelect: PropTypes.func.isRequired,
  handleChangeTransaction: PropTypes.func.isRequired,
  showTransactionViewModal: PropTypes.func.isRequired,
  hideTransactionViewModal: PropTypes.func.isRequired,
  visibleViewTransactionModal: PropTypes.bool.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  inputsSelect: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  viewTransaction: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array])).isRequired,
  transactionsOptions: PropTypes.arrayOf(PropTypes.instanceOf(Transaction)).isRequired,
}

MultipleSelectPollsForm.defaultProps = {
  isEdition: false,
}

export default observer(MultipleSelectPollsForm)
