import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { USERS_LIST } from 'routing/routes'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import imageError from './imageError.png'

const RoleSectionError = () => {
  const { t } = useTranslation('users')
  const history = useHistory()
  const handleGoBack = useCallback(() => history.push(USERS_LIST))

  return (
    <GenericNotFoundError
      description={t('userSectionErrorDesc')}
      textButton={t('userSectionErrorButton')}
      goBackFunction={handleGoBack}
      title={t('userSectionErrorTitle')}
      image={imageError}
    />
  )
}

export default RoleSectionError
