import axios from 'axios'
import { API_URL } from 'services/config'
import ListRole from 'models/ListRole'

/* eslint-disable class-methods-use-this */
class RolesService {
  listRoles = (page, search = null, orderParam = null, orderDir = null) =>
    axios
      .get(
        `${API_URL}/roles?page=${page}&sort[${orderParam || 'updatedAt'}]=${
          orderDir || 'desc'
        }&search=${search || ''}`
      )
      .then((response) => ({
        roles: response.data.data.map((listRole) => ListRole.fromJson(listRole)),
        pagination: response.data.pagination,
      }))

  listPermissions = () =>
    axios
      .get(`${API_URL}/permissions`)
      .then((response) => response.data.data.map((listPermission) => listPermission))

  addRole = (json) => axios.post(`${API_URL}/roles`, json).then((response) => response.data.data)

  saveRole = (id, json) =>
    axios.put(`${API_URL}/roles/${id}`, json).then((response) => response.data.data)

  getRole = (id) => axios.get(`${API_URL}/roles/${id}`).then((response) => response.data.data)

  deleteRole = (id) => axios.delete(`${API_URL}/roles/${id}`).then((response) => response.data.data)
}

export default RolesService
