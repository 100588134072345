import { useContext } from 'react'
import StoresContext from 'providers/storeContext'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'presentation/PageTitle'
import SignedDocumentsEdit from './SignedDocumentsEdit'
import SignedDocumentsView from './SignedDocumentsView'

const SignedDocumentsSection = () => {
  const { t } = useTranslation('signedDocuments')
  const { corporationsCreationStore } = useContext(StoresContext)

  return (
    <>
      <PageTitle title={t('signedDocuments')} />
      {corporationsCreationStore.entityStatus.isExecuted ? (
        <SignedDocumentsEdit />
      ) : (
        <SignedDocumentsView />
      )}
    </>
  )
}

export default observer(SignedDocumentsSection)
