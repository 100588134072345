import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import TrustsCreationStore from 'stores/TrustsCreationStore'
import CloudAlertIcon from 'mdi-react/CloudAlertIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import { EDIT_TRUST } from 'stores/AuthStore'
import Button from 'presentation/Button'
import styles from './trustCreationActionButtons.module.scss'

const TrustCreationActionButtons = ({
  trustsCreationStore,
  handleSave,
  handleCancel,
  handleEdit,
  handleGoBack,
}) => {
  const { t } = useTranslation('trustsCreation')
  if (!trustsCreationStore.visibleActionButtons) {
    return null
  }

  return (
    <div className={styles.buttonsContainer}>
      {trustsCreationStore.showSaveToContinue && (
        <div className={styles.showSaveToContinue}>{t('saveToContinue')}</div>
      )}
      {trustsCreationStore.isViewMode ? (
        <>
          <Button
            secondary
            label={t('common:back')}
            onClick={handleGoBack}
            title={t('common:back')}
            style={{ marginRight: '15px' }}
            icon={<ArrowLeftIcon />}
            iconPositionLeft
          />
          {trustsCreationStore.entityStatus.canBeEdited && (
            <Button
              permission={EDIT_TRUST}
              label={t('editTrust')}
              title={t('editTrust')}
              onClick={handleEdit}
              primary
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.saveButtonContainer}>
            <div className={c(styles.unsetChanges, trustsCreationStore.isDirty ? styles.show : '')}>
              <CloudAlertIcon />
              <span>{t('unsavedLabel')}</span>
            </div>
            <Button
              isLoading={trustsCreationStore.loadingSave}
              title={`${t(`saveTrust`)} (alt+s)`}
              disabled={!trustsCreationStore.canSave}
              style={{ marginRight: '15px' }}
              label={t('saveTrust')}
              permission={EDIT_TRUST}
              onClick={handleSave}
              primary
              big
            />
          </div>
          <Button secondary big label={t('common:cancel')} onClick={handleCancel} />
        </>
      )}
    </div>
  )
}

TrustCreationActionButtons.propTypes = {
  trustsCreationStore: PropTypes.instanceOf(TrustsCreationStore).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
}

export default observer(TrustCreationActionButtons)
