import ListDate from 'models/ListDate'

class ListContact {
  constructor(
    id,
    name,
    personalEmail,
    companyEmail,
    firstName,
    lastName,
    isClient,
    created,
    updated,
    type,
    wishesToBeContacted
  ) {
    this.id = id
    this.name = name
    this.personalEmail = personalEmail
    this.companyEmail = companyEmail
    this.firstName = firstName
    this.lastName = lastName
    this.isClient = isClient
    this.created = created
    this.updated = updated
    this.type = type
    this.wishesToBeContacted = wishesToBeContacted
  }

  get isIndividual() {
    return this.type === 'individual'
  }

  get isCompany() {
    return this.type === 'company'
  }

  get createName() {
    let name
    if (this.type === 'company') {
      name = this.name
    }
    if (this.type === 'individual') {
      name = `${this.firstName} ${this.lastName}`
    }
    return name
  }

  get createEmail() {
    let email
    if (this.type === 'company') {
      email = this.companyEmail
    }
    if (this.type === 'individual') {
      email = this.personalEmail
    }
    return email
  }

  static fromJson({ id, created, updated, data }) {
    return new ListContact(
      id,
      data.name,
      data.personalEmail,
      data.companyEmail,
      data.firstName,
      data.lastName,
      data.isClient,
      ListDate.fromJson(created),
      ListDate.fromJson(updated),
      data.type,
      data.wishesToBeContacted === '' ? false : data.wishesToBeContacted
    )
  }
}

export default ListContact
