import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StoresContext from 'providers/storeContext'
import InputStore from 'stores/InputStore'
import screenSizesMatches from 'util/screen'
import FormSelect from 'presentation/FormSelect'
import CreationInput from 'presentation/CreationInput'
import numberHelper from 'util/numberHelper'
import styles from './dynamicFormPartMoney.module.scss'

const FormMoney = ({
  currencyStore,
  valueStore,
  label,
  onChangeValue,
  onChangeCurrency,
  onBlur,
  literal,
  inputRef,
}) => {
  const { t } = useTranslation('dynamicForms')
  const { creationParametersStore } = useContext(StoresContext)

  const handleChangeValue = (e) => {
    valueStore.setValue(e.target.value)
    if (onChangeValue) onChangeValue()
  }

  const handleChangeCurrency = (value) => {
    currencyStore.setValue(value)
    if (onChangeCurrency) onChangeCurrency()
  }

  const handleOnBlur = () => {
    if (onBlur) onBlur()
  }

  return (
    <div className={styles.valueCurrencyContainer}>
      <CreationInput
        inputStore={valueStore}
        value={literal ? numberHelper(valueStore.value) : valueStore.value}
        label={label}
        placeholder={t('placeholderText', { name: label })}
        onChange={handleChangeValue}
        literal={literal}
        inputRef={inputRef}
        onlyNumeric
        onBlur={handleOnBlur}
      />
      <CreationInput
        as={FormSelect}
        placeholder={t('select')}
        label="Currency"
        inputStore={currencyStore}
        onChange={handleChangeCurrency}
        options={creationParametersStore.getOptions('currencies')}
        mergeStyles={
          !screenSizesMatches('xs') && {
            control: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          }
        }
        literal={literal}
        onBlur={handleOnBlur}
      />
      {valueStore.error && <div className={styles.error}>{valueStore.errorMessage}</div>}
    </div>
  )
}

FormMoney.propTypes = {
  onBlur: PropTypes.func,
  label: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  literal: PropTypes.bool,
  onChangeValue: PropTypes.func,
  onChangeCurrency: PropTypes.func,
  currencyStore: PropTypes.instanceOf(InputStore).isRequired,
  valueStore: PropTypes.instanceOf(InputStore).isRequired,
}

FormMoney.defaultProps = {
  literal: '',
  onBlur: null,
  label: false,
  inputRef: null,
  onChangeValue: null,
  onChangeCurrency: null,
}

export default observer(FormMoney)
