import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import PageTitle from 'presentation/PageTitle'
import MetaTitle from 'util/MetaTitle'
import HistoryVersionElement from 'presentation/HistoryVersionElement'
import TrustHistoryVersionsStore from './TrustHistoryVersionsStore'

const TrustHistoryVersions = () => {
  const { t } = useTranslation('history')
  const { search } = useLocation()
  const [trustId] = new URLSearchParams(search).getAll('id')
  const [trustHistoryVersionsStore] = useState(() => new TrustHistoryVersionsStore(trustId))

  useEffect(() => {
    trustHistoryVersionsStore.loadVersions()
  }, [])

  return (
    <div>
      <MetaTitle pageTitle={t('versions')} />
      <PageTitle title={t('versions')} />
      <div>
        {trustHistoryVersionsStore.visibleVersions &&
          trustHistoryVersionsStore.visibleVersions.map((version) => (
            <HistoryVersionElement
              version={version}
              trustId={trustId}
              key={version.updated.at.format(t('common:humanDateTimeYearFormat'))}
            />
          ))}
      </div>
    </div>
  )
}

export default observer(TrustHistoryVersions)
