import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'scenes/Modal'
import Button from 'presentation/Button'

const NewDraftModal = ({
  open,
  createNewDraftFromCurrent,
  createNewDraftFromScratch,
  onCancel,
  letterTypeName,
  showRulesAlert,
}) => {
  const { t } = useTranslation('lettersForms')

  return (
    <Modal
      name="newDraftModal"
      title={t('confirmNewDraft', { letterTypeName })}
      open={open}
      onCancel={onCancel}
      customButtons={[
        <Button
          key="fromCurrent"
          label={t('fromCurrent', { letterTypeName })}
          onClick={createNewDraftFromCurrent}
          primary
        />,
        <Button
          key="fromScratch"
          label={t('fromScratch')}
          onClick={createNewDraftFromScratch}
          secondary
        />,
        <Button key="cancelDraft" label={t('common:cancel')} onClick={onCancel} />,
      ]}
      okMessage={t('fromScratch')}
      cancelMessage={t('fromCurrent', { letterTypeName })}
      closeOutside
    >
      {showRulesAlert && t('creatingDraftRulesWarning', { letterTypeName })}
    </Modal>
  )
}

NewDraftModal.propTypes = {
  open: PropTypes.bool.isRequired,
  createNewDraftFromCurrent: PropTypes.func.isRequired,
  createNewDraftFromScratch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  letterTypeName: PropTypes.string.isRequired,
  showRulesAlert: PropTypes.bool,
}

NewDraftModal.defaultProps = {
  showRulesAlert: true,
}

export default NewDraftModal
