import React, { useContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import { FilePart, CertifiedFilePart } from 'models/FormMetadata/Parts'
import CreationInput from 'presentation/CreationInput'
import InputFile from 'presentation/InputFile'
import RadioButtonToggle from 'presentation/RadioButtonToggle'
import styles from './dynamicFormPartFile.module.scss'

const DynamicFormPartFile = ({
  part,
  inputs,
  readOnly,
  showLegalCertified,
  autoHideLegalCertified,
}) => {
  const { t } = useTranslation('dynamicForms')
  const { currentDynamicStore } = useContext(StoresContext)
  const [inputStore] = useState(inputs.get(part.id).store)
  const { name, legalCertified } = inputStore
  const handleChangeCertifiedDocument = useCallback(
    () => inputStore.changeLegalCertified(!inputStore.legalCertified),
    []
  )
  const handleStartUploadingFile = useCallback(() => currentDynamicStore.startUploadingFile(), [])
  const handleFinishUploadingFile = useCallback(() => currentDynamicStore.finishUploadingFile(), [])

  return (
    <div className={styles.wrapper}>
      <CreationInput
        as={InputFile}
        fileStore={inputStore}
        label={part.label}
        placeholder={t('placeholderFile', { name: part.label })}
        literal={readOnly}
        handleStartUploadingFile={handleStartUploadingFile}
        handleFinishUploadingFile={handleFinishUploadingFile}
      />
      {showLegalCertified && (!autoHideLegalCertified || name !== null) && (
        <RadioButtonToggle
          onChange={handleChangeCertifiedDocument}
          label={t('uploadDocumentCertification')}
          checked={legalCertified}
          italic
          literal={readOnly}
        />
      )}
    </div>
  )
}

DynamicFormPartFile.propTypes = {
  part: PropTypes.oneOfType([
    PropTypes.instanceOf(FilePart),
    PropTypes.instanceOf(CertifiedFilePart),
  ]).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  showLegalCertified: PropTypes.bool,
  autoHideLegalCertified: PropTypes.bool,
}

DynamicFormPartFile.defaultProps = {
  readOnly: true,
  showLegalCertified: false,
  autoHideLegalCertified: false,
}

export default observer(DynamicFormPartFile)
