import { observable, makeObservable } from 'mobx'
import LettersSectionSecondaryBaseStore from 'stores/LettersSectionSecondaryBaseStore'

class LetterOfDistributionSectionStore extends LettersSectionSecondaryBaseStore {
  constructor(lettersStore) {
    super()

    this.lettersStore = lettersStore

    makeObservable(this, {
      lettersStore: observable,
    })
  }
}

export default LetterOfDistributionSectionStore
