import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class ChangePasswordService {
  changePassword = (currentPassword, newPassword, repeatPassword) =>
    axios
      .post(`${API_URL}/tenant/auth/changePassword`, {
        old_password: currentPassword,
        password: newPassword,
        password_confirmation: repeatPassword,
      })
      .then((response) => response.data.data)
}

export default ChangePasswordService
