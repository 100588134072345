import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import PageTitle from 'presentation/PageTitle'
import { TRUSTS_LIST, TRUSTS_ASSETS, TRUSTS_CREATION } from 'routing/routes'
import { useTranslation } from 'react-i18next'
import NoResultsList from 'presentation/NoResultsList'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import LinkButton from 'presentation/LinkButton'
import ServerError from 'presentation/ServerError'
import ButtonBackListTrust from 'presentation/ButtonBackListTrust'
import LoadingRing from 'presentation/LoadingRing'
import ValuationAssets from 'presentation/ValuationAssets'
import CreationInput from 'presentation/CreationInput'
import DatePicker from 'presentation/DatePicker'
import TrustsValuationsStore from './TrustsValuationsStore'
import styles from './trustsValuations.module.scss'

const TrustsValuations = ({ location }) => {
  const { t } = useTranslation('trustsValuations')
  const history = useHistory()
  const [trustsValuationsStore] = useState(() => new TrustsValuationsStore())

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    trustsValuationsStore
      .init(params.get('id'))
      .then(() => trustsValuationsStore.getTrustValuations())
  }, [])

  const tryAgain = useCallback(() => trustsValuationsStore.tryAgain(), [])

  const handleGoBack = useCallback(() => history.push(TRUSTS_LIST), [])

  const handleGoToTrust = useCallback(
    () => history.push(`${TRUSTS_ASSETS}?id=${trustsValuationsStore.id}&v=true`),
    []
  )

  if (trustsValuationsStore.isLoading) {
    return <LoadingRing center />
  }

  if (trustsValuationsStore.noIdError) {
    return (
      <GenericNotFoundError
        description={t('noIdErrorDesc')}
        textButton={t('noIdErrorButton')}
        goBackFunction={handleGoBack}
        title={t('noIdErrorTitle')}
      />
    )
  }

  if (trustsValuationsStore.serverError) {
    return <ServerError handleTryAgain={tryAgain} />
  }

  const handleChangeDate = (date) => {
    trustsValuationsStore.setDateInput(date)
    trustsValuationsStore.getTrustValuations()
  }

  return (
    <>
      <LinkButton
        styleButton={styles.subtitle}
        text={trustsValuationsStore.trustName ? trustsValuationsStore.trustName : ''}
        icon={<ArrowRightIcon size={15} />}
        route={`${TRUSTS_CREATION}?id=${trustsValuationsStore.id}&v=true`}
      />
      <PageTitle title={t('trustsValuationsTitle')} floating />
      <div className={styles.inputContainer}>
        <div className={styles.InputSideLabel}>{t('fromDate')}</div>
        <CreationInput
          as={DatePicker}
          placeholder={t('selectDate')}
          inputStore={trustsValuationsStore.dateInput}
          onChange={handleChangeDate}
          wrapperClassName={styles.dateInputWrapper}
        />
      </div>
      {!trustsValuationsStore.hasValidValuations ? (
        <NoResultsList
          topText={t('noValuations')}
          buttonText={t('noValuationsButton')}
          onClick={handleGoToTrust}
        />
      ) : (
        <div className={styles.valuations}>
          {!!trustsValuationsStore.valuablesCount && (
            <ValuationAssets
              valuations={trustsValuationsStore.valuables}
              title={t('valuableAssetsTitle', { number: trustsValuationsStore.valuablesCount })}
            />
          )}
          {!!trustsValuationsStore.invaluablesCount && (
            <ValuationAssets
              valuations={trustsValuationsStore.invaluables}
              title={t('invaluableAssetsTitle', {
                number: trustsValuationsStore.invaluablesCount,
              })}
            />
          )}
          {!!trustsValuationsStore.misvaluedCount && (
            <ValuationAssets
              valuations={trustsValuationsStore.misvalued}
              title={t('misvaluedAssetsTitle', {
                number: trustsValuationsStore.misvaluedCount,
              })}
            />
          )}
        </div>
      )}
      <ButtonBackListTrust id={trustsValuationsStore.id} />
    </>
  )
}

TrustsValuations.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  location: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default observer(TrustsValuations)
