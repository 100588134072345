import React, { useEffect, useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { validatePart } from 'util/validation'
import { observer } from 'mobx-react'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import { InstanceEntity, EntityListPart } from 'models/FormMetadata'
import Button from 'presentation/Button'
import StoresContext from 'providers/storeContext'
import CreateEntityModalButtons from './CreateEntityModalButtons'
import CreateEntityModalForm from './CreateEntityModalForm'

const CreateEntityModal = ({
  setButtons,
  editionInstanceEntity,
  readOnly,
  handleRemoveViewMode,
  canRemoveViewMode,
  handleCancel,
  handleAddEntity,
  part,
}) => {
  const { t } = useTranslation('dynamicForms')
  const [isEdition] = useState(editionInstanceEntity !== null)
  const [instanceEntity, setInstanceEntity] = useState(editionInstanceEntity)
  const [isLast, setIsLast] = useState(false)
  const elementRef = useRef()
  const { hotkeyStore } = useContext(StoresContext)

  const handleCancelButton = () => {
    setInstanceEntity(null)
    handleCancel()
  }

  const handleSave = () => {
    setInstanceEntity(null)
    handleAddEntity(instanceEntity)
  }

  const handleOkButton = () => {
    if (elementRef) {
      if (isEdition || isLast) {
        handleSave() // solo graba en el caso de edicion el wizard o último tab
      } else {
        elementRef.current.onSuccessTab() // Mueve el wizard
      }
    }
  }

  const buildButtons = () => {
    const buttons = []

    if (instanceEntity) {
      const label = isEdition || isLast ? 'ok' : 'next'

      if (canRemoveViewMode) {
        buttons.push(
          <Button
            icon={label === 'next' ? <ArrowRightIcon size={18} /> : null}
            label={readOnly ? t(`common:edit`) : t(`common:${label}`)}
            title={readOnly ? t(`common:edit`) : `${t(`common:${label}`)} (alt+s)`}
            style={{ marginRight: '15px' }}
            onClick={() => {
              if (readOnly) {
                if (handleRemoveViewMode) {
                  handleRemoveViewMode()
                }
              } else {
                handleOkButton()
              }
            }}
            primary
            small
          />
        )
      }

      buttons.push(
        <Button
          label={readOnly ? t('common:close') : t('common:cancel')}
          onClick={handleCancelButton}
          secondary
          small
        />
      )
    }

    setButtons(buttons)
  }

  const configureHotKeys = () => {
    hotkeyStore.setConfig('createEditEntity', [
      {
        shortcut: 'alt+s',
        handler: () => {
          handleOkButton()
        },
      },
    ])
  }

  const createEntity = (createdEntity) => {
    setInstanceEntity(createdEntity.createInputInstance())
  }

  const showEntitySelection = () => {
    setInstanceEntity(null)
  }

  const handleOnChangeTab = (active, last) => {
    setIsLast(last)

    // valido cada parte anterior al avanzar en un tab
    if (active - 1 >= 0) {
      validatePart(instanceEntity.parts[active - 1], instanceEntity.inputs)
    }
  }

  // configure hot keys after first mount
  useEffect(() => {
    configureHotKeys()

    return () => hotkeyStore.removeConfig('createEditEntity')
  }, [])

  // validates entity if it's edition
  useEffect(() => {
    if (isEdition) {
      instanceEntity.validate()
    }
  }, [instanceEntity])

  // builds button if entity changes, or last tab is reached
  useEffect(() => {
    buildButtons()
  }, [instanceEntity, isLast])

  if (!instanceEntity) {
    return <CreateEntityModalButtons part={part} createEntity={createEntity} />
  }

  return (
    <CreateEntityModalForm
      onGoBack={isEdition ? null : showEntitySelection}
      instanceEntity={instanceEntity}
      onTabChangeTab={handleOnChangeTab}
      onTabFinish={handleSave}
      readOnly={readOnly}
      ref={elementRef}
    />
  )
}

CreateEntityModal.contextType = StoresContext

CreateEntityModal.propTypes = {
  part: PropTypes.instanceOf(EntityListPart),
  handleRemoveViewMode: PropTypes.func,
  setButtons: PropTypes.func,
  handleAddEntity: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  canRemoveViewMode: PropTypes.bool,
  editionInstanceEntity: PropTypes.instanceOf(InstanceEntity),
}

CreateEntityModal.defaultProps = {
  part: null,
  readOnly: true,
  editionInstanceEntity: null,
  canRemoveViewMode: true,
  handleRemoveViewMode: null,
  setButtons: () => {},
}

export default observer(CreateEntityModal)
