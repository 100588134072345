import Part, { TYPE_INPUT_BOOL } from './Part'
import FormPartStore from './FormPartStore'

class BoolPart extends Part {
  constructor(id, label, validationRules, readOnly, defaultValue, showWarning, warningId) {
    super(id, TYPE_INPUT_BOOL)

    this.label = label
    this.validationRules = validationRules
    this.readOnly = readOnly
    this.defaultValue = defaultValue
    this.showWarning = showWarning
    this.warningId = warningId
  }

  static fromJson({ id, display, validationRules = null }, onCreateInput) {
    const {
      label = '',
      readOnly = false,
      defaultValue = false,
      showWarning = false,
      warningId = null,
    } = display

    const initializedFormPartStore = new FormPartStore()
    initializedFormPartStore.store.setValue(defaultValue)

    onCreateInput(id, initializedFormPartStore)

    return new BoolPart(id, label, validationRules, readOnly, defaultValue, showWarning, warningId)
  }
}
export default BoolPart
