import { observable, action, computed, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'

class LettersBaseStore extends AsyncStore {
  errorsCountMainLetter = 0
  errorsCountSecondaryLetter = 0
  mainFirstLevelError = false
  secondaryFirstLevelError = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      errorsCountMainLetter: observable,
      errorsCountSecondaryLetter: observable,
      mainFirstLevelError: observable,
      secondaryFirstLevelError: observable,
      // actions
      validateMainLetterDraft: action,
      clearFirstLevelError: action,
      validateSecondaryLetterDraft: action,
      clearErrors: action,
      setErrorCountMainLetter: action,
      setErrorCountSecondaryLetter: action,
      // computeds
      isValid: computed,
      totalErrors: computed,
      mainLetterErrors: computed,
      secondaryLetterErrors: computed,
    })

    this.clearErrors()
  }

  validateMainLetterDraft() {
    const { draftMainLetter } = this
    let errors = 0

    if (draftMainLetter) {
      errors += draftMainLetter.validateEmptyFields()
    }

    return errors
  }

  clearFirstLevelError() {
    this.mainFirstLevelError = false
    this.secondaryFirstLevelError = false
  }

  validateSecondaryLetterDraft() {
    const { draftSecondaryLetter } = this
    let errors = 0

    if (draftSecondaryLetter) {
      errors += draftSecondaryLetter.validateEmptyFields()
    }

    return errors
  }

  clearErrors() {
    this.clearFirstLevelError()
    this.setErrorCountMainLetter(0)
    this.setErrorCountSecondaryLetter(0)
  }

  setErrorCountMainLetter(value = 1) {
    this.errorsCountMainLetter = value
  }

  setErrorCountSecondaryLetter(value = 1) {
    this.errorsCountSecondaryLetter = value
  }

  get isValid() {
    return this.errorsCountMainLetter === 0 && this.errorsCountSecondaryLetter === 0
  }

  get totalErrors() {
    return this.errorsCountMainLetter + this.errorsCountSecondaryLetter
  }

  get mainLetterErrors() {
    return this.errorsCountMainLetter
  }

  get secondaryLetterErrors() {
    return this.errorsCountSecondaryLetter
  }
}

export default LettersBaseStore
