import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import LoadingRing from 'presentation/LoadingRing'
import GoverningLaw from 'models/GoverningLaw'
import GoverningLawSelectList from '../GoverningLawSelectList'
import GoverningLawStore from '../GoverningLawStore'
import styles from './governingLawItem.module.scss'

const GoverningLawItem = ({
  governingLaw,
  governingLawStore,
  handleChangeTrustEnabled,
  handleChangeCorporationEnabled,
}) => {
  if (!governingLaw) {
    return <LoadingRing center />
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.itemName}>{governingLawStore.name.value}</div>
        <div className={styles.containerSelectList}>
          <GoverningLawSelectList
            hasCheckbox
            handleChangeTrustEnabled={handleChangeTrustEnabled}
            trustEnabled={governingLawStore.governingLaw.trustEnabled}
            handleChangeCorporationEnabled={handleChangeCorporationEnabled}
            corporationEnabled={governingLawStore.governingLaw.corporationEnabled}
          />
        </div>
      </div>
    </div>
  )
}

GoverningLawItem.propTypes = {
  governingLawStore: PropTypes.instanceOf(GoverningLawStore).isRequired,
  handleChangeCorporationEnabled: PropTypes.func.isRequired,
  handleChangeTrustEnabled: PropTypes.func.isRequired,
  governingLaw: PropTypes.instanceOf(GoverningLaw).isRequired,
}

export default observer(GoverningLawItem)
