import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './dockScroller.module.scss'

/* eslint-disable class-methods-use-this */
class DockScroller extends React.Component {
  refWrapper

  refInner

  constructor(props) {
    super(props)
    this.state = {
      topInner: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleOnResizeWindow)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleOnResizeWindow)
  }

  handleOnResizeWindow = () => {
    this.setState({ topInner: 0 })
  }

  handleOnMouseMove = (e) => {
    const diff = Math.max(0, this.refInner.clientHeight - this.refWrapper.clientHeight)
    const mousePosition = e.pageY - this.refWrapper.getBoundingClientRect().top - window.scrollY
    const ratio = mousePosition / this.refWrapper.clientHeight

    let top = 0

    if (diff) {
      top = Math.round(diff * ratio) * -1
      this.setState({ topInner: top })
    }
  }

  render() {
    const { children, className, ...restOfProps } = this.props
    const { topInner } = this.state

    return (
      <div
        onMouseMove={this.handleOnMouseMove}
        className={c(styles.wrapper, className)}
        ref={(ele) => {
          this.refWrapper = ele
        }}
        {...restOfProps}
      >
        <div
          className={styles.inner}
          ref={(ele) => {
            this.refInner = ele
          }}
          style={{
            transform: `translate(0, ${topInner}px)`,
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

DockScroller.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

DockScroller.defaultProps = {
  className: undefined,
}

export default DockScroller
