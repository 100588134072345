import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CalendarIcon from 'mdi-react/CalendarIcon'
import isMobile from 'util/browser'
import DatePickerComponent from 'react-datepicker'
import FormLabel from 'presentation/FormLabel'
import c from 'classnames'
import LiteralValue from 'presentation/LiteralValue'
import styles from './datePicker.module.scss'
import 'react-datepicker/dist/react-datepicker.css'

const DatePicker = ({
  label,
  value,
  placeholder,
  inputRef,
  disabled,
  onChange,
  literal,
  maxDate,
  error,
  ...props
}) => {
  const { t } = useTranslation('common')
  let PickerComponent = null

  if (literal) {
    return (
      <LiteralValue label={label} value={value !== '' ? value.format(t('dateFormat')) : null} />
    )
  }

  if (isMobile()) {
    const inputEl = useRef(null)

    // needed for clear button on safari ios
    useEffect(() => {
      inputEl.current.defaultValue = ''

      if (inputRef) {
        inputRef(inputEl)
      }
    })

    PickerComponent = (
      <label className={styles.iosInput}>
        {(value === '' || value === null) && (
          <span className={styles.iosPlaceholder}>{placeholder}</span>
        )}
        <input
          type="date"
          className={c(styles.input, error && styles.error)}
          value={value !== '' ? value.toISOString().slice(0, 10) : ''}
          ref={inputEl}
          max={maxDate !== null ? maxDate.toISOString().split('T')[0] : null}
          onChange={(e) => {
            let val = ''

            if (typeof e === 'string') {
              val = e
            } else {
              val = e.target.value
            }

            onChange(val !== null && val !== '' ? moment(val) : '')

            // hack for clear button on safari ios
            const { target } = e
            setTimeout(() => {
              target.defaultValue = ''
            }, 100)
          }}
        />
      </label>
    )
  } else {
    PickerComponent = (
      <DatePickerComponent
        className={c(styles.input, error && styles.error)}
        selected={value !== '' ? value.toDate() : null}
        placeholderText={placeholder}
        ref={inputRef}
        disabled={disabled}
        shouldCloseOnSelect
        scrollableYearDropdown
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
          },
        }}
        onChange={(val) => {
          onChange(val !== null && val !== '' ? moment(val) : '')
        }}
        maxDate={maxDate}
        yearDropdownItemNumber={100}
        {...props}
      />
    )
  }

  return (
    <div>
      {label && <FormLabel label={label} disabled={disabled} />}
      <div className={styles.pickerContainer}>
        {PickerComponent}
        <CalendarIcon size={18} className={styles.pickerIcon} />
      </div>
    </div>
  )
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  literal: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  maxDate: PropTypes.any,
  error: PropTypes.bool,
}

DatePicker.defaultProps = {
  label: '',
  value: '',
  onChange: null,
  inputRef: null,
  disabled: false,
  literal: false,
  maxDate: null,
  error: false,
}

export default DatePicker
