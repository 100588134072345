import { makeAutoObservable } from 'mobx'

const STATUS_DRAFT = 'draft'
const STATUS_VOTING = 'voting'
const STATUS_APPROVED = 'approved'
const STATUS_REJECTED = 'rejected'
const STATUS_CANCEL = 'cancel'
const STATUS_FINISHED = 'finished'
const STATUS_STORED = 'stored'

class TransactionStatus {
  constructor(status = STATUS_DRAFT) {
    this.status = null

    makeAutoObservable(this)

    this.setStatus(status)
  }

  setStatus(status) {
    this.status = status
  }

  get isDraft() {
    return this.status === STATUS_DRAFT
  }

  get isVoting() {
    return this.status === STATUS_VOTING
  }

  get isApproved() {
    return this.status === STATUS_APPROVED
  }

  get isRejected() {
    return this.status === STATUS_REJECTED
  }

  get isCancel() {
    return this.status === STATUS_CANCEL
  }

  get isFinish() {
    return this.status === STATUS_FINISHED
  }

  get isStored() {
    return this.status === STATUS_STORED
  }

  get canBeModified() {
    return !(this.isFinish || this.isVoting)
  }
}

const resultOptions = [STATUS_APPROVED, STATUS_REJECTED]

export default TransactionStatus
export { resultOptions }
