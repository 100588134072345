import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Rule from 'models/Rule'
import Button from 'presentation/Button'
import RuleNameExpansor from 'presentation/RuleNameExpansor'
import RuleAssetsForm from 'presentation/RuleAssetsForm'
import StoresContext from 'providers/storeContext'
import styles from './ruleAssetsCreation.module.scss'

const RuleAssetsCreation = ({ rule, setButtons, handleSave, handleCancel }) => {
  const { t } = useTranslation('common')
  const { hotkeyStore } = useContext(StoresContext)

  const save = () => {
    rule.validate().then(() => {
      if (!rule.hasError) {
        handleSave()
      }
    })
  }

  useEffect(() => {
    const buttons = []

    buttons.push(
      <Button
        label={t('save')}
        style={{ marginRight: '15px' }}
        title={`${t(`common:save`)}`}
        primary
        small
        onClick={save}
      />
    )

    buttons.push(<Button label={t('common:cancel')} onClick={handleCancel} secondary small />)
    setButtons(buttons)

    hotkeyStore.setConfig(
      'RuleAssetCreation',
      [
        {
          shortcut: 'alt+s',
          handler: () => save(),
        },
      ],
      1
    )

    // unmount
    return () => hotkeyStore.removeConfig('RuleAssetCreation')
  }, [])

  return (
    <div className={styles.container}>
      <RuleNameExpansor rule={rule} />
      <RuleAssetsForm rule={rule} />
    </div>
  )
}

RuleAssetsCreation.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  setButtons: PropTypes.func,
}

RuleAssetsCreation.defaultProps = {
  setButtons: () => {},
}

export default RuleAssetsCreation
