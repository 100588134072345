import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useHistory } from 'react-router'
import Button from 'presentation/Button'
import LoadingRing from 'presentation/LoadingRing'
import FolderDownloadOutlineIcon from 'mdi-react/FolderDownloadOutlineIcon'
import { useTranslation } from 'react-i18next'
import imageError from 'presentation/TrustSectionError/imageError.png'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import { TRUSTS_CREATION } from 'routing/routes'
import RequestErrors from 'presentation/RequestErrors'
import ServerError from 'presentation/ServerError'
import DownloadZipStore from './DownloadZipStore'
import styles from './downloadZip.module.scss'

const DownloadZip = observer(() => {
  const { t } = useTranslation('trustsCreation')
  const history = useHistory()
  const location = useLocation()
  const [downloadZipStore] = useState(() => new DownloadZipStore())
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    downloadZipStore.init(params.get('id'), params.get('file'))
  }, [])

  const handleClickDownload = useCallback(() => {
    downloadZipStore.downloadZip()
  }, [])

  const handleClickGoToTrust = useCallback(() => {
    history.push(`${TRUSTS_CREATION}?id=${params.get('id')}&v=true`)
  }, [])

  const handleClickGoToSite = useCallback(() => {
    history.push(`/`)
  }, [])

  const handleTryAgain = useCallback(() => downloadZipStore.tryAgain(), [])

  if (downloadZipStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  return (
    <>
      <div className={styles.container}>
        {!downloadZipStore.isLoading ? (
          <div>
            {downloadZipStore.noIdError ? (
              <GenericNotFoundError
                description={t('trustSectionErrorDesc')}
                textButton={t('trustSectionErrorButton')}
                goBackFunction={handleClickGoToSite}
                title={t('trustSectionErrorTitle')}
                image={imageError}
              />
            ) : (
              <div className={styles.downloadContainer}>
                <div className={styles.title}>
                  {t('downloadZipTitle', { trustName: downloadZipStore.trustName })}
                </div>
                <div className={styles.subtitle}>{t('downloadZipSubtitle')}</div>
                <Button
                  onClick={handleClickDownload}
                  icon={<FolderDownloadOutlineIcon size={20} />}
                  label={t('downloadZipButtonLabel')}
                  className={styles.button}
                  isLoading={downloadZipStore.loadingDownload}
                />
                <Button
                  onClick={handleClickGoToTrust}
                  label={t('goToTrust')}
                  className={styles.goToTrustButton}
                  primary
                />
                {downloadZipStore.downloadError && (
                  <RequestErrors errors={[t('downloadZipError')]} />
                )}
              </div>
            )}
          </div>
        ) : (
          <LoadingRing />
        )}
      </div>
    </>
  )
})

DownloadZip.propTypes = {}

export default DownloadZip
