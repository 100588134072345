import InputStore from 'stores/InputStore'
import PartStore from './PartStore'

class EntitiesDataListFormPartStore extends PartStore {
  constructor() {
    super(new InputStore())
  }

  fill(data) {
    this.store.setValue(data)
  }

  createFromCurrent() {
    const newFormPartStore = new EntitiesDataListFormPartStore()

    newFormPartStore.fill(this.store.value)
    newFormPartStore.setValidationRules(this.validationRules)

    return newFormPartStore
  }

  get json() {
    return this.store.value
  }
}

export default EntitiesDataListFormPartStore
