import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import ChevronUpIcon from 'mdi-react/ChevronUpIcon'
import Rule from 'models/Rule'
import styles from './ruleNameExpansor.module.scss'

const RuleNameExpansor = ({ rule }) => {
  const [collapsed, setCollapsed] = useState(true)
  const toggleCollapse = () => setCollapsed(!collapsed)

  return (
    <div className={styles.container}>
      <div
        className={c(styles.title, !collapsed && styles.titleCollapsed)}
        onClick={toggleCollapse}
        role="button"
        tabIndex="0"
      >
        <div className={styles.text}>{rule.title.value}</div>
        {collapsed ? (
          <span>
            <ChevronDownIcon size={25} />
          </span>
        ) : (
          <span>
            <ChevronUpIcon size={25} />
          </span>
        )}
      </div>
      <div className={c(styles.collapseSection, collapsed && styles.collapsed)}>
        {rule.description.value && (
          <div className={styles.description}>
            <span className={styles.descIcon}>
              <MessageTextOutlineIcon size={15} />
            </span>
            <div className={styles.pre}>{rule.description.value}</div>
          </div>
        )}
        <div className={styles.ruleFullText}>
          <div className={styles.pre}>{`"${rule.fullText.trim()}"`}</div>
        </div>
      </div>
    </div>
  )
}

RuleNameExpansor.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
}

export default RuleNameExpansor
