import axios from 'axios'
import moment from 'moment'
import { API_URL } from 'services/config'
import ActiveEntity from 'models/ActiveEntity'

/* eslint-disable class-methods-use-this */
class ActivitiesService {
  listActiveEntities = (
    sortField = 'createdAt',
    sortDirection = 'desc',
    activePage = '1',
    statusFilter = null,
    from = null,
    to = null
  ) => {
    const params = {}
    if (sortField && sortDirection) {
      params[`?sort[${sortField}]`] = sortDirection
    }

    if (activePage) {
      params.page = activePage
    }

    if (statusFilter) {
      params[`&status[]`] = statusFilter
    }

    if (from) {
      params.from = moment(from).format('YYYY-MM-DD')
    }

    if (to) {
      params.to = moment(to).format('YYYY-MM-DD')
    }

    return axios.get(`${API_URL}/tenant/structures/activities`, params).then((response) => {
      return {
        activeEntities: response.data.data.map((entity) => {
          return ActiveEntity.fromJson(entity)
        }),
        pagination: response.data.pagination,
      }
    })
  }
}

export default ActivitiesService
