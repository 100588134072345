import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import AlertOutlineIcon from 'mdi-react/AlertOutlineIcon'
import Tippy from '@tippy.js/react'
import LiteralValue from 'presentation/LiteralValue'
import YesNoToggle from 'presentation/YesNoToggle'
import styles from './yesNoToggleContainer.module.scss'

const YesNoToggleContainer = ({
  value,
  desc,
  handleChange,
  viewMode,
  simple,
  showWarning,
  warningId,
}) => {
  const { t } = useTranslation('common')
  if (viewMode) {
    return <LiteralValue label={desc} value={value ? t('yes') : t('no')} />
  }

  const tooltip = (
    <>
      <div className={styles.tooltip}>{t(warningId)}</div>
    </>
  )

  return (
    <div className={c(styles.yesNoToggle, simple && styles.simple)}>
      <div className={styles.labelWrapper}>
        <div>{desc}</div>
        {showWarning && warningId && (
          <Tippy placement="bottom" content={tooltip}>
            <div className={styles.warningIcon}>
              <AlertOutlineIcon size={18} />
            </div>
          </Tippy>
        )}
      </div>
      <YesNoToggle
        value={value}
        onToggle={handleChange}
        inactiveLabel={t('common:no')}
        activeLabel={t('common:yes')}
        literal={viewMode}
      />
    </div>
  )
}

YesNoToggleContainer.propTypes = {
  desc: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  viewMode: PropTypes.bool,
  simple: PropTypes.bool,
  showWarning: PropTypes.bool,
  warningId: PropTypes.string,
}

YesNoToggleContainer.defaultProps = {
  handleChange: () => {},
  viewMode: false,
  simple: false,
  showWarning: false,
  warningId: null,
}

export default YesNoToggleContainer
