import { successToast } from 'helpers/Toast'
import { TRUSTS_CREATION } from 'routing/routes'

const changeNextTrustStatus = (trustsCreationStore, toastText = null, reload = true, history) => {
  trustsCreationStore.changeNextStatus(() => {
    if (toastText) {
      successToast(toastText, null, 'changedTrustStatus').show()
    }

    if (reload) {
      const redirectRoute = `${TRUSTS_CREATION}${trustsCreationStore.entitySections[0].route}`
      history.push(`${redirectRoute}?id=${trustsCreationStore.idEdition}`)

      trustsCreationStore.reloadData()
    }
  })
}

const changeBackTrustStatus = (trustsCreationStore, toastText = null, reload = true, history) => {
  trustsCreationStore.successRollBackModal(() => {
    if (toastText) {
      successToast(toastText, null, 'changedTrustStatus').show()
    }

    if (reload) {
      trustsCreationStore.reloadData(() => {
        const redirectRoute = `${TRUSTS_CREATION}${trustsCreationStore.entitySections[0].route}`
        history.push(`${redirectRoute}?id=${trustsCreationStore.idEdition}`)
      })
    }
  })
}

export { changeNextTrustStatus, changeBackTrustStatus }
