import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Icon from 'presentation/Icon'
import { Entity, InstanceEntity } from 'models/FormMetadata'
import instanceEntityTextReplacer from 'util/instanceEntityTextReplacer'
import { countPartErrors } from 'util/validation'
import GenericRow from 'presentation/GenericRow'
import GenericRowValuationExtraData from 'presentation/GenericRow/GenericRowValuationExtraData'
import GenericRowDefaultExtraData from 'presentation/GenericRow/GenericRowDefaultExtraData'
import { useLocation } from 'react-router'
import { TRUSTS_ASSETS } from 'routing/routes'

const PartEntityRow = ({ handleClickRow, instanceEntity, entity }) => {
  const { pathname } = useLocation()
  const { extraTypeText, secondaryTextHierarchy } = entity.listView

  const getErrors = useCallback((ent) => {
    let errors = 0

    ent.entity.parts.forEach((instance) => {
      errors += countPartErrors(instance, ent.inputs)
    })

    return errors
  }, [])

  let type = entity.name
  const { fullName } = instanceEntity
  const extraType = instanceEntityTextReplacer(instanceEntity, extraTypeText)

  if (extraType !== '') {
    type += ` / ${extraType}`
  }

  let extraData = []

  if (secondaryTextHierarchy.includes('valuation')) {
    const valuation = instanceEntityTextReplacer(instanceEntity, 'valuation')
    extraData = [...valuation]
  } else if (secondaryTextHierarchy.includes('ownershipPercentage')) {
    const valuation = instanceEntityTextReplacer(instanceEntity, 'ownershipPercentage')
    extraData = [valuation]
  } else {
    secondaryTextHierarchy.forEach((secondaryText) => {
      const options = {}

      if (pathname === TRUSTS_ASSETS) options.moneyFormat = 'billions'

      const text = instanceEntityTextReplacer(instanceEntity, secondaryText, options)

      if (text !== '') {
        extraData.push(text)
      }
    })
  }

  return (
    <GenericRow
      TypeIcon={<Icon name={entity.icon} size={15} />}
      handleClickRow={handleClickRow}
      extraData={extraData}
      errors={getErrors(instanceEntity)}
      name={fullName}
      type={type}
      ExtraDataComponent={
        secondaryTextHierarchy.includes('valuation')
          ? GenericRowValuationExtraData
          : GenericRowDefaultExtraData
      }
    />
  )
}

PartEntityRow.propTypes = {
  handleClickRow: PropTypes.func.isRequired,
  instanceEntity: PropTypes.instanceOf(InstanceEntity).isRequired,
  entity: PropTypes.instanceOf(Entity).isRequired,
}

export default observer(PartEntityRow)
