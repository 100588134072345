import { useRef, useState } from 'react'
import { FileDrop } from 'react-file-drop'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ImageInputStore from 'stores/ImageInputStore'
import c from 'classnames'
import Modal from 'scenes/Modal'
import Button from 'presentation/Button'
import defaultProfile from 'presentation/NavBarComponents/images/defaultProfile.png'
import ImageAddIcon from 'mdi-react/ImageAddIcon'
import ImageCropper from '../ImageCropper'
import styles from './imageInput.module.scss'

const ImageInput = ({ literal, noButton, inputStore }) => {
  const { t } = useTranslation('users')
  const refInput = useRef(null)
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)

  const handleButtonClick = () => {
    refInput.current.click()
    document.activeElement.blur()
  }

  const onChangeFile = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const file = e.target.files[0]

    inputStore.selectImage(file)

    e.target.value = null
  }

  const handleDrop = (files) => {
    inputStore.selectImage(files[0])
  }

  const handleCropImage = (image) => {
    setCroppedImageUrl(image)
  }

  const onSuccessCropModal = () => {
    inputStore.saveImage(croppedImageUrl)
    inputStore.closeCropModal()
  }

  const onCancelCropModal = () => {
    inputStore.closeCropModal(true)
  }

  // const handleRemovePhoto = () => {
  //   inputStore.removePhoto()
  // }

  const { savedImage, image, imageTypeError, visibleCropModal, imageBase64, loadingSaveImage } =
    inputStore

  return (
    <>
      {literal ? (
        <div
          className={c(styles.image, styles.literalImage)}
          style={{ backgroundImage: `url(${image || defaultProfile})` }}
        />
      ) : (
        <div className={styles.inputContainer}>
          <FileDrop
            draggingOverTargetClassName={styles.dragginOverTarget}
            draggingOverFrameClassName={styles.dragginOverTarget}
            onDrop={handleDrop}
            className={styles.fileDrop}
          >
            {loadingSaveImage && (
              <div className={styles.loadingSaveImage}>
                <div />
                <div />
                <div />
                <div />
              </div>
            )}
            <div
              className={c(styles.image, loadingSaveImage && styles.loadingImage)}
              onClick={handleButtonClick}
              role="button"
              tabIndex="0"
              style={
                loadingSaveImage
                  ? {
                      backgroundImage: `url(${imageBase64})`,
                    }
                  : { backgroundImage: `url(${image})` }
              }
            >
              <input
                type="file"
                style={{ display: 'none' }}
                ref={refInput}
                onChange={onChangeFile}
                accept="image/*"
              />
              {!savedImage && !image && !loadingSaveImage && <ImageAddIcon size={35} />}
              <div className={styles.dropHere} />
            </div>
            <Modal name="cropModal" title={t('setImage')} noButtons open={visibleCropModal}>
              <ImageCropper
                imageBase64={imageBase64}
                handleCropImage={handleCropImage}
                handleSaveCrop={onSuccessCropModal}
                handleCancelCrop={onCancelCropModal}
              />
            </Modal>
          </FileDrop>
          {imageTypeError.error && <div className={styles.error}>{t(imageTypeError.message)}</div>}
          {!noButton && (
            <Button
              className={styles.changeImageButton}
              small
              transparent
              label={t('uploadPhoto')}
              onClick={handleButtonClick}
            />
          )}
          {/* <div className={styles.remove} onClick={this.handleRemovePhoto}>
              {t('removePhoto')}
            </div> */}
        </div>
      )}
    </>
  )
}

ImageInput.propTypes = {
  literal: PropTypes.bool,
  noButton: PropTypes.bool,
  inputStore: PropTypes.instanceOf(ImageInputStore).isRequired,
}

ImageInput.defaultProps = {
  literal: false,
  noButton: false,
}

export default observer(ImageInput)
