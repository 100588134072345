import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TransactionActivity from 'models/TransactionActivity'
import LiteralValue from 'presentation/LiteralValue'
import InputFile from 'presentation/InputFile'
import styles from './fundingActivityModalForm.module.scss'

const FundingActivityModalForm = ({ item }) => {
  const { t } = useTranslation('activity')

  return (
    <div>
      <div className={styles.sectionContainer}>
        <div className={styles.title}>{t('sourceAccounts')}</div>
        {item.sourceAccounts.map((account) => (
          <div key={account.id} className={styles.accountsContainer}>
            <LiteralValue label={t('account')} value={account.account} />
            <LiteralValue label={t('amount')} value={account.moneyString} />
          </div>
        ))}
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.title}>{t('destinationAssets')}</div>
        {item.destinationAssets.map((asset, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${asset.assetId}+${i}`} className={styles.accountsContainer}>
            <LiteralValue label={t('account')} value={asset.account} />
            <LiteralValue label={t('amount')} value={asset.moneyString} />
          </div>
        ))}
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.title}>{t('attachedFiles')}</div>
        <InputFile label={t('attachedFiles')} fileStore={item.document} literal />
      </div>
    </div>
  )
}

FundingActivityModalForm.propTypes = {
  item: PropTypes.instanceOf(TransactionActivity).isRequired,
}

export default FundingActivityModalForm
