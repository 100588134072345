import moneyValueFormat from 'util/numberHelper'
import Asset from 'models/Asset'

class Valuation {
  constructor(name, totalValue, assets) {
    this.name = name
    this.value = totalValue
    this.assets = assets
  }

  static fromJson({ name, totalValue, assets }) {
    return new Valuation(
      name,
      totalValue === 'Invaluable' || totalValue === 'Misvalued' ? '' : moneyValueFormat(totalValue),
      assets.map((asset) => Asset.createFromJson(asset))
    )
  }

  get currencyValue() {
    return `${this.name} ${this.value}`
  }
}

export default Valuation
