import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RuleAssetBeneficiaryDistribution, TYPE_VALUE, TYPE_PERCENTAGE } from 'models/Rule'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import styles from './beneficiaryDistributionSelection.module.scss'

const BeneficiaryDistributionSelection = ({ distribution, handleChangeValue }) => {
  const [selectedType, setSelectedType] = useState({
    id: distribution.type.value,
    value: distribution.type.value,
  })

  useEffect(() => {
    setSelectedType({
      id: distribution.type.value,
      value: distribution.type.value,
    })
  }, [distribution.type.value])

  return (
    <div className={styles.container}>
      <CreationInput
        inputStore={distribution.value}
        name="amount"
        onChange={(e) => {
          distribution.setValue(e.target.value)
          handleChangeValue()
        }}
        onlyNumeric
      />
      <CreationInput
        as={FormSelect}
        name="type"
        inputStore={distribution.type}
        onChange={(value) => {
          setSelectedType(value)
          distribution.setType(value.id)
          handleChangeValue()
        }}
        value={selectedType}
        options={[
          { id: TYPE_VALUE, value: TYPE_VALUE },
          { id: TYPE_PERCENTAGE, value: TYPE_PERCENTAGE },
        ]}
        mergeStyles={{
          control: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }}
      />
    </div>
  )
}

BeneficiaryDistributionSelection.propTypes = {
  distribution: PropTypes.instanceOf(RuleAssetBeneficiaryDistribution).isRequired,
  handleChangeValue: PropTypes.func,
}

BeneficiaryDistributionSelection.defaultProps = {
  handleChangeValue: () => {},
}

export default BeneficiaryDistributionSelection
