import React from 'react'
import c from 'classnames'
import { times } from 'lodash'
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon'
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon'
import PropTypes from 'prop-types'
import styles from './tablePager.module.scss'

const TablePager = ({ handlePageChange, pages, currentPage }) => (
  <ol className={styles.tablePager}>
    {currentPage > 1 && (
      <li className={styles.page} onClick={() => handlePageChange(1)} role="menuitem">
        <ChevronDoubleLeftIcon size={18} />
      </li>
    )}
    {times(pages, (i) => {
      const page = i + 1

      return (
        <li
          key={`pager_${page}`}
          className={c(styles.page, page === currentPage && styles.active)}
          onClick={() => handlePageChange(page)}
          role="menuitem"
        >
          {page}
        </li>
      )
    })}
    {currentPage < pages && (
      <li className={styles.page} onClick={() => handlePageChange(pages)} role="menuitem">
        <ChevronDoubleRightIcon size={18} />
      </li>
    )}
  </ol>
)

TablePager.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  pages: PropTypes.number,
  currentPage: PropTypes.number,
}

TablePager.defaultProps = {
  pages: 1,
  currentPage: 1,
}

export default TablePager
