class RuleAssetGovernor {
  constructor(contactId, firstName, lastName, name, type, isTrustee) {
    this.contactId = contactId
    this.firstName = firstName
    this.lastName = lastName
    this.type = type
    this.name = name
    this.isTrustee = isTrustee
  }

  static fromJson(json) {
    if (json?.isTrustee !== undefined) {
      const {
        contact: {
          data: { firstName, lastName, type, name },
          id,
        },
        isTrustee,
      } = json
      return new RuleAssetGovernor(id, firstName, lastName, name, type, isTrustee)
    }

    if (json?.contact) {
      const {
        contact: { id: contactId, name, type, firstName, lastName },
      } = json

      return new RuleAssetGovernor(contactId, firstName, lastName, name, type, null)
    }

    return new RuleAssetGovernor(json.contactId)
  }

  getJson() {
    if (this.contactId) {
      return { contactId: this.contactId }
    }
    return null
  }

  get fullName() {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    if (this.firstName) {
      return this.firstName
    }
    if (this.lastName) {
      return this.lastName
    }

    if (this.name) {
      return this.name
    }
    return null
  }
}

export default RuleAssetGovernor
