import React, { useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import ChevronUpIcon from 'mdi-react/ChevronUpIcon'
import FileRestoreIcon from 'mdi-react/FileRestoreIcon'
import TrustDocumentVersion from 'models/TrustDocumentVersion'
import LoadingRing from 'presentation/LoadingRing'
import ExpandableButton from 'presentation/ExpandableButton'
import Button from 'presentation/Button'
import { DownloadFile } from 'presentation/InputFile'
import { DraftEditionButton } from 'presentation/LettersSection'
import DocumentDate from 'presentation/DocumentsSection'
import styles from './itemCard.module.scss'

const getStatusName = (trustDocumentVersion, t) => {
  if (trustDocumentVersion.isActive) {
    return t('active')
  }

  if (trustDocumentVersion.isDraft) {
    return t('draft')
  }

  if (trustDocumentVersion.isDeprecated) {
    return t('deprecated')
  }

  if (trustDocumentVersion.isDeleted) {
    return t('deleted')
  }

  if (trustDocumentVersion.isDeleted) {
    return t('deleted')
  }

  if (trustDocumentVersion.isRevoked) {
    return t('revoked')
  }

  return null
}

const LoadingContent = () => (
  <div className={styles.loadingContent}>
    <LoadingRing small />
  </div>
)

const LetterContent = ({ trustDocumentVersion }) => {
  const { t } = useTranslation('trustsCreation')
  return (
    <>
      <div className={styles.content}>{trustDocumentVersion.content}</div>
      <div className={styles.fileContent}>
        <DownloadFile fileStore={trustDocumentVersion.file} label={t('letterFileLabel')} />
        {trustDocumentVersion.signedFile && trustDocumentVersion.signedFile.id && (
          <div className={styles.signedDocument}>
            <DownloadFile
              fileStore={trustDocumentVersion.signedFile}
              label={t('letterFileSignedLabel')}
            />
          </div>
        )}
      </div>
    </>
  )
}

LetterContent.propTypes = {
  trustDocumentVersion: PropTypes.instanceOf(TrustDocumentVersion).isRequired,
}

const ItemButton = ({ trustDocumentVersion, t }) => {
  if (trustDocumentVersion.isDraft) {
    return (
      <div className={styles.itemStatus}>
        <DraftEditionButton />
      </div>
    )
  }

  return (
    <div className={styles.itemStatus}>
      {getStatusName(trustDocumentVersion, t)}
      <span className={styles.statusIndicator} />
    </div>
  )
}

ItemButton.propTypes = {
  trustDocumentVersion: PropTypes.instanceOf(TrustDocumentVersion).isRequired,
  t: PropTypes.func.isRequired,
}

const LetterTitle = ({ title }) => <h1 className={styles.title}>{title !== '' ? title : '-'}</h1>

LetterTitle.propTypes = {
  title: PropTypes.string,
}

LetterTitle.defaultProps = {
  title: '',
}

const ItemCard = ({
  child,
  style,
  eleRef,
  trustDocumentVersion,
  showStatus,
  handleRestoreDocument,
  handleDraftEdit,
  errors,
}) => {
  const { t } = useTranslation('trustsCreation')
  const [showingContent, setShowingContent] = useState(false)

  const handleShowContent = (e) => {
    if (!showingContent) {
      e.preventDefault()
      e.stopPropagation()

      if (trustDocumentVersion.isDraft && handleDraftEdit !== null) {
        handleDraftEdit()
      }

      if (trustDocumentVersion.content === null) {
        trustDocumentVersion.loadContent()
      }

      setShowingContent(true)
    }
  }
  const handleHideContent = (e) => {
    if (showingContent) {
      e.preventDefault()
      e.stopPropagation()

      if (trustDocumentVersion.isDraft && handleDraftEdit !== null) {
        handleDraftEdit()
      }

      setShowingContent(false)
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <article
      className={c(
        styles.trustDocumentArchiveItem,
        trustDocumentVersion.isActive && styles.cardActive,
        trustDocumentVersion.isDraft && styles.cardDraft,
        trustDocumentVersion.isDeleted && styles.cardDeleted,
        trustDocumentVersion.isDeprecated && styles.cardDeprecated,
        trustDocumentVersion.isRevoked && styles.cardRevoked,
        !child && styles.arrow,
        child && styles.child
      )}
      style={style}
      ref={eleRef}
      onClick={handleShowContent}
    >
      {errors > 0 && <div className={styles.errors}>{errors}</div>}
      <div className={styles.letterTop} onClick={handleHideContent} role="button" tabIndex="0">
        <div className={styles.itemData}>
          <DocumentDate
            lastUpdate={trustDocumentVersion.lastUpdate}
            firsName={trustDocumentVersion.updatedByFirstName}
            lastName={trustDocumentVersion.updatedByLastName}
            active={trustDocumentVersion.isActive}
            deleted={trustDocumentVersion.isDeleted || trustDocumentVersion.isDeprecated}
          />
          <LetterTitle title={trustDocumentVersion.title} />
        </div>
        {showStatus && <ItemButton trustDocumentVersion={trustDocumentVersion} t={t} />}
      </div>
      {showingContent && trustDocumentVersion.isLoadingContent && <LoadingContent />}
      {showingContent && !trustDocumentVersion.isLoadingContent && (
        <LetterContent trustDocumentVersion={trustDocumentVersion} />
      )}
      {handleRestoreDocument !== null && (
        <div className={c(styles.buttonsContainer, showingContent && styles.itemCardExpanded)}>
          {showingContent && (
            <div className={styles.actionButton}>
              <ExpandableButton
                onClick={handleHideContent}
                label={t('closeDetail')}
                Icon={<ChevronUpIcon size={25} />}
              />
            </div>
          )}
          <div className={c(styles.copyButton, showingContent && styles.itemCardExpanded)}>
            <Button
              onClick={(e) => {
                e.stopPropagation()

                handleRestoreDocument()
              }}
              label={t('restoreVersion')}
              iconPositionLeft
              small
              icon={<FileRestoreIcon size={25} />}
            />
          </div>
        </div>
      )}
    </article>
  )
}

ItemCard.propTypes = {
  trustDocumentVersion: PropTypes.instanceOf(TrustDocumentVersion).isRequired,
  handleRestoreDocument: PropTypes.func,
  child: PropTypes.bool,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  style: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  eleRef: PropTypes.any,
  showStatus: PropTypes.bool,
  handleDraftEdit: PropTypes.func,
  errors: PropTypes.number,
}

ItemCard.defaultProps = {
  child: false,
  style: {},
  eleRef: null,
  showStatus: true,
  handleRestoreDocument: null,
  handleDraftEdit: null,
  errors: 0,
}

export default observer(ItemCard)
