import { useCallback } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import c from 'classnames'
import { TRUSTS_HISTORY, TRUSTS_HISTORY_VERSIONS } from 'routing/routes'
import TrustsCreationStore from 'stores/TrustsCreationStore'
import StatusName from 'presentation/StatusName'
import MenuButton from 'presentation/MenuButton'
import styles from './historySubMenu.module.scss'

const parseLastSaveDate = (date, t) => {
  if (moment().diff(date, 'months') >= 11) {
    return date.format(t('common:dateFormat'))
  }

  return date.format('MMM DD, H:mm')
}

const HistorySubMenu = ({ store, collapsed, idTrust }) => {
  const { t } = useTranslation('navBar')
  const getUrl = useCallback((baseUrl, id) => {
    return `${baseUrl}?id=${id}`
  }, [])

  return (
    <>
      <div className={styles.entityTitle}>
        {store.entityName === '' ? t('newEntityType', 'trust') : store.entityName}
      </div>
      <div className={styles.entityLastSave}>
        {store.lastSaveDate !== null && !collapsed && (
          <>
            {t('lastSave')}
            <span className={styles.lastSaveDate}>{parseLastSaveDate(store.lastSaveDate, t)}</span>
          </>
        )}
      </div>
      <div className={c(styles.status, collapsed && styles.statusCollapsed)}>
        {store.entityStatus && <StatusName status={store.entityStatus} onlyBullet={collapsed} />}
      </div>

      <div className={styles.subMenuButtonsContainer}>
        <MenuButton
          menuIconName="HistoryIcon"
          disabled={false}
          url={getUrl(TRUSTS_HISTORY, idTrust)}
          menuLabel={t('history')}
          onClick={() => {}}
        />
        <MenuButton
          menuIconName="ChartGanttIcon"
          disabled={false}
          url={getUrl(TRUSTS_HISTORY_VERSIONS, idTrust)}
          menuLabel={t('versions')}
          onClick={() => {}}
        />
      </div>
    </>
  )
}

HistorySubMenu.propTypes = {
  store: PropTypes.instanceOf(TrustsCreationStore).isRequired,
  collapsed: PropTypes.bool.isRequired,
  idTrust: PropTypes.string.isRequired,
}

export default observer(HistorySubMenu)
