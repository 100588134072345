import { parseValidationRules } from 'util/validation'
import Part, { TYPE_INPUT_ASSET_COMPANY } from './Part'
import AssetCompanyPartStore from './AssetCompanyPartStore'

class AssetCompanyPart extends Part {
  constructor(id, /* label, */ validationRules) {
    super(id, TYPE_INPUT_ASSET_COMPANY)

    /* this.label = label */
    this.validationRules = validationRules
  }

  static fromJson({ id, /* display, */ validationRules = null }, onCreateInput) {
    /* const { label = '' } = display */
    const input = new AssetCompanyPartStore()

    if (validationRules) {
      input.setValidationRules(parseValidationRules(validationRules))
    }

    onCreateInput(id, input)

    return new AssetCompanyPart(id, /* label, */ validationRules)
  }
}

export default AssetCompanyPart
