import React, { useEffect, useContext, useCallback } from 'react'
import { rootStore } from 'App'
import { useHistory, useLocation } from 'react-router'
import { Prompt } from 'react-router-dom'
import { USERS_LIST, USERS_CREATION } from 'routing/routes'
import MainCreationContainer from 'presentation/MainCreationContainer'
import MainCreationLoading from 'presentation/MainCreationLoading'
import { CHANGE_USER_STATUS, EDIT_USER } from 'stores/AuthStore'
import StoresContext from 'providers/storeContext'
import { useTranslation } from 'react-i18next'
import LoadingRing from 'presentation/LoadingRing'
import ToggleSwitchOffOutlineIcon from 'mdi-react/ToggleSwitchOffOutlineIcon'
import EmailSendOutlineIcon from 'mdi-react/EmailSendOutlineIcon'
import ToggleSwitchOutlineIcon from 'mdi-react/ToggleSwitchOutlineIcon'
import { observer } from 'mobx-react'
import Button from 'presentation/Button'
import ServerError from 'presentation/ServerError'
import TenantUserWarning from 'presentation/TenantUserWarning'
import RequestErrors from 'presentation/RequestErrors'
import Modal from 'scenes/Modal'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import PageTitle from 'presentation/PageTitle'
import Gated from 'util/Gated'
import UserFormStatus from 'presentation/UserFormStatus'
import { successToast } from 'helpers/Toast'
import ImageInput from 'presentation/ImageInput'
import UserSectionError from 'presentation/UserSectionError'
import UserCreationActionButtons from './UserCreationActionButtons'
import styles from './userCreation.module.scss'

const UserCreation = () => {
  const { usersCreationStore, hotkeyStore, authStore } = useContext(StoresContext)
  const { viewMode, isLoading, stores, idEdition, roles, isDirty, isTenantUser } =
    usersCreationStore
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation('users')

  const callbacks = useCallback(
    {
      configureKeyStore: () => {
        usersCreationStore.save().then((isValid) => {
          if (isValid) {
            usersCreationStore.reloadData()
            usersCreationStore.toggleViewMode(true)
          }
        })
      },
      handleEdit: () => usersCreationStore.toggleViewMode(false),
      handleGoBack: () => history.push(USERS_LIST),
      handleChange: (fieldName, value) => usersCreationStore.changeInput(fieldName, value),
      handleToggleActivation: () => usersCreationStore.toggleUserStatus(),
      handleSave: () => {
        usersCreationStore
          .save(() => authStore.keepAlive())
          .then((isValid) => {
            if (isValid) {
              usersCreationStore.resetDirty()
              history.push(`${USERS_CREATION}?id=${usersCreationStore.idEdition}&v=true`)
              usersCreationStore.toggleViewMode(true)
              successToast(t('userCreatedMsg'), null, 'savedUser').show()
            }
          })
      },
    },
    []
  )

  const handleCancel = useCallback(() => {
    if (!viewMode && !idEdition) return callbacks.handleGoBack()
    usersCreationStore.resetAttributes()
    return usersCreationStore.toggleViewMode(true)
  }, [idEdition])

  const changeStatus = (onClick) => {
    if (usersCreationStore.data.status && usersCreationStore.data.status.isActive) {
      return (
        <div className={styles.statusRightSection}>
          <span>
            <ToggleSwitchOffOutlineIcon size={24} />
          </span>
          <span onClick={onClick} role="button" tabIndex="0">
            {t('userCreation.deactivate')}
          </span>
        </div>
      )
    }
    return (
      <div className={styles.statusRightSection}>
        <span>
          <ToggleSwitchOutlineIcon size={24} />
        </span>
        <span onClick={onClick} role="button" tabIndex="0">
          {t('userCreation.activate')}
        </span>
      </div>
    )
  }

  // onMount
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    usersCreationStore.init(params.get('id'))
    callbacks.configureKeyStore()
    usersCreationStore.setSchema({
      requiredErrorMsg: t('userCreation.isRequired'),
      emailErrorMsg: t('userCreation.failedEmail'),
    })
    // Unmount
    return () => {
      rootStore.resetUsersCreation()
      hotkeyStore.removeConfig('usersCreation')
    }
  }, [])

  // Calcula el titulo
  let title = t('userCreation.title.userTitle')
  if (!viewMode) {
    if (!usersCreationStore.idEdition) {
      title = t('userCreation.title.newUser')
    } else {
      title = t('userCreation.title.userEdit')
    }
  }

  const handleTryAgain = useCallback(() => usersCreationStore.tryAgain(), [])

  const handleClickResendActivation = useCallback(
    () => usersCreationStore.openConfirmActivationModal(),
    []
  )

  const showModalText = () => {
    if (usersCreationStore.successResend) {
      return t('userCreation.successResendActivation')
    }

    return (
      <div>
        {usersCreationStore.resendErrors.map((error, i) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`resendError_${i}`}
            >
              {error}
            </div>
          )
        })}
      </div>
    )
  }

  const handleOkResendActivationModal = useCallback(
    () => usersCreationStore.hideResendActivationModal(),
    []
  )

  const handleConfirmResendActivation = useCallback(() => usersCreationStore.resendActivation(), [])

  const handleCancelResendActivation = useCallback(
    () => usersCreationStore.hideConfirmActivationModal(),
    []
  )

  if (usersCreationStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  if (usersCreationStore.noIdError) {
    return <UserSectionError />
  }

  return (
    <>
      <PageTitle title={!isLoading && title} floating />
      <Prompt
        when={isDirty}
        message={(loc) => {
          if (loc.pathname.search(USERS_CREATION) < 0 || loc.pathname === USERS_CREATION) {
            return t('leavePagePrompt')
          }

          return true
        }}
      />
      <MainCreationLoading store={usersCreationStore} />
      <MainCreationContainer isLoading={isLoading}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <ImageInput literal={viewMode} inputStore={stores.profileImage} />
            {isTenantUser && !viewMode && <TenantUserWarning />}
          </div>
          <div className={styles.formContainer}>
            <CreationInput
              inputStore={stores.firstName}
              label={t('userCreation.firstName')}
              placeholder={t('userCreation.firstNamePlaceHolder')}
              onChange={(e) => callbacks.handleChange('firstName', e.target.value)}
              literal={viewMode}
            />
            <CreationInput
              inputStore={stores.lastName}
              label={t('userCreation.lastName')}
              placeholder={t('userCreation.lastNamePlaceHolder')}
              onChange={(e) => callbacks.handleChange('lastName', e.target.value)}
              literal={viewMode}
            />
            <div className={styles.largeInput}>
              <CreationInput
                inputStore={stores.email}
                label={t('userCreation.email')}
                placeholder={t('userCreation.emailPlaceHolder')}
                onChange={(e) => callbacks.handleChange('email', e.target.value)}
                literal={!stores.id.value ? viewMode : true}
              />
            </div>
            <div className={styles.largeInput}>
              <CreationInput
                as={FormSelect}
                inputStore={stores.roles}
                label={t('userCreation.roles')}
                placeholder={t('userCreation.rolesPlaceHolder')}
                onChange={(options) => callbacks.handleChange('roles', options)}
                options={roles.map((e) => ({
                  id: e.id,
                  value: e.name,
                }))}
                literal={isTenantUser || viewMode}
                isMulti
              />
            </div>
            <UserFormStatus
              status={usersCreationStore.data.status}
              rightSection={
                !viewMode &&
                idEdition && (
                  <Gated permission={CHANGE_USER_STATUS}>
                    <div className={styles.toggleActive}>
                      {!usersCreationStore.data.isActive ? (
                        <div className={styles.resendContainer}>
                          <div className={styles.statusRightSection}>
                            <span>
                              <EmailSendOutlineIcon size={22} />
                            </span>
                            <span onClick={handleClickResendActivation} role="button" tabIndex="0">
                              {t('resendActivation')}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div>{changeStatus(() => usersCreationStore.toggleStatusModal())}</div>
                      )}
                    </div>
                  </Gated>
                )
              }
            />
            <RequestErrors errors={usersCreationStore.requestErrors} />
          </div>
          <UserCreationActionButtons
            store={usersCreationStore}
            editPermission={EDIT_USER}
            handleSave={callbacks.handleSave}
            handleCancel={handleCancel}
            handleEdit={callbacks.handleEdit}
            handleGoBack={callbacks.handleGoBack}
          />
        </div>
      </MainCreationContainer>
      <Modal
        name="userCreationStatusModal"
        title={t('userCreationStatusModalTitle')}
        open={usersCreationStore.statusModal}
        onSuccess={callbacks.handleToggleActivation}
        onCancel={() => usersCreationStore.toggleStatusModal()}
      />
      <Modal
        name="resendActivationModal"
        title={t('userCreation.resendActivationTitle')}
        okOnly
        open={usersCreationStore.visibleActivationModal}
        onSuccess={handleOkResendActivationModal}
        customButtons={[
          <Button
            key="button_ok_userCreation"
            label={t('userCreation.ok')}
            primary
            onClick={handleOkResendActivationModal}
          />,
        ]}
      >
        {usersCreationStore.isLoadingResend ? <LoadingRing small center /> : showModalText()}
      </Modal>
      <Modal
        name="confirmActivationModal"
        title={t('userCreation.resendActivationTitle')}
        okOnly
        open={usersCreationStore.visibleConfirmActivationModal}
        onSuccess={handleOkResendActivationModal}
        customButtons={[
          <Button
            key="button_sendEmail_userCreation"
            label={t('userCreation.sendEmail')}
            primary
            style={{ marginRight: '10px' }}
            onClick={handleConfirmResendActivation}
          />,
          <Button
            key="button_cancel_userCreation"
            label={t('userCreation.cancel')}
            secondary
            onClick={handleCancelResendActivation}
          />,
        ]}
      >
        {t('userCreation.confirmResendActivation')}
      </Modal>
    </>
  )
}

export default observer(UserCreation)
