import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import ContactsCreationStore from 'stores/ContactsCreationStore'
import { SAVE } from 'stores/AuthStore'
import CloudAlertIcon from 'mdi-react/CloudAlertIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Button from 'presentation/Button'
import styles from './contactCreationActionButtons.module.scss'

const ContactCreationActionButtons = ({
  handleSave,
  handleEdit,
  handleGoBack,
  handleCancel,
  contactsCreationStore,
}) => {
  const { t } = useTranslation('contactsCreation')

  if (!contactsCreationStore.visibleActionButtons) {
    return null
  }

  const { isDirty, canSave, isViewMode, entityStatus, loadingSave, showSaveToContinue } =
    contactsCreationStore

  return (
    <div className={styles.buttonsContainer}>
      {showSaveToContinue && <div className={styles.showSaveToContinue}>{t('saveToContinue')}</div>}
      {isViewMode ? (
        <>
          <Button
            style={{ marginRight: '15px' }}
            label={t('common:back')}
            title={t('common:back')}
            icon={<ArrowLeftIcon />}
            onClick={handleGoBack}
            iconPositionLeft
            secondary
          />
          {entityStatus.canBeEdited && (
            <Button
              label={t('editContact')}
              title={t('editContact')}
              onClick={handleEdit}
              permission={SAVE}
              primary
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.saveButtonContainer}>
            <div className={c(styles.unsetChanges, isDirty ? styles.show : '')}>
              <CloudAlertIcon />
              <span>{t('unsavedLabel')}</span>
            </div>
            <Button
              title={`${t(`saveContact`)} (alt+s)`}
              style={{ marginRight: '15px' }}
              label={t('saveContact')}
              isLoading={loadingSave}
              onClick={handleSave}
              disabled={!canSave}
              permission={SAVE}
              primary
              big
            />
          </div>
          <Button secondary big label={t('common:cancel')} onClick={handleCancel} />
        </>
      )}
    </div>
  )
}

ContactCreationActionButtons.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  contactsCreationStore: PropTypes.instanceOf(ContactsCreationStore).isRequired,
}

export default observer(ContactCreationActionButtons)
