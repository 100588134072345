import React, { useState, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import StoresContext from 'providers/storeContext'
import PlusIcon from 'mdi-react/PlusIcon'
import PlusCircleIcon from 'mdi-react/PlusCircleIcon'
import Button from 'presentation/Button'
import { CREATE_TRUST } from 'stores/AuthStore'
import SearchField from 'presentation/SearchField'
import PageTitle from 'presentation/PageTitle'
import SectionListViewSelection from 'presentation/SectionListViewSelection'
import NoResultsList from 'presentation/NoResultsList'
import TablePager from 'presentation/TablePager'
import styles from './mainSectionList.module.scss'

const getBrowserListKey = (sectionName) => `saved${sectionName}ListView`
const getDefaultView = (browserListKey) => window.localStorage.getItem(browserListKey) || 'list'

const MainSectionList = ({
  sectionNameSingular,
  sectionNamePlural,
  creationRoute,
  sectionItems,
  handleSearch,
  isLoading,
  handleCreation,
  tableComponent,
  handlePageChange,
  currentPage,
  totalPages,
  setShowDropdownOrder,
  setShowDropdownFilter,
  creationPermission,
  hasFilterStatus,
  customSearchComponent,
  customHeaderRightSection,
  onChangeView,
}) => {
  const { authStore } = useContext(StoresContext)
  const { t } = useTranslation('mainSectionList')
  const browserListKey = getBrowserListKey(sectionNameSingular)
  const [activeSearch, setActiveSearch] = useState(false)
  const [viewTable, setViewTable] = useState(getDefaultView(browserListKey))
  const handleChangeView = (value) => {
    window.localStorage.setItem(browserListKey, value)
    setViewTable(value)
    if (onChangeView) {
      onChangeView(value)
    }
  }

  const standardButtons = useMemo(
    () => (
      <>
        <Button
          onClick={creationRoute === null ? handleCreation : null}
          label={t('newSection', { name: sectionNameSingular })}
          as={creationRoute !== null ? NavLink : 'button'}
          className="visibleDesktop"
          permission={creationPermission}
          to={creationRoute || null}
          icon={<PlusIcon />}
          primary
          big
        />
        <Button
          onClick={creationRoute === null ? handleCreation : null}
          as={creationRoute !== null ? NavLink : 'button'}
          className="visibleMobile"
          permission={creationPermission}
          to={creationRoute || null}
          icon={<PlusIcon />}
          primary
          circle
        />
      </>
    ),
    []
  )

  return (
    <>
      <PageTitle
        title={sectionNamePlural}
        rightSection={customHeaderRightSection || standardButtons}
      />
      <div className={styles.searchBar}>
        {customSearchComponent || (
          <SearchField
            onChange={(e, value) => {
              handleSearch(value)

              if (value !== '') {
                setActiveSearch(true)
              } else {
                setActiveSearch(false)
              }
            }}
          />
        )}
        <SectionListViewSelection
          setShowDropdownFilter={setShowDropdownFilter}
          setShowDropdownOrder={setShowDropdownOrder}
          changeView={handleChangeView}
          viewTable={viewTable}
        />
      </div>
      <>
        {React.cloneElement(tableComponent, { view: viewTable })}
        {totalPages > 1 && !isLoading && (
          <TablePager
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            pages={totalPages}
          />
        )}
        {sectionItems.length === 0 && !hasFilterStatus && !isLoading && (
          <>
            {authStore.can(CREATE_TRUST) ? (
              <NoResultsList
                bottomText={!activeSearch ? t('startByCreatingOne') : t('tryAnotherSearch')}
                topText={t('noItemsFound', { name: sectionNamePlural })}
                onClick={handleCreation}
                Icon={PlusCircleIcon}
              />
            ) : (
              <NoResultsList
                Icon="false"
                topText={t('noItemsFound', { name: sectionNamePlural })}
              />
            )}
          </>
        )}
        {sectionItems.length === 0 && hasFilterStatus && (
          <NoResultsList topText={t('noItemsFound', { name: sectionNamePlural })} />
        )}
      </>
    </>
  )
}

MainSectionList.propTypes = {
  sectionNameSingular: PropTypes.string.isRequired,
  sectionNamePlural: PropTypes.string.isRequired,
  creationRoute: PropTypes.string,
  handleSearch: PropTypes.func,
  tableComponent: PropTypes.node.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  setShowDropdownOrder: PropTypes.func,
  setShowDropdownFilter: PropTypes.func,
  creationPermission: PropTypes.string.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  sectionItems: PropTypes.oneOfType([PropTypes.array]),
  hasFilterStatus: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleCreation: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  customSearchComponent: PropTypes.node,
  customHeaderRightSection: PropTypes.node,
  onChangeView: PropTypes.func,
}

MainSectionList.defaultProps = {
  setShowDropdownOrder: null,
  setShowDropdownFilter: null,
  creationRoute: null,
  sectionItems: [],
  isLoading: false,
  hasFilterStatus: false,
  handleCreation: null,
  currentPage: 1,
  totalPages: 1,
  customSearchComponent: null,
  customHeaderRightSection: null,
  handleSearch: () => {},
  onChangeView: null,
}

export default MainSectionList
