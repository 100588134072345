import React from 'react'
import PropTypes from 'prop-types'
import VoteStatus from 'presentation/VoteStatus'
import styles from './voterFormStatus.module.scss'

const VoterFormStatus = ({ status, rightSection, label }) => {
  return (
    <div className={styles.status}>
      <div>{label}</div>
      <div className={styles.infoContainer}>
        <VoteStatus status={status} />
        <div className={styles.rightSection}>{rightSection}</div>
      </div>
    </div>
  )
}

VoterFormStatus.propTypes = {
  rightSection: PropTypes.string,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

VoterFormStatus.defaultProps = {
  rightSection: '',
}

export default VoterFormStatus
