import React, { useState } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import { useTranslation } from 'react-i18next'
import styles from './searchField.module.scss'

const changeTimeoutTime = 500
let changeTimeout = null

const SearchField = ({ onChange }) => {
  const [value, setValue] = useState('')
  const { t } = useTranslation('common')
  const onClick = (e, val) => {
    setValue(val)

    if (onChange) {
      clearTimeout(changeTimeout)
      changeTimeout = setTimeout(() => {
        onChange(e, val)
      }, changeTimeoutTime)
    }
  }

  return (
    <div className={c(styles.searchContainer, value && styles.showClose)}>
      <MagnifyIcon className={styles.searchIcon} />
      <input
        value={value}
        type="text"
        className={c(styles.searchInput, value !== '' && styles.expanded)}
        placeholder={t('search')}
        onChange={(e) => {
          onClick(e, e.target.value)
        }}
      />
      <CloseIcon
        className={styles.resetIcon}
        onClick={(e) => {
          if (value) {
            onClick(e, '')
          }
        }}
      />
    </div>
  )
}

SearchField.propTypes = {
  onChange: PropTypes.func,
}

SearchField.defaultProps = {
  onChange: null,
}

export default SearchField
