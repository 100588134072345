import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { observer } from 'mobx-react'
import styles from './contactSelectItem.module.scss'

const ContactSelectItem = ({ innerProps, innerRef, data, isFocused }) => {
  const selectedUser = data
  return (
    <div
      ref={innerRef}
      className={c(styles.option, isFocused && styles.focus, innerProps.className)}
      {...innerProps}
    >
      <div className={styles.content}>
        <div className={styles.title} />
        <div className={styles.name}>{selectedUser.label}</div>
      </div>
      <div className={styles.email}>{selectedUser.email}</div>
    </div>
  )
}

ContactSelectItem.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isFocused: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  handleSetUserRole: PropTypes.func,
  innerProps: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}
ContactSelectItem.defaultProps = {
  innerRef: null,
  isFocused: false,
  hasCheckbox: false,
  handleSetUserRole: null,
}

export default observer(ContactSelectItem)
