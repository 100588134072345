import { makeAutoObservable } from 'mobx'
import InputStore from 'stores/InputStore'

class GoverningLaw {
  trustEnabled = false
  corporationEnabled = false

  constructor() {
    this.name = new InputStore()

    makeAutoObservable(this)
  }

  changeName(name) {
    this.name.setValue(name)
  }

  setTrustEnabled(value) {
    this.trustEnabled = value
  }

  setCorporationEnabled(value) {
    this.corporationEnabled = value
  }

  getJson() {
    return {
      name: this.name.value,
      trustEnabled: this.trustEnabled,
      corporationEnabled: this.corporationEnabled,
    }
  }
}

export default GoverningLaw
