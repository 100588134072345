import React from 'react'
import PropTypes from 'prop-types'
import { Part } from 'models/FormMetadata'
import DynamicFormPartText from '../DynamicFormPartText'
import DynamicFormPartBool from '../DynamicFormPartBool'
import DynamicFormPartInputList from '../DynamicFormPartInputList'
import DynamicFormPartGroupList from '../DynamicFormPartGroupList'
import DynamicFormPartDataList from '../DynamicFormPartDataList'
import DynamicFormPartReadOnly from '../DynamicFormPartReadOnly'
import DynamicFormPartEntityList from '../DynamicFormPartEntityList'
import DynamicFormPartDate from '../DynamicFormPartDate'
import DynamicFormPartTextarea from '../DynamicFormPartTextarea'
import DynamicFormPartLows from '../DynamicFormPartLows'
import DynamicFormPartDeeds from '../DynamicFormPartDeeds'
import DynamicFormPartStatutes from '../DynamicFormPartStatutes'
import DynamicFormPartFile from '../DynamicFormPartFile'
import DynamicFormPartFileList from '../DynamicFormPartFileList'
import DynamicFormPartAssetTrust from '../DynamicFormPartAssetTrust'
import DynamicFormPartAssetCompany from '../DynamicFormPartAssetCompany'
import DynamicFormPartMoney from '../DynamicFormPartMoney'
import DynamicFormPartPercentage from '../DynamicFormPartPercentage'
import DynamicFormPartEntitiesDataList from '../DynamicFormPartEntitiesDataList'
import DynamicFormPartProtectorCategoryDataList from '../DynamicFormPartProtectorCategoryDataList'
import DynamicFormPartEntitySnapshotList from '../DynamicFormPartEntitySnapshotList'
import DynamicFormPartBoolLowsSnapshot from '../DynamicFormPartBoolLowsSnapshot'
import DynamicFormPartContactSearch from '../DynamicFormPartContactSearch'
import DynamicFormPartTrustDocuments from '../DynamicFormPartTrustDocuments'

const DynamicFormPart = ({
  part,
  inputs,
  readOnly,
  inputRef,
  modal,
  tab,
  dynamicEntityName,
  isEntityStored,
}) => {
  const isReadOnly = () => {
    return readOnly || part.readOnly || (isEntityStored && part.readOnlyInEdition)
  }
  if (part.isLows) {
    return <DynamicFormPartLows part={part} readOnly={readOnly} />
  }

  if (part.isDeeds) {
    return <DynamicFormPartDeeds part={part} readOnly={readOnly} />
  }

  if (part.isTrustDocuments) {
    return <DynamicFormPartTrustDocuments />
  }

  if (part.isStatutes) {
    return <DynamicFormPartStatutes part={part} readOnly={readOnly} />
  }

  if (part.isGroupList) {
    return (
      <DynamicFormPartGroupList
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        inputRef={inputRef}
        modal={modal}
        tab={tab}
        isEntityStored={isEntityStored}
      />
    )
  }

  if (part.isInputList) {
    return (
      <DynamicFormPartInputList
        part={part}
        inputs={inputs}
        readOnly={readOnly}
        firstInputRef={inputRef}
        modal={modal}
        tab={tab}
        isEntityStored={isEntityStored}
      />
    )
  }

  if (part.isEntityList) {
    return (
      <DynamicFormPartEntityList
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        dynamicEntityName={dynamicEntityName}
      />
    )
  }

  if (part.isInputText) {
    return (
      <DynamicFormPartText
        part={part}
        inputs={inputs || inputs.get(part.id).store}
        readOnly={isReadOnly()}
        inputRef={inputRef}
      />
    )
  }

  if (part.isBool) {
    return <DynamicFormPartBool part={part} inputs={inputs} readOnly={isReadOnly()} />
  }

  if (part.isLowsSnapShot) {
    return <DynamicFormPartBoolLowsSnapshot part={part} />
  }

  if (part.isDataList) {
    return (
      <DynamicFormPartDataList
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        inputRef={inputRef}
      />
    )
  }

  if (part.isCreatableDataList) {
    return (
      <DynamicFormPartDataList
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        creatable
        inputRef={inputRef}
      />
    )
  }

  if (part.isReadOnly) {
    return <DynamicFormPartReadOnly part={part} inputs={inputs} readOnly={readOnly} />
  }

  if (part.isDate) {
    return (
      <DynamicFormPartDate
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        inputRef={inputRef}
      />
    )
  }

  if (part.isTextarea) {
    return (
      <DynamicFormPartTextarea
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        inputRef={inputRef}
      />
    )
  }

  if (part.isFile) {
    return <DynamicFormPartFile part={part} inputs={inputs} readOnly={readOnly} />
  }

  if (part.isCertifiedFile) {
    return (
      <DynamicFormPartFile
        part={part}
        inputs={inputs}
        readOnly={readOnly}
        showLegalCertified
        autoHideLegalCertified={!part.alwaysVisible}
      />
    )
  }

  if (part.isFileList) {
    return <DynamicFormPartFileList part={part} inputs={inputs} readOnly={readOnly} modal={modal} />
  }

  if (part.isMoney) {
    return (
      <DynamicFormPartMoney
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        inputRef={inputRef}
      />
    )
  }

  if (part.isPercentage) {
    return (
      <DynamicFormPartPercentage
        part={part}
        inputs={inputs}
        readOnly={isReadOnly()}
        inputRef={inputRef}
      />
    )
  }

  if (part.isEntitiesDataList) {
    return (
      <DynamicFormPartEntitiesDataList
        part={part}
        inputs={inputs}
        readOnly={readOnly}
        inputRef={inputRef}
      />
    )
  }

  if (part.isProtectorCategoryDataList) {
    return (
      <DynamicFormPartProtectorCategoryDataList
        part={part}
        inputs={inputs}
        readOnly={readOnly}
        inputRef={inputRef}
      />
    )
  }

  if (part.isAssetTrust) {
    return <DynamicFormPartAssetTrust part={part} inputs={inputs} readOnly={readOnly} />
  }

  if (part.isAssetCompany) {
    return <DynamicFormPartAssetCompany part={part} inputs={inputs} readOnly={readOnly} />
  }

  if (part.isHidden) {
    return null
  }

  if (part.isEntitySnapshotList) {
    return <DynamicFormPartEntitySnapshotList part={part} inputs={inputs} />
  }

  if (part.isContactSearch) {
    return <DynamicFormPartContactSearch part={part} />
  }

  return null
}

DynamicFormPart.propTypes = {
  dynamicEntityName: PropTypes.string,
  part: PropTypes.instanceOf(Part).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  modal: PropTypes.bool,
  tab: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isEntityStored: PropTypes.bool,
}

DynamicFormPart.defaultProps = {
  readOnly: true,
  modal: false,
  tab: false,
  dynamicEntityName: '',
  inputRef: () => {},
  isEntityStored: false,
}

export default DynamicFormPart
