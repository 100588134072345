import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { observer } from 'mobx-react'
import CheckIcon from 'mdi-react/CheckIcon'
import styles from './governingLawSelectList.module.scss'

const GoverningLawSelectList = ({
  hasCheckbox,
  trustEnabled,
  corporationEnabled,
  handleChangeTrustEnabled,
  handleChangeCorporationEnabled,
}) => {
  return (
    <div className={styles.selectedList}>
      <div className={styles.governingLaw}>
        <div
          onClick={handleChangeTrustEnabled}
          className={c(styles.role, trustEnabled && styles.governingLaw)}
          role="button"
          tabIndex="0"
        >
          {hasCheckbox && (
            <div className={styles.checkbox}>
              <CheckIcon />
            </div>
          )}
          <div className={styles.roleName}>Trust</div>
        </div>
        <div
          onClick={handleChangeCorporationEnabled}
          className={c(styles.role, corporationEnabled && styles.governingLaw)}
          role="button"
          tabIndex="0"
        >
          {hasCheckbox && (
            <div className={styles.checkbox}>
              <CheckIcon />
            </div>
          )}
          <div className={styles.roleName}>Corporation</div>
        </div>
      </div>
    </div>
  )
}

GoverningLawSelectList.propTypes = {
  hasCheckbox: PropTypes.bool,
  trustEnabled: PropTypes.bool,
  corporationEnabled: PropTypes.bool,
  handleChangeCorporationEnabled: PropTypes.func.isRequired,
  handleChangeTrustEnabled: PropTypes.func.isRequired,
}

GoverningLawSelectList.defaultProps = {
  hasCheckbox: false,
  trustEnabled: false,
  corporationEnabled: false,
}

export default observer(GoverningLawSelectList)
