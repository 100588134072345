import React, { useContext, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PlusIcon from 'mdi-react/PlusIcon'
import StoresContext from 'providers/storeContext'
import { EntityListPart } from 'models/FormMetadata'
import Button from 'presentation/Button'
import PageTitle from 'presentation/PageTitle'
import CreationModal from 'presentation/CreationModal'
import NoResultsList from 'presentation/NoResultsList'
import ContactSearch from 'presentation/ContactSearch'
import FirstLevelError from 'presentation/FirstLevelError'
import Modal from 'scenes/Modal'
import InstanceEntitiesList from 'models/FormMetadata/InstanceEntitiesList'
import { EntitiesDataListFormPartStore } from 'models/FormMetadata/Parts'
import CreateEntityModal from '../CreateEntityModal'
import PartEntityList from './PartEntityList'
import DynamicFormPartEntityListStore from './DynamicFormPartEntityListStore'

const DynamicFormPartEntityList = ({ part, readOnly, dynamicEntityName }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation('dynamicForms')
  const { hotkeyStore, currentDynamicStore } = useContext(StoresContext)
  const [dynamicFormPartEntityListStore] = useState(() => new DynamicFormPartEntityListStore())
  const handleClickAddEntity = useCallback(
    () => dynamicFormPartEntityListStore.setShowCreation(true),
    []
  )

  useEffect(() => {
    hotkeyStore.setConfig(
      'entitySection',
      [
        {
          shortcut: 'alt+c',
          handler: () => handleClickAddEntity(),
        },
      ],
      1
    )

    return () => hotkeyStore.removeConfig('entitySection')
  }, [])

  const handleCloseModalCreation = useCallback(
    () => dynamicFormPartEntityListStore.closeModalCreation(),
    []
  )

  const handleRemoveViewMode = useCallback(() => currentDynamicStore.removeViewMode(), [])

  const entityActionTitle = useCallback(() => {
    const { entityName } = part

    if (readOnly) {
      return t('viewEntity', { name: entityName })
    }

    if (dynamicFormPartEntityListStore.editEntity) {
      return t('editEntity', { name: entityName })
    }

    return t('addEntity', { name: entityName })
  }, [])

  const handleAddEntity = useCallback(
    (instanceEntity) => {
      const { editEntity, editEntityOriginal } = dynamicFormPartEntityListStore

      if (editEntity === null) {
        part.addInstanceEntity(instanceEntity)
      } else {
        editEntityOriginal.updateFromEntity(editEntity)

        // Busco todos los EntitiesDataListFormPart para actualizar aquellos en los que esta entidad se encuentre entre sus opciones
        currentDynamicStore.inputs.forEach((input) => {
          if (input instanceof InstanceEntitiesList) {
            input.instanceEntities.forEach((instanceEntityItem) => {
              instanceEntityItem.inputs.forEach((instanceEntityInput) => {
                if (instanceEntityInput instanceof EntitiesDataListFormPartStore) {
                  let update = false
                  const newValue = []

                  instanceEntityInput.store.value.forEach((dataListOption) => {
                    const { id, value } = dataListOption

                    if (dataListOption.id === instanceEntity.id) {
                      // Si la entidad es la misma, actualizo el value
                      newValue.push({ id, value: instanceEntity.fullName })
                      update = true
                    } else {
                      newValue.push({ id, value })
                    }
                  })

                  if (update) {
                    instanceEntityInput.store.setValue(newValue)
                  }
                }
              })
            })
          }
        })
      }

      currentDynamicStore.validate()

      handleCloseModalCreation()
    },
    [dynamicFormPartEntityListStore, part]
  )

  const handleDeleteRow = useCallback(
    (instanceEntity) => dynamicFormPartEntityListStore.handleDeleteRow(instanceEntity),
    []
  )

  const handleClickRow = useCallback((instanceEntity) => {
    dynamicFormPartEntityListStore.handleClickRow(instanceEntity)
  }, [])

  const closeDeleteModal = useCallback(() => {
    dynamicFormPartEntityListStore.closeDeleteModal()
  }, [])

  const successDeleteModal = useCallback(() => {
    part.removeInstanceEntity(dynamicFormPartEntityListStore.deleteInstanceEntity)
    currentDynamicStore.validate()
    closeDeleteModal()
  }, [])

  const handleSelectContact = useCallback(({ contact }) => {
    const candidateEntities = part.entities.filter((entity) => contact.type === entity.type)

    if (candidateEntities.length > 0) {
      const [entity] = candidateEntities
      const instanceEntity = entity.createInputInstance()

      instanceEntity.fillFromContact(contact)

      handleAddEntity(instanceEntity)
      handleClickRow(instanceEntity)
    }
  }, [])

  const { editEntity, deleteModal, showCreation } = dynamicFormPartEntityListStore
  const isContactStructureModal = part.entityName === 'Structure' && pathname.includes('contacts')

  return (
    <>
      <PageTitle
        title={part.entityNamePlural}
        rightSection={
          !readOnly &&
          part.canAddEntities && (
            <>
              <Button
                title={`${t('addEntity', { name: part.entityName })} (alt+c)`}
                label={t('addEntity', { name: part.entityName })}
                onClick={handleClickAddEntity}
                className="visibleDesktop"
                icon={<PlusIcon />}
                big
              />
              <Button
                onClick={handleClickAddEntity}
                className="visibleMobile"
                icon={<PlusIcon />}
                circle
                small
              />
            </>
          )
        }
      />
      {!readOnly && part.hasContactSearch() && (
        <ContactSearch
          onSelectContact={handleSelectContact}
          entityName={part.entityName}
          isOnlyClientSearch={part.isOnlyClientSearch}
        />
      )}
      <PartEntityList
        part={part}
        readOnly={readOnly}
        handleDeleteRow={handleDeleteRow}
        handleClickRow={handleClickRow}
      />
      {part.instanceEntitiesList.firstLevelError && (
        <FirstLevelError description={t(part.instanceEntitiesList.errorMessage)} />
      )}
      {part.instanceEntitiesList.instanceEntities.length === 0 && (
        <NoResultsList
          onClick={readOnly ? null : handleClickAddEntity}
          topText={t('noEntitiesFound', {
            what: part.entityNamePlural,
            mainEntity: dynamicEntityName,
          })}
          bottomText={readOnly ? null : t('startBySelectingOrCreatingOne')}
          showIcon={!readOnly}
        />
      )}
      <CreationModal
        title={entityActionTitle()}
        handleClose={handleCloseModalCreation}
        visible={showCreation}
      >
        <CreateEntityModal
          part={part}
          readOnly={readOnly}
          editionInstanceEntity={editEntity}
          handleRemoveViewMode={handleRemoveViewMode}
          handleCancel={handleCloseModalCreation}
          handleAddEntity={handleAddEntity}
          canRemoveViewMode={
            currentDynamicStore.entityStatus.canBeEdited && !isContactStructureModal
          }
        />
      </CreationModal>
      <Modal
        name="deleteModal"
        title={t('confirmDeleteEntity', { what: part.entityName })}
        open={deleteModal}
        onSuccess={successDeleteModal}
        onCancel={closeDeleteModal}
        closeOutside
      />
    </>
  )
}

DynamicFormPartEntityList.propTypes = {
  part: PropTypes.instanceOf(EntityListPart).isRequired,
  dynamicEntityName: PropTypes.string.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  inputs: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
}

DynamicFormPartEntityList.defaultProps = {
  readOnly: true,
}

export default observer(DynamicFormPartEntityList)
