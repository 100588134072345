import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import Gated from 'util/Gated'
import styles from './subMenuButton.module.scss'

const SubMenuButton = ({ collapsed, to, onClick, Icon, label, separator, primary, permission }) => (
  <Gated.NavLink
    className={c(
      styles.subMenuButton,
      collapsed && styles.collapsed,
      separator && styles.subMenuButtonSeparator,
      primary && styles.primary
    )}
    to={to}
    activeClassName={styles.subMenuButtonActive}
    isActive={() => {
      const toNoParams = to.split('?')[0]

      return window.location.pathname.indexOf(toNoParams) > -1
    }}
    onClick={onClick}
    permission={permission}
  >
    <span>
      <Icon size={17} />
    </span>
    <span className={styles.subMenuButtonText}>{label}</span>
  </Gated.NavLink>
)

SubMenuButton.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  Icon: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  separator: PropTypes.bool,
  primary: PropTypes.bool,
}

SubMenuButton.defaultProps = {
  collapsed: false,
  separator: false,
  primary: false,
}

export default SubMenuButton
