import { makeAutoObservable } from 'mobx'
import Cookies from 'js-cookie'
import SettingsService from 'services/SettingsService'

class SettingsStore {
  isGrantor = false
  maxUploadSize = 100 // mb
  mainRef = null

  constructor(rootStore) {
    makeAutoObservable(this)

    this.rootStore = rootStore
    this.settingsService = new SettingsService()
  }

  setMainRef(ref) {
    this.mainRef = ref
  }

  loadSettingsFromBrowser() {
    const settings = Cookies.get('settings')

    if (!settings) {
      this.getSettings()
    } else {
      this.parseSettings(JSON.parse(settings))
    }

    return Promise.resolve()
  }

  parseSettings(settings) {
    this.isGrantor = settings.tenant.settlorType === 'GRANTOR'
    this.maxUploadSize = settings.maxUploadSize
  }

  getSettings() {
    return this.settingsService.settings().then((response) => {
      this.parseSettings(response)

      Cookies.set('settings', JSON.stringify(response))
    })
  }

  removeSettings() {
    this.settings = null

    Cookies.remove('settings')
  }
}

export default SettingsStore
