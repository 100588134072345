import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import Icon from 'presentation/Icon'
import c from 'classnames'
import styles from './valuationAssetItem.module.scss'

const ValuationAssetItem = ({ title, assets, expandable }) => {
  const assetInfoRef = useRef()
  const [outspread, setOutspread] = useState()

  const handleClick = useCallback(() => setOutspread(!outspread), [outspread])

  return (
    <div
      className={c(
        styles.wrapper,
        outspread && styles.outspreadItem,
        !expandable && styles.onlyTitle
      )}
      onClick={expandable ? handleClick : null}
      role="button"
      tabIndex="0"
    >
      <div className={styles.title}>
        <span className={styles.assetTitle}>{title}</span>
        {expandable && (
          <span>
            <ChevronDownIcon className={c(styles.chevronIcon, outspread && styles.outspreadIcon)} />
          </span>
        )}
      </div>
      <div
        className={c(styles.assetsInfo, outspread && styles.assetsInfoOutspread)}
        ref={assetInfoRef}
      >
        {assets.map((asset, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`asset_${i}`}
            className={styles.item}
          >
            <span className={styles.assetIcon}>
              <Icon size={17} className={styles.assetIcon} name={asset.icon} />
              {asset.name.value}
            </span>
            <span>{asset.moneyValue}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

ValuationAssetItem.propTypes = {
  title: PropTypes.string.isRequired,
  expandable: PropTypes.bool.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  assets: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default ValuationAssetItem
