import { parseValidationRules } from 'util/validation'
import Part, { TYPE_INPUT_ASSET_TRUST } from './Part'
import AssetTrustPartStore from './AssetTrustPartStore'

class AssetTrustPart extends Part {
  constructor(id, validationRules) {
    super(id, TYPE_INPUT_ASSET_TRUST)

    this.validationRules = validationRules
  }

  static fromJson({ id, validationRules = null }, onCreateInput) {
    const input = new AssetTrustPartStore()

    if (validationRules) {
      input.setValidationRules(parseValidationRules(validationRules))
    }

    onCreateInput(id, input)

    return new AssetTrustPart(id, validationRules)
  }
}

export default AssetTrustPart
