import { action, observable, reaction, computed, runInAction, makeObservable } from 'mobx'
import { Statute } from 'models/Letters'
import LettersBaseStore from './LettersBaseStore'

class StatutesStore extends LettersBaseStore {
  constructor(corporationsCreationStore) {
    super()

    this.isDirty = false
    this.isLoading = false
    this.initialized = false
    this.statutes = []
    this.hasError = false

    makeObservable(this, {
      // observables
      initialized: observable,
      statutes: observable,
      isDirty: observable,
      hasError: observable,
      // actions
      createLetter: action,
      initialize: action,
      initList: action,
      resetDirty: action,
      fillFromJson: action,
      restoreMainFromOldVersion: action,
      validate: action,
      // computeds
      draftMainLetter: computed,
      activeMainLetter: computed,
      statuteForRules: computed,
    })

    this.corporationsCreationStore = corporationsCreationStore
  }

  getJson() {
    const statutes = []

    this.statutes.forEach((statute) => {
      if (!statute.isActive) {
        statutes.push(statute.getJson())
      }
    })

    return {
      statutes,
    }
  }

  createLetter(from = null) {
    let newStatute = new Statute()

    if (from !== null) {
      newStatute = Statute.createFromStatute(from)
      newStatute.clearFile()
    }

    this.statutes.push(newStatute)
  }

  async initialize(alternativeStatusLoad) {
    this.preRequest()

    try {
      const response = await this.corporationsCreationStore.corporationService.getStatutesSection(
        alternativeStatusLoad,
        this.corporationsCreationStore.idEdition
      )

      runInAction(() => {
        this.initList(response)

        this.initialized = true

        this.onSuccessRequest()
      })

      return response
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }

    return null
  }

  initList(json) {
    if (json) {
      this.fillFromJson(json)
    }

    setTimeout(() => {
      reaction(
        () => {
          return JSON.stringify(this.getJson())
        },
        () => {
          if (!this.isDirty) {
            this.isDirty = true
          }
        },
        {
          equals: (prev, next) => prev === next && this.isDirty,
        }
      )
    })
  }

  resetDirty() {
    this.isDirty = false
  }

  fillFromJson(statutes = []) {
    this.statutes = statutes.map((statute) => Statute.createFromJson(statute))
  }

  restoreMainFromOldVersion(oldVersion) {
    this.draftMainLetter.fillFromOldVersion(oldVersion)
  }

  get draftMainLetter() {
    const drafts = this.statutes.filter((statute) => statute.isDraft)

    if (drafts.length > 0) {
      return drafts[0]
    }

    return null
  }

  get activeMainLetter() {
    const actives = this.statutes.filter((statute) => statute.isActive)

    if (actives.length > 0) {
      return actives[0]
    }

    return null
  }

  get statuteForRules() {
    const actives = this.statutes.filter(
      (statute) => statute.isExecution || statute.isActive || statute.isExecuted
    )

    if (actives.length === 1) {
      return actives[0]
    }
    if (actives.length > 1) {
      return actives.filter((statute) => statute.isExecution || statute.isExecuted)[0]
    }

    return null
  }

  validate() {
    if (
      this.corporationsCreationStore.entityStatus.isDraft &&
      !this.draftMainLetter &&
      !this.activeMainLetter
    ) {
      this.setErrorCountMainLetter()
    } else {
      this.setErrorCountMainLetter(this.validateMainLetterDraft())
    }
  }
}

export default StatutesStore
