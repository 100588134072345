import React, { useState, useEffect, useCallback } from 'react'
import UnauthorizedContainer from 'presentation/UnauthorizedContainer'
import { useTranslation } from 'react-i18next'
import SignInInput from 'presentation/SignInInput'
import Button from 'presentation/Button'
import MetaTitle from 'util/MetaTitle'
import { LOGIN } from 'routing/routes'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import LoadingRing from 'presentation/LoadingRing'
import ActivateUserStore from './ActivateUserStore'
import styles from './activateUser.module.scss'

const ActivateUser = () => {
  const { t } = useTranslation('signIn')
  const [loaded, setLoaded] = useState(false)
  const [activateUserStore] = useState(() => new ActivateUserStore())
  const { error, success, isLoading } = activateUserStore
  const history = useHistory()

  // onMount
  useEffect(() => {
    // eslint-disable-next-line
    const params = new URLSearchParams(location.search)
    activateUserStore.setId(params.get('id'))
    activateUserStore.validateToken(params.get('token')).then(() => {
      setLoaded(true)
    })
  }, [])

  const updateRPassword = useCallback((e) => activateUserStore.updateRPassword(e.target.value), [])
  const updatePassword = useCallback((e) => activateUserStore.updatePassword(e.target.value), [])

  return (
    <UnauthorizedContainer title={t('pageTitles:ActivateUser')}>
      {isLoading && (
        <div className={styles.loading}>
          <LoadingRing small />
        </div>
      )}
      {error && <div className={styles.errorBox}>{t('genericErrorPasswordUser')}</div>}
      {success && (
        <div className={styles.success}>
          <div className={styles.successBox}>{t('successMsg')}</div>
          <Button label={t('btn_success')} primary big onClick={() => history.push(LOGIN)} />
        </div>
      )}
      <MetaTitle pageTitle={t('pageTitles:activateUser')} />
      <div className={styles.container}>
        {loaded && !error && !success && (
          <>
            {activateUserStore.formError && (
              <div className={styles.errorBox}>
                {t(activateUserStore.formError.message, activateUserStore.formError.params)}
              </div>
            )}
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                activateUserStore.send()
              }}
              className={styles.form}
            >
              <SignInInput
                type="password"
                placeholder={t('password')}
                value={activateUserStore.password}
                handleChange={updatePassword}
                handleBlur={() => activateUserStore.validatePassword()}
                hasError={activateUserStore.hasError || !!activateUserStore.formError}
                autoFocus
              />
              <SignInInput
                type="password"
                placeholder={t('repeatPassword')}
                value={activateUserStore.repeatPassword || ''}
                handleChange={updateRPassword}
                handleBlur={() => activateUserStore.validatePassword()}
                hasError={activateUserStore.hasError || !!activateUserStore.formError}
              />
              <Button
                isLoading={activateUserStore.isLoading}
                label={t('btn_setPassword')}
                primary
                big
                type="submit"
              />
            </form>
          </>
        )}
      </div>
    </UnauthorizedContainer>
  )
}

export default observer(ActivateUser)
