import moment from 'moment'
import { createSchemaFromRules, DATE_INPUT } from 'util/validation'
import FormPartStore from './FormPartStore'

class DateFormPartStore extends FormPartStore {
  createFromCurrent() {
    const newFormPartStore = new DateFormPartStore()

    newFormPartStore.fill(this.store.value ? moment(this.store.value) : '')
    newFormPartStore.setValidationRules(this.validationRules)

    return newFormPartStore
  }

  fill(data) {
    this.store.setValue(data ? moment(data) : '')
  }

  get validationSchema() {
    return createSchemaFromRules(this.validationRules, DATE_INPUT)
  }
}

export default DateFormPartStore
