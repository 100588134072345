class Role {
  constructor(id, name) {
    this.id = id
    this.name = name
  }

  static fromJson({ id, name }) {
    return new Role(id, name)
  }
}

export default Role
