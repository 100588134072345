import Part, { TYPE_INPUT_ENTITIES_DATA_LIST } from './Part'
import EntitiesDataListFormPartStore from './EntitiesDataListFormPartStore'

class EntitiesDataListPart extends Part {
  constructor(id, label, dataKeys, validationRules) {
    super(id, TYPE_INPUT_ENTITIES_DATA_LIST)

    this.label = label
    this.dataKeys = dataKeys
    this.validationRules = validationRules
  }

  static fromJson({ id, display, dataKeys = [], validationRules = null }, onCreateInput) {
    const { label = '' } = display

    onCreateInput(id, new EntitiesDataListFormPartStore())

    return new EntitiesDataListPart(id, label, dataKeys, validationRules)
  }
}

export default EntitiesDataListPart
