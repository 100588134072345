import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isMobileChecker from 'util/browser'
import DockScroller from 'presentation/DockScroller'
import styles from '../authorizedNavBar.module.scss'

const subMenuUrl = (url, id, view, disabled, defaultBase) => {
  if (disabled) {
    return ''
  }

  let params = ''

  if (id) {
    params = `?id=${id}`

    if (view === 'true') {
      params += '&v=true'
    }
  }

  return `${defaultBase}${url}${params}`
}

const toggleNavBar = () => {
  document.getElementById('nav-bar').classList.toggle(styles['nav-bar-hidden'])
}

const isMobile = isMobileChecker()
const SubMenu = ({ children, collapsed }) => {
  // Habilita el boton de menu cuando se renderea un submenu
  return (
    <div
      className={c(
        styles.subMenu,
        collapsed && styles.subMenuCollapsed,
        isMobile && styles.isMobile
      )}
    >
      {!isMobile ? <DockScroller>{children}</DockScroller> : { children }}
    </div>
  )
}

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
}

SubMenu.defaultProps = {
  collapsed: false,
}

export { subMenuUrl, toggleNavBar, SubMenu }
