import { action, observable, computed, runInAction, makeObservable } from 'mobx'
import i18next from 'i18next'
import AsyncStore from 'stores/AsyncStore'
import ImageInputStore from 'stores/ImageInputStore'
import ProfileService from 'services/ProfileService'

class UserProfileStore extends AsyncStore {
  profileImage = new ImageInputStore()
  viewMode = true
  isLoadingSave = false
  requestErrors = []

  constructor(authUser) {
    super()
    this.authUser = authUser

    makeObservable(this, {
      // observables
      profileImage: observable,
      viewMode: observable,
      isLoadingSave: observable,
      requestErrors: observable,
      // actions
      reset: action,
      toggleViewMode: action,
      updateAuthUser: action,
      revertChanges: action,
      save: action,
      // computeds
      isDirty: computed,
    })

    this.profileService = new ProfileService()
    this.profileImage.setImage(authUser.profileImage)
  }

  reset() {
    this.requestErrors = []
  }

  toggleViewMode() {
    this.viewMode = !this.viewMode
    this.reset()
  }

  updateAuthUser(authUser) {
    this.authUser = authUser
    this.profileImage.setImage(authUser.profileImage)
  }

  get isDirty() {
    return this.profileImage.image !== this.authUser.profileImage
  }

  revertChanges() {
    this.profileImage.setImage(this.authUser.profileImage)
    this.toggleViewMode()
  }

  async save(successCallback) {
    if (this.isDirty) {
      this.requestErrors = []
      this.isLoadingSave = true
      this.requestProcess(() => this.save())
      try {
        await this.profileService.editProfile(this.profileImage.id)

        runInAction(() => {
          if (successCallback) {
            successCallback()
          }
          this.onSuccessRequest()
          this.isLoadingSave = false
        })
      } catch (e) {
        if (e.response.data.error.errors) {
          this.requestErrors = e.response.data.error.errors
        } else {
          this.requestErrors.push(i18next.t('common:genericError'))
        }
        this.isLoadingSave = false
        this.onErrorRequest(e)
      }
    }
  }
}

export default UserProfileStore
