import AsyncStore from 'stores/AsyncStore'
import { action, makeObservable, observable, runInAction } from 'mobx'
import TrustTransactionsAssetsService from 'services/TrustTransactionsAssetsService'
import InputStore from 'stores/InputStore'
import File from 'models/File'
import PercentageFormPartStore from 'models/FormMetadata/Parts/PercentageFormPartStore'
import OwnershipTransactionAsset from 'models/OwnershipTransactionAsset'
import dateFormat from 'util/dateHelper'

class OwnershipTransactionStore extends AsyncStore {
  constructor(id) {
    super()
    this.id = id
    this.assets = []
    this.hasVoting = new InputStore()
    this.votersOptions = []
    this.voters = []
    this.ownershipPercentageStores = []
    this.type = 'ownership'

    this.currentAsset = null
    this.currentPercentageStore = null

    this.file = new File()

    this.trustTransactionsAssetsService = new TrustTransactionsAssetsService()

    this.init()

    makeObservable(this, {
      // observables
      id: observable,
      assets: observable,
      hasVoting: observable,
      votersOptions: observable,
      voters: observable,
      file: observable,
      currentAsset: observable,
      currentPercentageStore: observable,
      // actions
      loadAssets: action,
      setHasVoting: action,
      setVoters: action,
      emptyInputs: action,
      resetErrors: action,
    })
  }

  init() {
    this.loadVotersOptions()
  }

  async loadVotersOptions() {
    try {
      this.preRequest(this.loadVotersOptions)
      const voters = await this.trustTransactionsAssetsService.loadVoters(this.id)

      runInAction(() => {
        this.votersOptions = voters
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async loadAssets() {
    try {
      this.preRequest(this.loadAssets)
      this.trustTransactionsAssetsService
        .loadAssetsByType(this.id, 'assetCorporation')
        .then((assets) => {
          runInAction(() => {
            this.assets = assets.map(OwnershipTransactionAsset.fromJson)

            assets.forEach((asset) => {
              const store = new PercentageFormPartStore()
              store.fill(asset.ownershipPercentage)
              this.ownershipPercentageStores.push(store)
            })
          })
        })
      this.onSuccessRequest()
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  async save(successCallback) {
    this.preRequest(this.save)
    const data = this.getDataJson()

    try {
      await this.trustTransactionsAssetsService.saveTransaction(this.type, data)
      runInAction(() => {
        this.emptyInputs()
        this.onSuccessRequest()
        successCallback(this.type)
      })
    } catch (e) {
      e.response?.data?.error?.errors.map((error) =>
        this.onErrorRequest(error || 'Something went wrong')
      )
    }
  }

  emptyInputs() {
    this.hasVoting.setValue(false)
    this.voters = []
    this.file = new File()
    this.currentPercentageStore.setValue('')
  }

  getDataJson() {
    const updatedAditionalData = {
      document: this.file.getJson(),
      ownership: this.currentPercentageStore.value,
      assetId: this.currentAsset.id,
    }
    if (this.hasVoting.value) {
      updatedAditionalData.voters = this.voters
    }
    const json = {}
    json.trustId = this.id
    json.name = this.currentAsset.name
    json.date = dateFormat(new Date())
    json.additionalData = updatedAditionalData
    json.withVoting = !!this.hasVoting.value
    // This field will be removed.
    json.approveAutomatically = false
    return json
  }

  setHasVoting() {
    this.hasVoting.setValue(!this.hasVoting.value)
  }

  setVoters(voters) {
    this.voters = [...voters]
  }

  resetErrors() {
    this.errors = []
  }

  setCurrentAsset(index) {
    this.currentPercentageStore = this.ownershipPercentageStores[index]
    this.currentAsset = this.assets[index]
  }
}

export default OwnershipTransactionStore
