import React from 'react'
import PropTypes from 'prop-types'
import VoterStatus from 'presentation/VoterStatus'
import styles from './viewVoteContainer.module.scss'

const ViewVoteContainer = ({ name, statusVoter }) => {
  return (
    <div className={styles.statusVoterContainer}>
      <div className={styles.voteName}>{name}</div>
      <div>
        <VoterStatus status={statusVoter} />
      </div>
    </div>
  )
}

ViewVoteContainer.propTypes = {
  name: PropTypes.string.isRequired,
  statusVoter: PropTypes.string.isRequired,
}

export default ViewVoteContainer
