import { useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { scrollBodyToElement } from 'util/scroll'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Document from 'models/Document'
import MultipleDocumentsSelection from 'presentation/MultipleDocumentsSelection'
import StoresContext from 'providers/storeContext'

const DocumentForm = ({
  literal,
  descriptions,
  large,
  store,
  handleStartUploadingFile,
  handleFinishUploadingFile,
}) => {
  const { inputFileStore, creationParametersStore } = useContext(StoresContext)
  const { t } = useTranslation('dynamicForms')
  const lastEleRef = useRef(null)

  const handleAddFile = () => {
    store.createFile()

    setTimeout(() => {
      scrollBodyToElement(document.querySelector('.fileContainer:last-child'))

      lastEleRef.current.focus()
    }, 20)
  }

  const handleRemoveFile = (file) => {
    if (file.name !== null) {
      inputFileStore.setCanNotUpload()
    }

    store.deleteFile(file)
  }

  const handleChangeFileType = (option, file) => {
    file.changeType(option)
  }

  const handleChangeDescription = (value, file) => {
    file.changeDescription(value)
  }

  const handleCreateFileType = (value, file) => {
    creationParametersStore.createFileType(value).then((type) => file.changeType(type))
  }

  const handleCertifiedFile = (file) => {
    file.changeLegalCertified(!file.legalCertified)
  }
  return (
    <MultipleDocumentsSelection
      files={store.files}
      isLoadingTypes={creationParametersStore.isLoading}
      fileTypeOptions={creationParametersStore.getOptions('file')}
      handleCreateFileType={handleCreateFileType}
      handleChangeFileType={handleChangeFileType}
      handleChangeFileDescription={handleChangeDescription}
      handelRemoveFile={handleRemoveFile}
      handleCertifiedFile={handleCertifiedFile}
      handleAddFile={handleAddFile}
      fileTypeName="category"
      fileName="file"
      certifyLabel={t('uploadDocumentCertification')}
      addDocumentLabel={t('addDocument')}
      literal={literal}
      descriptions={descriptions}
      handleChangeDescription={handleChangeDescription}
      large={large}
      lastEleRef={lastEleRef}
      handleStartUploadingFile={handleStartUploadingFile}
      handleFinishUploadingFile={handleFinishUploadingFile}
    />
  )
}

DocumentForm.propTypes = {
  store: PropTypes.instanceOf(Document).isRequired,
  literal: PropTypes.bool,
  descriptions: PropTypes.bool,
  large: PropTypes.bool,
  handleStartUploadingFile: PropTypes.func,
  handleFinishUploadingFile: PropTypes.func,
}

DocumentForm.defaultProps = {
  literal: false,
  descriptions: false,
  large: false,
  handleStartUploadingFile: null,
  handleFinishUploadingFile: null,
}

export default observer(DocumentForm)
