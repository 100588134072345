import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import EyeIcon from 'mdi-react/EyeIcon'
import EyeOffIcon from 'mdi-react/EyeOffIcon'
import c from 'classnames'
import styles from './signInInput.module.scss'

const SignInInput = ({
  value,
  handleChange,
  handleBlur,
  type,
  placeholder,
  hasError,
  autoFocus,
}) => {
  const [focus, setFocus] = useState(false)
  const inputRef = useRef()

  const [inputType, setInputType] = useState(type)

  const passwordIcon = (typeInput, onClick) => {
    if (typeInput === 'password') {
      return (
        <EyeIcon
          className={c(styles.passwordIcon, !focus && styles.iconNoFocus)}
          size={20}
          onClick={onClick}
        />
      )
    }

    return (
      <EyeOffIcon
        className={c(styles.passwordIcon, !focus && styles.iconNoFocus)}
        size={20}
        onClick={onClick}
      />
    )
  }

  const handleToggleType = useCallback(
    () => (inputType === 'password' ? setInputType('text') : setInputType('password')),
    [inputType]
  )

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  return (
    <div className={styles.signInInputContainer}>
      <input
        ref={inputRef}
        type={inputType}
        value={value}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          if (handleBlur) handleBlur()
          setFocus(false)
        }}
        className={c(
          styles.signInInput,
          focus && styles.signInInputFocused,
          hasError && styles.error,
          value !== '' && styles.hasValue
        )}
      />
      <div
        className={c(
          styles.floatingLabel,
          focus && styles.inputFocused,
          value !== '' && styles.hasValue
        )}
      >
        {placeholder}
      </div>
      {type === 'password' && passwordIcon(inputType, handleToggleType)}
    </div>
  )
}

SignInInput.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
}

SignInInput.defaultProps = {
  type: 'text',
  autoFocus: false,
  placeholder: '',
  hasError: false,
  handleBlur: null,
}

export default SignInInput
