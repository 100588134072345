const scrollTo = (element, to, duration) => {
  const start = element.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 20

  const animateScroll = () => {
    currentTime += increment

    // eslint-disable-next-line no-param-reassign
    element.scrollTop = Math.easeInOutQuad(currentTime, start, change, duration)

    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }

  animateScroll()
}

const scrollBodyToElement = (element, offset = 0, useScrollIntoView = true) => {
  if (offset === 0 && useScrollIntoView) {
    element.scrollIntoView({
      behavior: 'smooth',
    })
  } else if (element) {
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = (t, b, c, d) => {
  let time = t
  time /= d / 2
  if (time < 1) return (c / 2) * time * time + b
  time -= 1
  return (-c / 2) * (time * (time - 2) - 1) + b
}

const scrollToCoord = (top, bottom, left, right) => {
  window.scrollTo({ top, bottom, left, right, behavior: 'smooth' })
}

export default scrollTo
export { scrollToCoord }
export { scrollBodyToElement }
