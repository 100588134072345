import ListDate from 'models/ListDate'

class ActiveEntity {
  constructor(id, description, name, type, created, updated) {
    this.id = id
    this.description = description
    this.name = name
    this.type = type
    this.created = created
    this.updated = updated
  }

  static fromJson({ id, description, name, type, created, updated }) {
    return new ActiveEntity(
      id,
      description,
      name,
      type,
      ListDate.fromJson(created),
      ListDate.fromJson(updated)
    )
  }
}

export default ActiveEntity
