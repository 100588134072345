import axios from 'axios'
import { API_URL } from 'services/config'
import { CorporationUser } from 'models/PermissionUser'

/* eslint-disable class-methods-use-this */
class CorporationUsersService {
  searchPermissionUsers = (corporationId, term) =>
    axios
      .get(`${API_URL}/tenant/financialStructure/users/${corporationId}?search=${term}`)
      .then((response) => {
        return {
          users: response.data.data.map((listUser) => CorporationUser.fromJson(listUser)),
        }
      })

  listAssignedUsers = (corporationId) =>
    axios.get(`${API_URL}/tenant/financialStructure/${corporationId}/users`).then((response) =>
      response.data.data.map((listUser) => {
        return CorporationUser.fromJson(listUser)
      })
    )

  savePermissionUsers = (corporationId, json) =>
    axios
      .post(`${API_URL}/tenant/financialStructure/${corporationId}/users`, { data: json })
      .then((response) => response.data.data)
}

export default CorporationUsersService
