import { makeAutoObservable } from 'mobx'
import File from 'models/File'

class Document {
  constructor() {
    this.filesList = []
    this.error = false
    this.errors = []
    makeAutoObservable(this)

    this.createFile()
  }

  fillFromDocument(document) {
    this.filesList = document.filesList.map((fileItem) => File.fromFile(fileItem))
  }

  fillFromJson(document) {
    this.filesList = document.map((json) => File.fromJson(json))
  }

  deleteFile(file) {
    this.filesList.remove(file)
  }

  createFile() {
    this.filesList.push(new File())
  }

  get files() {
    return this.filesList
  }

  getFileById(id) {
    return this.filesList.find((file) => file.id === id)
  }

  getJson() {
    const filesJson = []

    this.filesList.forEach((file) => {
      // filtro los files que estan vacios, para no guardarlo sin datos
      if (!file.empty) {
        filesJson.push(file.getJson())
      }
    })

    return filesJson
  }

  setError(value, message) {
    this.error = value
    this.errors.push(message)
  }
}

export default Document
