import { observable, computed, action, makeObservable } from 'mobx'

const VIEW_FORM = 'form'
const VIEW_HISTORY = 'history'
const VIEW_VERSIONS = 'versions'

class LettersSectionSecondaryBaseStore {
  currentView = VIEW_VERSIONS
  deleteDraftModal = false
  newDraftFromModal = false
  draftEditionMode = false
  deleteDraftModalRevoke = false

  constructor() {
    makeObservable(this, {
      // observables
      currentView: observable,
      deleteDraftModal: observable,
      newDraftFromModal: observable,
      draftEditionMode: observable,
      deleteDraftModalRevoke: observable,
      // actions
      reset: action,
      viewHistory: action,
      viewForm: action,
      viewVersions: action,
      showDeleteDraftModal: action,
      hideDeleteDraftModal: action,
      showNewDraftFromModal: action,
      hideNewDraftFromModal: action,
      showDraftEditionMode: action,
      hideDraftEditionMode: action,
      // computeds
      formActive: computed,
      historyActive: computed,
      versionsActive: computed,
    })

    this.reset()
  }

  reset() {
    this.currentView = VIEW_VERSIONS
    this.deleteDraftModal = false
    this.newDraftFromModal = false
    this.draftEditionMode = false
    this.deleteDraftModalRevoke = false
  }

  viewHistory() {
    this.currentView = VIEW_HISTORY
  }

  viewForm() {
    this.currentView = VIEW_FORM
  }

  viewVersions() {
    this.currentView = VIEW_VERSIONS
  }

  get formActive() {
    return this.currentView === VIEW_FORM
  }

  get historyActive() {
    return this.currentView === VIEW_HISTORY
  }

  get versionsActive() {
    return this.currentView === VIEW_VERSIONS
  }

  showDeleteDraftModal(revokeCurrent = false) {
    this.deleteDraftModal = true
    this.deleteDraftModalRevoke = revokeCurrent
  }

  hideDeleteDraftModal() {
    this.deleteDraftModal = false
    this.deleteDraftModalRevoke = false
  }

  showNewDraftFromModal() {
    this.newDraftFromModal = true
  }

  hideNewDraftFromModal() {
    this.newDraftFromModal = false
  }

  showDraftEditionMode() {
    this.draftEditionMode = true
  }

  hideDraftEditionMode() {
    this.draftEditionMode = false
    this.viewVersions()
  }
}

export default LettersSectionSecondaryBaseStore
