import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MetaTitle = ({ pageTitle }) => {
  const { t } = useTranslation('pageTitles')

  return (
    <Helmet>
      <title>{`${pageTitle} | ${t('siteName')}`}</title>
    </Helmet>
  )
}

MetaTitle.propTypes = {
  pageTitle: PropTypes.string.isRequired,
}

export default MetaTitle
