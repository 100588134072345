import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import SetPasswordStore from 'stores/SetPasswordStore'
import Button from 'presentation/Button'
import SignInInput from 'presentation/SignInInput'
import styles from './formChangePassword.module.scss'

const FormChangePassword = ({ isLoading, submit, setPasswordStore, additionalContent }) => {
  const { t } = useTranslation('signIn')
  const updatePassword = useCallback((e) => setPasswordStore.updatePassword(e.target.value), [])
  const updateRPassword = useCallback(
    (e) => setPasswordStore.updateRepeatPassword(e.target.value),
    []
  )

  return (
    <div>
      {setPasswordStore.error && <div className={styles.errorBox}>{setPasswordStore.error}</div>}
      <form action="" onSubmit={submit} className={styles.form}>
        <SignInInput
          type="password"
          placeholder={t('password')}
          value={setPasswordStore.password.value}
          handleChange={updatePassword}
          handleBlur={() => setPasswordStore.validatePassword()}
          hasError={setPasswordStore.password.error}
          autoFocus
        />
        <SignInInput
          type="password"
          placeholder={t('repeatPassword')}
          value={setPasswordStore.repeatPassword.value}
          handleChange={updateRPassword}
          handleBlur={() => setPasswordStore.validatePassword()}
          hasError={setPasswordStore.repeatPassword.error}
        />
        <Button isLoading={isLoading} label={t('btn_setPassword')} primary big type="submit" />
        {additionalContent && <div className={styles.additionalContent}>{additionalContent}</div>}
      </form>
    </div>
  )
}

FormChangePassword.propTypes = {
  setPasswordStore: PropTypes.instanceOf(SetPasswordStore).isRequired,
  additionalContent: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
}

FormChangePassword.defaultProps = {
  additionalContent: null,
}

export default observer(FormChangePassword)
