import { useCallback, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import FiduciaryStructureTransactionsValuationStore from 'stores/FiduciaryStructureTransactionsValuationStore'
import TransactionsValuationForm from 'scenes/TransactionsValuation/TransactionsValuationForm'
import { successToast } from 'helpers/Toast'
import ButtonBack, { useBackButtonURL } from 'presentation/ButtonBack'
import PageTitle from 'presentation/PageTitle'
import LoadingRing from 'presentation/LoadingRing'
import WithdrawRow from 'presentation/WithdrawRow/WithdrawRow'
import CreationModal from 'presentation/CreationModal'
import FiduaciaryStructureHeaderLink from 'presentation/FiduaciaryStructureHeaderLink'
import NoResultsList from 'presentation/NoResultsList'
import Modal from 'scenes/Modal'
import StoresContext from 'providers/storeContext'
import styles from './trustTransactionsValuation.module.scss'

const FiduciaryStructureTransactionsValuation = () => {
  const { t } = useTranslation('transactions')
  const { fiduciaryStructureId } = useParams()
  const { search } = useLocation()
  const [showValuationChangeModal, setShowValuationChangeModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { creationParametersStore } = useContext(StoresContext)
  const structureType = new URLSearchParams(search).get('structure')
  const backButtonURL = useBackButtonURL()

  const [fiduciaryStructureTransactionsValuationStore] = useState(
    () =>
      new FiduciaryStructureTransactionsValuationStore(
        fiduciaryStructureId,
        structureType,
        creationParametersStore
      )
  )

  const handleClickNext = useCallback((id) => {
    fiduciaryStructureTransactionsValuationStore.selectAsset(id)
    setShowValuationChangeModal(true)
    fiduciaryStructureTransactionsValuationStore.loadAssetDetails()
  }, [])

  const handleCloseValuationChangeModal = useCallback(() => {
    setShowValuationChangeModal(false)
    fiduciaryStructureTransactionsValuationStore.selectAsset()
  }, [])

  const handleSaveValuationChangeModal = useCallback(() => {
    setShowConfirmModal(true)
  }, [])

  const handleCancelConfirmModal = useCallback(() => {
    setShowConfirmModal(false)
  }, [])

  const handleSuccessConfirmModal = useCallback(() => {
    fiduciaryStructureTransactionsValuationStore.save(() => {
      successToast(t('assetValuationSaved'), null, 'assetValuationSaved').show()
    })
    setShowValuationChangeModal(false)
    setShowConfirmModal(false)
  }, [])

  const handleCloseErrorModal = useCallback(() => {
    fiduciaryStructureTransactionsValuationStore.closeMultipleValuationChangeErrorModal()
  }, [])

  useEffect(() => {
    creationParametersStore.init()
    fiduciaryStructureTransactionsValuationStore.loadAssets()
  }, [])

  if (fiduciaryStructureTransactionsValuationStore.isLoading) {
    return <LoadingRing center />
  }

  return (
    <div>
      <FiduaciaryStructureHeaderLink
        text={fiduciaryStructureTransactionsValuationStore.fiduciaryStructureName}
        route={fiduciaryStructureTransactionsValuationStore.fiduciaryStructureRoute}
      />
      <PageTitle title={t('changeValuationTitle')} />
      <div className={styles.assetContainer}>
        {!fiduciaryStructureTransactionsValuationStore.assets.length && (
          <NoResultsList topText={t('noAssetResults')} />
        )}
        {fiduciaryStructureTransactionsValuationStore.assets.map((asset) => (
          <div key={asset.id} className={styles.elementRow}>
            <WithdrawRow
              asset={asset}
              name={asset.name}
              type={t(asset.type)}
              handleClickNext={() => handleClickNext(asset.id)}
              noAmount
            />
          </div>
        ))}
        <div className={styles.actionButtons}>
          <ButtonBack to={backButtonURL} />
        </div>
      </div>
      <CreationModal
        handleClose={handleCloseValuationChangeModal}
        visible={showValuationChangeModal}
        title={t('changeValuationTitle')}
      >
        <TransactionsValuationForm
          instanceEntity={fiduciaryStructureTransactionsValuationStore.instanceEntity}
          onCancelForm={handleCloseValuationChangeModal}
          onSaveForm={handleSaveValuationChangeModal}
        />
      </CreationModal>
      <Modal
        name={t('confirmModal')}
        open={showConfirmModal}
        onCancel={handleCancelConfirmModal}
        onSuccess={handleSuccessConfirmModal}
      >
        <div>{t('areYouSureChangeValuation')}</div>
        <div>{t('ruleChangeWarning')}</div>
      </Modal>
      <Modal
        name={t('errorModal')}
        title={t('errorModalTitle')}
        open={fiduciaryStructureTransactionsValuationStore.multipleValuationChangeErrorModal}
        onSuccess={handleCloseErrorModal}
        okOnly
      >
        <div>{t('multipleValuationChangeError')}</div>
      </Modal>
    </div>
  )
}

export default observer(FiduciaryStructureTransactionsValuation)
