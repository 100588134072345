import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PlusIcon from 'mdi-react/PlusIcon'
import TrustDocumentArchive from 'presentation/TrustDocumentArchive'
import TrustDocumentArchiveItem from 'presentation/TrustDocumentArchiveItem'
import Button from 'presentation/Button'
import LettersBaseStore from 'stores/LettersBaseStore'
import styles from './secondaryLetterVersions.module.scss'

const SecondaryLetterVersions = ({
  mainStore,
  handleNewLetter,
  handleEditDraft,
  isViewMode,
  letterTypeNameSingular,
}) => {
  const { t } = useTranslation('lettersForms')

  return (
    <>
      {!mainStore.draftSecondaryLetter && !isViewMode && (
        <div className={styles.titleContainer}>
          <Button
            label={t('newLetter', { letterTypeName: letterTypeNameSingular })}
            icon={<PlusIcon size={20} />}
            onClick={handleNewLetter}
            iconPositionLeft
            small
          />
        </div>
      )}
      <TrustDocumentArchive>
        {mainStore.usableSecondaryLetters.map((usableLetter) => (
          <TrustDocumentArchiveItem
            trustDocumentVersion={usableLetter.letterVersion}
            errors={usableLetter.errorsCount}
            key={`version_${usableLetter.id}`}
            handleDraftEdit={handleEditDraft !== null ? () => handleEditDraft(usableLetter) : null}
            showStatus
          />
        ))}
      </TrustDocumentArchive>
    </>
  )
}

SecondaryLetterVersions.propTypes = {
  mainStore: PropTypes.instanceOf(LettersBaseStore).isRequired,
  handleNewLetter: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool.isRequired,
  letterTypeNameSingular: PropTypes.string.isRequired,
  handleEditDraft: PropTypes.func,
}

SecondaryLetterVersions.defaultProps = {
  handleEditDraft: null,
}

export default observer(SecondaryLetterVersions)
