import axios from 'axios'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import { API_URL } from '../config'

/* eslint-disable class-methods-use-this */
class TrustGovernorsService {
  listGovernors = (trustId) => {
    const params = { page: 1 }
    return axios
      .get(`${API_URL}/tenant/trusts/${trustId}/governors/candidates`, { params })
      .then((response) =>
        response.data.data.map((governor) => RuleAssetGovernor.fromJson(governor))
      )
  }
}

export default TrustGovernorsService
