import React from 'react'
import PropTypes from 'prop-types'
import CheckBoldIcon from 'mdi-react/CheckBoldIcon'
import c from 'classnames'
import styles from './checkboxInput.module.scss'

const handleInputChange = (e, onChange) => {
  onChange(e)
  return null
}

const CheckboxInput = ({ label, disabled, onChange, error, checked, ...props }) => {
  return (
    <div className={styles.checkbox}>
      <div
        role="button"
        tabIndex={0}
        onClick={onChange}
        className={c(styles.checkmark, checked && styles.show)}
      >
        <CheckBoldIcon size={10} />
      </div>
      <input
        {...props}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => handleInputChange(e, onChange)}
        className={c(styles.input, error && styles.error)}
      />
      {label && <div className={c(styles.label, error && styles.error)}>{label}</div>}
    </div>
  )
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

CheckboxInput.defaultProps = {
  checked: '',
  label: '',
  error: false,
  onChange: null,
  disabled: false,
}

export default CheckboxInput
