import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './tableItemName.module.scss'

const TableItemName = ({ name, type, icon, classNames, iconClassNames }) => (
  <div className={c(styles.itemName, classNames)}>
    {icon && (
      <span className={c(styles.icon, iconClassNames)} title={type}>
        {icon}
      </span>
    )}
    {name}
  </div>
)

TableItemName.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.node,
  classNames: PropTypes.string,
  iconClassNames: PropTypes.string,
}

TableItemName.defaultProps = {
  icon: null,
  classNames: null,
  iconClassNames: null,
}

export default TableItemName
