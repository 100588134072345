import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import moneyValueFormat from 'util/numberHelper'
import { RuleAsset } from 'models/Rule'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import Icon from 'presentation/Icon'
import AssetValue from 'presentation/RuleAssetFormItem/AssetValue' // @todo cambiar AssetValue a un component propio
import styles from './ruleAssetGraphicalView.module.scss'
// import RuleAssetGraphicalViewNoAsset from 'presentation/RuleAssetGraphicalViewNoAsset'

const GraphicalViewBubble = ({ icon, title, content, governors }) => (
  <div className={styles.bubble}>
    <div className={styles.bubbleTitle}>
      <Icon className={styles.bubbleIcon} name={icon} size={18} />
      <span title={title} className={styles.text}>
        {title}
      </span>
    </div>
    <div className={styles.bubbleContent}>{content}</div>
    <div className={styles.bubbleGovernorContainer}>
      {governors?.map((governor) => (
        <div className={styles.bubbleGovernor} key={governor.contactId}>
          {governor.fullName}
        </div>
      ))}
    </div>
  </div>
)

const beneficiaryDistribution = (ruleBeneficiary, selectedAsset) => {
  if (ruleBeneficiary.distribution.isPercentage) {
    const assetValue = selectedAsset.value.value ? selectedAsset.value.value * 1 : 0
    const distributionValue = ruleBeneficiary.distribution.value.value
      ? ruleBeneficiary.distribution.value.value * 1
      : 0
    const percentageValue = Math.round((assetValue * distributionValue) / 100)

    return `${distributionValue}${ruleBeneficiary?.distribution?.type?.value} - ${moneyValueFormat(
      percentageValue,
      selectedAsset?.currency?.value?.value
    )}`
  }

  return moneyValueFormat(
    ruleBeneficiary?.distribution?.value?.value * 1,
    selectedAsset?.currency?.value?.value
  )
}

const RuleAssetGraphicalView = ({ ruleAsset, assets, beneficiaries, governors }) => {
  const { t } = useTranslation('rulesCreation')
  let selectedAsset = null
  const selectedBeneficiaries = []

  assets.forEach((asset) => {
    if (ruleAsset.assetId === asset.id) {
      selectedAsset = asset
    }
  })

  beneficiaries.forEach((beneficiary) => {
    ruleAsset.beneficiaries.forEach((ruleAssetBeneficiary) => {
      if (beneficiary.id === ruleAssetBeneficiary.beneficiaryId) {
        selectedBeneficiaries.push({ beneficiary, ruleAssetBeneficiary })
      }
    })
  })

  if (!selectedAsset) {
    return null
    // return <RuleAssetGraphicalViewNoAsset />
  }

  return (
    <div className={styles.container}>
      <div
        className={c(styles.partAsset, selectedBeneficiaries.length && styles.partAssetNoBenefs)}
      >
        <GraphicalViewBubble
          icon={selectedAsset.icon}
          title={selectedAsset.name.value}
          content={<AssetValue asset={selectedAsset} />}
          governors={governors}
        />
      </div>
      <div className={styles.partBenefs}>
        {selectedBeneficiaries.length ? (
          selectedBeneficiaries.map(({ beneficiary, ruleAssetBeneficiary }, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`selectedbenef_${i}`}
              className={c(
                styles.benefBubbleContainer,
                selectedBeneficiaries.length === 1 && styles.lonely
              )}
            >
              <GraphicalViewBubble
                icon={beneficiary.icon}
                title={beneficiary.name.value}
                content={beneficiaryDistribution(ruleAssetBeneficiary, selectedAsset)}
              />
            </div>
          ))
        ) : (
          <div className={styles.noBenefs}>{t('noBeneficiariesAsset')}</div>
        )}
      </div>
    </div>
  )
}

RuleAssetGraphicalView.propTypes = {
  ruleAsset: PropTypes.instanceOf(RuleAsset).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  assets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  beneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  governors: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

GraphicalViewBubble.propTypes = {
  icon: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.any]),
  title: PropTypes.string.isRequired,
  governors: PropTypes.arrayOf(PropTypes.instanceOf(RuleAssetGovernor)),
}

GraphicalViewBubble.defaultProps = {
  icon: null,
  content: '',
  governors: null,
}

export default RuleAssetGraphicalView
