import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import FormLabel from 'presentation/FormLabel'
import styles from './literalValue.module.scss'

const LiteralValue = ({ label, value, borderBottom, icon, handleClick, print }) => (
  <label className={c(styles.literalLabel, borderBottom && styles.borderBottom)}>
    {label && <FormLabel label={label} viewMode />}
    <div className={c(styles.literalValue, icon && styles.icon, print && styles.print)}>
      <div>{value !== null && value !== '' ? value : '-'}</div>
      <div
        className={c(handleClick && styles.iconClick)}
        onClick={handleClick}
        role="button"
        tabIndex="0"
      >
        {icon}
      </div>
    </div>
  </label>
)

LiteralValue.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
  icon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  borderBottom: PropTypes.bool,
  print: PropTypes.bool,
}

LiteralValue.defaultProps = {
  label: null,
  handleClick: null,
  value: null,
  icon: null,
  borderBottom: true,
  print: false,
}

export default LiteralValue
