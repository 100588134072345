import Part, { TYPE_INPUT_READ_ONLY } from './Part'
import FormPartStore from './FormPartStore'

class ReadOnlyPart extends Part {
  constructor(id, label) {
    super(id, TYPE_INPUT_READ_ONLY)

    this.label = label
  }

  static fromJson({ id, display }, onCreateInput) {
    const { label = '' } = display

    onCreateInput(id, new FormPartStore())

    return new ReadOnlyPart(id, label)
  }
}

export default ReadOnlyPart
