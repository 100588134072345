import React from 'react'
import PropTypes from 'prop-types'
import Asset from 'models/Asset'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import LiteralValue from 'presentation/LiteralValue'
import Button from 'presentation/Button'
import AssetValue from './AssetValue'
import styles from './assetSelection.module.scss'

const AssetSelection = ({
  selectedAsset,
  options,
  selectedOption,
  onChange,
  showDeleteButton,
  handleDeleteAsset,
  error,
}) => (
  <div className={styles.container}>
    <CreationInput
      as={FormSelect}
      name="selectAsset"
      options={options}
      value={selectedOption}
      onChange={onChange}
      error={error}
    />
    <CreationInput
      as={LiteralValue}
      name="value"
      value={<AssetValue asset={selectedAsset} />}
      borderBottom={false}
    />
    {showDeleteButton && (
      <Button onClick={handleDeleteAsset} icon={<TrashCanOutlineIcon />} circle secondary />
    )}
  </div>
)

AssetSelection.propTypes = {
  selectedAsset: PropTypes.instanceOf(Asset),
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  selectedOption: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  handleDeleteAsset: PropTypes.func.isRequired,
  error: PropTypes.bool,
}

AssetSelection.defaultProps = {
  selectedAsset: null,
  selectedOption: null,
  error: false,
}

export default AssetSelection
