import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import RuleAssetInfo from 'presentation/RuleAssetInfo'
import Rule from 'models/Rule'
import styles from './ruleViewItemBeneficiaries.module.scss'

const MAX_ASSETS_SHOWN = 3

const RuleViewItemBeneficiaries = ({ trustBeneficiaries, trustAssets, rule, handleViewMore }) => {
  const { t } = useTranslation('rulesCreation')
  let ruleBenefs = []
  let leftOver = 0

  rule.assets.forEach((ruleAsset) => {
    const selectedBeneficiaries = []

    ruleAsset.beneficiaries.forEach((ruleAssetBeneficiary) => {
      trustBeneficiaries.forEach((beneficiary) => {
        if (beneficiary.id === ruleAssetBeneficiary.beneficiaryId) {
          selectedBeneficiaries.push({ beneficiary })
        }
      })
    })

    trustAssets.forEach((asset) => {
      if (asset.id === ruleAsset.assetId) {
        ruleBenefs.push({ asset, selectedBeneficiaries })
      }
    })
  })

  // se mostraran como maximo n assets
  if (ruleBenefs.length > MAX_ASSETS_SHOWN) {
    leftOver = ruleBenefs.length - MAX_ASSETS_SHOWN
    ruleBenefs = ruleBenefs.splice(0, MAX_ASSETS_SHOWN)
  }

  return ruleBenefs.length > 0 ? (
    <div className={styles.assetsInfo}>
      {ruleBenefs.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <RuleAssetInfo item={item} key={`ruleAsset_${i}`} />
      ))}
      {leftOver > 0 && (
        <div className={styles.andXMore} onClick={handleViewMore} role="button" tabIndex="0">
          {t('andXMore', { amount: leftOver })}
        </div>
      )}
    </div>
  ) : null
}

RuleViewItemBeneficiaries.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  rule: PropTypes.instanceOf(Rule).isRequired,
  handleViewMore: PropTypes.func,
}

RuleViewItemBeneficiaries.defaultProps = {
  handleViewMore: null,
}

export default RuleViewItemBeneficiaries
