import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import styles from './contactErrorsModalContent.module.scss'

const ContactErrorsModalContent = () => {
  const { t } = useTranslation('contactsCreation')
  const { contactsCreationStore } = useContext(StoresContext)

  return (
    <div className={styles.modalContainer}>
      <div className={styles.message}>{t('errorsDescription')}</div>
      {contactsCreationStore &&
        contactsCreationStore.validationErrors.map((error, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.errorItem} key={`validation_error_${i}`}>
            {error}
          </div>
        ))}
    </div>
  )
}

export default observer(ContactErrorsModalContent)
