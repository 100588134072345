import React, { useContext, useState, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import StoresContext from 'providers/storeContext'
import MetaTitle from 'util/MetaTitle'
import { CORPORATIONS_CREATION } from 'routing/routes'
import StatusFilter from 'presentation/StatusFilter'
import { CREATE_CORPORATION } from 'stores/AuthStore'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import CorporationsTableItem from 'presentation/CorporationsTableItem'
import CorporationsListStore from './CorporationsListStore'

const CorporationsList = () => {
  const [corporationsListStore] = useState(() => new CorporationsListStore())
  const { hotkeyStore } = useContext(StoresContext)
  const [columns, setColumns] = useState([])
  const { t } = useTranslation('corporations')
  const history = useHistory()

  const configureHotKeys = useCallback(() => {
    hotkeyStore.setConfig('corporationList', [
      {
        shortcut: 'alt+c',
        handler: () => {
          history.push(CORPORATIONS_CREATION)
        },
      },
    ])
  }, [])

  const handleSetShowDropdownOrder = useCallback((value) => {
    corporationsListStore.setShowDropdownOrder(value)
  }, [])

  const handleSetShowDropdownFilter = useCallback((value) => {
    corporationsListStore.setShowDropdownFilter(value)
  }, [])

  useEffect(() => {
    configureHotKeys()

    setColumns([
      {
        name: 'corporationName',
        order: true,
        inverted: true,
        transName: t('name'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'controllingPartyName',
        order: false,
        inverted: true,
        transName: t('controllingPartyName'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'status',
        order: false,
        inverted: false,
        transName: t('common:status'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'actions',
        order: false,
        inverted: false,
        transName: t('common:actions'),
        cssGridDefinition: '1fr',
      },
    ])

    corporationsListStore.loadCorporations()

    return () => hotkeyStore.removeConfig('corporationList')
  }, [])

  const handlePageChange = useCallback(
    (pageTo) => corporationsListStore.changeActivePage(pageTo),
    []
  )

  const handleCreateCorporation = useCallback(() => history.push(CORPORATIONS_CREATION), [])

  const handleSearch = useCallback(
    (value) =>
      corporationsListStore.loadCorporations(
        value,
        null,
        corporationsListStore.orderDirection,
        corporationsListStore.selectedStatusFilter,
        true
      ),
    []
  )

  const handleChangeOrder = useCallback((orderParamter, orderDirection) => {
    corporationsListStore.setOrderDirection(orderDirection)
    corporationsListStore.loadCorporations(
      null,
      orderParamter.includes('corporation')
        ? orderParamter.replace('corporation', '').toLowerCase()
        : orderParamter,
      corporationsListStore.orderDirection,
      corporationsListStore.selectedStatusFilter,
      true
    )
  }, [])

  const handleChangeFilter = useCallback((status) => {
    corporationsListStore.setSelectedStatusFilter(status)

    if (corporationsListStore.selectedStatusFilter) {
      corporationsListStore.setIsFiltered(true)
    } else {
      corporationsListStore.setIsFiltered(false)
    }

    corporationsListStore.loadCorporations(
      null,
      null,
      corporationsListStore.orderDirection,
      corporationsListStore.selectedStatusFilter,
      true
    )
  }, [])

  const { visibleCorporations } = corporationsListStore

  return (
    <>
      <MetaTitle pageTitle={t('pageTitles:corporations')} />
      <MainSectionList
        setShowDropdownFilter={handleSetShowDropdownFilter}
        setShowDropdownOrder={handleSetShowDropdownOrder}
        hasFilterStatus={corporationsListStore.isFiltered}
        currentPage={corporationsListStore.activePage}
        isLoading={corporationsListStore.isLoading}
        totalPages={corporationsListStore.pages}
        handleCreation={handleCreateCorporation}
        creationPermission={CREATE_CORPORATION}
        sectionNameSingular={t('corporation')}
        sectionNamePlural={t('corporations')}
        creationRoute={CORPORATIONS_CREATION}
        handleChangeOrder={handleChangeOrder}
        handlePageChange={handlePageChange}
        sectionItems={visibleCorporations}
        handleSearch={handleSearch}
        tableComponent={
          <MainSectionTable
            t={t}
            moreFilters={
              <StatusFilter
                showDropdownFilter={corporationsListStore.showDropdownFilter}
                setShowDropdownFilter={handleSetShowDropdownFilter}
                handleChangeFilter={handleChangeFilter}
              />
            }
            showDropdownOrder={corporationsListStore.showDropdownOrder}
            setShowDropdownOrder={handleSetShowDropdownOrder}
            isLoading={corporationsListStore.isLoading}
            listItems={visibleCorporations}
            onOrdered={handleChangeOrder}
            columns={columns}
            view="list"
            component={({ listItem, view: cView }) => (
              <CorporationsTableItem
                view={cView}
                listItem={listItem}
                link={`${CORPORATIONS_CREATION}?id=${listItem.id}&v=true`}
                isCorp
              />
            )}
          />
        }
      />
    </>
  )
}

export default observer(CorporationsList)
