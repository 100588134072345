import { action, observable, runInAction, computed, makeObservable } from 'mobx'
import i18next from 'i18next'
import DynamicFormsStore from 'stores/DynamicFormsStore'
import InputStore from 'stores/InputStore'
import AsyncStore from 'stores/AsyncStore'
import RolesService from 'services/RolesService'
import { pullAll } from 'lodash'

class RolesCreationStore extends DynamicFormsStore {
  isDirty = false
  allPermissions = []
  selectedPermissions = []
  editable = null
  permissionsErrorMsg = ''
  idEdition = null
  requestErrors = []
  deleteErrors = []

  constructor() {
    super()

    makeObservable(this, {
      // observables
      isDirty: observable,
      allPermissions: observable,
      selectedPermissions: observable,
      editable: observable,
      permissionsErrorMsg: observable,
      requestErrors: observable,
      deleteErrors: observable,
      // actions
      fillPermissions: action,
      loadRole: action,
      clearRequestErrors: action,
      delete: action,
      save: action,
      changeName: action,
      setIsDirty: action,
      removeIsDirty: action,
      addSelectedPermissions: action,
      removeSelectedPermissions: action,
      // computeds
      visibleAllPermissions: computed,
    })

    super.resetAttributes()

    this.isLoading = true
    this.rolesService = new RolesService()
    this.roleName = new InputStore()
  }

  async fillPermissions() {
    this.preRequest(this.fillPermissions)

    try {
      const response = await this.rolesService.listPermissions()

      runInAction(() => {
        this.allPermissions = response
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  async loadRole(id) {
    this.preRequest(() => this.loadRole(id))
    super.setIsEdition(id)
    this.isDirty = false

    try {
      const response = await this.rolesService.getRole(id)

      runInAction(() => {
        this.idEdition = id
        this.roleName.setValue(response.name)
        this.editable = response.editable
        this.selectedPermissions = response.permissions

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }
  }

  getJson() {
    return {
      name: this.roleName.value,
      permissions: this.selectedPermissions,
    }
  }

  validate() {
    const validRoleName = this.validateRoleName()
    const validPermissions = this.validatePermissions()

    return validRoleName && validPermissions
  }

  validateRoleName() {
    this.roleName.clearError()

    if (this.roleName.value.trim() === '') {
      this.roleName.setError(true, i18next.t('validation:requiredField'))

      return false
    }

    return true
  }

  validatePermissions() {
    this.permissionsErrorMsg = ''

    if (this.selectedPermissions.length === 0) {
      this.permissionsErrorMsg = i18next.t('validation:requiredField')

      return false
    }

    return true
  }

  clearRequestErrors() {
    this.requestErrors = []
  }

  async delete(onSuccessCallback, onErrorCallback) {
    this.deleteErrors = []
    try {
      await this.rolesService.deleteRole(this.idEdition)

      onSuccessCallback()
    } catch (e) {
      runInAction(() => {
        this.deleteErrors = e.response.data.error.errors ? e.response.data.error.errors : []
        onErrorCallback()
      })
    }
  }

  async save(callbackSuccess) {
    this.removeIsDirty()
    if (!this.validate()) {
      return
    }
    this.clearRequestErrors()
    this.loadingSave = true

    this.requestProcess(() => this.save(callbackSuccess))
    try {
      const data = this.getJson()

      if (this.idEdition) {
        await this.rolesService.saveRole(this.idEdition, data)
      } else {
        await this.rolesService.addRole(data)
      }

      this.loadingSave = false

      callbackSuccess()
    } catch (e) {
      runInAction(() => {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        } else {
          this.requestErrors = e.response.data.error.errors
            ? e.response.data.error.errors
            : [i18next.t('validation:genericError')]
        }
        this.loadingSave = false
      })
    }
  }

  changeName(value) {
    this.setIsDirty()
    this.roleName.setValue(value)
    this.validateRoleName()
  }

  setIsDirty() {
    this.isDirty = true
  }

  removeIsDirty() {
    this.isDirty = false
  }

  addSelectedPermissions(permissions) {
    this.setIsDirty()
    permissions.forEach((permission) => this.selectedPermissions.push(permission))
    this.validatePermissions()
  }

  removeSelectedPermissions(permissions) {
    this.setIsDirty()
    permissions.forEach((permission) => this.selectedPermissions.remove(permission))
    this.validatePermissions()
  }

  get visibleAllPermissions() {
    const allPermissions = this.allPermissions.map((permission) => permission)
    pullAll(allPermissions, this.selectedPermissions)

    return allPermissions
  }
}

export default RolesCreationStore
