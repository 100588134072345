import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './trustDocumentArchive.module.scss'

const TrustDocumentArchive = ({ children }) =>
  children.map((child, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={`archive_${i}`} className={c(styles.trustDocumentArchiveContainer)}>
      {children.length > 1 && (
        <div
          className={c(
            styles.connectionLine,
            i === 0 && styles.first,
            i + 1 === children.length && styles.last
          )}
        />
      )}
      <div className={styles.bulletContainer}>
        <div
          className={c(styles.bullet, child.props.trustDocumentVersion.isActive && styles.active)}
        >
          <div className={styles.bulletInner} />
        </div>
      </div>
      <div className={styles.itemContainer}>{child}</div>
    </div>
  ))

TrustDocumentArchive.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TrustDocumentArchive
