import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MAINTENANCE_PAGE } from 'routing/routes'
import StoresContext from 'providers/storeContext'

const PrivateRoute = observer(({ component: Component, redirectPath, ...rest }) => {
  const { authStore } = useContext(StoresContext)
  const location = useLocation()

  if (authStore.isMaintenancePage) {
    return (
      <Redirect
        to={{
          pathname: MAINTENANCE_PAGE,
        }}
      />
    )
  }

  if (authStore.isAuthenticated || authStore.isLoading) {
    return <Route {...rest} render={(props) => <Component {...props} />} />
  }

  return (
    <Redirect
      to={{
        pathname: redirectPath,
        from: location,
      }}
    />
  )
})

PrivateRoute.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
}

export default PrivateRoute
