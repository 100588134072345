import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import CloudAlertIcon from 'mdi-react/CloudAlertIcon'
import Button from 'presentation/Button'
import styles from './governingLawCreationActionButtons.module.scss'

const GoverningLawCreationActionButtons = ({ store, handleSave, handleCancel }) => {
  const { t } = useTranslation('governingLaw')

  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.saveButtonContainer}>
        <div className={c(styles.unsetChanges, store.isDirty ? styles.show : '')}>
          <CloudAlertIcon />
          <span>{t('unsavedLabel')}</span>
        </div>
        <Button
          big
          primary
          label={t('save')}
          onClick={handleSave}
          isLoading={store.loadingSave}
          title={`${t(`save`)} (alt+s)`}
          style={{ marginRight: '15px' }}
        />
      </div>
      <Button secondary big label={t('common:cancel')} onClick={handleCancel} />
    </div>
  )
}

GoverningLawCreationActionButtons.propTypes = {
  store: PropTypes.shape().isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

export default observer(GoverningLawCreationActionButtons)
