import { makeAutoObservable } from 'mobx'
import * as yup from 'yup'
import i18next from 'i18next'
import InputStore from './InputStore'

const PASSWORD_DIGITS = 8
const SHORT_PASSWORD = i18next.t('validation:shortPassword', { count: PASSWORD_DIGITS })
const EQUAL_PASSWORD = i18next.t('validation:equalPassword')
const REQUIRED = i18next.t('validation:requiredField')

class SetPasswordStore {
  constructor() {
    this.password = new InputStore()
    this.repeatPassword = new InputStore()
    this.error = false

    makeAutoObservable(this)
  }

  updatePassword(value) {
    this.password.setValue(value)
    this.validatePassword()
  }

  updateRepeatPassword(value) {
    this.repeatPassword.setValue(value)
    this.validatePassword()
  }

  get hasError() {
    return this.password.error || this.repeatPassword.error
  }

  validatePassword() {
    const schema = yup.string().min(8, SHORT_PASSWORD)

    if (this.password.value) {
      try {
        schema.validateSync(this.password.value)
      } catch (e) {
        this.password.setError(true)
        this.error = e.message
        return
      }
    } else {
      this.password.setError(true)
      this.password.setError(REQUIRED)
    }

    if (this.repeatPassword.value !== null && this.repeatPassword.value !== this.password.value) {
      this.error = EQUAL_PASSWORD
      this.repeatPassword.setError(true)
      return
    }
    if (!this.repeatPassword.value) {
      this.repeatPassword.setError(true)
      this.repeatPassword.setError(REQUIRED)
    }

    this.error = false
  }
}

export default SetPasswordStore
