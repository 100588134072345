/* eslint-disable react/prop-types */
import React, { useRef, forwardRef, useCallback, useImperativeHandle, useContext } from 'react'
import c from 'classnames'
import StoresContext from 'providers/storeContext'
import styles from './button.module.scss'

const Button = (
  {
    big,
    icon,
    type,
    small,
    label,
    error,
    circle,
    primary,
    smallest,
    disabled,
    secondary,
    fullWidth,
    isLoading,
    className,
    transparent,
    iconPositionLeft,
    permission = null,
    as: Component = 'button',
    ...props
  },
  ref
) => {
  const { authStore } = useContext(StoresContext)

  if (permission && !authStore.can(permission)) {
    return null
  }

  const componentRef = useRef(null)
  const setFocus = useCallback(() => {
    if (Component.name !== 'NavLink') componentRef.current.focus()
  }, [])

  useImperativeHandle(ref, () => ({
    setFocus,
  }))

  return (
    <Component
      type={type}
      disabled={isLoading || disabled}
      className={c(
        styles.button,
        primary && styles.primary,
        big && styles.big,
        small && styles.small,
        smallest && styles.smallest,
        secondary && styles.secondary,
        transparent && styles.transparent,
        fullWidth && styles.fullWidth,
        isLoading && styles.isLoading,
        circle && styles.circle,
        iconPositionLeft && styles.iconLeft,
        error && styles.error,
        className && className
      )}
      ref={Component.name !== 'NavLink' && componentRef}
      {...props}
    >
      <span>{label}</span>
      {icon && <span className={styles.icon}>{icon}</span>}
      {isLoading && (
        <div className={styles.loadingRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </Component>
  )
}

/* Button.propTypes = {
  as: PropTypes.node,
  big: PropTypes.bool,
  icon: PropTypes.node,
  error: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.string,
  circle: PropTypes.bool,
  primary: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  smallest: PropTypes.bool,
  secondary: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  transparent: PropTypes.bool,
  permission: PropTypes.string,
  iconPositionLeft: PropTypes.bool,
}

Button.defaultProps = {
  label: '',
  big: false,
  error: false,
  small: false,
  circle: false,
  className: '',
  type: 'button',
  permission: '',
  primary: false,
  disabled: false,
  smallest: false,
  secondary: false,
  fullWidth: false,
  isLoading: false,
  transparent: false,
  iconPositionLeft: false,
  as: PropTypes.elementType,
  icon: PropTypes.elementType,
} */

export default forwardRef(Button)
