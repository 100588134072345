import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class LettersArchiveService {
  listVersions = (id, letterType) =>
    axios
      .get(`${API_URL}/tenant/history/${letterType}s/${id}`)
      .then((response) => response.data.data)

  loadHistory = (letterId, letterType) =>
    axios
      .get(`${API_URL}/tenant/${letterType}s/versions/${letterId}`)
      .then((response) => response.data.data)

  loadContent = (versionId, lowId, letterType) =>
    axios
      .get(`${API_URL}/tenant/${letterType}s/versions/${lowId}/detail/${versionId}`)
      .then((response) => response.data.data)
}

export default LettersArchiveService
