import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import ListRole from 'models/ListRole'
import TableItemDate from 'presentation/TableItemDate'
import UpdateIcon from 'mdi-react/UpdateIcon'
import styles from './rolesTableItem.module.scss'

const RolesTableItem = ({ listItem, link, view }) => {
  return (
    <NavLink
      className={[styles.roleTableItem, styles[`roleTableItem--${view}`]].join(' ')}
      to={link}
    >
      <div className={styles.itemName}>{listItem.name}</div>
      <div className={styles.roleTableItemCreatedAt}>
        <TableItemDate
          listDate={{ at: listItem.created, name: listItem.name }}
          view={view}
          icon={<UpdateIcon size={12} />}
        />
      </div>
      <div className={styles.roleTableItemUpdatedAt}>
        <TableItemDate
          listDate={{ at: listItem.created, name: listItem.name }}
          view={view}
          icon={<UpdateIcon size={12} />}
        />
      </div>
    </NavLink>
  )
}

RolesTableItem.propTypes = {
  listItem: PropTypes.instanceOf(ListRole).isRequired,
  link: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
}

export default RolesTableItem
