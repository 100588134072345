import { successToast } from 'helpers/Toast'

const saveTrust = (trustsCreationStore, toastText, history = null, redirectRoute) => {
  trustsCreationStore.save((id) => {
    successToast(toastText, null, 'savedTrust').show()

    const firstLoad = !trustsCreationStore.isEdition

    if (!trustsCreationStore.isEdition) {
      trustsCreationStore.setIsEdition(id)

      // Mueve al item siguiente de Trust info
      history.push(`${redirectRoute}?id=${id}`)
    }

    trustsCreationStore.reloadData(!firstLoad)
  })
}

export default saveTrust
