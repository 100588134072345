import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LinkButton from 'presentation/LinkButton'
import styles from './loginError.module.scss'

const LoginError = ({ message, route, textBack }) => {
  const { t } = useTranslation('signIn')

  return (
    <>
      <div className={styles.errorBox}>{message ? t(message) : t('genericErrorPasswordUser')}</div>
      {route && <LinkButton route={route} text={textBack} />}
    </>
  )
}

LoginError.propTypes = {
  message: PropTypes.string,
  textBack: PropTypes.string,
  route: PropTypes.string,
}

LoginError.defaultProps = {
  message: 'genericErrorPasswordUser',
  textBack: '',
  route: null,
}

export default LoginError
