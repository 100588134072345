import * as yup from 'yup'
import Amount from '../../Asset/Amount'

class MoneyFormPartStore {
  validationRules

  constructor() {
    this.store = new Amount()
  }

  fill(data) {
    this.store.fillFromJson(data)
  }

  createFromCurrent() {
    const newMoneyFormPartStore = new MoneyFormPartStore()

    newMoneyFormPartStore.fill(this.store.json)
    newMoneyFormPartStore.setValidationRules(this.validationRules)

    return newMoneyFormPartStore
  }

  get json() {
    return this.store.json
  }

  setValidationRules(validationRules) {
    this.validationRules = validationRules
  }

  get validationSchema() {
    let schema = yup.object()

    if (this.validationRules && this.validationRules.get('required')) {
      schema = schema.shape({
        currency: yup.string().required(),
        value: yup.string().required(),
      })
    }

    return schema
  }

  async validate() {
    await this.validationSchema.validate(this.json)

    return new Promise((resolve, reject) => {
      if (this.validationSchema) {
        try {
          resolve(true)
        } catch (err) {
          this.store[err.path].setError(true) // esto es para mostrar el input en rojo
          this.store.setError(true, err.message)

          reject(err.message)
        }
      }

      resolve(true)
    })
  }

  get isValid() {
    return !this.store.error
  }
}

export default MoneyFormPartStore
