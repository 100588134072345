import { action, runInAction, makeObservable } from 'mobx'
import LettersArchiveService from 'services/LettersArchiveService'
import { LetterVersion } from 'models/TrustDocumentVersion'
import LetterArchiveStore from 'stores/LetterArchiveStore'

class LetterOfWishesArchiveStore extends LetterArchiveStore {
  constructor(idEdition) {
    super()

    makeObservable(this, {
      listVersions: action,
    })

    this.idEdition = idEdition
    this.lettersArchiveService = new LettersArchiveService()
  }

  async listVersions() {
    this.preRequest()

    try {
      const response = await this.lettersArchiveService.listVersions(this.idEdition, 'low')

      runInAction(() => {
        this.versionsList = []
        response.map((lowVersion) => this.versionsList.push(LetterVersion.fromJson(lowVersion)))

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }
}

export default LetterOfWishesArchiveStore
