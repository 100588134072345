import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  TRANSACTIONS_LIST,
  TRUST_USERS_VIEW,
  ZIP_FILE,
  TRUST_VALUATIONS,
  TRUST_HISTORY_VIEW,
} from 'stores/AuthStore'
import ListTrust from 'models/ListTrust'
import ListCorporation from 'models/ListCorporation'
import Gated from 'util/Gated'
import FolderDownloadOutlineIcon from 'mdi-react/FolderDownloadOutlineIcon'
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon'
import HandLeftIcon from 'mdi-react/HandLeftIcon'
import SwapHorizontalIcon from 'mdi-react/SwapHorizontalIcon'
import ShieldAccountOutlineIcon from 'mdi-react/ShieldAccountOutlineIcon'
import HistoryIcon from 'mdi-react/HistoryIcon'
import FiduciaryStructureActionsButton from 'presentation/FiduciaryStructureActionsButton'
import styles from '../FiduciaryStructuresTableItem/trustsTableItem.module.scss'

function FiduciaryStructureActionsButtonList({
  listItem,
  view,
  handleExpenseLink,
  handleTransactionsLink,
  handleValuationChange,
  handlePermissionLink,
  handleValuationsLink,
  handleHistoryLink,
  handleContentChangeLink,
  handleRequestZip,
  requestedZip,
  isTransaction,
  isCorp,
}) {
  const { t } = useTranslation('common')

  const buttons = useMemo(
    () => [
      {
        isVisible: listItem.status.isStored && isTransaction,
        permission: TRANSACTIONS_LIST,
        buttonProps: {
          icon: <HandLeftIcon size={16} />,
          label: t('trusts:authorization'),
          transparent: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handleTransactionsLink(listItem.id)
          },
        },
      },
      {
        isVisible: isTransaction,
        permission: TRANSACTIONS_LIST,
        buttonProps: {
          icon: <CurrencyUsdIcon size={16} />,
          label: t('trusts:valuations'),
          transparent: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handleValuationChange(listItem.id)
          },
        },
      },
      {
        isVisible: isTransaction,
        permission: TRANSACTIONS_LIST,
        buttonProps: {
          icon: <SwapHorizontalIcon size={16} />,
          label: t('trusts:manageAssets'),
          transparent: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handleContentChangeLink(listItem.id)
          },
        },
      },
      {
        isVisible: isTransaction,
        permission: TRANSACTIONS_LIST,
        buttonProps: {
          icon: <CurrencyUsdIcon size={16} />,
          label: t('trusts:expense'),
          transparent: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handleExpenseLink(listItem.id)
          },
        },
      },
      {
        isVisible: !isTransaction,
        permission: TRUST_USERS_VIEW,
        buttonProps: {
          icon: <ShieldAccountOutlineIcon size={16} />,
          label: t('trusts:permissions'),
          transparent: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handlePermissionLink(listItem.id)
          },
        },
      },
      {
        isVisible: !isTransaction && !isCorp,
        permission: ZIP_FILE,
        buttonProps: {
          icon: <FolderDownloadOutlineIcon size={16} />,
          label: t('trusts:download'),
          transparent: !requestedZip,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handleRequestZip(listItem)
          },
        },
      },
      {
        isVisible: !isTransaction && !isCorp,
        permission: TRUST_VALUATIONS,
        buttonProps: {
          icon: <CurrencyUsdIcon size={16} />,
          label: t('trusts:valuations'),
          transparent: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handleValuationsLink(listItem.id)
          },
        },
      },
      {
        isVisible: !isTransaction && !isCorp,
        permission: TRUST_HISTORY_VIEW,
        buttonProps: {
          icon: <HistoryIcon size={16} />,
          label: t('trusts:history'),
          transparent: true,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()

            handleHistoryLink(listItem.id)
          },
        },
      },
    ],
    [isTransaction, listItem.id, requestedZip]
  )

  return (
    <div className={styles.trustTableItemActions}>
      {buttons
        .filter((button) => button.isVisible)
        .map(({ permission, buttonProps }) => (
          <Gated permission={permission} key={`${listItem.id}_${buttonProps.label}`}>
            <FiduciaryStructureActionsButton
              className={styles.actionButton}
              iconOnly={view === 'detail' || view === 'grid'}
              {...buttonProps}
            />
          </Gated>
        ))}
    </div>
  )
}

FiduciaryStructureActionsButtonList.propTypes = {
  listItem: PropTypes.oneOfType([
    PropTypes.instanceOf(ListCorporation),
    PropTypes.instanceOf(ListTrust),
  ]).isRequired,
  handleExpenseLink: PropTypes.func,
  handleTransactionsLink: PropTypes.func,
  handlePermissionLink: PropTypes.func,
  handleValuationsLink: PropTypes.func,
  handleRequestZip: PropTypes.func,
  handleHistoryLink: PropTypes.func,
  handleContentChangeLink: PropTypes.func,
  handleValuationChange: PropTypes.func,
  view: PropTypes.string.isRequired,
  requestedZip: PropTypes.bool,
  isTransaction: PropTypes.bool,
  isCorp: PropTypes.bool,
}

FiduciaryStructureActionsButtonList.defaultProps = {
  handleExpenseLink: () => {},
  handleTransactionsLink: () => {},
  handlePermissionLink: () => {},
  handleValuationsLink: () => {},
  handleValuationChange: () => {},
  handleRequestZip: () => {},
  handleHistoryLink: () => {},
  handleContentChangeLink: () => {},
  requestedZip: false,
  isTransaction: false,
  isCorp: false,
}

export default FiduciaryStructureActionsButtonList
