import { useState, useEffect, useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { FIDUCIARY_STRUCTURE_TRANSACTIONS } from 'routing/routes'
import Modal from 'scenes/Modal'
import { successToast } from 'helpers/Toast'
import PageTitle from 'presentation/PageTitle'
import WithdrawRow from 'presentation/WithdrawRow'
import CreationModal from 'presentation/CreationModal'
import TrustTransactionsAssetForm from 'presentation/TrustTransactionsAssetForm'
import LoadingRing from 'presentation/LoadingRing'
import CreationInput from 'presentation/CreationInput/CreationInput'
import FormSelect from 'presentation/FormSelect'
import NoResultsList from 'presentation/NoResultsList'
import FiduaciaryStructureHeaderLink from 'presentation/FiduaciaryStructureHeaderLink'
import ButtonBack, { useBackButtonURL } from 'presentation/ButtonBack'
import Button from 'presentation/Button'
import ToolTip from 'presentation/ToolTip'
import StoresContext from 'providers/storeContext'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import TransactionsAssetsStore from './TransactionsAssetsStore'
import styles from './trustTransactionsAssets.module.scss'

const TransactionsAssets = () => {
  const { t } = useTranslation('transactions')
  const { fiduciaryStructureId } = useParams()
  const { search, pathname } = useLocation()
  const { creationParametersStore } = useContext(StoresContext)
  const isExpenseView = pathname.includes('expense')
  const structureType = new URLSearchParams(search).get('structure') || 'trust'
  const history = useHistory()
  const [transactionAssetsStore] = useState(
    () => new TransactionsAssetsStore(fiduciaryStructureId, structureType)
  )
  const [showCreationModal, setShowCreationModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const { isLoading } = transactionAssetsStore
  const backButtonURL = useBackButtonURL()

  useEffect(() => {
    if (isExpenseView) {
      transactionAssetsStore.setTypeSelected({ id: 'expense', value: 'Expense' })
      transactionAssetsStore.loadAssets(structureType)
    }

    creationParametersStore.init()
    creationParametersStore.loadTypes()
    transactionAssetsStore.loadTransactionTypes()
  }, [])

  useEffect(() => {
    if (transactionAssetsStore.typeSelected?.value?.value === 'Poll') {
      history.push(`${FIDUCIARY_STRUCTURE_TRANSACTIONS}?id=${fiduciaryStructureId}`)
    }
  }, [transactionAssetsStore.typeSelected.value])

  const handleClickNext = useCallback((index) => {
    setShowCreationModal(true)

    if (transactionAssetsStore.shouldHandleMultipleAssets())
      transactionAssetsStore.changeCurrentAsset(index)
  }, [])

  const handleSelectType = useCallback((value) => {
    transactionAssetsStore.setTypeSelected(value)
    transactionAssetsStore.loadAssets(structureType)
  }, [])

  const handleModalCancel = useCallback(() => {
    setShowCreationModal(false)
  }, [])

  const handleSaveTransaction = useCallback(() => {
    transactionAssetsStore.save((transactionType) => {
      successToast(t(`${transactionType}Saved`), null, 'transactionSaved').show()
    })
  }, [])

  const handleModalSave = useCallback(() => {
    if (!transactionAssetsStore.destinationAssetsHasErrors) {
      setShowConfirmModal(true)
    } else {
      setShowErrorModal(true)
    }
  }, [])

  const handleCancelErrorModal = useCallback(() => {
    setShowErrorModal(false)
  }, [])
  const handleCancelConfirmModal = useCallback(() => {
    setShowConfirmModal(false)
  }, [])

  const handleSuccessConfirmModals = useCallback(() => {
    transactionAssetsStore.save((transactionType) => {
      successToast(t(`${transactionType}Saved`), null, `${transactionType}Saved`).show()
    })
    setShowConfirmModal(false)
    setShowCreationModal(false)
  }, [])

  const handleClearErrors = useCallback(() => {
    transactionAssetsStore.resetErrors()
  }, [])

  if (isLoading) {
    return <LoadingRing center />
  }

  return (
    <div className={styles.wrapper}>
      <FiduaciaryStructureHeaderLink
        text={transactionAssetsStore.fiduciaryStructureName}
        route={transactionAssetsStore.fiduciaryStructureRoute}
      />
      <PageTitle title={t(isExpenseView ? 'expense' : 'assets')} />
      {!isExpenseView && (
        <CreationInput
          as={FormSelect}
          label={t('transactionType')}
          placeholder={t('selectTransactionType')}
          options={transactionAssetsStore.transactionTypesOptions}
          inputStore={transactionAssetsStore.typeSelected}
          wrapperClassName={styles.transactionTypeSelection}
          onChange={handleSelectType}
        />
      )}
      <div>
        {transactionAssetsStore.assets.length ? (
          transactionAssetsStore.assets.map((asset, index) => {
            return (
              <div className={styles.elementRow} key={asset.id}>
                <WithdrawRow
                  handleClickNext={() => handleClickNext(index)}
                  asset={asset}
                  type={t(`${asset.type}`)}
                  noAmount={transactionAssetsStore.isOwnershipTransaction}
                  noNextButton={
                    transactionAssetsStore.currentTransactionStore &&
                    !transactionAssetsStore.isOwnershipTransaction
                  }
                />
              </div>
            )
          })
        ) : (
          <NoResultsList topText="No Assets available." />
        )}
      </div>
      <div className={styles.actionButtons}>
        <ButtonBack to={backButtonURL} />
        {transactionAssetsStore.currentTransactionStore &&
          !transactionAssetsStore.isOwnershipTransaction && (
            <ToolTip
              tooltipContent={t('nextButtonHelp')}
              disabled={transactionAssetsStore.currentTransactionStore?.hasWithdrawAmount}
            >
              <Button
                className={styles.nextButton}
                label={t('common:next')}
                icon={<ArrowRightIcon size={18} />}
                onClick={handleClickNext}
                primary
                disabled={!transactionAssetsStore.currentTransactionStore?.hasWithdrawAmount}
              />
            </ToolTip>
          )}
      </div>
      <Modal
        name="errorsModal"
        title={t('errorInTransaction')}
        open={transactionAssetsStore.currentTransactionStore?.hasErrors}
        okOnly
        onSuccess={handleClearErrors}
      >
        <div className={styles.errorContainer}>
          <div>{t('errorOnTransactionMessage')}</div>
          {transactionAssetsStore.currentTransactionStore?.errors?.map((error) => (
            <div key={error} className={styles.error}>
              {error}
            </div>
          ))}
        </div>
      </Modal>

      <CreationModal
        handleClose={handleModalCancel}
        visible={showCreationModal}
        title={t('assetsTransaction')}
      >
        <TrustTransactionsAssetForm
          store={transactionAssetsStore.currentTransactionStore}
          onCancelForm={handleModalCancel}
          onSaveForm={handleModalSave}
          handleSaveTransaction={handleSaveTransaction}
        />
      </CreationModal>
      <Modal
        name={t('confirmModal')}
        open={showConfirmModal}
        onCancel={handleCancelConfirmModal}
        onSuccess={handleSuccessConfirmModals}
      >
        <div>{t('AreYouSureSaveTransaction')}</div>
        <div>{t('ruleChangeWarning')}</div>
      </Modal>
      <Modal
        name="transactionErrorModal"
        open={showErrorModal}
        onSuccess={handleCancelErrorModal}
        okOnly
      >
        <div>{t('errorsOnDestinationAssets')}</div>
      </Modal>
    </div>
  )
}

export default observer(TransactionsAssets)
