import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import AssetCompanyPartStore from 'models/FormMetadata/Parts/AssetCompanyPartStore'
import TrustAssetsStore from 'models/FormMetadata/Parts/AssetTrustPartStore'

const AssetNameSelect = ({ store, label, placeholder, readOnly }) => {
  const handleChange = (option) => {
    store.select({ id: option.id, name: option.value })
  }

  const handleInputChange = (value) => {
    store.loadData(value)
  }

  return (
    <CreationInput
      as={FormSelect}
      inputStore={store.stores.fullName}
      name="fullName"
      label={label}
      placeholder={placeholder}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={store.elementOptions}
      isLoading={store.isLoading}
      value={store.selectedOption}
      literal={readOnly}
    />
  )
}

AssetNameSelect.propTypes = {
  store: PropTypes.oneOfType([
    PropTypes.instanceOf(TrustAssetsStore),
    PropTypes.instanceOf(AssetCompanyPartStore),
  ]).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
}

AssetNameSelect.defaultProps = {
  readOnly: true,
}

export default observer(AssetNameSelect)
