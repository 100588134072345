import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import DynamicEntitiesTable from 'presentation/DynamicEntitiesTable'
import DynamicEntitiesUserItem from 'presentation/DynamicEntitiesUserItem'
import tableStyles from 'styles/tables.module.scss'
import styles from './permissionUserList.module.scss'

const PermissionUserList = ({ assignedUsers, loadingSave, handleSetUserRole, handleDelete }) => {
  return (
    <TransitionGroup className={tableStyles.tableRowGrid}>
      {assignedUsers.map((assignedUser) => {
        return (
          <CSSTransition
            key={assignedUser.id}
            timeout={200}
            className={tableStyles.rowTransition}
            classNames={{
              enter: tableStyles['rowTransition-enter'],
              enterActive: tableStyles['rowTransition-enter-active'],
              exit: tableStyles['rowTransition-exit'],
              exitActive: tableStyles['rowTransition-exit-active'],
            }}
          >
            <DynamicEntitiesTable handleDelete={() => handleDelete(assignedUser)} viewMode={false}>
              <DynamicEntitiesUserItem
                data={assignedUser}
                isLoading={loadingSave && assignedUser.isDirty}
                handleSetUserRole={handleSetUserRole}
                innerProps={{
                  className: styles.selectedUser,
                }}
                hasCheckbox
              />
            </DynamicEntitiesTable>
          </CSSTransition>
        )
      })}
    </TransitionGroup>
  )
}

PermissionUserList.propTypes = {
  loadingSave: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSetUserRole: PropTypes.func.isRequired,
  assignedUsers: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default PermissionUserList
