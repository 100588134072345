import React, { useContext, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import Creatable from 'react-select/creatable/dist/react-select.esm'
import { ProtectorCategoriesDataListPart } from 'models/FormMetadata/Parts'

const DynamicFormPartProtectorCategoryDataList = ({ part, inputs, readOnly, inputRef }) => {
  const { t } = useTranslation('dynamicForms')
  const { trustProtectorCategoriesStore, currentDynamicStore } = useContext(StoresContext)
  const [inputStore] = useState(inputs.get(part.id).store)

  useEffect(() => {
    trustProtectorCategoriesStore.loadCategories(currentDynamicStore.idEdition)
  }, [])

  const handleChangeOption = useCallback((option) => inputStore.setValue(option), [])
  const handleCreateCategory = useCallback(
    (value) =>
      trustProtectorCategoriesStore
        .createCategory(value, currentDynamicStore.idEdition)
        .then((category) => handleChangeOption(category)),
    []
  )

  return (
    <CreationInput
      as={FormSelect}
      inputStore={inputStore}
      label={part.label}
      placeholder={t('placeholderSelect', { name: part.label })}
      onChange={handleChangeOption}
      literal={readOnly}
      formSelectAs={Creatable}
      options={trustProtectorCategoriesStore.categoriesOptions}
      onCreateOption={handleCreateCategory}
      isLoading={trustProtectorCategoriesStore.isLoading}
      inputRef={inputRef}
    />
  )
}

DynamicFormPartProtectorCategoryDataList.propTypes = {
  part: PropTypes.instanceOf(ProtectorCategoriesDataListPart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

DynamicFormPartProtectorCategoryDataList.defaultProps = {
  readOnly: true,
  inputRef: () => {},
}

export default observer(DynamicFormPartProtectorCategoryDataList)
