import * as yup from 'yup'
import moment from 'moment'
import InstanceEntitiesList from 'models/FormMetadata/InstanceEntitiesList'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import React from 'react'

export const STRING_INPUT = 'string'
export const DATE_INPUT = 'date'
export const NUMBER_INPUT = 'number'
export const DATE_MIN_ERROR = 'This field must be later than'
export const DATE_MAX_ERROR = 'This field must be earlier than'

const dateStringToObject = (string) => {
  if (string === 'today') {
    return moment().startOf('day').toDate()
  }
  return undefined
}

const formatDate = (currentDate, format) => {
  return moment(currentDate).format(format)
}

/**
 * Crea un objeto Map con las reglas de validación.
 * Esto permite manipularlas: rules.has('required') o rules.get('max') devuelve el número o la fecha parseada
 * */
export const parseValidationRules = (validationRulesString) => {
  if (!validationRulesString) {
    return null
  }

  return validationRulesString
    .split('|')
    .filter((rule) => rule.trim() !== '')
    .reduce((validationMap, rule) => {
      // "validationRules": "required"
      if (rule === 'required') {
        validationMap.set('required', true)
        return validationMap
      }

      // "validationRules": "min:now" || "validationRules": "min:255"
      const minMatches = rule.match(/min:(\w+)/)
      if (minMatches) {
        let min = Number(minMatches[1])
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(minMatches[1])) {
          min = dateStringToObject(minMatches[1])
        }

        validationMap.set('min', min)

        return validationMap
      }

      // "validationRules": "max:now" || "validationRules": "max:255"
      const maxMatches = rule.match(/max:(\w+)/)
      if (maxMatches) {
        let max = Number(maxMatches[1])
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(maxMatches[1])) {
          max = dateStringToObject(maxMatches[1])
        }
        validationMap.set('max', max)

        return validationMap
      }

      return validationMap
    }, new Map())
}

const getTextWithTranslation = (type, a) => {
  const keyToUse = type === 'min' ? `validation:dateMinError` : `validation:dateMaxError`
  const date = formatDate(a, i18next.t('common:humanDateYearFormat'))
  return (
    <Trans i18nKey={keyToUse} key={keyToUse}>
      {{ date }}
    </Trans>
  )
}

export const createSchemaFromRules = (rules, dataType = STRING_INPUT) => {
  let validation = yup.string()
  let isInputDate = false

  if (dataType === DATE_INPUT) {
    validation = yup.date()
    isInputDate = true
  }

  if (dataType === NUMBER_INPUT) {
    validation = yup.number()
  }

  if (rules) {
    if (rules.get('required')) {
      validation = validation.required()
    }

    if (rules.get('min')) {
      validation = isInputDate
        ? validation.min(rules.get('min'), getTextWithTranslation('min', rules.get('min')))
        : validation.min(rules.get('min'))
    }

    if (rules.get('max')) {
      validation = isInputDate
        ? validation.max(rules.get('max'), getTextWithTranslation('max', rules.get('max')))
        : validation.max(rules.get('max'))
    }
  }

  return validation
}

export const countPartErrors = (part, inputs) => {
  const input = inputs.get(part.id)
  let errors = 0

  if (part.parts) {
    // Si tiene sub partes, las recorro

    part.parts.forEach((subPart) => {
      errors += countPartErrors(subPart, inputs)
    })
  }

  if (input) {
    if (input instanceof InstanceEntitiesList) {
      input.countErrors()
      return input.validationCountErrors
    }

    // Si tiene un store de input, me fijo si es válido
    if (input.isValid) {
      return 0
    }

    return 1
  }

  return errors
}

export const validatePart = (part, inputs) => {
  const input = inputs.get(part.id)

  if (part.parts) {
    // Si tiene sub partes, las recorro

    part.parts.forEach((subPart) => {
      validatePart(subPart, inputs)
    })
  } else if (input) {
    input.validate()
  }
}

export const getPartErrorMessages = (part, inputs) => {
  const input = inputs.get(part.id)

  if (input) {
    if (!input.isValid) {
      return [
        {
          label: part.label,
          message: input.errorMessage || input.store.errorMessage,
        },
      ]
    }
  }

  let errors = []
  if (part.parts) {
    // Si tiene sub partes, las recorro
    part.parts.forEach((subPart) => {
      errors = [...errors, ...getPartErrorMessages(subPart, inputs)]
    })
  }

  return errors
}

export const partIsValid = (part, inputs) => {
  return !countPartErrors(part, inputs)
}

export default createSchemaFromRules
