import axios from 'axios'
import moment from 'moment'
import ContactActivity from 'models/ContactActivity'
import AssetActivity from 'models/AssetActivity'
import GovernorActivity from 'models/GovernorActivity'
import DocumentActivity from 'models/DocumentActivity'
import { API_URL } from 'services/config'
import TransactionActivity from 'models/TransactionActivity'
import StoreApprovalActivity from 'models/StoreApprovalActivity'

/* eslint-disable class-methods-use-this */
class ActivityDetailsListService {
  loadDetails = (
    id,
    structure = 'trust',
    activePage = 1,
    statusFilter = null,
    fromDate = null,
    toDate = null,
    orderParam = 'createdAt',
    orderDir = 'desc'
  ) => {
    const params = {}
    if (orderParam && orderDir) {
      params[`?sort[${orderParam}]`] = orderDir
    }

    if (activePage) {
      params.page = activePage
    }

    if (statusFilter) {
      params[`&status[]`] = statusFilter
    }

    if (fromDate) {
      params.from = moment(fromDate).format('YYYY-MM-DD')
    }

    if (toDate) {
      params.to = moment(toDate).format('YYYY-MM-DD')
    }
    return axios
      .get(`${API_URL}/tenant/${structure}/${id}/activities`, params)
      .then((response) => {
        return response.data.data
      })
      .then((data) => {
        return data.map((item) => {
          switch (item.type) {
            case 'contactInformationChange':
              return ContactActivity.fromJson(item)
            case 'assetValuationChange':
              return AssetActivity.fromJson(item)
            case 'governorApproval':
              return GovernorActivity.fromJson(item)
            case 'assetDocumentChange':
              return DocumentActivity.fromJson(item)
            case 'transactionApproval':
              return TransactionActivity.fromJson(item)
            case 'storeApproval':
              return StoreApprovalActivity.fromJson(item)
            default:
              return null
          }
        })
      })
  }

  approveChange = (id) => axios.post(`${API_URL}/tenant/activities/${id}/approve`)

  rejectChange = (id) => axios.post(`${API_URL}/tenant/activities/${id}/reject`)

  abstainChange = (id) => axios.post(`${API_URL}/tenant/activities/${id}/abstain`)

  confirmChange = (id) => axios.post(`${API_URL}/tenant/activities/confirm`, { content: [{ id }] })

  sendToBlockchain = (id) =>
    axios.post(`${API_URL}/tenant/content-transactions/run-trigger`, { trust: id })
}

export default ActivityDetailsListService
