import { createSchemaFromRules } from 'util/validation'

class PartStore {
  validationRules

  constructor(store) {
    this.store = store
  }

  setValidationRules(validationRules) {
    this.validationRules = validationRules
  }

  get validationSchema() {
    return createSchemaFromRules(this.validationRules)
  }

  // eslint-disable-next-line class-methods-use-this
  get json() {
    return false
  }

  async validate() {
    if (this.validationRules) {
      try {
        await this.validationSchema.validate(this.json || undefined)

        this.store.clearError()
      } catch (e) {
        this.store.setError(true, e.message)
      }
    }
  }

  get isValid() {
    if (this.validationRules) {
      return !this.store.error
    }

    return true
  }
}

export default PartStore
