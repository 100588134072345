import axios from 'axios'
import { API_URL } from 'services/config'
import ListClient from 'models/ListClient'

/* eslint-disable class-methods-use-this */
class ClientService {
  listClients = (page, search = null, orderParam = null, orderDir = null) =>
    axios
      .get(
        `${API_URL}/tenant/clients?page=${page}&sort[${orderParam || 'updatedAt'}]=${
          orderDir || 'desc'
        }&search=${search || ''}`
      )
      .then((response) => ({
        clients: response.data.data.map((listClient) => ListClient.fromJson(listClient)),
        pagination: response.data.pagination,
      }))

  saveClient = (json) =>
    axios.post(`${API_URL}/tenant/clients`, json).then((response) => response.data.data)

  loadMetadata = (type, clientsId, dataId) => {
    const url = clientsId
      ? `${API_URL}/metadata/clients/${clientsId}/${dataId}`
      : `${API_URL}/metadata/clients`
    return axios.get(url).then((response) => response.data.data.content.types[type])
  }

  getClient = (id) =>
    axios
      .get(`${API_URL}/tenant/clients/data/${id}?status=current`)
      .then((response) => response.data.data)

  activate = (id) =>
    axios
      .post(`${API_URL}/tenant/client/status/activate`, { id })
      .then((response) => response.data.data)

  inactivate = (id) =>
    axios
      .post(`${API_URL}/tenant/client/status/deactivate`, { id })
      .then((response) => response.data.data)
}

export default ClientService
