import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import RadioButtonToggle from 'presentation/RadioButtonToggle'

const SaveAndCreateToggle = ({ onChange, checkedSaveAndCreate }) => {
  const { t } = useTranslation('transactionsCreation')

  return (
    <RadioButtonToggle
      onChange={onChange}
      label={t('saveAndCreate')}
      checked={checkedSaveAndCreate}
    />
  )
}

SaveAndCreateToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  checkedSaveAndCreate: PropTypes.bool.isRequired,
}

export default observer(SaveAndCreateToggle)
