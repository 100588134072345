import File from 'models/File/File'
import PartStore from './PartStore'

class FileFormPartStore extends PartStore {
  constructor() {
    super(new File())
  }

  fill(data) {
    if (data) {
      this.store.changeId(data.id)
      this.store.changeName(data.name)
      this.store.changeType(data.type)
      this.store.changeLegalCertified(data.legalCertified)

      if (data.description) {
        this.store.changeDescription(data.description)
      }
    }
  }

  createFromCurrent() {
    const newFileFormPartStore = new FileFormPartStore()

    newFileFormPartStore.fill(this.store.getJson())
    newFileFormPartStore.setValidationRules(this.validationRules)

    return newFileFormPartStore
  }

  get json() {
    const { id, name, type, legalCertified, description } = this.store

    return {
      id,
      name,
      type: type.value,
      legalCertified,
      description: description.value,
    }
  }
}

export default FileFormPartStore
