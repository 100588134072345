import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { MoneyPart } from 'models/FormMetadata/Parts'
import FormMoney from './FormMoney'

const DynamicFormPartMoney = ({ part, inputs, readOnly, inputRef }) => {
  const [inputStore] = useState(inputs.get(part.id))
  const validate = useCallback(() => inputStore.validate(), [])

  return (
    <FormMoney
      valueStore={inputStore.store.value}
      currencyStore={inputStore.store.currency}
      onChangeValue={() => validate()}
      onChangeCurrency={() => validate()}
      onBlur={() => validate()}
      label={part.label}
      literal={readOnly}
      inputRef={inputRef}
    />
  )
}

DynamicFormPartMoney.propTypes = {
  part: PropTypes.instanceOf(MoneyPart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

DynamicFormPartMoney.defaultProps = {
  readOnly: true,
  inputRef: () => {},
}

export default observer(DynamicFormPartMoney)
