import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import RolesCreationStore from 'stores/RolesCreationStore'
import { EDIT_ROLE } from 'stores/AuthStore'
import CloudAlertIcon from 'mdi-react/CloudAlertIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Button from 'presentation/Button'
import styles from './roleCreationActionButtons.module.scss'

const RoleCreationActionButtons = ({
  rolesCreationStore,
  handleSave,
  handleCancel,
  handleGoBack,
  editable,
  handleEdit,
}) => {
  const { t } = useTranslation('rolesCreation')

  return (
    <div className={styles.buttonsContainer}>
      {rolesCreationStore.isViewMode ? (
        <>
          <Button
            secondary
            label={t('common:back')}
            onClick={handleGoBack}
            title={t('common:back')}
            style={{ marginRight: '15px' }}
            icon={<ArrowLeftIcon />}
            iconPositionLeft
          />
          {editable && (
            <Button
              permission={EDIT_ROLE}
              primary
              label={t('editRole')}
              onClick={handleEdit}
              title={t('editRole')}
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.saveButtonContainer}>
            <div className={c(styles.unsetChanges, rolesCreationStore.isDirty ? styles.show : '')}>
              <CloudAlertIcon />
              <span>{t('unsavedLabel')}</span>
            </div>
            <Button
              primary
              big
              label={t('saveRole')}
              onClick={handleSave}
              title={`${t(`saveRole`)} (alt+s)`}
              style={{ marginRight: '15px' }}
              isLoading={rolesCreationStore.loadingSave}
            />
          </div>
          <Button secondary big label={t('common:cancel')} onClick={handleCancel} />
        </>
      )}
    </div>
  )
}

RoleCreationActionButtons.propTypes = {
  rolesCreationStore: PropTypes.instanceOf(RolesCreationStore).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  handleGoBack: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
}

RoleCreationActionButtons.defaultProps = {
  editable: false,
}

export default observer(RoleCreationActionButtons)
