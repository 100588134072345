class StoreApprovalActivity {
  constructor(activityId, id, description, created, updated, status, type, isConfirmed) {
    this.activityId = activityId
    this.id = id
    this.description = description
    this.created = created
    this.updated = updated
    this.status = status
    this.type = type
    this.isConfirmed = isConfirmed
  }

  static fromJson({
    subjectAt: { trustId: id },
    description,
    created,
    updated,
    status,
    type,
    id: activityId,
    isConfirmed,
  }) {
    return new StoreApprovalActivity(
      activityId,
      id,
      description,
      created,
      updated,
      status,
      type,
      isConfirmed
    )
  }

  get isApproved() {
    return this.status === 'approved'
  }

  get showingName() {
    return this.name
  }

  get showingEntity() {
    return this.governorType
  }
}

export default StoreApprovalActivity
