import React, { useContext, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import FreezeModalList, { FreezeModalItem } from 'presentation/FreezeModal'
import styles from './freezeModalContent.module.scss'

const FreezeModalContent = () => {
  const { t } = useTranslation('trustsCreation')
  const { trustsCreationStore } = useContext(StoresContext)
  const { lettersStore, deedsStore, otherDocsStore, hasAlternativeStatus } = trustsCreationStore
  const { draftMainLetter: draftDeed, draftSecondaryLetter: draftAmendment } = deedsStore
  const { draftMainLetter: draftLow, draftSecondaryLetter: draftLod } = lettersStore
  const { draftMainLetter: draftOtherDocs } = otherDocsStore

  let trustFreezeRow = null
  const toggleFreezeLow = useCallback(() => trustsCreationStore.toggleFreezeLow(), [])
  const toggleFreezeDeed = useCallback(() => trustsCreationStore.toggleFreezeDeed(), [])
  const toggleFreezeLod = useCallback(() => trustsCreationStore.toggleFreezeLod(), [])
  const toggleFreezeAmendment = useCallback(() => trustsCreationStore.toggleFreezeAmendment(), [])
  const toggleFreezeOtherDocs = useCallback(
    () => trustsCreationStore.toggleFreezeOtherDocuments(),
    []
  )

  if (draftDeed) {
    trustFreezeRow = (
      <FreezeModalItem label={`${t('deedOfTrust')} - ${draftDeed.title.value}`} disabled />
    )

    if (hasAlternativeStatus) {
      trustFreezeRow = (
        <FreezeModalItem
          label={`${t('deedOfTrust')} - ${draftDeed.title.value}`}
          selected={trustsCreationStore.freezeDeed}
          handleClick={toggleFreezeDeed}
        />
      )
    }
  }

  return (
    <div className={styles.freezeModalContainer}>
      <div className={styles.message}>{t('executionModalDescription')}</div>
      <FreezeModalList>
        <FreezeModalItem label={t('trustInformation')} disabled />
        {trustFreezeRow}
        {draftLow && (
          <FreezeModalItem
            label={`${t('letterOfWishes')} - ${draftLow.title.value}`}
            selected={trustsCreationStore.freezeLow}
            handleClick={toggleFreezeLow}
          />
        )}
        {draftLod && (
          <FreezeModalItem
            label={`${t('letterOfDistribution')} - ${draftLod.title.value}`}
            selected={trustsCreationStore.freezeLod}
            handleClick={toggleFreezeLod}
          />
        )}
        {draftAmendment && (
          <FreezeModalItem
            label={`${t('amendment')} - ${draftAmendment.title.value}`}
            selected={trustsCreationStore.freezeAmendment}
            handleClick={toggleFreezeAmendment}
          />
        )}
        {draftOtherDocs && (
          <FreezeModalItem
            label={`${t('otherDocuments')} - ${draftOtherDocs.title.value}`}
            selected={trustsCreationStore.freezeOtherDocuments}
            handleClick={toggleFreezeOtherDocs}
          />
        )}
      </FreezeModalList>
    </div>
  )
}

export default observer(FreezeModalContent)
