import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import RolesService from 'services/RolesService'

class RolesListStore extends AsyncStore {
  roles = []
  activePage = 1
  pages = 5
  activeSearch = false
  order = ''
  showDropDownOrder = false

  lastSearch = ''
  lastOrderParam = ''
  lastOrderDir = ''

  constructor() {
    super()

    makeObservable(this, {
      // observables
      roles: observable,
      activePage: observable,
      pages: observable,
      activeSearch: observable,
      order: observable,
      // actions
      loadRoles: action,
      changeActivePage: action,
      setShowDropdownOrder: action,
      // computeds
      visibleRoles: computed,
    })

    this.isLoading = true
    this.rolesService = new RolesService()
  }

  async loadRoles(searchKey = null, orderParam = null, orderDir = null, resetpage = false) {
    this.activeSearch = searchKey !== '' && searchKey !== null

    this.preRequest(this.loadRoles)

    try {
      if (searchKey || searchKey === '') {
        this.lastSearch = searchKey
      }

      if (orderParam || orderParam === '') {
        this.lastOrderParam = orderParam
      }

      if (orderDir || orderDir === '') {
        this.lastOrderDir = orderDir
      }

      if (resetpage) {
        this.activePage = 1
      }

      const response = await this.rolesService.listRoles(
        this.activePage,
        this.lastSearch,
        this.lastOrderParam,
        this.lastOrderDir
      )

      runInAction(() => {
        this.roles = response.roles
        this.pages = response.pagination.totalPages

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  get visibleRoles() {
    return this.roles
  }

  changeActivePage(pageNumber, orderParam, orderDir) {
    this.activePage = pageNumber
    this.loadRoles(null, orderParam, orderDir)
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }
}

export default RolesListStore
