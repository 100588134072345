import React, { useContext, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { EntitiesDataListPart } from 'models/FormMetadata/Parts'
import StoresContext from 'providers/storeContext'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'

const DynamicFormPartEntitiesDataList = ({ part, inputs, readOnly, inputRef }) => {
  const { t } = useTranslation('dynamicForms')
  const { currentDynamicStore } = useContext(StoresContext)
  const [inputStore] = useState(inputs.get(part.id).store)
  const [options, setOptions] = useState([])

  useEffect(() => {
    const optionsList = []
    part.dataKeys.forEach(({ key, type }) => {
      currentDynamicStore.inputs
        .get(key)
        .instanceEntities.filter((instanceEntity) => {
          return instanceEntity.type === type
        })
        .forEach((instanceEntity) => {
          optionsList.push({
            id: instanceEntity.id,
            value: instanceEntity.fullName,
          })
        })
    })

    setOptions(optionsList)
  }, [])

  const handleChangeOption = useCallback((option) => inputStore.setValue(option), [])

  return (
    <CreationInput
      as={FormSelect}
      inputStore={inputStore}
      label={part.label}
      placeholder={t('placeholderSelect', { name: part.label })}
      onChange={handleChangeOption}
      literal={readOnly}
      options={options}
      inputRef={inputRef}
      isMulti
    />
  )
}

DynamicFormPartEntitiesDataList.propTypes = {
  part: PropTypes.instanceOf(EntitiesDataListPart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

DynamicFormPartEntitiesDataList.defaultProps = {
  readOnly: true,
  inputRef: () => {},
}

export default observer(DynamicFormPartEntitiesDataList)
