import React from 'react'
import PropTypes from 'prop-types'
import LinkButton from 'presentation/LinkButton'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import styles from './fiduaciaryStructureHeaderLink.module.scss'

const FiduaciaryStructureHeaderLink = ({ text, route }) => {
  return (
    <LinkButton
      styleButton={styles.subtitle}
      text={text}
      icon={<ArrowRightIcon size={15} />}
      route={route}
    />
  )
}

FiduaciaryStructureHeaderLink.propTypes = {
  text: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
}

export default FiduaciaryStructureHeaderLink
