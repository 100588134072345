import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import Cookies from 'js-cookie'
import i18next from 'i18next'
import AsyncStore from 'stores/AsyncStore'
import { STATUS_STORED_ALTERNATIVE, STATUS_STORED } from 'presentation/StatusFilter/filterStatus'
import TrustService from 'services/TrustService'

const MINUTES_TO_EXPIRE = 10

class TrustsListStore extends AsyncStore {
  trusts = []
  activePage = 1
  pages = 5
  activeSearch = false
  selectedStatusFilter = ''
  orderDirection = ''
  visibleZipModal = false
  showDropdownOrder = false
  showDropdownFilter = false
  isFiltered = false
  zipModalMessage = ''
  zipModalSuccess = false
  isLoadingZip = false
  requestedZips = null
  requestedFiduciaryChartWithPeople = []
  requestedFiduciaryChartNoPeople = []
  lastSearch = ''
  lastOrderParam = ''
  lastOrderDir = ''
  lastStatus = ''
  lastAlternativeStatus = false
  selectedTrustForDownload = null
  isZipRequested = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      trusts: observable,
      activePage: observable,
      pages: observable,
      activeSearch: observable,
      selectedStatusFilter: observable,
      orderDirection: observable,
      visibleZipModal: observable,
      showDropdownOrder: observable,
      showDropdownFilter: observable,
      isFiltered: observable,
      zipModalMessage: observable,
      zipModalSuccess: observable,
      isLoadingZip: observable,
      requestedZips: observable,
      selectedTrustForDownload: observable,
      isZipRequested: observable,
      requestedFiduciaryChartWithPeople: observable,
      requestedFiduciaryChartNoPeople: observable,
      // actions
      isRequestedZip: action,
      loadTrusts: action,
      setIsFiltered: action,
      setSelectedStatusFilter: action,
      setOrderDirection: action,
      openZipModal: action,
      setShowDropdownOrder: action,
      setShowDropdownFilter: action,
      hideZipModal: action,
      requestZip: action,
      changeActivePage: action,
      setSelectedTrustForDownload: action,
      setIsZipRequested: action,

      // computeds
      visibleTrusts: computed,
    })

    this.isLoading = true
    this.trustService = new TrustService()
    this.requestedZips = Cookies.get('requestedZips')
      ? TrustsListStore.filterActiveRequestedZips()
      : []

    this.hideZipModal = this.hideZipModal.bind(this)
  }

  static filterActiveRequestedZips() {
    return JSON.parse(Cookies.get('requestedZips')).filter(
      (requestedZip) =>
        new Date() <
        new Date(new Date(requestedZip.createdAt).getTime() + MINUTES_TO_EXPIRE * 60 * 1000)
    )
  }

  isRequestedZip(trustId) {
    let isRequested = false
    this.requestedZips.forEach((requestedZip) => {
      if (requestedZip.id === trustId) {
        isRequested = true
      }
    })

    return isRequested
  }

  isRequestedFiduciaryChartWithPeople(trustId) {
    let isRequested = false
    this.requestedFiduciaryChartWithPeople.forEach((requestedFile) => {
      if (requestedFile.id === trustId) {
        isRequested = true
      }
    })
    return isRequested
  }

  isRequestedFiduciaryChartNoPeople(trustId) {
    let isRequested = false
    this.requestedFiduciaryChartNoPeople.forEach((requestedFile) => {
      if (requestedFile.id === trustId) {
        isRequested = true
      }
    })
    return isRequested
  }

  async loadTrusts(
    searchKey = null,
    orderParam = null,
    orderDir = null,
    status = '',
    resetpage = false
  ) {
    this.activeSearch = searchKey !== '' && searchKey !== null
    this.preRequest(this.loadTrusts)

    try {
      if (searchKey || searchKey === '') {
        this.lastSearch = searchKey
      }

      if (orderParam || orderParam === '') {
        this.lastOrderParam = orderParam
      }

      if (status || status === '') {
        this.lastStatus = status
        this.lastAlternativeStatus = ''
      }

      if (orderDir || orderDir === '') {
        this.lastOrderDir = orderDir
      }

      if (status === '') {
        this.lastStatus = ''
      }

      if (status === STATUS_STORED_ALTERNATIVE) {
        this.lastStatus = STATUS_STORED
        this.lastAlternativeStatus = true
      } else if (status === STATUS_STORED) {
        this.lastAlternativeStatus = false
      } else {
        this.lastAlternativeStatus = ''
      }

      if (resetpage) {
        this.activePage = 1
      }

      const response = await this.trustService.listTrusts(
        this.activePage,
        this.lastSearch,
        this.lastStatus,
        this.lastOrderParam,
        this.lastOrderDir,
        this.lastAlternativeStatus
      )

      runInAction(() => {
        this.trusts = response.trusts
        this.pages = response.pagination.totalPages

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  setIsFiltered(value) {
    this.isFiltered = value
  }

  setSelectedStatusFilter(value) {
    this.selectedStatusFilter = value
  }

  setOrderDirection(value) {
    this.orderDirection = value
  }

  openZipModal() {
    this.visibleZipModal = true
  }

  setIsZipRequested(value) {
    this.isZipRequested = value
  }

  setSelectedTrustForDownload(trust) {
    this.selectedTrustForDownload = trust
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }

  setShowDropdownFilter(value) {
    this.showDropdownFilter = value
  }

  hideZipModal() {
    this.visibleZipModal = false
  }

  setRequestedZipCookie() {
    Cookies.set('requestedZips', this.requestedZips, {
      expires: new Date(new Date().getTime() + MINUTES_TO_EXPIRE * 60 * 1000),
    })
  }

  setRequestedFiduciaryChartWithPeopleCookie() {
    Cookies.set('requestedFiduciaryChartsWithPeople', this.requestedFiduciaryChartWithPeople, {
      expires: new Date(new Date().getTime() + MINUTES_TO_EXPIRE * 60 * 1000),
    })
  }

  setRequestedFiduciaryChartNoPeopleCookie() {
    Cookies.set('requestedFiduciaryChartsNoPeople', this.requestedFiduciaryChartNoPeople, {
      expires: new Date(new Date().getTime() + MINUTES_TO_EXPIRE * 60 * 1000),
    })
  }

  async requestZip(trustId) {
    // this.openZipModal()
    let trust = trustId
    if (!trust) {
      trust = this.selectedTrustForDownload
    }

    if (!this.isRequestedZip(trust.id)) {
      runInAction(() => {
        this.isLoadingZip = true
      })

      try {
        await this.trustService.getZip(trust.id)

        runInAction(() => {
          this.requestedZips.push({ id: trust.id, createdAt: new Date() })
          this.setRequestedZipCookie()
          this.zipModalSuccess = true
          this.isLoadingZip = false
        })
      } catch (e) {
        runInAction(() => {
          this.zipModalSuccess = false
          this.zipModalMessage = e.response.data.error.message
          this.isLoadingZip = false
        })
      }
    } else {
      this.zipModalSuccess = false
      this.zipModalMessage = i18next.t('trusts:zipPending')
    }
  }

  async requestFiduciaryChartWithPeople(trustId) {
    let trust = trustId
    if (!trust) {
      trust = this.selectedTrustForDownload
    }

    if (!this.isRequestedFiduciaryChartWithPeople(trust.id)) {
      runInAction(() => {
        this.isLoadingZip = true
      })
      try {
        await this.trustService.getFiduciaryChart(true, trust.id)
        runInAction(() => {
          this.requestedFiduciaryChartWithPeople.push({ id: trust.id, createdAt: new Date() })
          this.setRequestedFiduciaryChartWithPeopleCookie()
          this.zipModalSuccess = true
          this.isLoadingZip = false
        })
      } catch (e) {
        runInAction(() => {
          this.zipModalSuccess = false
          this.zipModalMessage = e.response.data.error.message
          this.isLoadingZip = false
        })
      }
    } else {
      this.zipModalSuccess = false
      this.zipModalMessage = i18next.t('trusts:zipPending')
    }
  }

  async requestFiduciaryChartNoPeople(trustId) {
    let trust = trustId
    if (!trust) {
      trust = this.selectedTrustForDownload
    }

    if (!this.isRequestedFiduciaryChartNoPeople(trust.id)) {
      runInAction(() => {
        this.isLoadingZip = true
      })

      try {
        await this.trustService.getFiduciaryChart(false, trust.id)

        runInAction(() => {
          this.requestedFiduciaryChartNoPeople.push({ id: trust.id, createdAt: new Date() })
          this.setRequestedFiduciaryChartNoPeopleCookie()
          this.zipModalSuccess = true
          this.isLoadingZip = false
        })
      } catch (e) {
        runInAction(() => {
          this.zipModalSuccess = false
          this.zipModalMessage = e.response.data.error.message
          this.isLoadingZip = false
        })
      }
    } else {
      this.zipModalSuccess = false
      this.zipModalMessage = i18next.t('trusts:zipPending')
    }
  }

  get visibleTrusts() {
    return this.trusts
  }

  changeActivePage(pageNumber, orderParam, orderDir) {
    this.activePage = pageNumber

    this.loadTrusts(null, orderParam, orderDir, this.selectedStatusFilter)
  }
}

export default TrustsListStore
