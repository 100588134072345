import { makeAutoObservable } from 'mobx'
import moneyValueFormat from 'util/numberHelper'
import InputStore from 'stores/InputStore'

class Amount {
  constructor() {
    this.currency = new InputStore()
    this.value = new InputStore()
    this.error = false
    this.errorMessage = ''

    makeAutoObservable(this)
  }

  changeValue(value) {
    // eslint-disable-next-line no-restricted-globals
    if (value === '' || (!isNaN(value) && value > 0)) {
      this.value.setValue(value)
      this.clearError()
    }
  }

  changeCurrency(value) {
    this.currency.setValue(value)
    this.clearError()
  }

  fillFromJson({ value, currency }) {
    this.changeValue(value)
    this.changeCurrency(currency)
  }

  get moneyValue() {
    return moneyValueFormat(this.value.value, this.currency.value.value)
  }

  getMoneyValue(moneyFormat) {
    return moneyValueFormat(this.value.value, this.currency.value.value, moneyFormat)
  }

  get json() {
    return {
      value: this.value.value,
      currency: this.currency.value,
    }
  }

  setError(error, errorMessage = '') {
    this.error = error
    this.errorMessage = errorMessage
  }

  clearError() {
    this.error = false
    this.errorMessage = ''
  }
}

export default Amount
