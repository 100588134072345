import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import VoteItem from 'presentation/VoteItem'

const VotesList = ({ votes, handleViewVote, handleResolveVoting }) => {
  return votes.map((vote) => (
    <VoteItem
      vote={vote}
      key={`votes__${vote.id}`}
      handleViewVote={handleViewVote}
      handleResolveVoting={handleResolveVoting}
    />
  ))
}

VotesList.propTypes = {
  votes: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleViewVote: PropTypes.func.isRequired,
  handleResolveVoting: PropTypes.func.isRequired,
}

export default observer(VotesList)
