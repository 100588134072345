import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import Tippy from '@tippy.js/react'
import c from 'classnames'
import styles from './tooltip.module.scss'
import 'tippy.js/dist/tippy.css'

const ToolTip = ({
  children,
  tooltipContent,
  secondary,
  className,
  placement,
  disabled,
  ...props
}) => (
  <Tippy
    content={<span>{tooltipContent}</span>}
    className={c(
      styles.toolTip,
      secondary && styles.toolTipSecondary,
      placement === 'top' && styles.toolTipTop,
      placement === 'right' && styles.toolTipRight,
      placement === 'left' && styles.toolTipLeft,
      placement === 'bottom' && styles.toolTipBottom,
      disabled && styles.toolTipHidden,
      className
    )}
    placement={placement}
    zIndex={80}
    {...props}
  >
    <span>{children}</span>
  </Tippy>
)

ToolTip.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tooltipContent: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.any,
  placement: PropTypes.string,
  secondary: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

ToolTip.defaultProps = {
  children: null,
  className: null,
  placement: 'top',
  secondary: false,
  disabled: false,
}

export default ToolTip
