import { useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { BoolPart } from 'models/FormMetadata/Parts'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'

const CreationYesNoToggleContainer = observer(
  ({ inputStore, handleChange, readOnly, label, showWarning, warningId }) => (
    <YesNoToggleContainer
      desc={label}
      value={!!inputStore.value}
      handleChange={handleChange}
      viewMode={readOnly}
      showWarning={showWarning}
      warningId={warningId}
    />
  )
)

const DynamicFormPartBool = ({ part, inputs, readOnly }) => {
  const [inputStore] = useState(inputs.get(part.id).store)
  const handleChange = useCallback(() => inputStore.setValue(!inputStore.value), [])

  return (
    <CreationYesNoToggleContainer
      label={part.label}
      inputStore={inputStore}
      handleChange={handleChange}
      readOnly={readOnly || part.readOnly}
      showWarning={part.showWarning}
      warningId={part.warningId}
    />
  )
}

DynamicFormPartBool.propTypes = {
  part: PropTypes.instanceOf(BoolPart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
}

DynamicFormPartBool.defaultProps = {
  readOnly: true,
}

export default DynamicFormPartBool
