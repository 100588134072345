import React from 'react'
import PropTypes from 'prop-types'
import styles from './authFormLayout.module.scss'

const AuthFormLayout = ({ title, children }) => {
  return (
    <>
      <div className={styles.title}>{title}</div>
      {children}
    </>
  )
}

AuthFormLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default AuthFormLayout
