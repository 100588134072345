import * as yup from 'yup'
import Percentage from '../../Asset/Percentage'

class PercentageFormPartStore {
  validationRules

  constructor() {
    this.store = new Percentage()
  }

  fill(data) {
    this.store.fillFromJson(data || { value: '' })
  }

  createFromCurrent() {
    const newPercentageFormPartStore = new PercentageFormPartStore()

    newPercentageFormPartStore.fill(this.store.json)
    newPercentageFormPartStore.setValidationRules(this.validationRules)

    return newPercentageFormPartStore
  }

  get json() {
    return this.store.json
  }

  setValidationRules(validationRules) {
    this.validationRules = validationRules
  }

  // eslint-disable-next-line class-methods-use-this
  get validationSchema() {
    const schema = yup.object().shape({
      value: yup
        .number()
        .min(0)
        .max(100)
        .transform((val) => (Number.isNaN(val) ? undefined : val))
        .nullable(),
    })

    return schema
  }

  async validate() {
    if (this.validationSchema) {
      try {
        await this.validationSchema.validate(this.json)
      } catch (err) {
        this.store.value.setError(true, err.message)

        return Promise.reject(err.message)
      }
    }

    return Promise.resolve(true)
  }

  get isValid() {
    return !this.store.error
  }

  get value() {
    return this.store.value.value
  }

  setValue(value) {
    this.store.value.setValue(value)
  }
}

export default PercentageFormPartStore
