import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { GA_TRACKING_ID } from 'services/config'

/* eslint-disable class-methods-use-this */
class GoogleAnalytics extends React.Component {
  componentDidUpdate(prevProps) {
    setTimeout(() => {
      const { location } = this.props
      const { gtag } = window

      if (prevProps.location.pathname === location.pathname) {
        // don't log identical link clicks (nav links likely)
        return
      }

      // eslint-disable-next-line react/prop-types
      if (prevProps.history.action === 'PUSH' && typeof gtag === 'function') {
        gtag('config', GA_TRACKING_ID, {
          page_title: document.title,
          page_location: location.pathname + location.search,
          page_path: location.pathname + location.search,
        })
      }
    }, 1)
  }

  render() {
    return null
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.string.isRequired,
}

export default withRouter(GoogleAnalytics)
