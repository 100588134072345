import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './genericRow.module.scss'
import GenericRowDefaultExtraData from './GenericRowDefaultExtraData'

const GenericRow = ({
  name,
  type,
  extraData,
  TypeIcon,
  handleClickRow,
  errors,
  ExtraDataComponent,
}) => (
  <article className={c(styles.row, name === '' && styles.noName)} onClick={handleClickRow}>
    {errors ? <div className={styles.error}>{errors}</div> : null}
    {name !== '' && <div className={styles.name}>{name}</div>}
    <div className={styles.type}>
      {TypeIcon && <div className={styles.typeIcon}>{TypeIcon}</div>}
      {type}
    </div>
    <ExtraDataComponent extraData={extraData} />
  </article>
)

GenericRow.propTypes = {
  handleClickRow: PropTypes.func.isRequired,
  errors: PropTypes.number,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  extraData: PropTypes.oneOfType([PropTypes.array]),
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  TypeIcon: PropTypes.shape({}),
  ExtraDataComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

GenericRow.defaultProps = {
  name: null,
  type: null,
  errors: 0,
  extraData: [],
  TypeIcon: null,
  ExtraDataComponent: GenericRowDefaultExtraData,
}

export default GenericRow
