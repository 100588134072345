import { successToast } from 'helpers/Toast'
import { CORPORATIONS_CREATION } from 'routing/routes'

const changeNextCorporationStatus = (
  corporationsCreationStore,
  toastText = null,
  reload = true,
  history
) => {
  corporationsCreationStore.changeNextStatus(() => {
    if (toastText) {
      successToast(toastText, null, 'changedCorporationStatus').show()
    }

    if (reload) {
      corporationsCreationStore.reloadData(() => {
        const redirectRoute = `${CORPORATIONS_CREATION}${corporationsCreationStore.entitySections[0].route}`
        history.push(`${redirectRoute}?id=${corporationsCreationStore.idEdition}`)
      })
    }
  })
}

const changeBackCorporationStatus = (
  corporationsCreationStore,
  toastText = null,
  reload = true,
  history
) => {
  corporationsCreationStore.successRollBackModal(() => {
    if (toastText) {
      successToast(toastText, null, 'changedCorporationStatus').show()
    }

    if (reload) {
      const redirectRoute = `${CORPORATIONS_CREATION}${corporationsCreationStore.entitySections[0].route}`
      history.push(`${redirectRoute}?id=${corporationsCreationStore.idEdition}`)
      corporationsCreationStore.reloadData()
    }
  })
}

export { changeNextCorporationStatus, changeBackCorporationStatus }
