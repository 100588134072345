import { action, observable, makeObservable, runInAction } from 'mobx'
import File from 'models/File'

class InputFileStore {
  canUpload = false
  eleFileInput = null
  error = false
  errors = []
  warning = false
  warnings = []
  fileStore = new File()
  settingsStore = null

  constructor() {
    makeObservable(this, {
      // observables
      canUpload: observable,
      eleFileInput: observable,
      error: observable,
      errors: observable,
      warning: observable,
      warnings: observable,
      // actions
      setCanUpload: action,
      setCanNotUpload: action,
      fileCheck: action,
      setFileStore: action,
      setEleFileInput: action,
    })
  }

  setCanUpload() {
    this.canUpload = true
  }

  setCanNotUpload() {
    this.canUpload = false
  }

  setFileStore(fileStore) {
    this.fileStore = fileStore
  }

  setSettingsStore(settingsStore) {
    this.settingsStore = settingsStore
  }

  setEleFileInput(value) {
    this.eleFileInput = value
  }

  setError(value) {
    this.error = value
    if (!value) {
      this.errors = []
    }
  }

  fileCheck(file) {
    if (!this.canUpload) {
      this.setCanUpload()

      // If there is no file do nothing
      if (!file) {
        this.setCanNotUpload()
        return
      }

      // Check file size
      const fileSizeMB = file.size / 1024 / 1024
      if (fileSizeMB > this.settingsStore?.maxUploadSize) {
        // Delete filename from filestore
        this.fileStore.changeName(null)

        // Push Error to errors array
        this.error = true
        this.errors.push({
          title: 'attention',
          content: 'fileSizeError',
          maxMb: this.settingsStore.maxUploadSize,
        })

        // Clear the file input element from the store
        if (this.eleFileInput) {
          this.eleFileInput.value = ''
        }

        this.setCanNotUpload()
      }

      this.fileStore.changeName(file.name)
    } else {
      this.warning = true
      this.warnings.push({ title: 'warningUpload', opts: null, customId: 'warningUpload' })
    }
  }

  async fileUpload() {
    try {
      const file = this.eleFileInput.files[0]

      const uploadProgress = (progressEvent) => {
        this.fileStore.setLoaderValue((progressEvent.loaded * 100) / progressEvent.total)
      }
      this.fileStore.changeName(file.name)
      this.fileStore.setLoaderValue(0)

      const response = await this.fileStore.uploadContacts(file, uploadProgress)

      runInAction(() => {
        this.fileStore.setLoaderValue(null)
        this.setCanNotUpload()
      })

      return response
    } catch (e) {
      if (!e.response) {
        this.error = true
        this.errors.push({ title: 'common:attention', content: 'common:genericError' })
      } else {
        switch (e.response.status) {
          case 400:
            runInAction(() => {
              this.error = true
              this.errors.push({
                title: 'common:attention',
                content: e.response.data.error.errors[0],
              })
            })
            break
          case 413:
            runInAction(() => {
              this.error = true
              this.errors.push({
                title: 'common:attention',
                content: 'common:fileSizeError',
                maxMb: this.settingsStore.maxUploadSize,
              })
            })
            break
          case 422:
            runInAction(() => {
              this.error = true
              this.errors.push({
                title: 'Error',
                content: e.response.data.error.fields,
              })
            })
            break
          default:
            runInAction(() => {
              this.error = true
              this.errors.push({
                title: 'common:attention',
                content: 'common:genericError',
              })
            })
            break
        }
      }
      runInAction(() => {
        this.fileStore.changeName(null)
        this.eleFileInput = null
        this.setCanNotUpload()
      })

      return e.response.data
    }
  }
}

export default InputFileStore
