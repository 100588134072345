import { successToast } from 'helpers/Toast'
import { CLIENTS_LIST } from 'routing/routes'

const saveClient = (clientsCreationStore, toastText, history = null, redirectRoute) => {
  clientsCreationStore.save((id) => {
    successToast(toastText, null, 'savedClient').show()

    const firstLoad = !clientsCreationStore.isEdition

    if (clientsCreationStore.isEdition) {
      history.push(CLIENTS_LIST)
    }

    if (!clientsCreationStore.isEdition) {
      clientsCreationStore.setIsEdition(id)

      // Mueve al item siguiente de Client info
      history.push(`${redirectRoute}?id=${id}`)
    }

    clientsCreationStore.reloadData(!firstLoad)
  })
}

export default saveClient
