import { makeAutoObservable, runInAction } from 'mobx'
import Resizer from 'react-image-file-resizer'
import ImageService from 'services/ImageService'

class ImageInputStore {
  id = null
  image = null
  lastImage = null
  imageBase64 = null
  savedImage = false
  visibleCropModal = false
  loadingSaveImage = false
  imageTypeError = { error: false, message: '' }

  constructor() {
    makeAutoObservable(this)

    this.fileReader = null
    this.imageService = new ImageService()
  }

  handleFileRead() {
    Resizer.imageFileResizer(
      this.image, // is the file of the new image that can now be uploaded...
      200, // is the maxWidth of the  new image
      200, // is the maxHeight of the  new image
      'JPEG', // is the compressFormat of the  new image
      100, // is the quality of the  new image
      0, // is the ROTATION of the  new image
      (uri) => {
        runInAction(() => {
          this.imageBase64 = uri
        })
      } // is the callBack function of the new image URI
    )
  }

  setImageTypeError(message) {
    this.imageTypeError = { error: true, message }
  }

  clearImageTypeError() {
    this.imageTypeError = { error: false, message: '' }
  }

  validateImage(file) {
    const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']

    if (allowedTypes.indexOf(file.type) === -1) {
      this.setImageTypeError('invalidTypeImage')

      return false
    }

    return true
  }

  selectImage(file) {
    if (this.validateImage(file)) {
      this.clearImageTypeError()
      this.lastImage = this.image
      this.image = file
      this.visibleCropModal = true
      this.fileReader = new FileReader()
      this.fileReader.onloadend = this.handleFileRead()

      this.fileReader.readAsDataURL(this.image)
    } else {
      this.visibleCropModal = false
    }
  }

  setImage(image) {
    this.image = image
  }

  static convertBase64ToFile(image) {
    const byteString = atob(image.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i)
    }
    const newBlob = new Blob([ab], {
      type: 'image/jpeg',
    })
    return newBlob
  }

  async saveImage(imageBase64) {
    this.loadingSaveImage = true

    // realizar guardado de la imagen
    try {
      const image = ImageInputStore.convertBase64ToFile(imageBase64)

      const response = await this.imageService.saveImage(image)

      runInAction(() => {
        this.id = response.id
        this.image = imageBase64
        this.savedImage = true
        this.loadingSaveImage = false
      })
    } catch (e) {
      runInAction(() => {
        this.image = null
        this.savedImage = false
        this.loadingSaveImage = false
      })
    }
  }

  removePhoto() {
    this.saveImage(null)
  }

  closeCropModal(cancel = false) {
    this.visibleCropModal = false
    if (cancel) {
      this.image = this.lastImage
    }
  }
}

export default ImageInputStore
