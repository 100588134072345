import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class TrustAssetsService {
  listAssets = (trustId, alternativeStatus) =>
    axios
      .get(
        `${API_URL}/tenant/trusts/${trustId}/assets?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)
}

export default TrustAssetsService
