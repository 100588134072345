import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { TRANSACTIONS_APPROVE } from 'stores/AuthStore'
import Transaction from 'models/Transaction'
import Gated from 'util/Gated'
import CreationInput from 'presentation/CreationInput'
import DatePicker from 'presentation/DatePicker'
import TextArea from 'presentation/TextArea'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'
import VoterFormStatus from 'presentation/VoterFormStatus'
import SaveAndCreateToggle from '../SaveAndCreateToggle'
import TransactionCreateActionsButtons from './TransactionCreateActionsButtons'
import styles from './pollsForm.module.scss'

const PollsForm = ({
  viewMode,
  handleCancel,
  showTransaction,
  handleChangeDate,
  handleChangeName,
  checkedSaveAndCreate,
  handleSaveTransaction,
  handleChangeDescription,
  handleToggleCreateAnother,
  handleChangeApproveAutomatically,
}) => {
  const { t } = useTranslation('transactionsCreation')

  return (
    <>
      <div className={styles.formContainerTwoColumns}>
        <CreationInput
          literal={viewMode}
          label={t('titleLabel')}
          onChange={handleChangeName}
          inputStore={showTransaction.name}
          placeholder={t('titlePlaceholder')}
        />
        <CreationInput
          as={DatePicker}
          literal={viewMode}
          label={t('dateLabel')}
          onChange={handleChangeDate}
          placeholder={t('datePlaceholder')}
          inputStore={showTransaction.date}
        />
      </div>
      <div className={styles.formContainer}>
        <CreationInput
          as={TextArea}
          literal={viewMode}
          label={t('descriptionLabel')}
          onChange={handleChangeDescription}
          placeholder={t('descriptionPlaceholder')}
          inputStore={showTransaction.additionalData.description}
        />
        <Gated permission={TRANSACTIONS_APPROVE}>
          <YesNoToggleContainer
            viewMode={viewMode}
            desc={t('approveAutomatically')}
            value={showTransaction.approveAutomatically}
            handleChange={handleChangeApproveAutomatically}
          />
        </Gated>
        {viewMode && (
          <div className={styles.formStatus}>
            <VoterFormStatus status={showTransaction.status.status} label={t('status')} />
          </div>
        )}
      </div>
      <div className={styles.containerActionButtons}>
        {!viewMode && (
          <div className={styles.createFromCurrent}>
            <SaveAndCreateToggle
              onChange={handleToggleCreateAnother}
              checkedSaveAndCreate={checkedSaveAndCreate}
            />
          </div>
        )}
        <TransactionCreateActionsButtons
          isEdition={viewMode}
          handleCancel={handleCancel}
          handleSaveTransaction={handleSaveTransaction}
        />
      </div>
    </>
  )
}

PollsForm.propTypes = {
  viewMode: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  checkedSaveAndCreate: PropTypes.bool.isRequired,
  handleSaveTransaction: PropTypes.func.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  handleToggleCreateAnother: PropTypes.func.isRequired,
  handleChangeApproveAutomatically: PropTypes.func.isRequired,
  showTransaction: PropTypes.instanceOf(Transaction).isRequired,
}

PollsForm.defaultProps = {
  viewMode: false,
}

export default observer(PollsForm)
