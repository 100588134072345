import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import { TRANSACTIONS } from 'routing/routes'
import Button from 'presentation/Button'
import styles from './buttonBack.module.scss'

const useBackButtonURL = () => {
  const { search } = useLocation()
  const structure = new URLSearchParams(search).get('structure')

  return `${TRANSACTIONS}?structure=${structure}`
}

const ButtonBack = ({ onClick, to, ...restProps }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
      return
    }

    if (to) {
      history.push(to)
      return
    }

    history.goBack()
  }, [])

  return (
    <Button
      secondary
      onClick={handleClick}
      iconPositionLeft
      label={t('common:back')}
      className={styles.backButton}
      icon={<ArrowLeftIcon size={20} />}
      {...restProps}
    />
  )
}

ButtonBack.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
}

ButtonBack.defaultProps = {
  onClick: null,
  to: null,
}

export { ButtonBack as default, useBackButtonURL }
