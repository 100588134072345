import { observable, action, runInAction, makeObservable } from 'mobx'
import i18next from 'i18next'
import * as yup from 'yup'
import AuthService from 'services/AuthService'
import AsyncStore from 'stores/AsyncStore'
import InputStore from 'stores/InputStore'

class SendForgotPasswordStore extends AsyncStore {
  email = new InputStore()
  error = false
  success = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      email: observable,
      error: observable,
      success: observable,
      // actions
      send: action,
      changeEmail: action,
      validateEmail: action,
    })

    this.authService = new AuthService()
  }

  async send() {
    this.validateEmail()
    this.error = null
    if (!this.email.error && this.email.value) {
      this.preRequest()

      try {
        await this.authService.forgotPassword(this.email.value)

        runInAction(() => {
          this.success = true
          this.onSuccessRequest()
        })
      } catch (e) {
        runInAction(() => {
          this.success = false
          this.error = e.response.data.error.errors
            ? e.response.data.error.errors[0]
            : i18next.t('common:genericError')

          this.onErrorRequest(e)
        })
      }
    }
  }

  changeEmail(value) {
    this.email.setValue(value)
    this.validateEmail()
  }

  validateEmail() {
    const schema = yup.string().email()

    if (this.email.value) {
      try {
        schema.validateSync(this.email.value)
      } catch (e) {
        this.email.setError(true)
      }
    }
  }
}

export default SendForgotPasswordStore
