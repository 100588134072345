import React from 'react'
import PropTypes from 'prop-types'
import ToggleButton from 'react-toggle-button'

/*
 * receives same as react-toggle-button
 * */
const YesNoToggle = ({ value, ...props }) => (
  <ToggleButton
    colors={{
      active: {
        base: 'rgb(224, 176, 25)',
      },
      inactive: {
        base: 'rgb(109, 109, 114)',
      },
    }}
    value={value}
    {...props}
  />
)

YesNoToggle.propTypes = {
  value: PropTypes.bool.isRequired,
}

export default YesNoToggle
