import { useCallback } from 'react'
import { observer } from 'mobx-react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import TrashOutlineIcon from 'mdi-react/TrashOutlineIcon'
import InputStore from 'stores/InputStore'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import Button from 'presentation/Button'
import styles from './transactionsDestinationAssetForm.module.scss'

const TransactionsDestinationAssetsForm = ({
  assetInputStore,
  amountInputStore,
  assetOptions,
  index,
  handleDeleteAsset,
}) => {
  const { t } = useTranslation('transactions')

  const handleAssetChange = useCallback(
    (value) => {
      if (value.canTransaction) {
        assetInputStore.setValue(value)
      } else {
        assetInputStore.setValue(value)
        assetInputStore.setError(true, t('cantTransactionMessage'))
      }
    },
    [assetInputStore.value]
  )

  const handleAmountChange = (e) => {
    amountInputStore.setValue(e.target.value)
  }

  return (
    <div className={styles.container}>
      <CreationInput
        as={FormSelect}
        label={t('asset')}
        inputStore={assetInputStore}
        onChange={handleAssetChange}
        placeholder={t('selectDestination')}
        options={assetOptions}
      />
      <CreationInput
        label={`${t('amount')} ${
          assetInputStore.value.currency ? `(${assetInputStore.value.currency})` : ''
        }`}
        inputStore={amountInputStore}
        onChange={handleAmountChange}
        placeholder={t('amount')}
      />
      {index !== 0 && (
        <Button icon={<TrashOutlineIcon />} onClick={() => handleDeleteAsset(index)} circle />
      )}
    </div>
  )
}

TransactionsDestinationAssetsForm.propTypes = {
  assetInputStore: PropTypes.instanceOf(InputStore).isRequired,
  amountInputStore: PropTypes.instanceOf(InputStore).isRequired,
  index: PropTypes.number.isRequired,
  assetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  handleDeleteAsset: PropTypes.func,
}

TransactionsDestinationAssetsForm.defaultProps = {
  handleDeleteAsset: () => {},
}

export default observer(TransactionsDestinationAssetsForm)
