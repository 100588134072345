class OptionsContactSearch {
  constructor(id, name, personalEmail, companyEmail, firstName, lastName, type, data) {
    this.id = id
    this.name = name
    this.personalEmail = personalEmail
    this.companyEmail = companyEmail
    this.firstName = firstName
    this.lastName = lastName
    this.type = type
    this.rawData = data
  }

  get createName() {
    let name
    if (this.type === 'company') {
      name = this.name
    }
    if (this.type === 'individual') {
      name = `${this.firstName} ${this.lastName}`
    }
    return name
  }

  get createEmail() {
    let email
    if (this.type === 'company') {
      email = this.companyEmail
    }
    if (this.type === 'individual') {
      email = this.personalEmail
    }
    return email
  }

  get optionsSelect() {
    const options = {}
    options.id = this.id
    options.value = `${this.createName}`
    options.label = `${this.createName}`
    options.email = `${this.createEmail}`
    options.contact = this
    return options
  }

  static fromJson({ id, data }) {
    return new OptionsContactSearch(
      id,
      data.name,
      data.personalEmail,
      data.companyEmail,
      data.firstName,
      data.lastName,
      data.type,
      data
    )
  }
}

export default OptionsContactSearch
