import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import { STATUS_STORED_ALTERNATIVE, STATUS_STORED } from 'presentation/StatusFilter/filterStatus'
import AsyncStore from 'stores/AsyncStore'
import CorporationService from 'services/CorporationService'

class CorporationsListStore extends AsyncStore {
  corporations = []
  activePage = 1
  isFiltered = false
  pages = 5
  activeSearch = false
  showDropdownFilter = false
  showDropdownOrder = false
  orderDirection = ''
  selectedStatusFilter = ''
  lastSearch = ''
  lastOrderParam = ''
  lastOrderDir = ''
  lastStatus = ''
  lastAlternativeStatus = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      corporations: observable,
      activePage: observable,
      isFiltered: observable,
      pages: observable,
      activeSearch: observable,
      showDropdownFilter: observable,
      showDropdownOrder: observable,
      orderDirection: observable,
      selectedStatusFilter: observable,
      // actions
      loadCorporations: action,
      setSelectedStatusFilter: action,
      setOrderDirection: action,
      setIsFiltered: action,
      changeActivePage: action,
      setShowDropdownFilter: action,
      setShowDropdownOrder: action,
      // computeds
      visibleCorporations: computed,
    })

    this.isLoading = true
    this.corporationService = new CorporationService()
  }

  async loadCorporations(
    searchKey = null,
    orderParam = null,
    orderDir = null,
    status = '',
    resetpage = false
  ) {
    this.activeSearch = searchKey !== '' && searchKey !== null

    this.preRequest()

    try {
      if (searchKey || searchKey === '') {
        this.lastSearch = searchKey
      }

      if (orderParam || orderParam === '') {
        this.lastOrderParam = orderParam
      }

      if (orderDir || orderDir === '') {
        this.lastOrderDir = orderDir
      }

      if (status || status === '') {
        this.lastStatus = status
      }

      if (status === STATUS_STORED_ALTERNATIVE) {
        this.lastStatus = STATUS_STORED
        this.lastAlternativeStatus = true
      } else if (status === STATUS_STORED) {
        this.lastAlternativeStatus = false
      } else {
        this.lastAlternativeStatus = ''
      }

      if (resetpage) {
        this.activePage = 1
      }

      const response = await this.corporationService.listCorporations(
        this.activePage,
        this.lastSearch,
        this.lastStatus,
        this.lastOrderParam,
        this.lastOrderDir,
        this.lastAlternativeStatus
      )

      runInAction(() => {
        this.corporations = response.corporations
        this.pages = response.pagination.totalPages

        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  setSelectedStatusFilter(value) {
    this.selectedStatusFilter = value
  }

  setOrderDirection(value) {
    this.orderDirection = value
  }

  setIsFiltered(value) {
    this.isFiltered = value
  }

  get visibleCorporations() {
    return this.corporations
  }

  changeActivePage(pageNumber, orderParam, orderDir) {
    this.activePage = pageNumber

    this.loadCorporations(null, orderParam, orderDir, this.selectedStatusFilter)
  }

  setShowDropdownFilter(value) {
    this.showDropdownFilter = value
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }
}

export default CorporationsListStore
