import React from 'react'
import PropTypes from 'prop-types'
import MetaTitle from 'util/MetaTitle'
import Button from 'presentation/Button'
import styles from './genericNotFoundError.module.scss'

const GenericNotFoundError = ({
  title,
  description,
  goBackFunction,
  image,
  textButton,
  noButton,
}) => (
  <div className={styles.pageWrapper}>
    <MetaTitle pageTitle={title} />
    <div className={styles.notFoundWrapper}>
      <div className={styles.title}>
        {title}
        <span>.</span>
      </div>
      <div className={styles.desc}>{description}</div>
      {!noButton && (
        <div className={styles.button}>
          <Button label={textButton} big primary onClick={() => goBackFunction()} />
        </div>
      )}
      {image && (
        <div className={styles.image}>
          <img src={image} alt="" />
        </div>
      )}
    </div>
  </div>
)

GenericNotFoundError.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  goBackFunction: PropTypes.func.isRequired,
  image: PropTypes.string,
  noButton: PropTypes.bool,
  textButton: PropTypes.string.isRequired,
}

GenericNotFoundError.defaultProps = {
  noButton: false,
  image: null,
}

export default GenericNotFoundError
