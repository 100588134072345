import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './linkButton.module.scss'

const LinkButton = ({ route, text, styleButton, icon }) => {
  return (
    <Link className={c(styleButton || styles.linkButton)} to={route}>
      {text}
      {icon && <div className={styles.icon}>{icon}</div>}
    </Link>
  )
}

LinkButton.propTypes = {
  icon: PropTypes.node,
  styleButton: PropTypes.string,
  text: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
}

LinkButton.defaultProps = {
  icon: null,
  styleButton: '',
}

export default LinkButton
