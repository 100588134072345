import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import FormLabel from 'presentation/FormLabel'
import TextareaAutosize from 'react-textarea-autosize'
import LiteralValue from 'presentation/LiteralValue'
import styles from './textArea.module.scss'

const TextArea = ({
  label,
  inputRef,
  error,
  value,
  literal,
  fullHeightLabel,
  minRows,
  autosize,
  ...props
}) =>
  literal ? (
    <LiteralValue label={label} value={value} />
  ) : (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={c(fullHeightLabel && styles.fullHeightLabel)}>
      {label && <FormLabel label={label} />}
      <TextareaAutosize
        className={c(styles.input, fullHeightLabel && styles.fullHeightArea, error && styles.error)}
        ref={inputRef}
        value={value}
        minRows={minRows}
        maxRows={autosize ? 1 : minRows}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            e.target.blur()
          }
        }}
        {...props}
      />
    </label>
  )

TextArea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  error: PropTypes.bool,
  fullHeightLabel: PropTypes.bool,
  minRows: PropTypes.number,
  literal: PropTypes.bool,
  autosize: PropTypes.bool,
}

TextArea.defaultProps = {
  label: '',
  value: '',
  inputRef: () => {},
  error: false,
  fullHeightLabel: false,
  minRows: 4,
  literal: false,
  autosize: false,
}

export default TextArea
