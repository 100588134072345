import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import * as Routes from 'routing/routes'
import { useTranslation } from 'react-i18next'
import DynamicFormSubMenu from 'presentation/DynamicFormSubMenu'
import ClientChangeStatusButton from 'presentation/ClientChangeStatusButton'
import ClientStatusName from 'presentation/ClientStatusName'
import StoresContext from 'providers/storeContext'
import MenuButton from 'presentation/MenuButton'
import { SubMenu, subMenuUrl, toggleNavBar } from './SubMenuUtils'

const SubMenuCompanyClients = observer(({ id: idClient, view, collapsed }) => {
  const { clientsCreationStore, navBarStore } = useContext(StoresContext)
  const { t } = useTranslation('navBar')

  useEffect(() => {
    if (clientsCreationStore.errorLoadEdit) {
      navBarStore.setForcedOnlyMainNavBar()
    }
  }, [clientsCreationStore.errorLoadEdit])

  const getSubMenuUrl = (url, smIdView, smView, disabled) =>
    subMenuUrl(url, smIdView, smView, disabled, Routes.COMPANY_CLIENT_CREATION)

  return (
    <SubMenu collapsed={collapsed}>
      <DynamicFormSubMenu
        dynamicFormsStore={clientsCreationStore}
        sectionUrlFunction={getSubMenuUrl}
        toggleNavBar={toggleNavBar}
        entityTypeName={t('client')}
        entityId={idClient}
        collapsed={collapsed}
        view={view}
        changeStatusButton={
          clientsCreationStore.isEdition && (
            <ClientChangeStatusButton toggleNavBar={toggleNavBar} collapsed={collapsed} />
          )
        }
        statusComponent={ClientStatusName}
        extraMenuButtons={
          clientsCreationStore.isEdition && (
            <MenuButton
              url={getSubMenuUrl(Routes.COMPANY_CLIENT_FINANCIAL_STRUCTURE, idClient, view)}
              onClick={toggleNavBar}
              disabled={clientsCreationStore.subMenuDisabled}
              iconProps={{ size: 17 }}
              menuIconName="SitemapIcon"
              menuLabel={t('fiduciaryStructures')}
              collapsed={collapsed}
            />
          )
        }
      />
    </SubMenu>
  )
})

export default SubMenuCompanyClients
