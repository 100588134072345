import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Part } from 'models/FormMetadata'
import AssetCompanyForm from 'presentation/AssetCompanyForm'

const DynamicFormPartAssetCompany = ({ readOnly, part, inputs }) => {
  const [store] = useState(inputs.get(part.id))

  return <AssetCompanyForm store={store} part={part} inputs={inputs} readOnly={readOnly} />
}

DynamicFormPartAssetCompany.propTypes = {
  readOnly: PropTypes.bool,
  part: PropTypes.instanceOf(Part).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

DynamicFormPartAssetCompany.defaultProps = {
  readOnly: true,
}

export default observer(DynamicFormPartAssetCompany)
