import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import ClientStatus from 'models/ClientStatus'
import styles from './clientStatusName.module.scss'

const ClientStatusName = ({ status, onlyBullet }) => {
  const { t } = useTranslation('clientsCreation')
  return (
    <div className={c(styles.statusContainer, onlyBullet && styles.onlyBullet)}>
      <span
        className={c(
          styles.bullet,
          status.isInactive && styles.statusInactive,
          status.isActive && styles.statusActive
        )}
        title={t(`status_${status.status}`)}
      />
      <div className={c(styles.status)}>{t(`status_${status.status}`)}</div>
    </div>
  )
}

ClientStatusName.propTypes = {
  status: PropTypes.instanceOf(ClientStatus),
  onlyBullet: PropTypes.bool,
}

ClientStatusName.defaultProps = {
  status: new ClientStatus(),
  onlyBullet: false,
}

export default observer(ClientStatusName)
