import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import File from 'models/File'
import InputFile from 'presentation/InputFile'
import CreationInput from 'presentation/CreationInput/CreationInput'
import DownloadFileLink from 'presentation/InputFile/DownloadFileLink'
import styles from './documentForSignRow.module.scss'

/* eslint-disable class-methods-use-this */
class DocumentForSignRow extends React.Component {
  unsignedUploaded() {
    const { file } = this.props

    return file.id !== undefined && file.id !== null && file.id !== ''
  }

  render() {
    const {
      t,
      documentName,
      file,
      signedFile,
      handleStartUploadingFile,
      handleFinishUploadingFile,
      handleBeforeDeletion,
      errors,
      section,
      sectionTitle,
    } = this.props

    return (
      <div className={styles.wrapper}>
        {errors ? <div className={styles.error}>{errors}</div> : null}
        <div className={styles.documents}>
          <div className={styles.sectionTitleContainer}>
            <div className={styles.sectionTitle}>{section}</div>
          </div>
          <div className={styles.documentType}>{sectionTitle}</div>
          <div className={styles.unsignedDoc}>
            <p className={styles.subtitle}>{t('unsignedDocument')}</p>
            <div className={styles.downloadLinkContainer}>
              <DownloadFileLink
                fileStore={file}
                className={styles.downloadBtn}
                circle
                noUploadFile={!this.unsignedUploaded()}
              />
              <div>{this.unsignedUploaded() ? documentName : t('noFileUploaded')}</div>
            </div>
          </div>
          <div className={styles.signedDoc}>
            <CreationInput
              as={InputFile}
              fileStore={signedFile}
              label={t('signedDocument')}
              placeholder={t('signedDocumentPlaceholder')}
              handleStartUploadingFile={handleStartUploadingFile}
              handleFinishUploadingFile={handleFinishUploadingFile}
              onBeforeDeletion={handleBeforeDeletion}
            />
          </div>
        </div>
      </div>
    )
  }
}

DocumentForSignRow.propTypes = {
  t: PropTypes.func.isRequired,
  documentName: PropTypes.string,
  handleFinishUploadingFile: PropTypes.func.isRequired,
  handleStartUploadingFile: PropTypes.func,
  handleBeforeDeletion: PropTypes.func,
  file: PropTypes.instanceOf(File),
  section: PropTypes.string.isRequired,
  signedFile: PropTypes.instanceOf(File),
  errors: PropTypes.number.isRequired,
  sectionTitle: PropTypes.string.isRequired,
}

DocumentForSignRow.defaultProps = {
  handleStartUploadingFile: null,
  handleBeforeDeletion: null,
  file: new File(),
  signedFile: new File(),
  documentName: null,
}

export default withTranslation('signedDocuments')(DocumentForSignRow)
