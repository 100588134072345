import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TextareaPart } from 'models/FormMetadata'
import CreationInput from 'presentation/CreationInput'
import TextArea from 'presentation/TextArea'

const DynamicFormPartTextarea = ({ part, inputs, readOnly, inputRef }) => {
  const [inputStore] = useState(inputs.get(part.id))

  const { t } = useTranslation('dynamicForms')

  const handleChangeText = useCallback((e) => {
    inputStore.store.setValue(e.target.value)
    inputStore.validate()
  }, [])
  const handleOnBlur = useCallback(() => inputStore.validate(), [])

  return (
    <CreationInput
      inputStore={inputStore.store}
      label={part.label}
      placeholder={t('placeholderText', { name: part.label })}
      onChange={handleChangeText}
      literal={readOnly}
      as={TextArea}
      inputRef={inputRef}
      onBlur={handleOnBlur}
    />
  )
}

DynamicFormPartTextarea.propTypes = {
  part: PropTypes.instanceOf(TextareaPart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

DynamicFormPartTextarea.defaultProps = {
  readOnly: true,
  inputRef: () => {},
}

export default DynamicFormPartTextarea
