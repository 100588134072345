import React, { useState, useCallback } from 'react'
import UnauthorizedContainer from 'presentation/UnauthorizedContainer'
import { observer } from 'mobx-react'
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon'
import { useTranslation } from 'react-i18next'
import SignInInput from 'presentation/SignInInput'
import { LOGIN } from 'routing/routes'
import Button from 'presentation/Button'
import LinkButton from 'presentation/LinkButton'
import AuthFormLayout from 'presentation/AuthFormLayout'
import SuccessLoginMessage from 'presentation/SuccessLoginMessage'
import LoginError from 'presentation/LoginError'
import SendForgotPasswordStore from './SendForgotPasswordStore'
import styles from './sendForgotPassword.module.scss'

const SendForgotPassword = () => {
  const [sendForgotPasswordStore] = useState(new SendForgotPasswordStore())

  const { t } = useTranslation('signIn')
  const handleEmail = useCallback((e) => sendForgotPasswordStore.changeEmail(e.target.value), [])

  return (
    <UnauthorizedContainer title={t('pageTitles:forgotPassword')}>
      <AuthFormLayout title={t('titleForgotPassword')}>
        {sendForgotPasswordStore.error && <LoginError message={sendForgotPasswordStore.error} />}
        {sendForgotPasswordStore.success && (
          <div className={styles.containerSuccessLogin}>
            <SuccessLoginMessage
              route={LOGIN}
              icon={<EmailOutlineIcon size={30} />}
              messageFirstLine={t('successMsgForgotPasswordFirstLine')}
              messageSecondLine={t('successMsgForgotPasswordSecondLine')}
              textButton={t('backToLogin')}
            />
          </div>
        )}
        {!sendForgotPasswordStore.success && (
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              sendForgotPasswordStore.send()
              return false
            }}
            className={styles.forgotFormForm}
          >
            <SignInInput
              type="email"
              placeholder={t('username')}
              handleChange={handleEmail}
              value={sendForgotPasswordStore.email.value}
              hasError={sendForgotPasswordStore.email.error || sendForgotPasswordStore.error}
              autoFocus
            />
            <div className={styles.actionsContainer}>
              <Button
                isLoading={sendForgotPasswordStore.isLoading}
                label={t('btn_sendEmail')}
                primary
                big
                type="submit"
              />
              <LinkButton route={LOGIN} text={t('backToLogin')} />
            </div>
          </form>
        )}
      </AuthFormLayout>
    </UnauthorizedContainer>
  )
}

export default observer(SendForgotPassword)
