import PropTypes from 'prop-types'
import ContactActivity from 'models/ContactActivity'
import AssetActivity from 'models/AssetActivity'
import DocumentActivity from 'models/DocumentActivity'
import GovernorActivity from 'models/GovernorActivity'
import TransactionActivity from 'models/TransactionActivity'
import StoreApprovalActivity from 'models/StoreApprovalActivity'
import ListDate from 'models/ListDate'
import TableItemDate from 'presentation/TableItemDate'
import ActivityIconSelector from 'presentation/ActivityIconSelector'
import UpdateIcon from 'mdi-react/UpdateIcon'
import styles from './activityDetailsTableItem.module.scss'

const ActivityDetailsTableItem = ({ listItem, view, handleSelectItem }) => {
  return (
    <div
      className={[styles.activityTableItem, styles[`activityTableItem--${view}`]].join(' ')}
      onClick={() => handleSelectItem(listItem)}
      role="button"
      tabIndex={0}
    >
      <div className={styles.itemName}>{listItem.description}</div>
      <div className={styles.activityTableItemBadge}>
        <ActivityIconSelector type={listItem.type} />
        {listItem.showingName}
      </div>
      <div className={styles.activityTableItemStatus}>{listItem.status}</div>
      <div className={styles.activityTableItemUpdatedAt}>
        <TableItemDate
          listDate={new ListDate(listItem.updated)}
          view={view}
          icon={<UpdateIcon size={12} />}
        />
      </div>
      <div className={styles.activityTableItemCreatedAt}>
        <TableItemDate
          listDate={new ListDate(listItem.created)}
          view={view}
          icon={<UpdateIcon size={12} />}
        />
      </div>
    </div>
  )
}

ActivityDetailsTableItem.propTypes = {
  listItem: PropTypes.oneOfType([
    PropTypes.instanceOf(AssetActivity),
    PropTypes.instanceOf(ContactActivity),
    PropTypes.instanceOf(DocumentActivity),
    PropTypes.instanceOf(GovernorActivity),
    PropTypes.instanceOf(TransactionActivity),
    PropTypes.instanceOf(StoreApprovalActivity),
  ]).isRequired,
  view: PropTypes.string.isRequired,
  handleSelectItem: PropTypes.func,
}

ActivityDetailsTableItem.defaultProps = {
  handleSelectItem: () => {},
}

export default ActivityDetailsTableItem
