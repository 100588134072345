import { successToast } from 'helpers/Toast'

const saveContact = (contactsCreationStore, toastText, history = null, redirectRoute) => {
  contactsCreationStore.save((id) => {
    successToast(toastText, null, 'savedContact').show()

    const firstLoad = !contactsCreationStore.isEdition

    if (contactsCreationStore.isEdition) {
      // Save and go to list of contacts
      history.push('/contacts')
    }

    if (!contactsCreationStore.isEdition) {
      contactsCreationStore.setIsEdition(id)

      // Mueve al item siguiente de Contact info
      history.push(`${redirectRoute}?id=${id}`)
    }

    contactsCreationStore.reloadData(!firstLoad)
  })
}

export default saveContact
