import React from 'react'
import { Route } from 'react-router'
import PropTypes from 'prop-types'

const SubMenuRoute = ({ component: Component, collapsed, query, path }) => {
  return (
    <Route
      path={path}
      render={() => {
        const params = new URLSearchParams(query)

        return <Component id={params.get('id')} view={params.get('v')} collapsed={collapsed} />
      }}
    />
  )
}

SubMenuRoute.propTypes = {
  path: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
}

export default SubMenuRoute
