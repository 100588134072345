/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './tabs.module.scss'

const TabTitles = ({ tabs, titlesRef, removeOutPageScrollMobile, activeTab, handleChangeTab }) => {
  const titles = []

  tabs.forEach((child, i) => {
    if (child.props.name !== '' && child.props.name !== null && child.props.name !== undefined) {
      titles.push(
        <div
          className={c(
            styles.tabTitle,
            activeTab === i && styles.activeTabTitle,
            child.props.errors && styles.tabTitleError
          )}
          key={`tabname_${child.props.name}`}
          role="link"
          tabIndex={0}
          onClick={(e) => handleChangeTab(e.target, i)}
        >
          {child.props.errors ? <div className={styles.error}>{child.props.errors}</div> : null}
          {child.props.name}
        </div>
      )
    }
  })

  if (titles.length === 0) {
    return null
  }

  return (
    <div
      ref={titlesRef}
      className={c(
        styles.tabsTitles,
        removeOutPageScrollMobile && styles.removeOutPageScrollMobile
      )}
    >
      <div className={styles.tabTitlesScroll}>{titles}</div>
    </div>
  )
}

const Tabs = ({ children, defaultActiveTab, onChangeTab, removeOutPageScrollMobile }, ref) => {
  const titlesRef = useRef()

  const activatedTab = children.reduce((prev, current, i) => {
    if (current.props.active) {
      return i
    }
    return prev
  }, defaultActiveTab)

  const [activeTab, setActiveTab] = useState(activatedTab)

  const handleChangeTab = useCallback(
    (element, index) => {
      // Mueve el scroll
      setActiveTab(index)
    },
    [titlesRef]
  )

  useEffect(() => {
    if (onChangeTab) {
      onChangeTab(activeTab)
    }
  }, [activeTab])

  useImperativeHandle(ref, () => ({
    getSelected: () => ({
      index: activeTab,
      title: children[activeTab].props.name,
    }),
  }))

  useEffect(() => {
    if (activatedTab !== activeTab) {
      setActiveTab(activatedTab)

      handleChangeTab(
        titlesRef.current.querySelectorAll(`.${styles.tabTitlesScroll} > div`)[activatedTab],
        activatedTab
      )
    }
  }, [children])

  return (
    <div className={styles.tabsContainer}>
      <TabTitles
        tabs={children}
        titlesRef={titlesRef}
        removeOutPageScrollMobile={removeOutPageScrollMobile}
        activeTab={activeTab}
        handleChangeTab={handleChangeTab}
      />
      <div className={styles.tabsPanel}>
        {children.map((child, i) => (
          <div
            key={`tabcontent_${child.props.name}`}
            className={c(styles.tab, activeTab === i && styles.tabVisible)}
          >
            {child.props.active && i !== activatedTab
              ? React.cloneElement(child, { active: false })
              : child}
          </div>
        ))}
      </div>
    </div>
  )
}

TabTitles.propTypes = {
  titlesRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeTab: PropTypes.number,
  handleChangeTab: PropTypes.func.isRequired,
  removeOutPageScrollMobile: PropTypes.bool,
  tabs: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

TabTitles.defaultProps = {
  removeOutPageScrollMobile: false,
  activeTab: 0,
}

/* Tabs.propTypes = {
  children: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  defaultActiveTab: PropTypes.number,
  removeOutPageScrollMobile: PropTypes.bool.isRequired,
}

Tabs.defaultProps = {
  defaultActiveTab: 0,
} */

export default forwardRef(Tabs)
