import Part, { TYPE_CONTACT_SEARCH } from './Part'

class ContactSearchPart extends Part {
  contactMapExceptions = []

  constructor(id, contactMapExceptions = null) {
    super(id, TYPE_CONTACT_SEARCH)

    this.contactMapExceptions = contactMapExceptions
  }

  static fromJson({ id, contactMapExceptions = null }) {
    return new ContactSearchPart(id, contactMapExceptions)
  }
}

export default ContactSearchPart
