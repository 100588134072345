class CausedBy {
  constructor(firstName, lastName) {
    this.firstName = firstName
    this.lastName = lastName
  }

  static fromJson({ firstName, lastName }) {
    return new CausedBy(firstName, lastName)
  }

  get name() {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    if (this.lastName) {
      return this.lastName
    }

    if (this.firstName) {
      return this.firstName
    }

    return null
  }
}

export default CausedBy
