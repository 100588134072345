import { useContext, useCallback, useEffect, useState } from 'react'
import { warningToast } from 'helpers/Toast'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import c from 'classnames'
import PlusIcon from 'mdi-react/PlusIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import File from 'models/File'
import FormLabel from 'presentation/FormLabel'
import Modal from 'scenes/Modal'
import StoresContext from 'providers/storeContext'
import DownloadFile from './DownloadFile'
import DownloadFileLink from './DownloadFileLink'
import styles from './inputContactsFile.module.scss'

const InputContactsFile = ({
  label,
  placeholder,
  onChange,
  fileStore,
  literal,
  autoUpload,
  onBeforeDeletion,
}) => {
  const { t } = useTranslation('dynamicForms')
  const [blobUrl, setBlobUrl] = useState('')
  const { settingsStore, inputContactsFileStore } = useContext(StoresContext)

  const handleSetErrorModal = useCallback((v) => {
    inputContactsFileStore.setError(v)
  }, [])

  const handleChangeFileInput = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()

    inputContactsFileStore.fileCheck(e.target.files[0])

    if (blobUrl) {
      URL.revokeObjectURL(blobUrl)
    }

    const blob = new Blob(e.target.files)
    setBlobUrl(URL.createObjectURL(blob))

    if (autoUpload) {
      inputContactsFileStore.fileUpload()
    }
  }, [])

  const handleRevokeLocalFileUrl = useCallback(() => {
    URL.revokeObjectURL(blobUrl)
  }, [])

  useEffect(() => {
    inputContactsFileStore.setFileStore(fileStore)
    inputContactsFileStore.setSettingsStore(settingsStore)
  }, [fileStore])

  useEffect(() => {
    if (inputContactsFileStore.warnings.length > 0) {
      const [warningObject] = inputContactsFileStore.warnings
      warningToast(t(warningObject.title), warningObject.opts, warningObject.customId).show()
    }
  }, [inputContactsFileStore.warning])

  if (literal) {
    return <DownloadFile fileStore={fileStore} label={label} />
  }

  return (
    <label>
      {label && <FormLabel label={label} />}
      <div className={styles.inputContainer}>
        <input
          type="file"
          className={styles.inputFileHidden}
          ref={(ele) => {
            inputContactsFileStore.setEleFileInput(ele)
          }}
          onChange={handleChangeFileInput}
        />
        <input
          style={(() => {
            if (fileStore.isUploading) {
              const progressStyle = {
                backgroundImage:
                  'linear-gradient(to right, var(--primary-btn-main-color-1), var(--primary-btn-main-color-2))',
                backgroundRepeat: 'no-repeat',
              }

              if (fileStore.loaderValue) {
                return Object.assign(progressStyle, {
                  backgroundSize: `${fileStore.loaderValue}% 100%`,
                })
              }

              return Object.assign(progressStyle, {
                backgroundSize: `0% 100%`,
              })
            }

            return null
          })()}
          className={c(styles.input, fileStore.error && styles.error)}
          placeholder={placeholder}
          readOnly
          onClick={() => {
            if (!inputContactsFileStore.canUpload) {
              inputContactsFileStore.setCanNotUpload()
            }
          }}
          value={fileStore.name !== null ? fileStore.name : ''}
        />
        {fileStore.name === null || fileStore.name === '' ? (
          <PlusIcon className={styles.fileIcon} />
        ) : (
          <>
            {fileStore.isUploading === false ? (
              <div className={styles.fileIcons}>
                <CloseIcon
                  size={20}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    if (onBeforeDeletion !== null) {
                      onBeforeDeletion()
                    }

                    fileStore.removeFile()

                    inputContactsFileStore.setEleFileInput('') // Vacie el input file

                    if (onChange !== null) {
                      onChange(null)
                    }

                    inputContactsFileStore.setCanNotUpload()
                  }}
                />
                <DownloadFileLink
                  fileStore={fileStore}
                  localFileUrl={blobUrl}
                  localFileName={fileStore.name}
                  handleRevokeLocalFileUrl={handleRevokeLocalFileUrl}
                />
              </div>
            ) : (
              <div className={styles.fileIcons}>
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    fileStore.cancelUpload()

                    if (onChange !== null) {
                      onChange(null)
                    }

                    inputContactsFileStore.setCanNotUpload()
                  }}
                />
                <div className={styles.loadingRing}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {fileStore && fileStore.error && (
        <div className={c(styles.errorInput, literal && styles.errorLiteralInput)}>
          {t(fileStore.errorMessage)}
        </div>
      )}
      <Modal
        name="inputErrorModal"
        title={
          inputContactsFileStore.errors.length > 0
            ? t(inputContactsFileStore?.errors[0]?.title)
            : ''
        }
        open={inputContactsFileStore.error}
        onSuccess={() => {
          handleSetErrorModal(false)
        }}
        closeOutside
        okOnly
        okMessage={t('common:ok')}
      >
        <div>
          {inputContactsFileStore.errors.length > 0 &&
            (inputContactsFileStore?.errors[0]?.maxMb
              ? t(
                  inputContactsFileStore?.errors[0]?.content,
                  inputContactsFileStore.errors[0].maxMb
                )
              : t(inputContactsFileStore?.errors[0]?.content))}
        </div>
      </Modal>
    </label>
  )
}

InputContactsFile.propTypes = {
  literal: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fileStore: PropTypes.instanceOf(File).isRequired,
  autoUpload: PropTypes.bool,
  onChange: PropTypes.func,
  onBeforeDeletion: PropTypes.func,
}

InputContactsFile.defaultProps = {
  label: '',
  placeholder: '',
  literal: false,
  autoUpload: false,
  onChange: null,
  onBeforeDeletion: null,
}

export default observer(InputContactsFile)
