import { observable, action, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import TrustService from 'services/TrustService'
import FileService from 'services/FileService'
import { API_URL } from 'services/config'

class DownloadZipStore extends AsyncStore {
  id = null
  idTrust = null
  noIdError = false
  trustName = null
  downloadError = false
  loadingDownload = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      id: observable,
      idTrust: observable,
      noIdError: observable,
      trustName: observable,
      downloadError: observable,
      loadingDownload: observable,
      // actions
      setTrustData: action,
      setError: action,
      init: action,
      downloadZip: action,
    })

    this.trustService = new TrustService()
    this.fileService = new FileService()
  }

  setTrustData(id, name) {
    this.idTrust = id
    this.trustName = name
  }

  setError() {
    this.downloadError = true
  }

  init(idTrust, id) {
    if (idTrust) {
      this.preRequest(() => this.init(idTrust, id))
      this.trustService
        .getTrust(idTrust)
        .then((trust) => {
          this.id = id
          this.setTrustData(idTrust, trust.data.trustName)
          this.isLoading = false
          this.onSuccessRequest()
        })
        .catch((e) => {
          this.noIdError = true
          this.isLoading = false
          this.onErrorRequest(e)
        })
    } else {
      this.noIdError = true
    }
  }

  async downloadZip() {
    this.loadingDownload = true
    this.downloadError = false
    await this.fileService
      .getSecureUrl(this.id)
      .then((secureUrl) => {
        const tempIframe = document.createElement('iframe')

        tempIframe.style.display = 'none'
        tempIframe.src = `${API_URL}${secureUrl[0].replace('/api', '')}`

        document.body.appendChild(tempIframe)
        this.loadingDownload = false

        setTimeout(() => {
          document.body.removeChild(tempIframe)
        }, 10000)
      })
      .catch(() => {
        this.loadingDownload = false
        this.downloadError = true
      })
  }
}

export default DownloadZipStore
