import { makeAutoObservable } from 'mobx'

class NavBarStore {
  topMsg = false
  forcedOnlyMainNavBar = false

  constructor() {
    makeAutoObservable(this)
  }

  toggleTopMsg = () => {
    this.topMsg = !this.topMsg
  }

  setForcedOnlyMainNavBar() {
    this.forcedOnlyMainNavBar = true
  }

  clearForcedOnlyMainNavBar() {
    this.forcedOnlyMainNavBar = false
  }
}

export default NavBarStore
