import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class TrustProtectorCategoriesService {
  listCategories = (trustId) =>
    axios
      .get(`${API_URL}/tenant/protector/categories/${trustId}`)
      .then((response) => response.data.data)

  createCategory = (trust, name) =>
    axios
      .post(`${API_URL}/tenant/protector/categories`, { trust, name })
      .then((response) => response.data.data)
}

export default TrustProtectorCategoriesService
