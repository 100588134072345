import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'
import DatePicker from 'presentation/DatePicker'
import ViewVotingFormVoters from 'presentation/ViewVotingFormVoters'
import InputStore from 'stores/InputStore'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import InputFile from 'presentation/InputFile'
import Vote from 'models/Vote'
import Button from 'presentation/Button'
import Modal from 'scenes/Modal'
import styles from './resolveVotingForm.module.scss'

const ResolveVotingForm = ({
  vote,
  setButtons,
  visibleErrorModal,
  messageErrorServer,
  isComplexResolution,
  handleVoteCloseModal,
  votingResultSelected,
  warningVoteInProgress,
  handleChangeVotingResult,
  handleSetComplexResolution,
  handleToggleLoadingDocument,
  handleActionComplexResolution,
  visibleWarningVotingModalInProcess,
  handleSuccessWarningVotingInProsess,
  handleVoteCloseModalWarningVotingModalInProsess,
}) => {
  const { t } = useTranslation('transactionsCreation')

  const votingResult = [
    { id: 'rejected', value: t('rejected') },
    { id: 'approved', value: t('approved') },
  ]

  const handleChangeResolution = useCallback((e) => {
    vote.resolution.setValue(e.target.value)
  }, [])

  const buttons = []

  if (vote) {
    buttons.push(
      <Button
        primary
        label={t('close')}
        title={t('close')}
        style={{ marginRight: '15px' }}
        onClick={handleActionComplexResolution}
      />
    )
    buttons.push(<Button label={t('cancel')} secondary onClick={handleVoteCloseModal} />)
  } else {
    buttons.push(<Button label={t('ok')} title={t('ok')} primary onClick={handleVoteCloseModal} />)
  }

  useEffect(() => {
    setButtons(buttons)
    vote.resolution.setValue('')
  }, [])

  return (
    <>
      <div className={styles.formContainerTwoColumns}>
        <CreationInput
          literal
          label={t('titleLabel')}
          inputStore={vote.title}
          placeholder={t('titlePlaceholder')}
        />
        <CreationInput
          literal
          as={DatePicker}
          label={t('dateLabel')}
          inputStore={vote.created}
          placeholder={t('datePlaceholder')}
        />
      </div>
      <div className={styles.formContainer}>
        <CreationInput
          literal
          inputStore={vote.description}
          label={t('descriptionLabel')}
          placeholder={t('descriptionPlaceholder')}
        />
      </div>
      <div className={styles.formContainer}>
        <YesNoToggleContainer
          viewMode
          desc={t('votersCanAbstain')}
          value={vote.votersCanAbstain.value || false}
        />
      </div>
      <div className={styles.formContainer}>
        <CreationInput
          literal
          inputStore={vote.approvalRule}
          label={t('approvalRuleLabel')}
          placeholder={t('approvalRulePlaceholder')}
        />
      </div>
      <ViewVotingFormVoters vote={vote} />
      <div className={styles.formContainer}>
        <YesNoToggleContainer
          value={isComplexResolution}
          desc={t('complexResolution')}
          handleChange={handleSetComplexResolution}
        />
      </div>
      {!isComplexResolution && (
        <div className={styles.containerComplexResolution}>
          <div className={styles.formContainerTwoColumns}>
            <CreationInput
              as={FormSelect}
              label={t('votingResultLabel')}
              inputStore={votingResultSelected}
              placeholder={t('votingResultPlaceholder')}
              onChange={(option) => handleChangeVotingResult(option)}
              options={votingResult.map((e) => ({
                id: e.id,
                value: e.value,
              }))}
            />
            <CreationInput
              as={InputFile}
              fileStore={vote.document}
              label={t('documentLabel')}
              placeholder={t('documentPlaceholder')}
              handleStartUploadingFile={handleToggleLoadingDocument}
              handleFinishUploadingFile={handleToggleLoadingDocument}
            />
          </div>
          <div className={styles.formContainer}>
            <CreationInput
              inputStore={vote.resolution}
              label={t('resolutionLabel')}
              onChange={handleChangeResolution}
              placeholder={t('resolutionPlaceholder')}
            />
          </div>
        </div>
      )}
      <Modal
        name="warningVoting"
        title={t('warningVoting')}
        open={visibleWarningVotingModalInProcess}
        onSuccess={handleSuccessWarningVotingInProsess}
        onCancel={handleVoteCloseModalWarningVotingModalInProsess}
      >
        {warningVoteInProgress && (
          <div className={styles.errorMessageModal}>{t('votersInProgress')}</div>
        )}
      </Modal>
      <Modal
        okOnly
        name="errorServer"
        open={visibleErrorModal}
        title={t('titleErrorMessage')}
        onSuccess={handleVoteCloseModal}
      >
        <div className={styles.errorMessageModal}>{messageErrorServer}</div>
      </Modal>
    </>
  )
}

ResolveVotingForm.propTypes = {
  setButtons: PropTypes.func,
  visibleErrorModal: PropTypes.bool,
  isComplexResolution: PropTypes.bool,
  messageErrorServer: PropTypes.string,
  warningVoteInProgress: PropTypes.bool,
  handleToggleLoadingDocument: PropTypes.func,
  vote: PropTypes.instanceOf(Vote).isRequired,
  handleVoteCloseModal: PropTypes.func.isRequired,
  visibleWarningVotingModalInProcess: PropTypes.bool,
  handleChangeVotingResult: PropTypes.func.isRequired,
  handleSetComplexResolution: PropTypes.func.isRequired,
  handleActionComplexResolution: PropTypes.func.isRequired,
  handleSuccessWarningVotingInProsess: PropTypes.func.isRequired,
  votingResultSelected: PropTypes.instanceOf(InputStore).isRequired,
  handleVoteCloseModalWarningVotingModalInProsess: PropTypes.func.isRequired,
}

ResolveVotingForm.defaultProps = {
  setButtons: null,
  messageErrorServer: '',
  visibleErrorModal: false,
  isComplexResolution: false,
  warningVoteInProgress: false,
  handleToggleLoadingDocument: null,
  visibleWarningVotingModalInProcess: false,
}

export default observer(ResolveVotingForm)
