import Account from 'models/Account'
import File from 'models/File'

class TransactionActivity {
  constructor(
    activityId,
    id,
    name,
    storedAt,
    sourceAccounts,
    destinationAssets,
    document,
    description,
    transactionDescription,
    created,
    updated,
    status,
    type,
    transactionType,
    isConfirmed,
    ownership
  ) {
    this.id = id
    this.activityId = activityId
    this.name = name
    this.storedAt = storedAt
    this.sourceAccounts = sourceAccounts
    this.destinationAssets = destinationAssets
    this.document = document
    this.description = description
    this.transactionDescription = transactionDescription
    this.created = created
    this.updated = updated
    this.status = status
    this.type = type
    this.transactionType = transactionType
    this.isConfirmed = isConfirmed
    this.ownership = ownership
  }

  static fromJson({
    subjectAt: {
      id,
      additionalData: {
        description: transactionDescription,
        destination = [],
        sourceAccounts = [],
        document,
        ownership,
      },
      name,
      type: transactionType,
      storedAt,
    },
    created,
    updated,
    status,
    description,
    type,
    id: activityId,
    isConfirmed,
  }) {
    return new TransactionActivity(
      activityId,
      id,
      name,
      storedAt,
      sourceAccounts.map((source) => Account.fromJson(source)),
      destination.map((destine) => Account.fromJson(destine)),
      File.fromJson(document),
      description,
      transactionDescription,
      created,
      updated,
      status,
      type,
      transactionType,
      isConfirmed,
      ownership
    )
  }

  get isApproved() {
    return this.status === 'approved'
  }

  get showingName() {
    return this.description
  }

  get showingEntity() {
    return this.storedAt
  }

  get isFundingTransaction() {
    return (
      this.type === 'transactionApproval' &&
      (this.transactionType === 'accountFunding' || this.transactionType === 'expense')
    )
  }

  get isOwnershipTransaction() {
    return this.type === 'transactionApproval' && this.transactionType === 'ownership'
  }
}

export default TransactionActivity
