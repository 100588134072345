import { useCallback, useMemo, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import InputStore from 'stores/InputStore'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import { RuleAsset } from 'models/Rule'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import { useTranslation } from 'react-i18next'
import styles from './governanceSelection.module.scss'

const GovernanceSelection = ({ ruleAsset, governors, isSaved }) => {
  const { t } = useTranslation('trustsCreation')
  const [selectedOptions] = useState(() => new InputStore())

  const handleChangeGovernance = useCallback(
    (options) => {
      const filteredOptions = options.filter((filteredOption, index) => {
        return options.findIndex((option) => option.id === filteredOption.id) === index
      })
      selectedOptions.setValue(filteredOptions)
      options.forEach((option) => ruleAsset.addGovernor(option.instance))
    },
    [ruleAsset]
  )

  const optionsList = useMemo(
    () =>
      governors.map((governor) => ({
        id: governor.contactId,
        value: governor.fullName,
        instance: governor,
      })),
    [governors]
  )

  useEffect(() => {
    if (!isSaved) {
      // If it's a new distribution
      const initialGovernors = optionsList.filter((option) => option.instance.isTrustee)
      initialGovernors.forEach((initialGovernor) => ruleAsset.addGovernor(initialGovernor.instance))
      selectedOptions.setValue(initialGovernors)
    } else if (ruleAsset.governors) {
      // if the distribution isn't new and has governors assigned to it
      const initialGovernors = ruleAsset.governors.map((governor) => {
        return optionsList.filter((option) => option.id === governor.contactId)[0]
      })
      // avoid undefined governor error
      if (initialGovernors[0]) {
        selectedOptions.setValue(
          // only unique values
          initialGovernors.filter((filteredGovernor, index) => {
            return (
              initialGovernors.findIndex((governor) => {
                return governor.id === filteredGovernor.id
              }) === index
            )
          })
        )
      }
    }
  }, [isSaved, ruleAsset, optionsList])

  return (
    <div className={styles.container}>
      <CreationInput
        as={FormSelect}
        inputStore={selectedOptions}
        label={t('selectGovernorLabel')}
        placeholder={t('selectGovernorPlaceholder')}
        options={optionsList}
        onChange={handleChangeGovernance}
        isMulti
      />
    </div>
  )
}

GovernanceSelection.propTypes = {
  ruleAsset: PropTypes.instanceOf(RuleAsset).isRequired,
  governors: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.instanceOf(RuleAssetGovernor), value: PropTypes.string })
  ).isRequired,
  isSaved: PropTypes.bool,
}

GovernanceSelection.defaultProps = {
  isSaved: false,
}

export default observer(GovernanceSelection)
