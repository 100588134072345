import React from 'react'
import PropTypes from 'prop-types'
import MultipleTooltipsBeneficiaries from 'presentation/MultipleTooltipsBeneficiaries'
import Icon from '../Icon'
import styles from './ruleAssetInfo.module.scss'

const RuleAssetInfo = ({ item }) => {
  return (
    <div className={styles.assetContainer}>
      <div className={styles.assetInfo}>
        <span>
          <Icon className={styles.assetIcon} name={item.asset.icon} size={15} />
        </span>
        <span title={item.asset.name.value} className={styles.assetText}>
          {item.asset.name.value}
        </span>
      </div>
      {item.selectedBeneficiaries.length > 0 && (
        <div className={styles.assetBenefs}>
          <span>
            <Icon
              className={styles.assetIcon}
              name={item.selectedBeneficiaries[0].beneficiary.icon}
              size={15}
            />
          </span>
          <MultipleTooltipsBeneficiaries beneficiaries={item.selectedBeneficiaries} />
        </div>
      )}
    </div>
  )
}

RuleAssetInfo.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default RuleAssetInfo
