import axios from 'axios'
import { API_URL } from 'services/config'
import ListContact from 'models/ListContact'
import OptionsContactSearch from 'models/OptionsContactSearch'

/* eslint-disable class-methods-use-this */
class ContactsService {
  listContact = (page, search = null, orderParam = null, orderDir = null) => {
    const params = {
      page,
      [`sort[${orderParam || 'updatedAt'}]`]: orderDir || 'desc',
      isClient: false,
      search: search || '',
    }

    return axios.get(`${API_URL}/tenant/contacts`, { params }).then((response) => ({
      contacts: response.data.data.map((listContact) => ListContact.fromJson(listContact)),
      pagination: response.data.pagination,
    }))
  }

  saveContact = (json) =>
    axios.post(`${API_URL}/tenant/contacts`, json).then((response) => response.data.data)

  deleteContact = (contactId) =>
    axios.delete(`${API_URL}/tenant/contacts/${contactId}`).then((response) => response.data.data)

  loadMetadata = (type, contactId) => {
    const url = contactId
      ? `${API_URL}/metadata/contacts/${contactId}`
      : `${API_URL}/metadata/contacts`
    return axios.get(url).then((response) => response.data.data.content.types[type])
  }

  getContact = (id) =>
    axios
      .get(`${API_URL}/tenant/contacts/${id}?status=current`)
      .then((response) => response.data.data)

  listContactSearch = (search, isClient = '') => {
    const params = { search }

    if (isClient) params.isClient = isClient

    return axios.get(`${API_URL}/tenant/contacts?`, { params }).then((response) => ({
      contacts: response.data.data.map((optionContactSearch) =>
        OptionsContactSearch.fromJson(optionContactSearch)
      ),
    }))
  }

  downloadTemplate = () =>
    axios
      .get(`${API_URL}/tenant/contacts/import/template`, { responseType: 'blob' })
      .then((response) => URL.createObjectURL(response.data))
}

export default ContactsService
