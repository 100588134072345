import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CONTACTS_LIST } from 'routing/routes'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import contactNotExist from './contactNotExist.png'

const ContactsSectionError = () => {
  const { t } = useTranslation('contactsCreation')
  const history = useHistory()
  const handleGoBack = useCallback(() => history.push(CONTACTS_LIST))

  return (
    <GenericNotFoundError
      title={t('contactSectionErrorTitle')}
      description={t('contactSectionErrorMsg')}
      textButton={t('goToContacts')}
      goBackFunction={handleGoBack}
      image={contactNotExist}
    />
  )
}

export default ContactsSectionError
