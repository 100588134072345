import axios from 'axios'
import { API_URL } from 'services/config'
import User from 'models/User'

/* eslint-disable class-methods-use-this */
class UsersService {
  listUsers = (page, search = null, orderParam = null, orderDir = null) =>
    axios
      .get(
        `${API_URL}/users?page=${page}&sort[${orderParam || 'updatedAt'}]=${
          orderDir || 'desc'
        }&search=${search || ''}`
      )
      .then((response) => ({
        users: response.data.data.map((listClient) => User.fromJson(listClient)),
        pagination: response.data.pagination,
      }))

  getUser = (id) =>
    axios.get(`${API_URL}/users/${id}`).then((response) => User.fromJson(response.data.data))

  getRoles = () =>
    axios.get(`${API_URL}/roles?noPagination=true`).then((response) => response.data.data)

  addUser = (json) => axios.post(`${API_URL}/users`, json).then((response) => response.data.data)

  saveUser = (id, json) =>
    axios.put(`${API_URL}/users/${id}`, json).then((response) => response.data.data)

  changeStatus = (id, active) =>
    axios
      .post(`${API_URL}/users/changeStatus`, { id, active })
      .then((response) => response.data.data)

  resendActivation = (email) =>
    axios
      .post(`${API_URL}/tenant/auth/activate/resend`, { email })
      .then((response) => response.data.data)
}

export default UsersService
