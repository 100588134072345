import FundingTransactionStore from './FundingTransactionStore'

class ExpenseTransactionStore extends FundingTransactionStore {
  constructor(id) {
    super(id, 'expense')

    this.id = id
  }
}

export default ExpenseTransactionStore
