class Position {
  constructor(start, end, text) {
    this.start = start
    this.end = end
    this.text = text
  }

  includesChunk(chunk) {
    return this.start < chunk[1] && this.end > chunk[0]
  }

  getJson() {
    return {
      startPosition: this.start,
      endPosition: this.end,
      text: this.text,
    }
  }
}

export default Position
