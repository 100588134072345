import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import LoadingRing from 'presentation/LoadingRing'

const MainCreationLoading = observer(({ store }) => {
  return store.isLoading ? <LoadingRing center /> : null
})

MainCreationLoading.propTypes = {
  store: PropTypes.shape().isRequired,
}

export default MainCreationLoading
