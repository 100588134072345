import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import AlertIcon from 'mdi-react/AlertIcon'
import PropTypes from 'prop-types'
import { RuleAsset } from 'models/Rule'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import c from 'classnames'
import AssetSelection from './AssetSelection'
import GovernanceSelection from './GovernanceSelection'
import BeneficiariesSelection from './BeneficiariesSelection'
import styles from './ruleAssetFormItem.module.scss'

const RuleAssetFormItem = observer(
  ({
    assets,
    ruleAsset,
    beneficiaries,
    governors,
    showDeleteButton,
    handleDeleteAsset,
    options,
    isSaved,
  }) => {
    const { t } = useTranslation('rulesCreation')
    let selectedAssetOption = null
    let selectedAsset = null

    assets.forEach((asset) => {
      const option = {
        id: asset.id,
        value: asset.name.value,
      }

      if (ruleAsset.assetId === asset.id) {
        selectedAssetOption = option
        selectedAsset = asset
        ruleAsset.setValue(parseFloat(asset.value.value))
      }
    })

    const [show, setShow] = useState(false)
    useEffect(() => {
      setShow(true)
    }, [])

    return (
      <div className={c(styles.container, show && styles.show)}>
        <AssetSelection
          selectedAsset={selectedAsset}
          options={options}
          selectedOption={selectedAssetOption}
          onChange={(selection) => ruleAsset.selectAsset(selection.id)}
          showDeleteButton={showDeleteButton}
          error={!!ruleAsset.error}
          handleDeleteAsset={() => {
            setShow(false)
            setTimeout(() => {
              setShow(true)
              handleDeleteAsset(ruleAsset)
            }, 400)
          }}
        />
        <BeneficiariesSelection ruleAsset={ruleAsset} beneficiaries={beneficiaries} />
        {ruleAsset.hasDistributionError && (
          <div className={styles.errorContainer}>
            <div className={styles.error}>
              <span>
                <AlertIcon size={18} />
              </span>
              {t('ruleAssetError')}
            </div>
          </div>
        )}
        <GovernanceSelection ruleAsset={ruleAsset} governors={governors} isSaved={isSaved} />
      </div>
    )
  }
)

RuleAssetFormItem.propTypes = {
  ruleAsset: PropTypes.instanceOf(RuleAsset).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  assets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  beneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  governors: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.instanceOf(RuleAssetGovernor), value: PropTypes.string })
  ).isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  handleDeleteAsset: PropTypes.func.isRequired,
  isSaved: PropTypes.bool,
}

RuleAssetFormItem.defaultProps = {
  isSaved: false,
}

export default RuleAssetFormItem
