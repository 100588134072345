import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InputStore from 'stores/InputStore'
import DestinationAsset from 'models/DestinationAsset'
import TransactionsDestinationAssetsForm from 'presentation/TransactionsDestinationAssetsForm'
import Button from 'presentation/Button'
import CreationInput from 'presentation/CreationInput'
import TextArea from 'presentation/TextArea'
import DatePicker from 'presentation/DatePicker'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'
import styles from '../trustTransactionsAssetForm.module.scss'

const TrustFundingForm = ({
  transactionDate: dateInputStore,
  title: titleInputStore,
  description: descriptionInputStore,
  destinationAssets,
  destinationAssetsOptions,
  hasVoting,
  handleHasVotingChange,
  addDestinationAsset,
  deleteDestinationAsset,
}) => {
  const { t } = useTranslation('transactions')

  const handleAddDestinationAsset = useCallback(() => {
    addDestinationAsset()
  })

  const handleDeleteDestinationAsset = useCallback((index) => {
    deleteDestinationAsset(index)
  }, [])

  const handleDateChange = useCallback((value) => {
    dateInputStore.setValue(value)
  }, [])

  const handleTitleChange = useCallback((e) => {
    titleInputStore.setValue(e.target.value)
  }, [])

  const handleDescriptionChange = useCallback((e) => {
    descriptionInputStore.setValue(e.target.value)
  }, [])

  return (
    <div>
      <div className={styles.destinationForm}>
        <div className={styles.destinationAssetsContainer}>
          {destinationAssets?.map((destination, i) => (
            <TransactionsDestinationAssetsForm
              // eslint-disable-next-line react/no-array-index-key
              key={Math.random() + i}
              amountInputStore={destination.amount}
              assetInputStore={destination.asset}
              assetOptions={destinationAssetsOptions}
              index={i}
              handleDeleteAsset={handleDeleteDestinationAsset}
            />
          ))}
        </div>
        <Button label={t('addAssetDestination')} onClick={handleAddDestinationAsset} />
      </div>
      <div>
        <CreationInput
          label={t('title')}
          placeholder={t('transactionTitle')}
          inputStore={titleInputStore}
          onChange={handleTitleChange}
          wrapperClassName={styles.genericInput}
        />
        <CreationInput
          as={TextArea}
          label={t('description')}
          placeholder={t('description')}
          inputStore={descriptionInputStore}
          onChange={handleDescriptionChange}
          wrapperClassName={styles.genericInput}
        />
        <CreationInput
          as={DatePicker}
          label={t('transactionDate')}
          placeholder={t('transactionDate')}
          maxDate={Date.now()}
          inputStore={dateInputStore}
          onChange={handleDateChange}
          wrapperClassName={styles.genericInput}
        />
        <YesNoToggleContainer
          label={t('withVoting')}
          value={!!hasVoting.value}
          handleChange={handleHasVotingChange}
          desc={t('withVoting')}
        />
      </div>
    </div>
  )
}

TrustFundingForm.propTypes = {
  destinationAssets: PropTypes.arrayOf(PropTypes.instanceOf(DestinationAsset)).isRequired,
  transactionDate: PropTypes.instanceOf(InputStore).isRequired,
  title: PropTypes.instanceOf(InputStore).isRequired,
  description: PropTypes.instanceOf(InputStore).isRequired,
  hasVoting: PropTypes.instanceOf(InputStore).isRequired,
  destinationAssetsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  addDestinationAsset: PropTypes.func,
  deleteDestinationAsset: PropTypes.func,
  handleHasVotingChange: PropTypes.func,
}

TrustFundingForm.defaultProps = {
  addDestinationAsset: () => {},
  deleteDestinationAsset: () => {},
  handleHasVotingChange: () => {},
}

export default observer(TrustFundingForm)
