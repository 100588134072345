import React from 'react'
import PropTypes from 'prop-types'
import { GroupListPart } from 'models/FormMetadata'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'
import DynamicFormPart from '../DynamicFormPart'

const DynamicFormPartGroupList = ({
  part,
  readOnly,
  inputs,
  inputRef,
  modal,
  tab,
  isEntityStored,
}) => {
  const { title, parts } = part

  return (
    <>
      {!tab && title !== undefined && <DynamicFormSubtitle label={title} />}
      {parts.map((subPart, index) => {
        return (
          <DynamicFormPart
            key={`dfp_${subPart.id}`}
            part={subPart}
            inputs={inputs}
            readOnly={readOnly}
            inputRef={index === 0 ? inputRef : undefined}
            modal={modal}
            isEntityStored={isEntityStored}
          />
        )
      })}
    </>
  )
}

DynamicFormPartGroupList.propTypes = {
  part: PropTypes.instanceOf(GroupListPart).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  inputs: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  modal: PropTypes.bool,
  tab: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isEntityStored: PropTypes.bool,
}

DynamicFormPartGroupList.defaultProps = {
  readOnly: true,
  modal: false,
  tab: false,
  inputRef: () => {},
  isEntityStored: false,
}

export default DynamicFormPartGroupList
