class GenericListPerson {
  constructor(id, companyName, firstName, lastName, type) {
    this.id = id
    this.companyName = companyName
    this.firstName = firstName
    this.lastName = lastName
    this.type = type
  }

  get isIndividual() {
    return this.type === 'individual'
  }

  get isCompany() {
    return this.type === 'company'
  }

  get isAssetCorporation() {
    return this.type === 'assetCorporation'
  }

  get isAssetTrust() {
    return this.type === 'assetTrust'
  }

  get name() {
    if (this.isIndividual) {
      if (this.firstName && this.lastName) {
        return `${this.firstName} ${this.lastName}`
      }
      if (!this.firstName && this.lastName) {
        return this.lastName
      }
      if (this.firstName && !this.lastName) {
        return this.firstName
      }
    } else if (this.isCompany || this.isAssetCorporation || this.isAssetTrust) {
      if (this.companyName) {
        return this.companyName
      }
    }

    return '-'
  }
}

export default GenericListPerson
