import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import ListTransactionsComplex from './ListTransactionsComplex'
import styles from './complexResolutionList.module.scss'

const ComplexResolutionList = ({ transactions }) => {
  const { t } = useTranslation('transactionsCreation')
  return (
    <>
      <div className={styles.gridTable}>
        <div className={c(styles.valuesTable, styles.transaction)}>{t('transactions')}</div>
        <div className={styles.valuesTable}>{t('approve')}</div>
        <div className={styles.valuesTable}>{t('reject')}</div>
        <div className={styles.valuesTable}>{t('vote')}</div>
        {transactions.map((transaction) => {
          return (
            <ListTransactionsComplex
              transaction={transaction}
              key={`transaction__${transaction.transaction.id}`}
            />
          )
        })}
      </div>
    </>
  )
}

ComplexResolutionList.propTypes = {
  transactions: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default observer(ComplexResolutionList)
