import React, { useCallback, useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import * as Routes from 'routing/routes'
import Modal from 'scenes/Modal'
import { useHistory } from 'react-router'
import Button from 'presentation/Button'
import AccountIcon from 'mdi-react/AccountIcon'
import ServerError from 'presentation/ServerError'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import StoresContext from 'providers/storeContext'
import MetaTitle from 'util/MetaTitle'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import ClientsTableItem from 'presentation/ClientsTableItem'
import { CREATE_CLIENT } from 'stores/AuthStore'
import ClientsListStore from './ClientsListStore'
import styles from './clientsList.module.scss'

const ClientsList = () => {
  const [clientsListStore] = useState(() => new ClientsListStore())
  const { hotkeyStore } = useContext(StoresContext)
  const [columns, setColumns] = useState([])
  const [buttons, setButtons] = useState([])
  const { t } = useTranslation('clients')
  const history = useHistory()

  const handleCreateClient = useCallback(() => clientsListStore.toggleCreateClientModal(), [])

  const createIndividualClient = useCallback(
    () => history.push(Routes.INDIVIDUAL_CLIENT_CREATION),
    []
  )
  const createCompanyClient = useCallback(() => history.push(Routes.COMPANY_CLIENT_CREATION), [])

  const configureHotKeys = useCallback(() => {
    hotkeyStore.setConfig('clientsList', [
      {
        shortcut: 'alt+c',
        handler: () => {
          handleCreateClient()
        },
      },
    ])
  }, [])

  const setModalButtons = useCallback(() => {
    const buttonArray = Object.assign([], buttons)

    buttonArray.push(
      <div className={styles.modalButtons} key="modalbuttons">
        <Button
          onClick={createIndividualClient}
          icon={<AccountIcon size={20} />}
          label={t('individual')}
          iconPositionLeft
          fullWidth
          big
        />
        <Button
          iconPositionLeft
          icon={<BriefcaseIcon size={20} />}
          onClick={createCompanyClient}
          fullWidth
          big
          label={t('company')}
        />
      </div>
    )

    setButtons(buttonArray)
  }, [])

  useEffect(() => {
    configureHotKeys()

    setColumns([
      {
        name: 'clientName',
        order: true,
        inverted: true,
        transName: t('name'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'Structures',
        order: false,
        inverted: false,
        transName: t('common:structures'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'status',
        order: false,
        inverted: false,
        transName: t('common:status'),
        cssGridDefinition: '130px',
      },
    ])

    clientsListStore.loadClients()
    setModalButtons()

    return () => hotkeyStore.removeConfig('clientsList')
  }, [])

  const handlePageChange = useCallback((pageTo) => clientsListStore.changeActivePage(pageTo), [])

  const handleCloseCreateClientModal = useCallback(
    () => clientsListStore.toggleCreateClientModal(),
    []
  )

  const handleSearch = useCallback(
    (value) => clientsListStore.loadClients(value, null, null, true),
    []
  )
  const handleChangeOrder = useCallback(
    (orderParamter, orderDirection) =>
      clientsListStore.loadClients(
        null,
        orderParamter.includes('client')
          ? orderParamter.replace('client', '').toLowerCase()
          : orderParamter,
        orderDirection,
        true
      ),
    []
  )

  const handleSetShowDropdownOrder = useCallback((value) => {
    clientsListStore.setShowDropdownOrder(value)
  }, [])

  const { visibleClients, visibleCreateClientModal } = clientsListStore

  const handleTryAgain = useCallback(() => clientsListStore.tryAgain(), [])

  if (clientsListStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  return (
    <>
      <MetaTitle pageTitle={t('pageTitles:clients')} />
      <MainSectionList
        setShowDropdownOrder={handleSetShowDropdownOrder}
        currentPage={clientsListStore.activePage}
        isLoading={clientsListStore.isLoading}
        handlePageChange={handlePageChange}
        totalPages={clientsListStore.pages}
        handleCreation={handleCreateClient}
        creationPermission={CREATE_CLIENT}
        sectionNameSingular={t('client')}
        sectionNamePlural={t('clients')}
        sectionItems={visibleClients}
        handleSearch={handleSearch}
        tableComponent={
          <MainSectionTable
            t={t}
            showDropdownOrder={clientsListStore.showDropdownOrder}
            setShowDropdownOrder={handleSetShowDropdownOrder}
            isLoading={clientsListStore.isLoading}
            onOrdered={handleChangeOrder}
            listItems={visibleClients}
            columns={columns}
            view="list"
            component={({ listItem, view: cView }) => (
              <ClientsTableItem
                view={cView}
                listItem={listItem}
                link={`${
                  listItem.isIndividual
                    ? Routes.INDIVIDUAL_CLIENT_CREATION
                    : Routes.COMPANY_CLIENT_CREATION
                }?id=${listItem.id}&v=true`}
              />
            )}
          />
        }
      />
      <Modal
        onCancel={handleCloseCreateClientModal}
        open={visibleCreateClientModal}
        customButtons={buttons}
        name="createClientModal"
        title={t('addNew')}
        close
      >
        <span>{t('selectTypeClient')}</span>
      </Modal>
    </>
  )
}

export default observer(ClientsList)
