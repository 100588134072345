// Using window.location for reload the entire metadata & data.

const corporationViewAlternativeStatus = () => {
  window.location.replace(`${window.location.href}&&a=true`)
}

const corporationViewCurrentStatus = (corporationsCreationStore) => {
  window.location.replace(
    `${window.location.pathname}?id=${corporationsCreationStore.idEdition}&&v=true`
  )
}

export { corporationViewAlternativeStatus, corporationViewCurrentStatus }
