// eslint-disable-next-line import/no-mutable-exports
let API_URL = '/api'
// eslint-disable-next-line import/no-mutable-exports
let GA_TRACKING_ID = null

if (process.env.NODE_ENV === 'development') {
  // API_URL = 'http://localhost/api
  // API_URL = 'https://smart-trust.uat.com/api'
  API_URL = 'https://smart-trust.digbang.com/api'
  // API_URL = 'https://smart-trust-dev.digbang.com/api'
  // API_URL = 'http://polls.apiblueprint.org/api/'
  // API_URL = 'http://glaita.digbang.com/api'
  // API_URL = 'https://private-9235d-smarttrust.apiary-mock.com/api'
  // API_URL = 'https://api.smart-trust.digbang.com/api'
}

if (process.env.NODE_ENV === 'production') {
  GA_TRACKING_ID = 'UA-141895577-3'
}

const DEFAULT_CURRENCY = 'USD'

const STILL_ALIVE_MODAL_DIFF_TIME = 180000
const STILL_ALIVE_LOGOUT_DIFF_TIME = 30000

export {
  API_URL,
  DEFAULT_CURRENCY,
  STILL_ALIVE_MODAL_DIFF_TIME,
  STILL_ALIVE_LOGOUT_DIFF_TIME,
  GA_TRACKING_ID,
}
