import React, { useEffect, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PlusIcon from 'mdi-react/PlusIcon'
import OwnershipTransactionStore from 'scenes/TransactionsAssets/OwnershipTransactionStore'
import AccountFundingTransactionStore from 'scenes/TransactionsAssets/AccountFundingTransactionStore'
import ExpenseTransactionStore from 'scenes/TransactionsAssets/ExpenseTransactionStore'
import Button from 'presentation/Button'
import { Tab, TabsWizard } from 'presentation/Tabs'
import InputFile from 'presentation/InputFile'
import CreationInput from 'presentation/CreationInput'
import TransactionVotingForm from 'presentation/TransactionVotingForm'
import TrustFundingForm from './TrustFundingForm'
import styles from './trustTransactionsAssetForm.module.scss'
import TrustOwnershipForm from './TrustOwnershipForm'

const TrustTransactionsAssetForm = ({ store, onCancelForm, onSaveForm, setButtons }) => {
  const { t } = useTranslation('transactions')

  const handleModalCancel = useCallback(() => {
    onCancelForm()
  }, [])

  const handleSave = useCallback(() => {
    onSaveForm()
  }, [])

  const handleSetVoters = useCallback((voters) => {
    store.setVoters(voters)
  }, [])

  const handleHasVotingChange = useCallback(() => {
    store.setHasVoting()
  }, [])

  useEffect(() => {
    setButtons([
      <Button label={t('save')} primary className={styles.primaryButton} onClick={handleSave} />,
      <Button label={t('cancel')} secondary onClick={handleModalCancel} />,
    ])
  }, [])

  const CurrentMainTab = useMemo(() => {
    if (store instanceof OwnershipTransactionStore) return TrustOwnershipForm
    if (store instanceof AccountFundingTransactionStore) return TrustFundingForm
    if (store instanceof ExpenseTransactionStore) return TrustFundingForm
    return () => 'No content.'
  }, [store])

  return (
    <>
      <TabsWizard hideButtons>
        <Tab name={t('destination')}>
          <CurrentMainTab {...store} handleHasVotingChange={handleHasVotingChange} />
        </Tab>
        <Tab name={t('attachments')}>
          <CreationInput
            as={InputFile}
            label={t('attachment')}
            placeholder={t('attachment')}
            fileStore={store.file}
          />
        </Tab>

        <Tab name={t('voting')}>
          {!store.hasVoting.value ? (
            <div className={styles.noVotingContainer}>
              <div className={styles.noVotingLegend}>No voting added</div>
              <Button icon={<PlusIcon />} label={t('addVoting')} onClick={handleHasVotingChange} />
            </div>
          ) : (
            <TransactionVotingForm
              votersOptions={store.votersOptions}
              voters={store.voters}
              setVoters={handleSetVoters}
            />
          )}
        </Tab>
      </TabsWizard>
    </>
  )
}

TrustTransactionsAssetForm.propTypes = {
  store: PropTypes.oneOfType([
    PropTypes.instanceOf(OwnershipTransactionStore),
    PropTypes.instanceOf(AccountFundingTransactionStore),
    PropTypes.instanceOf(ExpenseTransactionStore),
  ]),
  onCancelForm: PropTypes.func,
  setButtons: PropTypes.func,
  onSaveForm: PropTypes.func,
}

TrustTransactionsAssetForm.defaultProps = {
  store: null,
  onCancelForm: () => {},
  setButtons: () => {},
  onSaveForm: () => {},
}

export default observer(TrustTransactionsAssetForm)
