import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PercentagePart } from 'models/FormMetadata/Parts'
import FormPercentage from './FormPercentage'

const DynamicFormPartPercentage = ({ part, inputs, readOnly, inputRef }) => {
  const [inputStore] = useState(inputs.get(part.id))

  return (
    <FormPercentage
      inputStore={inputStore}
      label={part.label}
      literal={readOnly}
      inputRef={inputRef}
    />
  )
}

DynamicFormPartPercentage.propTypes = {
  part: PropTypes.instanceOf(PercentagePart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  readOnly: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

DynamicFormPartPercentage.defaultProps = {
  readOnly: true,
  inputRef: () => {},
}

export default DynamicFormPartPercentage
