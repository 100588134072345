import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import CloudDownloadOutlineIcon from 'mdi-react/CloudDownloadOutlineIcon'
import { API_URL } from 'services/config'
import File from 'models/File'
import styles from './inputFile.module.scss'

const DownloadFileLink = ({ fileStore, circle, className, noUploadFile }) => {
  const [loading, setLoading] = useState(false)

  return (
    <div
      role="link"
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault()

        if (!noUploadFile) {
          setLoading(true)

          fileStore.getSecureUrl().then((secureUrl) => {
            // busco una url segura y luego creo un iframe durante una ventana de tiempo para que el browser promptee la descarga
            const tempIframe = document.createElement('iframe')

            tempIframe.style.display = 'none'
            tempIframe.src = `${API_URL}${secureUrl[0].replace('/api', '')}`

            document.body.appendChild(tempIframe)
            setLoading(false)

            setTimeout(() => document.body.removeChild(tempIframe), 10000)
          })
        }
      }}
      target="_blank"
      className={c(
        styles.downloadFileBtn,
        circle && styles.circle,
        className,
        noUploadFile && styles.noUploadFile
      )}
    >
      {loading ? (
        <div className={styles.loadingRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
      ) : (
        <CloudDownloadOutlineIcon />
      )}
    </div>
  )
}

DownloadFileLink.propTypes = {
  fileStore: PropTypes.instanceOf(File).isRequired,
  circle: PropTypes.bool,
  className: PropTypes.string,
  noUploadFile: PropTypes.bool,
}

DownloadFileLink.defaultProps = {
  circle: false,
  className: undefined,
  noUploadFile: false,
}

export default DownloadFileLink
