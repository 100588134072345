import axios from 'axios'
import { API_URL } from 'services/config'
import ListCorporation from 'models/ListCorporation'

const { CancelToken } = axios

/* eslint-disable class-methods-use-this */
class CorporationService {
  listCorporations = (
    page,
    search = null,
    status,
    orderParam = null,
    orderDir = null,
    alternativeStatus = false
  ) =>
    axios
      .get(
        `${API_URL}/tenant/financialStructures?page=${page}&sort[${orderParam || 'updatedAt'}]=${
          orderDir || 'desc'
        }&status=${status}&hasAlternativeStatus=${alternativeStatus}&search=${search || ''}`
      )
      .then((response) => ({
        corporations: response.data.data.map((listCorporation) =>
          ListCorporation.fromJson(listCorporation)
        ),
        pagination: response.data.pagination,
      }))

  saveCorporation = (json, id) => {
    if (id) {
      return axios
        .put(`${API_URL}/tenant/financialStructures`, json)
        .then((response) => response.data.data)
    }

    return axios
      .post(`${API_URL}/tenant/financialStructures`, json)
      .then((response) => response.data.data)
  }

  validateCorporation = (id) =>
    axios
      .post(`${API_URL}/tenant/financialStructures/validate`, { id })
      .then((response) => response)

  changeNextStatus = (id, jsonParams = {}) =>
    axios
      .post(`${API_URL}/tenant/financialStructures/status/forward`, { id, ...jsonParams })
      .then((response) => response)

  changeBackStatus = (id) =>
    axios
      .post(`${API_URL}/tenant/financialStructures/status/rollback`, { id })
      .then((response) => response)

  loadMetadata = (corporationId, dataId) => {
    const url = corporationId
      ? `${API_URL}/metadata/financialStructures/${corporationId}/${dataId}`
      : `${API_URL}/metadata/financialStructures`
    return axios.get(url).then((response) => response.data.data.content.types.financialStructure)
  }

  updateMetadata = (corporationId) =>
    axios
      .put(`${API_URL}/metadata/fiduciary-structures/${corporationId}`)
      .then((response) => response.data.data.content.types.financialStructure)

  getCorporation = (id, alternativeStatus = false) =>
    axios
      .get(
        `${API_URL}/tenant/financialStructures/data/${id}?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)

  getStatutesSection = (alternativeStatus, id) =>
    axios
      .get(
        `${API_URL}/tenant/financialStructures/statute/${id}?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)

  listAssetCorporation = (term) => {
    if (this.listAssetCorporationCall) {
      this.listAssetCorporationCall.cancel('Only one request allowed at a time.')
    }

    this.listAssetCorporationCall = CancelToken.source()

    return axios
      .get(`${API_URL}/tenant/financialStructures/assets?search=${term}`, {
        cancelToken: this.listAssetCorporationCall.token,
      })
      .then((response) => response.data.data)
  }
}

export default CorporationService
