import Part, { TYPE_INPUT_LIST } from './Part'
import PartStrategy from '../PartStrategy'

class InputListPart extends Part {
  parts = []

  constructor(id, title) {
    super(id, TYPE_INPUT_LIST)

    this.title = title
  }

  addPart(part) {
    this.parts.push(part)
  }

  static fromJson({ id, display = {}, parts, title: fullTitle }, onCreateInput) {
    const { title } = display
    const inputList = new InputListPart(id, title || fullTitle)

    parts.forEach((partJson) => {
      const part = PartStrategy.getPart(partJson, onCreateInput)

      if (part) {
        inputList.addPart(part)
      }
    })

    return inputList
  }
}

export default InputListPart
