import { action, observable, makeObservable } from 'mobx'

class InputFileStore {
  canUpload = false
  eleFileInput = null

  constructor() {
    makeObservable(this, {
      // observables
      canUpload: observable,
      eleFileInput: observable,
      // actions
      setCanUpload: action,
      setCanNotUpload: action,
    })
  }

  setCanUpload() {
    this.canUpload = true
  }

  setCanNotUpload() {
    this.canUpload = false
  }
}

export default InputFileStore
