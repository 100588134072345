import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import StoresContext from 'providers/storeContext'
import { trustViewAlternativeStatus, trustViewCurrentStatus } from 'util/trustViewStatus'
import SwitchStatusMessage from 'presentation/SwitchStatusMessage'

const TrustAlternativeStatusMessage = () => {
  const { trustsCreationStore } = useContext(StoresContext)
  const { t } = useTranslation('trustsCreation')
  const history = useHistory()

  if (trustsCreationStore.trustAlternativeStatus !== null) {
    if (!trustsCreationStore.trustAlternativeStatus.isStored) {
      return (
        <SwitchStatusMessage
          handleClick={() => trustViewAlternativeStatus(trustsCreationStore, history)}
          message={t('viewTrustInProgress')}
        />
      )
    }

    if (
      trustsCreationStore.trustAlternativeStatus.isStored &&
      !trustsCreationStore.entityStatus.isStored
    ) {
      return (
        <SwitchStatusMessage
          handleClick={() => trustViewCurrentStatus(trustsCreationStore, history)}
          message={t('viewTrustStored')}
        />
      )
    }
  }

  return null
}

export default observer(TrustAlternativeStatusMessage)
