import { useState, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import { successToast } from 'helpers/Toast'
import DocumentForSignRow from 'presentation/DocumentForSignRow'
import NoResultsList from 'presentation/NoResultsList'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'
import SignedDocumentsStore from '../SignedDocumentsStore'

const SignedDocumentsEdit = () => {
  const { t } = useTranslation('signedDocuments')
  const [signedDocumentsStore] = useState(() => new SignedDocumentsStore())
  const { corporationsCreationStore } = useContext(StoresContext)
  const { statutesStore } = corporationsCreationStore

  const getSignedErrors = (section) => {
    return section.signedFile.hasErrors ? 1 : 0
  }

  const finishUpload = () => {
    corporationsCreationStore.validateExecuted()

    successToast(t('successUpload'), null, 'successUpload').show()
  }

  const handleFinishUploadingStatute = () => {
    const statute = statutesStore.draftMainLetter

    signedDocumentsStore
      .uploadStatute(statute.signedFile.id, corporationsCreationStore.idEdition, statute.id)
      .then(finishUpload)
  }

  const handleDeleteStatute = () => {
    const statute = statutesStore.draftMainLetter

    signedDocumentsStore
      .deleteStatute(statute.signedFile.id, corporationsCreationStore.idEdition, statute.id)
      .then(() => corporationsCreationStore.validateExecuted())
  }

  const statute = statutesStore.draftMainLetter

  return (
    <>
      {statute ? (
        <>
          <DynamicFormSubtitle label={t('subtitleEdit')} />
          {statute && (
            <DocumentForSignRow
              file={statute.file}
              signedFile={statute.signedFile}
              errors={getSignedErrors(statute)}
              section={t('statute')}
              documentName={statute.file.name}
              sectionTitle={statute.title.value}
              handleFinishUploadingFile={handleFinishUploadingStatute}
              handleBeforeDeletion={handleDeleteStatute}
            />
          )}
        </>
      ) : (
        <NoResultsList showIcon={false} topText={t('noNewDocumentsToSign')} />
      )}
    </>
  )
}

export default observer(SignedDocumentsEdit)
