import { parseValidationRules } from 'util/validation'
import Part, { TYPE_INPUT_DATE } from './Part'
import DateFormPartStore from './DateFormPartStore'

class DatePart extends Part {
  constructor(id, label, readOnlyInEdition) {
    super(id, TYPE_INPUT_DATE)

    this.label = label
    this.setReadOnlyInEdition(readOnlyInEdition)
  }

  static fromJson({ id, display, validationRules = null }, onCreateInput) {
    const { label = '', readOnlyInEdition = true } = display
    const input = new DateFormPartStore()

    if (validationRules) {
      input.setValidationRules(parseValidationRules(validationRules))
    }

    onCreateInput(id, input)

    return new DatePart(id, label, readOnlyInEdition)
  }
}

export default DatePart
