import React from 'react'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import AccountIcon from 'mdi-react/AccountIcon'
import AccountGroupIcon from 'mdi-react/AccountGroupIcon'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import PropTypes from 'prop-types'
import ToolTip from 'presentation/ToolTip'
import ListTrust from 'models/ListTrust'
import styles from './trustTableItemSettlorName.module.scss'

const LIST_MAX_SETTLORS_SHOWN = 4
const GRID_MAX_SETTLORS_SHOWN = 3
const TOOLTIP_MAX_SETTLORS_SHOWN = 10
const NAME_MAX_CHARACTERS = 35

const parseName = (name) => {
  return name.length > NAME_MAX_CHARACTERS ? `${name.substring(0, NAME_MAX_CHARACTERS)}...` : name
}

const MultipleSettlorsTooltip = ({ settlors }) => {
  const { t } = useTranslation('trusts')

  const maxSettlorsDisplay = TOOLTIP_MAX_SETTLORS_SHOWN
  const chosenSettlors = settlors
    .slice(0, maxSettlorsDisplay)
    .map((settlor) => parseName(settlor.name))

  return (
    <>
      {chosenSettlors.map((settlor, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${i}_settlor`}>{settlor}</div>
      ))}
      {settlors.length > maxSettlorsDisplay && (
        <div>
          {t('andXMore', {
            amount: settlors.length - maxSettlorsDisplay,
          })}
        </div>
      )}
    </>
  )
}

const MultipleSettlorsNames = ({ settlors, view, isGrantor }) => {
  const { t } = useTranslation('trusts')

  if (view === 'detail') {
    /*
     * Si la lista es tipo detalle, se muestra la cantidad de settlors en números y un tooltip donde se muestran los nombres
     * */

    return (
      <>
        <ToolTip tooltipContent={<MultipleSettlorsTooltip settlors={settlors} />}>
          <span className={c(styles.dottedUnderline, styles.noTextTransform)}>
            {t('xSettlors', {
              amount: settlors.length,
              what: isGrantor ? t('grantor') : t('settlor'),
            })}
          </span>
        </ToolTip>
      </>
    )
  }
  const maxSettlorsDisplay = view === 'list' ? LIST_MAX_SETTLORS_SHOWN : GRID_MAX_SETTLORS_SHOWN
  const chosenSettlors = settlors
    .slice(0, maxSettlorsDisplay)
    .map((settlor) => parseName(settlor.name))
  const remainingSettlors = settlors.slice(maxSettlorsDisplay)
  const chosenNames = chosenSettlors.join(', ')

  if (settlors.length > maxSettlorsDisplay) {
    return (
      <>
        {chosenNames}
        &nbsp;
        <ToolTip
          className={c(styles.andXMore)}
          tooltipContent={<MultipleSettlorsTooltip settlors={remainingSettlors} />}
        >
          <span className={c(styles.dottedUnderline, styles.noTextTransform)}>
            {t('andXMore', {
              amount: settlors.length - maxSettlorsDisplay,
            })}
          </span>
        </ToolTip>
      </>
    )
  }

  return chosenNames
}

const TrustTableItemSettlorName = ({ listItem, view, isGrantor }) => {
  const { t } = useTranslation('trusts')
  if (listItem.isIndividualSettlor) {
    return (
      <>
        <AccountIcon size={18} className={styles.iconSettlorType} />
        <span className={styles.settlorsName}>{listItem.settlors[0].name}</span>
      </>
    )
  }

  if (listItem.isCompanySettlor) {
    return (
      <>
        <BriefcaseIcon size={18} className={styles.iconSettlorType} />
        <span>{listItem.settlors[0].name}</span>
      </>
    )
  }

  if (listItem.isMultipleSettlors) {
    return (
      <>
        <AccountGroupIcon size={18} className={styles.iconSettlorType} />
        <span>
          <MultipleSettlorsNames settlors={listItem.settlors} view={view} isGrantor={isGrantor} />
        </span>
      </>
    )
  }

  return (
    <i>
      {t('noSettlor', {
        what: isGrantor ? t('grantor') : t('settlor'),
      })}
    </i>
  )
}

MultipleSettlorsNames.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  settlors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  view: PropTypes.string.isRequired,
  isGrantor: PropTypes.bool,
}

MultipleSettlorsNames.defaultProps = {
  isGrantor: false,
}

MultipleSettlorsTooltip.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  settlors: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

TrustTableItemSettlorName.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  settlors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  listItem: PropTypes.instanceOf(ListTrust).isRequired,
  view: PropTypes.string.isRequired,
  isGrantor: PropTypes.bool,
}

TrustTableItemSettlorName.defaultProps = {
  isGrantor: false,
}

export default TrustTableItemSettlorName
