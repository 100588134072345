import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import {
  corporationViewAlternativeStatus,
  corporationViewCurrentStatus,
} from 'util/corporationViewStatus'
import SwitchStatusMessage from 'presentation/SwitchStatusMessage'
import { useHistory } from 'react-router'

const CorporationAlternativeStatusMessage = () => {
  const { t } = useTranslation('corporationsCreation')
  const { corporationsCreationStore } = useContext(StoresContext)
  const history = useHistory()

  if (corporationsCreationStore.corporationAlternativeStatus !== null) {
    if (!corporationsCreationStore.corporationAlternativeStatus.isStored) {
      return (
        <SwitchStatusMessage
          handleClick={() => corporationViewAlternativeStatus(corporationsCreationStore, history)}
          message={t('viewCorporationInProgress')}
        />
      )
    }

    if (
      corporationsCreationStore.corporationAlternativeStatus.isStored &&
      !corporationsCreationStore.entityStatus.isStored
    ) {
      return (
        <SwitchStatusMessage
          handleClick={() => corporationViewCurrentStatus(corporationsCreationStore, history)}
          message={t('viewCorporationStored')}
        />
      )
    }
  }

  return null
}

export default observer(CorporationAlternativeStatusMessage)
