import React, { useRef, useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Tab, TabsWizard } from 'presentation/Tabs'
import Vote from 'models/Vote'
import Modal from 'scenes/Modal'
import Button from 'presentation/Button'
import FiduciaryStructureVotesStore from '../FiduciaryStructureVotesStore'
import MultipleSelectTransactionsForm from '../MultipleSelectPollsForm'
import GeneralInformationForm from '../GeneralInformationForm'
import VotersInformationForm from '../VotersInformationForm'
import styles from '../fiduciaryStructurePolls.module.scss'

const ViewVoteForm = ({
  setButtons,
  handleCancel,
  handleSaveVote,
  fiduciaryStructureVotesStore,
  defaultSelectedTransactions,
}) => {
  const [visibleViewTransactionModal, setVisibleViewTransactionModal] = useState(false)
  const [viewTransaction, setViewTransaction] = useState([])
  const { t } = useTranslation('transactionsCreation')

  const tabWizardRef = useRef(null)

  const {
    vote,
    checksum,
    activeTab,
    isEdition,
    possibleVoters,
    votersSelected,
    blockChainAddress,
    transactionErrors,
    visibleErrorModal,
    messageErrorServer,
    transactionsOptions,
    votersInformationErrors,
    generalInformationErrors,
  } = fiduciaryStructureVotesStore

  const handleChangeTransaction = useCallback((option, input) => {
    input.setValue(option || '')
    fiduciaryStructureVotesStore.setVoteTransactions()
  }, [])

  const handleCreateSelect = () => {
    fiduciaryStructureVotesStore.createNewInputSelect()
  }

  const handleRemoveSelect = (input) => {
    fiduciaryStructureVotesStore.removeInputSelect(input)
  }

  const showTransactionViewModal = useCallback((transaction) => {
    setViewTransaction(transaction)
    setVisibleViewTransactionModal(true)
  }, [])

  const hideTransactionViewModal = useCallback(() => {
    setVisibleViewTransactionModal(false)
    fiduciaryStructureVotesStore.setTab(2)
  }, [])

  const nextStep = useCallback(() => {
    fiduciaryStructureVotesStore.setActiveTab(fiduciaryStructureVotesStore.activeTab)
  }, [])

  const handleChangeVoter = useCallback((options) => {
    fiduciaryStructureVotesStore.setVoters(options)
  }, [])

  const handleChangeApprovalRule = useCallback((e) => {
    fiduciaryStructureVotesStore.vote.approvalRule.setValue(e.target.value)
  }, [])

  const handleChangeVotersCanAbstain = useCallback(() => {
    fiduciaryStructureVotesStore.setVotersCanAbstain()
    vote.votersCanAbstain.setValue(fiduciaryStructureVotesStore.votersCanAbstain)
  }, [])

  const handleOnChangeTab = useCallback(
    (active, isLast) => {
      const buttons = []

      if (active === 1) {
        fiduciaryStructureVotesStore.setTab(1)
      }

      if (active < 2) {
        buttons.push(<Button label={t('next')} title={t('next')} primary onClick={nextStep} />)
      }

      if (isLast && !fiduciaryStructureVotesStore.isEdition) {
        fiduciaryStructureVotesStore.setTab(0)

        buttons.push(
          <Button label={t('save')} title={t('save')} primary onClick={handleSaveVote} />
        )
      }

      buttons.push(
        <Button
          label={t('cancel')}
          style={{ marginLeft: '15px' }}
          secondary
          onClick={handleCancel}
        />
      )

      setButtons(buttons)
    },
    [fiduciaryStructureVotesStore.isEdition]
  )

  useEffect(() => {
    fiduciaryStructureVotesStore.setIsEditionVote()

    if (defaultSelectedTransactions.length > 0) {
      fiduciaryStructureVotesStore.setDefaultSelectedTransactions(defaultSelectedTransactions)
    }
  }, [])

  return (
    <>
      <TabsWizard
        hideButtons
        ref={tabWizardRef}
        onChangeTab={handleOnChangeTab}
        defaultActiveTab={activeTab}
      >
        <Tab name={t('tabGeneralInformation')} errors={generalInformationErrors}>
          <GeneralInformationForm
            vote={vote}
            checksum={checksum}
            isEdition={isEdition}
            blockChainAddress={blockChainAddress}
          />
        </Tab>
        <Tab name={t('tabVotersInformation')} errors={votersInformationErrors}>
          <VotersInformationForm
            vote={vote}
            isEdition={isEdition}
            votersSelected={votersSelected}
            possibleVoters={possibleVoters}
            handleChangeVoter={handleChangeVoter}
            handleChangeApprovalRule={handleChangeApprovalRule}
            handleChangeVotersCanAbstain={handleChangeVotersCanAbstain}
          />
        </Tab>
        <Tab name={t('polls')} errors={transactionErrors}>
          <MultipleSelectTransactionsForm
            vote={vote}
            isEdition={!isEdition}
            viewTransaction={viewTransaction}
            isLoading={fiduciaryStructureVotesStore.isLoading}
            handleCreateSelect={handleCreateSelect}
            handleRemoveSelect={handleRemoveSelect}
            transactionsOptions={transactionsOptions}
            inputsSelect={fiduciaryStructureVotesStore.inputsSelect}
            handleChangeTransaction={handleChangeTransaction}
            showTransactionViewModal={showTransactionViewModal}
            hideTransactionViewModal={hideTransactionViewModal}
            visibleViewTransactionModal={visibleViewTransactionModal}
          />
        </Tab>
      </TabsWizard>
      <Modal
        okOnly
        name="errorServer"
        onSuccess={handleCancel}
        open={visibleErrorModal}
        title={t('titleErrorMessage')}
      >
        <div className={styles.errorMessageModal}>{messageErrorServer}</div>
      </Modal>
    </>
  )
}

ViewVoteForm.propTypes = {
  isEdition: PropTypes.bool,
  setButtons: PropTypes.func,
  vote: PropTypes.instanceOf(Vote),
  handleCancel: PropTypes.func.isRequired,
  handleSaveVote: PropTypes.func.isRequired,
  fiduciaryStructureVotesStore: PropTypes.instanceOf(FiduciaryStructureVotesStore).isRequired,
  defaultSelectedTransactions: PropTypes.oneOfType([PropTypes.array]),
}

ViewVoteForm.defaultProps = {
  vote: null,
  setButtons: null,
  isEdition: false,
  defaultSelectedTransactions: [],
}

export default observer(ViewVoteForm)
