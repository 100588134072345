import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ListClient from 'models/ListClient'
import ClientStatusName from 'presentation/ClientStatusName'
import AccountIcon from 'mdi-react/AccountIcon'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import UpdateIcon from 'mdi-react/UpdateIcon'
import TableItemDate from 'presentation/TableItemDate'
import TableItemName from 'presentation/TableItemName'
import ClientTableItemStructureName from './ClientTableStructureName'
import styles from './clientsTableItem.module.scss'

const ClientsTableItem = ({ listItem, link, view }) => {
  const { t } = useTranslation('common')

  return (
    <NavLink
      className={[styles.clientTableItem, styles[`clientTableItem--${view}`]].join(' ')}
      to={link}
    >
      <TableItemName
        name={listItem.name}
        type={listItem.isIndividual ? t('clients:individual') : t('clients:company')}
        icon={listItem.isIndividual ? <AccountIcon size={16} /> : <BriefcaseIcon size={16} />}
        classNames={styles.clientTableItemName}
        iconClassNames={styles.typeIcon}
      />
      <div className={styles.clientTableItemStructureName}>
        <ClientTableItemStructureName
          listItem={listItem}
          structures={listItem.structures}
          view={view}
        />
      </div>
      <div className={styles.clientTableItemCreatedAt}>
        <TableItemDate listDate={listItem.created} view={view} preText={t('createdAt')} />
      </div>
      <div className={styles.clientTableItemUpdatedAt}>
        <TableItemDate
          listDate={listItem.dataUpdated}
          view={view}
          icon={<UpdateIcon size={12} />}
        />
      </div>
      <div className={styles.clientTableItemStatus}>
        <ClientStatusName status={listItem.status} alternativeStatus={listItem.alternativeStatus} />
      </div>
    </NavLink>
  )
}

ClientsTableItem.propTypes = {
  listItem: PropTypes.instanceOf(ListClient).isRequired,
  link: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
}

export default ClientsTableItem
