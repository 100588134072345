import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Route } from 'react-router-dom'
import { rootStore } from 'App'
import { changeClientStatusInactive, changeClientStatusActive } from 'util/changeClientStatus'
import saveClient from 'util/saveClient'
import {
  INDIVIDUAL_CLIENT_FINANCIAL_STRUCTURE,
  INDIVIDUAL_CLIENT_CREATION,
  COMPANY_CLIENT_CREATION,
  CLIENTS_LIST,
} from 'routing/routes'
import { EDIT_CLIENT, CREATE_CLIENT } from 'stores/AuthStore'
import { INDIVIDUAL_CLIENT_TYPE } from 'stores/ClientsCreationStore'
import MainCreationContainer from 'presentation/MainCreationContainer'
import ClientCreationActionButtons from 'presentation/ClientCreationActionButtons'
import ServerError from 'presentation/ServerError'
import MainCreationLoading from 'presentation/MainCreationLoading'
import ClientsSectionError from 'presentation/ClientsSectionError'
import ClientCreationTitle from 'presentation/ClientCreationTitle'
import StoresContext from 'providers/storeContext'
import Modal from 'scenes/Modal'
import DynamicFormRoutes from 'scenes/DynamicForm'
import FinancialStructure from 'scenes/ClientsCreation/FinancialStructure'
import ClientErrorsModalContent from './ClientErrorsModalContent'

/* eslint-disable class-methods-use-this */
class ClientsCreation extends React.Component {
  constructor(props, context) {
    super(props, context)

    const { clientsCreationStore } = this.context

    rootStore.setCurrentDynamicStore(clientsCreationStore)

    clientsCreationStore.prepare()
    this.startComponent()
  }

  componentDidMount() {
    this.configureKeyStore()
  }

  componentWillUnmount() {
    const { hotkeyStore } = this.context

    hotkeyStore.removeConfig('clientCreation')

    rootStore.resetClientsCreation()
  }

  get creationUrl() {
    const { type } = this.props

    if (type === INDIVIDUAL_CLIENT_TYPE) {
      return INDIVIDUAL_CLIENT_CREATION
    }

    return COMPANY_CLIENT_CREATION
  }

  closeErrorsModal = () => {
    const { clientsCreationStore } = this.context

    clientsCreationStore.hideValidationModal()
  }

  handleSave = () => {
    const { history, t } = this.props
    const { clientsCreationStore } = this.context

    if (clientsCreationStore.isViewMode) {
      return null
    }

    /* saco la ruta de la segunda sección de una forma cuestionable */
    const redirectRoute = `${this.creationUrl}${clientsCreationStore.entitySections[1].route}`

    saveClient(clientsCreationStore, t('clientCreatedMsg'), history, redirectRoute)

    return null
  }

  handleCancel = () => {
    const { history } = this.props
    const { clientsCreationStore } = this.context

    if (clientsCreationStore.isEdition) {
      clientsCreationStore.reloadData()
      clientsCreationStore.setViewMode(true)
    } else {
      history.push(CLIENTS_LIST)
    }
  }

  handleEdit = () => {
    const { clientsCreationStore } = this.context

    clientsCreationStore.removeViewMode()
  }

  handleGoBack = () => {
    const { history } = this.props

    history.push(CLIENTS_LIST)
  }

  startComponent = () => {
    const { location, type } = this.props
    const { clientsCreationStore, creationParametersStore, authStore } = this.context
    const params = new URLSearchParams(location.search)
    let isViewMode = params.get('v') === 'true'
    let id = params.get('id')

    if (!id && !authStore.can(CREATE_CLIENT)) {
      id = 'forced_error'
    }

    if (!isViewMode && !authStore.can(EDIT_CLIENT)) {
      isViewMode = true
    }

    creationParametersStore.init()
    clientsCreationStore.init(id, isViewMode, type)
  }

  handleTryAgain = () => {
    const { clientsCreationStore } = this.context

    clientsCreationStore.tryAgain()
  }

  successChangeStatus = () => {
    const { history, t } = this.props
    const { clientsCreationStore } = this.context

    if (clientsCreationStore.entityStatus.isActive) {
      changeClientStatusInactive(
        clientsCreationStore,
        t('inactivatedToast'),
        history,
        this.creationUrl
      )
    } else {
      changeClientStatusActive(clientsCreationStore, t('activatedToast'), history, this.creationUrl)
    }

    this.handleCloseChangeStatus()
  }

  handleCloseChangeStatus = () => {
    const { clientsCreationStore } = this.context

    clientsCreationStore.hideChangeStatusModal()
  }

  configureKeyStore() {
    const { hotkeyStore } = this.context

    hotkeyStore.setConfig('clientCreation', [
      {
        shortcut: 'alt+s',
        handler: () => {
          this.handleSave()
        },
      },
    ])
  }

  render() {
    const { t } = this.props
    const { clientsCreationStore } = this.context

    if (clientsCreationStore.serverError) {
      return <ServerError handleTryAgain={this.handleTryAgain} />
    }

    if (clientsCreationStore.errorLoadEdit) {
      return <ClientsSectionError />
    }

    return (
      <>
        <MainCreationLoading store={clientsCreationStore} />
        <MainCreationContainer isLoading={clientsCreationStore.isLoading}>
          <ClientCreationTitle />
          <DynamicFormRoutes
            dynamicFormsStore={clientsCreationStore}
            onResetComponent={this.startComponent}
            dynamicEntityName={t('client')}
            baseRoute={this.creationUrl}
          />
          {clientsCreationStore.isEdition && (
            <>
              <Modal
                name="changeStatusModal"
                title={
                  clientsCreationStore.entityStatus.isActive
                    ? t('changeStatusModalInactivateTitle')
                    : t('changeStatusModalActivateTitle')
                }
                open={clientsCreationStore.visibleChangeStatusModal}
                onSuccess={this.successChangeStatus}
                onCancel={this.handleCloseChangeStatus}
              />
              <Modal
                name="errorsClientModal"
                title={t('attention')}
                open={clientsCreationStore.visibleValidationModal}
                onSuccess={this.closeErrorsModal}
                okOnly
              >
                <ClientErrorsModalContent />
              </Modal>
            </>
          )}

          <Route
            render={() => <FinancialStructure />}
            exact
            path={COMPANY_CLIENT_CREATION + INDIVIDUAL_CLIENT_FINANCIAL_STRUCTURE}
          />
          <Route
            render={() => <FinancialStructure />}
            exact
            path={INDIVIDUAL_CLIENT_CREATION + INDIVIDUAL_CLIENT_FINANCIAL_STRUCTURE}
          />

          <ClientCreationActionButtons
            clientsCreationStore={clientsCreationStore}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            handleEdit={this.handleEdit}
            handleGoBack={this.handleGoBack}
          />
        </MainCreationContainer>
      </>
    )
  }
}

ClientsCreation.contextType = StoresContext

ClientsCreation.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default withTranslation('clientsCreation')(observer(ClientsCreation))
