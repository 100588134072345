import { observable, action, runInAction, makeObservable } from 'mobx'
import TrustAssetsService from 'services/TrustAssetsService'
import Asset from 'models/Asset'
import AsyncStore from './AsyncStore'

class TrustAssetsStore extends AsyncStore {
  assets = []

  constructor(trustId) {
    super()

    makeObservable(this, {
      assets: observable,
      loadAssets: action,
    })

    this.trustId = trustId
    this.trustAssetsService = new TrustAssetsService()
  }

  async loadAssets(alternativeStatus) {
    this.preRequest()

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.trustAssetsService.listAssets(this.trustId, alternativeStatus)

      runInAction(() => {
        this.assets = []

        response.forEach((assetJson) => this.assets.push(Asset.createFromJson(assetJson)))

        this.onSuccessRequest()
      })
    } catch (e) {
      throw e
    }
  }
}

export default TrustAssetsStore
