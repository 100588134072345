import { makeAutoObservable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import InputStore from 'stores/InputStore'

class BeneficiaryListItem {
  constructor(type) {
    this.id = uuidv4()
    this.type = type
    this.name = new InputStore()
    this.icon = ''
    this.contactId = ''

    makeAutoObservable(this)
  }

  fillSharedFromBeneficiaryListItem(beneficiaryListItem) {
    this.id = beneficiaryListItem.id
    this.type = beneficiaryListItem.type
    this.icon = beneficiaryListItem.icon

    this.changeName(beneficiaryListItem.name.value)
  }

  fillSharedFromJson({ id, type, icon, name, contactId }) {
    this.id = id
    this.type = type
    this.icon = icon
    this.contactId = contactId

    this.changeName(name)
  }

  changeName(value) {
    this.name.setValue(value)
  }

  getSharedJson() {
    return {
      id: this.id,
      type: this.type,
      name: this.name.value,
      icon: this.icon,
      contactId: this.contactId,
    }
  }

  static createFromJson(json) {
    const beneficiaryListItem = new BeneficiaryListItem()

    beneficiaryListItem.fillSharedFromJson(json)

    return beneficiaryListItem
  }
}

export default BeneficiaryListItem
