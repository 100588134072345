import { makeObservable, observable, runInAction } from 'mobx'
import TrustGovernorsService from 'services/TrustGovernorsService'
import AsyncStore from './AsyncStore'

class TrustGovernorsStore extends AsyncStore {
  constructor(trustId) {
    super()
    this.trustId = trustId
    this.governors = []
    this.trustGovernorsService = new TrustGovernorsService()
    this.isLoading = false

    makeObservable(this, {
      trustId: observable,
      governors: observable,
    })
  }

  async loadGovernors() {
    try {
      this.preRequest(this.loadGovernors)
      const governors = await this.trustGovernorsService.listGovernors(this.trustId)

      runInAction(() => {
        this.governors = governors
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }
}

export default TrustGovernorsStore
