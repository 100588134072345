import React, { useCallback, useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ROLES_CREATION } from 'routing/routes'
import MetaTitle from 'util/MetaTitle'
import { CREATE_ROLE } from 'stores/AuthStore'
import StoresContext from 'providers/storeContext'
import ServerError from 'presentation/ServerError'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import RolesTableItem from 'presentation/RolesTableItem'
import RolesListStore from './RolesListStore'

const RolesList = () => {
  const [rolesListStore] = useState(() => new RolesListStore())
  const { hotkeyStore } = useContext(StoresContext)
  const [columns, setColumns] = useState([])
  const { t } = useTranslation('roles')
  const history = useHistory()

  const handleCreateRole = useCallback(() => history.push(ROLES_CREATION), [])

  const configureHotKeys = useCallback(() => {
    hotkeyStore.setConfig('rolesList', [
      {
        shortcut: 'alt+c',
        handler: () => {
          handleCreateRole()
        },
      },
    ])
  }, [])

  useEffect(() => {
    configureHotKeys()

    setColumns([
      {
        name: 'roleName',
        order: true,
        inverted: true,
        transName: t('common:roleName'),
        cssGridDefinition: '2fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
    ])

    rolesListStore.loadRoles()

    return () => hotkeyStore.removeConfig('rolesList')
  }, [])

  const handleTryAgain = useCallback(() => rolesListStore.tryAgain(), [])
  const handlePageChange = useCallback((pageTo) => rolesListStore.changeActivePage(pageTo), [])
  const handleSearch = useCallback((value) => rolesListStore.loadRoles(value, null, null, true), [])
  const handleChangeOrder = useCallback(
    (orderParamter, orderDirection) =>
      rolesListStore.loadRoles(
        null,
        orderParamter.includes('role')
          ? orderParamter.replace('role', '').toLowerCase()
          : orderParamter,
        orderDirection,
        true
      ),
    []
  )

  const handleSetShowDropdownOrder = useCallback((value) => {
    rolesListStore.setShowDropdownOrder(value)
  }, [])

  const { visibleRoles } = rolesListStore

  if (rolesListStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  return (
    <>
      <MetaTitle pageTitle={t('pageTitles:roles')} />
      <MainSectionList
        sectionItems={visibleRoles}
        currentPage={rolesListStore.activePage}
        isLoading={rolesListStore.isLoading}
        handleChangeOrder={handleChangeOrder}
        handlePageChange={handlePageChange}
        creationPermission={CREATE_ROLE}
        totalPages={rolesListStore.pages}
        handleCreation={handleCreateRole}
        sectionNameSingular={t('role')}
        sectionNamePlural={t('roles')}
        handleSearch={handleSearch}
        setShowDropdownOrder={handleSetShowDropdownOrder}
        tableComponent={
          <MainSectionTable
            t={t}
            listItems={visibleRoles}
            isLoading={rolesListStore.isLoading}
            onOrdered={handleChangeOrder}
            view="list"
            columns={columns}
            component={({ listItem, view: cView }) => (
              <RolesTableItem
                view={cView}
                listItem={listItem}
                // CAMBIAR RUTA
                link={`${ROLES_CREATION}?id=${listItem.id}&v=true`}
              />
            )}
          />
        }
      />
    </>
  )
}

export default observer(RolesList)
