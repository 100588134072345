/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Fragment, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import c from 'classnames'
import PropTypes from 'prop-types'
import scrollTo from 'util/scroll'
import isMobile from 'util/browser'
import KeyHandler, { KEYDOWN } from 'react-key-handler'
import CloseIcon from 'mdi-react/CloseIcon'
import styles from './creationModal.module.scss'

const CreationModal = ({ children, visible, title, handleClose, outsideClickClose, usePortal }) => {
  const [buttons, setButtons] = useState([])

  if (isMobile()) {
    useEffect(() => {
      scrollTo(document.body, 0, 400)
    })
  }

  const modalDom = (
    <>
      {visible && <KeyHandler keyEventName={KEYDOWN} keyValue="Escape" onKeyHandle={handleClose} />}
      <div
        role="link"
        className={c(styles.overlay, visible && styles.visible)}
        onClick={outsideClickClose ? handleClose : null}
        tabIndex="0"
      />
      <div className={c(styles.creationModal, visible && styles.visible)}>
        <div className={styles.modalBody} id="creationModalBody">
          <div className={styles.creationModalTitle}>
            {title}
            <CloseIcon onClick={handleClose} className={styles.closeIcon} />
          </div>
          {visible && <>{React.cloneElement(children, { setButtons })}</>}
        </div>
        <div className={styles.modalButtons}>
          {buttons.map((button, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`button_${i}`}>{button}</Fragment>
          ))}
        </div>
      </div>
    </>
  )

  if (usePortal) {
    return ReactDOM.createPortal(modalDom, document.body)
  }

  return modalDom
}

CreationModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  outsideClickClose: PropTypes.bool,
  usePortal: PropTypes.bool,
}

CreationModal.defaultProps = {
  outsideClickClose: true,
  usePortal: false,
}

export default CreationModal
