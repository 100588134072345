import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import styles from './executionErrorsModalContent.module.scss'

const ExecutionErrorsModalContent = () => {
  const { t } = useTranslation('corporationsCreation')
  const { corporationsCreationStore } = useContext(StoresContext)

  return (
    <div className={styles.modalContainer}>
      <div className={styles.message}>
        {corporationsCreationStore.entityStatus.isExecuted
          ? t('executedErrorsDescription')
          : t('executionErrorsDescription')}
      </div>
      {corporationsCreationStore &&
        corporationsCreationStore.validationErrors.map((error, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.errorItem} key={`validation_error_${i}`}>
            {error}
          </div>
        ))}
    </div>
  )
}

export default observer(ExecutionErrorsModalContent)
