import {
  TYPE_LODS,
  TYPE_LOWS,
  TYPE_TRUST_DOCUMENTS,
  TYPE_STATUTES,
  TYPE_INPUT_BOOL,
  TYPE_INPUT_TEXT,
  TYPE_INPUT_LIST,
  TYPE_GROUP_LIST,
  TYPE_ENTITY_LIST,
  TYPE_INPUT_DATA_LIST,
  TYPE_INPUT_CREATABLE_DATA_LIST,
  TYPE_INPUT_READ_ONLY,
  TYPE_INPUT_DATE,
  TYPE_INPUT_TEXTAREA,
  TYPE_INPUT_FILE,
  TYPE_INPUT_CERTIFIED_FILE,
  TYPE_INPUT_FILE_LIST,
  TYPE_INPUT_MONEY,
  TYPE_INPUT_PERCENTAGE,
  TYPE_INPUT_ASSET_TRUST,
  TYPE_INPUT_ASSET_COMPANY,
  TYPE_INPUT_ENTITIES_DATA_LIST,
  TYPE_INPUT_PROTECTOR_CATEGORIES_DATA_LIST,
  TYPE_HIDDEN,
  TYPE_ENTITY_SNAPSHOT_LIST,
  TYPE_CONTACT_SEARCH,
  InputListPart,
  GroupListPart,
  EntityListPart,
  TextPart,
  BoolPart,
  DataListPart,
  CreatableDataListPart,
  ReadOnlyPart,
  DatePart,
  TextareaPart,
  LowsPart,
  StatutesPart,
  LodsPart,
  TrustDocumentsPart,
  FilePart,
  CertifiedFilePart,
  FileListPart,
  MoneyPart,
  PercentagePart,
  EntitiesDataListPart,
  ProtectorCategoriesDataListPart,
  AssetTrustPart,
  AssetCompanyPart,
  HiddenPart,
  EntitySnapshotListPart,
  LowSnapshotPart,
  ContactSearchPart,
} from './Parts'

class PartStrategy {
  static getPart(part, onCreateInput) {
    switch (part.type) {
      case TYPE_INPUT_LIST:
        return InputListPart.fromJson(part, onCreateInput)

      case TYPE_GROUP_LIST:
        return GroupListPart.fromJson(part, onCreateInput)

      case TYPE_LODS:
        return LodsPart.getInstance()

      case TYPE_LOWS:
        return LowsPart.getInstance()

      case TYPE_TRUST_DOCUMENTS:
        return TrustDocumentsPart.getInstance()

      case TYPE_STATUTES:
        return StatutesPart.getInstance()

      case TYPE_ENTITY_LIST:
        return EntityListPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_TEXT:
        return TextPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_BOOL:
        if (part.id === 'lows') {
          return LowSnapshotPart.fromJson(part)
        }
        return BoolPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_DATA_LIST:
        return DataListPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_CREATABLE_DATA_LIST:
        return CreatableDataListPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_READ_ONLY:
        return ReadOnlyPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_DATE:
        return DatePart.fromJson(part, onCreateInput)

      case TYPE_INPUT_TEXTAREA:
        return TextareaPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_FILE:
        return FilePart.fromJson(part, onCreateInput)

      case TYPE_INPUT_CERTIFIED_FILE:
        return CertifiedFilePart.fromJson(part, onCreateInput)

      case TYPE_INPUT_FILE_LIST:
        return FileListPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_MONEY:
        return MoneyPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_PERCENTAGE:
        return PercentagePart.fromJson(part, onCreateInput)

      case TYPE_INPUT_ENTITIES_DATA_LIST:
        return EntitiesDataListPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_PROTECTOR_CATEGORIES_DATA_LIST:
        return ProtectorCategoriesDataListPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_ASSET_TRUST:
        return AssetTrustPart.fromJson(part, onCreateInput)

      case TYPE_INPUT_ASSET_COMPANY:
        return AssetCompanyPart.fromJson(part, onCreateInput)

      case TYPE_HIDDEN:
        return HiddenPart.fromJson(part, onCreateInput)

      case TYPE_ENTITY_SNAPSHOT_LIST:
        return EntitySnapshotListPart.fromJson(part)

      case TYPE_CONTACT_SEARCH:
        return ContactSearchPart.fromJson(part)

      default:
        // console.error(`Type ${part.type} is not supported`)
        return null
    }
  }
}

export default PartStrategy
