import React from 'react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './dashboadCard.module.scss'

const DashboadCard = ({ name, updated, icon, status }) => {
  const { t } = useTranslation('dashboard')

  return (
    <div className={styles.card}>
      <div className={styles.containerInformation}>
        <div className={styles.name}>{name}</div>
        <div className={styles.containerUpdated}>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.updated}>{updated}</div>
        </div>
      </div>
      <div className={styles.statusContainer}>
        <span
          className={c(
            styles.bullet,
            status === 'draft' && styles.statusDraft,
            status === 'stored' && styles.statusStored,
            status === 'executed' && styles.statusExecuted,
            status === 'execution' && styles.statusExecution,
            status === 'pending' && styles.statusPending
          )}
        />
        <div className={styles.status}>{t(status === 'pending' ? 'common:pending' : status)}</div>
      </div>
    </div>
  )
}

DashboadCard.propTypes = {
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
}

DashboadCard.defaultProps = {
  icon: null,
}

export default DashboadCard
