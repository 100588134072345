import InputStore from 'stores/InputStore'

class DestinationAsset {
  constructor() {
    this.asset = new InputStore()
    this.amount = new InputStore()
  }

  setAmount(value) {
    this.amount.setValue(value)
  }

  setAsset(value) {
    this.asset.setValue(value)
  }

  getJson() {
    return { assetId: this.asset.value.label, amount: this.amount.value }
  }
}

export default DestinationAsset
