import { action, computed, observable, makeObservable } from 'mobx'

const STATUS_DRAFT = 'draft'
const STATUS_EXECUTED = 'executed'
const STATUS_EXECUTION = 'execution'
const STATUS_PENDING_TO_APPROVAL = 'pending'
const STATUS_STORED = 'stored'
const STATUS_TERMINATED = 'terminated'

class EntityStatus {
  constructor(status = STATUS_DRAFT) {
    this.status = status

    makeObservable(this, {
      // observables
      status: observable,
      // actions
      setStatus: action,
      setDraft: action,
      // computeds
      isDraft: computed,
      isExecuted: computed,
      isExecution: computed,
      isPendingToApproval: computed,
      isStored: computed,
      isTerminated: computed,
      isWorkingProgress: computed,
      canBeEdited: computed,
      canViewRules: computed,
      canViewSignedDocuments: computed,
      canEditRules: computed,
      canRollback: computed,
    })
  }

  setStatus(status) {
    this.status = status
  }

  setDraft() {
    this.setStatus(STATUS_DRAFT)
  }

  get isDraft() {
    return this.status === STATUS_DRAFT
  }

  get isExecuted() {
    return this.status === STATUS_EXECUTED
  }

  get isExecution() {
    return this.status === STATUS_EXECUTION
  }

  get isPendingToApproval() {
    return this.status === STATUS_PENDING_TO_APPROVAL
  }

  get isStored() {
    return this.status === STATUS_STORED
  }

  get isTerminated() {
    return this.status === STATUS_TERMINATED
  }

  get isWorkingProgress() {
    return this.isDraft || this.isExecuted || this.isExecution
  }

  get canBeEdited() {
    return this.isDraft
  }

  get canViewRules() {
    return !this.isDraft
  }

  get canViewSignedDocuments() {
    return this.isExecuted || this.isPendingToApproval || this.isStored
  }

  get canEditRules() {
    return this.isExecution
  }

  get canRollback() {
    return this.isExecution || this.isExecuted
  }
}

export default EntityStatus
export { STATUS_STORED }
