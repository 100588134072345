import React, { useContext } from 'react'
import StoresContext from 'providers/storeContext'
import { observer } from 'mobx-react'
import LoadingRing from 'presentation/LoadingRing'
import styles from './logout.module.scss'

const Logout = () => {
  const { authStore } = useContext(StoresContext)

  if (authStore.firstLoadToken) {
    setTimeout(() => {
      authStore.logout()
    })
  }

  return (
    <div className={styles.loading}>
      <LoadingRing />
    </div>
  )
}

export default observer(Logout)
