import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import PlusIcon from 'mdi-react/PlusIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import { RuleAssetBeneficiary } from 'models/Rule'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'
import Button from 'presentation/Button'
import BeneficiaryDistributionSelection from './BeneficiaryDistributionSelection'
import styles from './beneficiarySelection.module.scss'

const BeneficiarySelection = observer(
  ({
    options,
    beneficiary,
    handleChangeBeneficiary,
    showAddButton,
    handleAddBeneficiary,
    handleDeleteBeneficiary,
    handleChangeValue,
    error,
  }) => {
    let selected = null

    if (beneficiary) {
      options.forEach((option) => {
        if (option.id === beneficiary.beneficiaryId) {
          selected = option
        }
      })
    }

    return (
      <div className={styles.container}>
        <CreationInput
          as={FormSelect}
          name="selectBeneficiary"
          options={options}
          onChange={handleChangeBeneficiary}
          value={selected}
          error={error}
        />
        <div>
          {selected && (
            <BeneficiaryDistributionSelection
              distribution={beneficiary.distribution}
              handleChangeValue={handleChangeValue}
            />
          )}
        </div>
        {showAddButton && <Button onClick={handleAddBeneficiary} icon={<PlusIcon />} circle />}
        {!showAddButton && (
          <Button onClick={handleDeleteBeneficiary} icon={<CloseIcon />} circle secondary />
        )}
      </div>
    )
  }
)

BeneficiarySelection.propTypes = {
  beneficiary: PropTypes.instanceOf(RuleAssetBeneficiary),
  handleChangeBeneficiary: PropTypes.func.isRequired,
  handleDeleteBeneficiary: PropTypes.func.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  options: PropTypes.oneOfType([PropTypes.array]),
  handleChangeValue: PropTypes.func,
  showAddButton: PropTypes.bool,
  handleAddBeneficiary: PropTypes.func,
  error: PropTypes.bool,
}

BeneficiarySelection.defaultProps = {
  options: [],
  beneficiary: null,
  showAddButton: false,
  handleAddBeneficiary: null,
  handleChangeValue: null,
  error: false,
}

export default BeneficiarySelection
