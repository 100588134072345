import { parseValidationRules } from 'util/validation'
import Part, { TYPE_INPUT_TEXTAREA } from './Part'
import FormPartStore from './FormPartStore'

class TextareaPart extends Part {
  constructor(id, label, readOnly) {
    super(id, TYPE_INPUT_TEXTAREA)

    this.label = label
    this.readOnly = readOnly
  }

  static fromJson({ id, display, validationRules = null }, onCreateInput) {
    const { label = '', readOnly = false } = display
    const input = new FormPartStore()

    if (validationRules) {
      input.setValidationRules(parseValidationRules(validationRules))
    }

    onCreateInput(id, input)

    return new TextareaPart(id, label, readOnly)
  }
}

export default TextareaPart
