import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Tree from 'react-d3-tree'
import { observer } from 'mobx-react'
import c from 'classnames'
import { useLocation, useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import ChevronUpIcon from 'mdi-react/ChevronUpIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import CompanyIcon from 'mdi-react/BriefcaseIcon'
import ClientIcon from 'mdi-react/AccountIcon'
import TrustIcon from 'mdi-react/CardTextIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import MinusIcon from 'mdi-react/MinusIcon'
import * as Routes from 'routing/routes'
import PageTitle from 'presentation/PageTitle'
import LoadingRing from 'presentation/LoadingRing'
import FinancialStructureStore from './FinancialStructureStore'
import styles from './FinancialStructure.module.scss'

const ITEM_WIDTH = 270 + 12
const ZOOM_MAX = 6
const ZOOM_MIN = 0

const FinancialStructure = () => {
  const history = useHistory()
  const location = useLocation()
  const [store] = useState(() => new FinancialStructureStore())
  const [initZoom, setInitZoom] = useState(false)

  const { t } = useTranslation('corporationsCreation')
  const container = useRef(null)
  const treeRef = useRef(null)

  useEffect(() => {
    const id = new URLSearchParams(location.search).get('id')
    if (!id) return

    const fetchData = async () => {
      await store.getData(id)
      setTimeout(() => {
        store.setConfig({
          x: container.current.offsetWidth / 2,
          y: 40,
        })
      }, 100)
    }
    fetchData()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (!initZoom && treeRef.current && container.current) {
        const treeContainer = container.current
          .getElementsByClassName(treeRef.current.state.rd3tGClassName)[0]
          .getBoundingClientRect()

        if (treeContainer.width && container.current.clientWidth) {
          if (
            container.current.clientWidth < treeContainer.width ||
            container.current.clientHeight < treeContainer.height
          ) {
            if (store.changeZoom('out') <= ZOOM_MIN) {
              setInitZoom(true)
            }
          } else {
            setInitZoom(true)
          }
        }
      }
    }, 500)
  }, [treeRef.current, container.current, store.treeConfig.zoom])

  const onClickNode = (nodeData) => {
    if (!store.onMove && nodeData.idElement) {
      let route = null
      if (nodeData.type === 'assetTrust') route = Routes.TRUSTS_CREATION
      if (nodeData.type === 'assetCorporation') route = Routes.CORPORATIONS_CREATION
      if (route) history.push(`${route}?id=${nodeData.idElement}&v=true`)
    }
  }

  const TreeLabel = ({ nodeData }) => {
    return (
      <div
        className={c(
          styles.tree__item,
          !store.onMove && nodeData.idElement && styles.tree__itemClick
        )}
        title={nodeData.name}
        onClick={() => onClickNode(nodeData)}
        role="button"
        tabIndex="0"
      >
        <div>
          <div className={styles.tree__itemIcon}>
            {nodeData.type === 'assetTrust' && <TrustIcon />}
            {nodeData.type === 'assetCorporation' && <CompanyIcon />}
            {(nodeData.type === 'client' || !nodeData.type) && <ClientIcon />}
          </div>
          <div className={styles.tree__itemName}>{nodeData.name}</div>
        </div>
      </div>
    )
  }

  TreeLabel.propTypes = {
    nodeData: PropTypes.oneOfType([PropTypes.object]),
  }

  TreeLabel.defaultProps = {
    nodeData: null,
  }

  const zoom = (value) => {
    return () => store.changeZoom(value)
  }

  const move = (value) => {
    return () => store.move(value)
  }

  const stopMove = () => {
    store.stopMove()
  }

  const getTranslate = () => {
    return JSON.parse(JSON.stringify(store.treeConfig.translate))
  }

  return (
    <>
      <PageTitle title={t('fiduciaryStructures')} />
      <div ref={container} className={c(styles.tree__container)}>
        <div className={styles.zoomControls}>
          <button type="button" className={styles.controlButton} onClick={zoom('out')}>
            <MinusIcon />
          </button>
          <button type="button" className={styles.controlButton} onClick={zoom('in')}>
            <PlusIcon />
          </button>
        </div>
        <div className={styles.moveControls} onMouseUp={stopMove} role="button" tabIndex="0">
          <button type="button" className={styles.controlButton} onMouseDown={move('up')}>
            <ChevronUpIcon />
          </button>
          <button type="button" className={styles.controlButton} onMouseDown={move('left')}>
            <ChevronLeftIcon />
          </button>
          <button type="button" className={styles.controlButton} onMouseDown={move('right')}>
            <ChevronRightIcon />
          </button>
          <button type="button" className={styles.controlButton} onMouseDown={move('down')}>
            <ChevronDownIcon />
          </button>
        </div>
        {!initZoom && <LoadingRing center absolute />}
        <div className={c(styles.tree, initZoom && styles.tree__containerShow)}>
          {store.data.length > 0 && (
            <Tree
              ref={treeRef}
              collapsible={false}
              className={styles.tree}
              data={store.data}
              onUpdate={({ zoom: thisZoom, translate }) => {
                store.setCurrentTreeConfig({ zoom: thisZoom, x: translate.x, y: translate.y })
              }}
              pathFunc={({ source, target }) => {
                return `M${source.x},${source.y + 25}V${target.y - 25}H${target.x}V${target.y}`
              }}
              zoom={store.treeConfig.zoom}
              scaleExtent={{
                min: ZOOM_MIN,
                max: ZOOM_MAX,
              }}
              orientation="vertical"
              separation={{ siblings: 2.2, nonSiblings: 2.2 }}
              translate={getTranslate()}
              nodeSvgShape={{ shape: 'none' }}
              styles={{
                links: { strokeWidth: 2, stroke: 'white' },
              }}
              allowForeignObjects
              nodeLabelComponent={{
                render: <TreeLabel />,
                foreignObjectWrapper: {
                  width: ITEM_WIDTH,
                  y: -8,
                  x: -ITEM_WIDTH / 2,
                  cursor: 'default',
                },
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default observer(FinancialStructure)
