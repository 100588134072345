import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { EntitySnapshotListPart } from 'models/FormMetadata/Parts'
import instanceEntityTextReplacer from 'util/instanceEntityTextReplacer'
import Icon from 'presentation/Icon'
import FormLabel from 'presentation/FormLabel'
import styles from './dynamicFormPartEntitySnapshotList.module.scss'

function intersperse(arr, sep) {
  const filteredArray = arr.filter((el) => el)

  if (filteredArray.length === 0) {
    return []
  }

  return filteredArray.slice(1).reduce((xs, x) => xs.concat([sep, x]), [filteredArray[0]])
}

const DynamicFormPartEntitySnapshotList = ({ part, inputs }) => {
  const { instanceEntities } = inputs.get(part.id)

  const entityNames = instanceEntities.map((instanceEntity) => {
    const entityMapper = part.getEntityByType(instanceEntity.type)

    if (entityMapper === null) return null

    return (
      <span className={styles.containerSnapshotItem} key={instanceEntity.id}>
        <div className={styles.name}>
          <Icon className={styles.icon} name={entityMapper?.icon} size={15} />
          {instanceEntityTextReplacer(instanceEntity, entityMapper?.name)}
        </div>
      </span>
    )
  })

  return (
    <div className={styles.containerViewMode}>
      <FormLabel label={part.label} viewMode />
      {entityNames.length > 0 ? intersperse(entityNames, ', ') : '-'}
    </div>
  )
}

DynamicFormPartEntitySnapshotList.propTypes = {
  part: PropTypes.instanceOf(EntitySnapshotListPart).isRequired,
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default observer(DynamicFormPartEntitySnapshotList)
