import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReadOnlyPart } from 'models/FormMetadata'
import CreationInput from 'presentation/CreationInput'

const DynamicFormPartReadOnly = ({ part, inputs, readOnly }) => {
  const [inputStore] = useState(inputs.get(part.id).store)

  if (!readOnly) {
    return null
  }

  return <CreationInput inputStore={inputStore} label={part.label} literal />
}

DynamicFormPartReadOnly.propTypes = {
  inputs: PropTypes.oneOfType([PropTypes.object]).isRequired,
  part: PropTypes.instanceOf(ReadOnlyPart).isRequired,
  readOnly: PropTypes.bool,
}

DynamicFormPartReadOnly.defaultProps = {
  readOnly: true,
}

export default DynamicFormPartReadOnly
