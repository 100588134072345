import { action, computed, observable, runInAction, makeObservable } from 'mobx'
import Color from 'color'
import AsyncStore from 'stores/AsyncStore'
import RulesService from 'services/RulesService'
import Rule from 'models/Rule'
import { flatMap, sortBy, sortedUniq } from 'lodash'

const START_COLOR = Color('rgb(111, 205, 255)')
const COLOR_HUE_ROTATION = 100

const nextColor = (currentColor) => currentColor.rotate(COLOR_HUE_ROTATION)

class RulesStore extends AsyncStore {
  deedOfTrustText
  letterOfWishesText
  otherDocumentsText
  amendmentsTexts = {}

  constructor(trustCreationStore) {
    super()

    this.rules = []
    this.currentColor = START_COLOR
    this.globalViewMode = false
    this.viewFilters = false
    this.assetsFilter = []
    this.beneficiariesFilter = []
    this.selectionMode = false

    this.restrictions = []
    this.restrictionsIsDirty = false

    this.rulesService = new RulesService()
    this.trustCreationStore = trustCreationStore

    makeObservable(this, {
      // observables
      rules: observable,
      globalViewMode: observable,
      viewFilters: observable,
      assetsFilter: observable,
      beneficiariesFilter: observable,
      selectionMode: observable,
      restrictions: observable,
      restrictionsIsDirty: observable,
      // actions
      reset: action,
      createRule: action,
      clearFilters: action,
      startSelectionMode: action,
      finishSelectionMode: action,
      setAssetsFilter: action,
      setBeneficiariesFilter: action,
      removeRule: action,
      loadRules: action,
      disableGlobalViewMode: action,
      toggleGlobalViewMode: action,
      toggleViewFilters: action,
      setToggleViewFilters: action,
      saveRule: action,
      isSearchedRule: action,
      setRestrictionsIsDirty: action,
      // computeds
      deedSectionRules: computed,
      letterSectionRules: computed,
      isEditingRules: computed,
      deedOfTrustRules: computed,
      letterOfWishesRules: computed,
      visibleRules: computed,
    })
  }

  reset() {
    this.assetsFilter = []
    this.beneficiariesFilter = []
    this.viewFilters = false
    this.selectionMode = false
  }

  setDeedOfTrustText(text) {
    this.deedOfTrustText = text
  }

  setLetterOfWishesText(text) {
    this.letterOfWishesText = text
  }

  setOtherDocumentsText(text) {
    this.otherDocumentsText = text
  }

  setAmendmentText(id, text) {
    this.amendmentsTexts[id] = text
  }

  getTextForTarget(target) {
    if (target === 'deedOfTrust') {
      return this.deedOfTrustText
    }

    if (target === 'letterOfWishes') {
      return this.letterOfWishesText
    }

    if (target === 'otherDocuments') {
      return this.otherDocumentsText
    }

    if (target.indexOf('amendment') > -1) {
      const id = target.replace('amendment_', '')

      return this.amendmentsTexts[id]
    }

    return null
  }

  createRule(start, end, targetRuleCreation) {
    const text = this.getTextForTarget(targetRuleCreation)

    this.rules.push(new Rule(start, end, text, this.currentColor, targetRuleCreation))

    this.currentColor = nextColor(this.currentColor)
  }

  clearFilters() {
    if (this.assetsFilter.length || this.beneficiariesFilter.length) {
      this.setAssetsFilter()
      this.setBeneficiariesFilter()
    }
  }

  startSelectionMode() {
    this.selectionMode = true
    this.clearFilters()
  }

  finishSelectionMode() {
    this.selectionMode = false
  }

  setAssetsFilter(options) {
    this.assetsFilter = options || []
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }

  setBeneficiariesFilter(options) {
    this.beneficiariesFilter = options || []
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }

  removeRule(rule) {
    rule.setDeleted(rule)
    this.isLoading = true
    this.saveRule(rule).then(() => {
      this.rules.remove(rule)
      this.isLoading = false
    })
  }

  async loadRules() {
    this.preRequest(this.loadRules)

    try {
      const response = await this.rulesService.getByTrustId(
        this.trustCreationStore.idEdition,
        this.trustCreationStore.alternativeStatusLoad
      )
      runInAction(() => {
        this.rules = response.map((rule) => {
          // eslint-disable-next-line no-param-reassign
          rule.color = this.currentColor

          this.currentColor = nextColor(this.currentColor)
          return Rule.fromJson(rule)
        })
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }
  }

  async loadRestrictions() {
    const restrictions = await this.rulesService.loadRestrictions(this.trustCreationStore.idEdition)

    runInAction(() => {
      this.restrictions = restrictions
    })
  }

  setRestrictionsIsDirty(value) {
    this.restrictionsIsDirty = value
  }

  async saveRestrictions() {
    try {
      this.preRequest()

      const restrictedTypes = this.restrictions
        .filter((type) => type.isRestricted.value === true)
        .map((type) => {
          return type.getJson()
        })

      await this.rulesService.saveRestrictions(this.trustCreationStore.idEdition, {
        assetNotAllowed: restrictedTypes,
      })
      runInAction(() => {
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  get hasValidAssets() {
    let validAssets = false

    this.rules.forEach((rule) => {
      if (rule.hasValidAssets) {
        validAssets = true
      }
    })

    return validAssets
  }

  disableGlobalViewMode() {
    this.globalViewMode = false
  }

  toggleGlobalViewMode() {
    this.globalViewMode = !this.globalViewMode
  }

  toggleViewFilters() {
    this.viewFilters = !this.viewFilters
  }

  setToggleViewFilters(value) {
    this.viewFilters = value
  }

  async saveRule(rule) {
    rule.loading()

    if (!(!rule.saved && rule.isDeleted)) {
      try {
        const completeRequest = (response) => {
          runInAction(() => {
            rule.notLoading()
            rule.save()

            if (!rule.id && response.id) {
              rule.setId(response.id)
              if (response.letterType === 'deed') {
                rule.setTarget(`deedOfTrust_${response.letterId}`)
              }
              if (response.letterType === 'low') {
                rule.setTarget(`letterOfWishes_${response.letterId}`)
              }
              if (response.letterType === 'amendment') {
                rule.setTarget(`amendment_${response.letterId}`)
              }
              if (response.letterType === 'other') {
                rule.setTarget(`otherDocuments_${response.letterId}`)
              }
            }
          })
        }

        if (rule.isDeedOfTrust) {
          const response = await this.rulesService.saveDeedOfTrustRule(
            this.trustCreationStore.idEdition,
            rule.getJson()
          )

          completeRequest(response)

          return response
        }

        if (rule.isAmendment) {
          const response = await this.rulesService.saveAmendmentRule(
            this.trustCreationStore.idEdition,
            rule.getJson()
          )

          completeRequest(response)

          return response
        }

        if (rule.isLetterOfWishes) {
          const response = await this.rulesService.saveLetterOfWishesRule(
            this.trustCreationStore.idEdition,
            rule.getJson()
          )

          completeRequest(response)

          return response
        }

        if (rule.isOtherDocuments) {
          const response = await this.rulesService.saveOtherDocumentsRule(
            this.trustCreationStore.idEdition,
            rule.getJson()
          )
          completeRequest(response)

          return response
        }
      } catch (e) {
        runInAction(() => {
          rule.notLoading()
          this.onErrorRequest(e)
        })
      }
    } else {
      this.rules = this.rules.filter((a) => a !== rule)
    }

    return null
  }

  isVisibleAmendment(id) {
    let isVisible = false

    this.trustCreationStore.deedsStore.amendmentsForRules.forEach((amendment) => {
      if (amendment.id === id) {
        isVisible = true
      }
    })

    return isVisible
  }

  isSearchedRule(rule) {
    let searched = false
    if (rule.saved) {
      this.assetsFilter.forEach((assetFilter) => {
        if (rule.selectedAssets.includes(assetFilter.id)) {
          searched = true
        }
      })

      if (!searched) {
        this.beneficiariesFilter.forEach((beneficiaryFilter) => {
          if (rule.selectedBeneficiaries.includes(beneficiaryFilter.id)) {
            searched = true
          }
        })
      }
    } else {
      searched = !this.beneficiariesFilter.length && !this.assetsFilter.length
    }

    return searched
  }

  get deedSectionRules() {
    return this.rules.filter(
      (rule) =>
        ((rule.isDeedOfTrust &&
          rule.letterId === this.trustCreationStore.deedsStore.deedOfTrustForRules.id) ||
          (rule.isAmendment && this.isVisibleAmendment(rule.letterId)) ||
          !rule.saved) &&
        ((!this.assetsFilter.length && !this.beneficiariesFilter.length) ||
          this.isSearchedRule(rule))
    )
  }

  get letterSectionRules() {
    return this.rules.filter(
      (rule) =>
        ((rule.isLetterOfWishes &&
          rule.letterId === this.trustCreationStore.lettersStore.letterOfWishesForRules.id) ||
          !rule.saved) &&
        ((!this.assetsFilter.length && !this.beneficiariesFilter.length) ||
          this.isSearchedRule(rule))
    )
  }

  get otherDocsSectionRules() {
    return this.rules.filter(
      (rule) =>
        ((rule.isOtherDocuments &&
          rule.letterId === this.trustCreationStore.otherDocsStore.otherDocumentsForRules.id) ||
          !rule.saved) &&
        ((!this.assetsFilter.length && !this.beneficiariesFilter.length) ||
          this.isSearchedRule(rule))
    )
  }

  get isEditingRules() {
    return this.rules.reduce((p, e) => {
      if (
        e.edition &&
        (e.backupTitle !== e.title.value || e.backupDescription !== e.description.value)
      )
        return true
      return p
    }, false)
  }

  get deedOfTrustRules() {
    const rules = this.deedSectionRules.filter((rule) => rule.isDeedOfTrust)
    return this.parseRules(rules, this.deedOfTrustText)
  }

  get letterOfWishesRules() {
    return this.parseRules(this.letterSectionRules, this.letterOfWishesText)
  }

  get otherDocumentsRules() {
    return this.parseRules(this.otherDocsSectionRules, this.otherDocumentsText)
  }

  amendmentRules(id) {
    const rules = this.deedSectionRules.filter((rule) => rule.target === `amendment_${id}`)

    return this.parseRules(rules, this.getTextForTarget(`amendment_${id}`))
  }

  get visibleRules() {
    return [...this.deedSectionRules, ...this.letterSectionRules]
  }

  // eslint-disable-next-line class-methods-use-this
  parseRules(targetRules, targetText) {
    if (targetText === undefined) {
      return []
    }

    // flattens all breakpoints, creating an array of only starts/ends of positions
    let breakpoints = flatMap(targetRules, (rule) =>
      flatMap(rule.positions, (position) => [position.start, position.end])
    )

    // adds to the flatten the first and last position of the text
    breakpoints.push(0)
    breakpoints.push(targetText.length)

    // removes duplicate keys and orders asc
    breakpoints = sortedUniq(sortBy(breakpoints))

    // creates first chunks, as helpers, this will create an array of double values with breakpoints of selected rules
    const chunks = []

    breakpoints.forEach((value, key, array) => {
      if (key < array.length - 1) {
        chunks.push([value, array[key + 1]])
      }
    })

    // creates super chunks, creates an array of objects that will contain start/end for each chunk, the text for that chunk and which rules (if any) apply for it
    return chunks.map((chunk) => {
      const rules = targetRules.filter((rule) => rule.includesChunk(chunk))

      return { rules, start: chunk[0], end: chunk[1], text: targetText.substring(...chunk) }
    })
  }
}

export default RulesStore
