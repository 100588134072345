import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import ContactSearchStore from 'stores/ContactSearchStore'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'
import FormSelect from 'presentation/FormSelect'
import CreationInput from 'presentation/CreationInput'
import ContactSelectItem from './ContactSelectItem'
import styles from './contactSearch.module.scss'

const ContactSearch = ({ entityName, onSelectContact, isOnlyClientSearch }) => {
  const [contactSearchStore] = useState(() => new ContactSearchStore())
  const { t } = useTranslation('dynamicForms')

  const handleInputChange = (value) => {
    contactSearchStore.setSearchTerm(value)
  }

  const handleChange = useCallback((selectedContact) => {
    onSelectContact(selectedContact)
  }, [])

  useEffect(() => {
    contactSearchStore.setIsOnlyClientSearch(isOnlyClientSearch)
    contactSearchStore.loadContacts()
  }, [])

  return (
    <div className={styles.searchContainer}>
      <DynamicFormSubtitle label={t('titleContact', { what: entityName })} />
      <CreationInput
        as={FormSelect}
        onChange={handleChange}
        placeholder={t('selectContact')}
        onInputChange={handleInputChange}
        isLoading={contactSearchStore.isLoading}
        components={{ Option: ContactSelectItem }}
        options={contactSearchStore.selectOptions}
        inputStore={contactSearchStore.selectedUser}
      />
    </div>
  )
}

ContactSearch.propTypes = {
  entityName: PropTypes.string.isRequired,
  onSelectContact: PropTypes.func.isRequired,
  isOnlyClientSearch: PropTypes.bool,
}

ContactSearch.defaultProps = {
  isOnlyClientSearch: undefined,
}

export default observer(ContactSearch)
