import PropTypes from 'prop-types'
import AccountCheckIcon from 'mdi-react/AccountCheckIcon'
import CashMultipleIcon from 'mdi-react/CashMultipleIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import ClipboardTextMultipleIcon from 'mdi-react/ClipboardTextMultipleIcon'
import SwapHorizontalBoldIcon from 'mdi-react/SwapHorizontalBoldIcon'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'

const ActivityIconSelector = ({ type }) => {
  let icon
  switch (type) {
    case 'governorApproval':
      icon = <AccountCheckIcon size={16} />
      break
    case 'assetValuationChange':
      icon = <CashMultipleIcon size={16} />
      break
    case 'contactInformationChange':
      icon = <AccountMultipleIcon size={16} />
      break
    case 'assetDocumentChange':
      icon = <ClipboardTextMultipleIcon size={16} />
      break
    case 'transactionApproval':
      icon = <SwapHorizontalBoldIcon size={16} />
      break
    case 'storeApproval':
      icon = <ContentSaveIcon size={16} />
      break
    default:
      icon = <div>{type}</div>
  }
  return <div style={{ marginRight: '5px' }}>{icon}</div>
}

ActivityIconSelector.propTypes = {
  type: PropTypes.string.isRequired,
}

export default ActivityIconSelector
