import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import FileDocumentIcon from 'mdi-react/FileDocumentIcon'
import { Amendment } from 'models/Letters'
import styles from './rulesAmendmentTitle.module.scss'

const RulesAmendmentTitle = ({ amendment }) => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div>
          <FileDocumentIcon size={20} />
        </div>
        {amendment.title.value}
      </div>
      <div>{amendment.lastUpdate.format(t('humanDateYearFormat'))}</div>
    </div>
  )
}

RulesAmendmentTitle.propTypes = {
  amendment: PropTypes.instanceOf(Amendment).isRequired,
}

export default RulesAmendmentTitle
