import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import LoadingRing from 'presentation/LoadingRing'
import TrustAssetsStore from 'models/FormMetadata/Parts/AssetTrustPartStore'
import Tabs, { Tab } from 'presentation/Tabs'
import AssetNameSelect from '../AssetForm/AssetNameSelect'
import ContactInformation from '../AssetForm/ContactInformation'
import TrustInformation from './TrustInformation'
import AssetInformation from '../AssetInformation'
import styles from './styles.module.scss'

const AssetTrustForm = ({ store, readOnly }) => {
  const [loaded, setLoaded] = useState(false)
  const { t } = useTranslation('corporationsCreation')

  const [activeTab, setActiveTab] = useState(0)

  const handleChangeTab = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    store.loadData()
  }, [])

  if (store.isLoading && !loaded) {
    return <LoadingRing center />
  }

  if (!loaded) setLoaded(true)

  return (
    <>
      <AssetNameSelect
        store={store}
        label={t('assetTrust.fullName')}
        placeholder={t('assetTrust.fullNamePlaceholder')}
        readOnly={readOnly}
      />
      {store.selected && (
        <div className={styles.tabsContainer}>
          <Tabs
            removeOutPageScrollMobile
            onChangeTab={handleChangeTab}
            defaultActiveTab={activeTab}
          >
            <Tab name={t('assetTrust.trustInformation.title')}>
              <TrustInformation store={store} readOnly={readOnly} />
            </Tab>
            <Tab name={t('asset.contactInformation.tabTitleContact')}>
              <ContactInformation store={store} readOnly={readOnly} />
            </Tab>
            <Tab name={t('assetTrust.assetInformation')}>
              <AssetInformation store={store} readOnly={readOnly} />
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  )
}

AssetTrustForm.propTypes = {
  store: PropTypes.instanceOf(TrustAssetsStore).isRequired,
  readOnly: PropTypes.bool,
}

AssetTrustForm.defaultProps = {
  readOnly: true,
}

export default observer(AssetTrustForm)
