import File from 'models/File'
import Letter from './Letter'

class OtherDocuments extends Letter {
  constructor() {
    super('other-documents')
  }

  static createFromJson({
    id = null,
    title = null,
    text = null,
    file = null,
    signedFile = null,
    status = null,
    updated = null,
  }) {
    const otherDocument = new OtherDocuments()

    otherDocument.changeUpdated(updated)
    otherDocument.changeId(id)
    otherDocument.changeStatus(status)
    otherDocument.changeTitle(title)
    otherDocument.changeText(text)
    otherDocument.file = new File()
    otherDocument.signedFile = new File()

    if (file !== null) {
      otherDocument.file = File.fromJson(file)
    }

    if (signedFile !== null) {
      otherDocument.signedFile = File.fromJson(signedFile)
    }

    return otherDocument
  }

  fillFromOldVersion(documentVersion) {
    this.changeTitle(documentVersion.title)
    this.changeText(documentVersion.text)

    if (documentVersion.file !== null) {
      this.file = documentVersion.file
    }

    if (documentVersion.signedFile !== null) {
      this.signedFile = documentVersion.signedFile
    }
  }

  static createFromOtherDocuments(otherDocuments) {
    const document = new OtherDocuments()

    document.changeTitle(otherDocuments.title)
    document.changeText(otherDocuments.text)

    if (otherDocuments.file !== null) {
      document.file = otherDocuments.file
    }

    if (otherDocuments.signedFile !== null) {
      document.signedFile = otherDocuments.signedFile
    }

    return document
  }
}

export default OtherDocuments
