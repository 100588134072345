import React from 'react'
import PropTypes from 'prop-types'
import icons from './icons'

const Icon = ({ name, ...restOfProps }) => {
  if (icons[name]) {
    const Component = icons[name]

    return <Component {...restOfProps} />
  }
  return null
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
}

export default Icon
