import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Icon from 'presentation/Icon'
import Rule from 'models/Rule'
import styles from './ruleViewItemScheduling.module.scss'

const RuleViewItemScheduling = ({ rule }) => {
  const { t } = useTranslation('trustCreation')

  const recurrentRule = useMemo(() => {
    const fromDate = rule?.from?.value ? `${rule.from.value.format(t('common:dateFormat'))}` : null
    const toDate = rule?.to?.value ? `${rule.to.value.format(t('common:dateFormat'))}` : null
    return `${fromDate}-${toDate} (${rule?.recurrencePeriodAmount?.value} ${rule?.recurrencePeriodUnits?.value?.value})`
  }, [
    rule.from.value,
    rule.to.value,
    rule.recurrencePeriodAmount.value,
    rule.recurrencePeriodUnits.value,
  ])

  const nonRecurrentRule = useMemo(() => {
    return rule.vestingDate.value
      ? `${rule.vestingDate.value.format(t('common:dateFormat'))}`
      : null
  }, [rule.vestingDate.value])

  return (
    <div className={styles.schedulingInfo}>
      {((rule.isRecurrent.value && rule.from.value) ||
        (!rule.isRecurrent.value && rule.vestingDate.value)) && (
        <div className={styles.labelContainer}>
          <Icon
            name={rule.isRecurrent.value ? 'CogClockwiseIcon' : 'ClockCheckOutlineIcon'}
            size={15}
            className={styles.icon}
          />
          <span className={styles.textLabel}>
            {rule.isRecurrent.value ? recurrentRule : nonRecurrentRule}
          </span>
        </div>
      )}
    </div>
  )
}

RuleViewItemScheduling.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
}

export default RuleViewItemScheduling
