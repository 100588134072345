import React from 'react'
import PropTypes from 'prop-types'
import MetaTitle from 'util/MetaTitle'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'presentation/PageTitle'

const RoleCreationTitle = ({ isViewMode, isEdition, name }) => {
  const { t } = useTranslation('rolesCreation')
  let pageTitle = t('titleCreation')

  if (isViewMode) {
    pageTitle = t('titleViewName', { name })
  } else if (isEdition) {
    pageTitle = t('titleEditionName', { name })
  }

  return (
    <>
      <MetaTitle pageTitle={pageTitle} />
      <PageTitle title={pageTitle} />
    </>
  )
}

RoleCreationTitle.propTypes = {
  isViewMode: PropTypes.bool,
  isEdition: PropTypes.bool,
  name: PropTypes.string,
}

RoleCreationTitle.defaultProps = {
  isViewMode: false,
  isEdition: false,
  name: '',
}

export default observer(RoleCreationTitle)
