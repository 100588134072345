import React from 'react'
import PropTypes from 'prop-types'
import { GlobalHotKeys, configure } from 'react-hotkeys'
import styles from './HotKeys.module.scss'

const HotKeys = ({ children, config }) => {
  configure({
    ignoreTags: [],
    defaultKeyEvent: 'keydown',
    ignoreKeymapAndHandlerChangesByDefault: false,
  })

  return (
    <GlobalHotKeys
      keyMap={{ ...config.map((e) => e.shortcut) }}
      handlers={{ ...config.map((e) => e.handler) }}
      allowChanges
    >
      <div className={styles.container}>{children}</div>
    </GlobalHotKeys>
  )
}

HotKeys.propTypes = {
  children: PropTypes.element.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  config: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default HotKeys
