import { useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import c from 'classnames'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import MenuDownIcon from 'mdi-react/MenuDownIcon'
import MenuUpIcon from 'mdi-react/MenuUpIcon'
import InputStore from 'stores/InputStore'
import LoadingRing from 'presentation/LoadingRing'
import styles from './mainSectionList.module.scss'

const MainSectionTable = observer(
  ({
    columns,
    component: Component,
    listItems,
    isLoading,
    view,
    onOrdered,
    t,
    moreFilters,
    setShowDropdownOrder,
    showDropdownOrder,
    orderInputStore,
  }) => {
    const [order, setOrder] = useState({ name: 'createdAt' })
    const [orderParameter, setOrderParameter] = useState('desc')

    const orderColumns = (column) => {
      // Asigna el orden para iconos y cosas visuales
      let orderParam = 'desc'

      if (column.name === order.name && orderParameter === 'desc') {
        orderParam = 'asc'
      }

      let realOrderParam = orderParam // Orden para el request

      // Si esta en la lista de campos invertido, cambia el valor para el request
      if (column.inverted) {
        if (orderParam === 'desc') {
          realOrderParam = 'asc'
        } else if (orderParam === 'asc') {
          realOrderParam = 'desc'
        }
      }

      if (orderInputStore) {
        orderInputStore.setValue({ column, orderParam })
      }
      setOrder(column)
      setOrderParameter(orderParam)
      onOrdered(column.name, realOrderParam)
    }

    const OrderColumns = []
    const columnsCssGridDefinition = []

    columns.forEach((col) => {
      if ((view === 'grid' || view === 'list') && !col.order) {
        return false
      }

      columnsCssGridDefinition.push(col.cssGridDefinition)

      OrderColumns.push(
        <div
          className={c(order.name === col.name && styles.selectedOrder)}
          onClick={(e) => {
            if (!col.order) {
              return false
            }

            e.stopPropagation()
            e.preventDefault()

            setShowDropdownOrder(false)

            if (onOrdered && !isLoading) {
              orderColumns(col)
            }

            return null
          }}
          key={col.name}
          role="button"
          tabIndex="0"
        >
          {col.transName}
          {col.order && (
            <div className={styles.orderArrow}>
              {order.name === col.name && (!orderParameter || orderParameter === 'asc') && (
                <MenuUpIcon size={20} />
              )}
              {(order.name !== col.name ||
                (order.name === col.name && orderParameter === 'desc')) && (
                <MenuDownIcon size={20} />
              )}
            </div>
          )}
        </div>
      )

      return null
    })

    return (
      <div className={styles[`table_${view}`]}>
        <div className={styles.containerFilters}>
          <div className={styles.titleAndOrder}>
            <div className={styles.moreFilters}>{moreFilters}</div>
            <div
              className={styles.order}
              onBlur={() => {
                setShowDropdownOrder(false)
              }}
            >
              <label>{t('common:sortBy')}</label>
              <div
                className={styles.orderBox}
                onClick={() => {
                  setShowDropdownOrder(!showDropdownOrder)
                }}
                role="button"
                tabIndex="0"
              >
                <div>{order ? t(`common:${order.name}`) : t('createdAt')}</div>
                <MenuDownIcon />
              </div>
            </div>
            <div
              className={styles.orderButton}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (onOrdered && !isLoading) {
                  orderColumns(order)
                }
              }}
              role="button"
              tabIndex="0"
            >
              {orderParameter === 'asc' && <SortAscendingIcon size={20} />}
              {(!orderParameter || orderParameter === 'desc') && <SortDescendingIcon size={20} />}
            </div>
            <div className={c(styles.dropdownOrder, showDropdownOrder && styles.showDropdown)}>
              {OrderColumns.map((col) => col)}
            </div>
          </div>
        </div>
        <div
          className={styles.tableDetailTitle}
          style={{ gridTemplateColumns: columnsCssGridDefinition.join(' ') }}
        >
          {OrderColumns.map((col) => col)}
        </div>
        {isLoading ? (
          <div className={styles.loading}>
            <LoadingRing />
          </div>
        ) : (
          <>
            <div className={styles.tableItems}>
              {listItems.map((listItem) => (
                <Component view={view} key={listItem?.id} listItem={listItem} />
              ))}
            </div>
          </>
        )}
      </div>
    )
  }
)

MainSectionTable.propTypes = {
  moreFilters: PropTypes.node,
  setShowDropdownOrder: PropTypes.func,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  listItems: PropTypes.oneOfType([PropTypes.array]),
  isLoading: PropTypes.bool,
  showDropdownOrder: PropTypes.bool,
  view: PropTypes.string,
  onOrdered: PropTypes.func,
  orderInputStore: PropTypes.instanceOf(InputStore),
}

MainSectionTable.defaultProps = {
  moreFilters: null,
  showDropdownOrder: false,
  setShowDropdownOrder: () => {},
  view: 'list',
  listItems: [],
  isLoading: true,
  onOrdered: null,
  orderInputStore: null,
}

export default MainSectionTable
