import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import TransactionsService from 'services/TransactionsService'
import Transaction from 'models/Transaction'
import FiduciaryStructurePreloadDataStore from 'stores/FiduciaryStructurePreloadDataStore'

const REQUIRED_FIELD = 'This is a required field'
class FiduciaryStructurePollsStore extends FiduciaryStructurePreloadDataStore {
  deleteTransaction = null
  transactionsList = []
  canOpenViewVote = false
  messageErrorServer = ''
  transactionsOptions = []
  visibleStoreModal = false
  haveTransacations = false
  finishedCloseVote = false
  visibleDeleteModal = false
  visibleRejectModal = false
  visibleApproveModal = false
  transactionsNewVote = []
  checkedSaveAndCreate = false
  visibleCreationModal = false
  transactionFromDashboard = []
  visibleVoteDeleteModal = false
  viewDashboardTransaction = false
  transactionIdFromDashboard = ''
  visibleWarningVotingModal = false
  showTransaction = new Transaction()
  canOpenTransactionFromDashboard = true

  constructor(fiduciaryStructureId, structure) {
    super(fiduciaryStructureId, structure)

    makeObservable(this, {
      // observables
      transactionsList: observable,
      canOpenViewVote: observable,
      messageErrorServer: observable,
      transactionsOptions: observable,
      visibleStoreModal: observable,
      haveTransacations: observable,
      finishedCloseVote: observable,
      visibleDeleteModal: observable,
      visibleRejectModal: observable,
      visibleApproveModal: observable,
      transactionsNewVote: observable,
      checkedSaveAndCreate: observable,
      visibleCreationModal: observable,
      transactionFromDashboard: observable,
      visibleVoteDeleteModal: observable,
      viewDashboardTransaction: observable,
      transactionIdFromDashboard: observable,
      visibleWarningVotingModal: observable,
      showTransaction: observable,
      canOpenTransactionFromDashboard: observable,
      // actions
      init: action,
      reloadList: action,
      loadList: action,
      deleteTransactionSelected: action,
      approveTransaction: action,
      rejectTransaction: action,
      storeTransactions: action,
      createTransaction: action,
      resolutionTransaction: action,
      resolveTransactionsComplexResolution: action,
      resetTransationslist: action,
      resetShowTransaction: action,
      setFinishedCloseVote: action,
      showErrorModal: action,
      hideErrorModal: action,
      resetMessageErrorServer: action,
      showDeleteModal: action,
      hideDeleteModal: action,
      closeVoteDeleteModal: action,
      showApproveModal: action,
      closeApproveModal: action,
      showRejectModal: action,
      closeRejectModal: action,
      showStoreModal: action,
      closeStoreModal: action,
      showCreationModal: action,
      hideCreationModal: action,
      setTransactionIdFromDashboard: action,
      setViewDashboardTransaction: action,
      setCanOpenTransactionFromDashboard: action,
      findTransaction: action,
      setShowTransaction: action,
      setDeleteTransaction: action,
      setListVotingIsView: action,
      toggleCheckedSaveAndCreate: action,
      validateTransaction: action,
      // computeds
      releaseJson: computed,
    })

    this.fiduciaryStructureId = fiduciaryStructureId
    this.transactionsService = new TransactionsService()
    this.init()
  }

  init() {
    this.loadList(this.fiduciaryStructureId)
  }

  reloadList() {
    this.transactions = []
    this.loadList(this.fiduciaryStructureId)
  }

  async loadList() {
    this.preRequest(() => this.loadList(this.fiduciaryStructureId))

    try {
      const response = await this.transactionsService.listTrustTransactions(
        this.fiduciaryStructureId
      )

      runInAction(() => {
        this.transactionsList = response

        if (this.transactionIdFromDashboard && this.canOpenTransactionFromDashboard) {
          this.findTransaction(this.transactionIdFromDashboard)
        }

        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async deleteTransactionSelected() {
    this.resetMessageErrorServer()

    this.preRequest(() => this.deleteTransactionSelected(this.deleteTransaction))

    try {
      await this.transactionsService.deleteTransaction(this.deleteTransaction.id)

      runInAction(() => {
        this.reloadList()
        this.onSuccessRequest()
      })
    } catch (e) {
      const [errorMessage] = e.response.data.error.errors
      this.messageErrorServer = errorMessage
      this.showErrorModal()
      this.onErrorRequest(e)
    }
  }

  async approveTransaction(transactionsApprove) {
    this.resetMessageErrorServer()

    let transactionFormat = []

    if (typeof transactionsApprove === 'string') {
      transactionFormat.push(transactionsApprove)
    }
    if (typeof transactionsApprove !== 'string') {
      transactionFormat = transactionsApprove
    }

    this.preRequest(() => this.approveTransaction(transactionFormat))

    try {
      await this.transactionsService.approveTransaction(transactionFormat)

      runInAction(() => {
        this.resetTransationslist()
        this.reloadList()
        this.onSuccessRequest()
      })
    } catch (e) {
      const [errorMessage] = e.response.data.error.errors
      this.messageErrorServer = errorMessage
      this.showErrorModal()
      this.onErrorRequest(e)
    }
  }

  async rejectTransaction(transactionsReject) {
    this.resetMessageErrorServer()

    let transactionFormat = []

    if (typeof transactionsReject === 'string') {
      transactionFormat.push(transactionsReject)
    }
    if (typeof transactionsReject !== 'string') {
      transactionFormat = transactionsReject
    }

    this.preRequest(() => this.rejectTransaction(transactionFormat))

    try {
      await this.transactionsService.rejectTransaction(transactionFormat)

      runInAction(() => {
        this.resetTransationslist()
        this.reloadList()
        this.onSuccessRequest()
      })
    } catch (e) {
      const [errorMessage] = e.response.data.error.errors
      this.messageErrorServer = errorMessage
      this.showErrorModal()
      this.onErrorRequest(e)
    }
  }

  async storeTransactions() {
    this.preRequest(() => this.storeTransactions)

    try {
      await this.transactionsService.storeTransactions(this.fiduciaryStructureId)

      runInAction(() => {
        this.reloadList()
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async createTransaction() {
    if (this.validateTransaction()) {
      this.preRequest()
      this.showTransaction.setFiduciaryStructureId(this.fiduciaryStructureId)
      try {
        await this.transactionsService.saveTransaction(this.showTransaction.getJson())

        runInAction(() => {
          this.reloadList()
          this.resetShowTransaction()
          this.hideCreationModal()
          this.onSuccessRequest()
        })
      } catch (e) {
        this.onErrorRequest(e)
      }
    }

    return false
  }

  async resolutionTransaction(vote, transactionsResolve) {
    this.setFinishedCloseVote(false)
    this.resetMessageErrorServer()
    const data = {
      status: 'complexResolution',
      resolution: 'complex Resolution',
    }

    try {
      await this.transactionsService.closeVoting(vote.id, data)

      runInAction(() => {
        this.resolveTransactionsComplexResolution(transactionsResolve)

        this.init()
        this.setFinishedCloseVote(true)
      })
    } catch (e) {
      if (e.response) {
        const [errorMessage] = e.response.data.error.errors
        this.messageErrorServer = errorMessage
      }
      this.onErrorRequest(e)
    }
  }

  resolveTransactionsComplexResolution(transactionsResolve) {
    const transactionsApprove = []
    const transactionsReject = []
    this.transactionsNewVote = []

    transactionsResolve.forEach((complexResolution) => {
      const { transaction } = complexResolution

      if (complexResolution.isApproved) {
        transactionsApprove.push(transaction.id)
      }

      if (complexResolution.isRejected) {
        transactionsReject.push(transaction.id)
      }

      if (complexResolution.isNewVote) {
        this.transactionsNewVote.push(transaction)
      }
    })

    if (transactionsApprove.length > 0) {
      this.approveTransaction(transactionsApprove)
    }

    if (transactionsReject.length > 0) {
      this.rejectTransaction(transactionsReject)
    }
  }

  get releaseJson() {
    const json = { transactions: [] }
    this.transactions.forEach((transaction) => json.transactions.push(transaction.id))

    return json
  }

  resetTransationslist() {
    this.transactionsList = []
  }

  resetShowTransaction() {
    this.showTransaction = new Transaction()
  }

  setFinishedCloseVote(value) {
    this.finishedCloseVote = value
  }

  showErrorModal() {
    this.visibleErrorModal = true
  }

  hideErrorModal() {
    this.visibleErrorModal = false
  }

  resetMessageErrorServer() {
    this.messageErrorServer = ''
  }

  showDeleteModal() {
    this.visibleDeleteModal = true
  }

  hideDeleteModal() {
    this.visibleDeleteModal = false
  }

  closeVoteDeleteModal() {
    this.visibleVoteDeleteModal = false
  }

  showApproveModal() {
    this.visibleApproveModal = true
  }

  closeApproveModal() {
    this.visibleApproveModal = false
  }

  showRejectModal() {
    this.visibleRejectModal = true
  }

  closeRejectModal() {
    this.visibleRejectModal = false
  }

  showStoreModal() {
    this.visibleStoreModal = true
  }

  closeStoreModal() {
    this.visibleStoreModal = false
  }

  showCreationModal() {
    this.visibleCreationModal = true
  }

  hideCreationModal() {
    this.visibleCreationModal = false
  }

  setTransactionIdFromDashboard(transactionId) {
    this.transactionIdFromDashboard = transactionId
  }

  setViewDashboardTransaction(value) {
    this.viewDashboardTransaction = value
  }

  setCanOpenTransactionFromDashboard(value) {
    this.canOpenTransactionFromDashboard = value
  }

  findTransaction(transactionId) {
    this.transactionFromDashboard = this.transactionsList.filter(
      (allTransaction) => transactionId === allTransaction.id
    )
    this.setViewDashboardTransaction(true)
  }

  setShowTransaction(value) {
    this.showTransaction = value
  }

  setDeleteTransaction(value) {
    this.deleteTransaction = value
  }

  setListVotingIsView(value) {
    this.listVotingIsView = value
  }

  toggleCheckedSaveAndCreate() {
    this.checkedSaveAndCreate = !this.checkedSaveAndCreate
  }

  validateTransaction() {
    const { date, additionalData, name, requiresVoting, approvalRule } = this.showTransaction
    let hasErrors = false

    this.clearErrors()

    if (!date.value) {
      date.setError(true, REQUIRED_FIELD)

      hasErrors = true
    }

    if (!additionalData.description.value) {
      additionalData.description.setError(true, REQUIRED_FIELD)

      hasErrors = true
    }

    if (!name.value) {
      name.setError(true, REQUIRED_FIELD)

      hasErrors = true
    }

    if (requiresVoting && !approvalRule.value) {
      approvalRule.setError(true, REQUIRED_FIELD)

      hasErrors = true
    }

    return !hasErrors
  }

  clearErrors() {
    const { date, additionalData, name, approvalRule } = this.showTransaction

    date.clearError()
    name.clearError()
    approvalRule.clearError()
    additionalData.description.clearError()
  }
}

export default FiduciaryStructurePollsStore
