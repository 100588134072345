import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PencilPlusIcon from 'mdi-react/PencilPlusIcon'
import FileCancelIcon from 'mdi-react/FileCancelIcon'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import LockResetIcon from 'mdi-react/LockResetIcon'
import HistoryIcon from 'mdi-react/HistoryIcon'
import ExpandableButton from 'presentation/ExpandableButton'
import ButtonWithHelp from 'presentation/ButtonWithHelp'
import Letter from 'models/Letters'
import styles from './actionButtons.module.scss'

const ButtonDelete = ({ onClick, letterTypeName, revoke }) => {
  const { t } = useTranslation('lettersForms')

  return (
    <ButtonWithHelp
      buttonText={revoke ? t('revoke') : t('common:delete')}
      tooltipText={revoke ? t('helpRevoke', { letterTypeName }) : t('helpDeleteDraftDocu')}
      Icon={revoke ? FileCancelIcon : TrashCanOutlineIcon}
      handleClick={onClick}
      expandableButtonClassname={styles.actionButtonRemove}
    />
  )
}

ButtonDelete.propTypes = {
  onClick: PropTypes.func.isRequired,
  letterTypeName: PropTypes.string.isRequired,
  revoke: PropTypes.bool,
}

ButtonDelete.defaultProps = {
  revoke: false,
}

const ButtonNew = ({ onClick, letterTypeName }) => {
  const { t } = useTranslation('lettersForms')

  return (
    <ButtonWithHelp
      buttonText={t('newLetter', { letterTypeName })}
      tooltipText={t('helpNewLetter', { letterTypeName })}
      Icon={PencilPlusIcon}
      handleClick={onClick}
    />
  )
}

ButtonNew.propTypes = {
  onClick: PropTypes.func.isRequired,
  letterTypeName: PropTypes.string.isRequired,
}

const ButtonVersions = ({ onClick }) => {
  const { t } = useTranslation('lettersForms')

  return (
    <ExpandableButton
      onClick={onClick}
      label={t('storedHistory')}
      Icon={<LockResetIcon size={20} />}
      big
    />
  )
}

ButtonVersions.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const ButtonHistory = ({ onClick }) => {
  const { t } = useTranslation('lettersForms')

  return (
    <ExpandableButton
      onClick={onClick}
      label={t('draftHistory')}
      Icon={<HistoryIcon size={20} />}
      big
    />
  )
}

ButtonHistory.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const ActionButtons = ({
  letter,
  handleDeleteDraft,
  handleRevokeLetter,
  handleViewHistory,
  handleNewDraft,
  handleViewVersions,
  viewMode,
  showCreateLetter,
  letterTypeName,
}) => {
  const buttons = []

  if (letter.isDraft) {
    if (!viewMode) {
      buttons.push(
        <ButtonDelete
          key="deleteButton"
          onClick={handleDeleteDraft}
          letterTypeName={letterTypeName}
        />
      )
    }

    if (letter.id !== null) {
      buttons.push(<ButtonHistory key="historyButton" onClick={handleViewHistory} />)
    }
  }

  if (letter.isActive) {
    if (!viewMode && handleRevokeLetter) {
      buttons.push(
        <ButtonDelete
          letterTypeName={letterTypeName}
          onClick={handleRevokeLetter}
          key="deleteButton"
          revoke
        />
      )
    }

    if (!viewMode && showCreateLetter) {
      buttons.push(
        <ButtonNew key="newButton" onClick={handleNewDraft} letterTypeName={letterTypeName} />
      )
    }

    if (handleViewVersions) {
      buttons.push(<ButtonVersions key="historyButton" onClick={handleViewVersions} />)
    }
  }

  return <div className={styles.buttonsContainer}>{buttons}</div>
}

ActionButtons.propTypes = {
  letter: PropTypes.instanceOf(Letter).isRequired,
  handleDeleteDraft: PropTypes.func.isRequired,
  handleRevokeLetter: PropTypes.func,
  handleViewHistory: PropTypes.func.isRequired,
  letterTypeName: PropTypes.string.isRequired,
  handleNewDraft: PropTypes.func,
  handleViewVersions: PropTypes.func,
  viewMode: PropTypes.bool,
  showCreateLetter: PropTypes.bool,
}

ActionButtons.defaultProps = {
  handleViewVersions: null,
  viewMode: false,
  showCreateLetter: false,
  handleRevokeLetter: null,
  handleNewDraft: null,
}

export default observer(ActionButtons)
