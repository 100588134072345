import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import styles from './documentDate.module.scss'

const DocumentDate = ({ lastUpdate, firsName, lastName, active, deleted }) => {
  const { t } = useTranslation('common')

  if (lastUpdate === null || lastUpdate === '') {
    return <div className={styles.date}>{t('notSavedYet')}</div>
  }

  return (
    <div className={c(styles.date, active && styles.cardActive, deleted && styles.cardDeleted)}>
      <span>{t('lastUpdatedOn')}</span>
      <span className={styles.dateCaps}>{lastUpdate.format(t('humanDateTimeYearFormat'))}</span>
      {t('by')}
      <span className={styles.dateCaps}>
        {firsName}
        {lastName}
      </span>
    </div>
  )
}

DocumentDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lastUpdate: PropTypes.any,
  firsName: PropTypes.string,
  lastName: PropTypes.string,
  active: PropTypes.bool,
  deleted: PropTypes.bool,
}

DocumentDate.defaultProps = {
  lastUpdate: null,
  firsName: null,
  lastName: null,
  active: false,
  deleted: false,
}

export default DocumentDate
