import React, { useRef } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import FloatingBar from './FloatingBar'
import styles from './pageTitle.module.scss'

const PageTitle = ({ title, containerClass, rightSection, subtitle, floating, tabRef }) => {
  const ref = useRef(null)

  return (
    <>
      <FloatingBar
        enabled={floating}
        title={tabRef ? tabRef.getSelected().title : title}
        contentRef={ref}
        rightSection={rightSection}
      />
      <div
        ref={ref}
        className={c(
          styles.pageTitleContainer,
          rightSection && styles.hasRightSection,
          containerClass
        )}
      >
        {!subtitle ? (
          <h1 className={styles.title}>{title}</h1>
        ) : (
          <h2 className={styles.subtitle}>{title}</h2>
        )}
        {rightSection && <div className={styles.rightSection}>{rightSection}</div>}
      </div>
    </>
  )
}

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tabRef: PropTypes.oneOfType([PropTypes.object]),
  containerClass: PropTypes.string,
  rightSection: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  subtitle: PropTypes.bool,
  floating: PropTypes.bool,
}

PageTitle.defaultProps = {
  containerClass: '',
  rightSection: null,
  subtitle: false,
  floating: false,
  tabRef: null,
}

export default PageTitle
