import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { DASHBOARD } from 'routing/routes'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import image404 from './404.png'

const NotFound404 = () => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const handleGoBack = useCallback(() => history.push(DASHBOARD), [])

  return (
    <GenericNotFoundError
      description={t('error404Msg')}
      textButton={t('goToHome')}
      goBackFunction={handleGoBack}
      title={t('error404')}
      image={image404}
    />
  )
}

export default NotFound404
