import React, { useEffect, useState, useContext, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import MetaTitle from 'util/MetaTitle'
import AlertIcon from 'mdi-react/AlertIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import {
  CREATE_TRUST,
  TRANSACTIONS_LIST,
  TRUST_USERS_VIEW,
  ZIP_FILE,
  TRUST_VALUATIONS,
} from 'stores/AuthStore'
import {
  TRUSTS_CREATION,
  TRUSTS_HISTORY,
  FIDUCIARY_STRUCTURE_TRANSACTIONS,
  trustsCreationRoute,
  TRUSTS_VALUATIONS,
} from 'routing/routes'
import StoresContext from 'providers/storeContext'
import Modal from 'scenes/Modal'
import { STATUS_STORED_ALTERNATIVE } from 'presentation/StatusFilter/filterStatus'
import LoadingRing from 'presentation/LoadingRing'
import Button from 'presentation/Button'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import StatusFilter from 'presentation/StatusFilter'
import FiduciaryStructuresTableItem from 'presentation/FiduciaryStructuresTableItem'
import TrustsListStore from './TrustsListStore'
import styles from './trustsList.module.scss'

const TrustsList = ({ location }) => {
  const [trustsListStore] = useState(() => new TrustsListStore())
  const { t } = useTranslation('trusts')
  const [columns, setColumns] = useState([])
  const { settingsStore, hotkeyStore, authStore } = useContext(StoresContext)
  const history = useHistory()

  // falta agregar a la condicion || y que verifique si tiene permiso para usersTrust
  const hasActions =
    authStore.can(TRANSACTIONS_LIST) ||
    authStore.can(TRUST_USERS_VIEW) ||
    authStore.can(ZIP_FILE) ||
    authStore.can(TRUST_VALUATIONS)

  const configureHotKeys = () => {
    hotkeyStore.setConfig('trustList', [
      {
        shortcut: 'alt+c',
        handler: () => {
          history.push(TRUSTS_CREATION)
        },
      },
    ])
  }

  const handleChangeFilter = useCallback((status) => {
    trustsListStore.setSelectedStatusFilter(
      status === 'storedAlternative' ? STATUS_STORED_ALTERNATIVE : status
    )

    if (trustsListStore.selectedStatusFilter) {
      trustsListStore.setIsFiltered(true)
    } else {
      trustsListStore.setIsFiltered(false)
    }

    trustsListStore.loadTrusts(
      null,
      null,
      trustsListStore.orderDirection,
      trustsListStore.selectedStatusFilter,
      true
    )
  }, [])

  useEffect(() => {
    configureHotKeys()

    const visibleColumns = [
      {
        name: 'trustName',
        order: true,
        inverted: true,
        transName: t('name'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'settlorName',
        order: false,
        inverted: true,
        transName: t('settlorName', {
          what: settingsStore.isGrantor ? t('grantor') : t('settlor'),
        }),
        cssGridDefinition: '1fr',
      },
      {
        name: 'createdAt',
        order: true,
        inverted: false,
        transName: t('common:createdAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'status',
        order: false,
        inverted: false,
        transName: t('common:status'),
        cssGridDefinition: '1fr',
      },
    ]

    if (hasActions) {
      visibleColumns.push({
        name: 'actions',
        order: false,
        inverted: false,
        transName: t('common:actions'),
        cssGridDefinition: '1fr',
      })
    }

    setColumns(visibleColumns)

    const params = new URLSearchParams(location.search)

    if (params.get('status')) {
      handleChangeFilter(params.get('status'))
      trustsListStore.setSelectedStatusFilter(trustsListStore.selectedStatusFilter)
    } else {
      trustsListStore.loadTrusts()
    }

    return () => hotkeyStore.removeConfig('trustList')
  }, [])

  const handlePageChange = useCallback((pageTo) => trustsListStore.changeActivePage(pageTo), [])
  const handleCreateTrust = useCallback(() => history.push(TRUSTS_CREATION), [])
  const handleSearch = useCallback(
    (value) =>
      trustsListStore.loadTrusts(
        value,
        null,
        trustsListStore.orderDirection,
        trustsListStore.selectedStatusFilter,
        true
      ),
    []
  )
  const handleChangeOrder = useCallback((orderParamter, orderDirection) => {
    trustsListStore.setOrderDirection(orderDirection)

    trustsListStore.loadTrusts(
      null,
      orderParamter.includes('trust')
        ? orderParamter.replace('trust', '').toLowerCase()
        : orderParamter,
      trustsListStore.orderDirection,
      trustsListStore.selectedStatusFilter,
      true
    )
  }, [])

  const handleTransactionsLink = useCallback(
    (trustId) => history.push(`${FIDUCIARY_STRUCTURE_TRANSACTIONS}?id=${trustId}`),
    []
  )

  const handleValuationsLink = useCallback(
    (trustId) => history.push(`${TRUSTS_VALUATIONS}?id=${trustId}`),
    []
  )

  const handleHistoryLink = useCallback((trustId) => {
    history.push(`${TRUSTS_HISTORY}?id=${trustId}`)
  }, [])

  const handleSuccessZipModal = useCallback(() => trustsListStore.hideZipModal(), [])

  const handleOpenDownloadsModal = useCallback((trust) => {
    trustsListStore.openZipModal()
    trustsListStore.setSelectedTrustForDownload(trust)
  }, [])

  const handleRequestZip = useCallback((trust) => {
    trustsListStore.setIsZipRequested(true)
    trustsListStore.requestZip(trust)
  }, [])

  const handlePermissionLink = useCallback(
    (trustId) => history.push(`${trustsCreationRoute(trustId)}`),
    []
  )

  const handleSetShowDropdownOrder = useCallback((value) => {
    trustsListStore.setShowDropdownOrder(value)
  }, [])

  const handleSetShowDropdownFilter = useCallback((value) => {
    trustsListStore.setShowDropdownFilter(value)
  }, [])

  const handleRequestFiduciaryChartWithPeople = useCallback(() => {
    trustsListStore.requestFiduciaryChartWithPeople()
  }, [])

  const handleRequestFiduciaryChartNoPeople = useCallback(() => {
    trustsListStore.requestFiduciaryChartNoPeople()
  }, [])

  const modalText = () => {
    if (trustsListStore.zipModalSuccess) {
      return (
        <>
          <span>
            <CheckIcon size={20} className={styles.checkIcon} />
          </span>
          {t('zipSuccessfulModalMessage')}
        </>
      )
    }

    if (trustsListStore.zipModalMessage !== '') {
      return (
        <>
          <span>
            <AlertIcon size={20} className={styles.alertIcon} />
          </span>
          {trustsListStore.zipModalMessage}
        </>
      )
    }

    return null
  }

  return (
    <>
      <MetaTitle pageTitle={t('pageTitles:trusts')} />
      <MainSectionList
        setShowDropdownFilter={handleSetShowDropdownFilter}
        setShowDropdownOrder={handleSetShowDropdownOrder}
        sectionItems={trustsListStore.visibleTrusts}
        hasFilterStatus={trustsListStore.isFiltered}
        currentPage={trustsListStore.activePage}
        isLoading={trustsListStore.isLoading}
        handleChangeOrder={handleChangeOrder}
        handlePageChange={handlePageChange}
        totalPages={trustsListStore.pages}
        handleCreation={handleCreateTrust}
        creationPermission={CREATE_TRUST}
        sectionNameSingular={t('trust')}
        sectionNamePlural={t('trusts')}
        creationRoute={TRUSTS_CREATION}
        handleSearch={handleSearch}
        tableComponent={
          <MainSectionTable
            t={t}
            moreFilters={
              <StatusFilter
                selectedFromDashboard={trustsListStore.selectedStatusFilter}
                showDropdownFilter={trustsListStore.showDropdownFilter}
                setShowDropdownFilter={handleSetShowDropdownFilter}
                handleChangeFilter={handleChangeFilter}
              />
            }
            showDropdownOrder={trustsListStore.showDropdownOrder}
            setShowDropdownOrder={handleSetShowDropdownOrder}
            listItems={trustsListStore.visibleTrusts}
            isLoading={trustsListStore.isLoading}
            onOrdered={handleChangeOrder}
            columns={columns}
            view="list"
            component={({ listItem, view: cView }) => (
              <FiduciaryStructuresTableItem
                requestedZip={trustsListStore.isRequestedZip(listItem.id)}
                link={`${TRUSTS_CREATION}?id=${listItem.id}&v=true`}
                handleTransactionsLink={handleTransactionsLink}
                handlePermissionLink={handlePermissionLink}
                handleValuationsLink={handleValuationsLink}
                handleHistoryLink={handleHistoryLink}
                isGrantor={settingsStore.isGrantor}
                handleRequestZip={handleOpenDownloadsModal} // handleRequestZip
                hasActions={hasActions}
                listItem={listItem}
                view={cView}
              />
            )}
          />
        }
      />
      <Modal
        noButtons={trustsListStore.isLoadingZip}
        open={trustsListStore.visibleZipModal}
        onSuccess={handleSuccessZipModal}
        onCancel={trustsListStore.hideZipModal}
        title={t('zipModalTitle')}
        name="zipModal"
        okOnly
        close
      >
        <div className={styles.downloadModalContainer}>
          <div className={styles.modalSubtitleContainer}>
            <h3 className={styles.modalSubtitle}>{t('requestZipTitle')}</h3>
            <Button transparent label={t('requestZip')} onClick={() => handleRequestZip()} />
          </div>
          <div className={styles.modalSubtitleContainer}>
            <h3 className={styles.modalSubtitle}>{t('requestFiduciaryChartTitle')}</h3>
            <Button
              label={t('requestFiduciaryChartWithPeople')}
              transparent
              onClick={() => handleRequestFiduciaryChartWithPeople()}
            />
            <Button
              label={t('requestFiduciaryChartNoPeople')}
              transparent
              className={styles.modalButton}
              onClick={() => handleRequestFiduciaryChartNoPeople()}
            />
          </div>
          {trustsListStore.isLoadingZip ? (
            <LoadingRing small center />
          ) : (
            <div className={styles.textContainer}>{modalText()}</div>
          )}
        </div>
      </Modal>
    </>
  )
}

TrustsList.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default observer(TrustsList)
