import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import c from 'classnames'
import LockIcon from 'mdi-react/LockIcon'
import { useSwipeable } from 'react-swipeable'
import { observer } from 'mobx-react'
import { TRANSACTIONS_DELETE, TRANSACTIONS_APPROVE, TRANSACTIONS_REJECT } from 'stores/AuthStore'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import TransactionStatus from 'presentation/TransactionStatus'
import Button from 'presentation/Button'
import Gated from 'util/Gated'
import Transaction from 'models/Transaction'
import styles from './transactionItem.module.scss'

const TransactionItem = ({
  transaction,
  handleRejectTransaction,
  handleApproveTransaction,
  handleOpenViewTransaction,
  handleModalDeleteTransaction,
}) => {
  const { t } = useTranslation('transactionsCreation')
  const [showDelete, setShowDelete] = useState(false)
  const editTransactionHandler = useCallback(() => handleOpenViewTransaction(transaction), [])
  const deleteTransactionHandler = useCallback(() => handleModalDeleteTransaction(transaction), [])
  const rejectTransactionHandler = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    handleRejectTransaction(transaction)
  }, [])

  const votingApproveTransactionHandler = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    handleApproveTransaction(transaction)
  }, [])

  const { storedAt } = transaction.setStoredAt()

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => setShowDelete(true),
    onSwipedRight: () => setShowDelete(false),
  })

  return (
    <div className={styles.container} {...swipeableHandlers}>
      <div className={styles.wrapper} onClick={editTransactionHandler} role="button" tabIndex="0">
        <div>
          <div className={c(styles.date, storedAt && styles.dateStored)}>
            <span className={c(storedAt && styles.spanInformation)}>
              {storedAt && <LockIcon size={12} />}
            </span>
            <span className={styles.spanInformation}>{storedAt ? t('stored') : t('created')}</span>
            <span className={styles.spanInformation}>{t('on')}</span>
            <span className={styles.spanInformation}>
              {transaction.lastUpdate.format(t('common:humanDateDayFormat'))}
            </span>
            <span className={styles.spanInformation}>{t('by')}</span>
            <span className={styles.spanInformation}>{transaction.updatedByFirstName}</span>
            <span className={styles.spanInformation}>{transaction.updatedByLastName}</span>
          </div>
          <div className={styles.itemName}>{transaction.name.value}</div>
          {transaction.status.status === 'pending' && (
            <div className={styles.containerButtonsVotes}>
              <Button
                small
                smallest
                transparent
                label={t('approve')}
                className={styles.votingIcon}
                permission={TRANSACTIONS_APPROVE}
                onClick={votingApproveTransactionHandler}
              />
              <Button
                small
                smallest
                transparent
                label={t('reject')}
                className={styles.votingIcon}
                permission={TRANSACTIONS_REJECT}
                onClick={rejectTransactionHandler}
              />
            </div>
          )}
        </div>
        <div>
          <TransactionStatus status={transaction.status} />
        </div>
      </div>
      {transaction.status.status === 'pending' && (
        <Gated permission={TRANSACTIONS_DELETE}>
          <div
            onClick={deleteTransactionHandler}
            className={c(
              styles.tableRowDelete,
              transaction.canDelete && styles.showDelete,
              showDelete && styles.showSwipeDelete
            )}
            role="button"
            tabIndex="0"
          >
            <TrashCanOutlineIcon size={20} />
          </div>
        </Gated>
      )}
    </div>
  )
}

TransactionItem.propTypes = {
  handleRejectTransaction: PropTypes.func.isRequired,
  handleApproveTransaction: PropTypes.func.isRequired,
  handleOpenViewTransaction: PropTypes.func.isRequired,
  handleModalDeleteTransaction: PropTypes.func.isRequired,
  transaction: PropTypes.instanceOf(Transaction).isRequired,
}

export default observer(TransactionItem)
