import React from 'react'
import PropTypes from 'prop-types'
import styles from './freezeModalList.module.scss'

const FreezeModalList = ({ children }) => <ul className={styles.list}>{children}</ul>

FreezeModalList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FreezeModalList
