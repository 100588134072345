import { makeAutoObservable } from 'mobx'
import ComplexResolutionTransaction from 'models/ComplexResolutionTransaction'

class ComplexResolutionModalStore {
  vote = null
  transactions = []
  messageErrorServer = ''
  missingSelection = false
  visibleErrorModal = false

  constructor() {
    makeAutoObservable(this)
  }

  processVote(vote) {
    this.transactions = []

    if (vote) {
      vote.transactions.value.forEach((transaction) =>
        this.transactions.push(new ComplexResolutionTransaction(transaction))
      )
    }
  }

  hasEmptyTransactions() {
    return this.transactions.filter((transaction) => transaction.isEmpty).length > 0
  }

  setMissingSelection(value) {
    this.missingSelection = value
  }

  setVote(vote) {
    this.vote = vote
  }
}

export default ComplexResolutionModalStore
