import { makeAutoObservable } from 'mobx'

const APPROVED = 'approved'
const REJECTED = 'rejected'
const NEW_VOTE = 'newVote'

class ComplexResolutionTransaction {
  constructor(transaction) {
    this.transaction = transaction
    this.selection = null

    makeAutoObservable(this)
  }

  setApproved() {
    this.selection = APPROVED
  }

  setRejected() {
    this.selection = REJECTED
  }

  setNewVote() {
    this.selection = NEW_VOTE
  }

  get isApproved() {
    return this.selection === APPROVED
  }

  get isRejected() {
    return this.selection === REJECTED
  }

  get isNewVote() {
    return this.selection === NEW_VOTE
  }

  get isEmpty() {
    return this.selection === null
  }
}

export default ComplexResolutionTransaction
