import axios from 'axios'
import { API_URL } from 'services/config'
import RulesRestriction from 'models/RulesRestriction/RulesRestriction'

/* eslint-disable class-methods-use-this */
class RulesService {
  getByTrustId = (trustId, alternativeStatus) =>
    axios
      .get(
        `${API_URL}/tenant/trusts/${trustId}/rules?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)

  saveDeedOfTrustRule = (trustId, json) =>
    axios
      .post(`${API_URL}/tenant/trusts/${trustId}/rules`, json)
      .then((response) => response.data.data)

  saveLetterOfWishesRule = (trustId, json) =>
    axios
      .post(`${API_URL}/tenant/trusts/${trustId}/rules`, json)
      .then((response) => response.data.data)

  saveAmendmentRule = (trustId, json) =>
    axios
      .post(`${API_URL}/tenant/trusts/${trustId}/rules`, json)
      .then((response) => response.data.data)

  saveOtherDocumentsRule = (trustId, json) =>
    axios
      .post(`${API_URL}/tenant/trusts/${trustId}/rules`, json)
      .then((response) => response.data.data)

  loadRestrictions = (trustId) =>
    axios
      .get(`${API_URL}/tenant/trusts/${trustId}/configurations`)
      .then((response) => response.data.data.assets)
      .then((restrictions) =>
        restrictions.map((restriction) => {
          return RulesRestriction.fromJson(restriction)
        })
      )

  saveRestrictions = (trustId, restrictedTypes) =>
    axios.post(`${API_URL}/tenant/trusts/${trustId}/configurations`, restrictedTypes)
}

export default RulesService
