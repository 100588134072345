import { observable, makeObservable } from 'mobx'
import moment from 'moment'
import UserStatus from 'models/UserStatus'

class User {
  selectedRoles = []

  constructor(
    id,
    name,
    firstName,
    lastName,
    email,
    created,
    updated,
    status,
    roles,
    profileImage,
    isActive,
    tenantUser
  ) {
    this.id = id
    this.name = name
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.created = created
    this.updated = updated
    this.profileImage = profileImage
    this.status = new UserStatus(status)
    this.roles = roles
    this.isActive = isActive
    this.tenantUser = tenantUser

    makeObservable(this, {
      selectedRoles: observable,
    })
  }

  static fromJson({
    id,
    firstName,
    lastName,
    email,
    created,
    updated,
    status,
    roles,
    profileImage,
    isActive,
    isSuperAdmin,
  }) {
    return new User(
      id,
      `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      /* ListDate.fromJson(created),
      ListDate.fromJson(updated), */
      moment(created),
      moment(updated),
      isActive ? status : 'pending',
      roles,
      profileImage ? profileImage[0] : null,
      isActive,
      isSuperAdmin
    )
  }
}

export default User
