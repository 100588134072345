import { observable, action, runInAction, makeObservable } from 'mobx'
import BlockchainVotingService from 'services/BlockchainVotingService'
import InputStore from 'stores/InputStore'
import Vote from 'models/Vote'
import TransactionsService from 'services/TransactionsService'
import FiduciaryStructurePreloadDataStore from 'stores/FiduciaryStructurePreloadDataStore'

const REQUIRED_FIELD = 'This is a required field'

class FiduciaryStructureVotesStore extends FiduciaryStructurePreloadDataStore {
  votes = []
  activeTab = 0
  vote = new Vote()
  isEdition = false
  inputsSelect = []
  possibleVoters = []
  transactionErrors = 0
  messageErrorServer = ''
  transactionsNewVote = []
  votersCanAbstain = false
  visibleErrorModal = false
  transactionsOptions = []
  showVoteTransaction = null
  isComplexResolution = false
  votersInformationErrors = 0
  generalInformationErrors = 0
  warningVoteInProgress = false
  votingResult = new InputStore()
  visibleModalCreationVote = false
  votersSelected = new InputStore()
  visibleResolveVotingModal = false
  visibleCreationVotingModal = false
  showVoteTransactionEditable = false
  visibleComplexResolutionModal = false
  votingResultSelected = new InputStore()
  transactionNameSelect = new InputStore()
  visibleWarningVotingModalInProcess = false

  constructor(fiduciaryStructureId, structure) {
    super(fiduciaryStructureId, structure)

    makeObservable(this, {
      // observables
      votes: observable,
      activeTab: observable,
      vote: observable,
      isEdition: observable,
      inputsSelect: observable,
      possibleVoters: observable,
      transactionErrors: observable,
      messageErrorServer: observable,
      transactionsNewVote: observable,
      votersCanAbstain: observable,
      visibleErrorModal: observable,
      transactionsOptions: observable,
      showVoteTransaction: observable,
      isComplexResolution: observable,
      votersInformationErrors: observable,
      generalInformationErrors: observable,
      warningVoteInProgress: observable,
      votingResult: observable,
      visibleModalCreationVote: observable,
      votersSelected: observable,
      visibleResolveVotingModal: observable,
      visibleCreationVotingModal: observable,
      showVoteTransactionEditable: observable,
      visibleComplexResolutionModal: observable,
      votingResultSelected: observable,
      transactionNameSelect: observable,
      visibleWarningVotingModalInProcess: observable,
      // actions
      init: action,
      setShowVote: action,
      loadListVotes: action,
      saveVote: action,
      approveTransaction: action,
      resetMessageErrorServer: action,
      setMessageErrorServer: action,
      rejectTransaction: action,
      loadPossibleVoters: action,
      getBlockChainInformation: action,
      loadListTransactionsForSelect: action,
      closeVotingResolution: action,
      setDefaultSelectedTransactions: action,
      resetInputSelect: action,
      setVotersSelected: action,
      removeInputSelect: action,
      createNewInputSelect: action,
      setVoteTransactions: action,
      resetResultSelected: action,
      setVotingResult: action,
      resetVotesList: action,
      validateVotersInProgress: action,
      setRarningVoteInProgress: action,
      showWarningVotingInProcess: action,
      hideWarningVotingInProcess: action,
      setIsVotersInProgress: action,
      toggleLoadingDocument: action,
      filteredTransactionsPending: action,
      getJsonVote: action,
      showErrorModal: action,
      hideErrorModal: action,
      setActiveTab: action,
      setVoters: action,
      setVotersCanAbstain: action,
      setTab: action,
      showCreationVoteModal: action,
      hideCreationVoteModal: action,
      resetVote: action,
      showResolveVotingModal: action,
      hideResolveVotingModal: action,
      showComplexResolutionModal: action,
      setComplexResolution: action,
      closeComplexResolutionModal: action,
      setCloseModalVote: action,
      setIsEditionVote: action,
      validateClose: action,
      validateVote: action,
    })

    this.fiduciaryStructureId = fiduciaryStructureId
    this.transactionsService = new TransactionsService()
    this.init()
    this.loadListTransactionsForSelect()
    this.blockchainVotingService = new BlockchainVotingService()
  }

  init() {
    this.votersSelected.setValue([])
    this.loadListVotes()
    this.loadPossibleVoters(this.fiduciaryStructureId)
  }

  setShowVote(voteSelected) {
    this.vote.fillFromVote(voteSelected)
  }

  async loadListVotes() {
    this.preRequest(() => this.loadListVotes(this.fiduciaryStructureId))

    try {
      const response = await this.transactionsService.listVotes(this.fiduciaryStructureId)

      runInAction(() => {
        this.votes = response

        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async saveVote() {
    if (this.validateVote()) {
      this.preRequest()
      this.vote.fiduciaryStructure.setValue(this.fiduciaryStructureId)

      if (this.vote.votersCanAbstain.value === '') {
        this.vote.votersCanAbstain.setValue(false)
      }

      try {
        await this.transactionsService.saveVote(this.getJsonVote())

        runInAction(() => {
          this.resetVotesList()
          this.hideCreationVoteModal()
          this.loadListVotes()
          this.resetInputSelect()
          this.loadListTransactionsForSelect()
          this.onSuccessRequest()
        })
      } catch (e) {
        const [errorMessage] = e.response.data.error.errors
        this.messageErrorServer = errorMessage
        this.showErrorModal()
        this.onErrorRequest(e)
      }
    }
  }

  async approveTransaction(transactions) {
    this.resetMessageErrorServer()
    this.preRequest(() => this.approveTransaction(transactions))

    try {
      if (transactions.length > 0) {
        await this.transactionsService.approveTransaction(transactions)
      } else {
        const transactionId = [transactions.id]
        await this.transactionsService.approveTransaction(transactionId)
      }

      runInAction(() => {
        this.closeComplexResolutionModal()
        this.hideResolveVotingModal()
        this.onSuccessRequest()
      })
    } catch (e) {
      const [errorMessage] = e.response.data.error.errors
      this.messageErrorServer = errorMessage
      this.showErrorModal()
      this.onErrorRequest(e)
    }
  }

  resetMessageErrorServer() {
    this.messageErrorServer = ''
  }

  setMessageErrorServer(value) {
    this.messageErrorServer = value
  }

  async rejectTransaction(transactions) {
    this.resetMessageErrorServer()
    this.preRequest(() => this.rejectTransaction(transactions))

    try {
      if (transactions.length > 0) {
        await this.transactionsService.rejectTransaction(transactions)
      } else {
        const transactionId = [transactions.id]
        await this.transactionsService.rejectTransaction(transactionId)
      }

      runInAction(() => {
        this.closeComplexResolutionModal()
        this.hideResolveVotingModal()
        this.onSuccessRequest()
      })
    } catch (e) {
      const [errorMessage] = e.response.data.error.errors
      this.messageErrorServer = errorMessage
      this.showErrorModal()
      this.onErrorRequest(e)
    }
  }

  async loadPossibleVoters() {
    this.preRequest()

    if (this.vote) {
      if (this.vote.votings.value) {
        const arrayVoters = []
        this.vote.votings.value.forEach((voting) => {
          arrayVoters.push({
            id: voting.voter.id,
            value: `${voting.voter.firstName} ${voting.voter.lastName}`,
          })
        })
        this.votersSelected.setValue(arrayVoters)
      }
    }

    try {
      const response = await this.transactionsService.listPossibleVoters(this.fiduciaryStructureId)

      runInAction(() => {
        this.possibleVoters = response

        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async getBlockChainInformation() {
    this.preRequest()

    try {
      const response = await this.blockchainVotingService.getBlockchainTransaction(this.vote.id)

      runInAction(() => {
        this.blockChainAddress = response.address
        this.checksum = response.checksum
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async loadListTransactionsForSelect() {
    this.preRequest(() => this.loadList(this.fiduciaryStructureId))

    try {
      const response = await this.transactionsService.listTrustTransactions(
        this.fiduciaryStructureId
      )

      runInAction(() => {
        this.filteredTransactionsPending(response)
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  async closeVotingResolution() {
    this.preRequest(() => this.closeVotingResolution())

    this.validateVotersInProgress()

    const data = {
      status: this.votingResult.value,
      resolution: this.vote.resolution.value,
      additionalData: {
        description: this.vote.description.value,
        document: this.vote.document,
      },
    }

    try {
      await this.transactionsService.closeVoting(this.vote.id, data)

      runInAction(() => {
        this.loadListVotes()
        this.onSuccessRequest()
      })
    } catch (e) {
      const [errorMessage] = e.response.data.error.errors
      this.messageErrorServer = errorMessage
      this.showErrorModal()
      this.onErrorRequest(e)
    }
  }

  setDefaultSelectedTransactions(defaultSelectedTransactions) {
    this.inputsSelect = []

    defaultSelectedTransactions.forEach((selectedTransaction) => {
      const inputStore = new InputStore()

      inputStore.setValue({
        id: selectedTransaction.id,
        value: selectedTransaction.name,
      })

      this.inputsSelect.push(inputStore)
    })

    this.setVoteTransactions()
  }

  resetInputSelect() {
    this.inputsSelect = []
  }

  setVotersSelected() {
    const voters = this.vote.votings.value
    const voterFormater = []
    voters.forEach((voterData) => {
      voterFormater.push({
        id: voterData.voter.id,
        value: `${voterData.voter.firstName} ${voterData.voter.lastName}`,
      })
    })

    this.votersSelected.setValue(voterFormater)
  }

  removeInputSelect(input) {
    this.inputsSelect.remove(input)
    let idsVoteTransactions = [...this.vote.transactions.value]

    idsVoteTransactions = idsVoteTransactions.filter((id) => {
      return id !== input.value.id
    })

    this.vote.setTransactions(idsVoteTransactions)
  }

  createNewInputSelect() {
    this.inputsSelect.push(new InputStore())
  }

  setVoteTransactions() {
    const voteTransactions = []

    if (this.inputsSelect) {
      this.inputsSelect.forEach((input) => {
        voteTransactions.push(input.value.id)
      })
    }

    this.vote.setTransactions(voteTransactions)
  }

  resetResultSelected() {
    this.votingResultSelected.setValue(null)
  }

  setVotingResult(option) {
    this.votingResultSelected.setValue(option)
    this.votingResult.setValue(option.id)
  }

  resetVotesList() {
    this.votes = []
  }

  validateVotersInProgress() {
    const { votings } = this.vote
    this.setRarningVoteInProgress(false)
    this.setIsVotersInProgress()

    votings.value.forEach((resultVotes) => {
      if (resultVotes.response === null) {
        this.setRarningVoteInProgress(true)
        this.setIsVotersInProgress()
        this.showWarningVotingInProcess()
      }
    })
  }

  setRarningVoteInProgress(value) {
    this.warningVoteInProgress = value
  }

  showWarningVotingInProcess() {
    this.visibleWarningVotingModalInProcess = true
    this.warningVoteInProgress = true
  }

  hideWarningVotingInProcess() {
    this.visibleWarningVotingModalInProcess = false
  }

  setIsVotersInProgress() {
    this.votersInProgress = !this.votersInProgress
  }

  toggleLoadingDocument() {
    this.isLoadingDocument = !this.isLoadingDocument
  }

  resetTransactionsOptions() {
    this.transactionsOptions = null
  }

  filteredTransactionsPending(transactions) {
    this.resetTransactionsOptions()

    this.transactionsOptions = transactions.filter((transaction) => {
      return transaction.status.status === 'pending'
    })
  }

  getJsonVote() {
    return this.vote.getJson()
  }

  showErrorModal() {
    this.visibleErrorModal = true
  }

  hideErrorModal() {
    this.visibleErrorModal = false
  }

  setActiveTab(resetTab) {
    const previousActiveTab = this.activeTab

    if (resetTab === 0) {
      this.activeTab = previousActiveTab + 1
    }
    if (resetTab === 1) {
      this.activeTab = previousActiveTab + 1
    }
  }

  setVoters(option) {
    const arrayVoterId = []
    if (option) {
      option.forEach((voter) => {
        arrayVoterId.push(voter.id)
      })
    }
    this.votersSelected.setValue(option)
    this.vote.voters.setValue(arrayVoterId)
  }

  setVotersCanAbstain() {
    this.votersCanAbstain = !this.votersCanAbstain
  }

  setTab(value) {
    this.activeTab = value
  }

  showCreationVoteModal() {
    this.visibleModalCreationVote = true
  }

  hideCreationVoteModal() {
    this.visibleModalCreationVote = false
    this.resetVote()
  }

  resetVote() {
    this.vote = new Vote()
  }

  showResolveVotingModal() {
    this.visibleResolveVotingModal = true
  }

  hideResolveVotingModal() {
    this.visibleResolveVotingModal = false
  }

  showComplexResolutionModal() {
    this.visibleComplexResolutionModal = true
  }

  setComplexResolution(value) {
    this.isComplexResolution = value
  }

  closeComplexResolutionModal() {
    this.visibleComplexResolutionModal = false
  }

  setCloseModalVote(value) {
    this.closeModalVote = value
  }

  setIsEditionVote() {
    if (this.vote.id) {
      this.isEdition = true
      return
    }
    this.isEdition = false
  }

  validateClose() {
    this.hasErrorsVote = false
    this.clearErrorsClose()

    if (!this.vote.resolution.value) {
      this.vote.resolution.setError(true, REQUIRED_FIELD)

      this.hasErrorsVote = true
    }

    if (!this.votingResultSelected.value) {
      this.votingResultSelected.setError(true, REQUIRED_FIELD)

      this.hasErrorsVote = true
    }

    return !this.hasErrorsVote
  }

  clearErrorsClose() {
    this.votingResultSelected.clearError()
    this.votingResult.clearError()
  }

  validateVote() {
    const { description, title, approvalRule } = this.vote
    this.hasErrorsVote = false
    this.clearErrorsVotes()

    this.generalInformationErrors = 0
    this.votersInformationErrors = 0
    this.transactionErrors = 0

    if (!title.value) {
      title.setError(true, REQUIRED_FIELD)

      this.generalInformationErrors += 1

      this.hasErrorsVote = true
    }

    if (!description.value) {
      description.setError(true, REQUIRED_FIELD)

      this.generalInformationErrors += 1

      this.hasErrorsVote = true
    }

    if (!this.vote.voters.value.length) {
      this.votersSelected.setError(true, REQUIRED_FIELD)

      this.votersInformationErrors += 1

      this.hasErrorsVote = true
    }

    if (this.vote.transactions.value.length === 0) {
      this.vote.transactions.setError(true, REQUIRED_FIELD)

      this.inputsSelect.forEach((input) => {
        input.setError(true, REQUIRED_FIELD)
      })

      this.transactionErrors += 1

      this.hasErrorsVote = true
    }

    if (!approvalRule.value) {
      approvalRule.setError(true, REQUIRED_FIELD)

      this.votersInformationErrors += 1

      this.hasErrorsVote = true
    }

    return !this.hasErrorsVote
  }

  clearErrorsVotes() {
    this.generalInformationErrors = 0
    this.votersInformationErrors = 0
    this.transactionErrors = 0

    const { description, title, approvalRule, transactions } = this.vote

    description.clearError()
    this.votersSelected.clearError()
    title.clearError()
    transactions.clearError()
    approvalRule.clearError()
  }
}

export default FiduciaryStructureVotesStore
