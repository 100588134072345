import TransactionsValuation from 'models/TransactionsValuation'

class ValuationChangeAsset {
  constructor(id, name, type, description, valuation, isInvaluable, isLiquid) {
    this.id = id
    this.name = name
    this.type = type
    this.description = description
    this.valuation = valuation
    this.isInvaluable = isInvaluable
    this.isLiquid = isLiquid
  }

  static fromJson({ id, name, type, description, valuation, isInvaluable, isLiquid }) {
    const valuate = valuation.map((value) => TransactionsValuation.fromJson(value))
    return new ValuationChangeAsset(id, name, type, description, valuate, isInvaluable, isLiquid)
  }
}

export default ValuationChangeAsset
