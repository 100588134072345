import React from 'react'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import BriefcaseAccountIcon from 'mdi-react/BriefcaseAccountIcon'
import VectorTriangleIcon from 'mdi-react/VectorTriangleIcon'
import ClipboardTextMultipleIcon from 'mdi-react/ClipboardTextMultipleIcon'
import PropTypes from 'prop-types'
import ToolTip from 'presentation/ToolTip'
import ListClient from 'models/ListClient'
import styles from './clientTableStructureName.module.scss'

const LIST_MAX_STRUCTURE_SHOWN = 4
const GRID_MAX_STRUCTURE_SHOWN = 3
const TOOLTIP_MAX_STRUCTURE_SHOWN = 10
const NAME_MAX_CHARACTERS = 35

const parseName = (name) => {
  return name.length > NAME_MAX_CHARACTERS ? `${name.substring(0, NAME_MAX_CHARACTERS)}...` : name
}

const MultiplePartiesTooltip = ({ structures }) => {
  const { t } = useTranslation('clients')
  const maxStructuresDisplay = TOOLTIP_MAX_STRUCTURE_SHOWN

  const chosenStructures = structures
    .slice(0, maxStructuresDisplay)
    .map((structure) => parseName(structure.companyName))

  return (
    <>
      {chosenStructures.map((structure, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${i}_structure`}>{structure}</div>
      ))}
      {structures.length > maxStructuresDisplay && (
        <div className={c(styles.dottedUnderline, styles.noTextTransform)}>
          {t('andXMore', {
            amount: structures.length - maxStructuresDisplay,
          })}
        </div>
      )}
    </>
  )
}

const MultipleStructures = ({ structures, view }) => {
  const { t } = useTranslation('clients')
  if (view === 'detail') {
    return (
      <ToolTip tooltipContent={<MultiplePartiesTooltip structures={structures} />}>
        {' '}
        <span className={c(styles.dottedUnderline, styles.noTextTransform)}>
          {t('xStructure', {
            amount: structures.length,
          })}
        </span>
      </ToolTip>
    )
  }

  const maxStructureDisplay = view === 'list' ? LIST_MAX_STRUCTURE_SHOWN : GRID_MAX_STRUCTURE_SHOWN
  const chosenStructures = structures
    .slice(0, maxStructureDisplay)
    .map((structure) => parseName(structure.companyName))
  const remainingStructure = structures.slice(maxStructureDisplay)
  const chosenNames = chosenStructures.join(', ')

  if (structures.length > maxStructureDisplay) {
    return (
      <>
        {chosenNames}

        <ToolTip
          className={c(styles.andXMore)}
          tooltipContent={<MultiplePartiesTooltip structures={remainingStructure} />}
        >
          {' '}
          <span className={c(styles.dottedUnderline, styles.noTextTransform)}>
            {t('andXMore', {
              amount: structures.length - maxStructureDisplay,
            })}
          </span>
        </ToolTip>
      </>
    )
  }
  return chosenNames
}

const ClientTableItemStructureName = ({ listItem, view }) => {
  const { t } = useTranslation('clients')

  if (listItem.isAssetCorporation) {
    return (
      <>
        <BriefcaseAccountIcon size={18} className={styles.iconStructureType} />
        <span className={styles.companyName}>{listItem.structures[0].companyName}</span>
      </>
    )
  }

  if (listItem.isAssetTrust) {
    return (
      <>
        <ClipboardTextMultipleIcon size={18} className={styles.iconStructureType} />
        <span className={styles.companyName}>{listItem.structures[0].companyName}</span>
      </>
    )
  }

  if (listItem.isMultipleStructures) {
    return (
      <>
        <VectorTriangleIcon size={18} className={styles.iconStructureType} />
        <span className={styles.companyName}>
          <MultipleStructures structures={listItem.structures} view={view} />
        </span>
      </>
    )
  }

  return <i>{t('noStructure')}</i>
}

MultipleStructures.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  structures: PropTypes.oneOfType([PropTypes.array]).isRequired,
  view: PropTypes.string.isRequired,
}

MultiplePartiesTooltip.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  structures: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

ClientTableItemStructureName.propTypes = {
  listItem: PropTypes.instanceOf(ListClient).isRequired,
  view: PropTypes.string.isRequired,
}

export default ClientTableItemStructureName
