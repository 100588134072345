import Document from 'models/Document'
import * as yup from 'yup'
import { makeObservable, runInAction, observable, action, computed } from 'mobx'
import PartStore from './PartStore'

class FileListFormPartStore extends PartStore {
  constructor() {
    super(new Document())

    this.error = false
    this.errorMessage = ''
    this.firstLevelError = false
    this.validationCountErrors = 0

    makeObservable(this, {
      // observables
      error: observable,
      errorMessage: observable,
      firstLevelError: observable,
      validationCountErrors: observable,
      // actions
      setError: action,
      // computeds
      isValid: computed,
      validationSchema: computed,
    })
  }

  fill(data) {
    if (data) {
      this.store.fillFromJson(data)
    }
  }

  createFromCurrent() {
    const newFileListFormPartStore = new FileListFormPartStore()

    newFileListFormPartStore.fill(this.store.getJson())
    newFileListFormPartStore.setValidationRules(this.validationRules)

    return newFileListFormPartStore
  }

  get json() {
    return this.store.getJson()
  }

  clearError() {
    this.error = false
    this.errorMessage = ''
    this.firstLevelError = false
  }

  get validationSchema() {
    let validation = yup.array()

    if (this.validationRules.get('required')) {
      validation = validation.min(1)
    }

    return validation
  }

  async validate() {
    this.clearError()
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (this.validationRules) {
        try {
          await this.validationSchema.validate(this.json || undefined)
          this.countErrors()
          resolve(true)
        } catch (e) {
          this.setError(
            true,
            e.message,
            this.validationRules && !this.validationSchema.isValidSync(this.json || undefined)
          )
          this.store.setError(true, e.message)

          this.countErrors()
        }
      }
      resolve(true)
    })
  }

  setError(error, errorMessage = '', firstLevelError = false) {
    this.error = error
    this.errorMessage = errorMessage
    this.firstLevelError = firstLevelError
  }

  get isValid() {
    return !this.validationCountErrors
  }

  countErrors() {
    let errors = 0

    this.store.filesList.forEach((file) => {
      errors += file.validationCountErrors
    })

    if (this.error) {
      errors += 1
    }

    runInAction(() => {
      this.validationCountErrors = errors
    })
  }
}

export default FileListFormPartStore
