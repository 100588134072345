import { action, observable, reaction, computed, runInAction, makeObservable } from 'mobx'
import { LetterOfWishes, LetterOfDistribution } from 'models/Letters'
import LettersBaseStore from './LettersBaseStore'

class LettersStore extends LettersBaseStore {
  constructor(trustCreationStore) {
    super()

    this.isLoading = false
    this.lows = []
    this.lods = []
    this.initialized = false
    this.isDirty = false

    makeObservable(this, {
      // observables
      initialized: observable,
      lows: observable,
      lods: observable,
      isDirty: observable,
      // actions
      createLetter: action,
      createSecondaryLetter: action,
      initialize: action,
      initList: action,
      resetDirty: action,
      fillFromJson: action,
      restoreMainFromOldVersion: action,
      restoreSecondaryFromOldVersion: action,
      validate: action,
      // computeds
      draftMainLetter: computed,
      activeMainLetter: computed,
      activeSecondaryLetter: computed,
      letterOfWishesForRules: computed,
      draftSecondaryLetter: computed,
      usableSecondaryLetters: computed,
    })

    this.trustCreationStore = trustCreationStore
  }

  getJson() {
    const lows = []
    const lods = []

    this.lows.forEach((low) => {
      if (!low.isActive) {
        lows.push(low.getJson())
      }
    })

    this.lods.forEach((lod) => {
      if (!lod.isActive) {
        lods.push(lod.getJson())
      }
    })

    return {
      lows,
      lods,
    }
  }

  createLetter(from = null) {
    let newLetter = new LetterOfWishes()

    if (from !== null) {
      newLetter = LetterOfWishes.createFromLetterOfWishes(from)
      newLetter.clearFile()
    }

    this.lows.push(newLetter)
  }

  createSecondaryLetter() {
    this.lods.unshift(new LetterOfDistribution())
  }

  async initialize(alternativeStatusLoad) {
    this.preRequest()

    try {
      const response = await this.trustCreationStore.trustService.getLowsSection(
        alternativeStatusLoad,
        this.trustCreationStore.idEdition
      )

      runInAction(() => {
        this.initList(response)

        this.initialized = true

        this.onSuccessRequest()
      })

      return response
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }

    return null
  }

  initList(json) {
    if (json) {
      this.fillFromJson(json)
    }

    setTimeout(() => {
      reaction(
        () => {
          return JSON.stringify(this.getJson())
        },
        () => {
          if (!this.isDirty) {
            this.isDirty = true
          }
        },
        {
          equals: (prev, next) => prev === next && this.isDirty,
        }
      )
    })
  }

  resetDirty() {
    this.isDirty = false
  }

  fillFromJson({ lows = [], lods = [] }) {
    this.lows = lows.map((low) => LetterOfWishes.createFromJson(low))
    this.lods = lods.map((lod) => LetterOfDistribution.createFromJson(lod))
  }

  restoreMainFromOldVersion(oldVersion) {
    this.draftMainLetter.fillFromOldVersion(oldVersion)
  }

  restoreSecondaryFromOldVersion(oldVersion) {
    this.draftSecondaryLetter.fillFromOldVersion(oldVersion)
  }

  validate() {
    this.setErrorCountMainLetter(this.validateMainLetterDraft())
    this.setErrorCountSecondaryLetter(this.validateSecondaryLetterDraft())
  }

  get draftMainLetter() {
    const drafts = this.lows.filter((low) => low.isDraft)

    if (drafts.length > 0) {
      return drafts[0]
    }

    return null
  }

  get activeMainLetter() {
    const actives = this.lows.filter((low) => low.isActive)

    if (actives.length > 0) {
      return actives[0]
    }

    return null
  }

  get activeSecondaryLetter() {
    return this.lods.filter((lod) => lod.isActive)
  }

  get letterOfWishesForRules() {
    const actives = this.lows.filter((low) => low.isExecution || low.isActive || low.isExecuted)

    if (actives.length === 1) {
      return actives[0]
    }
    if (actives.length > 1) {
      return actives.filter((low) => low.isExecution || low.isExecuted)[0]
    }

    return null
  }

  get draftSecondaryLetter() {
    const drafts = this.lods.filter((lod) => lod.isDraft)

    if (drafts.length > 0) {
      return drafts[0]
    }

    return null
  }

  get usableSecondaryLetters() {
    return this.lods.filter((lod) => lod.isDraft || lod.isActive)
  }
}

export default LettersStore
