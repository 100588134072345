import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import * as yup from 'yup'
import ActivateUserService from 'services/ActivateUserService'

class ActivateUserStore extends AsyncStore {
  id = ''
  token = ''
  password = ''
  repeatPassword = null
  formError = null
  error = false
  success = false
  isLoadingToken = false

  constructor() {
    super()
    this.isLoading = false

    makeObservable(this, {
      // observables
      id: observable,
      token: observable,
      password: observable,
      repeatPassword: observable,
      formError: observable,
      error: observable,
      success: observable,
      isLoadingToken: observable,
      // actions
      setId: action,
      validateToken: action,
      validatePassword: action,
      updatePassword: action,
      updateRPassword: action,
      send: action,
      // computeds
      isEqualPassword: computed,
    })

    this.activateUserService = new ActivateUserService()
  }

  setId(id) {
    this.id = id
  }

  validateToken(token) {
    if (!token) {
      this.error = true
      return Promise.reject()
    }

    this.preRequest()

    // Validar token con endpoint
    return this.activateUserService
      .validateToken(this.id, token)
      .then(() => {
        this.token = token
        runInAction(() => {
          this.onSuccessRequest()
        })
      })
      .catch((e) => {
        this.error = true
        this.isLoadingToken = false
        this.onErrorRequest(e)
      })
  }

  get isEqualPassword() {
    return !(this.repeatPassword !== null && this.repeatPassword !== this.password)
  }

  validatePassword() {
    const schema = yup.string().min(8, 'shortPassword')

    if (this.password) {
      try {
        schema.validateSync(this.password)
      } catch (e) {
        this.formError = e
        return
      }
    }

    if (this.repeatPassword !== null && this.repeatPassword !== this.password) {
      this.formError = { message: 'NotEqualPassword' }
      return
    }

    this.formError = false
  }

  updatePassword(value) {
    this.password = value
  }

  updateRPassword(value) {
    this.repeatPassword = value
  }

  send() {
    this.validatePassword()

    if (!this.formError) {
      this.preRequest()

      return this.activateUserService
        .activate(this.id, this.token, this.password, this.repeatPassword)
        .then(() => {
          this.success = true

          runInAction(() => {
            this.onSuccessRequest()
          })
        })
        .catch((e) => {
          this.error = true
          this.onErrorRequest(e)
        })
    }
    return false
  }
}

export default ActivateUserStore
