import { makeAutoObservable } from 'mobx'

export default class HotkeyStore {
  constructor() {
    this.configs = []

    makeAutoObservable(this)
  }

  /* crea nueva configuracion para hotkeys

  Name: identificador de la confinguracion
  config: configuracion de los hotkeys
  parentMerge: cantidad de padres con los cuales la configuracion se mergea
   */
  setConfig(name, config, parentMerge = 0) {
    const index = this.configs.findIndex((e) => e.name === name)

    if (index > 0) {
      this.configs[index] = { name, config: Object.assign(this.configs[index].config, config) }
    } else {
      let resultConfig = []

      for (let i = 0; i < parentMerge; i += 1) {
        // eslint-disable-next-line no-restricted-syntax
        for (const conf of this.configs[this.configs.length - 1 - i].config.map((e) => e)) {
          const cIndex = resultConfig.findIndex((e) => e.shortcut === conf.shortcut)
          if (cIndex >= 0) {
            resultConfig.splice(cIndex, 1)
          }
          resultConfig.push({ shortcut: conf.shortcut, handler: conf.handler })
        }
      }

      resultConfig = resultConfig.concat(config)
      this.configs.push({ name, config: resultConfig })
    }
  }

  removeConfig(name) {
    const index = this.configs.findIndex((e) => e.name === name)
    if (index >= 0) {
      this.configs.splice(index, 1)
    }
  }

  // Obtiene la configuracion de los hotkey a aplicar
  get currentConfig() {
    if (this.configs.length > 0) {
      return this.configs[this.configs.length - 1].config
    }
    return []
  }
}
