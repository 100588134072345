import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { RuleViewForm } from 'scenes/TrustsCreation/RulesSection'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import NoRules from './NoRules'
import styles from './rulesScrollableList.module.scss'

const RulesScrollableList = ({
  rules,
  handleDeleteRule,
  handleSaveRule,
  handleSelectRule,
  handleOpenCardToTextFromList,
  handleScrollToTextFromList,
  listRef,
  trustAssets,
  trustBeneficiaries,
  trustGovernors,
  isFiltered,
  viewMode /* , handleAddChunk */,
}) => (
  <div className={styles.rulesList} ref={listRef}>
    {rules.length > 0 ? (
      rules.map((rule, i) => (
        <RuleViewForm
          rule={rule}
          // eslint-disable-next-line react/no-array-index-key
          key={`rule_${i}`}
          deleteRule={handleDeleteRule}
          handleSaveRule={handleSaveRule}
          handleSelectRule={handleSelectRule}
          trustAssets={trustAssets}
          trustBeneficiaries={trustBeneficiaries}
          trustGovernors={trustGovernors}
          handleOpenCardToTextFromList={handleOpenCardToTextFromList}
          handleScrollToTextFromList={handleScrollToTextFromList}
          viewMode={viewMode}
          /* handleAddChunk={handleAddChunk} */
        />
      ))
    ) : (
      <NoRules isFiltered={isFiltered} />
    )}
  </div>
)

RulesScrollableList.propTypes = {
  handleDeleteRule: PropTypes.func.isRequired,
  handleOpenCardToTextFromList: PropTypes.func,
  isFiltered: PropTypes.number,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  trustGovernors: PropTypes.arrayOf(PropTypes.instanceOf(RuleAssetGovernor)),
  handleSaveRule: PropTypes.func.isRequired,
  handleSelectRule: PropTypes.func.isRequired,
  handleScrollToTextFromList: PropTypes.func.isRequired,
  listRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /* handleAddChunk: PropTypes.func.isRequired, */
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  rules: PropTypes.oneOfType([PropTypes.array]),
  viewMode: PropTypes.bool,
}

RulesScrollableList.defaultProps = {
  rules: [],
  listRef: null,
  isFiltered: 0,
  viewMode: false,
  handleOpenCardToTextFromList: null,
  trustGovernors: null,
}

export default observer(RulesScrollableList)
