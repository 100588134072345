import React from 'react'
import PropTypes from 'prop-types'
import ExpandableButton, { ANIMATION_DURATION } from 'presentation/ExpandableButton'
import ToolTip from 'presentation/ToolTip'

const ButtonWithHelp = ({
  handleClick,
  Icon,
  buttonText,
  tooltipText,
  expandableButtonClassname,
}) => (
  <ToolTip secondary tooltipContent={tooltipText} delay={[ANIMATION_DURATION, 0]}>
    <ExpandableButton
      className={expandableButtonClassname}
      label={buttonText}
      onClick={handleClick}
      Icon={<Icon size={20} />}
      big
    />
  </ToolTip>
)

ButtonWithHelp.propTypes = {
  handleClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PropTypes.any.isRequired,
  expandableButtonClassname: PropTypes.string,
}

ButtonWithHelp.defaultProps = {
  expandableButtonClassname: null,
}

ButtonWithHelp.defaultProps = {}

export default ButtonWithHelp
