import React from 'react'
import c from 'classnames'
import { useTranslation } from 'react-i18next'
import AccountIcon from 'mdi-react/AccountIcon'
import AccountGroupIcon from 'mdi-react/AccountGroupIcon'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import PropTypes from 'prop-types'
import ToolTip from 'presentation/ToolTip'
import ListCorporation from 'models/ListCorporation'
import styles from './corporationTableItemPartyName.module.scss'

const LIST_MAX_PARTIES_SHOWN = 4
const GRID_MAX_PARTIES_SHOWN = 3
const TOOLTIP_MAX_PARTIES_SHOWN = 10
const NAME_MAX_CHARACTERS = 35

const parseName = (name) => {
  return name.length > NAME_MAX_CHARACTERS ? `${name.substring(0, NAME_MAX_CHARACTERS)}...` : name
}

const MultiplePartiesTooltip = ({ parties }) => {
  const { t } = useTranslation('corporations')
  const maxPartiesDisplay = TOOLTIP_MAX_PARTIES_SHOWN
  const chosenParties = parties.slice(0, maxPartiesDisplay).map((party) => parseName(party.name))

  return (
    <>
      {chosenParties.map((party, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${i}_party`}>{party}</div>
      ))}
      {parties.length > maxPartiesDisplay && (
        <div>
          {t('andXMore', {
            amount: parties.length - maxPartiesDisplay,
          })}
        </div>
      )}
    </>
  )
}

const MultiplePartiesNames = ({ parties, view }) => {
  const { t } = useTranslation('corporations')

  if (view === 'detail') {
    /*
     * Si la lista es tipo detalle, se muestra la cantidad de parties en números y un tooltip donde se muestran los nombres
     * */

    return (
      <>
        <ToolTip tooltipContent={<MultiplePartiesTooltip parties={parties} />}>
          <span className={c(styles.dottedUnderline, styles.noTextTransform)}>
            {t('xControllingParties', {
              amount: parties.length,
            })}
          </span>
        </ToolTip>
      </>
    )
  }
  const maxPartiesDisplay = view === 'list' ? LIST_MAX_PARTIES_SHOWN : GRID_MAX_PARTIES_SHOWN
  const chosenParties = parties.slice(0, maxPartiesDisplay).map((party) => parseName(party.name))
  const remainingParties = parties.slice(maxPartiesDisplay)
  const chosenNames = chosenParties.join(', ')

  if (parties.length > maxPartiesDisplay) {
    return (
      <>
        {chosenNames}
        &nbsp;
        <ToolTip
          className={c(styles.andXMore)}
          tooltipContent={<MultiplePartiesTooltip parties={remainingParties} />}
        >
          <span className={c(styles.dottedUnderline, styles.noTextTransform)}>
            {t('andXMore', {
              amount: parties.length - maxPartiesDisplay,
            })}
          </span>
        </ToolTip>
      </>
    )
  }

  return chosenNames
}

const CorporationTableItemPartyName = ({ listItem, view }) => {
  const { t } = useTranslation('corporations')

  if (listItem.isIndividualControllingParty) {
    return (
      <>
        <AccountIcon size={18} className={styles.iconPartyType} />
        <span>{listItem.controllingParties[0].name}</span>
      </>
    )
  }

  if (listItem.isCompanyControllingParty) {
    return (
      <>
        <BriefcaseIcon size={18} className={styles.iconPartyType} />
        <span>{listItem.controllingParties[0].name}</span>
      </>
    )
  }

  if (listItem.isMultipleControllingParties) {
    return (
      <>
        <AccountGroupIcon size={18} className={styles.iconPartyType} />
        <span>
          <MultiplePartiesNames parties={listItem.controllingParties} view={view} />
        </span>
      </>
    )
  }

  return <i>{t('noControllingParties')}</i>
}

MultiplePartiesNames.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  parties: PropTypes.oneOfType([PropTypes.array]).isRequired,
  view: PropTypes.string.isRequired,
}

MultiplePartiesTooltip.propTypes = {
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  parties: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

CorporationTableItemPartyName.propTypes = {
  listItem: PropTypes.instanceOf(ListCorporation).isRequired,
  view: PropTypes.string.isRequired,
}

export default CorporationTableItemPartyName
