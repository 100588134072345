import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import tableStyles from 'styles/tables.module.scss'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import { useSwipeable } from 'react-swipeable'
import styles from './dynamicEntitiesTable.module.scss'

const DynamicEntitiesTable = ({ children, handleDelete, viewMode }) => {
  const [showDelete, setShowDelete] = useState(false)

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => setShowDelete(true),
    onSwipedRight: () => setShowDelete(false),
  })

  if (viewMode) {
    return (
      <div className={tableStyles.rowBg}>
        <div className={tableStyles.rowTransition}>
          <div className={styles.tableRowContainer}>{children}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={tableStyles.rowBg}>
      <div className={tableStyles.rowTransition}>
        <div className={styles.tableRowContainer} {...swipeableHandlers}>
          {children}
          <div
            className={c(styles.tableRowDelete, showDelete && styles.showDelete)}
            onClick={() => {
              handleDelete()
              setShowDelete(false)
            }}
            role="button"
            tabIndex="0"
          >
            <TrashCanOutlineIcon size={20} />
          </div>
        </div>
      </div>
    </div>
  )
}

DynamicEntitiesTable.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  viewMode: PropTypes.bool,
}

DynamicEntitiesTable.defaultProps = {
  viewMode: false,
}

export default DynamicEntitiesTable
