import { makeObservable, observable, action } from 'mobx'
import moment from 'moment'
import i18next from 'i18next'
import InputStore from 'stores/InputStore'
import File from 'models/File'
import TransactionStatus from 'models/TransactionStatus'

class Transaction {
  canDelete = false
  canResolve = false
  fiduciaryStructureId = null
  requiresVoting = false
  votersCanAbstain = false
  storedFormat = ''
  lastUpdate = null
  updatedByLastName = null
  updatedByFirstName = null
  approveAutomatically = false

  constructor() {
    this.status = new TransactionStatus()
    this.date = new InputStore()
    this.name = new InputStore()
    this.additionalData = {
      description: new InputStore(),
    }
    this.document = new File()
    this.approvalRule = new InputStore()
    this.checksum = new InputStore()
    this.blockchainAddress = new InputStore()
    this.voters = new InputStore()
    this.votingResult = new InputStore()
    this.storedAt = new InputStore()
    this.votingResultDocument = new File()
    this.votingResultResolution = new InputStore()
    this.requiresVoting = false
    this.votersCanAbstain = false

    this.voters.setValue([])

    makeObservable(this, {
      // observables
      canDelete: observable,
      canResolve: observable,
      fiduciaryStructureId: observable,
      requiresVoting: observable,
      votersCanAbstain: observable,
      storedFormat: observable,
      lastUpdate: observable,
      updatedByLastName: observable,
      updatedByFirstName: observable,
      approveAutomatically: observable,
      // actions
      fillFromTransaction: action,
      setUpdated: action,
      setStoredAt: action,
      fillFromJson: action,
      setApproveAutomatically: action,
      setFiduciaryStructureId: action,
      setName: action,
      setDate: action,
      setDescription: action,
    })
  }

  getJson() {
    return {
      fiduciaryStructureId: this.fiduciaryStructureId,
      name: this.name.value,
      additionalData: this.additionalData,
      date: this.date.value,
      storedAt: this.storedAt.value,
      document: this.document.getJson(),
      requiresVoting: this.requiresVoting,
      votersCanAbstain: this.votersCanAbstain,
      approveAutomatically: this.approveAutomatically,
      approvalRule: this.requiresVoting ? this.approvalRule.value : '',
      voters: this.requiresVoting ? this.voters.value : [],
      status: this.status.status,
      canDelete: this.canDelete,
      canResolve: this.canResolve,
      votingResult: this.votingResult.value,
      votingResultDocument: this.votingResultDocument.getJson(),
      votingResultResolution: this.votingResultResolution.value,
    }
  }

  fillFromTransaction(transaction) {
    this.name.setValue(transaction.name.value)
    this.date.setValue(transaction.date.value)
    this.additionalData = {
      description: this.description.setValue(transaction.description.value),
    }
    this.approvalRule.setValue(transaction.approvalRule.value)
    this.voters.setValue(transaction.voters.value)
    this.votingResult.setValue(transaction.votingResult.value)
    this.votingResultResolution.setValue(transaction.votingResultResolution.value)
    this.checksum.setValue(transaction.checksum.value)
    this.blockchainAddress.setValue(transaction.blockchainAddress.value)
    this.storedAt.setValue(transaction.storedAt.value)

    this.fiduciaryStructureId = transaction.fiduciaryStructureId
    this.id = transaction.id
    this.canDelete = transaction.canDelete
    this.approveAutomatically = transaction.approveAutomatically
    this.approveAutomatically = transaction.approveAutomatically
    this.canResolve = transaction.canResolve
    this.status = transaction.status
    this.requiresVoting = transaction.requiresVoting
    this.votersCanAbstain = transaction.votersCanAbstain
    this.document = File.fromFile(transaction.document)
    this.votingResultDocument = File.fromFile(transaction.votingResultDocument)

    this.setId(transaction.id)
  }

  setUpdated({ at = null, firstName = null, lastName = null }) {
    this.lastUpdate = at !== null ? moment(at) : null
    this.updatedByFirstName = firstName
    this.updatedByLastName = lastName
  }

  setStoredAt() {
    this.storedFormat = {
      storedAt: this.storedAt.value === '' ? '' : moment(this.storedAt.value),
      updatedByFirstName: this.updatedByFirstName,
      updatedByLastName: this.updatedByLastName,
    }
    return this.storedFormat
  }

  fillFromJson({
    id,
    name,
    date,
    fiduciaryStructureId,
    additionalData,
    status,
    document,
    updated,
    approvalRule,
    canDelete,
    storedAt,
    canResolve,
    approveAutomatically = false,
    requiresVoting = false,
    votersCanAbstain = false,
    votingResult,
    resolution,
    votingResultDocument,
    checksum,
    blockchainAddress,
  }) {
    const translation = `transactionsCreation:${votingResult}`

    this.name.setValue(name)
    this.date.setValue(moment(date))
    this.approvalRule.setValue(approvalRule)
    this.checksum.setValue(checksum)
    this.blockchainAddress.setValue(blockchainAddress)
    this.status.setStatus(status)
    this.votingResultResolution.setValue(resolution)
    this.storedAt.setValue(storedAt)
    this.setUpdated(updated)

    this.id = id
    this.additionalData = additionalData
    this.fiduciaryStructureId = fiduciaryStructureId
    this.approveAutomatically = approveAutomatically
    this.requiresVoting = requiresVoting
    this.canDelete = canDelete
    this.canResolve = canResolve
    this.votersCanAbstain = votersCanAbstain

    if (votingResult === 'approved' || votingResult === 'rejected') {
      this.votingResult.setValue({
        id: votingResult,
        value: i18next.t(translation),
      })
    }

    if (document) {
      this.document = File.fromJson(document)
    }

    if (votingResultDocument) {
      this.votingResultDocument = File.fromJson(votingResultDocument)
    }
  }

  updateFromTransaction(transaction) {
    this.fillFromTransaction(transaction)
  }

  static fromJson(json) {
    const transaction = new Transaction()

    transaction.fillFromJson(json)

    return transaction
  }

  static createFromTransaction(transaction) {
    const newTransaction = new Transaction()

    newTransaction.fillFromTransaction(transaction)

    return newTransaction
  }

  setApproveAutomatically() {
    this.approveAutomatically = !this.approveAutomatically
  }

  setFiduciaryStructureId(value) {
    this.fiduciaryStructureId = value
  }

  setName(value) {
    this.name.setValue(value)
  }

  setDate(value) {
    this.date.setValue(value)
  }

  setDescription(value) {
    this.additionalData.description.setValue(value)
  }
}

export default Transaction
