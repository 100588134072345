import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import LoadingRing from 'presentation/LoadingRing'
import RuleGlobalView from 'presentation/RuleGlobalView'
import CreationModal from 'presentation/CreationModal'
import { useTranslation } from 'react-i18next'
import RuleCreationForm from 'presentation/RuleCreationForm'
import RuleAssetsCreation from 'presentation/RuleAssetsCreation'
import RuleViewItem from 'presentation/RuleViewItem'
import RuleAdvancedConfigModalForm from 'presentation/RuleAdvancedConfigModalForm'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import Rule from 'models/Rule'
import isMobile from 'util/browser'
import c from 'classnames'
import styles from './ruleViewForm.module.scss'

const RuleViewForm = ({
  rule,
  deleteRule,
  handleSaveRule,
  handleSelectRule,
  handleOpenCardToTextFromList,
  handleScrollToTextFromList,
  viewMode,
  trustBeneficiaries,
  trustGovernors,
  trustAssets,
}) => {
  const { t } = useTranslation('rulesCreation')

  const [visibleAssetsModal, setVisibleAssetsModal] = useState(false)
  const [editRule, setEditRule] = useState(null)
  const [showConfigModal, setShowConfigModal] = useState(false)
  const { selected, highlighted, edition, isLoading } = rule

  const handleDeleteRule = useCallback((e) => {
    deleteRule(rule)
    e.stopPropagation()
  }, [])

  const handleEditRule = useCallback((e) => {
    const activeRule = e.currentTarget.parentNode.parentNode
    const rulesScrollableList = activeRule.parentNode

    rule.setEdition()

    setTimeout(() => {
      rulesScrollableList.scrollTo({
        top: activeRule.offsetTop,
        behavior: 'smooth',
      })
    }, 300)

    e.stopPropagation()
  }, [])

  const handleChangeTitle = useCallback((e) => {
    rule.title.setValue(e.target.value)
    if (rule.title.value !== '') rule.title.clearError()
  }, [])

  const handleSave = useCallback((e, removeEdition = true) => {
    if (rule.title.value === '') {
      rule.title.setError(true, t('titleError'))
    } else {
      handleSaveRule(rule)
      if (removeEdition) {
        rule.removeEdition()
      }
    }

    if (e) e.stopPropagation()
  }, [])

  const handleCancel = useCallback((e) => {
    if (rule.saved) {
      rule.revertChangesEdition()
      rule.removeEdition()
    } else {
      deleteRule(rule)
    }

    if (e) {
      e.stopPropagation()
    }
  }, [])

  const hideAssetsModal = useCallback(() => setVisibleAssetsModal(false), [])
  const showAssetsModal = useCallback(() => {
    rule.setEdition()
    rule.cleanUpAssets()
    setEditRule(Rule.createFromRule(rule))
    setVisibleAssetsModal(true)
  }, [rule])

  const cancelManageAssets = useCallback(() => {
    if (editRule) {
      rule.fillFromRule(editRule)
    }

    setEditRule(null)
    hideAssetsModal()

    if (rule.saved) {
      rule.revertChangesEdition()
    }
  }, [editRule])

  const saveManageAssets = useCallback((e) => {
    hideAssetsModal()
    handleSave(e, false)
  }, [])

  const closeModal = useCallback(() => {
    if (rule.graphicalView) {
      rule.hideGraphicalView()
    } else {
      cancelManageAssets()
    }
  }, [])

  const hideGlobalViewModal = useCallback(() => {
    rule.hideGraphicalView()
  }, [])

  const changeToEditionMode = useCallback(() => {
    hideGlobalViewModal()
    showAssetsModal()
  }, [])

  const handleChangeDescription = useCallback((e) => rule.description.setValue(e.target.value), [])
  const handleBoxClick = useCallback(() => handleSelectRule(rule), [])
  const handleShowTextClick = useCallback(() => handleOpenCardToTextFromList(rule), [])
  const handleShowScrollTextClick = useCallback(() => handleScrollToTextFromList(rule), [])

  const handleBoxMouseOver = useCallback(() => {
    if (!edition && !selected) rule.highlight()
  }, [])

  const handleBoxMouseOut = useCallback(() => {
    if (!edition && !selected) rule.dim()
  }, [])

  const handleShowAdvancedConfigModal = useCallback(() => {
    setShowConfigModal(true)
  }, [])

  const handleHideAdvancedConfigModal = useCallback(() => {
    setShowConfigModal(false)
  }, [])

  const handleCancelConfigModal = useCallback(() => {
    rule.resetAdvancedConfig()
    setShowConfigModal(false)
  }, [])

  const handleOkConfigModal = useCallback(() => {
    setShowConfigModal(false)
  }, [])

  const bgColor = rule.color.fade(0.8).toString()
  const highlightColor = rule.color.toString()

  return (
    <div
      className={c(
        styles.ruleDisplay,
        (highlighted || selected) && styles.hover,
        selected && styles.selected,
        rule.listElementId,
        edition && styles.edition
      )}
      style={{ borderColor: highlighted || selected ? highlightColor : bgColor }}
      onMouseOver={handleBoxMouseOver}
      onFocus={handleBoxMouseOver}
      onBlur={handleBoxMouseOut}
      onMouseOut={handleBoxMouseOut}
      onClick={isMobile() ? handleBoxClick : handleShowTextClick}
      role="button"
      tabIndex="0"
    >
      {isLoading && (
        <div className={styles.loading}>
          <LoadingRing small />
        </div>
      )}
      {edition && !isLoading && (
        <RuleCreationForm
          rule={rule}
          handleChangeTitle={handleChangeTitle}
          handleChangeDescription={handleChangeDescription}
          handleSave={handleSave}
          handleCancel={handleCancel}
          trustAssets={trustAssets}
          trustBeneficiaries={trustBeneficiaries}
          showAssetsModal={showAssetsModal}
          showConfigModal={handleShowAdvancedConfigModal}
          hideConfigModal={handleHideAdvancedConfigModal}
        />
      )}
      {!edition && !isLoading && (
        <RuleViewItem
          rule={rule}
          deleteRule={handleDeleteRule}
          handleEditRule={handleEditRule}
          handleShowTextClick={handleShowTextClick}
          handleShowScrollTextClick={handleShowScrollTextClick}
          selected={selected}
          viewMode={viewMode}
          trustAssets={trustAssets}
          trustBeneficiaries={trustBeneficiaries}
        />
      )}
      <CreationModal
        title={
          rule.graphicalView ? t('assetDiagramTitle') : t('trustsCreation:manageAssetsForRule')
        }
        visible={rule.graphicalView || visibleAssetsModal}
        handleClose={closeModal}
        outsideClickClose
        usePortal
      >
        {rule.graphicalView ? (
          <RuleGlobalView
            trustBeneficiaries={trustBeneficiaries}
            trustGovernors={trustGovernors}
            trustAssets={trustAssets}
            rule={rule}
            changeToEdit={changeToEditionMode}
            handleCancel={hideGlobalViewModal}
            canEdit={!viewMode}
          />
        ) : (
          <RuleAssetsCreation
            rule={rule}
            handleCancel={cancelManageAssets}
            handleSave={saveManageAssets}
          />
        )}
      </CreationModal>
      <CreationModal
        handleClose={handleHideAdvancedConfigModal}
        visible={showConfigModal}
        title={t('trustsCreation:advancedConfig')}
        outsideClickClose
        usePortal
      >
        <RuleAdvancedConfigModalForm
          rule={rule}
          handleCancel={handleCancelConfigModal}
          handleOk={handleOkConfigModal}
        />
      </CreationModal>
    </div>
  )
}

RuleViewForm.propTypes = {
  rule: PropTypes.instanceOf(Rule).isRequired,
  deleteRule: PropTypes.func.isRequired,
  handleSaveRule: PropTypes.func.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  trustGovernors: PropTypes.arrayOf(PropTypes.instanceOf(RuleAssetGovernor)),
  handleSelectRule: PropTypes.func.isRequired,
  handleOpenCardToTextFromList: PropTypes.func.isRequired,
  handleScrollToTextFromList: PropTypes.func.isRequired,
  viewMode: PropTypes.bool,
}

RuleViewForm.defaultProps = {
  viewMode: false,
  trustGovernors: null,
}

export default observer(RuleViewForm)
