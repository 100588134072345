import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import c from 'classnames'
import PropTypes from 'prop-types'
import EntityStatus from 'models/TransactionStatus'
import styles from './transactionStatus.module.scss'

const TransactionStatus = observer(({ status, onlyBullet, t }) => {
  return (
    <div className={c(styles.statusContainer, onlyBullet && styles.onlyBullet)}>
      <span
        className={c(
          styles.bullet,
          styles.statusVoting,
          status.isApproved && styles.statusApproved,
          status.isFinish && styles.statusFinish,
          status.isRejected && styles.statusRejected,
          status.isCancel && styles.statusCancel,
          status.isStored && styles.statusResolved
        )}
        title={t(status.status)}
      />
      <div className={c(styles.status)}>{t(status.status)}</div>
    </div>
  )
})

TransactionStatus.propTypes = {
  status: PropTypes.instanceOf(EntityStatus).isRequired,
  onlyBullet: PropTypes.bool,
}

TransactionStatus.defaultProps = {
  status: new EntityStatus(),
}

export default withTranslation('transactionsCreation')(TransactionStatus)
