import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RuleAssetGovernor from 'models/Rule/RuleAssetGovernor'
import RulesTextHighlighter from 'presentation/RulesTextHighlighter'
import RulesScrollableList from 'presentation/RulesScrollableList'
import RulesAmendmentTitle from 'presentation/RulesAmendmentTitle'
import NoResultsList from 'presentation/NoResultsList'
import LoadingRing from 'presentation/LoadingRing'
import RulesStore from '../RulesStore'
import styles from '../rulesSection.module.scss'

const DeedOfTrustRulesSection = ({
  rulesStore,
  selectionMode,
  unSelectAllRules,
  handleDeleteRule,
  handleSaveRule,
  handleSelectRuleFromList,
  handleOpenCardToTextFromList,
  handleScrollToTextFromList,
  handleSelectRuleFromText,
  handleAdd,
  listRef,
  trustAssets,
  trustBeneficiaries,
  trustGovernors,
  isLoading,
  viewMode /* , handleAddChunk */,
}) => {
  const { t } = useTranslation('trustsCreation')
  const { deedsStore } = rulesStore.trustCreationStore

  if (deedsStore.isLoading || isLoading) {
    return (
      <div className={styles.fullPage}>
        <LoadingRing />
      </div>
    )
  }

  const { deedOfTrustForRules, amendmentsForRules } = deedsStore

  if (
    deedOfTrustForRules === null ||
    deedOfTrustForRules.text.value === null ||
    deedOfTrustForRules.text.value === ''
  ) {
    return (
      <div className={styles.fullPage}>
        <NoResultsList showIcon={false} topText={t('noDeedOfTrustCreated')} />
      </div>
    )
  }

  rulesStore.setDeedOfTrustText(deedOfTrustForRules.text.value)

  amendmentsForRules.map((amendment) =>
    rulesStore.setAmendmentText(amendment.id, amendment.text.value)
  )

  return (
    <div className={styles.rulesSectionContainer}>
      <div className={styles.rules}>
        <RulesScrollableList
          rules={rulesStore.deedSectionRules}
          trustAssets={trustAssets}
          trustBeneficiaries={trustBeneficiaries}
          trustGovernors={trustGovernors}
          handleDeleteRule={handleDeleteRule}
          handleSaveRule={handleSaveRule}
          handleSelectRule={handleSelectRuleFromList}
          handleScrollToTextFromList={handleScrollToTextFromList}
          handleOpenCardToTextFromList={handleOpenCardToTextFromList}
          listRef={listRef}
          isFiltered={rulesStore.assetsFilter.length || rulesStore.beneficiariesFilter.length}
          viewMode={viewMode}
        />
      </div>
      <div className={styles.text}>
        <RulesTextHighlighter
          text={deedOfTrustForRules.text.value}
          selectionMode={selectionMode}
          unSelectAllRules={unSelectAllRules}
          id="deedOfTrust"
          parsedRules={rulesStore.deedOfTrustRules}
          handleSelectRule={handleSelectRuleFromText}
          onAdd={handleAdd}
        />
        {amendmentsForRules.length > 0 &&
          amendmentsForRules.map((amendment) => (
            <Fragment key={`amendment_${amendment.id}`}>
              <RulesAmendmentTitle amendment={amendment} />
              <RulesTextHighlighter
                text={amendment.text.value}
                selectionMode={selectionMode}
                unSelectAllRules={unSelectAllRules}
                id={`amendment_${amendment.id}`}
                parsedRules={rulesStore.amendmentRules(amendment.id)}
                handleSelectRule={handleSelectRuleFromText}
                onAdd={handleAdd}
              />
            </Fragment>
          ))}
      </div>
    </div>
  )
}

DeedOfTrustRulesSection.propTypes = {
  rulesStore: PropTypes.instanceOf(RulesStore).isRequired,
  unSelectAllRules: PropTypes.func.isRequired,
  handleDeleteRule: PropTypes.func.isRequired,
  handleSaveRule: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  trustGovernors: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.instanceOf(RuleAssetGovernor), value: PropTypes.string })
  ).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleSelectRuleFromList: PropTypes.func.isRequired,
  handleOpenCardToTextFromList: PropTypes.func.isRequired,
  handleScrollToTextFromList: PropTypes.func.isRequired,
  handleSelectRuleFromText: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  /* handleAddChunk: PropTypes.func.isRequired, */
  selectionMode: PropTypes.bool,
  listRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  viewMode: PropTypes.bool,
}

DeedOfTrustRulesSection.defaultProps = {
  selectionMode: false,
  listRef: false,
  viewMode: false,
}

export default observer(DeedOfTrustRulesSection)
