import Rule from './Rule'
import RuleAsset from './RuleAsset'
import RuleAssetBeneficiary from './RuleAssetBeneficiary'
import RuleAssetBeneficiaryDistribution, {
  TYPE_PERCENTAGE,
  TYPE_VALUE,
} from './RuleAssetBeneficiaryDistribution'

export default Rule
export {
  RuleAsset,
  RuleAssetBeneficiary,
  RuleAssetBeneficiaryDistribution,
  TYPE_PERCENTAGE,
  TYPE_VALUE,
}
