import Part, { TYPE_STATUTES } from './Part'

class StatutesPart extends Part {
  constructor(id) {
    super(id, TYPE_STATUTES)
  }

  static getInstance() {
    return new StatutesPart('statutes')
  }
}

export default StatutesPart
