import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import MenuButton from 'presentation/MenuButton'
import FormSection from 'models/FormMetadata'

const FormSectionMenuButton = ({
  url,
  formSection,
  onClick,
  disabled,
  iconProps,
  collapsed,
  countPartErrors,
}) => {
  return (
    <MenuButton
      menuIconName={formSection.menuIcon}
      menuLabel={formSection.menuLabel}
      errors={countPartErrors}
      iconProps={iconProps}
      collapsed={collapsed}
      disabled={disabled}
      onClick={onClick}
      url={url}
    />
  )
}

FormSectionMenuButton.propTypes = {
  url: PropTypes.string.isRequired,
  countPartErrors: PropTypes.number,
  formSection: PropTypes.instanceOf(FormSection).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool,
  iconProps: PropTypes.shape({
    size: PropTypes.number,
  }),
}

FormSectionMenuButton.defaultProps = {
  countPartErrors: 0,
  iconProps: {},
  collapsed: false,
}

export default observer(FormSectionMenuButton)
