import Part, { TYPE_LODS } from './Part'

class LodsPart extends Part {
  constructor(id) {
    super(id, TYPE_LODS)
  }

  static getInstance() {
    return new LodsPart('lows')
  }
}

export default LodsPart
