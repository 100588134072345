import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import RulesStore from 'scenes/TrustsCreation/RulesSection/RulesStore'
import c from 'classnames'
import FormSelect from 'presentation/FormSelect'
import CreationInput from 'presentation/CreationInput'
import styles from './rulesSearch.module.scss'

const RulesSearch = observer(({ rulesStore, assets, beneficiaries, selectionMode, view }) => {
  const { t } = useTranslation('rulesCreation')
  const handleChangeAssets = useCallback((options) => rulesStore.setAssetsFilter(options))
  const handleChangeBeneficiaries = useCallback((options) =>
    rulesStore.setBeneficiariesFilter(options)
  )

  const assetsOptions = assets.map((asset) => {
    return { id: asset.id, value: asset.name.value }
  })

  const beneficiariesOptions = beneficiaries.map((beneficiary) => {
    return { id: beneficiary.id, value: beneficiary.name.value }
  })

  return (
    <div className={c(styles.searchContainer, !view && styles.hideSearch)}>
      <div className={styles.inputsContainer}>
        <CreationInput
          as={FormSelect}
          value={rulesStore.assetsFilter}
          disabled={selectionMode}
          label={t('filterAssets')}
          isMulti
          placeholder={t('assetsInputPlaceholder')}
          onChange={handleChangeAssets}
          options={assetsOptions}
        />
        <CreationInput
          as={FormSelect}
          value={rulesStore.beneficiariesFilter}
          disabled={selectionMode}
          label={t('filterBeneficiaries')}
          isMulti
          placeholder={t('beneficiariesInputPlaceholder')}
          onChange={handleChangeBeneficiaries}
          options={beneficiariesOptions}
        />
      </div>
    </div>
  )
})

RulesSearch.propTypes = {
  rulesStore: PropTypes.instanceOf(RulesStore).isRequired,
  view: PropTypes.bool.isRequired,
}

export default RulesSearch
