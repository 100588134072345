import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import * as Routes from 'routing/routes'
import { useTranslation } from 'react-i18next'
import MenuButton from 'presentation/MenuButton'
import TrustChangeStatusButton from 'presentation/TrustChangeStatusButton'
import DynamicFormSubMenu from 'presentation/DynamicFormSubMenu'
import StoresContext from 'providers/storeContext'
import { SubMenu, subMenuUrl, toggleNavBar } from './SubMenuUtils'

const SubMenuTrust = observer(({ id: idTrust, view, collapsed }) => {
  const { trustsCreationStore, navBarStore } = useContext(StoresContext)
  const { t } = useTranslation('navBar')

  useEffect(() => {
    if (trustsCreationStore.errorLoadEdit) {
      navBarStore.setForcedOnlyMainNavBar()
    }
  }, [trustsCreationStore.errorLoadEdit])

  const getSubMenuUrl = (url, smIdView, smView, disabled) =>
    subMenuUrl(url, smIdView, smView, disabled, Routes.TRUSTS_CREATION)

  return (
    <SubMenu collapsed={collapsed}>
      <DynamicFormSubMenu
        dynamicFormsStore={trustsCreationStore}
        sectionUrlFunction={getSubMenuUrl}
        toggleNavBar={toggleNavBar}
        entityTypeName={t('trust')}
        collapsed={collapsed}
        entityId={idTrust}
        view={view}
        changeStatusButton={
          <TrustChangeStatusButton toggleNavBar={toggleNavBar} collapsed={collapsed} />
        }
        extraMenuButtons={
          <>
            {trustsCreationStore.entityStatus && trustsCreationStore.entityStatus.canViewRules && (
              <MenuButton
                url={getSubMenuUrl(Routes.TRUSTS_CREATION_RULES_SHORT, idTrust, view)}
                onClick={toggleNavBar}
                disabled={trustsCreationStore.subMenuDisabled}
                iconProps={{ size: 17 }}
                menuIconName="CardTextOutlineIcon"
                menuLabel={t('rules')}
                collapsed={collapsed}
              />
            )}
            {trustsCreationStore.entityStatus &&
              trustsCreationStore.entityStatus.canViewSignedDocuments && (
                <MenuButton
                  url={getSubMenuUrl(Routes.TRUSTS_CREATION_SIGNED_DOCUMENTS_SHORT, idTrust, view)}
                  onClick={toggleNavBar}
                  disabled={trustsCreationStore.subMenuDisabled}
                  iconProps={{ size: 17 }}
                  menuIconName="PenIcon"
                  menuLabel={t('signedDocs')}
                  errors={trustsCreationStore.signedDocsErrors}
                  collapsed={collapsed}
                />
              )}
          </>
        }
      />
    </SubMenu>
  )
})

export default SubMenuTrust
