import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InputStore from 'stores/InputStore'
import YesNoToggleContainer from 'presentation/YesNoToggleContainer'
import FormPercentage from 'scenes/DynamicForm/DynamicFormPartPercentage/FormPercentage'
import PercentageFormPartStore from 'models/FormMetadata/Parts/PercentageFormPartStore'
import OwnershipTransactionAsset from 'models/OwnershipTransactionAsset'
import styles from './trustOwnershipForm.module.scss'

const TrustOwnershipForm = ({
  currentPercentageStore,
  currentAsset,
  hasVoting,
  handleHasVotingChange,
}) => {
  const { t } = useTranslation('transactions')

  return (
    <div>
      <FormPercentage
        inputStore={currentPercentageStore}
        label={`${currentAsset.name}'s ${t('percentageLabel')}`}
        literal={false}
      />
      <div className={styles.voting}>
        <YesNoToggleContainer
          label={t('withVoting')}
          value={!!hasVoting.value}
          handleChange={handleHasVotingChange}
          desc={t('withVoting')}
        />
      </div>
    </div>
  )
}

TrustOwnershipForm.propTypes = {
  currentPercentageStore: PropTypes.instanceOf(PercentageFormPartStore).isRequired,
  currentAsset: PropTypes.instanceOf(OwnershipTransactionAsset).isRequired,
  hasVoting: PropTypes.instanceOf(InputStore).isRequired,
  handleHasVotingChange: PropTypes.func,
}

TrustOwnershipForm.defaultProps = {
  handleHasVotingChange: () => {},
}

export default observer(TrustOwnershipForm)
