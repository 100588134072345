import { observable, action, computed, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import FormSection from 'models/FormMetadata'

class DynamicFormsStore extends AsyncStore {
  idEdition = null
  isEdition = false
  viewMode = false
  loadingSave = false
  isUploadingFile = false
  errorLoadEdit = false
  lastSaveDate = null
  step1Finished = false
  visibleActionButtons = true
  entityStatus = null
  entitySections = []
  inputs = observable.map()
  authStore = null
  entityNameKeys = []

  constructor(authStore = null) {
    super()

    this.isLoading = true

    makeObservable(this, {
      // observables
      idEdition: observable,
      isEdition: observable,
      viewMode: observable,
      loadingSave: observable,
      isUploadingFile: observable,
      errorLoadEdit: observable,
      lastSaveDate: observable,
      step1Finished: observable,
      visibleActionButtons: observable,
      entityStatus: observable,
      entitySections: observable,
      inputs: observable,
      // actions
      resetAttributes: action,
      setIsEdition: action,
      setEntityStatus: action,
      setViewMode: action,
      removeViewMode: action,
      setLastSaveDate: action,
      toggleViewMode: action,
      fillDataInputs: action,
      startUploadingFile: action,
      finishUploadingFile: action,
      setStep1Finished: action,
      resetEntitySections: action,
      addEntitySection: action,
      processMetadata: action,
      showActionButtons: action,
      hideActionButtons: action,
      // computeds
      isViewMode: computed,
      isEditionMode: computed,
      isCreation: computed,
      canSave: computed,
      entityName: computed,
      subMenuDisabled: computed,
    })

    this.authStore = authStore
  }

  setEntityNameKey(key, alternativeKey = null) {
    this.entityNameKeys = []
    this.entityNameKeys.push(key)

    if (alternativeKey) {
      this.entityNameKeys.push(alternativeKey)
    }
  }

  resetAttributes() {
    this.idEdition = null
    this.isEdition = false
    this.viewMode = false
    this.loadingSave = false
    this.isUploadingFile = false
    this.errorLoadEdit = false
    this.lastSaveDate = null
    this.step1Finished = false
    this.visibleActionButtons = true
    this.entitySections = []
    this.inputs = observable.map()
  }

  setIsEdition(id) {
    this.isEdition = true
    this.idEdition = id
  }

  setEntityStatus(entityStatus) {
    this.entityStatus = entityStatus
  }

  setViewMode() {
    this.viewMode = true
  }

  removeViewMode() {
    this.viewMode = false
  }

  setLastSaveDate(value) {
    this.lastSaveDate = value
  }

  toggleViewMode(viewMode) {
    if (viewMode === true) {
      this.setViewMode()
    } else {
      this.removeViewMode()
    }
  }

  fillDataInputs(data) {
    this.inputs.forEach((input, key) => {
      if (data[key] !== undefined) {
        input.fill(data[key])
      }
    })
  }

  startUploadingFile() {
    this.isUploadingFile = true
  }

  finishUploadingFile() {
    this.isUploadingFile = false
  }

  setStep1Finished() {
    this.step1Finished = true
  }

  resetEntitySections() {
    this.entitySections = []
  }

  addEntitySection(section) {
    this.entitySections.push(section)
  }

  processMetadata(metadata) {
    this.resetEntitySections()

    if (metadata?.sections) {
      metadata.sections.forEach((formSection) => {
        this.addEntitySection(FormSection.fromJson(formSection, this.onCreateInput))
      })
      return
    }

    metadata.forEach((formSection) => {
      this.addEntitySection(FormSection.fromJson(formSection, this.onCreateInput))
    })
  }

  showActionButtons() {
    this.visibleActionButtons = true
  }

  hideActionButtons() {
    this.visibleActionButtons = false
  }

  // eslint-disable-next-line class-methods-use-this
  checkFirstStepComplete() {}

  onCreateInput = (key, input) => {
    this.inputs.set(key, input)
  }

  get isViewMode() {
    return this.viewMode
  }

  get isEditionMode() {
    return this.isEdition && !this.viewMode
  }

  get isCreation() {
    return !this.idEdition
  }

  get canSave() {
    return !this.isUploadingFile
  }

  get entityName() {
    let entityNameValue = ''
    this.entityNameKeys.forEach((entityNameKey) => {
      if (this.inputs.get(entityNameKey)) {
        entityNameValue = `${entityNameValue} ${this.inputs.get(entityNameKey).store.value}`
      }
    })

    if (entityNameValue !== '') {
      return entityNameValue
    }

    return null
  }

  get subMenuDisabled() {
    return !this.step1Finished
  }

  canViewSection(formSection) {
    if (this.authStore.can(formSection.permission)) {
      if (this.isCreation) {
        return formSection.isVisibleCreation()
      }

      if (this.isEditionMode) {
        return formSection.isVisibleEdition()
      }

      if (this.isViewMode) {
        return formSection.isVisibleView()
      }
    }

    return false
  }
}

export default DynamicFormsStore
