import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LiteralValue from 'presentation/LiteralValue'
import styles from './assetActivityModalForm.module.scss'

const AssetActivityModalForm = ({ valuation }) => {
  const { t } = useTranslation('activity')

  if (!valuation) {
    return null
  }

  return (
    <div className={styles.valuationChangeContainer}>
      <div className={styles.field}>{t('valuation')}</div>
      <div className={styles.currentValue}>
        {valuation &&
          valuation.currentValue.map((value) => (
            <LiteralValue
              label={t('current')}
              value={`${value.amount} ${value.currency}`}
              borderBottom
            />
          ))}
      </div>
      <div className={styles.newValue}>
        {valuation &&
          valuation.newValue.map((value) => (
            <LiteralValue
              key={value.currency}
              label={t('new')}
              value={`${value.amount} ${value.currency}`}
              borderBottom
            />
          ))}
      </div>
    </div>
  )
}

AssetActivityModalForm.propTypes = {
  valuation: PropTypes.shape({
    // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
    currentValue: PropTypes.arrayOf(PropTypes.shape({})),
    // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
    newValue: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default AssetActivityModalForm
