import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { EntityListPart } from 'models/FormMetadata'
import ArrowNavGrid from 'presentation/ArrowNavGrid'
import Button from 'presentation/Button'
import Icon from 'presentation/Icon'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'

const MAX_COLUMNS = 4
const FIXED_COLUMN_WIDTH = '180px'

const getColumns = (items) => (items.length > MAX_COLUMNS ? MAX_COLUMNS : items.length)
const getColumnsWidth = (columns) => (columns === MAX_COLUMNS ? '1fr' : FIXED_COLUMN_WIDTH)

const CreateEntityModalButtons = ({ part, createEntity }) => {
  const { t } = useTranslation('dynamicForms')
  const columns = getColumns(part.entities)

  return (
    <>
      <DynamicFormSubtitle label={t('selectEntityType', { name: part.entityName })} />
      <ArrowNavGrid columns={columns} colSize={getColumnsWidth(columns)}>
        {part.entities.map((entity) => (
          <Button
            icon={<Icon name={entity.icon} size={20} />}
            onClick={() => createEntity(entity)}
            key={`entityButton_${entity.name}`}
            label={entity.name}
            iconPositionLeft
            as="div"
            fullWidth
            big
          />
        ))}
      </ArrowNavGrid>
    </>
  )
}

CreateEntityModalButtons.propTypes = {
  part: PropTypes.instanceOf(EntityListPart).isRequired,
  createEntity: PropTypes.func.isRequired,
}

CreateEntityModalButtons.defaultProps = {}

export default CreateEntityModalButtons
