import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Letter from 'models/Letters'
import CreationInput from 'presentation/CreationInput'
import TextArea from 'presentation/TextArea'
import InputFile from 'presentation/InputFile'
import StoresContext from 'providers/storeContext'
import styles from './letterForm.module.scss'

/* eslint-disable class-methods-use-this */
class LetterForm extends React.Component {
  handleChangeTitle = (e) => {
    const { letter, validationHandler } = this.props

    letter.changeTitle(e.target.value)

    if (validationHandler) {
      validationHandler()
    }
  }

  handleChangeText = (e) => {
    const { letter, validationHandler } = this.props

    letter.changeText(e.target.value)

    if (validationHandler) {
      validationHandler()
    }
  }

  handleStartUploadingFile = () => {
    const { trustsCreationStore } = this.context

    trustsCreationStore.startUploadingFile()
  }

  handleFinishUploadingFile = () => {
    const { trustsCreationStore } = this.context

    trustsCreationStore.finishUploadingFile()
  }

  render() {
    const { viewMode, letter } = this.props

    return (
      <div className={styles.formContainer}>
        <CreationInput
          inputStore={letter.title}
          name="letterTitle"
          onChange={this.handleChangeTitle}
          literal={viewMode}
        />
        <CreationInput
          as={TextArea}
          inputStore={letter.text}
          name="letter"
          onChange={this.handleChangeText}
          minRows={20}
          literal={viewMode}
          fullHeightLabel
        />
        <CreationInput
          as={InputFile}
          fileStore={letter.file}
          name="letterFile"
          literal={viewMode}
          handleStartUploadingFile={this.handleStartUploadingFile}
          handleFinishUploadingFile={this.handleFinishUploadingFile}
        />
        {letter.signedFile && letter.signedFile.id && (
          <CreationInput
            as={InputFile}
            fileStore={letter.signedFile}
            name="letterFileSigned"
            handleStartUploadingFile={null}
            handleFinishUploadingFile={null}
            literal
          />
        )}
      </div>
    )
  }
}

LetterForm.contextType = StoresContext

LetterForm.propTypes = {
  letter: PropTypes.instanceOf(Letter).isRequired,
  validationHandler: PropTypes.func,
  viewMode: PropTypes.bool,
}

LetterForm.defaultProps = {
  viewMode: false,
  validationHandler: null,
}

LetterForm.defaultProps = {}

export default observer(LetterForm)
