import { successToast } from 'helpers/Toast'

const saveCorporation = (corporationsCreationStore, toastText, history = null, redirectRoute) => {
  corporationsCreationStore.save((id) => {
    successToast(toastText, null, 'savedCorporation').show()

    const firstLoad = !corporationsCreationStore.isEdition

    if (!corporationsCreationStore.isEdition) {
      corporationsCreationStore.setIsEdition(id)

      // Mueve al item siguiente de Corporation info
      history.push(`${redirectRoute}?id=${id}`)
    }

    corporationsCreationStore.reloadData(!firstLoad)
  })
}

export default saveCorporation
