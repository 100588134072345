import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import { API_URL } from 'services/config'

const ZOOM_STEP = 0.1
const MOVE_STEP = 10

class FinancialStructureStore {
  moveInterval = null
  onMove = false
  data = []
  treeConfig = {
    zoom: 1,
    translate: { x: 0, y: 0 },
  }

  currentConfigTimeout = null

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentTreeConfig({ zoom, x, y }) {
    const newConfig = { zoom, translate: { x, y } }
    if (JSON.stringify(newConfig) !== JSON.stringify(this.treeConfig)) {
      if (!this.onMove) this.onMove = true
      if (this.currentConfigTimeout) clearTimeout(this.currentConfigTimeout)
      this.currentConfigTimeout = setTimeout(() => {
        this.onMove = false
        this.treeConfig = newConfig
      }, 500)
    }
  }

  getData(id) {
    const renameKey = (element, oldKey, newKey) => {
      const newObject = {}
      Object.assign(newObject, element, { [newKey]: element[oldKey] })
      delete newObject[oldKey]

      if (element.children && element.children.length > 0) {
        for (let i = 0; i < element.children.length; i += 1) {
          newObject.children[i] = renameKey(element.children[i], oldKey, newKey)
        }
      }

      return newObject
    }

    return axios.get(`${API_URL}/tenant/clients/${id}/tree`).then((response) => {
      runInAction(() => {
        this.data = [renameKey(response.data.data, 'id', 'idElement')]
      })
    })
  }

  setConfig({ zoom, x, y }) {
    const newConfig = Object.assign(this.treeConfig)

    if (zoom || zoom === 0) newConfig.zoom = zoom
    if (x || x === 0) newConfig.translate.x = x
    if (y || y === 0) newConfig.translate.y = y

    this.treeConfig = newConfig
  }

  changeZoom(value) {
    const newConfig = { ...this.treeConfig }

    switch (value) {
      case 'in':
        newConfig.zoom += ZOOM_STEP
        break
      case 'out':
        newConfig.zoom -= ZOOM_STEP
        break
      case 'doubleOut':
        newConfig.zoom -= 2 * ZOOM_STEP
        break
      default:
    }

    this.setConfig({
      zoom: newConfig.zoom && newConfig.zoom,
    })

    return newConfig.zoom && newConfig.zoom
  }

  move(direction) {
    this.moveInterval = setInterval(() => {
      const newConfig = { ...this.treeConfig.translate }

      switch (direction) {
        case 'up':
          newConfig.y -= MOVE_STEP
          break
        case 'down':
          newConfig.y += MOVE_STEP
          break
        case 'left':
          newConfig.x -= MOVE_STEP
          break
        case 'right':
          newConfig.x += MOVE_STEP
          break
        default:
      }

      this.setConfig({
        x: newConfig.x && newConfig.x,
        y: newConfig.y && newConfig.y,
      })
    }, 50)
  }

  stopMove() {
    clearInterval(this.moveInterval)
  }
}

export default FinancialStructureStore
