import { observable, action, runInAction, makeObservable } from 'mobx'
import TrustBeneficiariesService from 'services/TrustBeneficiariesService'
import BeneficiaryListItem from 'models/BeneficiaryListItem'
import AsyncStore from './AsyncStore'

class TrustBeneficiariesStore extends AsyncStore {
  beneficiaries = []

  constructor(trustId) {
    super()

    makeObservable(this, {
      // observables
      beneficiaries: observable,
      // actions
      loadBeneficiaries: action,
    })

    this.trustId = trustId
    this.trustBeneficiariesService = new TrustBeneficiariesService()
  }

  async loadBeneficiaries(alternativeStatus) {
    this.preRequest()

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.trustBeneficiariesService.listBeneficiaries(
        this.trustId,
        alternativeStatus
      )

      runInAction(() => {
        this.beneficiaries = []

        response.forEach((json) =>
          this.beneficiaries.push(BeneficiaryListItem.createFromJson(json))
        )
        this.onSuccessRequest()
      })
    } catch (e) {
      throw e
    }
  }
}

export default TrustBeneficiariesStore
