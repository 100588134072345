import LettersSectionBaseStore from './LettersSectionBaseStore'

class LetterOfWishesSectionStore extends LettersSectionBaseStore {
  constructor(lettersStore) {
    super()

    this.lettersStore = lettersStore
    this.letterStore = lettersStore
  }
}

export default LetterOfWishesSectionStore
