import Part, { TYPE_LOWS } from './Part'

class LowsPart extends Part {
  constructor(id) {
    super(id, TYPE_LOWS)
  }

  static getInstance() {
    return new LowsPart('lows')
  }
}

export default LowsPart
