/* eslint-disable no-param-reassign */
import axios from 'axios'

const axiosInterceptors = (rootStore) => {
  // Handles all 403 requests
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status > 403) {
        error.response.unknown = true
      }

      return Promise.reject(error)
    }
  )

  // Handles all 401 requests
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        rootStore.authStore.logout()
      }

      return Promise.reject(error)
    }
  )

  // set error for redirect maintenance page
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 503) {
        rootStore.authStore.setIsMaintenancePage(true)
      }

      return Promise.reject(error)
    }
  )

  // Adds bearer to all API requests
  axios.interceptors.request.use(
    (config) => {
      if (rootStore.authStore.isAuthenticated) {
        config.headers.Authorization = `Bearer ${
          rootStore.authStore.authUser ? rootStore.authStore.authUser.token : null
        }`
      }

      // Do something before request is sent
      return config
    },
    (error) => Promise.reject(error)
  )

  // Token update
  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.metadata?.token) {
        rootStore.authStore.updateToken(response.data.metadata.token.jwt) // Update token in the store
      }
      return response
    },
    (error) => Promise.reject(error)
  )
}

export default axiosInterceptors
