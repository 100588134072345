import axios from 'axios'
import Transaction from 'models/Transaction'
import Vote from 'models/Vote'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class TransactionsService {
  storeTransactions = (fiduciaryStructureId) =>
    axios
      .post(`${API_URL}/fiduciaryStructure/${fiduciaryStructureId}/transactions/store`)
      .then((response) => response.data.data)

  listPossibleVoters = (id) =>
    axios
      .get(`${API_URL}/tenant/fiduciary-structures/${id}/users`)
      .then((response) => response.data.data)

  saveVote = (json) => axios.post(`${API_URL}/votes`, json).then((response) => response.data)

  closeVoting = (voteId, data) =>
    axios.post(`${API_URL}/votes/${voteId}/close`, data).then((response) => response.data)

  listTrustTransactions = (fiduciaryStructureId) =>
    axios
      .get(`${API_URL}/fiduciaryStructure/${fiduciaryStructureId}/transactions`)
      .then((response) =>
        response.data.data.map((transaction) => Transaction.fromJson(transaction))
      )

  saveTransaction = (json) =>
    axios.post(`${API_URL}/transactions/poll`, json).then((response) => response.data.data)

  approveTransaction = (transactions) =>
    axios
      .post(`${API_URL}/transactions/approve`, { transactions })
      .then((response) => response.data.data)

  rejectTransaction = (transactions) =>
    axios
      .post(`${API_URL}/transactions/reject`, { transactions })
      .then((response) => response.data.data)

  deleteTransaction = (transactionId) =>
    axios.delete(`${API_URL}/transactions/${transactionId}`).then((response) => response.data.data)

  listVotes = (fiduciaryStructureId) =>
    axios
      .get(`${API_URL}/votes?fiduciaryStructure=${fiduciaryStructureId}`)
      .then((response) => response.data.data.map((votes) => Vote.fromJson(votes)))
}

export default TransactionsService
