import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import RulesTextHighlighter from 'presentation/RulesTextHighlighter'
import RulesScrollableList from 'presentation/RulesScrollableList'
import LoadingRing from 'presentation/LoadingRing'
import NoResultsList from 'presentation/NoResultsList'
import RulesStore from '../RulesStore'
import styles from '../rulesSection.module.scss'

const OtherDocumentsRulesSection = ({
  rulesStore,
  selectionMode,
  unSelectAllRules,
  handleDeleteRule,
  handleSaveRule,
  handleSelectRuleFromList,
  handleScrollToTextFromList,
  handleOpenCardToTextFromList,
  handleSelectRuleFromText,
  handleAdd,
  listRef,
  trustAssets,
  trustBeneficiaries,
  isLoading,
  viewMode,
}) => {
  const { t } = useTranslation('rulesCreation')
  const { otherDocsStore } = rulesStore.trustCreationStore
  if (otherDocsStore.isLoading || isLoading) {
    return (
      <div className={styles.fullPage}>
        <LoadingRing />
      </div>
    )
  }

  const { otherDocumentsForRules } = otherDocsStore
  if (
    otherDocumentsForRules === null ||
    otherDocumentsForRules.text.value === null ||
    otherDocumentsForRules.text.value === ''
  ) {
    return (
      <div className={styles.fullPage}>
        <NoResultsList showIcon={false} topText={t('noLetterOfWishesInEffect')} />
      </div>
    )
  }

  rulesStore.setOtherDocumentsText(otherDocumentsForRules.text.value)

  return (
    <div className={styles.rulesSectionContainer}>
      <div className={styles.rules}>
        <RulesScrollableList
          rules={rulesStore.otherDocsSectionRules}
          trustAssets={trustAssets}
          trustBeneficiaries={trustBeneficiaries}
          handleDeleteRule={handleDeleteRule}
          handleSaveRule={handleSaveRule}
          handleSelectRule={handleSelectRuleFromList}
          handleOpenCardToTextFromList={handleOpenCardToTextFromList}
          handleScrollToTextFromList={handleScrollToTextFromList}
          listRef={listRef}
          isFiltered={rulesStore.assetsFilter.length || rulesStore.beneficiariesFilter.length}
          viewMode={viewMode}
        />
      </div>
      <div className={styles.text}>
        <RulesTextHighlighter
          text={otherDocumentsForRules.text.value}
          selectionMode={selectionMode}
          unSelectAllRules={unSelectAllRules}
          id="otherDocuments"
          parsedRules={rulesStore.otherDocumentsRules}
          handleSelectRule={handleSelectRuleFromText}
          onAdd={handleAdd}
        />
      </div>
    </div>
  )
}

OtherDocumentsRulesSection.propTypes = {
  rulesStore: PropTypes.instanceOf(RulesStore).isRequired,
  unSelectAllRules: PropTypes.func.isRequired,
  handleDeleteRule: PropTypes.func.isRequired,
  handleSaveRule: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustBeneficiaries: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  trustAssets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleSelectRuleFromList: PropTypes.func.isRequired,
  handleOpenCardToTextFromList: PropTypes.func.isRequired,
  handleSelectRuleFromText: PropTypes.func.isRequired,
  handleScrollToTextFromList: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  selectionMode: PropTypes.bool,
  listRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  viewMode: PropTypes.bool,
}

OtherDocumentsRulesSection.defaultProps = {
  selectionMode: false,
  listRef: null,
  viewMode: false,
}

export default observer(OtherDocumentsRulesSection)
