import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import UnauthorizedContainer from 'presentation/UnauthorizedContainer'
import lock from './images/lock.png'
import styles from './maintenancePage.module.scss'

const MaintenancePage = () => {
  const { t } = useTranslation('maintenancePage')

  return (
    <UnauthorizedContainer title={t('serverMaintenance')}>
      <div className={styles.subtitle}>{t('serverMaintenance')}</div>
      <div className={styles.containerInformation}>
        <div className={styles.textInformation}>{t('maintenanceTextOne')}</div>
        <div className={styles.textInformation}>
          {t('maintenanceTextSecound')}
          <a href="mailto:info@smartstructuring.io" className={styles.contactUs}>
            {t('contactUs')}
          </a>
        </div>
        <div className={styles.textInformation}>{t('maintenanceTextThird')}</div>
      </div>
      <div className={styles.containerImageLock}>
        <img className={styles.imageLock} src={lock} alt="lock" />
      </div>
    </UnauthorizedContainer>
  )
}

export default observer(MaintenancePage)
