import React from 'react'
import UserStatus from 'models/UserStatus'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import UserStatusName from 'presentation/UserStatusName'
import styles from './userFormStatus.module.scss'

const UserFormStatus = ({ status, rightSection }) => {
  const { t } = useTranslation('users')

  return (
    <div className={styles.status}>
      <div>{t('userStatusLabel')}</div>
      <div className={styles.infoContainer}>
        <UserStatusName status={status} />
        <div className={styles.rightSection}>{rightSection}</div>
      </div>
    </div>
  )
}

UserFormStatus.propTypes = {
  status: PropTypes.instanceOf(UserStatus),
  rightSection: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

UserFormStatus.defaultProps = {
  status: new UserStatus(),
  rightSection: null,
}

export default UserFormStatus
