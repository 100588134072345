import { observable, action, computed, override, runInAction, reaction, makeObservable } from 'mobx'
import moment from 'moment'
import AsyncStore from 'stores/AsyncStore'
import i18next from 'i18next'
import ClientsService from 'services/ClientsService'
import ClientStatus from 'models/ClientStatus'
import ContactsService from 'services/ContactsService'
import { countPartErrors } from 'util/validation'
import DynamicFormsStore from './DynamicFormsStore'

export const INDIVIDUAL_CLIENT_TYPE = 'individual'
export const COMPANY_CLIENT_TYPE = 'company'

class ClientsCreationStore extends DynamicFormsStore {
  clientsJson = null
  firstLoad = true
  clientType = ''
  isDirtyGeneral = false
  showSaveToContinue = false
  validationErrors = []
  loadingValidation = false
  loadingActivation = false
  loadingRollBack = false
  forceGeneralDataSend = false
  visibleErrors = true
  optionsContacts = []
  visibleChangeStatusModal = false
  visibleValidationModal = false
  viewMode = false

  constructor(authStore) {
    super(authStore)

    makeObservable(this, {
      // observables
      firstLoad: observable,
      clientType: observable,
      isDirtyGeneral: observable,
      showSaveToContinue: observable,
      validationErrors: observable,
      loadingValidation: observable,
      loadingActivation: observable,
      loadingRollBack: observable,
      forceGeneralDataSend: observable,
      visibleErrors: observable,
      optionsContacts: observable,
      visibleChangeStatusModal: observable,
      visibleValidationModal: observable,
      // actions
      prepare: action,
      init: action,
      reloadData: action,
      setFirstLoad: action,
      resetAttributes: override,
      fillEditData: action,
      save: action,
      tryToChangeStatus: action,
      activate: action,
      inactivate: action,
      showChangeStatusModal: action,
      hideChangeStatusModal: action,
      resetDirty: action,
      validate: action,
      validateFirstSection: action,
      resetValidationErrors: action,
      loadContacts: action,
      contactfillData: action,
      fillContactMappingData: action,
      contactsFilter: action,
      showValidationModal: action,
      hideValidationModal: action,
      // computeds
      isIndividual: computed,
      isCompany: computed,
      isDirty: computed,
      clientName: computed,
      showChangeStatusError: computed,
    })
  }

  prepare() {
    super.setEntityStatus(new ClientStatus())

    this.contactService = new ContactsService()
    this.clientService = new ClientsService()
    this.forceGeneralDataSend = false
  }

  async init(id, viewMode, type) {
    this.clientType = type

    this.isEdition = false

    if (id) {
      this.isEdition = true
    }

    if (this.isIndividual) {
      this.loadContacts(INDIVIDUAL_CLIENT_TYPE)
      super.setEntityNameKey('firstName', 'lastName')
    } else {
      this.loadContacts(COMPANY_CLIENT_TYPE)
      super.setEntityNameKey('name')
    }

    this.preRequest(() => this.init(id, viewMode, type))
    // resets atributos
    this.resetAttributes()

    if (id !== null) {
      this.clientsJson = await this.clientService.getClient(id)
    }

    try {
      // loads clients metadata
      const metadata = await this.clientService.loadMetadata(
        type,
        this.clientsJson?.id,
        this.clientsJson?.dataId
      )

      runInAction(() => {
        // processes the client metadata into classes
        this.processMetadata(metadata)

        // if is edition
        if (id !== null) {
          // sets edition
          this.setIsEdition(id)
          this.reloadData()
          this.toggleViewMode(viewMode)
        } else {
          this.onSuccessRequest()
          this.detectClientChanges()
        }

        this.firstLoad = false
      })
    } catch (e) {
      this.onErrorRequest(e)
      this.firstLoad = false
    }
  }

  reloadData(validate = true) {
    this.preRequest(() => this.reloadData(validate))
    this.firstLoad = false
    this.resetValidationErrors()

    this.fillEditData().then(() => {
      // starts detecting clients changes
      this.detectClientChanges()

      if (validate) {
        this.resetDirty()
        this.validate()
      }

      this.onSuccessRequest()
    })
  }

  setFirstLoad() {
    this.firstLoad = true
  }

  get isIndividual() {
    return this.clientType === INDIVIDUAL_CLIENT_TYPE
  }

  get isCompany() {
    return this.clientType === COMPANY_CLIENT_TYPE
  }

  resetAttributes() {
    super.resetAttributes()
    super.setEntityStatus(new ClientStatus())

    this.isDirtyGeneral = false
    this.showSaveToContinue = false
    this.loadingValidation = false
    this.visibleErrors = true
    this.visibleRollBackModal = false
    this.validationErrors = []
    this.loadingRollBack = false
    this.forceGeneralDataSend = false
  }

  detectClientChanges() {
    /**
     * sets reaction to detect changes on any part of the corporation except for lows and dots
     */
    reaction(
      () => JSON.stringify(this.getDataJson()),
      (data) => {
        /* al guardar, el datajson devuelve un objeto vacio, hago una validacion para ignorar ese caso */
        if (Object.keys(data).length > 2 && !this.isDirtyGeneral) {
          this.isDirtyGeneral = true
        }
      }
    )
  }

  async fillEditData() {
    this.preRequest(this.fillEditData)

    try {
      if (this.idEdition) {
        this.clientsJson = await this.clientService.getClient(this.idEdition)
      }

      runInAction(() => {
        super.setLastSaveDate(moment(this.clientsJson.updated.at))
        this.entityStatus.setStatus(this.clientsJson.status.currentData.status)

        this.fillDataInputs(this.clientsJson.data)
        super.setStep1Finished()
      })

      return this.clientsJson
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }
    return null
  }

  getJson() {
    return {
      id: this.idEdition,
      data: this.isDirtyGeneral || this.forceGeneralDataSend ? this.getDataJson() : null,
    }
  }

  getDataJson() {
    const json = {}

    json.type = this.clientType

    this.inputs.forEach((input, key) => {
      json[key] = input.json
    })

    return json
  }

  async save(successCallback) {
    if (this.isEdition) {
      await this.validate()
    }

    const validFirstSection = await this.validateFirstSection()

    runInAction(() => {
      this.showSaveToContinue = false
    })

    if (!validFirstSection) {
      return
    }

    runInAction(() => {
      this.loadingSave = true
    })

    this.requestProcess(() => this.save(successCallback))
    try {
      let id = this.idEdition
      if (this.isDirty) {
        const json = this.getJson()
        const response = await this.clientService.saveClient(json)
        id = response.id
      }

      runInAction(() => {
        this.loadingSave = false

        super.setStep1Finished()
        super.setLastSaveDate(moment())
        this.resetDirty()

        successCallback(id)
      })
    } catch (e) {
      runInAction(() => {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        }

        this.loadingSave = false
        this.loadingActivation = false
      })
    }
  }

  async tryToChangeStatus() {
    if (this.entityStatus.isInactive) {
      // if user is inactive we make required validations to change to active
      await this.validate()

      if (this.validationErrors.length) {
        this.showValidationModal()
      } else {
        this.save(() => this.showChangeStatusModal())
      }
    } else {
      // if user is inactive, no validations are needed
      this.showChangeStatusModal()
    }
  }

  async activate(succesFunc) {
    this.loadingActivation = true

    this.save(async () => {
      if (this.validationErrors.length === 0) {
        this.requestProcess(() => this.activate(succesFunc))
        try {
          await this.clientService.activate(this.idEdition)

          runInAction(() => {
            succesFunc()
            this.reloadData()
            this.loadingActivation = false
          })
        } catch (e) {
          if (AsyncStore.isServerError(e)) {
            this.setServerError()
          }
        }
      } else {
        this.showValidationModal()
        this.loadingActivation = false
      }
    })
  }

  async inactivate(succesFunc) {
    this.loadingActivation = true

    this.save(async () => {
      this.requestProcess(() => this.inactivate(succesFunc))
      try {
        await this.clientService.inactivate(this.idEdition)

        runInAction(() => {
          succesFunc()
          this.reloadData()
          this.loadingActivation = false
        })
      } catch (e) {
        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        }
      }
    })
  }

  showChangeStatusModal() {
    this.visibleChangeStatusModal = true
  }

  hideChangeStatusModal() {
    this.visibleChangeStatusModal = false
  }

  countPartErrors(part) {
    return countPartErrors(part, this.inputs)
  }

  resetDirty() {
    this.isDirtyGeneral = false
  }

  async validate() {
    this.visibleErrors = true

    const errors = await [...this.inputs.values()].reduce(async (validationErrors, input) => {
      const previous = await validationErrors

      if (input.validate) {
        try {
          await input.validate()
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      return previous
    }, [])

    this.resetValidationErrors()

    this.entitySections.forEach((clientSection) => {
      const error = this.countPartErrors(clientSection, this.inputs)
      if (error > 0) {
        const section = clientSection.menuLabel

        this.validationErrors.push(i18next.t('validation:sectionError', { section }))
      }
    })

    return !errors.length
  }

  async validateFirstSection() {
    this.resetValidationErrors()
    /*
    valida especificamente la primer seccion, pero de forma no dinamica, tener en cuenta
    */

    if (this.entitySections.length === 0) {
      return false
    }

    let isValid = true

    if (this.clientType === 'individual') {
      if (!this.inputs.get('firstName').store.value) {
        this.inputs.get('firstName').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
      if (!this.inputs.get('lastName').store.value) {
        this.inputs.get('lastName').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
      if (!this.inputs.get('passportNumber').store.value) {
        this.inputs
          .get('passportNumber')
          .store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
      if (!this.inputs.get('issuerCountry').store.value) {
        this.inputs.get('issuerCountry').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
    }

    if (this.clientType === 'company') {
      if (!this.inputs.get('name').store.value) {
        this.inputs.get('issuerCountry').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
      if (!this.inputs.get('taxNumber').store.value) {
        isValid = false
        this.inputs.get('issuerCountry').store.setError(true, i18next.t('validation:requiredField'))

        isValid = false
      }
      if (!this.inputs.get('jurisdiction').store.value) {
        isValid = false
        this.inputs.get('issuerCountry').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
    }

    return isValid
  }

  resetValidationErrors() {
    this.validationErrors = []
    this.inputs.get('firstName')?.store.setError(false)
    this.inputs.get('lastName')?.store.setError(false)
  }

  async loadContacts(typeClient) {
    try {
      const response = await this.contactService.listContact()

      runInAction(() => {
        this.contactsFilter(typeClient, response.contacts)
      })
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }
  }

  async contactfillData(id, part) {
    this.preRequest(this.contactfillData)

    try {
      const contact = await this.contactService.getContact(id)
      runInAction(() => {
        this.idEdition = id
        this.fillContactMappingData(contact, part)
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }
    return null
  }

  fillContactMappingData(contact, part) {
    if (part.contactMapExceptions) {
      part.contactMapExceptions.forEach((contactMapException) => {
        if (this.inputs.has(contactMapException.to)) {
          let value = null

          Object.entries(contact.data).forEach(([key, loopValue]) => {
            if (key === contactMapException.from) {
              value = loopValue
            }
          })

          if (value === null) {
            Object.entries(contact).forEach(([key, loopValue]) => {
              if (key === contactMapException.from) {
                value = loopValue
              }
            })
          }
          this.inputs.get(contactMapException.to).store.setValue(value)
        }
      })
    }

    this.fillDataInputs(contact.data)
  }

  contactsFilter(typeClient, arrayContacts) {
    this.optionsContacts = arrayContacts.filter((contact) => {
      return contact.type === typeClient
    })
  }

  get isDirty() {
    return this.isDirtyGeneral
  }

  get clientName() {
    if (this.inputs.get('clientName')) {
      return this.inputs.get('clientName').store.value
    }

    return null
  }

  checkFirstStepComplete() {
    if (this.subMenuDisabled && this.validateFirstSection()) {
      this.showSaveToContinue = true
    }
  }

  get showChangeStatusError() {
    return this.validationErrors.length > 0
  }

  showValidationModal() {
    this.visibleValidationModal = true
  }

  hideValidationModal() {
    this.visibleValidationModal = false
  }
}

export default ClientsCreationStore
