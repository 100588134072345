import React from 'react'
import PropTypes from 'prop-types'
import styles from './pageTitleButton.module.scss'

const PageTitleButton = ({ onClick, showButton, text }) =>
  showButton ? (
    <div onClick={onClick} className={styles.pageTitleButton} role="button" tabIndex="0">
      {text}
    </div>
  ) : null

PageTitleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  showButton: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
}

export default PageTitleButton
