import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import AssetActivity from 'models/AssetActivity'
import ContactActivity from 'models/ContactActivity'
import DocumentActivity from 'models/DocumentActivity'
import GovernorActivity from 'models/GovernorActivity'
import TransactionActivity from 'models/TransactionActivity'
import LiteralValue from 'presentation/LiteralValue'
import Button from 'presentation/Button'
import ActivityIconSelector from 'presentation/ActivityIconSelector'
import AssetActivityModalForm from './AssetActivityModalForm'
import ContactActivityModalForm from './ContactActivityModalForm'
import GovernorActivityModalForm from './GovernorActivityModalForm'
import DocumentActivityModalForm from './DocumentActivityModalForm'
import FundingActivityModalForm from './FundingActivityModalForm'
import OwnershipActivityModalForm from './OwnershipActivityModalForm'
import styles from './activityModalForm.module.scss'

const ActivityModalForm = ({
  item,
  setButtons,
  handleApproveChange,
  handleRejectChange,
  handleConfirmChange,
  handleAbstainChange,
}) => {
  const { t } = useTranslation('activity')
  const [status, setStatus] = useState(item.status)

  const displayShowingNameLabel = useCallback(
    (currentItem) => {
      if (currentItem.asset) {
        return t('assetName')
      }

      if (currentItem.sourceAccounts) {
        return t('description')
      }

      return t('contactName')
    },
    [item.asset, item.sourceAccounts]
  )

  const buildButtons = useCallback(() => {
    const buttons = []

    if (status === 'voting') {
      setButtons(buttons)
      return
    }

    if (!item.isConfirmed && status === 'pending') {
      buttons.push(
        <Button
          label={t('approve')}
          primary
          small
          style={{ marginRight: '15px' }}
          onClick={() => {
            handleApproveChange(item.activityId)
            setStatus('approve')
          }}
        />
      )
      buttons.push(
        <Button
          label={t('reject')}
          secondary
          small
          style={{ marginRight: '15px' }}
          onClick={() => {
            handleRejectChange(item.activityId)
            setStatus('reject')
          }}
        />
      )
      buttons.push(
        <Button
          label={t('abstain')}
          small
          onClick={() => {
            handleAbstainChange(item.activityId)
            setStatus('abstain')
          }}
        />
      )
    } else if (status !== 'pending' && !item.isConfirmed) {
      buttons.push(
        <Button label={t('confirm')} small onClick={() => handleConfirmChange(item.activityId)} />
      )
      buttons.push(
        <Button
          label={t('changeDecision')}
          small
          style={{ marginRight: '15px' }}
          secondary
          onClick={() => setStatus('pending')}
        />
      )
    }

    setButtons(buttons)
  }, [status])

  useEffect(() => {
    buildButtons()
  }, [item.status, item.isConfirmed, status])

  return (
    <div>
      <div
        className={item.type !== 'storeApproval' ? styles.subHeader : styles.subHeaderWithoutName}
      >
        <div className={styles.assetName}>
          <LiteralValue
            label={displayShowingNameLabel(item)}
            value={item.showingName}
            borderBottom
            icon={<ActivityIconSelector type={item.type} />}
          />
        </div>
        <div className={styles.createdAt}>
          <LiteralValue
            label={t('createdAt')}
            value={moment(item.created.at).format(t('common:humanDateTimeYearFormat'))}
            borderBottom
          />
        </div>
        <div className={styles.author}>
          <LiteralValue
            label={t('author')}
            value={`${item.created.firstName} ${item.created.lastName}`}
          />
        </div>
        <div className={styles.description}>
          <LiteralValue label={t('description')} value={item.description} />
        </div>
      </div>
      {item.type === 'assetValuationChange' && (
        <AssetActivityModalForm valuation={item.valuation} />
      )}
      {item.type === 'contactInformationChange' && (
        <ContactActivityModalForm changes={item.changes} contact={item.contact} />
      )}
      {item.type === 'governorApproval' && <GovernorActivityModalForm item={item} />}
      {item.type === 'assetDocumentChange' && <DocumentActivityModalForm item={item} />}
      {item.isFundingTransaction && <FundingActivityModalForm item={item} />}
      {item.isOwnershipTransaction && (
        <OwnershipActivityModalForm ownership={item.ownership} corpName={item.name} />
      )}
    </div>
  )
}

ActivityModalForm.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(AssetActivity),
    PropTypes.instanceOf(ContactActivity),
    PropTypes.instanceOf(DocumentActivity),
    PropTypes.instanceOf(GovernorActivity),
    PropTypes.instanceOf(TransactionActivity),
  ]),
  setButtons: PropTypes.func,
  handleApproveChange: PropTypes.func,
  handleRejectChange: PropTypes.func,
  handleConfirmChange: PropTypes.func,
  handleAbstainChange: PropTypes.func,
}

ActivityModalForm.defaultProps = {
  item: null,
  setButtons: () => {},
  handleApproveChange: () => {},
  handleRejectChange: () => {},
  handleConfirmChange: () => {},
  handleAbstainChange: () => {},
}
export default ActivityModalForm
