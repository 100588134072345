import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { TRUSTS_HISTORY_VERSIONS } from 'routing/routes'
import HistoryVersion from 'models/HistoryVersion'
import styles from './historyVersionElement.module.scss'

const HistoryVersionElement = ({ version, trustId }) => {
  const { t } = useTranslation('history')

  const getUrl = useCallback(
    () => `${TRUSTS_HISTORY_VERSIONS}/version?id=${trustId}&version=${version.number}`,
    [trustId]
  )

  return (
    <NavLink className={styles.card} to={getUrl()}>
      <div>
        <h3 className={styles.title}>{version.versionName}</h3>
        <p className={styles.authorStamp}>
          {t('authorStamp', {
            date: version.updated.at.format(t('common:humanDateTimeYearFormat')),
            author: version.updated.name,
          })}
        </p>
      </div>
      <div className={styles.tag}>{version.status}</div>
    </NavLink>
  )
}

HistoryVersionElement.propTypes = {
  version: PropTypes.instanceOf(HistoryVersion).isRequired,
  trustId: PropTypes.string.isRequired,
}

export default HistoryVersionElement
