import { action, observable, runInAction, makeObservable } from 'mobx'
import TrustParametersService from 'services/TrustParametersService'
import { sortBy as _sortBy } from 'lodash'
import AsyncStore from './AsyncStore'

class CreationParametersStore extends AsyncStore {
  constructor() {
    super()

    this.parameters = observable.map()

    makeObservable(this, {
      // observables
      parameters: observable,
      // actions
      resetTypes: action,
      loadTypes: action,
      createOption: action,
      addParameter: action,
      createFileType: action,
      createType: action,
    })
  }

  init() {
    this.trustParametersService = new TrustParametersService()

    this.resetTypes()
    this.loadTypes()
  }

  resetTypes() {
    this.parameters = observable.map()
  }

  async loadTypes() {
    this.preRequest()

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.trustParametersService.listParameters()

      runInAction(() => {
        this.resetTypes()

        response.forEach((typeCategory) =>
          _sortBy(typeCategory.values, (type) => type.value.toLowerCase()).forEach((type) => {
            this.addParameter(typeCategory.type, type)
          })
        )

        this.onSuccessRequest()
      })
    } catch (e) {
      throw e
    }
  }

  getOptions(typeName) {
    const values = this.parameters.get(typeName)

    if (!values) {
      return []
    }

    return values.map((type) => ({
      id: type.id,
      value: type.value,
    }))
  }

  createOption(type, value) {
    return this.createType(type, value, (option) => {
      this.addParameter(type, option)
    })
  }

  addParameter(type, value) {
    const currentData = this.parameters.get(type) || []

    currentData.push(value)

    this.parameters.set(type, currentData)
  }

  createFileType(value) {
    return this.createType('file', value, (type) => this.addParameter('file', type))
  }

  async createType(category, value, addFunction) {
    this.preRequest()

    const response = await this.trustParametersService.createParameter(category, value)

    try {
      const type = response.value

      this.onSuccessRequest()
      addFunction(type)

      return type
    } catch (e) {
      this.onErrorRequest()
      throw e
    }
  }
}

export default CreationParametersStore
