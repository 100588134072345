import React from 'react'
import MetaTitle from 'util/MetaTitle'
import PropTypes from 'prop-types'
import logo from './images/logo.svg'
import styles from './unauthorizedContainer.module.scss'

const UnauthorizedContainer = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <MetaTitle pageTitle={title} />
      <div className={styles.background} />
      <div className={styles.box}>
        <div className={styles.logo}>
          <img src={logo} title="Smart Trust" alt="Smart Trust" />
          <div className={styles.logoBrand}>Smart Trust</div>
        </div>
        {children}
      </div>
    </div>
  )
}

UnauthorizedContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.array]).isRequired,
}

export default UnauthorizedContainer
