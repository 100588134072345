import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import LiteralValue from 'presentation/LiteralValue'
import styles from './governorActivityModalForm.module.scss'

const GovernorActivityModalForm = ({ item }) => {
  const { t } = useTranslation('activity')
  return (
    <div className={styles.container}>
      <div className={styles.detailsContainer}>{t('governorInformation')}</div>
      <div>
        <div className={styles.literalValueContainer}>
          <LiteralValue label={t('governorName')} value={item.showingName} borderBottom />
        </div>
        <div className={styles.literalValueContainer}>
          <LiteralValue label={t('governorType')} value={item.showingEntity} borderBottom />
        </div>
      </div>
    </div>
  )
}

GovernorActivityModalForm.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any, PropTypes.string]).isRequired,
}

export default GovernorActivityModalForm
