import React from 'react'
import PropTypes from 'prop-types'
import Button from 'presentation/Button'
import ToolTip from 'presentation/ToolTip'

const FiduciaryStructureActionsButton = ({
  icon,
  label,
  iconOnly,
  onClick,
  className,
  transparent,
  ...props
}) => {
  const ActionButton = () => {
    return (
      <Button
        icon={icon}
        label={!iconOnly && label}
        className={className}
        iconPositionLeft={!iconOnly}
        smallest={!iconOnly}
        circle={iconOnly}
        onClick={onClick}
        small={iconOnly}
        transparent={transparent}
        {...props}
      />
    )
  }

  if (iconOnly) {
    return (
      <ToolTip tooltipContent={label}>
        <ActionButton />
      </ToolTip>
    )
  }
  return <ActionButton />
}

FiduciaryStructureActionsButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
  transparent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

FiduciaryStructureActionsButton.defaultProps = {
  className: '',
  label: '',
  transparent: true,
  iconOnly: false,
}

export default FiduciaryStructureActionsButton
