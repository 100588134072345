import React, { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import c from 'classnames'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import { DELETE_CONTACT } from 'stores/AuthStore'
import Gated from 'util/Gated'
import AccountIcon from 'mdi-react/AccountIcon'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import UpdateIcon from 'mdi-react/UpdateIcon'
import ListContact from 'models/ListContact'
import TableItemDate from 'presentation/TableItemDate'
import TableItemName from 'presentation/TableItemName'
import { NavLink } from 'react-router-dom'
import styles from './contactsTableItem.module.scss'

const ContactsTableItem = ({ link, view, listItem, handleDeleteContact }) => {
  const [showDelete, setShowDelete] = useState(false)
  const { t } = useTranslation('common')

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => setShowDelete(true),
    onSwipedRight: () => setShowDelete(false),
  })

  return (
    <div className={styles.container} {...swipeableHandlers}>
      <NavLink
        to={link}
        className={[styles.contactTableItem, styles[`contactTableItem--${view}`]].join(' ')}
      >
        <div title={listItem.name}>
          <TableItemName
            classNames={styles.contactTableItemName}
            name={listItem.createName}
            type={listItem.isIndividual ? t('contacts:individual') : t('contacts:company')}
            icon={listItem.isIndividual ? <AccountIcon size={16} /> : <BriefcaseIcon size={16} />}
            iconClassNames={styles.typeIcon}
          />
        </div>
        <div className={styles.contactTableItemEmail}>{listItem.createEmail}</div>
        <div className={styles.contactTableItemUpdatedAt}>
          <TableItemDate listDate={listItem.updated} view={view} icon={<UpdateIcon size={12} />} />
        </div>
        <div className={styles.contactTableItemContacted}>
          {listItem.wishesToBeContacted ? (
            <div className={styles.containerToBeContacted}>
              <span className={styles.statusToBeContacted} />
              <div className={styles.wishesToBeContacted}>{t('contacts:wantToBeContacted')}</div>
            </div>
          ) : (
            <div className={styles.containerToBeContacted}>
              <span className={styles.statusNotToBeContacted} />
              <div className={styles.notWishesToBeContacted}>
                {t('contacts:dontWantToBeContacted')}
              </div>
            </div>
          )}
        </div>
      </NavLink>
      <Gated permission={DELETE_CONTACT}>
        <div
          onClick={() => handleDeleteContact(listItem.id)}
          className={c(styles.tableRowDelete, showDelete && styles.showSwipeDelete)}
          role="button"
          tabIndex="0"
        >
          <TrashCanOutlineIcon size={20} />
        </div>
      </Gated>
    </div>
  )
}

ContactsTableItem.propTypes = {
  link: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  handleDeleteContact: PropTypes.func.isRequired,
  listItem: PropTypes.instanceOf(ListContact).isRequired,
}

export default ContactsTableItem
