import { makeAutoObservable, runInAction } from 'mobx'
import InputStore from 'stores/InputStore'
import FileService from 'services/FileService'
import { v4 as uuidv4 } from 'uuid'

class File {
  id = null
  name = null
  loaderValue = null
  isUploading = false
  uuid = uuidv4()
  error = false
  errorMessage = ''

  constructor() {
    this.fileService = new FileService()
    this.type = new InputStore()
    this.description = new InputStore()
    this.legalCertified = false

    makeAutoObservable(this)
  }

  static fromFile(file) {
    const fileEntity = new File()

    fileEntity.changeId(file.id)
    fileEntity.changeName(file.name)
    fileEntity.changeType(file.type.value)
    fileEntity.changeLegalCertified(file.legalCertified)

    if (file.description) {
      fileEntity.changeDescription(file.description.value)
    }

    return fileEntity
  }

  static fromJson({ id, name, type = '', description = null, legalCertified = false }) {
    const fileEntity = new File()

    fileEntity.changeId(id)
    fileEntity.changeName(name)
    fileEntity.changeType(type)
    fileEntity.changeLegalCertified(legalCertified)

    if (description) {
      fileEntity.changeDescription(description)
    }

    return fileEntity
  }

  clearError() {
    this.error = false
    this.errorMessage = ''
  }

  setError(status = false, message = '') {
    this.error = status
    this.errorMessage = message
  }

  changeId(id) {
    this.id = id
  }

  changeName(value) {
    this.name = value
  }

  changeType(value) {
    this.type.setValue(value)
  }

  changeDescription(value) {
    this.description.setValue(value)
  }

  changeLegalCertified(value) {
    this.legalCertified = value
  }

  async cancelUpload() {
    try {
      this.removeFile()
      this.fileService.cancelUpload()
    } catch (error) {
      // do nothing
    }
  }

  async upload(file, progress) {
    try {
      this.isUploading = true
      const fileUpload = await this.fileService.uploadFile(file, progress)

      runInAction(() => {
        this.id = fileUpload.id
        this.name = file.name
        this.clearError()
        this.isUploading = false
      })
    } catch (error) {
      this.isUploading = false
      this.clearError()

      return Promise.reject(error)
    }
    return null
  }

  async uploadContacts(file, progress) {
    try {
      this.isUploading = true
      await this.fileService.uploadContactsFile(file, progress)

      runInAction(() => {
        this.name = file.name
        this.clearError()
        this.isUploading = false
      })
    } catch (error) {
      this.isUploading = false
      this.clearError()

      return Promise.reject(error)
    }
    return null
  }

  async getSecureUrl() {
    try {
      const secureUrl = await this.fileService.getSecureUrl(this.id)

      return secureUrl
    } catch (error) {
      this.isUploading = false

      return Promise.reject(error)
    }
  }

  removeFile() {
    this.name = null
    this.id = null
    this.clearError()
  }

  setLoaderValue(value) {
    this.loaderValue = value
  }

  get empty() {
    return (
      (this.id === null || this.id === undefined || this.id === '') &&
      (this.type.value === null || this.type.value === undefined || this.type.value === '') &&
      (this.description.value === null ||
        this.description.value === undefined ||
        this.description.value === '')
    )
  }

  get hasErrors() {
    return this.error
  }

  getJson() {
    return {
      id: this.id,
      name: this.name,
      type: this.type.value !== '' ? this.type.value : '',
      description: this.description.value !== '' ? this.description.value : '',
      legalCertified: this.legalCertified,
    }
  }
}

export default File
