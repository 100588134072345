import ListDate from 'models/ListDate'

class HistoryVersion {
  constructor(number, created, updated, status) {
    this.number = number
    this.created = created
    this.updated = updated
    this.status = status
  }

  static fromJson({ versionNumber, created, updated, status }) {
    return new HistoryVersion(
      versionNumber,
      ListDate.fromJson(created),
      ListDate.fromJson(updated),
      status
    )
  }

  get versionName() {
    return `Version ${this.number}`
  }
}

export default HistoryVersion
