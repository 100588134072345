import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import CorporationsCreationStore from 'stores/CorporationsCreationStore'
import CloudAlertIcon from 'mdi-react/CloudAlertIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import { EDIT_CORPORATION } from 'stores/AuthStore'
import Button from 'presentation/Button'
import styles from './corporationCreationActionButtons.module.scss'

const CorporationCreationActionButtons = ({
  corporationsCreationStore,
  handleSave,
  handleCancel,
  handleEdit,
  handleGoBack,
}) => {
  const { t } = useTranslation('corporationsCreation')

  if (!corporationsCreationStore.visibleActionButtons) {
    return null
  }

  return (
    <div className={styles.buttonsContainer}>
      {corporationsCreationStore.showSaveToContinue && (
        <div className={styles.showSaveToContinue}>{t('saveToContinue')}</div>
      )}
      {corporationsCreationStore.isViewMode ? (
        <>
          <Button
            style={{ marginRight: '15px' }}
            label={t('common:back')}
            title={t('common:back')}
            icon={<ArrowLeftIcon />}
            onClick={handleGoBack}
            iconPositionLeft
            secondary
          />
          {corporationsCreationStore.entityStatus.canBeEdited && (
            <Button
              label={t('editCorporation')}
              title={t('editCorporation')}
              permission={EDIT_CORPORATION}
              onClick={handleEdit}
              primary
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.saveButtonContainer}>
            <div
              className={c(
                styles.unsetChanges,
                corporationsCreationStore.isDirty ? styles.show : ''
              )}
            >
              <CloudAlertIcon />
              <span>{t('unsavedLabel')}</span>
            </div>
            <Button
              isLoading={corporationsCreationStore.loadingSave}
              title={`${t(`saveCorporation`)} (alt+s)`}
              disabled={!corporationsCreationStore.canSave}
              label={t('saveCorporation')}
              style={{ marginRight: '15px' }}
              permission={EDIT_CORPORATION}
              onClick={handleSave}
              primary
              big
            />
          </div>
          <Button secondary big label={t('common:cancel')} onClick={handleCancel} />
        </>
      )}
    </div>
  )
}

CorporationCreationActionButtons.propTypes = {
  corporationsCreationStore: PropTypes.instanceOf(CorporationsCreationStore).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
}

export default observer(CorporationCreationActionButtons)
