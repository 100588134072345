import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import CheckboxBlankCircleOutlineIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon'
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon'
import styles from './radioButtonToggle.module.scss'

const RadioButtonToggle = ({ label, checked, onChange, italic, literal, error }) => (
  <div
    onClick={() => {
      if (!literal) {
        onChange()
      }
    }}
    className={c(
      styles.radioButtonToggle,
      italic && styles.italic,
      literal && styles.literal,
      error && styles.radioButtonToggleError
    )}
    role="button"
    tabIndex="0"
  >
    <div className={styles.radioButtonIcon}>
      {checked ? (
        <CheckCircleOutlineIcon size={15} />
      ) : (
        <CheckboxBlankCircleOutlineIcon size={15} />
      )}
    </div>
    {label}
  </div>
)

RadioButtonToggle.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  italic: PropTypes.bool,
  literal: PropTypes.bool,
  error: PropTypes.bool,
}

RadioButtonToggle.defaultProps = {
  label: '',
  checked: false,
  italic: false,
  literal: false,
  error: false,
}

export default RadioButtonToggle
