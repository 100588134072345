import { action, makeObservable } from 'mobx'
import PermissionUserStore from 'stores/PermissionUserStore'
import TrustsService from 'services/TrustService'
import TrustUsersService from 'services/TrustUsersService'

class TrustUsersStore extends PermissionUserStore {
  constructor() {
    super()
    this.trustService = new TrustsService()
    this.permissionService = new TrustUsersService()

    makeObservable(this, {
      init: action,
    })
  }

  async init(id) {
    if (!id) {
      this.noIdError = true
      return
    }

    this.setId(id)

    this.preRequest(() => this.init(id))

    this.trustService
      .getTrust(id)
      .then((trust) => {
        this.trustName = trust.data.trustName
        this.getAssignedUsers()
        this.searchUsers()
        this.onSuccessRequest()
      })
      .catch((e) => {
        this.noIdError = true
        this.onErrorRequest(e)
      })
  }

  listAssignedUsers(id) {
    return this.permissionService.listAssignedUsers(id)
  }

  searchPermissionUsers(id, term) {
    return this.permissionService.searchPermissionUsers(id, term)
  }

  savePermissionUsers(id, getJson) {
    this.permissionService.savePermissionUsers(id, getJson)
  }
}

export default TrustUsersStore
