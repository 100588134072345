import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CORPORATIONS_LIST } from 'routing/routes'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import imageError from './imageError.png'

const CorporationSectionError = () => {
  const { t } = useTranslation('corporationsCreation')
  const history = useHistory()
  const handleGoBack = useCallback(() => history.push(CORPORATIONS_LIST), [])

  return (
    <GenericNotFoundError
      description={t('corporationSectionErrorDesc')}
      textButton={t('corporationSectionErrorButton')}
      goBackFunction={handleGoBack}
      title={t('corporationSectionErrorTitle')}
      image={imageError}
    />
  )
}

export default CorporationSectionError
