import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InputContactsFile from 'presentation/InputContactsFile/InputContactsFile'
import CreationInput from 'presentation/CreationInput'
import File from 'models/File'
import styles from './contactsMassiveImport.module.scss'

const ContactsMassiveImport = ({
  templateStore,
  handleDownloadTemplate,
  templateFileUrl,
  handleRevokeTemplateUrl,
}) => {
  const { t } = useTranslation('contacts')

  useEffect(() => {
    handleDownloadTemplate()
    return () => {
      handleRevokeTemplateUrl()
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.templateDownload}>
        <span>{t('downloadTemplateFrom')}</span>
        <span>
          <a href={templateFileUrl} download="SmartStructuring.io - Contact Import Template.xls">
            {t('here')}
          </a>
        </span>
      </div>
      <div>
        <div>{t('bareInMind')}</div>
        <ul className={styles.tipsList}>
          <li>{t('numberFormattingMatters')}</li>
          <li>{t('dontRearrangeColumns')}</li>
          <li>{t('dontDeleteOrCreatePages')}</li>
          <li>{t('requiredFields')}</li>
        </ul>
      </div>

      <div className={styles.fileUpload}>
        <CreationInput
          as={InputContactsFile}
          fileStore={templateStore}
          label={t('importFile')}
          placeholder={t('selectYourFile')}
        />
      </div>
    </div>
  )
}

ContactsMassiveImport.propTypes = {
  templateStore: PropTypes.instanceOf(File).isRequired,
  templateFileUrl: PropTypes.string,
  handleDownloadTemplate: PropTypes.func,
  handleRevokeTemplateUrl: PropTypes.func,
}

ContactsMassiveImport.defaultProps = {
  templateFileUrl: '#',
  handleDownloadTemplate: () => {},
  handleRevokeTemplateUrl: () => {},
}

export default observer(ContactsMassiveImport)
