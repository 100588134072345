import { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InputStore from 'stores/InputStore'
import CreationInput from 'presentation/CreationInput'
import FormSelect from 'presentation/FormSelect'

const TransactionVotingForm = ({ votersOptions, voters, setVoters }) => {
  const { t } = useTranslation('transactions')
  const [selectedOptions] = useState(() => new InputStore([]))

  const handleChangeVoter = useCallback(
    (options) => {
      selectedOptions.setValue(options)

      setVoters(options.map((option) => option.id))
    },
    [voters]
  )

  const optionsList = useMemo(
    () =>
      votersOptions.map((option) => ({
        id: option.label,
        value: option.value,
      })),
    [votersOptions]
  )

  return (
    <div>
      <CreationInput
        as={FormSelect}
        inputStore={selectedOptions}
        label={t('voters')}
        placeholder={t('selectVoters')}
        options={optionsList}
        onChange={handleChangeVoter}
        isMulti
      />
    </div>
  )
}

TransactionVotingForm.propTypes = {
  votersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  voters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setVoters: PropTypes.func,
}

TransactionVotingForm.defaultProps = {
  setVoters: () => {},
}

export default TransactionVotingForm
