import React, { useCallback, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router'
import MainLayout from 'presentation/MainLayout'
import NotFound404 from 'scenes/NotFound404'
import * as Routes from 'routing/routes'
import Gated from 'util/Gated'
import MainPageRedirector from 'scenes/MainPageRedirector'
import Dashboard from 'scenes/Dashboard'
import TrustsList from 'scenes/TrustsList'
import TrustsCreation from 'scenes/TrustsCreation'
import UserCreation from 'scenes/UserCreation'
import CorporationsList from 'scenes/CorporationsList'
import FiduciaryStructuresTransactions from 'scenes/FiduciaryStructuresTransactions'
import TrustsValuations from 'scenes/TrustsValuations'
import UsersList from 'scenes/UsersList'
import ClientsList from 'scenes/ClientsList'
import UserProfile from 'scenes/UserProfile'
import ChangePassword from 'scenes/UserProfile/ChangePassword'
import CorporationsCreation from 'scenes/CorporationsCreation'
import ContactsList from 'scenes/ContactsList'
import RolesList from 'scenes/RolesList'
import ActivityList from 'scenes/ActivityList'
import ActivityDetailsList from 'scenes/ActivityDetailsList'
import Transactions from 'scenes/Transactions'
import IndividualClientsCreation, { CompanyClientsCreation } from 'scenes/ClientsCreation'
import IndividualContactsCreation, { CompanyContactsCreation } from 'scenes/ContactsCreation'
import TrustUsers from 'scenes/TrustUsers'
import AuthorizedNavBar from 'scenes/AuthorizedNavBar'
import DownloadZip from 'scenes/DownloadZip'
import StoresContext from 'providers/storeContext'
import RolesCreation from 'scenes/RolesCreation'
import Modal from 'scenes/Modal'
import CorporationsUsers from 'scenes/CorporationsUsers'
import Settings from 'scenes/Settings'
import Logout from 'scenes/Logout'
import TrustHistory from 'scenes/TrustHistory'
import TrustTransactionsAssets from 'scenes/TransactionsAssets'
import FiduciaryStructureTransactionsValuation from 'scenes/TransactionsValuation'
import * as Permissions from 'stores/AuthStore'
import TrustHistoryVersions from 'scenes/TrustHistory/TrustHistoryVersions'
import TrustHistoryVersionDetails from 'scenes/TrustHistory/TrustHistoryVersionDetails'
import TermsAndConditionsAlert from './TermsAndConditionsAlert/TermsAndConditionsAlert'

const AuthorizedRouter = () => {
  const { t } = useTranslation()
  const { authStore, navBarStore } = useContext(StoresContext)
  const history = useHistory()
  const [shouldShowTermsModal, setShouldShowTermsModal] = useState(
    authStore.authUser?.legals?.mustAccept
  )

  const closeTermsModal = useCallback(() => {
    setShouldShowTermsModal(false)
  }, [])

  useEffect(() => {
    history.listen((location) => {
      navBarStore.clearForcedOnlyMainNavBar()

      setTimeout(() => {
        if (location.state && location.state.permission) {
          authStore.keepAlive()
        }
      })
    })
  }, [])

  return (
    <MainLayout sidebar={AuthorizedNavBar}>
      <Switch>
        <Route exact path="/" component={MainPageRedirector} />
        <Gated.Route
          permission={Permissions.VIEW_TRUSTS}
          path={Routes.TRUSTS_LIST}
          component={TrustsList}
          exact
        />
        <Gated.Route
          permission={Permissions.VIEW_TRUSTS}
          path={Routes.TRUSTS_CREATION}
          component={TrustsCreation}
        />
        <Gated.Route
          permission={Permissions.CREATE_USER}
          path={Routes.USERS_CREATION}
          component={UserCreation}
        />
        <Gated.Route
          permission={Permissions.TRANSACTIONS_LIST}
          path={Routes.FIDUCIARY_STRUCTURE_TRANSACTIONS}
          component={FiduciaryStructuresTransactions}
        />
        <Gated.Route
          permission={Permissions.TRUST_VALUATIONS}
          path={Routes.TRUSTS_VALUATIONS}
          component={TrustsValuations}
        />
        <Gated.Route
          permission={Permissions.VIEW_CORPORATIONS}
          path={Routes.CORPORATIONS_LIST}
          component={CorporationsList}
          exact
        />
        <Gated.Route
          permission={Permissions.VIEW_CORPORATIONS}
          path={Routes.CORPORATIONS_CREATION}
          component={CorporationsCreation}
        />
        <Gated.Route
          permission={Permissions.LIST_CONTACT}
          path={Routes.CONTACTS_LIST}
          component={ContactsList}
          exact
        />
        <Gated.Route
          permission={Permissions.VIEW_CONTACT}
          path={Routes.INDIVIDUAL_CONTACT_CREATION}
          component={IndividualContactsCreation}
        />
        <Gated.Route
          permission={Permissions.VIEW_CONTACT}
          path={Routes.COMPANY_CONTACT_CREATION}
          component={CompanyContactsCreation}
        />
        <Gated.Route
          permission={Permissions.VIEW_CLIENTS}
          path={Routes.CLIENTS_LIST}
          component={ClientsList}
          exact
        />
        <Gated.Route
          permission={Permissions.TRANSACTIONS_SECTION}
          path={Routes.FIDUCIARY_STRUCTURE_TRANSACTIONS_VALUATION}
          component={FiduciaryStructureTransactionsValuation}
          exact
        />
        <Gated.Route
          permission={Permissions.TRANSACTIONS_SECTION}
          path={Routes.FIDUCIARY_STRUCTURE_TRANSACTIONS_EXPENSE}
          component={TrustTransactionsAssets}
          exact
        />
        <Gated.Route
          permission={Permissions.TRANSACTIONS_SECTION}
          path={Routes.FIDUCIARY_STRUCTURE_TRANSACTIONS_ASSETS}
          component={TrustTransactionsAssets}
          exact
        />
        <Gated.Route
          permission={Permissions.TRANSACTIONS_SECTION}
          path={Routes.TRANSACTIONS}
          component={Transactions}
          exact
        />
        <Gated.Route
          permission={Permissions.VIEW_USERS}
          exact
          path={Routes.USERS_LIST}
          component={UsersList}
        />
        <Gated.Route
          path={Routes.INDIVIDUAL_CLIENT_CREATION}
          permission={Permissions.VIEW_CLIENTS}
          component={IndividualClientsCreation}
        />
        <Gated.Route
          permission={Permissions.VIEW_CLIENTS}
          path={Routes.COMPANY_CLIENT_CREATION}
          component={CompanyClientsCreation}
        />
        <Gated.Route
          permission="yes"
          path={Routes.CORPORATIONS_USERS}
          component={CorporationsUsers}
          exact
        />
        <Gated.Route
          permission={Permissions.TRUST_USERS_VIEW}
          path={Routes.TRUSTS_USERS}
          component={TrustUsers}
          exact
        />
        <Gated.Route
          permission={Permissions.TRUST_HISTORY_VIEW}
          path={Routes.TRUSTS_HISTORY}
          component={TrustHistory}
          exact
        />
        <Gated.Route
          permission={Permissions.TRUST_HISTORY_VIEW}
          path={Routes.TRUSTS_HISTORY_VERSIONS}
          component={TrustHistoryVersions}
          exact
        />
        <Gated.Route
          permission={Permissions.TRUST_HISTORY_VIEW}
          path={Routes.TRUSTS_HISTORY_VERSION_DETAILS}
          component={TrustHistoryVersionDetails}
          exact
        />
        <Gated.Route
          permission={Permissions.CREATE_ROLE}
          path={Routes.ROLES_CREATION}
          component={RolesCreation}
        />
        <Gated.Route
          permission={Permissions.CONTENT_TRANSACTION_LIST}
          path={Routes.ACTIVITY_LIST}
          component={ActivityList}
          exact
        />
        <Gated.Route
          permission={Permissions.CONTENT_TRANSACTION_LIST}
          path={Routes.TRUST_ACTIVITY}
          component={ActivityDetailsList}
        />
        <Gated.Route
          permission={Permissions.VIEW_ROLES}
          exact
          path={Routes.ROLES_LIST}
          component={RolesList}
        />
        <Gated.Route
          permission={Permissions.ZIP_FILE}
          exact
          path={Routes.DOWNLOAD_ZIP}
          component={DownloadZip}
        />
        <Gated.Route permission="yes" exact path={Routes.USER_PROFILE} component={UserProfile} />
        <Gated.Route permission="yes" exact path={Routes.SETTINGS} component={Settings} />
        <Gated.Route
          permission="yes"
          exact
          path={Routes.CHANGE_PASSWORD}
          component={ChangePassword}
        />
        <Gated.Route permission="yes" exact path={Routes.DASHBOARD} component={Dashboard} />
        <Route exact path={Routes.LOGOUT} component={Logout} />
        <Route component={NotFound404} />
      </Switch>

      <Modal
        name="stillAliveModal"
        title={t('stillAliveModalTitle')}
        open={authStore.stillAliveModal}
        onSuccess={() => {
          authStore.keepAlive()
        }}
        closeOutside
        okOnly
        okIsLoading={authStore.isLoading}
        okMessage={t('stillAliveOkButton')}
        zIndex={200}
      >
        <div>{t('stillAliveModalContent')}</div>
        <div>{t('stillAliveModalContent2')}</div>
      </Modal>

      <TermsAndConditionsAlert
        showTermsModal={shouldShowTermsModal}
        closeTermsModal={closeTermsModal}
      />
    </MainLayout>
  )
}

export default observer(AuthorizedRouter)
