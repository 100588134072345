import axios from 'axios'
import { API_URL } from 'services/config'

/* eslint-disable class-methods-use-this */
class SignedDocumentsService {
  notifySignedUploadTrust = (file, type, trust, letter) =>
    axios
      .post(`${API_URL}/tenant/trusts/certify/file`, { file, type, trust, letter })
      .then((response) => response.data.data)

  notifySignedDeletionTrust = (file, type, trust, letter) =>
    axios
      .delete(`${API_URL}/tenant/trusts/certify/file`, { data: { letter, type } })
      .then((response) => response.data.data)

  notifySignedUploadCorporation = (file, type, financialStructure, letter) =>
    axios
      .post(`${API_URL}/tenant/financialStructures/certify/file`, {
        file,
        type,
        financialStructure,
        letter,
      })
      .then((response) => response.data.data)

  notifySignedDeletionCorporation = (type, letter) =>
    axios
      .delete(`${API_URL}/tenant/financialStructures/certify/file`, {
        data: { type, letter },
      })
      .then((response) => response.data.data)
}

export default SignedDocumentsService
