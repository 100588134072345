import Part, { TYPE_INPUT_CREATABLE_DATA_LIST } from './Part'
import FormPartStore from './FormPartStore'

class CreatableDataListPart extends Part {
  constructor(id, label, dataKey, validationRules) {
    super(id, TYPE_INPUT_CREATABLE_DATA_LIST)

    this.label = label
    this.dataKey = dataKey
    this.validationRules = validationRules
  }

  static fromJson({ id, display, dataKey, validationRules = null }, onCreateInput) {
    const { label = '' } = display

    onCreateInput(id, new FormPartStore())

    return new CreatableDataListPart(id, label, dataKey, validationRules)
  }
}

export default CreatableDataListPart
