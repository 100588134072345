import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { observer } from 'mobx-react'
import CheckIcon from 'mdi-react/CheckIcon'
import styles from './roleSelectList.module.scss'

const RoleSelectList = ({ handleSetUserRole, selectedUser, hasCheckbox }) => {
  return (
    <div className={styles.selectedList}>
      <div className={styles.roles}>
        {selectedUser.roles.map((role) => (
          <div
            key={role.id}
            onClick={() => {
              if (handleSetUserRole) handleSetUserRole(selectedUser, role)
            }}
            className={c(
              styles.role,
              selectedUser.selectedRoles &&
                selectedUser.selectedRoles.includes(role.id) &&
                styles.selectedRole
            )}
            role="button"
            tabIndex="0"
          >
            {hasCheckbox && (
              <div className={styles.checkbox}>
                <CheckIcon />
              </div>
            )}
            <div className={styles.roleName}>{role.name}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

RoleSelectList.propTypes = {
  selectedUser: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  handleSetUserRole: PropTypes.func,
  hasCheckbox: PropTypes.bool,
}
RoleSelectList.defaultProps = {
  handleSetUserRole: null,
  hasCheckbox: false,
}
export default observer(RoleSelectList)
