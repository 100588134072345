/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Route, Redirect } from 'react-router-dom'
import { useLocation } from 'react-router'
import DynamicFormsStore from 'stores/DynamicFormsStore'
import DynamicFormSection from 'scenes/DynamicForm/DynamicFormSection'

const DynamicFormRoutes = ({
  baseRoute,
  onResetComponent,
  dynamicEntityName,
  dynamicFormsStore,
}) => {
  const { entitySections: formSections, inputs, isViewMode, loadingRoutes } = dynamicFormsStore
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const formInitialComponentUrl = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < formSections.length; i++) {
      const formSection = formSections[i]

      if (dynamicFormsStore.canViewSection(formSection)) {
        return `${baseRoute}${formSections[i].route}`
      }
    }

    return formSections.length > 0 ? `${baseRoute}${formSections[0].route}` : null
  }

  if (loadingRoutes || formSections.length === 0) {
    return <div />
  }

  return (
    <>
      <Route
        render={() => {
          if (params.get('id') === null) {
            onResetComponent()

            return <Redirect to={formInitialComponentUrl()} />
          }

          return (
            <Redirect
              to={`${formInitialComponentUrl()}?id=${params.get('id')}&v=${params.get('v')}`}
            />
          )
        }}
        exact
        path={baseRoute}
      />
      {formSections.map((formSection) => {
        return (
          <Route
            render={() => (
              <DynamicFormSection
                defaultRedirectionUrl={`${baseRoute}?id=${params.get('id')}&v=${params.get('v')}`}
                dynamicEntityName={dynamicEntityName}
                formSection={formSection}
                readOnly={isViewMode}
                inputs={inputs}
              />
            )}
            path={`${baseRoute}${formSection.route}`}
            key={`dynamicRoute_${formSection.id}`}
          />
        )
      })}
    </>
  )
}

DynamicFormRoutes.propTypes = {
  dynamicFormsStore: PropTypes.instanceOf(DynamicFormsStore),
  baseRoute: PropTypes.string.isRequired,
  onResetComponent: PropTypes.func.isRequired,
  dynamicEntityName: PropTypes.string.isRequired,
}

export default observer(DynamicFormRoutes)
