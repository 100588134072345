import axios from 'axios'
import { API_URL } from 'services/config'
import ListTrust from 'models/ListTrust'
import Valuation from 'models/Valuation'

const { CancelToken } = axios

/* eslint-disable class-methods-use-this */
class TrustService {
  listTrusts = (
    page,
    search = null,
    status,
    orderParam = null,
    orderDir = null,
    alternativeStatus = false
  ) =>
    axios
      .get(
        `${API_URL}/tenant/trusts?page=${page}&sort[${orderParam || 'updatedAt'}]=${
          orderDir || 'desc'
        }&status=${status}&hasAlternativeStatus=${alternativeStatus}&search=${search || ''}`
      )
      .then((response) => ({
        trusts: response.data.data.map((listTrust) => ListTrust.fromJson(listTrust)),
        pagination: response.data.pagination,
      }))

  saveTrust = (json, id) => {
    if (id) {
      return axios.put(`${API_URL}/tenant/trusts`, json).then((response) => response.data.data)
    }

    return axios.post(`${API_URL}/tenant/trusts`, json).then((response) => response.data.data)
  }

  validateTrust = (id) =>
    axios.post(`${API_URL}/tenant/trusts/validate`, { id }).then((response) => response)

  changeNextStatus = (id, jsonParams = {}) =>
    axios
      .post(`${API_URL}/tenant/trusts/status/forward`, { id, ...jsonParams })
      .then((response) => response)

  changeBackStatus = (id) =>
    axios.post(`${API_URL}/tenant/trusts/status/rollback`, { id }).then((response) => response)

  loadMetadata = (trustId, dataId) => {
    const url = trustId
      ? `${API_URL}/metadata/trusts/${trustId}/${dataId}`
      : `${API_URL}/metadata/trusts`
    return axios.get(url).then(({ data }) => data.data.content.types.trust)
  }

  updateMetadata = (trustId) =>
    axios
      .put(`${API_URL}/metadata/fiduciary-structures/${trustId}`)
      .then((response) => response.data.data.content.types.trust)

  getTrust = (id, alternativeStatus = false) =>
    axios
      .get(
        `${API_URL}/tenant/trusts/data/${id}?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)

  getAdditionalTrustData = (id) =>
    axios.get(`${API_URL}/tenant/trusts/${id}/blockchain`).then((response) => response.data.data)

  getDeedsSection = (alternativeStatus, id) =>
    axios
      .get(
        `${API_URL}/tenant/trusts/deed/${id}?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)

  getLowsSection = (alternativeStatus, id) =>
    axios
      .get(
        `${API_URL}/tenant/trusts/letters/${id}?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)

  getOtherDocumentsSection = (alternativeStatus, id) =>
    axios
      .get(
        `${API_URL}/tenant/trusts/other-documents/${id}?status=${
          alternativeStatus ? 'alternative' : 'current'
        }`
      )
      .then((response) => response.data.data)

  listAssetTrusts = (term) => {
    if (this.listAssetTrustsCall) {
      this.listAssetTrustsCall.cancel('Only one request allowed at a time.')
    }

    this.listAssetTrustsCall = CancelToken.source()

    return axios
      .get(`${API_URL}/tenant/trusts/assets?search=${term}`, {
        cancelToken: this.listAssetTrustsCall.token,
      })
      .then((response) => response.data.data)
  }

  getZip = (id) => {
    return axios.get(`${API_URL}/tenant/files/trust/${id}`).then((response) => response.data.data)
  }

  getFiduciaryChart = (withPeople, trustId) =>
    axios
      .get(
        `${API_URL}/tenant/fiduciaryStructures/${trustId}/structure-chart${
          withPeople ? '?withPeople' : ''
        }`
      )
      .then((response) => response.data.data)

  getTrustValuations = (id, date) => {
    return axios
      .get(`${API_URL}/trusts/${id}/valuation${date && `?date=${date}`}`)
      .then((response) => ({
        valuables: Object.keys(response.data.data.valuables).map((key) => {
          return Valuation.fromJson(response.data.data.valuables[key])
        }),
        invaluables: [Valuation.fromJson(response.data.data.invaluables)],
        misvalued: [Valuation.fromJson(response.data.data.misvalued)],
      }))
  }

  getBlockChainData = (trustId) =>
    axios.get(`${API_URL}/tenant/trusts/${trustId}/blockchain`).then((response) => response)

  sendBlockChainData = (transactionId, transactionData) =>
    axios
      .post(`${API_URL}/tenant/blockchain/${transactionId}/send`, {
        signedTransaction: { ...transactionData },
      })
      .then((response) => response)

  verifyTransactionCollisions = (trustId) =>
    axios
      .get(`${API_URL}/tenant/trust/${trustId}/activities/pending`)
      .then((response) => response.data.data.filter((transaction) => transaction.hasCollision))

  deleteTransactionCollisions = (ids) =>
    axios
      .delete(`${API_URL}/tenant/activities/bulk`, { data: { content: ids } })
      .then((response) => response.data.data)
}

export default TrustService
