import Part, { TYPE_TRUST_DOCUMENTS } from './Part'

class TrustDocumentsPart extends Part {
  constructor(id) {
    super(id, TYPE_TRUST_DOCUMENTS)
  }

  static getInstance() {
    return new TrustDocumentsPart('trustDocuments')
  }
}

export default TrustDocumentsPart
