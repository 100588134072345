class GovernorActivity {
  constructor(
    activityId,
    id,
    name,
    governorType,
    description,
    created,
    updated,
    status,
    type,
    isConfirmed
  ) {
    this.id = id
    this.name = name
    this.governorType = governorType
    this.description = description
    this.created = created
    this.updated = updated
    this.status = status
    this.type = type
    this.activityId = activityId
    this.isConfirmed = isConfirmed
  }

  static fromJson({
    subjectAt: {
      contact: { id, name, type },
    },
    description,
    created,
    updated,
    status,
    type: activityType,
    id: activityId,
    isConfirmed,
  }) {
    return new GovernorActivity(
      activityId,
      id,
      name,
      type,
      description,
      created,
      updated,
      status,
      activityType,
      isConfirmed
    )
  }

  get isApproved() {
    return this.status === 'approved'
  }

  get showingName() {
    return this.name
  }

  get showingEntity() {
    return this.governorType
  }
}

export default GovernorActivity
