import { observable, action, makeObservable } from 'mobx'

export default class InputStore {
  error = false
  value = ''
  errorMessage = ''

  constructor(initialValue = '') {
    makeObservable(this, {
      // observables
      error: observable,
      value: observable,
      errorMessage: observable,
      // computeds
      setError: action,
      setValue: action,
      clearError: action,
    })

    this.value = initialValue
  }

  setError(error, errorMessage = '') {
    this.error = error
    this.errorMessage = errorMessage
  }

  setValue(value) {
    this.value = value

    this.clearError()
  }

  clearError() {
    this.error = false
    this.errorMessage = ''
  }
}
