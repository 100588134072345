import React from 'react'
import { useTranslation } from 'react-i18next'
import GenericNotFoundError from 'presentation/GenericNotFoundError'
import PropTypes from 'prop-types'
import image from './serverError.svg'

const ServerError = ({ handleTryAgain }) => {
  const { t } = useTranslation('serverError')

  return (
    <GenericNotFoundError
      image={image}
      description={t('serviceNotAvailableSubtitle')}
      textButton={t('tryAgain')}
      goBackFunction={handleTryAgain}
      title={t('serviceNotAvailableTitle')}
    />
  )
}

ServerError.propTypes = {
  handleTryAgain: PropTypes.func.isRequired,
}

export default ServerError
