import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import { InstanceEntity } from 'models/FormMetadata'

class InstanceEntitiesList {
  instanceEntities = []
  error = false
  errorMessage = ''
  firstLevelError = false
  validationCountErrors = 0

  constructor(entityListPart, validationRules) {
    this.entityListPart = entityListPart
    this.validationRules = validationRules

    makeAutoObservable(this)
  }

  setError(error, errorMessage = '', firstLevelError = false) {
    this.error = error
    this.errorMessage = errorMessage
    this.firstLevelError = firstLevelError
  }

  clearError() {
    this.error = false
    this.errorMessage = ''
    this.firstLevelError = false
  }

  addInstanceEntity(instanceEntity) {
    this.instanceEntities.push(instanceEntity)
    this.clearError()
  }

  removeInstanceEntity(instanceEntity) {
    this.instanceEntities.remove(instanceEntity)
    this.clearError()
  }

  fill(listData) {
    this.instanceEntities = []

    listData.forEach((data) => {
      const entity = this.entityListPart.getEntityByType(data.type)

      if (entity) {
        const instanceEntity = new InstanceEntity(data.type, entity.rawParts, entity, data.isStored)

        instanceEntity.fillFromJson(data)
        instanceEntity.setId(data.id)

        this.entityListPart.addInstanceEntity(instanceEntity)
      }
    })
  }

  get json() {
    return this.instanceEntities.map((instanceEntity) => instanceEntity.json)
  }

  get validationSchema() {
    let validation = yup.array()

    if (this.validationRules.get('min')) {
      validation = validation.min(this.validationRules.get('min'))
    }

    if (this.validationRules.get('max')) {
      validation = validation.max(this.validationRules.get('max'))
    }

    if (this.validationRules.get('required')) {
      validation = validation.min(1)
    }

    return validation
  }

  countErrors() {
    let errors = 0

    this.instanceEntities.forEach((instanceEntity) => {
      errors += instanceEntity.validationCountErrors
    })

    if (this.error) {
      errors += 1
    }

    runInAction(() => {
      this.validationCountErrors = errors
    })
  }

  async validate() {
    this.clearError()

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (this.validationRules) {
        try {
          this.instanceEntities.forEach((instanceEntity) => {
            instanceEntity.validate()
          })
          await this.validationSchema.validate(this.json || undefined)

          this.countErrors()
          resolve(true)
        } catch (err) {
          this.instanceEntities.forEach((instanceEntity) => {
            instanceEntity.validate()
          })
          this.setError(
            true,
            err.message,
            this.validationRules && !this.validationSchema.isValidSync(this.json || undefined)
          )
          this.countErrors()

          reject(err.message)
        }
      }

      resolve(true)
    })
  }

  get isValid() {
    return !this.validationCountErrors
  }
}

export default InstanceEntitiesList
