import React, { useContext, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StoresContext from 'providers/storeContext'
import LettersSection from 'presentation/LettersSection'
import StatutesSectionStore from 'stores/StatutesSectionStore'
import StatutesArchiveStore from 'stores/StatutesArchiveStore'
import saveCorporation from 'util/saveCorporation'

const DynamicFormPartStatutes = () => {
  const { t } = useTranslation('corporationsCreation')
  const { corporationsCreationStore } = useContext(StoresContext)
  const { statutesStore } = corporationsCreationStore

  const [statutesSectionStore] = useState(() => new StatutesSectionStore(statutesStore))
  const [statutesArchiveStore] = useState(
    () => new StatutesArchiveStore(corporationsCreationStore.idEdition)
  )

  const handleResetMainSection = useCallback(() => statutesSectionStore.reset(), [])
  const validationHandler = useCallback(() => corporationsCreationStore.validateStatute(), [])
  const handleSaveEntity = useCallback(
    () => saveCorporation(corporationsCreationStore, t('corporationCreatedMsg')),
    []
  )

  if (corporationsCreationStore.idEdition === undefined && corporationsCreationStore.isLoading) {
    return null
  }

  return (
    <LettersSection
      title={t('statute')}
      mainTabName={t('statute')}
      showExistsDraft={
        !statutesSectionStore.draftEditionMode &&
        statutesStore.draftMainLetter !== null &&
        corporationsCreationStore.hasAlternativeStatus
      }
      existsDraftText={t('draftInProgress')}
      resetMainSection={handleResetMainSection}
      isLoading={statutesStore.isLoading || corporationsCreationStore.isLoading}
      mainStore={statutesStore}
      mainSectionStore={statutesSectionStore}
      letterArchiveStore={statutesArchiveStore}
      handleSaveEntity={handleSaveEntity}
      isViewMode={corporationsCreationStore.isViewMode}
      mainEntityStatus={corporationsCreationStore.entityStatus}
      mainEntityHasAlternativeStatus={corporationsCreationStore.hasAlternativeStatus}
      mainSectionCanBeRevoked={false}
      showRulesAlert={false}
      validationHandler={validationHandler}
    />
  )
}

export default observer(DynamicFormPartStatutes)
