import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import MetaTitle from 'util/MetaTitle'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'

const TrustCreationTitle = () => {
  const { trustsCreationStore } = useContext(StoresContext)
  const { t } = useTranslation('trustsCreation')
  let pageTitle = t('titleCreation')

  if (trustsCreationStore.isViewMode) {
    pageTitle = t('titleViewName', { name: trustsCreationStore.entityName })
  } else if (trustsCreationStore.isEdition) {
    pageTitle = t('titleEditionName', { name: trustsCreationStore.entityName })
  }

  return <MetaTitle pageTitle={pageTitle} />
}

export default observer(TrustCreationTitle)
