import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { InputListPart } from 'models/FormMetadata'
import DynamicFormSubtitle from 'presentation/DynamicFormSubtitle'
import FullWidthInputContainer from 'presentation/FullWidthInputContainer'
import DynamicFormPart from '../DynamicFormPart'
import styles from './dynamicFormPartInputList.module.scss'

const DynamicFormPartInputList = ({
  part,
  readOnly,
  inputs,
  firstInputRef,
  modal,
  tab,
  isEntityStored,
}) => {
  const { title, parts } = part
  const twoColumns = useCallback(() => parts.length > 3, [])

  return (
    <>
      {!tab && title !== undefined && title !== '' && title !== null && (
        <DynamicFormSubtitle label={title} />
      )}
      <div
        className={c(
          styles.inputsContainer,
          modal && styles.insideModal,
          twoColumns() && styles.twoColumns
        )}
      >
        {parts.map((subPart, index) => {
          let InputWrapper = Fragment

          if (
            twoColumns() &&
            (subPart.isTextarea ||
              subPart.isBool ||
              subPart.isCertifiedFile ||
              subPart.isLowsSnapShot ||
              subPart.isContactSearch ||
              subPart.isEntitySnapshotList)
          ) {
            InputWrapper = FullWidthInputContainer
          }

          return (
            <InputWrapper key={`dfp_${subPart.id}`}>
              <DynamicFormPart
                part={subPart}
                inputs={inputs}
                readOnly={readOnly}
                inputRef={index === 0 ? firstInputRef : undefined}
                modal={modal}
                isEntityStored={isEntityStored}
              />
            </InputWrapper>
          )
        })}
      </div>
    </>
  )
}

DynamicFormPartInputList.propTypes = {
  part: PropTypes.instanceOf(InputListPart).isRequired,
  // TODO: We need to expand the proptypes, this circumambulation of props is not a good practice.
  inputs: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  modal: PropTypes.bool,
  tab: PropTypes.bool,
  firstInputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isEntityStored: PropTypes.bool,
}

DynamicFormPartInputList.defaultProps = {
  readOnly: true,
  modal: false,
  tab: false,
  firstInputRef: () => {},
  isEntityStored: false,
}

export default DynamicFormPartInputList
