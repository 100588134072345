import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import c from 'classnames'
import AlertIcon from 'mdi-react/AlertIcon'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import { CHANGE_CLIENT_STATUS } from 'stores/AuthStore'
import Button from 'presentation/Button'
import AccountRemoveIcon from 'mdi-react/AccountRemoveIcon'
import AccountCheckIcon from 'mdi-react/AccountCheckIcon'
import ToolTip from 'presentation/ToolTip'
import styles from './clientChangeStatusButton.module.scss'

const ClientChangeStatusButton = ({ collapsed, toggleNavBar }) => {
  const { t } = useTranslation('clientsCreation')
  const { clientsCreationStore, authStore } = useContext(StoresContext)
  const label = clientsCreationStore.entityStatus.isActive ? t('inactivate') : t('activate')
  const handleClick = useCallback(() => {
    clientsCreationStore.tryToChangeStatus()
    toggleNavBar()
  }, [])

  if (!authStore.can(CHANGE_CLIENT_STATUS)) {
    return null
  }

  const iconStatus = () =>
    clientsCreationStore.entityStatus.isActive ? (
      <AccountRemoveIcon size={18} />
    ) : (
      <AccountCheckIcon size={18} />
    )

  return (
    <div className={c(styles.changeStatusButton, collapsed && styles.collapsed)}>
      {clientsCreationStore.showChangeStatusError && !collapsed && (
        <ToolTip placement="right" tooltipContent={<div>{t('viewChangeErrors')}</div>}>
          <AlertIcon size={20} className={styles.alertExecution} onClick={handleClick} />
        </ToolTip>
      )}
      <Button
        fullWidth
        small
        label={label}
        onClick={handleClick}
        isLoading={clientsCreationStore.loadingActivation}
        className={collapsed ? styles.buttonCollapsable : null}
        icon={collapsed && iconStatus()}
        iconPositionLeft
      />
    </div>
  )
}

ClientChangeStatusButton.propTypes = {
  toggleNavBar: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
}

ClientChangeStatusButton.defaultProps = {
  collapsed: false,
}

export default observer(ClientChangeStatusButton)
