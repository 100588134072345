import { makeAutoObservable, runInAction } from 'mobx'
import moment from 'moment'
import Cookies from 'js-cookie'
import AuthService from 'services/AuthService'
import * as Routes from 'routing/routes'
import { STILL_ALIVE_MODAL_DIFF_TIME, STILL_ALIVE_LOGOUT_DIFF_TIME } from 'services/config'
import { warningToast } from 'helpers/Toast'
import i18n from 'util/i18n'

// keys
export const TENANT_IMPORT_PUBLIC_KEY = 'tenants.import.public.key'
export const TENANT_SHARE_PRIVATE_KEY = 'tenants.share.private.key'

// Settings governing law permissions
export const VIEW_SETTINGS = 'sections.settings'

// Dashboard permissions
export const VIEW_DASHBOARD = 'dashboard.view'

// trusts permissions
export const VIEW_SNAPSHOT = 'trusts.snapshot.view'

// trusts permissions
export const VIEW_TRUSTS = 'trusts.view'
export const CREATE_TRUST = 'trusts.create'
export const EDIT_TRUST = 'trusts.update'
export const CHANGE_TRUST_STATUS = 'trusts.status.change'
export const ZIP_FILE = 'zip.file'

// clients permissions
export const VIEW_CLIENTS = 'clients.view'
export const CREATE_CLIENT = 'clients.create'
export const EDIT_CLIENT = 'clients.update'
export const CHANGE_CLIENT_STATUS = 'clients.activate'

// corporations permissions
export const VIEW_CORPORATIONS = 'financialStructures.view'
export const CREATE_CORPORATION = 'financialStructures.create'
export const EDIT_CORPORATION = 'financialStructures.update'
export const CHANGE_CORPORATION_STATUS = 'financialStructures.status.change'

// user permissions
export const VIEW_USERS = 'users.view'
export const CREATE_USER = 'users.create'
export const EDIT_USER = 'users.update'
export const CHANGE_USER_STATUS = 'users.status.change'

// contact permissions
export const LIST_CONTACT = 'contacts.list'
export const DELETE_CONTACT = 'contacts.delete'
export const VIEW_CONTACT = 'contacts.view'
export const SAVE = 'contacts.save'

// roles permissions
export const VIEW_ROLES = 'roles.view'
export const CREATE_ROLE = 'roles.create'
export const EDIT_ROLE = 'roles.update'

// transactions
export const TRANSACTIONS_LIST = 'transactions.list'
export const TRANSACTIONS_CREATE = 'transactions.create'
export const TRANSACTIONS_DELETE = 'transactions.delete'
export const TRANSACTIONS_APPROVE = 'transactions.approve'
export const TRANSACTIONS_STORE = 'transactions.store'
export const TRANSACTIONS_REJECT = 'transactions.reject'
export const TRANSACTIONS_SECTION = 'sections.content.transaction'
export const TRANSACTIONS_FORCE_BLOCKCHAIN = 'content.transaction.run.trigger'
export const CONTENT_TRANSACTION_LIST = 'content.transaction.list'

// votes
export const VOTE_CREATE = 'votes.create'
export const VOTE_CAST = 'votes.cast'
export const VOTE_VIEW = 'votes.view'
export const VOTE_LIST = 'votes.list'
export const VOTE_CLOSE = 'votes.close'

// trust users
export const TRUST_USERS_VIEW = 'trust.user'

export const TRUST_VALUATIONS = 'trusts.valuation.view'
export const TRUST_HISTORY_VIEW = 'trusts.activity.history.view'

const getJWTExpDate = (token) => moment(JSON.parse(atob(token.split('.')[1])).exp * 1000)

class AuthStore {
  authUser = null
  stillAliveModal = false
  isLoading = false
  isLoadingAuthFromBrowser = false
  firstLoadToken = false
  isMaintenancePage = false
  mustAccept = false
  checkedConditions = false
  termsAndConditions = ''
  termsIsNotSelected = false
  currentVersion = false
  modalTermsAndConditions = false
  stillAliveTimeout = null
  stillAliveLogoutTimeout = null
  logoutToast = null

  constructor(rootStore) {
    this.rootStore = rootStore
    this.authService = new AuthService()

    makeAutoObservable(this)

    this.loadAuthFromBrowser().then(() => {
      runInAction(() => {
        this.firstLoadToken = true
        this.isLoading = false
        this.isLoadingAuthFromBrowser = false
      })
    })

    // runs when the content of its tab have become visible or have been hidden
    document.addEventListener(
      'visibilitychange',
      () => {
        const token = this.authService.getToken()
        if (!token || (token && this.checkValidToken(token) === 'expired')) {
          this.logout()
        } else if (this.authUser && token && token !== this.authUser.token) {
          this.updateToken(token)
        }
      },
      false
    )
  }

  // Devuelve el tiempor hasta que el token quede completamente expirado
  // eslint-disable-next-line class-methods-use-this
  getTimeToExpiration(token) {
    if (token) {
      const expDate = getJWTExpDate(token) // Fecha de expiracion del token
      return moment.utc(moment(expDate).diff(moment())).valueOf() // Tiempo hasta la expiracion del token
    }

    return 0
  }

  // Devuelve el tiempo hasta que se muestre el modal
  getTimeToShowModal(token) {
    if (token) {
      return this.getTimeToExpiration(token) - STILL_ALIVE_MODAL_DIFF_TIME
    }
    return 0
  }

  // Devuelve el tiempo hasta que se ejecute el logout
  getTimeToLogout(token) {
    if (token) {
      return this.getTimeToExpiration(token) - STILL_ALIVE_LOGOUT_DIFF_TIME
    }

    return 0
  }

  // Verifica en que rango se encuenta el token
  checkValidToken(token) {
    if (this.getTimeToLogout(token) <= 0) {
      return 'expired'
    }
    if (this.getTimeToShowModal(token)) {
      return 'modal'
    }

    return true
  }

  async loadAuthFromBrowser() {
    this.isLoading = true
    this.isLoadingAuthFromBrowser = true
    const authUser = await this.authService.loadAuthUserFromBrowser()

    if (authUser) {
      switch (this.checkValidToken(authUser.token)) {
        case 'expired':
          // SI el token esta expirado desloguea
          this.logout()
          return Promise.reject()
        case 'modal':
          // SI el token esta en el rango de mostrar el modal, autentica con el token, sin mostrar el modal y actualiza el token
          return this.authenticate(authUser, true).then(() => {
            this.keepAlive()
            return Promise.resolve()
          })
        default:
          // Token valido, autentica y actualiza el token
          return this.authenticate(authUser).then(() => {
            this.keepAlive()
            return Promise.resolve()
          })
      }
    }

    // Desloguea en caso de no existir token
    this.logout()

    return Promise.resolve()
  }

  async basicLogin(username, password, rememberMe) {
    return this.authService.authenticate(username, password, rememberMe).then((authUser) => {
      this.authenticate(authUser)
      if (this.logoutToast) {
        this.logoutToast.hide()

        setTimeout(() => {
          this.logoutToast = null
        })
      }

      return authUser.token
    })
  }

  resetSettings() {
    this.hideStillAliveModal()
    if (this.logoutToast) {
      this.logoutToast.hide()

      setTimeout(() => {
        this.logoutToast = null
      })
    }
  }

  showStillAliveModal() {
    this.stillAliveModal = true
  }

  hideStillAliveModal() {
    this.stillAliveModal = false
  }

  updateAuthUser(authUser) {
    this.authUser = authUser
    this.updateToken(authUser.token)
  }

  setSessionsTimes(token, noModal) {
    // Corta los timeouts de expiracion
    clearTimeout(this.stillAliveLogoutTimeout)
    clearTimeout(this.stillAliveTimeout)

    // Muestra el modal de verificacion de actividad cuando se cumpla el tiempo
    if (!noModal) {
      this.stillAliveTimeout = setTimeout(() => {
        const cookieToken = this.authService.getToken()
        if (this.checkValidToken(cookieToken) === 'modal') {
          this.showStillAliveModal()
        }
      }, this.getTimeToShowModal(token))
    }

    // Cuando se cumpla el tiempo de vida, se desloguea
    this.stillAliveLogoutTimeout = setTimeout(() => {
      const cookieToken = this.authService.getToken()
      if (this.checkValidToken(cookieToken) === 'expired') {
        this.hideStillAliveModal()
        this.logout(true)
      }
    }, this.getTimeToLogout(token))
  }

  authenticate(authUser, noModal) {
    this.updateAuthUser(authUser)
    const { token } = authUser
    this.setSessionsTimes(token, noModal)
    this.rootStore.settingsStore.loadSettingsFromBrowser()
    return Promise.resolve()
  }

  updateToken(token) {
    this.resetSettings()
    this.authUser.setToken(token)

    this.authService.updateToken(token)
    this.setSessionsTimes(token)
  }

  keepAlive() {
    const token = Cookies.get('token')

    if (!this.isLoading || !token) {
      this.isLoading = true
      return this.authService.keepAlive().then((response) => {
        const { jwt, email } = response
        if (email !== this.authUser.email) {
          this.authUser.getAuthUserByToken(jwt).then((authUser) => this.updateAuthUser(authUser))
        } else {
          this.authUser.updateFromJson(response)
          this.updateToken(jwt)
        }

        this.hideStillAliveModal()

        this.isLoading = false
        return jwt
      })
    }
    return false
  }

  logout(timeout = false) {
    // clear expiration timeouts
    clearTimeout(this.stillAliveLogoutTimeout)
    clearTimeout(this.stillAliveTimeout)

    this.authService.logout()

    setTimeout(() => {
      if (this.authUser) {
        this.authUser.setToken(null)
      }

      this.rootStore.settingsStore.removeSettings()

      if (timeout) {
        this.logoutToast = warningToast(
          i18n.t('logoutToast'),
          { autoClose: false },
          'loggedOut'
        ).show()
      }
    })
  }

  setIsMaintenancePage(value) {
    this.isMaintenancePage = value
  }

  get isAuthenticated() {
    return (
      this.authUser !== null &&
      this.authUser.token &&
      this.checkValidToken(this.authUser.token) !== 'expired'
    )
  }

  // eslint-disable-next-line class-methods-use-this
  get dashboardRoute() {
    return Routes.DASHBOARD
  }

  can = (permission) => permission === 'yes' || this.authUser.permissions.includes(permission)
}

export default AuthStore
