import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { EntityListPart } from 'models/FormMetadata'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import DynamicEntitiesTable from 'presentation/DynamicEntitiesTable'
import tableStyles from 'styles/tables.module.scss'
import PartEntityRow from './PartEntityRow'

const PartEntityList = ({ part, handleClickRow, handleDeleteRow, readOnly }) => (
  <TransitionGroup className={tableStyles.tableRowGrid}>
    {part.instanceEntitiesList.instanceEntities.map((instanceEntity, i) => {
      return (
        <CSSTransition
          // eslint-disable-next-line react/no-array-index-key
          key={`instanceEntity_${i}`}
          timeout={200}
          className={tableStyles.rowTransition}
          classNames={{
            enter: tableStyles['rowTransition-enter'],
            enterActive: tableStyles['rowTransition-enter-active'],
            exit: tableStyles['rowTransition-exit'],
            exitActive: tableStyles['rowTransition-exit-active'],
          }}
        >
          <DynamicEntitiesTable
            handleDelete={() => handleDeleteRow(instanceEntity)}
            viewMode={readOnly}
          >
            <PartEntityRow
              handleClickRow={() => handleClickRow(instanceEntity)}
              instanceEntity={instanceEntity}
              entity={part.getEntityByType(instanceEntity.type)}
            />
          </DynamicEntitiesTable>
        </CSSTransition>
      )
    })}
  </TransitionGroup>
)

PartEntityList.propTypes = {
  part: PropTypes.instanceOf(EntityListPart).isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

PartEntityList.defaultProps = {
  readOnly: true,
}

export default observer(PartEntityList)
