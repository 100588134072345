import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import ActiveEntity from 'models/ActiveEntity'
import AssetActivity from 'models/AssetActivity'
import ContactActivity from 'models/ContactActivity'
import GovernorActivity from 'models/GovernorActivity'
import DocumentActivity from 'models/DocumentActivity'
import TransactionActivity from 'models/TransactionActivity'
import StoreApprovalActivity from 'models/StoreApprovalActivity'
import TableItemDate from 'presentation/TableItemDate'
import UpdateIcon from 'mdi-react/UpdateIcon'
import styles from './activityTableItem.module.scss'

const ActivityTableItem = ({ listItem, view }) => {
  const { t } = useTranslation('activity')

  return (
    <NavLink
      to={`/activity/details/${listItem.id}?structure=${listItem.type}`}
      className={[styles.activityTableItem, styles[`activityTableItem--${view}`]].join(' ')}
    >
      <div className={styles.itemName}>{listItem.description}</div>
      <div className={styles.activityTableItemDoneBy}>
        {t('doneBy', {
          name: listItem.updated.name || t('unknown'),
        })}
      </div>
      <div className={styles.activityTableItemEntity}>
        {view === 'detail'
          ? listItem.name
          : t('onEntity', {
              entity: listItem.name,
              type: listItem.type === 'financialStructure' ? 'Corporation' : listItem.type,
            })}
      </div>
      <div className={styles.activityTableItemUpdatedAt}>
        <TableItemDate listDate={listItem.updated} view={view} icon={<UpdateIcon size={12} />} />
      </div>
      <div className={styles.activityTableItemCreatedAt}>
        <TableItemDate listDate={listItem.created} view={view} icon={<UpdateIcon size={12} />} />
      </div>
    </NavLink>
  )
}

ActivityTableItem.propTypes = {
  listItem: PropTypes.oneOfType([
    PropTypes.instanceOf(ActiveEntity),
    PropTypes.instanceOf(AssetActivity),
    PropTypes.instanceOf(ContactActivity),
    PropTypes.instanceOf(GovernorActivity),
    PropTypes.instanceOf(DocumentActivity),
    PropTypes.instanceOf(TransactionActivity),
    PropTypes.instanceOf(StoreApprovalActivity),
  ]).isRequired,
  view: PropTypes.string.isRequired,
}

export default ActivityTableItem
