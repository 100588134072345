import { action, makeObservable, observable, runInAction } from 'mobx'
import ActivityDetailsListService from 'services/ActivityDetailsListService'
import FiduciaryStructurePreloadDataStore from 'stores/FiduciaryStructurePreloadDataStore'
import InputStore from 'stores/InputStore'

class ActivityDetailsListStore extends FiduciaryStructurePreloadDataStore {
  constructor(id, structure) {
    super(id, structure)
    this.activityDetails = []
    this.isLoading = false
    this.selectedItem = null
    // Filter
    this.toDate = new InputStore()
    this.fromDate = new InputStore()
    // Pagination
    this.activePage = 1
    this.pages = 1

    this.showDropdownOrder = false
    // Service
    this.activityDetailsListService = new ActivityDetailsListService()

    makeObservable(this, {
      // observables
      activityDetails: observable,
      selectedItem: observable,
      toDate: observable,
      fromDate: observable,
      // actions
      loadDetails: action,
      selectItem: action,
      setShowDropdownOrder: action,
      setToDate: action,
      setFromDate: action,
    })
  }

  async loadDetails(orderParameter, orderDirection) {
    try {
      this.preRequest()
      const result = await this.activityDetailsListService.loadDetails(
        this.fiduciaryStructureId,
        this.structure,
        this.activePage,
        this.statusFilter,
        this.fromDate.value,
        this.toDate.value,
        orderParameter,
        orderDirection
      )
      runInAction(() => {
        this.activityDetails = result
        this.onSuccessRequest()
      })
    } catch (e) {
      this.onErrorRequest(e)
    }
  }

  selectItem(item) {
    this.selectedItem = item
  }

  setActivePage(value) {
    this.activePage = value
  }

  approveChange(id) {
    this.activityDetailsListService.approveChange(id)
  }

  rejectChange(id) {
    this.activityDetailsListService.rejectChange(id)
  }

  abstainChange(id) {
    this.activityDetailsListService.abstainChange(id)
  }

  confirmChange(id) {
    this.activityDetailsListService.confirmChange(id)
  }

  setShowDropdownOrder(value) {
    this.showDropdownOrder = value
  }

  setToDate(value) {
    this.toDate.setValue(value)
  }

  setFromDate(value) {
    this.fromDate.setValue(value)
  }

  sendActivityToBlockchain() {
    this.activityDetailsListService.sendToBlockchain(this.fiduciaryStructureId)
  }
}

export default ActivityDetailsListStore
