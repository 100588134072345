import PartStrategy from './PartStrategy'
import InstanceEntity from './InstanceEntity'
import ListView from './ListView'

class Entity {
  parts = []
  rawParts

  constructor(type, icon, name, listView, contactMapExceptions = null) {
    this.type = type
    this.icon = icon
    this.name = name
    this.listView = listView
    this.contactMapExceptions = contactMapExceptions
  }

  addPart(part) {
    this.parts.push(part)
  }

  createInputInstance() {
    return new InstanceEntity(this.type, this.rawParts, this)
  }

  static fromJson({ category, type, display, parts, contactMapExceptions = null }) {
    const { icon = '', name = '', listView = {} } = display
    const entityGroup = category || type

    const entity = new Entity(
      entityGroup,
      icon,
      name,
      ListView.fromJson(listView),
      contactMapExceptions
    )

    entity.rawParts = parts

    parts.forEach((part) => {
      const partInstance = PartStrategy.getPart(part, () => {})

      if (partInstance) {
        entity.addPart(partInstance)
      }
    })

    return entity
  }
}

export default Entity
