import { useCallback, useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import AccountIcon from 'mdi-react/AccountIcon'
import BriefcaseIcon from 'mdi-react/BriefcaseIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import * as Routes from 'routing/routes'
import StoresContext from 'providers/storeContext'
import MetaTitle from 'util/MetaTitle'
import { SAVE } from 'stores/AuthStore'
import Modal from 'scenes/Modal'
import ServerError from 'presentation/ServerError'
import MainSectionList, { MainSectionTable } from 'presentation/MainSectionList'
import Button from 'presentation/Button'
import ContactsListStore from './ContactsListStore'
import ContactsTableItem from './ContactsTableItem'
import ContactsMassiveImport from './ContactsMassiveImport'
import styles from './contactsList.module.scss'

const ContactsList = () => {
  const [contactsListStore] = useState(() => new ContactsListStore())
  const { hotkeyStore, inputContactsFileStore } = useContext(StoresContext)
  const [columns, setColumns] = useState([])
  const [buttons, setButtons] = useState([])
  const [showMassiveImportModal, setShowMassiveImportModal] = useState(false)
  const { t } = useTranslation('contacts')
  const history = useHistory()

  const handleCreateContact = useCallback(() => contactsListStore.toggleCreateContactModal(), [])

  const createIndividualContact = useCallback(
    () => history.push(Routes.INDIVIDUAL_CONTACT_CREATION),
    []
  )

  const handleClickMassiveImport = useCallback(() => {
    setShowMassiveImportModal(true)
    contactsListStore.toggleCreateContactModal()
  }, [])

  const createCompanyContact = useCallback(() => history.push(Routes.COMPANY_CONTACT_CREATION), [])

  const configureHotKeys = useCallback(() => {
    hotkeyStore.setConfig('contactList', [
      {
        shortcut: 'alt+c',
        handler: () => {
          handleCreateContact()
        },
      },
    ])
  }, [])

  const setModalButtons = useCallback(() => {
    const buttonArray = Object.assign([], buttons)

    buttonArray.push(
      <div className={styles.modalButtons} key="modalbuttons">
        <Button
          onClick={createIndividualContact}
          icon={<AccountIcon size={20} />}
          label={t('individual')}
          iconPositionLeft
          fullWidth
          big
        />
        <Button
          iconPositionLeft
          icon={<BriefcaseIcon size={20} />}
          onClick={createCompanyContact}
          fullWidth
          big
          label={t('company')}
        />
        <Button
          label={t('massiveImport')}
          icon={<AccountMultipleIcon size={20} />}
          fullWidth
          big
          iconPositionLeft
          onClick={handleClickMassiveImport}
        />
      </div>
    )

    setButtons(buttonArray)
  }, [])

  useEffect(() => {
    configureHotKeys()

    setColumns([
      {
        name: t('contactName'),
        order: true,
        inverted: true,
        transName: t('contactName'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'email',
        order: false,
        inverted: false,
        transName: t('common:email'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'updatedAt',
        order: true,
        inverted: false,
        transName: t('common:updatedAt'),
        cssGridDefinition: '1fr',
      },
      {
        name: 'wishesContacted',
        order: false,
        inverted: false,
        transName: t('common:wishesContacted'),
        cssGridDefinition: '230px',
      },
    ])

    setModalButtons()

    return () => hotkeyStore.removeConfig('contactsList')
  }, [])

  const handlePageChange = useCallback((pageTo) => contactsListStore.changeActivePage(pageTo), [])

  const handleCloseCreateContactModal = useCallback(
    () => contactsListStore.toggleCreateContactModal(),
    []
  )

  const handleSearch = useCallback(
    (value) => contactsListStore.loadContacts(value, null, null, true),
    []
  )

  const handleDeleteContact = useCallback((contactId) => {
    contactsListStore.showConfirmDelete()
    contactsListStore.setContactId(contactId)
  })

  const handleSuccessModal = useCallback(() => {
    contactsListStore.delecteContact()
    contactsListStore.hideConfirmDelete()
  })

  const handleCloseModal = useCallback(() => {
    contactsListStore.hideConfirmDelete()
  }, [])

  const handleCancelMassiveImport = useCallback(() => {
    setShowMassiveImportModal(false)
  }, [])

  const handleDownloadTemplate = useCallback(() => {
    contactsListStore.downloadTemplate()
  }, [])

  const handleRevokeTemplateUrl = useCallback(() => {
    contactsListStore.revokeTemplateUrl()
  }, [])

  const handleSuccessMassiveImport = useCallback(() => {
    inputContactsFileStore.fileUpload().then((error) => {
      if (!error) {
        setShowMassiveImportModal(false)
        contactsListStore.loadContacts()
      }
    })
  }, [inputContactsFileStore.eleFileInput])

  const handleChangeOrder = useCallback(
    (orderParamter, orderDirection) =>
      contactsListStore.loadContacts(
        null,
        orderParamter.includes('Contact')
          ? orderParamter.replace('Contact', '').toLowerCase().trim()
          : orderParamter,
        orderDirection,
        true
      ),
    []
  )

  const handleSetShowDropdownOrder = useCallback((value) => {
    contactsListStore.setShowDropdownOrder(value)
  }, [])

  const handleSetShowDropdownFilter = useCallback((value) => {
    contactsListStore.setShowDropdownFilter(value)
  }, [])

  const { visibleContacts, visibleCreateContactModal, visibleConfirmDelete } = contactsListStore

  const handleTryAgain = useCallback(() => contactsListStore.tryAgain(), [])

  if (contactsListStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }
  return (
    <>
      <MetaTitle pageTitle={t('pageTitles:contacts')} />
      <MainSectionList
        setShowDropdownOrder={handleSetShowDropdownOrder}
        setShowDropdownFilter={handleSetShowDropdownFilter}
        hasFilterStatus={contactsListStore.isFiltered}
        currentPage={contactsListStore.activePage}
        isLoading={contactsListStore.isLoading}
        handleChangeOrder={handleChangeOrder}
        totalPages={contactsListStore.pages}
        handleCreation={handleCreateContact}
        handlePageChange={handlePageChange}
        sectionNameSingular={t('contact')}
        sectionNamePlural={t('contacts')}
        sectionItems={visibleContacts}
        handleSearch={handleSearch}
        creationPermission={SAVE}
        tableComponent={
          <MainSectionTable
            t={t}
            showDropdownOrder={contactsListStore.showDropdownOrder}
            setShowDropdownOrder={handleSetShowDropdownOrder}
            isLoading={contactsListStore.isLoading}
            onOrdered={handleChangeOrder}
            listItems={visibleContacts}
            columns={columns}
            view="list"
            component={({ listItem, view: cView }) => (
              <ContactsTableItem
                view={cView}
                listItem={listItem}
                handleDeleteContact={handleDeleteContact}
                link={`${
                  listItem.isIndividual
                    ? Routes.INDIVIDUAL_CONTACT_CREATION
                    : Routes.COMPANY_CONTACT_CREATION
                }?id=${listItem.id}&v=true`}
              />
            )}
          />
        }
      />
      <Modal
        onCancel={handleCloseCreateContactModal}
        open={visibleCreateContactModal}
        name="createContactModal"
        customButtons={buttons}
        title={t('addNew')}
        close
      >
        <span>{t('selectTypeContact')}</span>
      </Modal>
      <Modal
        open={showMassiveImportModal}
        title={t('massiveImport')}
        name="massiveImport"
        onSuccess={handleSuccessMassiveImport}
        onCancel={handleCancelMassiveImport}
      >
        <ContactsMassiveImport
          templateStore={contactsListStore.templateFile}
          handleDownloadTemplate={handleDownloadTemplate}
          templateFileUrl={contactsListStore.templateUrl}
          handleRevokeTemplateUrl={handleRevokeTemplateUrl}
        />
      </Modal>
      <Modal
        title={t('confirmationDeleteContact')}
        onSuccess={handleSuccessModal}
        open={visibleConfirmDelete}
        onCancel={handleCloseModal}
        name="confirmDelete"
      />
    </>
  )
}

export default observer(ContactsList)
