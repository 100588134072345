import moment from 'moment'

class TransactionsValuation {
  constructor(amount, currency, date) {
    this.amount = amount
    this.currency = currency
    this.date = date
  }

  static fromJson({ amount, currency, date }) {
    return new TransactionsValuation(amount, currency, moment(date))
  }
}

export default TransactionsValuation
