import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import RulesStore from 'scenes/TrustsCreation/RulesSection/RulesStore'
import YesNoToggle from 'presentation/YesNoToggle'
import Button from 'presentation/Button/Button'
import styles from './restrictionsRulesSection.module.scss'

const RestrictionsRulesSection = ({ rulesStore }) => {
  const { t } = useTranslation('rulesCreation')

  const handleToggleIsRestricted = useCallback(
    (restriction) => {
      restriction.toggleIsRestricted()
      if (!rulesStore.restrictionsIsDirty) {
        rulesStore.setRestrictionsIsDirty(true)
      }
    },
    [rulesStore.restrictionsIsDirty]
  )

  const handleClickCancel = useCallback(() => {
    if (rulesStore.restrictionsIsDirty) {
      rulesStore.loadRestrictions()
      rulesStore.setRestrictionsIsDirty(false)
    }
  }, [])

  const handleClickSave = useCallback(() => {
    if (rulesStore.restrictionsIsDirty) {
      rulesStore.saveRestrictions()
      rulesStore.setRestrictionsIsDirty(false)
    }
  }, [])

  useEffect(() => {
    rulesStore.loadRestrictions()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Assets</div>
        <div className={styles.title}>Restricted</div>
      </div>
      {rulesStore.restrictions.map((restriction) => (
        <div key={restriction.label} className={styles.card}>
          <div>{restriction.label}</div>
          <div>
            <YesNoToggle
              value={restriction.isRestricted.value}
              activeLabel={t('yes')}
              inactiveLabel={t('no')}
              onToggle={() => handleToggleIsRestricted(restriction)}
            />
          </div>
        </div>
      ))}
      <div className={styles.buttonsContainer}>
        <Button
          label={t('save')}
          className={styles.saveButton}
          disabled={!rulesStore.restrictionsIsDirty}
          onClick={handleClickSave}
          primary
        />
        <Button label={t('cancel')} onClick={handleClickCancel} secondary />
      </div>
    </div>
  )
}

RestrictionsRulesSection.propTypes = {
  rulesStore: PropTypes.instanceOf(RulesStore).isRequired,
}

export default observer(RestrictionsRulesSection)
