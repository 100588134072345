import React, { useCallback, useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { USER_PROFILE } from 'routing/routes'
import RadioButtonToggle from 'presentation/RadioButtonToggle'
import StoresContext from 'providers/storeContext'
import RequestErrors from 'presentation/RequestErrors'
import PageTitle from 'presentation/PageTitle'
import PasswordInput from 'presentation/PasswordInput'
import CreationInput from 'presentation/CreationInput'
import CheckIcon from 'mdi-react/CheckIcon'
import { successToast } from 'helpers/Toast'
import MetaTitle from 'util/MetaTitle'
import ServerError from 'presentation/ServerError'
import Button from 'presentation/Button'
import ChangePasswordStore from './ChangePasswordStore'
import styles from './changePassword.module.scss'

const ChangePassword = observer(() => {
  const { authStore } = useContext(StoresContext)
  const [changePasswordStore] = useState(() => new ChangePasswordStore(authStore.authUser))
  const { t } = useTranslation('userProfile')
  const history = useHistory()

  const passwordRules = [t('passwordMinWords')]

  const handleChangeCurrentPassword = useCallback((e) => {
    changePasswordStore.currentPassword.setValue(e.target.value)
    ChangePasswordStore.validatePassword(changePasswordStore.currentPassword, false)
  }, [])

  const handleChangeNewPassword = useCallback((e) => {
    changePasswordStore.newPassword.setValue(e.target.value)
    ChangePasswordStore.validatePassword(changePasswordStore.newPassword)
  }, [])

  const handleChangeConfirmPassword = useCallback((e) => {
    changePasswordStore.confirmPassword.setValue(e.target.value)
    changePasswordStore.validateConfirmPassword()
  }, [])

  const handleAcceptCertifyKeys = useCallback(() => {
    changePasswordStore.toggleCertifyKeys()
  }, [])

  const handleGoBack = useCallback(() => history.push(USER_PROFILE), [])

  const handleSavePassword = useCallback(
    () =>
      changePasswordStore.save(() => {
        successToast(t('passwordChanged'), null, 'changedTrustStatus').show()
      }),
    []
  )

  const handleTryAgain = useCallback(() => changePasswordStore.tryAgain(), [])

  if (changePasswordStore.serverError) {
    return <ServerError handleTryAgain={handleTryAgain} />
  }

  return (
    <div className={styles.changePassword}>
      <MetaTitle pageTitle={t('pageTitles:changePassword')} />
      <PageTitle title={t('changePassword')} />
      {changePasswordStore.showAlertKeys && (
        <div className={styles.keysWarning}>{t('keysAlert')}</div>
      )}
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <CreationInput
            as={PasswordInput}
            label={t('currentPasswordLabel')}
            onChange={handleChangeCurrentPassword}
            placeholder={t('currentPasswordPlaceholder')}
            type="password"
            inputStore={changePasswordStore.currentPassword}
          />
          <CreationInput
            as={PasswordInput}
            label={t('newPasswordLabel')}
            onChange={handleChangeNewPassword}
            placeholder={t('newPasswordPlaceholder')}
            inputStore={changePasswordStore.newPassword}
            type="password"
          />
          <CreationInput
            as={PasswordInput}
            label={t('confirmPasswordLabel')}
            onChange={handleChangeConfirmPassword}
            placeholder={t('confirmPasswordPlaceholder')}
            type="password"
            inputStore={changePasswordStore.confirmPassword}
          />
          {changePasswordStore.showAlertKeys && (
            <RadioButtonToggle
              italic
              onChange={handleAcceptCertifyKeys}
              label={t('certifyKeysLabel')}
              checked={changePasswordStore.certifyKeys.value}
              error={changePasswordStore.certifyKeys.error}
            />
          )}
        </div>
        <div className={styles.passwordRules}>
          <div className={styles.rulesHeader}>{t('passwordMustContain')}</div>
          {passwordRules.map((rule) => (
            <div className={styles.ruleDescription}>
              <CheckIcon size={20} />
              <div>{rule}</div>
            </div>
          ))}
        </div>
      </div>
      <RequestErrors errors={changePasswordStore.requestErrors} />
      <div className={styles.actionsContainer}>
        <Button
          isLoading={changePasswordStore.isLoadingSave}
          big
          className={styles.saveButton}
          onClick={handleSavePassword}
          primary
          label={t('changePassword')}
        />
        <Button
          className={styles.backButton}
          big
          onClick={handleGoBack}
          secondary
          label={t('back')}
        />
      </div>
    </div>
  )
})

export default ChangePassword
