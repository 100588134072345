import { observable, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'

class LetterArchiveStore extends AsyncStore {
  versionsList = []

  constructor() {
    super()

    makeObservable(this, {
      versionsList: observable,
    })
  }
}

export default LetterArchiveStore
