import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Button from 'presentation/Button'
import styles from './corporationUsers.module.scss'

const CorporationUsersActionButtons = ({ handleCancel }) => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.buttonsContainer}>
      <Button secondary big label={t('back')} onClick={handleCancel} />
    </div>
  )
}

CorporationUsersActionButtons.propTypes = {
  handleCancel: PropTypes.func.isRequired,
}

export default observer(CorporationUsersActionButtons)
