import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'
import { useHistory } from 'react-router-dom'
import { trustsStatusCreationRoute } from 'routing/routes'
import PropTypes from 'prop-types'
import styles from './totalDashboadCard.module.scss'

const TotalDashboadCard = ({ count, status, icon }) => {
  const history = useHistory()
  const { t } = useTranslation('dashboard')

  const handleTrustListLink = useCallback(
    () => history.push(`${trustsStatusCreationRoute(status)}`),
    []
  )

  return (
    <div className={styles.card} onClick={handleTrustListLink} role="button" tabIndex="0">
      <div className={styles.containerCount}>
        <div className={styles.totalCount}>{count}</div>
        <div
          className={c(
            styles.icon,
            status === 'draft' && styles.iconDraft,
            status === 'stored' && styles.iconStored,
            status === 'execution' && styles.iconExecution
          )}
        >
          {icon}
        </div>
      </div>
      <div className={styles.statusContainer}>
        <span
          className={c(
            styles.bullet,
            status === 'draft' && styles.statusDraft,
            status === 'stored' && styles.statusStored,
            status === 'executed' && styles.statusExecuted,
            status === 'execution' && styles.statusExecution,
            status === 'pending' && styles.statusPending,
            status === 'storedAlternative' && styles.statusStored
          )}
        />
        <div className={styles.status}>{t(status === 'pending' ? 'common:pending' : status)}</div>
        {status === 'storedAlternative' && <span className={styles.storedPoint} />}
      </div>
    </div>
  )
}

TotalDashboadCard.propTypes = {
  icon: PropTypes.node,
  count: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
}

TotalDashboadCard.defaultProps = {
  icon: null,
}

export default TotalDashboadCard
