import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import MenuDownIcon from 'mdi-react/MenuDownIcon'
import MetaTitle from 'util/MetaTitle'
import PageTitle from 'presentation/PageTitle'
import HistoryList from 'presentation/HistoryList'
import TablePager from 'presentation/TablePager'
import CustomActivitySearch from 'scenes/ActivityList/CustomActivitySearch'
import LoadingRing from 'presentation/LoadingRing'
import NoResultsList from 'presentation/NoResultsList'
import TrustHistoryStore from './TrustHistoryStore'
import styles from './trustHistory.module.scss'

const TrustHistory = () => {
  const { t } = useTranslation('history')
  const { location } = useHistory()
  const [trustId] = location.search.split('=').reverse()
  const [trustHistoryStore] = useState(() => new TrustHistoryStore(trustId))
  const [showDropdownOrder, setShowDropdownOrder] = useState(false)

  useEffect(() => {
    trustHistoryStore.loadHistory()
  }, [])

  const onOrder = useCallback(() => {
    trustHistoryStore.orderList()
    trustHistoryStore.loadHistory()
  }, [])

  const handlePageChange = useCallback((value) => {
    trustHistoryStore.changePage(value)
    trustHistoryStore.loadHistory()
  }, [])

  const handleSetFromDate = useCallback((value) => {
    trustHistoryStore.setFromDate(value)
    trustHistoryStore.loadHistory()
  }, [])

  const handleSetToDate = useCallback((value) => {
    trustHistoryStore.setToDate(value)
    trustHistoryStore.loadHistory()
  }, [])

  const handleClickOrderBox = useCallback(() => {
    setShowDropdownOrder(!showDropdownOrder)
  }, [showDropdownOrder])

  const handleBlurOrder = useCallback(() => {
    setShowDropdownOrder(false)
  }, [])

  const { isLoading, orderDirection } = trustHistoryStore

  const handleClickOrderButton = useCallback(() => {
    if (!isLoading) {
      onOrder()
    }
  }, [isLoading])

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoadingRing />
      </div>
    )
  }

  return (
    <>
      <MetaTitle pageTitle={t('history')} />
      <PageTitle title={t('history')} />
      <div className={styles.orderContainer}>
        <div className={styles.dateFilter}>
          <CustomActivitySearch
            toDateInputStore={trustHistoryStore.toDate}
            setFromDate={handleSetFromDate}
            setToDate={handleSetToDate}
            fromDateInputStore={trustHistoryStore.fromDate}
          />
        </div>
        <div className={styles.sorter}>
          <div className={styles.order} onBlur={handleBlurOrder}>
            <label className={styles.labelSortBy}>{t('common:sortBy')}</label>
            <div
              className={styles.orderBox}
              onClick={handleClickOrderBox}
              role="button"
              tabIndex="0"
            >
              <div>{t('createdAt')}</div>
              <MenuDownIcon />
            </div>
          </div>
          <div
            className={styles.orderButton}
            onClick={handleClickOrderButton}
            role="button"
            tabIndex="0"
          >
            {orderDirection === 'asc' && <SortAscendingIcon size={20} />}
            {(!orderDirection || orderDirection === 'desc') && <SortDescendingIcon size={20} />}
          </div>
        </div>
      </div>
      <div className={styles.historyContainer}>
        {trustHistoryStore.visibleHistory.length === 0 ? (
          <NoResultsList topText={t('common:noResultsFound')} />
        ) : (
          <HistoryList historyElements={trustHistoryStore.visibleHistory} />
        )}
      </div>
      {trustHistoryStore.visibleHistory.length === 0 ? null : (
        <TablePager
          handlePageChange={handlePageChange}
          currentPage={trustHistoryStore.activePage}
          pages={trustHistoryStore.pages}
        />
      )}
    </>
  )
}

export default observer(TrustHistory)
