import Part, { TYPE_HIDDEN } from './Part'
import FormPartStore from './FormPartStore'

class HiddenPart extends Part {
  constructor(id) {
    super(id, TYPE_HIDDEN)
  }

  static fromJson({ id }, onCreateInput) {
    const input = new FormPartStore()

    onCreateInput(id, input)

    return new HiddenPart(id)
  }
}

export default HiddenPart
