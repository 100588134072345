import { observable, action, computed, override, runInAction, reaction, makeObservable } from 'mobx'
import moment from 'moment'
import AsyncStore from 'stores/AsyncStore'
import i18next from 'i18next'
import ContactsService from 'services/ContactsService'
import ContactStatus from 'models/ContactStatus'
import { countPartErrors } from 'util/validation'
import DynamicFormsStore from './DynamicFormsStore'

export const INDIVIDUAL_CONTACT_TYPE = 'individual'
export const COMPANY_CONTACT_TYPE = 'company'

class ContactsCreationStore extends DynamicFormsStore {
  contactsJson = null
  viewMode = false
  firstLoad = true
  contactType = ''
  validationErrors = []
  isDirtyGeneral = false
  loadingValidation = false
  loadingActivation = false
  messageErrorServer = false
  visibleErrorModal = false
  showSaveToContinue = false
  visibleConfirmDelete = false
  visibleValidationModal = false

  constructor(authStore) {
    super(authStore)

    makeObservable(this, {
      // observables
      firstLoad: observable,
      contactType: observable,
      validationErrors: observable,
      isDirtyGeneral: observable,
      loadingValidation: observable,
      loadingActivation: observable,
      messageErrorServer: observable,
      visibleErrorModal: observable,
      showSaveToContinue: observable,
      visibleConfirmDelete: observable,
      visibleValidationModal: observable,
      // actions
      init: action,
      resetValidationErrors: action,
      reloadData: action,
      setFirstLoad: action,
      resetAttributes: override,
      fillEditData: action,
      save: action,
      resetDirty: action,
      validateFirstSection: action,
      checkFirstStepComplete: action,
      showValidationModal: action,
      hideValidationModal: action,
      hideErrorModal: action,
      showErrorModal: action,
      // computeds
      isIndividual: computed,
      isCompany: computed,
      isDirty: computed,
      contactName: computed,
    })
  }

  prepare() {
    super.setEntityStatus(new ContactStatus())

    this.contactsService = new ContactsService()
  }

  async init(id, viewMode, type) {
    this.contactType = type

    if (this.isIndividual) {
      super.setEntityNameKey('firstName', 'lastName')
    } else {
      super.setEntityNameKey('name')
    }

    this.preRequest(() => this.init(id, viewMode, type))
    // resets atributos
    this.resetAttributes()

    try {
      if (id !== null) {
        this.contactsJson = await this.contactsService.getContact(id)
      }

      // loads clients metadata
      const metadata = await this.contactsService.loadMetadata(type, this.contactsJson?.id)

      runInAction(() => {
        // processes the client metadata into classes
        this.processMetadata(metadata)

        // if is edition
        if (id !== null) {
          // sets edition
          this.setIsEdition(id)
          this.reloadData()
          this.toggleViewMode(viewMode)
        } else {
          this.onSuccessRequest()
          this.detectContactChanges()
        }

        this.firstLoad = false
      })
    } catch (e) {
      this.onErrorRequest(e)
      this.firstLoad = false
    }
  }

  resetValidationErrors = () => {
    this.validationErrors = []
  }

  reloadData(validate = true) {
    this.preRequest(() => this.reloadData(validate))
    this.firstLoad = false
    this.resetValidationErrors()

    this.fillEditData().then(() => {
      // starts detecting clients changes
      this.detectContactChanges()

      if (validate) {
        this.resetDirty()
        this.validate()
      }

      this.onSuccessRequest()
    })
  }

  setFirstLoad() {
    this.firstLoad = true
  }

  get isIndividual() {
    return this.contactType === INDIVIDUAL_CONTACT_TYPE
  }

  get isCompany() {
    return this.contactType === COMPANY_CONTACT_TYPE
  }

  resetAttributes() {
    super.resetAttributes()
    super.setEntityStatus(new ContactStatus())

    this.isDirtyGeneral = false
    this.showSaveToContinue = false
    this.loadingValidation = false
    this.visibleRollBackModal = false
    this.validationErrors = []
  }

  detectContactChanges() {
    /**
     * sets reaction to detect changes on any part of the corporation except for lows and dots
     */
    reaction(
      () => JSON.stringify(this.getDataJson()),
      (data) => {
        /* al guardar, el datajson devuelve un objeto vacio, hago una validacion para ignorar ese caso */
        if (Object.keys(data).length > 2 && !this.isDirtyGeneral) {
          this.isDirtyGeneral = true
        }
      }
    )
  }

  async fillEditData() {
    this.preRequest(this.fillEditData)

    try {
      if (this.contactsJson) {
        this.contactsJson = await this.contactsService.getContact(this.idEdition)
      }

      runInAction(() => {
        super.setLastSaveDate(moment(this.contactsJson.updated.at))

        this.fillDataInputs(this.contactsJson.data)
        super.setStep1Finished()
      })

      return this.contactsJson
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }
    return null
  }

  getJson() {
    return {
      id: this.idEdition,
      data: this.isDirtyGeneral ? this.getDataJson() : null,
    }
  }

  getDataJson() {
    const json = {}

    json.type = this.contactType

    this.inputs.forEach((input, key) => {
      json[key] = input.json
    })

    return json
  }

  async save(successCallback) {
    if (this.isEdition) {
      await this.validate()
    }

    const validFirstSection = await this.validateFirstSection()

    this.showSaveToContinue = false

    if (!validFirstSection) {
      return
    }

    this.loadingSave = true

    this.requestProcess(() => this.save(successCallback))
    try {
      let id = this.idEdition

      if (this.isDirty) {
        const json = this.getJson()

        const response = await this.contactsService.saveContact(json)
        id = response.id

        this.contactsJson = await this.contactsService.getContact(id)
      }

      runInAction(() => {
        this.loadingSave = false

        super.setStep1Finished()
        super.setLastSaveDate(moment())
        this.resetDirty()

        successCallback(id)
      })
    } catch (e) {
      runInAction(() => {
        const [errorMessage] = e.response.data.error.errors
        this.messageErrorServer = errorMessage
        this.showErrorModal()

        if (AsyncStore.isServerError(e)) {
          this.setServerError()
        }

        this.loadingSave = false
        this.loadingActivation = false
      })
    }
  }

  countPartErrors(part) {
    return countPartErrors(part, this.inputs)
  }

  resetDirty() {
    this.isDirtyGeneral = false
  }

  async validate() {
    const errors = await [...this.inputs.values()].reduce(async (validationErrors, input) => {
      const previous = await validationErrors

      if (input.validate) {
        try {
          await input.validate()
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      return previous
    }, [])

    this.resetValidationErrors()

    this.entitySections.forEach((contactSection) => {
      const error = this.countPartErrors(contactSection, this.inputs)
      if (error > 0) {
        const section = contactSection.menuLabel

        this.validationErrors.push(i18next.t('validation:sectionError', { section }))
      }
    })
    return !errors.length
  }

  async validateFirstSection() {
    /*
    valida especificamente la primer seccion, pero de forma no dinamica, tener en cuenta
    */

    if (this.entitySections.length === 0) {
      return false
    }

    let isValid = true

    if (this.contactType === 'individual') {
      if (!this.inputs.get('firstName').store.value) {
        this.inputs.get('firstName').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }

      if (!this.inputs.get('lastName').store.value) {
        this.inputs.get('lastName').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }

      if (!this.inputs.get('passportNumber').store.value) {
        this.inputs
          .get('passportNumber')
          .store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }

      if (!this.inputs.get('issuerCountry').store.value) {
        this.inputs.get('issuerCountry').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
    }

    if (this.contactType === 'company') {
      if (!this.inputs.get('name').store.value) {
        this.inputs.get('name').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
      if (!this.inputs.get('taxNumber').store.value) {
        this.inputs.get('taxNumber').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
      if (!this.inputs.get('jurisdiction').store.value) {
        this.inputs.get('jurisdiction').store.setError(true, i18next.t('validation:requiredField'))
        isValid = false
      }
    }

    return isValid
  }

  get isDirty() {
    return this.isDirtyGeneral
  }

  get contactName() {
    if (this.inputs.get('contactName')) {
      return this.inputs.get('contactName').store.value
    }

    return null
  }

  checkFirstStepComplete() {
    if (this.subMenuDisabled && this.validateFirstSection()) {
      this.showSaveToContinue = true
    }
  }

  showValidationModal() {
    this.visibleValidationModal = true
  }

  hideValidationModal() {
    this.visibleValidationModal = false
  }

  hideErrorModal() {
    this.visibleErrorModal = false
  }

  showErrorModal() {
    this.visibleErrorModal = true
  }
}

export default ContactsCreationStore
