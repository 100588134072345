import { observable, action, runInAction, makeObservable } from 'mobx'
import DashboardService from 'services/DashboardService'
import AsyncStore from 'stores/AsyncStore'
import Dashboard from '../models/Dashboard/Dashboard'

class DashboardStore extends AsyncStore {
  constructor() {
    super()

    this.dashboard = new Dashboard()

    makeObservable(this, {
      // observables
      dashboard: observable,
      // actions
      init: action,
      loadDashboardData: action,
      showFreezeModal: action,
      hideFreezeModal: action,
      showModalBackToDraft: action,
    })

    this.dashboardServices = new DashboardService()
    this.init()
  }

  init() {
    this.loadDashboardData()
  }

  async loadDashboardData(id, viewMode, type) {
    this.isLoading = true

    this.preRequest(() => this.init(id, viewMode, type))

    try {
      const response = await this.dashboardServices.getDashboardData()

      runInAction(() => {
        this.dashboard.fillJson(response)
        this.isLoading = false
      })
    } catch (e) {
      this.isLoading = false
      this.onErrorRequest(e)
    }
  }

  showFreezeModal() {
    this.visibleFreezeModal = true
  }

  hideFreezeModal() {
    this.visibleFreezeModal = false
  }

  showModalBackToDraft() {
    this.visibleBackToDraftModal = true
  }
}

export default DashboardStore
