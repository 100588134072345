import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './dynamicFormSubtitle.module.scss'

const DynamicFormSubtitle = ({ label, separateTop }) => (
  <div className={c(styles.subtitle, separateTop && styles.separateTop)}>{label}</div>
)

DynamicFormSubtitle.propTypes = {
  label: PropTypes.string.isRequired,
  separateTop: PropTypes.bool,
}

DynamicFormSubtitle.defaultProps = {
  separateTop: false,
}

export default DynamicFormSubtitle
