import File from 'models/File'
import Letter from './Letter'

class Statute extends Letter {
  constructor() {
    super('statute')
  }

  static createFromJson({
    id = null,
    title = null,
    text = null,
    file = null,
    signedFile = null,
    status = null,
    updated = null,
  }) {
    const statute = new Statute()

    statute.changeUpdated(updated)
    statute.changeId(id)
    statute.changeStatus(status)
    statute.changeTitle(title)
    statute.changeText(text)
    statute.file = new File()
    statute.signedFile = new File()

    if (file !== null) {
      statute.file = File.fromJson(file)
    }

    if (signedFile !== null) {
      statute.signedFile = File.fromJson(signedFile)
    }

    return statute
  }

  fillFromOldVersion(statuteVersion) {
    this.changeTitle(statuteVersion.title)
    this.changeText(statuteVersion.content)

    if (statuteVersion.file !== null) {
      this.file = statuteVersion.file
    }

    if (statuteVersion.signedFile !== null) {
      this.signedFile = statuteVersion.signedFile
    }
  }

  static createFromStatute(sourceStatute) {
    const statute = new Statute()

    statute.changeTitle(sourceStatute.title.value)
    statute.changeText(sourceStatute.text.value)

    if (sourceStatute.file !== null) {
      statute.file = sourceStatute.file
    }

    if (sourceStatute.signedFile !== null) {
      statute.signedFile = sourceStatute.signedFile
    }

    return statute
  }
}

export default Statute
