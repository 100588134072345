import { action, observable, runInAction, computed, makeObservable } from 'mobx'
import ContactsService from 'services/ContactsService'
import AsyncStore from 'stores/AsyncStore'
import throttle from 'util/throttle'
import InputStore from 'stores/InputStore'

class ContactSearchStore extends AsyncStore {
  contacts = []
  isLoading = false
  searchTerm = ''
  selectedUser = new InputStore()
  isOnlyClientSearch = undefined

  constructor() {
    super()

    makeObservable(this, {
      // observables
      contacts: observable,
      searchTerm: observable,
      selectedUser: observable,
      // actions
      loadContacts: action,
      setSearchTerm: action,
      // computeds
      selectOptions: computed,
    })

    this.contactsService = new ContactsService()
  }

  async loadContacts() {
    this.isLoading = true

    try {
      const response = await this.contactsService.listContactSearch(
        this.searchTerm,
        this.isOnlyClientSearch
      )

      runInAction(() => {
        this.contacts = response.contacts
        this.isLoading = false
      })
    } catch (e) {
      this.isLoading = false
    }
  }

  get selectOptions() {
    return this.contacts.map((option) => option.optionsSelect)
  }

  setSearchTerm(value) {
    this.searchTerm = value
    throttle(() => this.loadContacts(), 300)
  }

  setIsOnlyClientSearch(isOnlyClientSearch) {
    this.isOnlyClientSearch = isOnlyClientSearch
  }
}

export default ContactSearchStore
