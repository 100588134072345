import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx'
import { OtherDocuments } from 'models/Letters'
import LettersBaseStore from './LettersBaseStore'

class OtherDocumentsStore extends LettersBaseStore {
  constructor(trustCreationStore) {
    super()

    this.isLoading = false
    this.initialized = false
    this.otherDocuments = []
    this.isDirty = false

    this.trustCreationStore = trustCreationStore

    makeObservable(this, {
      // observables
      initialized: observable,
      otherDocuments: observable,
      isDirty: observable,
      // actions
      createLetter: action,
      initialize: action,
      initList: action,
      resetDirty: action,
      fillFromJson: action,
      restoreMainFromOldVersion: action,
      setMainFirstLevelError: action,
      clearMainFirstLevelError: action,
      validate: action,
      // computeds
      draftMainLetter: computed,
      activeMainLetter: computed,
      otherDocumentsForRules: computed,
    })
  }

  getJson() {
    const otherDocuments = []

    this.otherDocuments.forEach((document) => {
      if (!document.isActive) {
        otherDocuments.push(document.getJson())
      }
    })

    return { documents: otherDocuments }
  }

  createLetter(from = null) {
    let newDocument = new OtherDocuments()

    if (from !== null) {
      newDocument = OtherDocuments.createFromOtherDocuments(from)
      newDocument.clearFile()
    }

    this.otherDocuments.push(newDocument)
  }

  async initialize(alternativeStatusLoad) {
    this.preRequest()

    try {
      const response = await this.trustCreationStore.trustService.getOtherDocumentsSection(
        alternativeStatusLoad,
        this.trustCreationStore.idEdition
      )

      runInAction(() => {
        this.initList(response)
        this.initialized = true
        this.onSuccessRequest()
      })

      return response
    } catch (e) {
      runInAction(() => {
        this.onErrorRequest(e)
      })
    }

    return null
  }

  initList(json) {
    if (json) {
      this.fillFromJson(json)
    }

    setTimeout(() => {
      reaction(
        () => {
          return JSON.stringify(this.getJson())
        },
        () => {
          if (!this.isDirty) {
            this.isDirty = true
          }
        },
        { equals: (prev, next) => prev === next && this.isDirty }
      )
    })
  }

  resetDirty() {
    this.isDirty = false
  }

  fillFromJson(documents = []) {
    this.otherDocuments = documents.map((document) => OtherDocuments.createFromJson(document))
  }

  restoreMainFromOldVersion(oldVersion) {
    this.draftMainLetter.fillFromOldVersion(oldVersion)
  }

  get draftMainLetter() {
    const drafts = this.otherDocuments.filter((document) => document.isDraft)

    if (drafts.length > 0) {
      return drafts[0]
    }

    return null
  }

  get activeMainLetter() {
    const actives = this.otherDocuments.filter((document) => document.isActive)

    if (actives.length > 0) {
      return actives[0]
    }

    return null
  }

  get otherDocumentsForRules() {
    const actives = this.otherDocuments.filter(
      (document) => document.isExecution || document.isActive || document.isExecuted
    )

    if (actives.length === 1) {
      return actives[0]
    }
    if (actives.length > 1) {
      return actives.filter((document) => document.isExecution || document.isExecuted)[0]
    }

    return null
  }

  setMainFirstLevelError() {
    this.mainFirstLevelError = true
  }

  clearMainFirstLevelError() {
    this.mainFirstLevelError = false
  }

  validate() {
    this.setErrorCountMainLetter(this.validateMainLetterDraft())
  }
}

export default OtherDocumentsStore
