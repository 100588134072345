import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import c from 'classnames'
import moment from 'moment'
import DynamicFormsStore from 'stores/DynamicFormsStore'
import FormSectionMenuButton from 'presentation/FormSectionMenuButton'
import StatusName from 'presentation/StatusName'
import styles from './dynamicFormSubMenu.module.scss'

const parseLastSaveDate = (date, t) => {
  if (moment().diff(date, 'months') >= 11) {
    return date.format(t('common:dateFormat'))
  }

  return date.format('MMM DD, H:mm')
}

const DynamicFormSubMenu = ({
  dynamicFormsStore,
  entityTypeName,
  view,
  collapsed,
  sectionUrlFunction,
  entityId,
  toggleNavBar,
  changeStatusButton,
  extraMenuButtons,
  noStatus,
  statusComponent: Component,
}) => {
  const { t } = useTranslation('navBar')

  if (dynamicFormsStore.isLoading || dynamicFormsStore.errorLoadEdit) {
    return null
  }

  return (
    <>
      {!collapsed && (
        <div className={styles.entityTitle}>
          {dynamicFormsStore.entityName === ''
            ? t('newEntityType', { entityTypeName })
            : dynamicFormsStore.entityName}
        </div>
      )}
      <div className={styles.entityLastSave}>
        {dynamicFormsStore.lastSaveDate !== null && !collapsed && (
          <>
            {t('lastSave')}
            <span className={styles.lastSaveDate}>
              {parseLastSaveDate(dynamicFormsStore.lastSaveDate, t)}
            </span>
          </>
        )}
      </div>
      {!noStatus && (
        <div className={c(styles.status, collapsed && styles.statusCollapsed)}>
          <Component status={dynamicFormsStore.entityStatus} onlyBullet={collapsed} />
        </div>
      )}
      <div
        className={c(
          styles.subMenuButtonsContainer,
          dynamicFormsStore.subMenuDisabled && styles.subMenuDisabled
        )}
        onClick={() => dynamicFormsStore.checkFirstStepComplete()}
        role="button"
        tabIndex="0"
      >
        {dynamicFormsStore.entitySections.map((formSection) => {
          if (dynamicFormsStore.canViewSection(formSection)) {
            return (
              <FormSectionMenuButton
                countPartErrors={dynamicFormsStore.countPartErrors(formSection)}
                url={sectionUrlFunction(formSection.route, entityId, view)}
                disabled={dynamicFormsStore.subMenuDisabled}
                key={`formSection_${formSection.id}`}
                inputs={dynamicFormsStore.inputs}
                formSection={formSection}
                iconProps={{ size: 17 }}
                collapsed={collapsed}
                onClick={
                  dynamicFormsStore.subMenuDisabled
                    ? dynamicFormsStore.checkFirstStepComplete
                    : toggleNavBar
                }
              />
            )
          }

          return null
        })}
      </div>
      {extraMenuButtons}
      {changeStatusButton}
    </>
  )
}

DynamicFormSubMenu.propTypes = {
  dynamicFormsStore: PropTypes.instanceOf(DynamicFormsStore).isRequired,
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sectionUrlFunction: PropTypes.func.isRequired,
  entityTypeName: PropTypes.string.isRequired,
  toggleNavBar: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  changeStatusButton: PropTypes.node,
  extraMenuButtons: PropTypes.node,
  statusComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  view: PropTypes.string,
  noStatus: PropTypes.bool,
}

DynamicFormSubMenu.defaultProps = {
  changeStatusButton: null,
  extraMenuButtons: null,
  view: null,
  entityId: null,
  statusComponent: StatusName,
  noStatus: false,
}

export default observer(DynamicFormSubMenu)
