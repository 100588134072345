import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import InputStore from 'stores/InputStore'
import File from 'models/File'
import VoteModels from 'models/Vote'

import TransactionStatus from '../TransactionStatus'

class Vote {
  lastUpdate = null
  updatedByFirstName = null
  updatedByLastName = null

  constructor() {
    this.status = new TransactionStatus()
    this.title = new InputStore()
    this.approvalRule = new InputStore()
    this.resolution = new InputStore()
    this.votersCanAbstain = new InputStore()
    this.description = new InputStore()
    this.voters = new InputStore()
    this.fiduciaryStructure = new InputStore()
    this.document = new File()
    this.transactions = new InputStore()
    this.date = new InputStore()
    this.created = new InputStore()
    this.updated = new InputStore()
    this.votings = new InputStore()

    makeAutoObservable(this)

    this.transactions.setValue([])
  }

  setTransactions(transactions) {
    this.transactions.setValue(transactions)
  }

  getJson() {
    return {
      id: this.id,
      title: this.title.value,
      approvalRule: this.approvalRule.value,
      resolution: this.resolution,
      votersCanAbstain: this.votersCanAbstain.value,
      additionalData: { description: this.description.value },
      transactionCount: this.transactionCount,
      transactions: this.transactions.value,
      date: this.date.value,
      voters: this.voters.value,
      votings: this.votings.value,
      created: this.created.value,
      updated: this.updated.value,
      fiduciaryStructure: this.fiduciaryStructure.value,
      status: this.status.status,
    }
  }

  fillFromVote(vote) {
    this.id = vote.id
    this.title.setValue(vote.title.value)
    this.approvalRule.setValue(vote.approvalRule.value)
    this.resolution.setValue(vote.resolution.value)
    this.description.setValue(vote.description.value)
    this.date.setValue(vote.date.value)
    this.votersCanAbstain.setValue(vote.votersCanAbstain.value)
    this.voters.setValue(vote.voters.value)
    this.votings.setValue(vote.votings.value)
    this.transactionCount = vote.transactionCount
    this.updated.setValue(vote.updated.value)
    this.created.setValue(vote.created.value)

    this.setTransactions(vote.transactions.value)

    this.status = vote.status
  }

  setUpdated({ at = null, firstName = null, lastName = null }) {
    this.lastUpdate = at !== null ? moment(at) : null
    this.updatedByFirstName = firstName
    this.updatedByLastName = lastName
  }

  setCreated({ at = null, firstName = null, lastName = null }) {
    this.created = at !== null ? moment(at) : null
    this.updatedByFirstName = firstName
    this.updatedByLastName = lastName
  }

  static fromJson(json) {
    const vote = new VoteModels()

    vote.fillFromJson(json)

    return vote
  }

  fillFromJson({
    id,
    title,
    approvalRule,
    additionalData,
    transactions,
    votersCanAbstain,
    resolution,
    date,
    voters,
    votings,
    transactionCount,
    created,
    updated,
    status = null,
  }) {
    this.id = id
    this.title.setValue(title)
    this.approvalRule.setValue(approvalRule)
    this.resolution.setValue(resolution || '')
    this.votersCanAbstain.setValue(votersCanAbstain)
    this.voters.setValue(voters)
    this.date.setValue(moment(date))
    this.votings.setValue(votings)
    this.transactionCount = transactionCount
    this.created.setValue(moment(created))
    this.setUpdated(updated)
    this.status.setStatus(status)

    this.fillDescription(additionalData)
    this.setTransactions(transactions)
  }

  fillDescription(additionalData) {
    if (additionalData !== null) {
      if (additionalData.description) {
        if (typeof additionalData.description === 'string') {
          this.description.setValue(additionalData.description)
        } else if (typeof additionalData.description === 'object') {
          if (additionalData.description.value) {
            this.description.setValue(additionalData.description.value)
          }
        }
      }
    } else {
      this.description.setValue('')
    }
  }
}

export default Vote
