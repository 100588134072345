import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import ArrowKeysReact from 'arrow-keys-react'
import styles from './arrowNavGrid.module.scss'

const ArrowNavGrid = ({ columns, colSize, children, autoFocus, gridRowGap, gridColumnGap }) => {
  const elements = []
  const arrowContainerRef = useRef(null)

  const handleFocus = (index) => {
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].focus = -1
    }

    elements[index].focus = 1
  }

  useEffect(() => {
    if (!autoFocus) {
      arrowContainerRef.current.focus()
    }
  }, [])

  // Mueve el elemento al indice dado
  const moveTo = (toIndex) => {
    if (elements[toIndex]) {
      elements[toIndex].ref.current.setFocus()

      handleFocus(toIndex)
      return Promise.resolve(true)
    }
    return Promise.resolve(false)
  }

  // Deteccion de los cursores
  ArrowKeysReact.config({
    left: () => {
      moveTo(elements.findIndex((e) => e.focus === 1) - 1)
    },
    right: () => {
      moveTo(elements.findIndex((e) => e.focus === 1) + 1)
    },
    up: () => {
      const index = elements.findIndex((e) => e.focus === 1)

      moveTo(index - columns).then((done) => {
        const fixedItems = Math.ceil(elements.length / columns) * 3
        const fixedIndex = fixedItems + index - columns - 1

        if (!done && index > 0) {
          moveTo(fixedIndex > elements.length - 1 ? fixedIndex - columns : fixedIndex)
        }
      })
    },

    down: () => {
      const index = elements.findIndex((e) => e.focus === 1)

      moveTo(index + columns).then((done) => {
        const fixedItems = Math.ceil(elements.length / columns) * 3
        const fixedIndex = index + columns >= fixedItems ? index : index + columns

        if (!done && fixedIndex < fixedItems - 1) {
          moveTo(-fixedItems + fixedIndex + columns + 1)
        }
      })
    },
  })

  // Asigna las columnas
  const gridStyle = {
    gridTemplateColumns: `repeat(${columns}, ${colSize})`,
    gridRowGap,
    gridColumnGap,
  }

  return (
    <div
      ref={arrowContainerRef}
      className={styles.ArrowNavGrid}
      style={gridStyle}
      {...ArrowKeysReact.events}
    >
      {children.map((child, index) => {
        elements[index] = { ref: React.createRef(), tabIndex: index + 100, focus: -1 }

        // Focus inicial
        if (autoFocus && index === 0) {
          elements[index].focus = 1
          setTimeout(() => {
            if (elements[index].ref.current) {
              elements[index].ref.current.setFocus()
            }
          }, 100)
        }

        let key = index
        if (child.props.id) {
          key += child.props.id
        }

        return React.cloneElement(child, {
          tabIndex: elements[index].tabIndex,
          ref: elements[index].ref,
          key,
          onFocus: () => {
            handleFocus(index)
          },
          onKeyPress: (event) => {
            if (event.key === 'Enter' && child.props.onClick()) {
              child.props.onClick()
            }
          },
        })
      })}
    </div>
  )
}

ArrowNavGrid.propTypes = {
  columns: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  autoFocus: PropTypes.bool,
  gridColumnGap: PropTypes.string,
  gridRowGap: PropTypes.string,
  colSize: PropTypes.string,
}

ArrowNavGrid.defaultProps = {
  columns: 3,
  autoFocus: false,
  gridColumnGap: '10px',
  gridRowGap: '10px',
  colSize: '1fr',
}

export default ArrowNavGrid
