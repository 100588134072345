import React, { useContext, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import LettersSection from 'presentation/LettersSection'
import LetterOfWishesSectionStore from 'stores/LetterOfWishesSectionStore'
import LetterOfDistributionSectionStore from 'stores/LetterOfDistributionSectionStore'
import LetterOfWishesArchiveStore from 'stores/LetterOfWishesArchiveStore'
import saveTrust from 'util/saveTrust'
import StoresContext from 'providers/storeContext'

const DynamicFormPartLows = () => {
  const { t } = useTranslation('trustsCreation')
  const { trustsCreationStore } = useContext(StoresContext)
  const { lettersStore } = trustsCreationStore
  const [letterOfWishesSectionStore] = useState(() => new LetterOfWishesSectionStore(lettersStore))

  const [letterOfDistributionSectionStore] = useState(
    new LetterOfDistributionSectionStore(lettersStore)
  )
  const [letterOfWishesArchiveStore] = useState(
    new LetterOfWishesArchiveStore(trustsCreationStore.idEdition)
  )
  const handleResetMainSection = useCallback(() => letterOfWishesSectionStore.reset(), [])
  const validationHandler = useCallback(() => trustsCreationStore.validateLows(), [])

  const handleResetSecondarySection = useCallback(
    () => letterOfDistributionSectionStore.reset(),
    []
  )
  const handleSaveEntity = useCallback(
    () => saveTrust(lettersStore.trustCreationStore, t('trustCreatedMsg')),
    []
  )

  if (trustsCreationStore.idEdition === undefined && trustsCreationStore.isLoading) {
    return null
  }

  return (
    <LettersSection
      title={t('letterOfWishes')}
      mainTabName={t('letterOfWishes')}
      secondaryTabName={t('lettersOfDistribution')}
      secondaryTabNameSingular={t('letterOfDistribution')}
      showExistsDraft={
        !letterOfWishesSectionStore.draftEditionMode &&
        lettersStore.draftMainLetter !== null &&
        trustsCreationStore.hasAlternativeStatus
      }
      existsDraftText={t('draftInProgress')}
      resetMainSection={handleResetMainSection}
      resetSecondarySection={handleResetSecondarySection}
      isLoading={lettersStore.isLoading || trustsCreationStore.isLoading}
      mainStore={lettersStore}
      mainSectionStore={letterOfWishesSectionStore}
      secondarySectionStore={letterOfDistributionSectionStore}
      letterArchiveStore={letterOfWishesArchiveStore}
      handleSaveEntity={handleSaveEntity}
      isViewMode={trustsCreationStore.isViewMode}
      mainEntityStatus={trustsCreationStore.entityStatus}
      mainEntityHasAlternativeStatus={trustsCreationStore.hasAlternativeStatus}
      secondLetterShowRulesAlert={false}
      validationHandler={validationHandler}
    />
  )
}

export default observer(DynamicFormPartLows)
