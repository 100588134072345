import { action, makeObservable, observable } from 'mobx'
import InputStore from 'stores/InputStore'
import TransactionsValuation from '../TransactionsValuation'

class TransactionsAsset {
  constructor(assetId, name, type, icon, valuation, canTransaction) {
    this.id = assetId
    this.name = name
    this.type = type
    this.icon = icon
    this.valuation = valuation
    this.isSelected = false
    this.value = new InputStore()
    this.canTransaction = canTransaction

    makeObservable(this, {
      // observables
      isSelected: observable,
      value: observable,
      // actions
      setSelected: action,
      setValue: action,
    })
  }

  static fromJson({ assetId, account, icon, valuation, label, allowedForTx }) {
    let filteredValuation = []
    let updatedAccount = account
    if (valuation) {
      filteredValuation = valuation.map((value) => {
        return TransactionsValuation.fromJson(value)
      })
    }
    if (account === 'otherFund') {
      updatedAccount = 'Other source of funds'
    }
    return new TransactionsAsset(
      assetId || 'no_id',
      updatedAccount,
      label,
      icon,
      filteredValuation,
      allowedForTx
    )
  }

  setSelected() {
    this.isSelected = !this.isSelected
  }

  unselect() {
    this.isSelected = false
  }

  setValue(value) {
    this.value.setValue(value)
  }

  getJson() {
    if (this.id === 'no_id') {
      return { account: 'otherFund', amount: this.value.value }
    }
    return { assetId: this.id, amount: this.value.value }
  }
}

export default TransactionsAsset
