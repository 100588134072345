import { useContext } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import AlertIcon from 'mdi-react/AlertIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import CheckAllIcon from 'mdi-react/CheckAllIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import FileUndoIcon from 'mdi-react/FileUndoIcon'
import LockIcon from 'mdi-react/LockIcon'
import Button from 'presentation/Button'
import ToolTip from 'presentation/ToolTip'
import { CHANGE_TRUST_STATUS, EDIT_TRUST } from 'stores/AuthStore'
import StoresContext from 'providers/storeContext'
import styles from './trustChangeStatusButton.module.scss'

const TrustChangeStatusButton = ({ toggleNavBar, collapsed }) => {
  const { t } = useTranslation('trustsCreation')
  const { trustsCreationStore, authStore } = useContext(StoresContext)

  const buttonLabel = () => {
    const { entityStatus } = trustsCreationStore

    if (entityStatus.isDraft) {
      return t('execution')
    }

    if (entityStatus.isExecution) {
      return t('executed')
    }

    if (entityStatus.isExecuted) {
      return t('sendToApprove')
    }

    if (entityStatus.isStored) {
      return t('editTrust')
    }

    return ''
  }

  const iconNext = () => {
    const { entityStatus } = trustsCreationStore

    if (entityStatus.isDraft) {
      return <CheckIcon size={18} />
    }

    if (entityStatus.isExecution) {
      return <CheckAllIcon size={18} />
    }

    if (entityStatus.isExecuted) {
      return <LockIcon size={18} />
    }

    return <PencilIcon size={18} />
  }

  if (trustsCreationStore.isEdition) {
    return (
      <>
        <div className={c(styles.changeStatusButton, collapsed && styles.collapsed)}>
          {authStore.can(CHANGE_TRUST_STATUS) &&
            trustsCreationStore.showChangeStatusError &&
            !collapsed && (
              <ToolTip placement="right" tooltipContent={<div>{t('viewTrustChangeErrors')}</div>}>
                <AlertIcon
                  size={20}
                  className={styles.alertExecution}
                  onClick={() => {
                    toggleNavBar()
                    trustsCreationStore.tryToChangeStatus()
                  }}
                />
              </ToolTip>
            )}
          {!trustsCreationStore.entityStatus.isStored &&
            !trustsCreationStore.entityStatus.isPendingToApproval && (
              <Button
                error={collapsed && trustsCreationStore.validationErrors.length}
                fullWidth={!trustsCreationStore.showChangeStatusError}
                primary={trustsCreationStore.entityStatus.isExecuted}
                isLoading={trustsCreationStore.loadingValidation}
                icon={collapsed && iconNext()}
                permission={CHANGE_TRUST_STATUS}
                label={buttonLabel()}
                iconPositionLeft
                onClick={() => {
                  toggleNavBar()
                  trustsCreationStore.tryToChangeStatus()
                }}
                className={c(
                  collapsed && styles.buttonCollapsable,
                  trustsCreationStore.entityStatus.isExecuted && styles.storeButton
                )}
                small
              />
            )}
          {trustsCreationStore.entityStatus.isStored &&
            !trustsCreationStore.trustAlternativeStatus && (
              <Button
                className={collapsed ? styles.buttonCollapsable : null}
                fullWidth={!trustsCreationStore.showChangeStatusError}
                isLoading={trustsCreationStore.loadingValidation}
                icon={collapsed && iconNext()}
                label={buttonLabel()}
                permission={EDIT_TRUST}
                iconPositionLeft
                onClick={() => {
                  toggleNavBar()
                  trustsCreationStore.tryToGoBackToDraft()
                }}
                small
              />
            )}
        </div>
        {trustsCreationStore.entityStatus.canRollback && (
          <div
            className={c(
              styles.changeStatusButton,
              collapsed && styles.collapsed,
              styles.secondary
            )}
          >
            <Button
              label={`${t('rollBack')} ${t(trustsCreationStore.entityStatus.status)}`}
              className={collapsed ? styles.buttonCollapsable : null}
              isLoading={trustsCreationStore.loadingRollBack}
              permission={CHANGE_TRUST_STATUS}
              onClick={() => {
                toggleNavBar()
                trustsCreationStore.tryToRollBack()
              }}
              icon={collapsed && <FileUndoIcon size={18} />}
              iconPositionLeft
              secondary
              fullWidth
              small
            />
          </div>
        )}
      </>
    )
  }

  return null
}

TrustChangeStatusButton.propTypes = {
  toggleNavBar: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
}

TrustChangeStatusButton.defaultProps = {
  collapsed: false,
}

export default observer(TrustChangeStatusButton)
