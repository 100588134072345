import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import { useTranslation } from 'react-i18next'
import PermissionsList from './PermissionsList'
import Button from '../Button/Button'
import styles from './permissionsSelector.module.scss'

const PermissionsSelector = ({
  allPermissions,
  selectedPermissions,
  handleAddPermissions,
  handleRemovePermissions,
  errorMsg,
  literal,
}) => {
  const { t } = useTranslation('rolesCreation')
  const inputAllPermissions = useRef(null)
  const inputSelectedPermissions = useRef(null)
  const addPermissions = useCallback(() => {
    const selectedOptions = [...inputAllPermissions.current.options].filter(
      (option) => option.selected
    )

    handleAddPermissions(selectedOptions.map((option) => option.value))
  })
  const removePermissions = useCallback(() => {
    const selectedOptions = [...inputSelectedPermissions.current.options].filter(
      (option) => option.selected
    )

    handleRemovePermissions(selectedOptions.map((option) => option.value))
  })

  if (literal) {
    return (
      <>
        <div className={styles.titlePermissions}>{t('assignedPermissions')}</div>
        <ul className={styles.selectedPermissions}>
          {selectedPermissions.length > 0 &&
            selectedPermissions.map((element) => (
              <li key={`permission_${element}`} className={styles.permissions}>
                {t(`permission:${element.replace(/\./g, '_')}`)}
              </li>
            ))}
          {selectedPermissions.length === 0 && (
            <div className={styles.noPermissionsSelected}>{t('noPermissionsSelected')}</div>
          )}
        </ul>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <PermissionsList
        refEle={inputAllPermissions}
        options={allPermissions}
        placeholder={t('searchPermissions')}
        label={t('labelAllPermissions')}
      />
      <div className={styles.btnContainer}>
        <div className={styles.btnAddArrow}>
          <Button onClick={addPermissions} icon={<ChevronRightIcon />} circle />
        </div>
        <div className={styles.btnRemoveArrow}>
          <Button onClick={removePermissions} icon={<ChevronLeftIcon />} circle />
        </div>
      </div>
      <PermissionsList
        refEle={inputSelectedPermissions}
        options={selectedPermissions}
        placeholder={t('searchPermissions')}
        label={t('labelSelectedPermissions')}
        errorMsg={errorMsg}
      />
    </div>
  )
}

PermissionsSelector.propTypes = {
  allPermissions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  selectedPermissions: PropTypes.oneOfType([PropTypes.array]),
  handleAddPermissions: PropTypes.func.isRequired,
  handleRemovePermissions: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  literal: PropTypes.bool,
}

PermissionsSelector.defaultProps = {
  errorMsg: '',
  literal: true,
  selectedPermissions: [],
}

export default PermissionsSelector
