import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import AsyncStore from 'stores/AsyncStore'
import UsersService from 'services/UsersService'
import ImageInputStore from 'stores/ImageInputStore'
import i18next from 'i18next'
import InputStore from 'stores/InputStore'
import * as yup from 'yup'
import moment from 'moment'

class UsersCreationStore extends AsyncStore {
  stores = {
    id: new InputStore(),
    firstName: new InputStore(),
    lastName: new InputStore(),
    email: new InputStore(),
    created: new InputStore(),
    updated: new InputStore(),
    roles: new InputStore(),
    profileImage: new ImageInputStore(),
  }

  data = {}
  isDirty = false
  noIdError = false
  firstLoad = true
  firstSave = true
  errorLoadEdit = true
  validationErrors = {}
  viewMode = false
  isTenantUser = false
  roles = []
  idEdition = null
  statusModal = false
  loadingSave = false
  requestErrors = []
  isLoadingResend = false
  successResend = true
  resendErrors = []
  visibleActivationModal = false
  visibleConfirmActivationModal = false

  constructor() {
    super()

    makeObservable(this, {
      // observables
      data: observable,
      isDirty: observable,
      noIdError: observable,
      firstLoad: observable,
      firstSave: observable,
      errorLoadEdit: observable,
      validationErrors: observable,
      viewMode: observable,
      isTenantUser: observable,
      roles: observable,
      idEdition: observable,
      statusModal: observable,
      loadingSave: observable,
      requestErrors: observable,
      isLoadingResend: observable,
      successResend: observable,
      resendErrors: observable,
      visibleActivationModal: observable,
      visibleConfirmActivationModal: observable,
      // actions
      setSchema: action,
      init: action,
      resetAttributes: action,
      resetValidationErrors: action,
      setViewMode: action,
      setLastSaveDate: action,
      toggleViewMode: action,
      changeInput: action,
      toggleUserStatus: action,
      fillEditData: action,
      resetDirty: action,
      validate: action,
      setInputs: action,
      reloadData: action,
      loadRoles: action,
      toJson: action,
      changeStatus: action,
      openResendActivationModal: action,
      hideResendActivationModal: action,
      openConfirmActivationModal: action,
      hideConfirmActivationModal: action,
      resendActivation: action,
      save: action,
      toggleStatusModal: action,
      // computeds
      canSave: computed,
    })

    this.usersService = new UsersService()
  }

  setSchema({ requiredErrorMsg, emailErrorMsg }) {
    this.validationSchema = yup.object().shape({
      firstName: yup.string().required(requiredErrorMsg),
      lastName: yup.string().required(requiredErrorMsg),
      email: yup.string().email(emailErrorMsg).required(requiredErrorMsg),
      roles: yup.array().min(1, requiredErrorMsg),
    })
  }

  init(id) {
    this.idEdition = id
    this.resetDirty()

    this.loadRoles().then(() => {
      if (id !== null && !this.noIdError) {
        runInAction(() => {
          // if es edition
          this.idEdition = id
          this.toggleViewMode(true)
          this.reloadData()
        })
      }
    })
  }

  resetAttributes() {
    this.setInputs(this.data)
    this.isDirty = false
    this.requestErrors = []
  }

  resetValidationErrors = () => {
    const keys = Object.keys(this.validationErrors)
    for (let i = 0; i < keys.length; i += 1) {
      this.stores[keys[i]].clearError()
    }
    this.validationErrors = {}
  }

  setViewMode(value) {
    this.isViewMode(value)
  }

  setLastSaveDate(value) {
    this.lastSaveDate = value
  }

  toggleViewMode(viewMode) {
    this.viewMode = viewMode === null ? !this.viewMode : viewMode
  }

  changeInput(fieldName, value) {
    this.isDirty = true
    this.stores[fieldName].setValue(value)
    if (!this.firstSave) {
      this.validate(fieldName)
    }
  }

  toggleUserStatus() {
    this.changeStatus(!this.data.status.isActive)
    this.toggleStatusModal()
  }

  async fillEditData() {
    this.preRequest(this.fillEditData)

    try {
      const response = await this.usersService.getUser(this.idEdition)
      runInAction(() => {
        this.stores.updated.setValue(moment(response.updated.at))
      })
      return response
    } catch (e) {
      runInAction(() => {
        this.noIdError = true
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }
    return null
  }

  resetDirty() {
    this.isDirty = false
  }

  async validate(key) {
    // Valida de a un solo campo
    if (key) {
      try {
        await this.validationSchema.validateAt(key, this.toJson())
        delete this.validationErrors[key]
        return false
      } catch (err) {
        this.stores[key].setError(true, err.message)
        this.validationErrors[key] = err.message
        return err
      }
    }

    try {
      await this.validationSchema.validate(this.toJson(), { abortEarly: false })
      this.resetValidationErrors()
      return false
    } catch (err) {
      const errors = {}
      if (err.inner) {
        for (let i = 0; i < err.inner.length; i += 1) {
          errors[err.inner[i].path] = String(err.inner[i].message)
          this.stores[err.inner[i].path].setError(true, String(err.inner[i].message))
        }
        this.validationErrors = errors
      }
      return err
    }
  }

  setInputs(data) {
    const keys = Object.keys(data)
    for (let i = 0; i <= keys.length; i += 1) {
      if (this.stores[keys[i]]) {
        if (keys[i] === 'profileImage') {
          this.stores.profileImage.setImage(data.profileImage)
        } else {
          this.stores[keys[i]].setValue(data[keys[i]])
        }
      }
    }
  }

  get canSave() {
    return !this.stores.profileImage.loadingSaveImage
  }

  reloadData(validate = true) {
    this.preRequest(() => this.reloadData(validate))
    this.firstLoad = false
    this.resetValidationErrors()

    this.fillEditData().then((data) => {
      let parsedData = {}
      if (data) {
        parsedData = {
          ...data,
          created: moment(data.created),
          updated: moment(data.updated),
          roles: data.roles.map((e) => ({
            id: e.id,
            value: e.name,
          })),
        }
      }

      runInAction(() => {
        this.setInputs(parsedData)
        this.data = parsedData
        this.isTenantUser = parsedData.tenantUser
      })

      if (validate) {
        this.resetDirty()
        this.validate()
      }

      this.onSuccessRequest()
    })
  }

  loadRoles() {
    this.preRequest(this.loadRoles)
    this.stores.roles.setValue([])

    return this.usersService
      .getRoles()
      .then((data) => {
        runInAction(() => {
          this.roles = data
          this.onSuccessRequest()
        })
      })
      .catch((e) => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
  }

  toJson() {
    const result = {}
    const keys = Object.keys(this.stores)

    for (let i = 0; i < keys.length; i += 1) {
      switch (keys[i]) {
        case 'profileImage':
          result[keys[i]] = this.stores[keys[i]].id
          break
        case 'roles':
          result[keys[i]] = this.stores[keys[i]].value
            ? this.stores[keys[i]].value.map((e) => e.id)
            : []
          break
        default:
          result[keys[i]] = this.stores[keys[i]].value
      }
    }
    return result
  }

  async changeStatus(active) {
    this.preRequest(() => this.changeStatus(active))

    try {
      await this.usersService.changeStatus(this.idEdition, active)

      runInAction(() => {
        this.data.status.toggleStatus()
        this.onSuccessRequest()
      })
    } catch (e) {
      runInAction(() => {
        this.errorLoadEdit = true
        this.onErrorRequest(e)
      })
    }
    return null
  }

  openResendActivationModal() {
    this.visibleActivationModal = true
  }

  hideResendActivationModal() {
    this.visibleActivationModal = false
    this.reloadData()
  }

  openConfirmActivationModal() {
    this.visibleConfirmActivationModal = true
  }

  hideConfirmActivationModal() {
    this.visibleConfirmActivationModal = false
  }

  async resendActivation() {
    this.openResendActivationModal()
    this.hideConfirmActivationModal()
    this.requestProcess(() => this.resendActivation())
    this.isLoadingResend = true

    try {
      await this.usersService.resendActivation(this.stores.email.value)

      runInAction(() => {
        this.successResend = true
        this.isLoadingResend = false
      })
    } catch (e) {
      runInAction(() => {
        this.resendErrors = e.response.data.error.errors
          ? e.response.data.error.errors
          : [i18next.t('common:genericError')]
        this.successResend = false
        this.isLoadingResend = false
        this.onErrorRequest(e)
      })
    }
    return null
  }

  async save(successCallback) {
    this.firstSave = false
    if (!(await this.validate())) {
      this.loadingSave = true
      const data = this.toJson()

      this.requestProcess(this.save)
      this.requestErrors = []

      try {
        let response = ''
        if (this.idEdition) {
          delete data.email
          response = await this.usersService.saveUser(this.idEdition, data)
        } else {
          response = await this.usersService.addUser(data)
        }

        runInAction(() => {
          if (!this.idEdition) {
            this.idEdition = response.id
            this.stores.id.setValue(response.id)
            this.init(response.id)
          }

          if (successCallback) {
            successCallback()
          }

          this.onSuccessRequest()
        })
      } catch (e) {
        runInAction(() => {
          this.errorLoadEdit = true
          this.requestErrors = e.response.data.error.errors
            ? e.response.data.error.errors
            : [i18next.t('common:genericError')]
          this.onErrorRequest(e)
        })
        this.loadingSave = false

        return false
      }
      this.loadingSave = false

      return true
    }

    return false
  }

  toggleStatusModal() {
    this.statusModal = !this.statusModal
  }
}

export default UsersCreationStore
