class Account {
  constructor(assetId, account, amount, currency) {
    this.assetId = assetId
    this.account = account
    this.amount = amount
    this.currency = currency
  }

  static fromJson({ account, amount, assetId = 'no_id', currency }) {
    let polishedAccount = account

    if (polishedAccount === 'otherFund') {
      polishedAccount = 'Other source of funds'
    }

    return new Account(assetId, polishedAccount, amount, currency)
  }

  get moneyString() {
    return `${this.amount} ${this.currency}`
  }
}

export default Account
