import Part, { TYPE_INPUT_FILE } from './Part'
import FileFormPartStore from './FileFormPartStore'

class FilePart extends Part {
  constructor(id, label, validationRules) {
    super(id, TYPE_INPUT_FILE)

    this.label = label
    this.validationRules = validationRules
  }

  static fromJson({ id, display, validationRules = null }, onCreateInput) {
    const { label = '' } = display

    onCreateInput(id, new FileFormPartStore())

    return new FilePart(id, label, validationRules)
  }
}

export default FilePart
