import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ExpandableButton from 'presentation/ExpandableButton'
import styles from './entityModalTypeTitle.module.scss'

const EntityModalTypeTitle = ({ label, icon, onGoBack }) => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.entityModalTypeTitle}>
      {onGoBack !== null && (
        <ExpandableButton
          label={t('goBack')}
          onClick={onGoBack}
          Icon={<ChevronLeftIcon size={25} />}
        />
      )}
      {icon && <div>{icon}</div>}
      <div className={styles.label}>{label}</div>
    </div>
  )
}

EntityModalTypeTitle.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onGoBack: PropTypes.func,
}

EntityModalTypeTitle.defaultProps = {
  onGoBack: null,
  icon: null,
}

export default EntityModalTypeTitle
