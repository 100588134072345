import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common from 'lang/common.json'
import signIn from 'lang/signIn.json'
import navBar from 'lang/navBar.json'
import trusts from 'lang/trusts.json'
import signedDocuments from 'lang/signedDocuments.json'
import trustsCreation from 'lang/trustsCreation.json'
import rulesCreation from 'lang/rulesCreation.json'
import dynamicForms from 'lang/dynamicForms.json'
import pageTitles from 'lang/pageTitles.json'
import mainSectionList from 'lang/mainSectionList.json'
import corporations from 'lang/corporations.json'
import serverError from 'lang/serverError.json'
import trustsValuations from 'lang/trustsValuations.json'
import transactionsCreation from 'lang/transactionsCreation.json'
import clients from 'lang/clients.json'
import clientsCreation from 'lang/clientsCreation.json'
import corporationsCreation from 'lang/corporationsCreation.json'
import lettersForms from 'lang/lettersForms.json'
import validation from 'lang/validation.json'
import users from 'lang/users.json'
import governingLaw from 'lang/governingLaw.json'
import roles from 'lang/roles.json'
import contacts from 'lang/contacts.json'
import contactsCreation from 'lang/contactsCreation.json'
import trustUsers from 'lang/trustUsers.json'
import corporationUsers from 'lang/corporationUsers.json'
import trezor from 'lang/trezor.json'
import userProfile from 'lang/userProfile.json'
import rolesCreation from 'lang/rolesCreation.json'
import permission from 'lang/permission.json'
import transactions from 'lang/transactions.json'
import maintenancePage from 'lang/maintenancePage.json'
import dashboard from 'lang/dashboard.json'
import activity from 'lang/activity.json'
import history from 'lang/history.json'
import cookiePolicy from 'lang/cookiePolicy.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',

  // have a common namespace used around the full app
  ns: [
    'common',
    'transactions',
    'signIn',
    'maintenancePage',
    'pageTitles',
    'contacts',
    'contactsCreation',
    'trusts',
    'governingLaw',
    'trustsCreation',
    'rolesCreation',
    'rulesCreation',
    'dynamicForms',
    'signedDocuments',
    'serverError',
    'trustUsers',
    'corporationUsers',
    'transactionsCreation',
    'userProfile',
    'mainSectionList',
    'corporations',
    'trustsValuations',
    'clients',
    'clientsCreation',
    'corporationsCreation',
    'lettersForms',
    'validation',
    'roles',
    'trezor',
    'users',
    'permission',
    'dashboard',
    'activity',
    'history',
    'cookiePolicy',
  ],
  defaultNS: 'common',

  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    // wait: true,
    useSuspense: true,
  },

  resources: {
    en: {
      common,
      transactions,
      trustUsers,
      maintenancePage,
      corporationUsers,
      signIn,
      governingLaw,
      pageTitles,
      navBar,
      trusts,
      rolesCreation,
      trustsCreation,
      serverError,
      transactionsCreation,
      rulesCreation,
      dynamicForms,
      signedDocuments,
      mainSectionList,
      corporations,
      contacts,
      contactsCreation,
      clients,
      clientsCreation,
      userProfile,
      corporationsCreation,
      lettersForms,
      trustsValuations,
      validation,
      roles,
      trezor,
      users,
      permission,
      dashboard,
      activity,
      history,
      cookiePolicy,
    },
  },
})

export default i18n
