import React, { useEffect, useContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Button from 'presentation/Button'
import { useTranslation } from 'react-i18next'
import StoresContext from 'providers/storeContext'
import CheckboxInput from 'presentation/CheckboxInput'
import Modal from 'scenes/Modal'
import TermsAndConditionsStore from 'stores/TermsAndConditionsStore'
import styles from './termsAndConditionsAlert.module.scss'

const TermsAndConditionsAlert = ({ showTermsModal, closeTermsModal }) => {
  const { t } = useTranslation()
  const [termsAndConditionsStore] = useState(() => new TermsAndConditionsStore())
  const { authStore } = useContext(StoresContext)

  const closeTermsAndConditions = useCallback(() => {
    termsAndConditionsStore.closeTermsAndConditions()
    closeTermsModal()
    authStore.logout()
  }, [])

  const createMarkup = () => {
    return { __html: termsAndConditionsStore.termsAndConditions?.description }
  }

  const handleInputCheckTermsAndConditions = useCallback(() => {
    termsAndConditionsStore.checkTermsAndConditions()
  }, [])

  const handleAcceptTermsAndConditions = useCallback(() => {
    termsAndConditionsStore
      .acceptTerms()
      .then(() => {
        closeTermsModal()
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    termsAndConditionsStore.setCurrentVersion(authStore.authUser?.legals.currentVersion)

    if (showTermsModal) {
      termsAndConditionsStore.loadTermsAndConditions()
    }
  }, [authStore.authUser?.legals])

  return (
    <Modal
      close
      name="termsAndConditions"
      onCancel={closeTermsAndConditions}
      customButtons={[
        <Button
          big
          primary
          key="accept"
          label={t('common:accept')}
          onClick={handleAcceptTermsAndConditions}
        />,
      ]}
      open={showTermsModal}
      closeOutside={false}
    >
      <div className={styles.titleTerms}>{termsAndConditionsStore.termsAndConditions?.title}</div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.termsAndConditions} dangerouslySetInnerHTML={createMarkup()} />
      <div>
        <CheckboxInput
          label={t('common:termsAndConditions')}
          error={termsAndConditionsStore.termsIsNotSelected}
          checked={termsAndConditionsStore.checkedConditions}
          onChange={handleInputCheckTermsAndConditions}
        />
        {termsAndConditionsStore.errorLoadEdit && (
          <small className={styles.errorMessage}>{termsAndConditionsStore.errorMessage}</small>
        )}
      </div>
    </Modal>
  )
}

TermsAndConditionsAlert.defaultProps = {
  showTermsModal: false,
  closeTermsModal: () => {},
}

TermsAndConditionsAlert.propTypes = {
  showTermsModal: PropTypes.bool,
  closeTermsModal: PropTypes.func,
}

export default observer(TermsAndConditionsAlert)
